import React, { useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MTable from '../../../../components/MuiTable';
import ConfirmationDialog from '../../../../components/confirmationDialog';
import {
  cnpjInputMask,
  daysMask,
  moneyMask,
  percentMask,
} from '../../../../utils/inputMasks';
import SellerProductsView from '../SellerProductsView/Index';

export default function SellerTable({
  allSellers,
  deleteSeller,
  setEditModalInfo,
}) {
  const [listDialogInfo, setIistDialogInfo] = useState({
    open: false,
    sellerId: null,
  });

  return (
    <MTable.Container sx={{ mb: 4, mt: 4 }}>
      <MTable.Root>
        <MTable.Head>
          <MTable.Row>
            <MTable.Cell>Nome</MTable.Cell>
            <MTable.Cell>CNPJ</MTable.Cell>
            <MTable.Cell>Taxa de Repasse</MTable.Cell>
            <MTable.Cell>Pedido Mínimo</MTable.Cell>
            <MTable.Cell>Estimativa de Tempo de Entrega</MTable.Cell>
            <MTable.Cell>Ações</MTable.Cell>
          </MTable.Row>
        </MTable.Head>

        <MTable.Body>
          {allSellers.map((seller) => (
            <MTable.Row key={seller.id}>
              <MTable.Cell>{seller.name}</MTable.Cell>
              <MTable.Cell>{cnpjInputMask(seller.cnpj)}</MTable.Cell>
              <MTable.Cell>
                {`${percentMask(
                  seller.takeRate.toString(),
                )}`}

              </MTable.Cell>
              <MTable.Cell>
                {`${moneyMask(
                  (seller.minimumOrder * 100).toString(),
                )}`}

              </MTable.Cell>
              <MTable.Cell>
                {`${daysMask(
                  seller.estimatedDeliveryTime.toString(),
                )}`}

              </MTable.Cell>
              <MTable.Cell>
                <Box display="flex">
                  <Tooltip title="Visualizar lista de produtos">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setIistDialogInfo({
                          open: true,
                          sellerId: seller.id,
                        });
                      }}
                    >
                      <ListAltIcon />
                    </IconButton>
                  </Tooltip>

                  <SellerProductsView
                    sellerId={seller.id}
                    sellerName={seller.name}
                    dialogInfo={listDialogInfo}
                    setDialogInfo={setIistDialogInfo}
                  />

                  <Tooltip title="Editar fornecedor">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setEditModalInfo({
                          open: true,
                          seller,
                        });
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>

                  <ConfirmationDialog
                    title="Deletar Fornecedor"
                    message="Tem certeza que deseja deletar este fornecedor?"
                    handleConfirm={() => deleteSeller(seller.id)}
                    testId="delete-seller-button"
                    iconButton={{
                      icon: <Delete />,
                      color: 'primary',
                      tooltip: 'Deletar Fornecedor',
                    }}
                  />
                </Box>
              </MTable.Cell>
            </MTable.Row>
          ))}
        </MTable.Body>
      </MTable.Root>
    </MTable.Container>
  );
}
