import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Typography,
  Alert,
  Snackbar,
  DialogTitle,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React from 'react';
import { TODAY } from '../../../../utils/daysConstants';
import {
  createEmployeeOptions,
  createResidentOptions,
} from '../../../../utils/createOptionsForAutoComplete';

export default function EditTaskModel({
  editTaskModal,
  setEditTaskModal,
  employees,
  residents,
  updateTask,
  timeError,
  setTimeError,
}) {
  const handleChange = ({ target: { name, value } }) => {
    setEditTaskModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };

  return (
    <Dialog open={editTaskModal.open}>
      <DialogTitle>
        <Typography color="primary" variant="h5" fontWeight={700}>
          Editar tarefa
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} p={2}>
          {employees.length > 0 && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                required
                id="employeeId"
                name="employeeId"
                label="Escolha o cuidador"
                onChange={handleChange}
                defaultValue={editTaskModal.data?.employeeId || ''}
                size="small"
              >
                {createEmployeeOptions(employees).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {editTaskModal.type === 'cleaning' ? (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="text"
                label="Nome da tarefa"
                id="title"
                name="title"
                required
                onChange={handleChange}
                value={editTaskModal.data?.title || ''}
                size="small"
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Autocomplete
                id="residentId"
                name="residentId"
                disabled
                options={createResidentOptions(residents) || []}
                value={
                  createResidentOptions(residents).find(
                    ({ value }) => value === editTaskModal.data?.residentId,
                  ) || null
                }
                onChange={(e, field) => {
                  handleChange({
                    target: { name: 'residentId', value: field.value },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} required label="Residentes" />
                )}
                size="small"
              />
              {editTaskModal.type === 'medications' && (
                <TextField
                  fullWidth
                  disabled
                  type="text"
                  label="Nome da medicação"
                  id="medicineName"
                  name="medicineName"
                  value={editTaskModal.data?.medicineName || ''}
                  size="small"
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Data final da tarefa"
              value={editTaskModal.data.untilDate}
              renderInput={(params) => (
                <TextField
                  required
                  id="untilDate"
                  name="untilDate"
                  {...params}
                  size="small"
                />
              )}
              onChange={(value) => handleChange({ target: { name: 'untilDate', value } })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TimePicker
              label="Hora da tarefa"
              value={editTaskModal.data.startTime}
              renderInput={(params) => (
                <TextField
                  required
                  id="startTime"
                  name="startTime"
                  {...params}
                  size="small"
                />
              )}
              onChange={(value) => handleChange({ target: { name: 'startTime', value } })}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              type="text"
              label="Observação (Opcional)"
              id="observation"
              name="observation"
              onChange={handleChange}
              value={editTaskModal.data?.observation || ''}
              size="small"
            />
          </Grid>
          <Snackbar
            open={timeError.open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={() => {
              setTimeError({
                message: '',
                open: false,
              });
            }}
          >
            <Alert
              onClose={() => {
                setTimeError({
                  message: '',
                  open: false,
                });
              }}
              severity="warning"
            >
              {timeError.message}
            </Alert>
          </Snackbar>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            setEditTaskModal({
              open: false,
              data: {
                untilDate: TODAY,
                startTime: moment(TODAY).format('YYYY-MM-DDTHH:00'),
              },
            });
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            updateTask();
          }}
        >
          Editar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
