import React, { useState } from 'react';
import { ReceiptRounded } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../../../../../components/Loading/Loading';
import { useAlert } from '../../../../../../context/alertContext';
import routes from '../../../../../../services/routes';
import { getTokenInfo } from '../../../../../../utils/configAuth';

const {
  Button,
  CircularProgress,
  Typography,
  Box,
  Autocomplete,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} = require('@mui/material');

function GenerateRecipe({ prescription }) {
  const alert = useAlert();
  const ilpiId = getTokenInfo('ilpiId');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [doctor, setDoctor] = useState({});

  const getDoctors = async () => {
    try {
      const { message } = await routes.doctor.searchDoctor({ ilpiId });

      return message.rows.map((thisDoctor) => ({
        label: thisDoctor.name,
        value: thisDoctor.id,
      }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        alert.info('Não há médicos cadastrados');
      } else throw error;

      return [];
    }
  };

  const { data: doctorSuggestions, isLoading: doctorsIsLoading } = useQuery({
    queryKey: ['doctorSuggestions', ilpiId],
    queryFn: getDoctors,
    enabled: isOpen,
    onError: () => {
      alert.error('Não foi possível buscar os médicos');
      return [];
    },
    retry: 0,
    cacheTime: 0,
  });

  const sendRecipe = async () => {
    setIsLoading(true);
    setIsOpen(false);

    try {
      const prescriptionItems = prescription.prescriptionItems.map((item) => {
        const itemWithoutNullValues = {};

        Object.entries(item).forEach(([key, value]) => {
          if (value !== null) itemWithoutNullValues[key] = value;
        });

        return itemWithoutNullValues;
      });

      const params = {
        prescriptionId: prescription.id,
        doctorId: doctor.value,
        residentId: prescription.residentId,
        residentName: prescription.residentName,
        prescriptionItems,
      };

      await routes.recipe.generateRecipeByIlpi({ ilpiId }, params);

      alert.success('Receita gerada com sucesso!');
    } catch {
      alert.error('Não foi possível gerar a receita');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Button
        color="primary"
        variant="outlined"
        padding="10px"
        sx={{ py: '2px' }}
        onClick={() => setIsOpen(true)}
      >
        {isLoading ? (
          <CircularProgress size="17px" />
        ) : (
          <ReceiptRounded sx={{ width: '18px' }} />
        )}
        <Typography component="label" style={{ marginLeft: '7px' }}>
          Gerar Receita
        </Typography>
      </Button>

      <Dialog open={isOpen}>
        <DialogTitle>
          <Typography color="primary" variant="h5" fontWeight="700">
            Gerar Receita
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography component="p" color="primary" fontWeight="700">
            Escolha o médico responsável pela Receita
          </Typography>

          {doctorsIsLoading ? (
            <Loading sx={{ margin: '12px 0' }} />
          ) : (
            <Autocomplete
              name="item"
              options={doctorSuggestions}
              onChange={(_e, item) => setDoctor(item || {})}
              disabled={doctorSuggestions.length === 0}
              value={doctor.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Médico responsável"
                  fullWidth
                  sx={{ marginTop: '16px' }}
                />
              )}
            />
          )}
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px',
          }}
        >
          <Button color="cancel" onClick={() => setIsOpen(false)}>
            Cancelar
          </Button>

          <Button
            type="submit"
            variant="contained"
            disabled={!doctor.value}
            onClick={sendRecipe}
          >
            Gerar Receita
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default GenerateRecipe;
