import React, { useState } from 'react';

import {
  Box,
  DialogActions,
  DialogContent,
} from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';
import {
  ContentContainer, Description, StyledDialog, Title,
} from './style';
import BenvoTextField from '../../../../components/BenvoTextField';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-icon-outline.svg';

export default function RejectModal({
  title, residentName, removeFromApproved,
}) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [motive, setMotive] = useState('');
  const [error, setError] = useState(false);

  const handleMotive = ({ target }) => {
    setError(false);
    setMotive(target.value);
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setMotive('');
  };

  const verifyError = () => {
    if (!motive || motive.length < 3) {
      setError(true);
      return true;
    }
    setError(false);
    return false;
  };

  const onSubmit = async () => {
    if (verifyError()) return;
    await removeFromApproved(residentName, motive);
    handleCloseModal();
  };

  return (
    <Box>
      <BenvoButton variant="secondary" onClick={handleOpenModal}>
        {title}
      </BenvoButton>
      <StyledDialog open={isOpenModal} onClose={handleCloseModal}>
        <DialogContent sx={{ p: '0px' }}>
          <ContentContainer>
            <img src="/images/delete-dialog.png" alt={title} />
            <Box>
              <Title>Deseja rejeitar a solicitação?</Title>
              <Description>Essa ação não poderá ser desfeita.</Description>
            </Box>
            <BenvoTextField
              value={motive}
              placeholder="Motivo"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              data-testid="reject-motive-text"
              onChange={handleMotive}
              sx={{
                p: '10px 8px',

                border: error ? '1px solid var(--paleta-senior-alert-red, #A73737);' : 'none',
                '.MuiInputBase-input::placeholder': {
                  color: error ? 'var(--paleta-senior-alert-red, #A73737);' : '#474750',
                  opacity: '1',
                  fontSize: '16px',
                },
              }}
            />
          </ContentContainer>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 0px 0px 0px',
          }}
        >
          <BenvoButton variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </BenvoButton>
          <BenvoButton
            onClick={onSubmit}
            variant="primary"
            startIcon={<TrashIcon />}
            data-testid="reject-button"
            sx={{ gap: '0px' }}
          >
            Rejeitar
          </BenvoButton>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
}
