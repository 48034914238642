import { Box, IconButton, styled } from '@mui/material';

export const ShoppingListItemContainer = styled(Box)`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h6 {
    color: #474750;

    font-size: 12px;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
    letter-spacing: 0.12px;
  }
`;

export const ButtonAndQuantityContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  min-width: 222px;

  p {
    color: #96969e;
    min-width: 20px;
    text-align: center;

    font-size: 12px;
    font-weight: 500;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.12px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 7px;
    flex: 1;
    justify-content: flex-end;
  }

  button {
    padding: 0;
  }
`;

export const ButtonAndQuantityContainerView = styled(Box)`
  display: flex;
  align-items: center;
  gap: 7px;
  justify-content: space-between;
  width: 186px;

  p {
    color: #96969e;
    min-width: 20px;
    text-align: center;

    font-size: 12px;
    font-weight: 500;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.12px;
  }

  button {
    padding: 0;
  }
`;

export const StyledIconButton = styled(IconButton)`
  svg {
    path {
      stroke: ${({ disabled }) => disabled && '#D4D4DA'};
    }
  }
`;

export const ManualQuantityInput = styled('input')`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  border: none;
  width: 56px;
  height: 28px;
  text-align: center;
  margin-right: 54px;

  color: #474750;
  font-size: 12px;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }


  &:focus {
    outline: none;
  }
`;
