const convertStatus = (status) => {
  let convertedStatus = '';
  switch (status) {
    case 'sent':
      convertedStatus = 'Enviado';
      break;
    case 'notSent':
      convertedStatus = 'Não Enviado';
      break;
    case 'approved':
      convertedStatus = 'Aprovado';
      break;
    case 'unapproved':
      convertedStatus = 'Não aprovado';
      break;
    case 'signed':
      convertedStatus = 'Assinado';
      break;
    default:
      break;
  }
  return convertedStatus;
};

export default convertStatus;
