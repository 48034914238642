import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import {
  ActionButtonsContainer,
  ActionsContainer,
  Container,
  NameAndPositionContainer,
  StyledDivider,
  StyledIconButton,
  StyledSelect,
  StyledSkeleton,
  UserInformationContainer,
} from './style';
import LockIcon from '../../../../assets/admin(ilpis)/lockIcon';
import BenvoTooltip from '../../../../components/BenvoTooltip';
import { phoneInputMask } from '../../../../utils/inputMasks';
import routes from '../../../../services/routes';
import { useAlert } from '../../../../context/alertContext';
import BenvoDeleteModal from '../../../../components/BenvoDeleteModal';
import GenerateImpersonate from '../GenerateImpersonate';
import EditIlpiUserModal from '../editIlpiUserModal';
import ViewIlpiUserModal from '../viewIlpiUserModal';

const statusOptions = [
  { value: 1, label: 'Ativo', dataTestId: 'active' },
  { value: 0, label: 'Inativo', dataTestId: 'inactive' },
];

const privilegeTranslations = {
  admin: 'Administrador',
  user: 'Usuário',
};

const translatePrivilege = (privilege) => privilegeTranslations[privilege] || 'Usuário';

export default function IlpiUsers({ user, refetchIlpiUsers, usersIsFetching }) {
  const [resetPasswordIsLoading, setResetPasswordIsLoading] = useState(false);

  const { users: usersRoute, publicRoutes } = routes;
  const alert = useAlert();
  const isAdmin = user.privilege === 'admin';

  const handleChangeUserStatus = async () => {
    try {
      await usersRoute.changeUserStatus({ userId: user.id });
      alert.success('Status do usuário alterado com sucesso');
      refetchIlpiUsers();
    } catch {
      alert.error('Não foi possível alterar o status do usuário');
    }
  };

  const handleDeleteUser = async () => {
    try {
      await usersRoute.deleteUserById({ userId: user.id });
      alert.success('Usuário deletado com sucesso');
      refetchIlpiUsers();
    } catch (error) {
      alert.error('Não foi possível deletar o usuário');
    }
  };

  const handleResetUserPassword = async () => {
    setResetPasswordIsLoading(true);
    try {
      await publicRoutes.requestNewPassword({
        email: user.email,
      });
      alert.success('E-mail de redefinição de senha enviado com sucesso!');
    } catch (error) {
      alert.error('Erro ao enviar e-mail de redefinição de senha');
    } finally {
      setResetPasswordIsLoading(false);
    }
  };

  if (usersIsFetching || !user) {
    return <StyledSkeleton variant="rectangular" />;
  }

  return (
    <Container>
      <NameAndPositionContainer>
        <h3>{user.name}</h3>
        <p>{translatePrivilege(user.privilege)}</p>
      </NameAndPositionContainer>

      <ActionsContainer>
        <StyledSelect
          fullWidth
          displayEmpty
          value={user.isActive}
          renderValue={() => `Status ${user.isActive ? 'ativo' : 'inativo'} `}
          onChange={handleChangeUserStatus}
        >
          {statusOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              data-testid={option.dataTestId}
            >
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
        <UserInformationContainer>
          <p>{phoneInputMask(user.phoneNumber)}</p>
          <span>{user.email}</span>
        </UserInformationContainer>

        <StyledDivider flexItem orientation="vertical" />

        <ActionButtonsContainer>
          <BenvoTooltip title="Deletar Usuário" arrow placement="top">
            <BenvoDeleteModal
              tooltipTitle="Apagar usuário"
              handleDelete={handleDeleteUser}
              title="Apagar usuário"
              description="Deseja apagar esse usuário, essa ação não pode ser desfeita?"
              buttonText="Excluir usuário"
              disabled={isAdmin}
              tooltipDisabledTitle="Usuário é administrador"
            />
          </BenvoTooltip>

          <BenvoTooltip
            title="Redefinir Senha do Usuário"
            arrow
            placement="top"
          >
            <StyledIconButton
              disabled={resetPasswordIsLoading}
              onClick={handleResetUserPassword}
            >
              <LockIcon />
            </StyledIconButton>
          </BenvoTooltip>

          <GenerateImpersonate userId={user.id} />

          <EditIlpiUserModal
            isAdmin={isAdmin}
            user={user}
            refetchIlpiUsers={refetchIlpiUsers}
          />

          <ViewIlpiUserModal user={user} />
        </ActionButtonsContainer>
      </ActionsContainer>
    </Container>
  );
}
