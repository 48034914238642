import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
} from './style';
import UsersIcon from '../../../../assets/admin(ilpis)/usersIcon';
import IlpiUsers from '../ilpiUsers';
import routes from '../../../../services/routes';
import BenvoTooltip from '../../../../components/BenvoTooltip';

export default function ManageIlpiUsersModal({ ilpiId }) {
  const [openModal, setOpenModal] = useState(false);
  const { ilpi } = routes;

  const getIlpiUsers = async () => {
    try {
      const { message } = await ilpi.getIlpiUsers({ ilpiId });
      return message;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const {
    data: ilpiUsers = Array(1).fill({}),
    isFetching: usersIsFetching,
    refetch: refetchIlpiUsers,
  } = useQuery({
    queryKey: ['ilpiUsers', ilpiId],
    queryFn: () => getIlpiUsers(),
    enabled: openModal,
    refetchOnWindowFocus: false,
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <BenvoTooltip title="Gerenciar usuários" arrow placement="top">
        <StyledIconButton onClick={handleOpenModal}>
          <UsersIcon />
        </StyledIconButton>
      </BenvoTooltip>
      <StyledDialog open={openModal} onClose={handleCloseModal}>
        <StyledDialogTitle>Usuários</StyledDialogTitle>
        <StyledDialogContent>
          {ilpiUsers?.map((user) => (
            <IlpiUsers
              key={user.id}
              user={user}
              refetchIlpiUsers={refetchIlpiUsers}
              usersIsFetching={usersIsFetching}
            />
          ))}
        </StyledDialogContent>
      </StyledDialog>
    </div>
  );
}
