import React from 'react';
import { StyledBenvoContentBox, StyledTitle } from './styles';

function BenvoContentBox({
  title, children, sx, ...props
}) {
  return (
    <StyledBenvoContentBox
      sx={{ ...sx }}
      {...props}
    >
      {title && <StyledTitle>{title}</StyledTitle>}
      {children}
    </StyledBenvoContentBox>
  );
}

export default BenvoContentBox;
