import { Box, styled } from '@mui/material';

/* eslint-disable import/prefer-default-export */
export const BenvoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 25px 16px 16px;
  border-radius: 6px;
  min-height: 100%;
  position: relative;
  overflow: hidden;
  flex: 1;
  min-width: 720px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 9px;
    background: linear-gradient(90deg, rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%);
  }
`;
