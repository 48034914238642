import {
  Step, StepConnector, StepContent, Stepper, styled, Typography,
} from '@mui/material';

export const LabelDescription = styled(Typography)`
  color: var(--grey-variation-normal-grey, #96969e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StyledStepper = styled(Stepper)`
  .MuiStepContent-root {
    padding-left: 28px;
  }

  .MuiStepLabel-iconContainer {
    padding-right: 16px;
  }

  .MuiStepLabel-root {
    padding: 0;
  }

  .MuiStepContent-transition {
    visibility: visible !important;
    display: block !important;
  }
`;

export const StyledStep = styled(Step)`
  ${({ isLastStep }) => (isLastStep
    ? `
    .MuiStepContent-root {
      border-left: none;
      margin-left: 11px;
    }
  `
    : '')}

  ${({ concluded }) => (concluded
    ? `
    .MuiStepLabel-iconContainer {
      svg {
        color: var(--paleta-senior-purple, #4a397f);
      }
    }
    .MuiStepLabel-label{
      color: var(--paleta-senior-purple, #000000de);
    }
    .MuiStepConnector-root{
      display: none !important;
    }
  `
    : `
     
    `)}
`;

export const StyledContent = styled(StepContent)`
  height: 59px;
  ${({ active }) => (active
    ? `
    border-left: 2px solid var(--grey-variation-light-grey, #4a397f);
  `
    : `
    border-left: 2px solid var(--grey-variation-light-grey, #D4D4DA);
  `)}

  ${({ concluded }) => (concluded ? 'border-left: 2px solid var(--grey-variation-light-grey, #4a397f);' : '')}
`;

export const StyledConnector = styled(StepConnector)`
  .MuiStepConnector-lineVertical {
    min-height: 0;
  }
`;
