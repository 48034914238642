import React from 'react';
import { Button, Card } from './style';

export default function MedicineAndPharmacy() {
  return (
    <Card>
      <h2>
        Medicamentos,
        EPI&apos;s e itens higiene
      </h2>
      <p>Economia para ILPI&apos;s que compram itens diretamente.</p>
      <a
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=5521988094488&text=Ol%C3%A1!%20Gostaria%20de%20mais%20detalhes%20sobre%20a%20economia%20atrav%C3%A9s%20de%20compras%20pela%20ILPI."
        rel="noreferrer"
      >
        <Button type="button">Saiba mais</Button>
      </a>
    </Card>
  );
}
