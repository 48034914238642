import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContainerPage from '../../../../templates/containerPage/ContainerPage';
import MTable from '../../../../components/MuiTable';
import Loading from '../../../../components/Loading/Loading';
import { convertBRDate } from '../../../../utils/dateFormat';
import routes from '../../../../services/routes';

function ShoppingListPreview() {
  const { listId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [shoppingList, setShoppingList] = useState({
    list: [],
  });

  const getList = async () => {
    try {
      const shoppingListId = listId;
      const { message: thisMessage } = await routes.publicRoutes.getShoppingListPreview({ shoppingListId });

      setShoppingList(thisMessage);
      setIsLoading(false);
    } catch (error) {
      console.log('ShoppingListPreview', error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <ContainerPage
      title={`Lista de compras para ${shoppingList.residentName}`}
      description={`Aqui você pode visualizar as compras para o dia ${convertBRDate(
        shoppingList.date,
      )}.`}
      sx={{ mx: 2 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ my: 2 }}>
          {shoppingList.observation && (
            <Typography variant="body1">
              <strong> Observação: </strong>
              {shoppingList.observation}
            </Typography>
          )}
        </Box>
      </Box>

      <MTable.Container sx={{ my: 2 }}>
        <MTable.Root>
          <MTable.Head>
            <MTable.Row>
              <MTable.Cell>Item</MTable.Cell>
              <MTable.Cell>Quantidade</MTable.Cell>
            </MTable.Row>
          </MTable.Head>

          <MTable.Body>
            {shoppingList.list.map(({ item, quantity }) => (
              <MTable.Row key={item}>
                <MTable.Cell>{item}</MTable.Cell>
                <MTable.Cell>{quantity}</MTable.Cell>
              </MTable.Row>
            ))}
          </MTable.Body>
        </MTable.Root>
      </MTable.Container>
    </ContainerPage>
  );
}

export default ShoppingListPreview;
