import { Box, Typography, styled } from '@mui/material';

export const Title = styled(Typography)`
    color: var(--grey-variation-dark-grey, #474750);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%; /* 18.4px */
    letter-spacing: 0.024px;
`;

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    gap: 18px;
`;
