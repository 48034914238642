import React, { useState } from 'react';

import { Divider, Typography } from '@mui/material';
import { useAlert } from '../../context/alertContext';
import routes from '../../services/routes';
import BenvoDrawer from '../../components/BenvoDrawer';
import CreateIlpi from './components/CreateIlpi';
import BenvoContentBox from '../../components/BenvoContentBox';
import {
  ContainerActions, ContainerIlpiInformation, ContainerSearchAndActions, ContainerTitleSearchAndActions, StyledSkeleton,
} from './style';
import BenvoTextField from '../../components/BenvoTextField';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-button-icon-outline.svg';
import BenvoPrimaryButton from '../../components/BenvoPrimaryButton';
import ListIlpi from './components/ListIlpi';
import useInfiniteList from '../../hooks/useInfiniteList';
import DynamicScroll from '../../components/DynamicScroll';
import BenvoEmpty from '../../components/BenvoEmpty';

function Ilpi() {
  const [searchParams, setSearchParams] = useState({
    name: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const alert = useAlert();

  const searchIlpi = async (queries) => {
    setIsLoading(true);
    try {
      const { message } = await routes.ilpi.findIlpi({
        ...queries,
      });
      return message;
    } catch (error) {
      alert.info('Não foram encontradas ILPIs');
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const {
    data: ilpiList = [],
    nextPage,
    setQueries,
    loading,
    finished,
    refetch,
  } = useInfiniteList({ getData: searchIlpi });

  const handleSearch = async () => {
    setQueries({ name: searchParams.name });
  };

  const deleteIlpi = async (ilpiId) => {
    setIsLoading(true);

    try {
      await routes.ilpi.removeIlpi({ ilpiId });
      refetch();
      alert.success('ILPI removida com sucesso!');
    } catch (error) {
      alert.error('Ocorreu um erro, tente novamente mais tarde!');
    } finally {
      setIsLoading(false);
    }
  };

  const resetIlpiPassword = async (ilpiId) => {
    try {
      await routes.ilpi.resetIlpiPassword({ ilpiId });
      refetch();
      alert.success('Email com nova senha enviado com sucesso!');
    } catch (error) {
      alert.error('Ocorreu um erro, tente novamente mais tarde!');
    }
  };

  return (
    <BenvoContentBox sx={{ heigth: '100%' }}>
      <ContainerTitleSearchAndActions>
        <Typography variant="h2">Gestão de ILPIs</Typography>
        <ContainerSearchAndActions>
          <BenvoTextField
            hiddenLabel
            placeholder="Pesquisar"
            onChange={(event) => setSearchParams({ name: event.target.value })}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
            value={searchParams.name}
            data-testid="search-bar"
            InputProps={{
              endAdornment: (
                <SearchIcon style={{ cursor: 'pointer' }} onClick={handleSearch} data-testid="search-button" />
              ),
            }}
            sx={{
              maxWidth: '266px',
            }}
          />
          <ContainerActions>
            <Divider orientation="vertical" flexItem />

            <BenvoPrimaryButton onClick={() => setOpenDrawer(true)}>
              Nova ILPI
            </BenvoPrimaryButton>

          </ContainerActions>
        </ContainerSearchAndActions>
      </ContainerTitleSearchAndActions>

      <ContainerIlpiInformation data-testid="ilpi-list">
        {(loading || isLoading)
          ? (
            <StyledSkeleton variant="rounded" />
          )
          : (
            <>
              <BenvoEmpty
                sx={{
                  margin: '80px',
                }}
                active={!ilpiList?.length}
                title="ILPIs"
                message="Não foram encontradas ILPIs cadastradas."
              />

              <DynamicScroll
                onScrollEnd={nextPage}
                sx={{ width: '100%' }}
                finished={finished}
                loading={loading}
              >
                {ilpiList.map((ilpi) => (
                  <ListIlpi
                    key={ilpi.id}
                    ilpiInfo={ilpi}
                    handleDelete={deleteIlpi}
                    handleSearch={handleSearch}
                    resetIlpiPassword={resetIlpiPassword}
                  />
                ))}
              </DynamicScroll>
            </>
          )}
      </ContainerIlpiInformation>

      <BenvoDrawer
        title="Cadastro de ILPI"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <CreateIlpi onClose={() => {
          setOpenDrawer(false);
          handleSearch();
        }}
        />
      </BenvoDrawer>
    </BenvoContentBox>
  );
}

export default Ilpi;
