import moment from 'moment';

/*
 * @params date: Date ou String
 * Obs: se for string, deve estar no formato 'YYYY-MM-DD'
 * retorna string no formato 'DD/MM/YYYY' ou string vazia
 */
export const convertBRDate = (date) => {
  if (!date) return '';

  try {
    const dateToFormat = typeof date === 'string' ? new Date(date) : date;
    return moment(dateToFormat).format('YYYY-MM-DD HH:mm');
  } catch (error) {
    return '';
  }
};

/*
 * @params date: Date
 * retorna string no formato 'YYYY-MM-DD'
 */
export const convertAPIDate = (date) => {
  if (!date) return moment().format('YYYY-MM-DD HH:mm');
  return moment(date).format('YYYY-MM-DD HH:mm');
};

export const convertAPIDateWithoutTime = (date) => {
  if (!date) return moment().format('YYYY-MM-DD');
  return moment(date).format('YYYY-MM-DD');
};

export const convertBeginDateToMomentFormat = (date) => moment(date).format('YYYY-MM-DD 00:00:00');

export const convertEndDateToMomentFormat = (date) => moment(date).format('YYYY-MM-DD 23:59:59');
