import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import moment from 'moment/moment';

import schemas from '../../../../schemas';
import routes from '../../../../services/routes';
import { getTokenInfo } from '../../../../utils/configAuth';
import { useAlert } from '../../../../context/alertContext';
import { convertAPIDate } from '../../../../utils/dateFormat';
import BenvoButton from '../../../../components/Button';

import WellBeingForm from './WellBeingForm';
import VitalSignsForm from './VitalSignsForm';
import DiuresisForm from './DiuresisForm';

const formOptions = [
  {
    value: 'Bem Estar',
    label: 'Bem Estar',
  },
  {
    value: 'Sinais Vitais',
    label: 'Sinais Vitais',
  },
  {
    value: 'Diurese',
    label: 'Diurese',
  },
];

const statusOptions = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Atenção', label: 'Atenção' },
  { value: 'Alerta', label: 'Alerta' },
];

export default function CreateEvolution({
  dialogInfoInitialState,
  dialogInfo,
  setDialogInfo,
  onSuccess,
  taskId,
}) {
  const [residentName, setResidentName] = useState('');
  const ilpiId = getTokenInfo('ilpiId');
  const alert = useAlert();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schemas.evolution.createEvolution),
    defaultValues: {
      kind: 'Sinais Vitais',
      status: 'Normal',
      date: moment().format('YYYY-MM-DD'),
    },
  });

  const getTask = async () => {
    const task = await routes.task.findTaskById({ taskId });
    return task.message;
  };

  const getResidentName = async (residentId) => {
    try {
      const resident = await routes.resident.getResidentById({ residentId });
      setResidentName(resident.message.name);
      return resident.message.name;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  const handleModalClose = () => {
    reset();
    setDialogInfo(dialogInfoInitialState);
  };

  const createResidentEvolution = async (data) => {
    const { residentId, observation, ...rest } = data;
    const payload = {
      ...rest,
      residentId,
      ilpiId,
      name: residentName,
      date: convertAPIDate(data.date),
    };

    if (observation) payload.observation = observation;

    try {
      await routes.resident.createResidentEvolution({ residentId }, payload);

      onSuccess();
      handleModalClose();
      alert.success('Evolução criada com sucesso');
    } catch (error) {
      console.error(error);
      alert.error('Erro ao criar evolução');
    }
  };

  const { data: currentTask = {} } = useQuery({
    queryKey: ['task'],
    queryFn: () => getTask(),
    onError: (error) => {
      console.error(error);
    },
    retry: 0,
    cacheTime: 0,
  });

  const createInputs = (kind) => {
    switch (kind) {
      case 'Bem Estar':
        return <WellBeingForm watch={watch} register={register} errors={errors} />;
      case 'Sinais Vitais':
        return <VitalSignsForm watch={watch} register={register} errors={errors} />;
      default:
        return <DiuresisForm watch={watch} register={register} errors={errors} />;
    }
  };

  useEffect(() => {
    const currentKind = watch('kind');

    reset();
    setValue('kind', currentKind);
    setValue('date', moment().format('YYYY-MM-DD'));
    setValue('residentId', currentTask.residentId);
    setValue('responsibleName', currentTask.caregiverName);
  }, [watch('kind')]);

  useEffect(() => {
    if (currentTask.residentId) {
      setValue('residentId', currentTask.residentId);
      getResidentName(currentTask.residentId);
    }

    if (currentTask.caregiverName) {
      setValue('responsibleName', currentTask.caregiverName);
    }
  }, [currentTask]);

  return (
    <Box>
      <BenvoButton
        onClick={() => setDialogInfo({
          open: true,
        })}
      >
        Criar Evolução
      </BenvoButton>
      <Dialog open={dialogInfo.open} onClose={handleModalClose}>
        <DialogTitle color="primary" fontWeight="600">
          Criar Evolução
        </DialogTitle>
        <form onSubmit={handleSubmit(createResidentEvolution)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Tipo"
                  error={!!errors.kind}
                  helperText={errors.kind?.message}
                  value={watch('kind')}
                  {...register('kind')}
                >
                  {formOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  disabled
                  size="small"
                  fullWidth
                  label="Responsável"
                  value={watch('responsibleName')}
                  {...register('responsibleName')}
                >
                  <MenuItem value={watch('responsibleName')}>{watch('responsibleName')}</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  disabled
                  size="small"
                  fullWidth
                  label="Residente"
                  value={residentName}
                >
                  <MenuItem value={residentName}>{residentName}</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  disabled
                  type="date"
                  label="Data da Evolução"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('date')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Status"
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  value={watch('status')}
                  {...register('status')}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {createInputs(watch('kind'))}

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Observação (Opcional)"
                  error={!!errors.observation}
                  helperText={errors.observation?.message}
                  {...register('observation')}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </form>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: '16px',
          }}
        >
          <Button color="cancel" onClick={handleModalClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSubmit(createResidentEvolution)}>
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
