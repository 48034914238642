export const white = '#fff';

export const black = '#000';

export const red = {
  100: '#FFD2D4',
  200: '#FDA7AA',
  300: '#FA7E82',
  400: '#F4575C',
  500: '#ED3237',
  600: '#D2292D',
  700: '#B72125',
  800: '#9A191D',
  900: '#7D1315',
};

export const primary = {
  100: '#CCF3F1',
  200: '#99E4E2',
  300: '#66D4D1',
  400: '#33C3BE',
  500: '#0A305A',
  600: '#009C97',
  700: '#008884',
  800: '#007370',
  900: '#005E5B',
};

export const blue = {
  100: '#CCE3FD',
  200: '#9CC7F9',
  300: '#6DACF3',
  400: '#4190EA',
  500: '#1675E0',
  600: '#1066C7',
  700: '#0B57AD',
  800: '#074993',
  900: '#043A77',
};

export const cyan = {
  100: '#E1F6F9',
  200: '#74CCD9',
  300: '#6BBECA',
  400: '#62B0BC',
  500: '#59A2AD',
  600: '#50949E',
  700: '#47868F',
  800: '#3F7780',
  900: '#376970',
};

export const gray = {
  100: '#F0F0F0',
  200: '#E5E5E5',
  300: '#D4D4D4',
  400: '#A3A3A3',
  500: '#737373',
  600: '#535353',
  700: '#404040',
  800: '#262626',
  900: '#171717',
};

export const purple = {
  500: '#7A5DEF',
};

export const gradient = {
  100: 'linear-gradient(90deg, #F85C4C 4.27%, #9F56B4 113.12%)',
};
