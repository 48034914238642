/* eslint-disable max-len */
import React from 'react';

export default function CalendarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M5.33325 1.36475V3.35533" stroke="#474750" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.6667 1.36475V3.35533" stroke="#474750" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.33325 6.06909H13.6666" stroke="#474750" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 5.67751V11.3175C14 13.3081 13 14.6352 10.6667 14.6352H5.33333C3 14.6352 2 13.3081 2 11.3175V5.67751C2 3.68692 3 2.35986 5.33333 2.35986H10.6667C13 2.35986 14 3.68692 14 5.67751Z" stroke="#474750" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.4632 9.12791H10.4692" stroke="#474750" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.4632 11.1186H10.4692" stroke="#474750" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.99691 9.12791H8.0029" stroke="#474750" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.99691 11.1186H8.0029" stroke="#474750" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.52962 9.12791H5.53561" stroke="#474750" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.52962 11.1186H5.53561" stroke="#474750" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
