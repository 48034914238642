import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  chipClasses,
  styled,
} from '@mui/material';

import moment from 'moment';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Stack } from '@mui/system';
import { CalendarMonthOutlined } from '@mui/icons-material';
import { useAlert } from '../../context/alertContext';
import { getTokenInfo } from '../../utils/configAuth';
import routes from '../../services/routes';
import { convertAPIDate } from '../../utils/dateFormat';
import RenderTasks from './components/RenderTasks';
import CreateEvolution from './components/CreateEvolution';

const medicineJustifications = [
  'Não tinha remédio',
  'Residente se recusou a tomar',
  'Residente não tinha condições de saúde',
];
const generalJustifications = [
  'Outra pessoa realizou a tarefa',
  'Não consegui chegar a tempo',
  'Essa tarefa não era pra mim',
];

const tasksTypes = [
  {
    label: 'Todos',
    value: '',
  },
  {
    label: 'Medicamentos',
    value: 'medications',
  },
  {
    label: 'Evolução',
    value: 'evolution',
  },
  {
    label: 'Lazer/Jogos',
    value: 'playing',
  },
  {
    label: 'Atividade Física',
    value: 'physicalActivity',
  },
  {
    label: 'Limpeza',
    value: 'cleaning',
  },
  {
    label: 'Alimentação',
    value: 'meals',
  },
  {
    label: 'Higiene',
    value: 'hygiene',
  },
];

const dialogInfoInitialState = {
  isOpen: false,
};

export default function CaregiverHome() {
  const alert = useAlert();
  const [isShowCancelModelOn, setIsShowCancelModelOn] = useState(false);
  const [isShowCompleteModelOn, setIsShowCompleteModelOn] = useState(false);
  const [taskIdToCancel, setTaskIdToCancel] = useState(null);
  const [currentTypeTask, setCurrentTypeTask] = useState(null);
  const [taskIdToComplete, setTaskIdToComplete] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState({
    value: '',
    label: 'Todos',
  });
  const [justification, setJustification] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [evolutionModal, setEvolutionModal] = useState({
    isOpen: false,
    onSuccess: null,
  });
  const [dialogInfo, setDialogInfo] = useState(dialogInfoInitialState);

  const CustomChip = styled(Chip)({
    [`&.${chipClasses.filledPrimary}`]: {
      backgroundColor: '#fff',
      color: '#7A5DEF',
      fontWeight: 700,
      border: '1px solid #7A5DEF',
    },
    [`&.${chipClasses.filledSecondary}`]: {
      backgroundColor: '#7A5DEF',
      color: '#fff',
      fontWeight: 700,
    },
  });

  const queryClient = useQueryClient();

  const employeeId = getTokenInfo('employeeId');
  const ilpiId = getTokenInfo('ilpiId');

  const intervalToUpdateRequest = 5 * 60 * 1000;
  const { isLoading } = useQuery({
    queryKey: ['tasks', { employeeId, selectedTaskType }],
    queryFn: () => routes.task.getTodayTasks({
      employeeId,
      onlyTasksType: selectedTaskType.value,
    }),
    retry: 0,
    refetchInterval: intervalToUpdateRequest,
    refetchIntervalInBackground: true,
    cacheTime: 0,
    onError: (error) => {
      console.error(error);
      if (error.response.status === 404 && selectedTaskType === '') {
        alert.success('Não há mais tarefas para hoje!');
      }
      setTasks([]);
    },
    onSuccess: (data) => {
      if (data.message.length === 0 && selectedTaskType === '') {
        alert.success('Não há tarefas para hoje');
      }
      setTasks(data.message);
    },
  });

  const handleFinishTask = async () => {
    const body = {
      employeeId,
      ilpiId,
      completedAt: convertAPIDate(),
      status: 'completed',
    };

    try {
      await routes.task.createTaskRegistry({ taskId: taskIdToComplete }, body);

      alert.success('Tarefa finalizada com sucesso!');
      queryClient.invalidateQueries('tasks');
    } catch {
      alert.error('Não foi possível finalizar tarefa');
    }
  };

  const handleCancelTask = async () => {
    if (!justification || justification === 'Selecione') {
      alert.warning('Selecione uma justificativa');
    } else {
      const body = {
        employeeId,
        ilpiId,
        completedAt: convertAPIDate(),
        status: 'cancelled',
        justification,
      };

      try {
        await routes.task.createTaskRegistry({ taskId: taskIdToCancel }, body);

        alert.success('Tarefa cancelada com sucesso!');
        queryClient.invalidateQueries('tasks');
      } catch {
        alert.error('Não foi possível cancelar tarefa');
      }
    }
  };

  const handleEvolutionCreatedSuccessfully = () => {
    evolutionModal.onSuccess();
    setEvolutionModal({
      isOpen: false,
      onSuccess: null,
    });
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'flex-start',
        justifyContent: 'center',
        direction: 'column',
        maxWidth: '800px',
        margin: '20px auto 0',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: { xs: 'center', md: 'flex-start' },
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <Typography variant="h5" component="h3" color="#464646" fontWeight={700}>
            Minhas tarefas
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <CalendarMonthOutlined size={24} color="#464646" />
            <Typography variant="body1" component="p" color="#464646">
              {moment().format('ddd, DD [de] MMMM')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 1,
              mt: 1,
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#464646"
              sx={{
                fontSize: 12,
              }}
            >
              Categorias
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '90vw',
                height: '40px',
                maxWidth: '800px',
                overflowX: 'auto',

                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollbarWidth: 'none',
              }}
            >
              {tasksTypes.map((taskType) => (
                <CustomChip
                  key={taskType.value}
                  label={taskType.label}
                  variant="filled"
                  color={selectedTaskType.value === taskType.value ? 'secondary' : 'primary'}
                  onClick={() => setSelectedTaskType({
                    label: taskType.label,
                    value: taskType.value,
                  })}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      </Grid>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '50vh',
          }}
        >
          <CircularProgress color="purple" />
        </Box>
      ) : (
        <RenderTasks
          tasks={tasks}
          isShowCancelModelOn={setIsShowCancelModelOn}
          setTaskIdToCancel={setTaskIdToCancel}
          setIsShowCompleteModelOn={setIsShowCompleteModelOn}
          setTaskIdToComplete={setTaskIdToComplete}
          setCurrentTypeTask={setCurrentTypeTask}
          selectedTaskType={selectedTaskType}
        />
      )}

      <Dialog open={isShowCancelModelOn} onClose={() => setIsShowCancelModelOn(false)}>
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1" component="p" color="#435565" fontWeight="700">
            Recusa de atividade
          </Typography>
          <Typography variant="body2" component="p" color="#848F99">
            Selecione o motivo para a recusa
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <FormControl>
            <RadioGroup
              name="justification"
              onChange={({ target: { value } }) => {
                const validJustification = medicineJustifications.some((element) => element === value)
                  || generalJustifications.some((element) => element === value);
                if (validJustification) setJustification(value);
              }}
              color="success"
            >
              {currentTypeTask === 'medications'
                && medicineJustifications.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio color="purple" />}
                    label={option}
                    labelPlacement="end"
                    color="success"
                  />
                ))}
              {generalJustifications.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio color="purple" />}
                  label={option}
                  labelPlacement="end"
                  color="success"
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <Button
            variant="secondary"
            onClick={() => {
              setCurrentTypeTask(null);
              setTaskIdToComplete(null);
              setIsShowCancelModelOn(false);
              setJustification(null);
            }}
          >
            Voltar
          </Button>

          <Button
            onClick={() => {
              setIsShowCancelModelOn(false);
              handleCancelTask();
            }}
          >
            Recusar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isShowCompleteModelOn} onClose={() => setIsShowCompleteModelOn(false)}>
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1" component="p" color="#435565">
            Finalizar a tarefa
          </Typography>
          <Typography variant="body2" component="p" color="#848F99">
            Deseja marcar a tarefa como finalizada?
          </Typography>
        </DialogTitle>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <Button
            variant="secondary"
            onClick={() => {
              setCurrentTypeTask(null);
              setTaskIdToComplete(null);
              setIsShowCompleteModelOn(false);
            }}
          >
            Voltar
          </Button>

          <Button
            onClick={() => {
              setIsShowCompleteModelOn(false);

              if (currentTypeTask === 'evolution') {
                setEvolutionModal({
                  isOpen: true,
                  onSuccess: handleFinishTask,
                });
              } else {
                handleFinishTask();
              }
            }}
          >
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>

      {evolutionModal.isOpen && (
        <Dialog
          open={evolutionModal}
          onClose={() => setEvolutionModal(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle1" component="p" color="#435565">
              Evolução do residente
            </Typography>
            <Typography variant="body2" component="p" color="#848F99">
              Informe as mudanças observadas no dia de hoje para prosseguir.
            </Typography>
          </DialogTitle>

          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 2,
            }}
          >
            <Button
              variant="secondary"
              onClick={() => {
                setEvolutionModal(false);
                setTaskIdToCancel(null);
                setCurrentTypeTask(null);
              }}
            >
              Voltar
            </Button>

            <CreateEvolution
              dialogInfo={dialogInfo}
              setDialogInfo={setDialogInfo}
              dialogInfoInitialState={dialogInfoInitialState}
              onSuccess={handleEvolutionCreatedSuccessfully}
              taskId={taskIdToComplete}
            />
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
}
