import React from 'react';

export default function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill="#4A397F"
        d="M12.9 3H6.267c-2.875 0-4.6 1.717-4.6 4.6v6.625c0 2.892 1.725 4.608 4.6 4.608h6.625c2.883 0 4.6-1.716 4.6-4.6V7.6C17.5 4.717 15.775 3 12.9 3z"
        opacity="0.4"
      />
      <path
        fill="#4A397F"
        d="M17.516 2.983c-1.491-1.5-2.95-1.533-4.483 0l-.942.934a.304.304 0 00-.075.308 6.248 6.248 0 004.259 4.258c.025.009.066.009.091.009a.322.322 0 00.225-.092l.925-.933c.759-.759 1.134-1.484 1.134-2.225 0-.75-.375-1.492-1.134-2.259zM14.884 9.183a6.66 6.66 0 01-1.134-.666c-.133-.084-.283-.209-.433-.334a.853.853 0 01-.133-.116 5.997 5.997 0 01-.792-.8.636.636 0 01-.108-.142 3.103 3.103 0 01-.317-.425 3.984 3.984 0 01-.3-.458 6.57 6.57 0 01-.333-.634 7.79 7.79 0 01-.267-.658L6.584 9.433c-.292.292-.575.842-.634 1.25l-.358 2.484c-.075.525.067 1.016.392 1.341.275.275.65.417 1.066.417.092 0 .184-.008.275-.017l2.475-.35c.409-.058.959-.333 1.25-.633l4.484-4.483c-.209-.067-.417-.159-.65-.259z"
      />
    </svg>
  );
}
