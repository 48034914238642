import React, { useRef, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import BenvoTooltip from '../../../../../../../../components/BenvoTooltip';
import {
  ButtonAndQuantityContainer,
  ButtonAndQuantityContainerView,
  ManualQuantityInput,
  ShoppingListItemContainer,
  StyledIconButton,
} from './style';
import MinusSquare from '../../../../../../../../assets/residents/MinusSquare';
import AddSquare from '../../../../../../../../assets/residents/AddSquare';
import TrashIcon from '../../../../../../../../assets/residents/TrashIcon';
import { ReactComponent as InfoIcon } from '../../../../../../../../assets/icons/info-circle-red.svg';

export default function ResidentShoppingListItem({
  product,
  setSelectedProductsList,
  isVisualization,
}) {
  const initialRawQuantityRef = useRef(product.defaultRawQuantity || product.rawQuantity);
  const [rawQuantityInput, setRawQuantityInput] = useState(product.rawQuantity || '');
  const handleIncrementQuantityProduct = () => {
    setSelectedProductsList((prevProductsList) => {
      const isProductAlreadySelected = prevProductsList.find(
        (selectedProduct) => selectedProduct.externalId === product.externalId,
      );

      if (isProductAlreadySelected) {
        return prevProductsList.map((selectedProduct) => {
          if (selectedProduct.externalId === product.externalId) {
            const newQuantity = selectedProduct.quantity + 1;
            const newRawQuantity = initialRawQuantityRef.current
              ? newQuantity * initialRawQuantityRef.current
              : null;
            return {
              ...selectedProduct,
              quantity: newQuantity,
              rawQuantity: newRawQuantity,
            };
          }
          return selectedProduct;
        });
      }
      return [
        ...prevProductsList,
        {
          name: product.name,
          quantity: 1,
          externalId: product.externalId,
          rawQuantity: initialRawQuantityRef.current ? initialRawQuantityRef.current : null,
        },
      ];
    });
  };

  const handleDecrementQuantityProduct = () => {
    setSelectedProductsList((prevProductsList) => {
      const isProductAlreadySelected = prevProductsList.find(
        (selectedProduct) => selectedProduct.externalId === product.externalId,
      );

      if (isProductAlreadySelected) {
        return prevProductsList.map((selectedProduct) => {
          if (selectedProduct.externalId === product.externalId) {
            const newQuantity = selectedProduct.quantity - 1;
            const newRawQuantity = initialRawQuantityRef.current && newQuantity > 0
              ? Math.max(0, newQuantity * initialRawQuantityRef.current)
              : null;
            return {
              ...selectedProduct,
              quantity: newQuantity,
              rawQuantity: newRawQuantity,
            };
          }
          return selectedProduct;
        });
      }
      return prevProductsList;
    });
  };

  const handleRawQuantityInputChange = (event) => {
    const { value } = event.target;
    if (value !== '' && Number.isNaN(Number(value))) {
      return;
    }

    setRawQuantityInput(value);

    const rawQuantity = value === '' ? '' : Number(value);

    setSelectedProductsList((prevProductsList) => {
      const isProductAlreadySelected = prevProductsList.find(
        (selectedProduct) => selectedProduct.externalId === product.externalId,
      );

      if (isProductAlreadySelected) {
        return prevProductsList.map((selectedProduct) => {
          if (selectedProduct.externalId === product.externalId) {
            return {
              ...selectedProduct,
              quantity: value === '' ? 0 : Math.max(0, Math.ceil(rawQuantity / initialRawQuantityRef.current)),
              rawQuantity,
            };
          }
          return selectedProduct;
        });
      }
      return prevProductsList;
    });
  };

  const handleBlur = () => {
    if (rawQuantityInput === '') {
      setSelectedProductsList((prevProductsList) => prevProductsList.map((selectedProduct) => {
        if (selectedProduct.externalId === product.externalId) {
          return {
            ...selectedProduct,
            quantity: 0,
            rawQuantity: 0,
          };
        }
        return selectedProduct;
      }));
    }
  };

  const handleDeleteProduct = () => {
    setSelectedProductsList((prevProductsList) => prevProductsList.filter(
      (selectedProduct) => selectedProduct.externalId !== product.externalId,
    ));
  };

  const notFound = product.found === false && !product.manuallyEntered;

  return (
    <ShoppingListItemContainer>
      <Typography variant="h6">{product.name}</Typography>
      {!isVisualization ? (
        <ButtonAndQuantityContainer>

          {(product.rawQuantity >= 0 && product.rawQuantity !== null) && !notFound && (
            <ManualQuantityInput
              type="number"
              value={product.rawQuantity}
              onChange={handleRawQuantityInputChange}
              onBlur={handleBlur}
              min={0}
            />
          )}

          <div>
            {(product.quantity === 0 || notFound) && (
            <IconButton onClick={handleDeleteProduct}>
              <TrashIcon />
            </IconButton>
            )}

            {!notFound && (
            <StyledIconButton
              isActive={product.quantity > 0}
              disabled={product.quantity === 0}
              onClick={handleDecrementQuantityProduct}
            >
              <MinusSquare />
            </StyledIconButton>
            )}

            <Typography variant="body1">{product.quantity}</Typography>

            {notFound && (
            <BenvoTooltip arrow title="Item descontinuado" placement="top">
              <InfoIcon />
            </BenvoTooltip>
            )}

            {!notFound && (
            <IconButton onClick={handleIncrementQuantityProduct}>
              <AddSquare />
            </IconButton>
            )}
          </div>

        </ButtonAndQuantityContainer>
      ) : (
        <ButtonAndQuantityContainerView>
          <div>
            { product.rawQuantity > 0 && <Typography variant="body1">{`${product.rawQuantity} cps`}</Typography>}
          </div>
          <Typography variant="body1">{`${product.quantity} un`}</Typography>
        </ButtonAndQuantityContainerView>
      )}
    </ShoppingListItemContainer>
  );
}
