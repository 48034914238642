import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ufOptions } from '../../../../../constants';
import { cepInputMask, removeMask } from '../../../../../utils/inputMasks';
import { get } from '../../../../../services/ApiRequest';
import {
  StyledMenuItem, StyledSelect, StyledTextField,
} from '../style';
import BenvoDropdown from '../../../../../components/BenvoDropdown';

export default function IlpiAdressForm({
  watch,
  register,
  setValue,
  errors,
}) {
  const [isCepChanged, setIsCepChanged] = useState(false);

  const fetchCEPData = async (cep) => {
    const cleanCep = removeMask(cep);
    const url = `https://viacep.com.br/ws/${cleanCep}/json/`;
    try {
      const data = await get(url);
      if (data.erro) {
        alert.error(
          'CEP não encontrado, favor verificar se o mesmo está correto.',
        );
      }
      return {
        state: setValue('state', data.uf || ''),
        city: setValue('city', data.localidade || ''),
        district: setValue('district', data.bairro || ''),
        street: setValue('street', data.logradouro || ''),
        number: setValue('number', ''),
        complement: setValue('complement', ''),
        reference: setValue('reference', ''),
      };
    } catch (error) {
      return {
        state: setValue('state', ''),
        city: setValue('city', ''),
        district: setValue('district', ''),
        street: setValue('street', ''),
        number: setValue('number', ''),
        complement: setValue('complement', ''),
        reference: setValue('reference', ''),
      };
    }
  };

  useQuery({
    queryKey: ['zipCodeEdit', watch('zipCode')],
    queryFn: () => fetchCEPData(watch('zipCode')),
    enabled: isCepChanged,
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const handleCepChange = ({ target: { value } }) => {
    setValue('zipCode', cepInputMask(value));

    if (value.length === 9) {
      setIsCepChanged(true);
    } else {
      setIsCepChanged(false);
    }
  };

  const addressErros = {
    ...errors.zipCode,
    ...errors.state,
    ...errors.city,
    ...errors.district,
    ...errors.street,
    ...errors.number,
    ...errors.complement,
    ...errors.reference,
  };

  return (
    <BenvoDropdown title="Endereço" error={addressErros}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="CEP"
            error={!!errors.zipCode}
            helperText={errors.zipCode?.message}
            value={cepInputMask(watch('zipCode')) || ''}
            onChange={handleCepChange}
            inputProps={{
              maxLength: 9,
            }}
          />
        </Grid>

        <Grid item xs={7}>
          <StyledSelect
            select
            variant="outlined"
            size="small"
            fullWidth
            data-testid="state"
            label="Estado"
            error={!!errors.state}
            helperText={errors.state?.message}
            value={watch('state') || ''}
            {...register('state')}
          >
            {ufOptions.map((option) => (
              <StyledMenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </Grid>

        <Grid item xs={6}>
          <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Cidade"
            error={!!errors.city}
            helperText={errors.city?.message}
            {...register('city')}
          />
        </Grid>

        <Grid item xs={6}>
          <StyledTextField
            id="name"
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Bairro"
            error={!!errors.district}
            helperText={errors.district?.message}
            {...register('district')}
          />
        </Grid>

        <Grid item xs={9}>
          <StyledTextField
            id="name"
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Logradouro"
            error={!!errors.street}
            helperText={errors.street?.message}
            {...register('street')}
          />
        </Grid>

        <Grid item xs={3}>
          <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Número"
            error={!!errors.number}
            helperText={errors.number?.message}
            {...register('number')}
          />
        </Grid>

        <Grid item xs={6}>
          <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Complemento"
            error={!!errors.complement}
            helperText={errors.complement?.message}
            {...register('complement')}
          />
        </Grid>

        <Grid item xs={6}>
          <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Referência"
            error={!!errors.reference}
            helperText={errors.reference?.message}
            {...register('reference')}
          />
        </Grid>
      </Grid>
    </BenvoDropdown>
  );
}
