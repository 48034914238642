import { Box, styled } from '@mui/material';

export const ActionsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 16px 12px;

    @media (min-width: 1100px) {
        flex-direction: row;
        align-items: center;
    }
`;

export const SearchAndFilterContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const ButtonsContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const UsersContainer = styled(Box)`
    display: flex;
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    width: 100%;
`;
