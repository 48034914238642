import React, { useRef } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import {
  ActionsWithoutFamiliarContainer, DividerContainer, LinkContainer,
  StyledIconButton,
} from './style';
import Copy from '../../../../assets/shoppingList/copy';
import BenvoTooltip from '../../../../components/BenvoTooltip';
import { useAlert } from '../../../../context/alertContext';

export default function SharebleLink({ orderInformation }) {
  const shareableLink = useRef(null);
  const alert = useAlert();

  const handleCopyLink = () => {
    const link = shareableLink.current.innerText;

    navigator.clipboard.writeText(link);
    alert.success('Link copiado com sucesso!');
  };
  return (
    <ActionsWithoutFamiliarContainer>
      <DividerContainer>
        <Typography variant="body1">ou</Typography>
      </DividerContainer>
      <Typography variant="h6">Envie o link para o familiar diretamente:</Typography>
      <LinkContainer>
        <Box ref={shareableLink}>
          {`${orderInformation.orderUrl}/v`}
          <BenvoTooltip title="Copiar link" arrow placement="top">
            <StyledIconButton
              onClick={handleCopyLink}
            >
              <Copy />
            </StyledIconButton>
          </BenvoTooltip>
        </Box>
      </LinkContainer>
    </ActionsWithoutFamiliarContainer>
  );
}
