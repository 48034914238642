import React from 'react';
import OrderHistoryListItem from './components/OrderHistoryListItem';
import DynamicScroll from '../../../../../../components/DynamicScroll';

export default function OrderHistoryList({
  orderHistory, residentInformations, loading, finished, onScrollEnd, refetchOrderHistory,
}) {
  return (
    <DynamicScroll
      onScrollEnd={onScrollEnd}
      loading={loading}
      finished={finished}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        maxHeight: '100%',
        overflowY: 'auto',
      }}
    >
      {orderHistory?.map((order) => (
        <OrderHistoryListItem
          key={order.id}
          order={order}
          residentInformations={residentInformations}
          refetchOrderHistory={refetchOrderHistory}
        />
      ))}
    </DynamicScroll>
  );
}
