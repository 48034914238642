import { CircularProgress, Box } from '@mui/material';
import React, { useState } from 'react';

const RANGE = 10;

export default function DynamicScroll({
  children, loading, finished, onScrollEnd, sx,
}) {
  const [running, setRunning] = useState(false);
  const isInRange = (valueOne, valueTwo, valueToCompare) => (valueOne - valueTwo) <= valueToCompare + RANGE;

  const handleScroll = async ({ target }) => {
    if (!finished && !loading && onScrollEnd && !running) {
      const { scrollTop, scrollHeight, clientHeight } = target;
      if (isInRange(scrollHeight, scrollTop, clientHeight)) {
        setRunning(true);
        await onScrollEnd();
      }
    }
    if (!finished && !loading && onScrollEnd && running) {
      setRunning(false);
    }
  };

  return (
    <Box
      onScroll={handleScroll}
      sx={{
        overflow: 'auto',
        flexGrow: 1,
        width: '100%',
        ...sx,
      }}
    >
      {children}
      {loading && (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '70px',
        }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
