import {
  Button, Grid, Modal, Paper, Stack, Typography,
} from '@mui/material';
import React from 'react';

function ConfirmationInput({
  title,
  message,
  AcceptMessage,
  denialMessage,
  onDenial,
  onAccept,
  onClose,
  isOpen,
}) {
  const handleDenial = () => {
    if (onDenial) onDenial();
    onClose();
  };

  const handleAccept = () => {
    if (onAccept) onAccept();
    onClose();
  };

  return (
    <Modal open={isOpen} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper sx={{ padding: 4, margin: 10 }}>
        <Grid container>
          <Grid item xs={12} sx={{ paddingY: 4 }}>
            {title && (
              <Typography
                variant="h1"
                color="primary"
                fontWeight="bold"
                sx={{ fontSize: '1.5rem' }}
              >
                {title}
              </Typography>
            )}

            <Typography variant="h2" sx={{ fontSize: '1.2rem', marginY: 2 }}>
              {message}
            </Typography>
          </Grid>

          <Stack direction="row" spacing={6} sx={{ justifyContent: 'center', width: '100%' }}>
            <Button
              onClick={handleDenial}
              color="cancel"
              data-testid="confirmation-input-denial-button"
            >
              {denialMessage || 'Não'}
            </Button>

            <Button
              onClick={handleAccept}
              variant="contained"
              color="primary"
              data-testid="confirmation-input-accept-button"
            >
              {AcceptMessage || 'Sim'}
            </Button>
          </Stack>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default ConfirmationInput;
