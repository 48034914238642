export default {
  login: '/login',
  changePassword: '/alterar-senha',
  forgotPassword: '/esqueci-senha',
  resetPassword: '/reset-password/:token',
  home: '/home',
  marketplace: '/marketplace',
  orders: '/pedidos',
  prescriptions: '/prescricoes',
  evolutions: '/evolucoes',
  accommodations: '/hospedagens',
  shoppingList: '/lista-mensal',
  residents: {
    root: '/residentes',
    orderHistory: 'historico-de-listas',
    shoppingList: 'lista-de-compras',
  },
  schedule: '/agenda',
  recipes: '/receitas',
  collaborators: '/profissionais',
  employees: '/funcionarios',
  doctors: '/medicos',
  tasks: '/tarefas',
  exams: {
    root: '/exames',
    requests: '/exames/solicitar-servico',
  },
  tasksRegistry: '/registro-de-tarefas',
  public: {
    shoppingList: '/public/lista-mensal/:listId',
    doctorLP: '/public/ilpi/:ilpiId/doctor/:doctorId/:type',
    certificateChecker: '/public/certificate/:fileId',
    privacyPolicy: '/public/politica-de-privacidade',
    termsAndConditions: '/public/termos-e-condicoes',
  },
  auth: {
    firstAccess: '/primeiro-acesso',
    recoverPassword: '/recuperar-senha',
    sendEmail: '/enviar-email',
    redefinePassword: '/redefinir-senha',
  },
  superadmin: {
    root: '/superadmin',
    home: '/superadmin/home',
    ilpis: '/superadmin/ilpis',
    marketplace: '/superadmin/marketplace',
    sellers: '/superadmin/fornecedores',
  },
  caregiver: {
    home: '/cuidador/home',
  },
  terms: '/termos-de-uso',
  impersonate: '/public/personificar/:jwt',
};
