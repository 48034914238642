import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import routes from '../../../services/routes';

import { removeMask } from '../../../utils/inputMasks';
import { useAlert } from '../../../context/alertContext';
import { useDoctorLP } from '../../../context/doctorLPContext';
import { ufOptions } from '../../../constants';

import alertMapper from '../../../utils/alertMapper';
import BenvoPrimaryButton from '../../../components/BenvoPrimaryButton';
import BenvoTextField from '../../../components/BenvoTextField';
import {
  StyledForm, StyledBoxInputs, StyledSelect, StyledBoxLogo,
} from '../styles';
import benvo from '../../../assets/benvo-txt.svg';
import benvoIcon from '../../../assets/benvo-icon.png';

function DoctorLogin() {
  const alert = useAlert();
  const { ilpiId, doctorId } = useParams();
  const doctorLP = useDoctorLP();

  const [crm, setCrm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uf, setUf] = useState('');

  const handleChange = ({ target }) => {
    setCrm(removeMask(target.value) || '');
  };

  const handleSuccessRealDoctor = (doctor) => {
    alert.success('Obrigado! Você será redirecionado em alguns segundos!');
    setTimeout(() => {
      doctorLP.setDoctor(doctor);
    }, alert.autoHideoutTime);
  };

  const checkDoctorCrm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const crmWithUf = `${crm}${uf.value}`;
      const { message } = await routes.publicRoutes.checkProfessionalIdentification({
        ilpiId,
        collaboratorId: doctorId,
        professionalIdentification: crmWithUf,
      });
      handleSuccessRealDoctor(message);
    } catch (error) {
      if (error.response?.status === 401) {
        alert.error('Não foi possível verificar seu CRM, por favor verifique a digitação.');
      } else alert[alertMapper(error.response?.status)]();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledForm
      action=""
      onSubmit={checkDoctorCrm}
      data-testid="doctor-login-form"
    >
      <StyledBoxLogo>
        <img
          src={benvoIcon}
          alt="benvo-logo"
          style={{ width: '40px', height: '40px' }}
        />
        <img
          src={benvo}
          alt="benvo-text-logo"
          style={{ width: '151px', height: '21px' }}
        />
      </StyledBoxLogo>

      <StyledBoxInputs>
        <BenvoTextField
          name="checkCrm"
          label="CRM"
          value={crm}
          onChange={handleChange}
          size="small"
          spellCheck="false"
          fullWidth
          inputProps={{
            'data-testid': 'check-crm',
          }}
        />
        <FormControl sx={{ width: '150px' }} size="small">
          <InputLabel htmlFor="uf">UF</InputLabel>
          <StyledSelect
            id="uf"
            onChange={(e) => setUf(e.target.value)}
            data-testid="check-uf"
            value={uf}
          >
            {ufOptions.map((option) => (
              <MenuItem key={option.value} value={option} data-testid={`select-${option.value}`}>
                {option.value}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </StyledBoxInputs>
      <BenvoPrimaryButton type="submit" sx={{ width: '160px' }} data-testid="access-button">
        {isLoading ? <CircularProgress size={24.5} color="white" /> : 'Acessar'}
      </BenvoPrimaryButton>
    </StyledForm>
  );
}

export default DoctorLogin;
