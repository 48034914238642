import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';

function FLVTab({ formValues, handleChange, handleFormItems }) {
  return (
    <>
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          label="Produto"
          name="item"
          value={formValues.item}
          onChange={({ target }) => handleFormItems({ label: target.value })}
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }}>
          <TextField
            id="quantity"
            name="quantity"
            type="number"
            label="Quantidade"
            value={formValues.quantity}
            onChange={({ target }) => handleChange(target)}
            fullWidth
            size="small"
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <FormControl fullWidth>
          <InputLabel id="measure">Medida</InputLabel>
          <Select
            fullWidth
            labelId="measure"
            label="Medida"
            name="measure"
            value={formValues.measure}
            onChange={({ target }) => handleChange(target)}
            size="small"
          >
            <MenuItem value="und">Und</MenuItem>
            <MenuItem value="kg">Kg</MenuItem>
            <MenuItem value="g">G</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export default FLVTab;
