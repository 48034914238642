import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import EyeIconOpen from '../../assets/icons/EyeIconOpen';
import EyeIconClose from '../../assets/icons/EyeIconClose';
import {
  Container, FormInputsContainer, StyledButton, StyledForm, StyledTexField,
} from './style';
import schemas from '../../schemas';
import PATH from '../../constants/applicationPaths';
import routes from '../../services/routes';
import { useAlert } from '../../context/alertContext';

export default function RedefinePassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
    resolver: joiResolver(schemas.firstAccess.firstAccessSchema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { publicRoutes } = routes;

  const navigate = useNavigate();
  const alert = useAlert();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const redefinePassword = async (data) => {
    try {
      await publicRoutes.resetPassword({
        code,
        password: data.password,
      });
      alert.success('Senha redefinida!');
      setTimeout(() => {
        navigate(PATH.login);
      }, 2000);
    } catch (error) {
      alert.error('Link expirado ou já utilizado. Por favor, solicite um novo link.');
    }
  };

  return (
    <Container>
      <h3>
        <span>Recuperar senha.</span>
        <br />
        Defina uma senha.
      </h3>

      <StyledForm onSubmit={handleSubmit(redefinePassword)}>
        <FormInputsContainer>
          <StyledTexField
            name="password"
            placeholder="Senha"
            size="small"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            error={errors.password}
            helperText={errors.password?.message}
            {...register('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    sx={{
                      '& .MuiIconButton-root': {
                        padding: 1,
                      },
                    }}
                  >
                    {showPassword ? (
                      <EyeIconOpen
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        color="#474750"
                      />
                    ) : (
                      <EyeIconClose
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        color="#474750"
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <StyledTexField
            name="repeatPassword"
            placeholder="Confirme a senha"
            size="small"
            fullWidth
            type={showRepeatPassword ? 'text' : 'password'}
            error={errors.repeatPassword}
            helperText={errors.repeatPassword?.message}
            {...register('repeatPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowRepeatPassword}
                    sx={{
                      '& .MuiIconButton-root': {
                        padding: 1,
                      },
                    }}
                  >
                    {showRepeatPassword ? (
                      <EyeIconOpen
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        color="#474750"
                      />
                    ) : (
                      <EyeIconClose
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        color="#474750"
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormInputsContainer>

        <StyledButton
          disabled={isSubmitting}
          type="submit"
        >
          Redefinir senha
        </StyledButton>
      </StyledForm>
    </Container>
  );
}
