import { Grid, styled } from '@mui/material';

export const StyledGridEmail = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledUl = styled('ul')`
  width: 50;
  max-height: 120px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  overflow: auto;
`;

export const StyledLi = styled('li')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
  background-color: ${(props) => (props.index % 2 === 0 ? '#f5f5f5' : '#fff')};
  border-radius: 8px;
`;
