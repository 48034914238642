/* eslint-disable max-len */
import React from 'react';

export default function ShopIcon({ width = 24, height = 25, color = '#474750' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="shop">
        <g id="vuesax/linear/shop">
          <g id="shop_2">
            <path id="Vector" d="M3.01001 11.72V16.21C3.01001 20.7 4.81001 22.5 9.30001 22.5H14.69C19.18 22.5 20.98 20.7 20.98 16.21V11.72" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_2" d="M12 12.5C13.83 12.5 15.18 11.01 15 9.18L14.34 2.5H9.66999L8.99999 9.18C8.81999 11.01 10.17 12.5 12 12.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_3" d="M18.31 12.5C20.33 12.5 21.81 10.86 21.61 8.85L21.33 6.1C20.97 3.5 19.97 2.5 17.35 2.5H14.3L15 9.51C15.17 11.16 16.66 12.5 18.31 12.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_4" d="M5.63988 12.5C7.28988 12.5 8.77988 11.16 8.93988 9.51L9.15988 7.3L9.63988 2.5H6.58988C3.96988 2.5 2.96988 3.5 2.60988 6.1L2.33988 8.85C2.13988 10.86 3.61988 12.5 5.63988 12.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_5" d="M12 17.5C10.33 17.5 9.5 18.33 9.5 20V22.5H14.5V20C14.5 18.33 13.67 17.5 12 17.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
      </g>
    </svg>

  );
}
