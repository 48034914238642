import {
  Box, Checkbox, IconButton, Typography,
} from '@mui/material';
import React from 'react';
import { RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined } from '@mui/icons-material';
import { ReactComponent as SearchArrowLeft } from '../../../../../assets/icons/square-arrow-left-icon.svg';

export default function SelectItens({
  handleReset, marketplaceList, setProductsList, productsList,
}) {
  const mainItems = marketplaceList?.rows?.find((item) => item.isMain === 1) || { itemList: [] };

  const handleCheckboxChange = (item) => {
    setProductsList((prevProductsList) => {
      const itemWithoutId = { ...item };
      delete itemWithoutId.id;
      const itemIndex = prevProductsList.itemList.findIndex((i) => i.name === item.name);
      if (itemIndex === -1) {
        return {
          ...prevProductsList,
          itemList: [...prevProductsList.itemList, itemWithoutId],
        };
      }
      const updatedItemList = [...prevProductsList.itemList];
      updatedItemList.splice(itemIndex, 1);
      return {
        ...prevProductsList,
        itemList: updatedItemList,
      };
    });
  };

  const isItemChecked = (item) => productsList.itemList.some((i) => i.name === item.name);

  return (
    <Box
      sx={{
        alignSelf: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#F5F5F7',
          borderRadius: '8px',
          width: '100%',
          px: '13px',
          py: '5px',
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          mb: '22px',
        }}
      >
        <IconButton
          onClick={handleReset}
        >
          <SearchArrowLeft data-testid="arrow-left-icon" />
        </IconButton>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#474750',
          }}
        >
          {productsList.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          mb: '22px',
        }}
      >
        <Typography
          fontWeight="500"
          sx={{
            fontSize: '16px',
            color: '#474750',
          }}
        >
          Selecione os itens
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#474750',
          }}
        >
          Os itens escolhidos serão fornecidos na quantidade selecionada, de acordo com a lista escolhida como modelo.
        </Typography>
      </Box>
      <Box
        sx={{
          mt: '12px',
          borderRadius: '8px',
          overflow: 'hidden',
          maxHeight: '308px',
          overflowY: 'auto',

          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
            backgroundColor: '#F5F5F7',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#4A397F',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F5F5F7',
          },
        }}
      >
        {mainItems.itemList?.map((item) => (
          <Box
            data-testid="main-items-card"
            key={item.id}
            onClick={() => handleCheckboxChange(item)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#fff',
              padding: '12px',
              '&:nth-of-type(odd)': {
                backgroundColor: '#F5F5F7',
              },
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Typography
                variant="subtitle1"
                color="#474750"
                sx={{
                  fontSize: '12px',
                  lineHeight: '170%',
                  maxWidth: '300px',
                }}
              >
                {item.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Checkbox
                icon={<RadioButtonUncheckedOutlined />}
                checkedIcon={<RadioButtonCheckedOutlined />}
                checked={isItemChecked(item)}
                sx={{
                  color: '#D4D4DA',
                  '&.Mui-checked': {
                    color: '#4A397F',
                  },
                }}
              />
            </Box>
          </Box>
        ))}

      </Box>
    </Box>
  );
}
