import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';

import routes from '../../../services/routes';
import { getTokenInfo } from '../../../utils/configAuth';
import { useAlert } from '../../../context/alertContext';

const RecipeContext = createContext([]);

export const useRecipe = () => {
  const context = useContext(RecipeContext);

  if (!context) {
    throw new Error('usePrice must be used within a PriceProvider');
  } else {
    return context;
  }
};

export function RecipeProvider({ children }) {
  const ilpiId = getTokenInfo('ilpiId');
  const { recipe } = routes;
  const alert = useAlert();

  const INITIAL_VALUES = {
    name: '',
    status: '',
    recipeType: '',
    page: 0,
  };

  const [listRecipe, setListRecipe] = useState([]);
  const [pagination, setPagination] = useState({});
  const [searchParams, setSearchParams] = useState(INITIAL_VALUES);
  const [isLoading, setIsLoading] = useState(false);
  const [selectDropdownStatus] = useState([
    {
      label: 'Todos',
      value: '',
    },
    {
      label: 'Não Enviado',
      value: 'notSent',
    },
    {
      label: 'Enviado',
      value: 'sent',
    },
    {
      label: 'Aprovado',
      value: 'approved',
    },
    {
      label: 'Não aprovado',
      value: 'unapproved',
    },
  ]);

  const [selectDropdownRecipeType] = useState([
    {
      label: 'Todos',
      value: '',
    },
    {
      label: 'Regular',
      value: 'REGULAR',
    },
    {
      label: 'C1',
      value: 'C1',
    },
    {
      label: 'AB',
      value: 'AB',
    },
  ]);

  const consultRecipe = async (queries) => {
    setIsLoading(true);
    try {
      const { message } = await recipe.searchRecipesByIlpi({ ilpiId }, queries);
      const { rows, totalPages } = message;
      if (!rows.length) alert.info('Não foram encontradas receitas');
      setListRecipe(rows);
      setPagination(totalPages);
    } catch (error) {
      console.error('recipeContext', error);
      alert.info('Não foram encontradas receitas');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteRecipe = async (recipeId) => {
    try {
      await recipe.removeRecipe({ recipeId });
      consultRecipe(searchParams);
      alert.success('Receita removida com sucesso');
    } catch (error) {
      console.error('Recipe', error);
      alert.error('Houve um erro ao tentar excluir a receita, tente novamente mais tarde');
    }
  };

  useEffect(() => {
    consultRecipe(INITIAL_VALUES);
  }, []);

  const addNewRecipe = async (payload) => {
    const response = await recipe.createRecipe(payload);
    consultRecipe(searchParams);

    return response;
  };

  const handleSubmit = () => {
    consultRecipe(searchParams);
  };

  const handlePagination = (page) => {
    const params = {
      ...searchParams,
      page: page - 1,
    };
    setSearchParams(params);
    consultRecipe(params);
  };

  const getDoctorIdsFromRecipes = (array) => {
    const doctorIds = array.map((thisRecipe) => thisRecipe.doctorId);

    return doctorIds;
  };

  const sendUnsentRecipes = async () => {
    try {
      const { message } = await recipe.findAllRecipesByIlpi({ ilpiId });
      const getUniqueDoctorIds = getDoctorIdsFromRecipes(message.rows);
      const doctorIds = Array.from(getUniqueDoctorIds);
      const send = doctorIds.map(async (doctorId) => recipe.sendRecipeToDoctor({ ilpiId, doctorId }));
      await Promise.all(send);
      alert.success('Receitas enviadas com sucesso para o(s) e-mail(s) do(s) médico(s)!');
    } catch (error) {
      const { response } = error;
      if (response.status === 404) {
        alert.info('Não existem receitas pendentes de envio');
      } else {
        alert.error('Ocorreu um erro ao processar sua solicitação, tente novamente mais tarde!');
      }
      console.log('Recipe', response);
    } finally {
      handleSubmit();
    }
  };

  const getPDFLink = async (recipeId) => {
    const { message } = await recipe.getPDFLink({ recipeId });
    window.open(message, '_blank');
  };

  const value = useMemo(() => ({
    addNewRecipe,
    listRecipe,
    pagination,
    handlePagination,
    searchParams,
    setSearchParams,
    handleSubmit,
    selectDropdownStatus,
    selectDropdownRecipeType,
    deleteRecipe,
    isLoading,
    sendUnsentRecipes,
    getPDFLink,
  }));

  return (
    <RecipeContext.Provider value={value}>{children}</RecipeContext.Provider>
  );
}
