import React from 'react';

import { useLocation } from 'react-router';
import benvoLogo from '../../assets/terms/logo-benvo.webp';
import { PageContainer } from './style';

export default function TermsPage() {
  const width = window.innerWidth;
  const path = useLocation();
  const route = path.pathname.split('/').pop();

  return (
    <PageContainer>
      <img src={benvoLogo} alt="logo da benvo" />
      {width <= 800 ? (
        <iframe
          title="Termos de Uso"
          src={`https://drive.google.com/viewerng/viewer?embedded=true&url=https://benvo.co/${route}`}
        />
      ) : (
        <iframe
          title="Termos de Uso"
          type="application/pdf"
          src={`https://benvo.co/${route}`}
        />
      )}

    </PageContainer>
  );
}
