import { Grid } from '@mui/material';
import React from 'react';

import { Controller } from 'react-hook-form';
import { phoneInputMask } from '../../../../../utils/inputMasks';
import { StyledTextField } from '../style';
import BenvoDropdown from '../../../../../components/BenvoDropdown';
import { StyledTellInput } from './style';

export default function IlpiContactForm({
  watch,
  register,
  errors,
  control,
}) {
  const contactErros = {
    ...errors.phoneNumber,
    ...errors.landline,
    ...errors.email,
  };

  return (
    <BenvoDropdown title="Contato" error={contactErros}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <StyledTellInput
                {...field}
                fullWidth
                placeholder="Telefone"
                langOfCountryName="pt-br"
                defaultCountry="BR"
                preferredCountries={['BR', 'PT']}
                helperText={errors.phoneNumber?.message}
                error={!!errors.phoneNumber}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Telefone Fixo"
            error={!!errors.landline}
            helperText={errors.landline?.message}
            value={phoneInputMask(watch('landline')) || ''}
            {...register('landline')}
            inputProps={{
              maxLength: 14,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Email"
            error={!!errors.email}
            helperText={errors.email?.message}
            value={watch('email') || ''}
            {...register('email')}
          />
        </Grid>
      </Grid>
    </BenvoDropdown>
  );
}
