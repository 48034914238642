import React from 'react';
import { Pagination } from '@mui/material';
import Loading from '../../components/Loading/Loading';
import ContainerPage from '../../templates/containerPage/ContainerPage';
import TableRecipe from './components/TableRecipe';
import { RecipeProvider, useRecipe } from './context/recipeContext';

function Recipe() {
  const {
    pagination,
    handlePagination,
    isLoading,
  } = useRecipe();

  return (
    <RecipeProvider>
      <ContainerPage
        title="Receita de medicamentos"
        description="Aqui você pode visualizar as receitas"
      >
        {isLoading ? <Loading /> : <TableRecipe />}
        <br />
        <Pagination
          count={pagination}
          color="primary"
          onChange={(e, page) => handlePagination(page)}
          sx={{
            '.MuiPagination-ul': {
              justifyContent: 'flex-end',
            },
          }}
        />
      </ContainerPage>
    </RecipeProvider>

  );
}

export default Recipe;
