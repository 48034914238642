/* eslint-disable max-len */
import React from 'react';

export default function HomeIconActive({ width = 24, height = 25, color = '#4A397F' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="category-2">
        <g id="vuesax/bold/home">
          <g id="home">
            <path id="Vector" d="M20.83 8.41657L14.28 3.17657C13 2.15657 11 2.14657 9.72996 3.16657L3.17996 8.41657C2.23996 9.16657 1.66996 10.6666 1.86996 11.8466L3.12996 19.3866C3.41996 21.0766 4.98996 22.4066 6.69996 22.4066H17.3C18.99 22.4066 20.59 21.0466 20.88 19.3766L22.14 11.8366C22.32 10.6666 21.75 9.16657 20.83 8.41657ZM12.75 18.4066C12.75 18.8166 12.41 19.1566 12 19.1566C11.59 19.1566 11.25 18.8166 11.25 18.4066V15.4066C11.25 14.9966 11.59 14.6566 12 14.6566C12.41 14.6566 12.75 14.9966 12.75 15.4066V18.4066Z" fill={color} />
          </g>
        </g>
      </g>
    </svg>

  );
}
