import React, { useState } from 'react';
import {
  Box, CircularProgress, Grid, IconButton, Skeleton, TextField, Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { formatPhraseToLowerCaseWithFirstLetterUpperCase } from '../../../../utils/formatStrings';
import routes from '../../../../services/routes';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-button-icon.svg';
import { ReactComponent as MinusIcon } from '../../../../assets/icons/minus-button-icon.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus-button-icon.svg';
import { ReactComponent as ArrowLeftSquare } from '../../../../assets/icons/square-arrow-left-secondary-icon.svg';
import CreateMarketplaceList from '../InsertItems';
import GenerateQuotation from '../GenerateQuotation';
import BenvoImage from '../../../../components/BenvoImage';
import { useAlert } from '../../../../context/alertContext';

export default function EditMarketplaceList() {
  const alert = useAlert();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: listId } = useParams();

  const [loading, setLoading] = useState(false);

  const { marketplace } = routes;

  const getMarketplaceList = async () => {
    const { message } = await marketplace.getMarketplaceListById({ listId });
    return message;
  };

  const {
    data: marketPlaceData = [],
    refetch: refetchMarketplaceList,
    isLoading,
  } = useQuery({
    queryKey: ['marketplaceList'],
    queryFn: getMarketplaceList,
    retry: 0,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onError: () => {
      alert.error('Erro ao carregar lista de compras.');
      navigate('/marketplace');
    },
  });

  const addProductToMarketplaceList = async (productList) => {
    try {
      const response = await marketplace.addProductToMarketplaceList({ listId }, productList);
      refetchMarketplaceList();
      return response;
    } catch (error) {
      console.log(error);
      return alert.error('Erro ao atualizar a lista.');
    }
  };

  const deleteMarketplaceListItem = async (itemId) => {
    try {
      const response = await marketplace.deleteProductToMarketplaceList({ listId, itemId });
      return response;
    } catch (error) {
      console.log(error);
      return alert.error('Erro ao excluir item da lista.');
    }
  };

  const updateMarketplaceList = async (thisMarketPlaceData) => {
    setLoading(true);

    const { name, itemList } = thisMarketPlaceData || marketPlaceData;
    const currentList = {
      name,
      itemList: itemList.map(({ image, ...item }) => item),
    };

    try {
      await marketplace.updateMarketplaceList({ listId }, currentList);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleRemoveFromMarketplaceList = async (item) => {
    queryClient.setQueryData(['marketplaceList'], (prevData) => {
      const newList = {
        ...prevData,
        itemList: prevData.itemList.filter((i) => i.name !== item.name),
      };
      deleteMarketplaceListItem(item.id);
      return newList;
    });
  };

  const handleQuantityChangeOnButton = async (increment, item) => { // TODO: Fix this implementation
    setLoading(true);
    queryClient.setQueryData(['marketplaceList'], (prevData) => {
      const updatedItemList = prevData.itemList.map((i) => {
        if (i.name === item.name && increment) {
          return {
            ...i,
            quantity: i.quantity + 1,
          };
        } if (i.name === item.name && !increment) {
          return {
            ...i,
            quantity: i.quantity - 1,
          };
        }
        return i;
      });

      return {
        ...prevData,
        itemList: updatedItemList,
      };
    });

    await updateMarketplaceList(queryClient.getQueryData(['marketplaceList']));
    setLoading(false);
  };

  const handleQuantityChange = (item, e) => {
    setLoading(true);

    const newQuantity = Number(e.target.value);
    if (newQuantity === 0 || Number.isNaN(newQuantity)) {
      queryClient.setQueryData(['marketplaceList'], (prevData) => ({
        ...prevData,
        itemList: prevData.itemList.map((i) => {
          if (i.name === item.name) {
            return {
              ...i,
              quantity: 0,
            };
          }
          return i;
        }),
      }));
    } else {
      queryClient.setQueryData(['marketplaceList'], (prevData) => ({
        ...prevData,
        itemList: prevData.itemList.map((i) => {
          if (i.name === item.name) {
            return {
              ...i,
              quantity: newQuantity,
            };
          }
          return i;
        }),
      }));
    }

    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',

        backgroundColor: '#fff',
        borderRadius: '6px',
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        overflow: 'hidden',
        height: '100%',

        '&::before': {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          height: '9px',
          background:
            'linear-gradient(90deg, rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%)',
        },
      }}
    >
      <IconButton
        data-testid="arrow-left-icon"
        sx={{
          alignSelf: 'flex-start',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          mt: '16px',
          p: '16px',
        }}
        onClick={() => {
          navigate(-1);
          updateMarketplaceList();
        }}
      >
        <ArrowLeftSquare />
        <Typography variant="h6" color="#474750" sx={{ fontSize: '12px' }}>
          Voltar
        </Typography>
      </IconButton>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            height: '100vh',
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              mt: '4px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '16px',
              pt: '0',
            }}
          >
            <Typography variant="h6" color="#474750" sx={{ fontSize: '18px' }}>
              {`Lista ${marketPlaceData.name}`}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <CreateMarketplaceList
                data-testid="add-items-btn"
                addProductToMarketplaceList={addProductToMarketplaceList}
                updateMarketplaceList={updateMarketplaceList}
              />
              <GenerateQuotation
                marketPlaceData={marketPlaceData}
                refetchMarketplaceList={refetchMarketplaceList}
                updateMarketplaceList={updateMarketplaceList}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mt: '24px',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 368px)',
              p: '16px',

              '&::-webkit-scrollbar': {
                width: '4px',
                height: '4px',
                backgroundColor: '#F5F5F7',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#4A397F',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#F5F5F7',
              },
            }}
          >
            {marketPlaceData?.itemList?.length > 0 ? (
              marketPlaceData?.itemList?.map((item) => (
                <Box
                  data-testid="item-card"
                  key={item.name}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      padding: '11px 20px',
                      borderRadius: '6px',
                      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: '12px',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <Box sx={{ width: '77px', height: '55px' }}>
                        <BenvoImage
                          src={item.image}
                          alt={item.name}
                          sx={{ minWidth: '77px' }}
                        />
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle1"
                          color="#474750"
                          sx={{
                            fontWeight: '500',
                            fontSize: '18px',
                          }}
                        >
                          {formatPhraseToLowerCaseWithFirstLetterUpperCase(
                            item.name,
                          )}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="#474750"
                          fontWeight={400}
                          sx={{
                            fontSize: '10px',
                          }}
                        >
                          {item.type}
                        </Typography>
                      </Box>
                    </Box>
                    {!item.sku && (
                      <Box
                        sx={{
                          mr: 'auto',
                          ml: '20px',
                          backgroundColor: '#4A397F',
                          padding: '5.231px 15.692px',
                          borderRadius: '6px',
                          color: '#fff',
                          fontSize: '12px',
                        }}
                      >
                        Grupo
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        borderLeft: '1px solid #D4D4DA',
                        paddingLeft: '11px',
                      }}
                    >
                      <IconButton
                        data-testid="remove-item-from-list"
                        color="cancel"
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        onClick={() => {
                          handleRemoveFromMarketplaceList(item);
                        }}
                      >
                        <TrashIcon />
                      </IconButton>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >

                        {loading ? (
                          <Skeleton variant="rounded" width={132} height={36} />
                        ) : (
                          <>
                            <IconButton
                              data-testid="decrement-quantity-icon"
                              color="primary"
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              onClick={() => {
                                handleQuantityChangeOnButton(false, item);
                              }}
                            >
                              <MinusIcon />
                            </IconButton>

                            <TextField
                              data-testid="quantity-input-field"
                              value={item.quantity || 0}
                              variant="standard"
                              onChange={(e) => handleQuantityChange(item, e)}
                              onBlur={() => updateMarketplaceList()}
                              onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                  updateMarketplaceList();
                                }
                              }}
                              sx={{
                                width: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '& input': {
                                  textAlign: 'center',
                                },
                                '& .MuiInput-underline:before': {
                                  borderBottom: 'none',
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                  borderBottom: 'none',
                                },
                              }}
                            />

                            <IconButton
                              data-testid="increment-quantity-icon"
                              color="primary"
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              onClick={() => {
                                handleQuantityChangeOnButton(true, item);
                              }}
                            >
                              <PlusIcon />
                            </IconButton>

                          </>
                        )}

                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Grid item xs={12}>
                <Box
                  sx={{
                    height: '550px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="subtitle1" color="#474750">
                    Nenhum item adicionado à sua lista de compras
                  </Typography>
                </Box>
              </Grid>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
