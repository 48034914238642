import React, { useState } from 'react';
import { useParams } from 'react-router';
import forge from 'node-forge';
import { Box, MenuItem } from '@mui/material';
import BenvoTextField from '../../../../components/BenvoTextField';
import { useAlert } from '../../../../context/alertContext';
import { useDoctorLP } from '../../../../context/doctorLPContext';
import BenvoDrawer from '../../../../components/BenvoDrawer';
import routes from '../../../../services/routes';
import arrayBufferToBase64 from '../../../../utils/arrayBufferToBase64';
import {
  KeyActionBox, KeySelectorBox, KeyUploadAndPasswordBox,
  StyledRemoveIcon,
  StyledSelectorA1, StyledTypography, StyledTypography10pxInsideInput, StyledTypography12pxInsideInput,
} from './style';
import { ReactComponent as DocumentUpload } from '../../../../assets/icons/document-upload.svg';
import BenvoSelectField from '../../../../components/BenvoSelectField';
import BenvoButton from '../../../../components/BenvoButton/style';

const DEFAULT_A1_CERTIFICATE = {
  key: null,
  password: null,
};

function KeySelector({ drawerHandler }) {
  const { ilpiId, doctorId } = useParams();
  const alert = useAlert();
  const doctorLP = useDoctorLP();
  const [certificateType, setCertificateType] = useState(null);
  const [a1Certificate, setA1Certificate] = useState(DEFAULT_A1_CERTIFICATE);
  const [isError, setIsError] = useState(false);
  const [filename, setFilename] = useState('');

  /**
   * Handler to cancel the signing proccess
   */
  const handleCancel = () => {
    setCertificateType(null);
    drawerHandler.handle();
    setA1Certificate(DEFAULT_A1_CERTIFICATE);
    setFilename('');
  };

  /**
   * Handler to manage the password state
   * @param {string} value - The password value
   */
  const handlePasswordChange = ({ target: { value } }) => {
    setIsError(false);
    setA1Certificate({
      ...a1Certificate,
      password: value,
    });
  };

  /**
   * Reads the pfx or p12 file
   * @param {File} files - The file to be read
   * @returns
   */
  const a1TokenRead = async ({ target: { files } }) => {
    if (files.length > 1) {
      alert.warning('Selecione apenas um arquivo!');
      return;
    }
    const token = files[0];

    const reader = new FileReader();
    const arrayBuffer = await new Promise((resolve, reject) => {
      reader.onload = (key) => {
        resolve(key.target.result);
      };
      reader.onerror = (error) => {
        alert.error('Erro ao ler o arquivo! Verifique se o arquivo é um token A1 válido.');
        reject(error);
      };
      reader.readAsArrayBuffer(token);
    });

    // Sets the key to be used in the a1Token function
    setA1Certificate({
      key: arrayBuffer,
      password: null,
    });
    setFilename(token.name);
  };

  /**
   * Treats payload for recipes
   */
  const sendRecipes = async () => {
    const payload = {
      key: arrayBufferToBase64(a1Certificate.key),
      password: a1Certificate.password,
      doctor: doctorLP.doctor,
      approved: doctorLP.approved,
      declined: doctorLP.removed,
    };
    try {
      await routes.publicRoutes.signA1PDFRecipe({ collaboratorId: doctorId, ilpiId }, payload);
      alert.success('Receitas assinadas com sucesso');
      doctorLP.setSigned(true);
    } catch (error) {
      alert.error('Erro ao assinar as receitas! Por favor, tente novamente.');
      handleCancel();
    } finally {
      doctorLP.setIsLoading(false);
    }
  };

  /**
 * Treats payload for recipes
 */
  const sendExams = async () => {
    const payload = {
      key: arrayBufferToBase64(a1Certificate.key),
      password: a1Certificate.password,
      doctor: doctorLP.doctor,
      approved: doctorLP.approved,
    };
    try {
      await routes.publicRoutes.signA1PDFExam({ collaboratorId: doctorId, ilpiId }, payload);
      alert.success('Exames assinados com sucesso');
      doctorLP.setSigned(true);
    } catch (error) {
      alert.error('Erro ao assinar os exames! Por favor, tente novamente.');
    } finally {
      doctorLP.setIsLoading(false);
    }
  };

  /**
   * Opens the a1Token to sign the pdfs
   * @returns
   */
  const a1TokenTreatment = async () => {
    doctorLP.setIsLoading(true);

    if (!a1Certificate.password) {
      setIsError(true);
      doctorLP.setIsLoading(false);
      return;
    }

    const u8IntArray = new Uint8Array(a1Certificate.key);
    const byteBuffer = forge.util.createBuffer(u8IntArray);
    const asn1 = forge.asn1.fromDer(byteBuffer);

    let pkcs = null;
    try {
      pkcs = forge.pkcs12.pkcs12FromAsn1(asn1, a1Certificate.password);
      drawerHandler.handle();
    } catch (error) {
      setA1Certificate({ ...a1Certificate, password: '' });
      doctorLP.setIsLoading(false);
      setIsError(true);
      alert.warning('Senha incorreta.');
      return;
    }

    const certBags = pkcs.getBags({ bagType: forge.pki.oids.certBag });
    const [certBag] = certBags[forge.pki.oids.certBag];
    const certificate = certBag.cert;

    const subjectAttributes = certificate.subject.attributes;

    let commonName;
    subjectAttributes.forEach((attribute) => {
      if (attribute.type === forge.pki.oids.commonName) {
        commonName = attribute.value;
      }
    });

    commonName = commonName.replace(/[^a-zA-Z]/g, '').toLowerCase();
    const normalizedDoctorName = doctorLP.doctor.name.toLowerCase().replace(/[^a-zA-Z]/g, '');

    if (commonName !== normalizedDoctorName) {
      console.log(commonName, normalizedDoctorName);
      // doctorLP.setIsLoading(false);
      // handleCancel();
      // alert.warning('O certificado selecionado não pertence ao médico logado!');
      // return;
    }

    if (drawerHandler.recipes === 'receitas') {
      sendRecipes();
    } else {
      sendExams();
    }
  };

  return (
    <BenvoDrawer
      open={drawerHandler.open}
      onClose={drawerHandler.handle}
      title="Aprovação de solicitação"
      sx2={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <KeySelectorBox>
        <KeyUploadAndPasswordBox>
          <StyledTypography>
            Selecione o seu certificado digital:
          </StyledTypography>
          <BenvoSelectField
            label="Selecione"
            displayEmpty
            initialValue=""
            value={certificateType}
            onChange={(e) => setCertificateType(e.target.value)}
          >
            <MenuItem value={null} disabled>
              Formato de certificado
            </MenuItem>
            <MenuItem
              key="A1"
              value="A1"
              data-testid="token-A1"
            >
              Token A1
            </MenuItem>
          </BenvoSelectField>
          {certificateType === 'A1' && (
            <Box sx={{
              position: 'relative', display: 'flex', gap: '12px', flexDirection: 'column',
            }}
            >
              {a1Certificate.key && (
                <StyledRemoveIcon
                  onClick={() => setA1Certificate(DEFAULT_A1_CERTIFICATE)}
                />
              )}
              <StyledSelectorA1
                component="label"
                data-testid="select-a1-token"
              >
                <DocumentUpload />
                {!a1Certificate.key ? (
                  <>
                    <StyledTypography12pxInsideInput>
                      Upload Token
                    </StyledTypography12pxInsideInput>
                    <StyledTypography10pxInsideInput>
                      Token em arquivo A1
                    </StyledTypography10pxInsideInput>
                  </>
                )
                  : (
                    <>
                      <StyledTypography12pxInsideInput>
                        Arquivo carregado
                      </StyledTypography12pxInsideInput>
                      <StyledTypography10pxInsideInput>
                        {filename}
                      </StyledTypography10pxInsideInput>
                    </>
                  )}
                <input
                  type="file"
                  accept={['.pfx', '.p12']}
                  hidden
                  onChange={a1TokenRead}
                />
              </StyledSelectorA1>
              <BenvoTextField
                name="certificatePassword"
                type="password"
                placeholder="Senha do certificado"
                value={a1Certificate.password}
                onChange={handlePasswordChange}
                size="small"
                spellCheck="false"
                fullWidth
                inputProps={{
                  'data-testid': 'certificate-password',
                }}
                sx={{
                  border: isError ? '1px solid var(--paleta-senior-alert-red, #A73737);' : 'none',
                  '.MuiInputBase-input::placeholder': {
                    color: isError ? 'var(--paleta-senior-alert-red, #A73737);' : '#474750',
                    opacity: '1',
                    fontSize: '16px',
                  },
                }}
              />
            </Box>
          )}
        </KeyUploadAndPasswordBox>
        <KeyActionBox>
          <BenvoButton
            variant="secondary"
            data-testid="cancel"
            onClick={handleCancel}
          >
            Cancelar
          </BenvoButton>
          <BenvoButton
            variant="primary"
            data-testid="send-password"
            onClick={() => {
              if (certificateType === 'A1') {
                a1TokenTreatment();
              }
            }}
          >
            Aprovar
          </BenvoButton>
        </KeyActionBox>
      </KeySelectorBox>
    </BenvoDrawer>
  );
}

export default KeySelector;
