/* eslint-disable max-len */
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BenvoTextField from '../../../../../../components/BenvoField/style';
import BenvoButton from '../../../../../../components/BenvoButton/style';
import {
  CardNumberAndCVVBoxes, CardNumberTypography, DarkTextTypography,
  Heading2, PaymentBox, PaymentButtonBox, PaymentHeadBox, Separator,
} from '../style';
import { getTokenInfo } from '../../../../../../utils/configAuth';
import routes from '../../../../../../services/routes';
import Cart from './Cart';

function Payment({
  selectedCard, handleCvv, handlePlaceAllOrdersFrubana, processedCart,
}) {
  const [ilpi, setIlpi] = useState({});
  const [address, setAddress] = useState(null);
  const ilpiId = getTokenInfo('ilpiId');

  const fetchIlpiData = async () => {
    const { message } = await routes.ilpi.getIlpiById({ ilpiId });
    const {
      street, number, district, city, state, zipCode,
    } = message;
    setIlpi(message);
    setAddress(`${street}, ${number} - ${district}, ${city} - ${state}, ${zipCode.substring(0, 5)}-${zipCode.substring(5)}`);
  };

  useEffect(() => {
    fetchIlpiData();
  }, []);

  return (
    <PaymentHeadBox>

      <PaymentBox>
        <Heading2>
          Dados do cartão
        </Heading2>
        <Box>
          <CardNumberAndCVVBoxes>
            <DarkTextTypography>
              Cartão nº
            </DarkTextTypography>
            <CardNumberTypography>
              {selectedCard.maskedNumber.replace(/(.{4})/g, '$1 ').trim().replace(/\*/g, '•')}
            </CardNumberTypography>
          </CardNumberAndCVVBoxes>
          <CardNumberAndCVVBoxes>
            <DarkTextTypography>
              Digite o CVV:
            </DarkTextTypography>
            <BenvoTextField
              placeholder=""
              onChange={handleCvv}
              inputProps={{
                maxLength: 3,
              }}
              sx={{
                width: '80px',
                '& ::placeholder': {
                  textAlign: 'center',
                  color: 'black !important',
                },
                '& input': {
                  textAlign: 'center',
                },
                '& .MuiOutlinedInput-root': {
                  height: '24px',
                },
              }}
            />
          </CardNumberAndCVVBoxes>
        </Box>
      </PaymentBox>

      <Separator />

      <PaymentBox>
        <Heading2>
          Endereço de entrega
        </Heading2>
        <Box>
          <DarkTextTypography>
            {ilpi.name || null}
          </DarkTextTypography>
          <DarkTextTypography>
            {`Endereço: ${address || null}`}
          </DarkTextTypography>
        </Box>
      </PaymentBox>

      <PaymentButtonBox>
        <BenvoButton
          variant="primary"
          onClick={handlePlaceAllOrdersFrubana}
        >
          Confirmar pagamento
        </BenvoButton>
      </PaymentButtonBox>

      {
        processedCart.notAddedToCart.length ? (
          <>
            <Separator />
            <Cart processedCart={processedCart} />
          </>
        ) : null
      }

    </PaymentHeadBox>
  );
}

export default Payment;
