import React from 'react';
import { Button, Card } from './style';

export default function CreditCard() {
  return (
    <Card>
      <div>
        <h2>Precisa de Crédito com as menores taxas do mercado?</h2>
      </div>
      <a
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=5521988094488&text=Gostaria%20de%20saber%20mais%20sobre%20empr%C3%A9stimos%20com%20taxas%20reduzidas."
        rel="noreferrer"
      >
        <Button type="button" variant="secondary">Fale Conosco</Button>
      </a>
    </Card>
  );
}
