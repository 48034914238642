import React from 'react';
import { Alert } from '@mui/material';

const CHANGE_PASSWORD_MESSAGES = {
  success: (
    <Alert severity="success">Sua senha foi alterada com sucesso, você será redirecionado à página principal.</Alert>
  ),
  error: (
    <Alert severity="error">Erro na solicitação. Por favor, verifique sua senha!</Alert>
  ),
};

export default CHANGE_PASSWORD_MESSAGES;
