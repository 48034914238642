/* eslint-disable max-len */
import React from 'react';

export default function UsersIcon({
  active, width = 24, height = 25, color = '#474750',
}) {
  if (active) {
    return (
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.73991 6.28302L4.74027 6.28281L10.668 2.85412C10.6684 2.85391 10.6687 2.85371 10.6691 2.8535C11.4959 2.38151 12.5057 2.38299 13.3199 2.853C13.3199 2.853 13.3199 2.85301 13.3199 2.85302L19.2566 6.28108C19.2571 6.28137 19.2576 6.28166 19.2581 6.28195C20.076 6.76325 20.5808 7.63037 20.5899 8.58236V15.42C20.5899 16.3724 20.0843 17.2468 19.2699 17.717L19.2699 17.717L13.3319 21.1459C13.3316 21.146 13.3314 21.1461 13.3311 21.1463C12.5042 21.6185 11.4942 21.6171 10.68 21.147C10.6799 21.147 10.6799 21.147 10.6799 21.147L4.74324 17.7189C4.74272 17.7186 4.74221 17.7183 4.74169 17.718C3.92376 17.2366 3.41992 16.3602 3.41992 15.42V8.58C3.41992 7.62761 3.9255 6.75319 4.73991 6.28302ZM8.07516 14.8108L8.07485 14.8112C7.40588 15.8073 8.10243 17.16 9.31992 17.16H14.6799C15.8814 17.16 16.5985 15.8012 15.9239 14.8097C15.1374 13.6423 13.6501 12.9 11.9999 12.9C10.3492 12.9 8.86155 13.6428 8.07516 14.8108ZM14.8299 9.67C14.8299 8.10386 13.5661 6.84 11.9999 6.84C10.4324 6.84 9.16992 8.11526 9.16992 9.67C9.16992 11.2361 10.4338 12.5 11.9999 12.5C13.5661 12.5 14.8299 11.2361 14.8299 9.67Z" fill="#4A397F" stroke="#4A397F" />
      </svg>
    );
  }
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="user-octagon">
        <g id="vuesax/linear/user-octagon">
          <g id="user-octagon_2">
            <path id="Vector" d="M21.0799 9.08003V15.92C21.0799 17.04 20.4799 18.08 19.5099 18.65L13.5699 22.08C12.5999 22.64 11.3999 22.64 10.4199 22.08L4.47991 18.65C3.50991 18.09 2.90991 17.05 2.90991 15.92V9.08003C2.90991 7.96003 3.50991 6.91999 4.47991 6.34999L10.4199 2.92C11.3899 2.36 12.5899 2.36 13.5699 2.92L19.5099 6.34999C20.4799 6.91999 21.0799 7.95003 21.0799 9.08003Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <g id="Group">
              <path id="Vector_2" d="M11.9999 11.5001C13.2867 11.5001 14.3299 10.4569 14.3299 9.17004C14.3299 7.88322 13.2867 6.84009 11.9999 6.84009C10.7131 6.84009 9.66992 7.88322 9.66992 9.17004C9.66992 10.4569 10.7131 11.5001 11.9999 11.5001Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path id="Vector_3" d="M16 17.1601C16 15.3601 14.21 13.9001 12 13.9001C9.79 13.9001 8 15.3601 8 17.1601" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
