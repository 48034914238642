import React, { useState } from 'react';
import {
  IconButton, InputAdornment,
} from '@mui/material';
import BenvoPrimaryButton from '../../../components/BenvoPrimaryButton';
import EyeIconClose from '../../../assets/icons/EyeIconClose';
import EyeIconOpen from '../../../assets/icons/EyeIconOpen';
import BenvoIconLogin from '../../../assets/BenvoIconLogin.png';
import {
  StyledBox, StyledButton, StyledForm, StyledTexField,
} from './style';
import PATHS from '../../../constants/applicationPaths';

export default function LoginForm({
  isLoading,
  watch,
  register,
  errors,
  onSubmit,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <StyledForm
      action=""
      onSubmit={onSubmit}
    >
      <img
        src={BenvoIconLogin}
        alt="Logo Benvo"
        width={208}
      />

      <StyledBox>
        <StyledTexField
          name="field"
          placeholder="E-mail"
          size="small"
          fullWidth
          data-testid="login-input"
          value={watch('email')}
          error={errors.email}
          helperText={errors.email?.message}
          {...register('email')}
        />

        <StyledTexField
          name="password"
          placeholder="Senha"
          size="small"
          fullWidth
          type={showPassword ? 'text' : 'password'}
          data-testid="password-input"
          error={errors.password}
          helperText={errors.password?.message}
          {...register('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  sx={{
                    '& .MuiIconButton-root': {
                      padding: 1,
                    },
                  }}
                >
                  {showPassword ? (
                    <EyeIconOpen
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      color="#474750"
                    />
                  ) : (
                    <EyeIconClose
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      color="#474750"
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <a href={PATHS.auth.recoverPassword}>Recuperar senha</a>

      </StyledBox>

      {isLoading ? (
        <BenvoPrimaryButton
          disabled
          sx={{
            width: '160px',
            height: '40px',
          }}
        >
          Acessando...
        </BenvoPrimaryButton>
      ) : (
        <StyledButton
          type="submit"
          onClick={onSubmit}
          data-testid="login-button"
        >
          Acessar
        </StyledButton>
      )}

    </StyledForm>
  );
}
