const Joi = require('joi');

const timeRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d)$/; // 00:00 - 23:59

const createSeller = Joi.object({
  name: Joi.string().min(3).required().messages({
    'string.empty': 'Você deve informar um nome.',
    'string.min': 'O nome deve ter no mínimo 3 caracteres.',
  }),
  cnpj: Joi.string().length(18).required().messages({
    'string.empty': 'Você deve informar um CNPJ.',
    'string.length': 'O CNPJ deve ter 14 caracteres.',
  }),
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: false },
  }).required().messages({
    'string.empty': 'Você deve informar um e-mail.',
    'string.email': 'Você deve informar um e-mail válido.',
    'string.base': 'Você deve informar um e-mail válido.',
  }),
  takeRate: Joi.number().min(0).max(100).required()
    .messages({
      'number.empty': 'Você deve informar uma taxa de repasse.',
      'number.min': 'A taxa de repasse deve ser maior ou igual a 0.',
      'number.max': 'A taxa de repasse deve ser menor ou igual a 100.',
      'number.base': 'A taxa de repasse deve ser um número.',
    }),
  estimatedDeliveryTime: Joi.number().min(0).required().messages({
    'number.empty': 'Você deve informar um tempo de entrega estimado.',
    'number.min': 'O tempo de entrega estimado deve ser maior ou igual a 0.',
    'number.base': 'O tempo de entrega estimado deve ser um número.',
  }),
  minimumOrder: Joi.number().min(0).required().messages({
    'number.empty': 'Você deve informar um pedido mínimo.',
    'number.min': 'O pedido mínimo deve ser maior ou igual a 0.',
    'number.base': 'O pedido mínimo deve ser um número.',
  }),
  paymentOptions: Joi.array().min(1).items(
    Joi.string().valid('debit', 'credit', 'pix', 'cash', 'bankSlip'),
  ).required()
    .messages({
      'array.empty': 'Você deve informar uma forma de pagamento.',
      'array.min': 'Você deve informar pelo menos uma forma de pagamento.',
      'any.only': 'Você deve informar uma forma de pagamento válida.',
      'array.base': 'Você deve informar uma forma de pagamento válida.',
    }),
  targetType: Joi.array().items(
    Joi.string().valid('b2b', 'b2c'),
  ).min(1).max(2)
    .required(),
  deliveryOrderTimeLimit: Joi.string().regex(timeRegex).required(),
});

export default {
  createSeller,
};
