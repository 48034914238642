import { Typography, styled } from '@mui/material';

export const CardContainer = styled('label')`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  max-width: 398px;
  min-width: 360.81px;
  padding: 14px 22px;
  flex-shrink: 0;
  background: linear-gradient(90deg, rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%);
  border-radius: 6px;
`;

export const CardText = styled(Typography)`
  width: 100%;
  max-width: 190.711px;

  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.024px;
`;
