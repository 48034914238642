const mapper = {
  401: 'wrongPassword',
  403: 'unauthorized',
  404: 'notFound',
  409: 'alreadyExists',
  500: 'generalError',
};

const alertMapper = (type) => mapper[type] || 'generalError';

export default alertMapper;
