import React, { useState } from 'react';
import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';

export default function ConfirmationDialog({
  title,
  message,
  handleConfirm,
  testId,
  iconButton = {
    icon: <Delete />,
    color: 'primary',
    tooltip: 'Deletar',
  },
}) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogIsOpen(true);
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };

  const handleSubmit = () => {
    handleConfirm();
    handleDialogClose();
  };

  return (
    <>
      <Tooltip title={iconButton.tooltip}>
        <IconButton data-testid={testId} color={iconButton.color} onClick={handleDialogOpen}>
          {iconButton.icon}
        </IconButton>
      </Tooltip>
      <Dialog open={dialogIsOpen} onClose={handleDialogClose}>
        <DialogTitle color="primary">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="cancel" onClick={handleDialogClose}>
            Não
          </Button>
          <Button onClick={handleSubmit}>Sim</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
