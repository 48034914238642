import React, { useState } from 'react';
import {
  MenuItem, IconButton, Divider,
} from '@mui/material';
import BenvoContentBox from '../../components/BenvoContentBox';
import BenvoSearchField from '../../components/BenvoSearchField';
import BenvoSelectField from '../../components/BenvoSelectField';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-button-icon.svg';
import ListUser from './components/ListUser';
import CreateCollaborator from './components/CreateCollaborator';
import routes from '../../services/routes';
import { getTokenInfo } from '../../utils/configAuth';
import {
  ActionsContainer,
  ButtonsContainer,
  SearchAndFilterContainer,
  UsersContainer,
} from './style';
import BenvoEmpty from '../../components/BenvoEmpty';
import useInfiniteList from '../../hooks/useInfiniteList';
import DynamicScroll from '../../components/DynamicScroll';

const { users } = routes;

const types = [
  { value: '', label: 'Todos', dataTestId: 'all' },
  { value: 'doctor', label: 'Médico', dataTestId: 'doctor' },
  { value: 'nurse', label: 'Enfermeiro', dataTestId: 'nurse' },
  { value: 'caregiver', label: 'Cuidador', dataTestId: 'caregiver' },
  { value: 'employee', label: 'Funcionário', dataTestId: 'employee' },
];

export default function Collaborators() {
  const [searchParams, setSearchParams] = useState({
    name: '',
    type: '',
  });

  const getUsersAndCollaborators = async (queries) => {
    const ilpiId = getTokenInfo('ilpiId');
    try {
      const { message } = await users.findAllUserAndCollaboratorsByIlpi({ ilpiId }, queries);
      return message;
    } catch (error) {
      return error;
    }
  };

  const {
    data: usersAndCollaborators = [],
    nextPage,
    setQueries,
    loading,
    finished,
    refetch,
  } = useInfiniteList({ getData: getUsersAndCollaborators });

  const handleSearch = async () => {
    setQueries({ name: searchParams.name, type: searchParams.type });
  };

  return (
    <BenvoContentBox title="Profissionais">
      <ActionsContainer>
        <SearchAndFilterContainer>
          <BenvoSearchField
            minWidth="266"
            placeholder="Buscar"
            value={searchParams.name}
            onChange={(e) => setSearchParams({ ...searchParams, name: e.target.value })}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <Divider orientation="vertical" flexItem />
          <BenvoSelectField
            minWidth="183"
            fullWidth
            displayEmpty
            value={searchParams.type}
            onChange={(e) => setSearchParams({ ...searchParams, type: e.target.value })}
          >
            <MenuItem value="" disabled>
              Cargo
            </MenuItem>
            {types.map((type) => (
              <MenuItem key={type.value} value={type.value} data-testid={type.dataTestId}>
                {type.label}
              </MenuItem>
            ))}
          </BenvoSelectField>
          <IconButton onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </SearchAndFilterContainer>
        <ButtonsContainer>
          <CreateCollaborator getUsersAndCollaborators={refetch} />
        </ButtonsContainer>
      </ActionsContainer>
      <UsersContainer>
        <DynamicScroll
          onScrollEnd={nextPage}
          loading={loading}
          finished={finished}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <BenvoEmpty
            active={!loading && !usersAndCollaborators?.length}
            title="Usuários"
            message="Nenhum usuário encontrado"
          />
          {usersAndCollaborators?.map((user) => (
            <ListUser key={user.id} user={user} getUsersAndCollaborators={refetch} />
          ))}
        </DynamicScroll>
      </UsersContainer>
    </BenvoContentBox>
  );
}
