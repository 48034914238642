import React from 'react';
import BenvoEmpty from '../../../../components/BenvoEmpty';

function EmptyResult({ type }) {
  return (
    <BenvoEmpty
      sx={{ height: '100%' }}
      active
      title={type}
      message={`Não foram encontradas ${type.toLowerCase()} para serem validadas.`}
    />
  );
}

export default EmptyResult;
