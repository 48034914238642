import React from 'react';
import { Box } from '@mui/material';
import RejectModal from '../components/RejectModal/RejectModal';
import {
  StyledBoxHeaderCard, StyledCard, StyledTypographyH1ResidentName,
  StyledTypography10px, StyledBoxForCardActions,
  StyledDivider, StyledBoxContentCard, StyledTypographyPDarkGrey12px,
} from '../styles';

function RecipeCard({ data, removeRecipe }) {
  const { name, inRecipes } = data;
  return (
    <StyledCard>
      <StyledBoxHeaderCard>
        <Box>
          <StyledTypography10px>
            RECEITA GERADA PARA:
          </StyledTypography10px>
          <StyledTypographyH1ResidentName>
            {name}
          </StyledTypographyH1ResidentName>
        </Box>
        <StyledBoxForCardActions>
          <StyledDivider
            orientation="vertical"
            flexItem
          />
          <RejectModal
            title="Rejeitar"
            residentName={name}
            removeFromApproved={removeRecipe}
          />
        </StyledBoxForCardActions>
      </StyledBoxHeaderCard>
      <StyledBoxContentCard>
        {inRecipes.map((inRecipe) => {
          const { recipeItems } = inRecipe;
          return recipeItems.map((item) => {
            const {
              dosage, quantity, substance, timesPerDay, id, unit,
            } = item;
            return (
              <StyledTypographyPDarkGrey12px
                key={`${substance}${id}`}
                align="justify"
              >
                {`${substance} - ${dosage} - ${quantity} ${unit}, ${timesPerDay}x ao dia`}
              </StyledTypographyPDarkGrey12px>
            );
          });
        })}
      </StyledBoxContentCard>
    </StyledCard>
  );
}

export default RecipeCard;
