import React, { createRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';

import moment from 'moment';
import logoAfarma from '../../../../assets/benvo-icon.png';

import {
  diuresisStatus,
  vitalSignsStatus,
  wellBeingStatus,
} from './utils/kindOptions';

function ViewEvolution({ evolution, children }) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const ref = createRef();

  const handleClickOpen = () => {
    setDialogIsOpen(true);
  };
  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Normal':
        return '#0A305A';
      case 'Atenção':
        return '#e57734';
      case 'Alerta':
        return '#F23C3C ';
      default:
        return 'transparent';
    }
  };

  const currentKind = (thisEvolution) => {
    switch (thisEvolution.kind) {
      case 'Bem Estar':
        return wellBeingStatus(thisEvolution);
      case 'Sinais Vitais':
        return vitalSignsStatus(thisEvolution);
      case 'Diurese':
        return diuresisStatus(thisEvolution);
      default:
        return null;
    }
  };

  return (
    <>
      {children ? (
        <TableRow
          sx={{
            cursor: 'pointer',
          }}
          onClick={handleClickOpen}
        >
          {children}
        </TableRow>
      ) : (
        <Tooltip title="Visualizar Evolução">
          <IconButton color="primary" onClick={handleClickOpen}>
            <Visibility />
          </IconButton>
        </Tooltip>
      )}

      <Dialog open={dialogIsOpen} onClose={handleDialogClose} maxWidth="xl">
        <div ref={ref}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h4"
              component="h2"
              color="primary"
              fontWeight="bold"
            >
              Registro de Evolução
            </Typography>
            <img
              style={{ width: '200px', height: 'auto' }}
              src={logoAfarma}
              alt="logo afarma"
            />
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 8,
                justifyContent: 'space-between',
                mt: '28px',
              }}
            >
              <Typography variant="h6" component="p" fontWeight="bold">
                Residente:
                {' '}
                <Typography variant="h6" component="span">
                  {evolution.name}
                </Typography>
              </Typography>
              <Typography variant="h6" component="p" fontWeight="bold">
                Responsável:
                {' '}
                <Typography variant="h6" component="span">
                  {evolution.responsibleName}
                </Typography>
              </Typography>
              <Typography variant="h6" component="p" fontWeight="bold">
                Data da evolução:
                {' '}
                <Typography variant="h6" component="span">
                  {moment(evolution.date).format('DD/MM/YYYY')}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                mt: '40px',
              }}
            >
              <Typography
                align="center"
                variant="h4"
                component="p"
                fontWeight="bold"
              >
                Status:
                {' '}
                <Typography
                  variant="h4"
                  component="span"
                  fontWeight="bold"
                  sx={{
                    color: getStatusColor(evolution.status),
                  }}
                >
                  {evolution.status}
                </Typography>
              </Typography>
            </Box>
            {currentKind(evolution)}
            <Box
              sx={{
                mt: '80px',
                maxWidth: '50%',
              }}
            >
              <Typography variant="h6" component="p" fontWeight="bold">
                Observação:
                {' '}
                <Typography variant="h6" component="p">
                  {evolution.observation}
                </Typography>
              </Typography>
            </Box>
          </DialogContent>
        </div>
        <DialogActions sx={{ p: '24px' }}>
          <Button onClick={handleDialogClose} variant="contained">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewEvolution;
