import React, { useState } from 'react';
import Grid from '@mui/material/Grid';

import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Button, TextField } from '@mui/material';
import { convertAPIDateWithoutTime } from '../../../../utils/dateFormat';
import { getTokenInfo } from '../../../../utils/configAuth';

function Search({ onSearch, searchStartDate }) {
  const [values, setValues] = useState({});
  const [startDate, setStartDate] = useState(searchStartDate);

  const currentIdLpi = getTokenInfo('ilpiId');

  const handleSubmit = () => {
    const params = {
      name: values.name || '',
      startDate: convertAPIDateWithoutTime(startDate),
      ilpiId: currentIdLpi,
    };

    onSearch(params);
  };

  const handleDateChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });

    if (field === 'startDate') {
      setStartDate(value);
    }
  };

  return (
    <Grid container spacing={2} lg={8}>
      <Grid item lg={6}>
        <TextField
          size="small"
          id="name"
          name="name"
          label="Busque por residente ou código da prescrição"
          variant="outlined"
          fullWidth
          autoComplete="off"
          onChange={(e) => {
            handleDateChange('name', e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
      </Grid>

      <Grid item lg>
        <DesktopDatePicker
          label="Data Inicial"
          inputFormat="DD/MM/yyyy"
          value={startDate}
          onChange={(newValue) => {
            handleDateChange('startDate', newValue._d);
          }}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </Grid>

      <Grid item lg>
        <Button
          data-testid="prescription-search"
          appearance="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
}

export default Search;
