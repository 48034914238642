import React from 'react';
import { Link } from 'react-router-dom';
import {
  DateAndStatusContainer,
  EmptySendButtonContainer,
  ListItemCodeContainer,
  ListItemCodeNumber,
  ListItemCodeTitle,
  ListItemContainer,
  StatusContainer,
  StyledBenvoButton,
  StyledDivider,
  StyledIconButton,
} from './style';
import DocumentDownload from '../../../../../../../../assets/icons/DocumentDownload';
import PATHS from '../../../../../../../../constants/applicationPaths';
import stateMachine, { actionButton as ActionButton } from '../../../../../../../../utils/stateMachine';
import Send from '../../../../../../../../assets/residents/Send';
import SendIndividualShoppingListModal from '../../../../../../../residentShoppingList/components/ResidentShoppingListCard/components/SendIndividualShoppingListModal';
import { generateShoppingListPdf } from '../../../../../../../../utils/generatePDFs';
import BenvoTooltip from '../../../../../../../../components/BenvoTooltip';

function Edit({ order }) {
  return (
    <Link to={`${PATHS.residents.root}/${order.residentId}/${PATHS.residents.shoppingList}/${order.id}?from=edit`}>
      <StyledBenvoButton>Editar Lista</StyledBenvoButton>
    </Link>
  );
}

function View({ order }) {
  return (
    <Link to={`${PATHS.residents.root}/${order.residentId}/${PATHS.residents.shoppingList}/${order.id}?from=visualize`}>
      <StyledBenvoButton variant="secondary">Visualizar itens</StyledBenvoButton>
    </Link>
  );
}

export default function OrderHistoryListItem({ order, residentInformations, refetchOrderHistory }) {
  const {
    status, isSendable, actionButton, color,
  } = stateMachine[order.status];
  const mapActionButton = () => {
    switch (actionButton) {
      case ActionButton.EDIT:
        return <Edit order={order} />;
      case ActionButton.VIEW:
        return <View order={order} />;
      default:
        return null;
    }
  };

  return (
    <ListItemContainer>
      <ListItemCodeContainer>
        <ListItemCodeTitle>COD. DA LISTA</ListItemCodeTitle>
        <ListItemCodeNumber>{order.orderCode ? order.orderCode : '-'}</ListItemCodeNumber>
      </ListItemCodeContainer>
      <DateAndStatusContainer>
        <StatusContainer color={color}>{status}</StatusContainer>
        {isSendable ? (
          <SendIndividualShoppingListModal
            residentInformation={residentInformations}
            currentListId={order.id}
            orderInformation={order}
            refetchShoppingList={refetchOrderHistory}
          >
            <BenvoTooltip
              title="Compartilhar lista"
              placement="top"
              arrow
            >
              <StyledIconButton>
                <Send />
              </StyledIconButton>
            </BenvoTooltip>
          </SendIndividualShoppingListModal>
        )
          : (
            <EmptySendButtonContainer />
          )}

        <StyledDivider flexItem orientation="vertical" />
        <BenvoTooltip
          title="Baixar lista"
          placement="top"
          arrow
        >
          <StyledIconButton onClick={() => generateShoppingListPdf(order, residentInformations)}>
            <DocumentDownload />
          </StyledIconButton>
        </BenvoTooltip>
        {mapActionButton()}
      </DateAndStatusContainer>
    </ListItemContainer>
  );
}
