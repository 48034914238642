import React from 'react';

export default function ArrowLeft() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.81 22L16.19 22C19.83 22 22 19.83 22 16.19L22 7.82C22 4.17 19.83 2 16.19 2L7.82 2C4.18 2 2.01 4.17 2.01 7.81L2.01 16.19C2 19.83 4.17 22 7.81 22ZM9.21 11.47L12.74 7.94C12.89 7.79 13.08 7.72 13.27 7.72C13.46 7.72 13.65 7.79 13.8 7.94C14.09 8.23 14.09 8.71 13.8 9L10.8 12L13.8 15C14.09 15.29 14.09 15.77 13.8 16.06C13.51 16.35 13.03 16.35 12.74 16.06L9.21 12.53C8.91 12.24 8.91 11.76 9.21 11.47Z" fill="#96969E" />
    </svg>

  );
}
