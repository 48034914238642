import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Visibility } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import ConfirmationDialog from '../../../../components/confirmationDialog';
import { useAlert } from '../../../../context/alertContext';
import routes from '../../../../services/routes';
import { residentPageContext } from '../../../../context/residentPageContext';
import MTable from '../../../../components/MuiTable';
import ModalVisualizedPrescription from '../ModalVisualizedPrescription';
import PATHS from '../../../../constants/applicationPaths';

function TablePrescription({ prescriptionList, onInactivateSuccess }) {
  const { prescription } = routes;
  const { setTab } = useContext(residentPageContext);
  const [modalVisualizedIsopen, setOpenModalVisualizedIsOpen] = useState(false);
  const [prescriptionId, setPrescriptionId] = useState('');
  const [selectedResidentName, setSelectedResidentName] = useState('');

  const navigate = useNavigate();
  const alert = useAlert();

  const handleOpenModalVisualized = (rowPrescriptionId, rowResidentName) => {
    setSelectedResidentName(rowResidentName);
    setPrescriptionId(rowPrescriptionId);
    setOpenModalVisualizedIsOpen(true);
  };

  const handleModalVisualizedClose = () => {
    setOpenModalVisualizedIsOpen(!modalVisualizedIsopen);
  };

  const handleInactivatePrescription = async (thisPrescriptionId) => {
    try {
      await prescription.removePrescription({ prescriptionId: thisPrescriptionId });

      onInactivateSuccess();
    } catch (error) {
      alert.error('Ocorreu um erro na solicitação, tente novamente mais tarde');
    }
  };

  const handleClickResident = (id) => {
    setTab('prescription');
    navigate(`${PATHS.residents.root}/${id}`);
  };

  const tableCellStyle = {
    cursor: 'pointer',
  };

  return (
    <>
      <ModalVisualizedPrescription
        isOpen={modalVisualizedIsopen}
        handleModalVisualizedClose={handleModalVisualizedClose}
        selectedResidentName={selectedResidentName}
        prescriptionId={prescriptionId}
      />

      <MTable.Container sx={{ mt: 4 }}>
        <MTable.Root>
          <MTable.Head>
            <MTable.Row>
              <MTable.Cell>Residente</MTable.Cell>
              <MTable.Cell>Criado em</MTable.Cell>
              <MTable.Cell>Ações</MTable.Cell>
            </MTable.Row>
          </MTable.Head>
          <MTable.Body>
            {prescriptionList?.map((thisPrescription, index) => (
              <MTable.Row key={thisPrescription.id}>
                <MTable.Cell
                  data-testid={`prescription-${index}`}
                  onClick={() => handleClickResident(thisPrescription.residentId)}
                  style={tableCellStyle}
                >
                  {thisPrescription.residentName}
                </MTable.Cell>

                <MTable.Cell
                  onClick={() => handleClickResident(thisPrescription.residentId)}
                  style={tableCellStyle}
                >
                  {moment(thisPrescription.createdAt).format('DD/MM/YYYY')}
                </MTable.Cell>

                <MTable.Cell>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: 4,
                    }}
                  >
                    <Tooltip title="Visualizar Prescrição">
                      <IconButton
                        data-testid={`prescription-visualize-${index}`}
                        color="primary"
                        onClick={() => {
                          handleOpenModalVisualized(thisPrescription.id, thisPrescription.residentName);
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>

                    <ConfirmationDialog
                      title="Deletar Prescrição"
                      message="Tem certeza que deseja deletar essa prescrição?"
                      handleConfirm={() => handleInactivatePrescription(thisPrescription.id)}
                      testId={`prescription-delete-${index}`}
                    />
                  </div>
                </MTable.Cell>
              </MTable.Row>
            ))}
          </MTable.Body>
        </MTable.Root>
      </MTable.Container>
    </>
  );
}

export default TablePrescription;
