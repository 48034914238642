/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  Container,
  EmptyImage,
  ImageContainer,
  LoadingContainer,
  SearchResultContainer,
  StyledSeachField,
  // StyledTextField,
  TextAndSearchContainer,
} from './style';
import ProductSearchResultContainer from './components/ProductSearchResultContainer';
import routes from '../../../../services/routes';
import EmptySearchState from '../../../../assets/residents/empty-resident-shopping-list-search.png';

const { shoppingList } = routes;
export default function AddProductCard({ setSelectedProductsList, selectedProductsList }) {
  const [searchParams, setSearchParams] = useState({});
  // const [currentNotFoundItem, setCurrentNotFoundItem] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (event) => {
    const { value } = event.target;

    setSearchParams({ name: value });
  };

  const getProductsList = async () => {
    setIsLoading(true);
    const normalizedValue = searchParams.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    try {
      const { message } = await shoppingList.getProducts(
        { name: normalizedValue },
      );
      // if (message.length === 0) {
      //   setCurrentNotFoundItem(searchParams.name.toUpperCase());
      // }

      return message;
    } catch (error) {
      // setCurrentNotFoundItem(searchParams.name.toUpperCase());

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const { data: productList = [], refetch } = useQuery({
    queryKey: ['residentProducts'],
    queryFn: getProductsList,
    refetchOnWindowFocus: false,
    enabled: false,
    cacheTime: 0,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchParams.name && searchParams.name.length >= 3) {
        refetch();
      }
    }, 800);
    return () => clearTimeout(timer);
  }, [searchParams]);

  const groupByTag = (products) => {
    products.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
    const tags = products?.reduce((acc, product) => {
      if (product.tag !== 'replaced') {
        acc[product.tag] = [...(acc[product.tag] || []), product];
      }
      return acc;
    }, {});

    return Object.entries(tags).sort((a, b) => {
      if (a[0] === 'matched') {
        return -1;
      }
      if (b[0] === 'matched') {
        return 1;
      }
      if (a[0] === 'generic') {
        return -1;
      }
      if (b[0] === 'generic') {
        return 1;
      }
      return 0;
    });
  };

  const translateTag = (tag) => {
    switch (tag) {
      case 'matched':
        return 'Produtos encontrados';
      case 'generic':
        return 'Por Princípio Ativo';
      default:
        return 'Outros';
    }
  };

  // const handleNotFoundItemChange = (event) => {
  //   setCurrentNotFoundItem(event.target.value.toUpperCase());
  // };

  // const addNotFoundItem = () => {
  //   setSelectedProductsList((prev) => [...prev, {
  //     externalId: uuidv4(),
  //     quantity: 1,
  //     name: currentNotFoundItem,
  //     manuallyEntered: true,
  //   }]);
  //   setCurrentNotFoundItem('');
  //   setSearchParams({ name: '' });
  // };

  // const handleNoProductListComponent = () => {
  //   if (currentNotFoundItem) {
  //     return (
  //       <ImageContainer>
  //         <EmptyImage src={NotFoundItems} alt="Item não encontrado" />
  //         <div style={{
  //           display: 'flex',
  //           flexDirection: 'column',
  //           alignItems: 'center',
  //           paddingTop: '22px',
  //           gap: '22px',
  //         }}
  //         >
  //           <StyledTextField
  //             value={currentNotFoundItem}
  //             onChange={handleNotFoundItemChange}
  //           />
  //           <BenvoButton
  //             onClick={addNotFoundItem}
  //           >
  //             Adicionar
  //           </BenvoButton>
  //         </div>
  //       </ImageContainer>
  //     );
  //   }

  //   return (
  //     <ImageContainer>
  //       <EmptyImage src={EmptySearchState} alt="Sem resultados" />
  //     </ImageContainer>
  //   );
  // };

  return (
    <Container>
      <TextAndSearchContainer>
        <Typography variant="h4">Adicionar produto</Typography>
        <StyledSeachField
          value={searchParams.name || ''}
          onChange={handleSearch}
          placeholder="Pesquisar produto"
        />
      </TextAndSearchContainer>
      {isLoading ? (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      ) : productList.length === 0
        ? (
          <ImageContainer>
            <EmptyImage src={EmptySearchState} alt="Sem resultados" />
          </ImageContainer>
        )
        : (
          <SearchResultContainer>
            {groupByTag(productList).map(([tag, products]) => (
              <>
                <Typography variant="h6">{translateTag(tag)}</Typography>
                <ProductSearchResultContainer
                  productList={products}
                  setSelectedProductsList={setSelectedProductsList}
                  selectedProductsList={selectedProductsList}
                />
              </>
            ))}
          </SearchResultContainer>
        )}
    </Container>
  );
}
