import { Box, styled } from '@mui/material';
import BenvoSearchField from '../../../../components/BenvoSearchField';

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 506px;
  padding: 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 6px;
  background: #fff;
`;

export const TextAndSearchContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;

  h4 {
    color: #474750;

    font-size: 16px;
    font-weight: 500;
    line-height: 115%; /* 18.4px */
    letter-spacing: 0.024px;
  }
`;

export const StyledSeachField = styled(BenvoSearchField)`
  width: 100%;
`;

export const SearchResultContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  overflow-y: auto;
  padding-right: 4px;

  h6 {
    color: #96969e;

    font-size: 12px;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
    letter-spacing: 0.12px;
  }
`;

export const ImageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const EmptyImage = styled('img')`
  align-self: center;
`;

export const LoadingContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
