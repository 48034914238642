import {
  Box, Button, Card, Divider, Grid, Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import OrderInformation from './OrderInformation';
import { formatCurrencyAndAddRealSymbol } from '../../../utils/currencyFormat';

const formatDate = (date) => moment(date)
  .format('DD MMM YYYY')
  .split(' ')
  .join(' DE ')
  .toLocaleUpperCase();

function OrderTextField({ label, value }) {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '12px',
      bgcolor: '#F5F5F7',
      borderRadius: '12px',
    }}
    >
      <Typography variant="p">{label}</Typography>
      <Typography variant="p">{value}</Typography>
    </Box>
  );
}

export default function OrderCard({ order }) {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const handleOpenDrawer = () => setDrawerIsOpen(true);

  const handleCloseDrawer = () => setDrawerIsOpen(false);

  return (
    <>
      <OrderInformation
        open={drawerIsOpen}
        onClose={handleCloseDrawer}
        order={order}
      />

      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
        <Card
          sx={{
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '13px',
            maxHeight: '335px',
            minHeight: '335px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  background: '#474750',
                  p: '4px 12px',
                  color: '#FFFFFF',
                  display: 'inline-block',
                  borderRadius: '6px',
                  fontSize: '12px',
                  mb: '8px',
                }}
              >
                {formatDate(order.createdAt)}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="body1"
                sx={{
                  textTransform: 'uppercase',
                  color: '#474750',
                  fontSize: '12px',
                }}
              >
                Fornecedor:
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontWeight: '500',
                  color: '#474750',
                  fontSize: '20px',
                }}
              >
                {order.sellerName}
              </Typography>
            </Box>

            <OrderTextField label="Itens" value={order?.orderItems.length} />
            <OrderTextField label="Valor total" value={formatCurrencyAndAddRealSymbol(order.totalPrice)} />

            <Divider sx={{ my: '8px', borderColor: '#EAEBEC', borderWidth: '1.19px' }} />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={handleOpenDrawer}
                sx={{
                  background: '#F5F5F7',
                  color: '#4A397F',
                  borderRadius: '80px',
                  textTransform: 'none',
                  fontSize: '16px',
                  p: '10px 34px',
                }}
              >
                Visualizar
              </Button>
            </Box>
          </Box>
        </Card>
      </Grid>
    </>
  );
}
