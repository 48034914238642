import React, { useEffect, useState } from 'react';

import BenvoButton from '../../../../components/BenvoButton/style';
import ResponsibleConflictImage from '../../../../assets/residents/responsibleConflictImage.webp';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from './style';
import { phoneWithCountryCodeMaskAndSpace } from '../../../../utils/inputMasks';

export default function ResponsibleConflictModal({
  responsibleConflict,
  handleClose,
  editReferenceContact,
  inputValues,
}) {
  const ableToEdit = responsibleConflict.responsible.length === 1;
  const [emailOrPhone, setEmailOrPhone] = useState({
    label: '',
    value: null,
  });

  useEffect(() => {
    if (ableToEdit) {
      if (inputValues.email
        && responsibleConflict.responsible[0].email
        && inputValues.email === responsibleConflict.responsible[0].email
        && inputValues.phone
        && inputValues.phone === responsibleConflict.responsible[0].phone) {
        setEmailOrPhone({
          label: null,
          value: `${phoneWithCountryCodeMaskAndSpace(responsibleConflict.responsible[0].phone)}, ${inputValues.email}`,
        });
        return;
      }

      if (
        inputValues.email
        && responsibleConflict.responsible[0].email
        && inputValues.email === responsibleConflict.responsible[0].email
      ) {
        setEmailOrPhone({
          label: 'E-mail',
          value: responsibleConflict.responsible[0].email,
        });
      }
      if (
        inputValues.phone
        && inputValues.phone === responsibleConflict.responsible[0].phone
      ) {
        setEmailOrPhone({
          label: 'Telefone',
          value: phoneWithCountryCodeMaskAndSpace(responsibleConflict.responsible[0].phone),
        });
      }
    }
  }, [ableToEdit, responsibleConflict.responsible, inputValues]);

  return (
    <StyledDialog open={responsibleConflict.open} onClose={handleClose}>
      <img src={ResponsibleConflictImage} alt="Conflito de Responsáveis" />
      <StyledDialogTitle>
        {ableToEdit && emailOrPhone.label
          ? `${emailOrPhone.label} já cadastrado`
          : 'Contato cadastrado'}
      </StyledDialogTitle>
      <StyledDialogContent ableToEdit={ableToEdit && emailOrPhone.label}>
        {ableToEdit ? (
          <p>
            {emailOrPhone.label
              ? `O ${emailOrPhone.label.toLowerCase()} informado está vinculado ao contato abaixo. 
                Deseja utilizar esse contato?`
              : 'Os dados cadastrados estão vinculados ao contato abaixo. Deseja utilizar esse contato?'}
          </p>
        ) : (
          <p>
            {responsibleConflict.responsible
              .some((responsible) => responsible.isEditing)
              ? 'Esse telefone (ou e-mail) está sendo utilizado para o contato abaixo. Caso você queira adicionar esse tutor para o residente, clique no botão "+" e inclua um novo contato.'
              : 'O e-mail e telefone correspondem a duas pessoas de referência diferentes já cadastradas. Verifique as informações e corrija por favor.'}
          </p>
        )}
        <ul>
          {responsibleConflict.responsible?.map((responsible) => {
            if (responsible.isEditing) {
              return null;
            }

            if (ableToEdit) {
              return (
                <li key={responsible.id}>
                  <div>
                    <p>{responsible.name}</p>
                    <span>{emailOrPhone.value}</span>
                  </div>
                </li>
              );
            }

            return (
              <li key={responsible.id}>
                <p>{responsible.name}</p>
                <div>
                  {responsible.phone && (
                    <span>{`${phoneWithCountryCodeMaskAndSpace(responsible.phone)}`}</span>
                  )}
                  {responsible.email && (
                  <span>
                    {`, ${responsible.email}`}
                  </span>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </StyledDialogContent>
      {ableToEdit ? (
        <StyledDialogActions>
          <BenvoButton
            onClick={handleClose}
            variant="secondary"
            horizontalPadding={30.5}
          >
            Cancelar
          </BenvoButton>
          <BenvoButton
            onClick={editReferenceContact}
            variant="primary"
            horizontalPadding={50}
          >
            Sim
          </BenvoButton>
        </StyledDialogActions>
      ) : (
        <StyledDialogActions>
          <BenvoButton
            onClick={handleClose}
            variant="secondary"
            horizontalPadding={118}
          >
            Cancelar
          </BenvoButton>
        </StyledDialogActions>
      )}
    </StyledDialog>
  );
}
