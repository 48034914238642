/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  FormControlLabel, Link, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { CheckBox } from '@mui/icons-material';
import BenvoIconLogin from '../../assets/BenvoIconLogin.png';
import {
  StyledBoxContainer, StyledCheckBox,
} from './style';
import BenvoButton from '../../components/BenvoButton/style';
import { getTokenInfo } from '../../utils/configAuth';
import routes from '../../services/routes';
import { useAlert } from '../../context/alertContext';
import TermsCheckboxLabel from './components/TermsCheckboxLabel';
import PATHS from '../../constants/applicationPaths';

function Terms() {
  const alert = useAlert();
  const navigate = useNavigate();
  const [read, setRead] = useState({ readTerms: false, readPrivacy: false });
  const ilpiId = getTokenInfo('ilpiId');
  if (!ilpiId) navigate('/login');

  const acceptTerms = async () => {
    try {
      await routes.ilpi.updateReadTerms({ ilpiId });
      navigate('/home');
    } catch (error) {
      alert.error('Ocorreu um erro.', 'Tente novamente mais tarde.');
    }
  };

  const handleChange = (event) => {
    setRead({ ...read, [event.target.name]: event.target.checked });
  };

  return (
    <StyledBoxContainer>
      <img
        src={BenvoIconLogin}
        alt="Logo Benvo"
        width={208}
      />
      <Typography>Olá!</Typography>
      <Typography align="center">
        {'Queremos manter você informado(a) sobre as mudanças em nossos termos de uso e política de privacidade. '}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '50px',
      }}
      >
        <FormControlLabel
          control={<StyledCheckBox onChange={handleChange} name="readTerms" />}
          label={<TermsCheckboxLabel href={PATHS.public.termsAndConditions} text="Termos de Uso" />}
        />
        <FormControlLabel
          control={<StyledCheckBox onChange={handleChange} name="readPrivacy" />}
          label={<TermsCheckboxLabel href={PATHS.public.privacyPolicy} text="Políticas de Privacidade" />}
        />
      </Box>
      <Typography align="center">
        {'Assim que você ler e concordar com os termos, o botão "Aceitar" será ativado, permitindo que você prossiga sem interrupções. '}
      </Typography>
      <BenvoButton
        disabled={!(read.readTerms && read.readPrivacy)}
        onClick={acceptTerms}
      >
        Aceitar
      </BenvoButton>
    </StyledBoxContainer>
  );
}

export default Terms;
