import { createTheme } from '@mui/material';

const defaultTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#4A397F',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f34f54',
      text: '',
    },
    action: {
      hover: '#fbffff',
    },
    cancel: {
      main: '#B11D33',
      contrastText: '#ffffff',
    },
    purple: {
      main: '#7A5DEF',
      contrastText: '#ffffff',
    },
    success: {
      main: '#52A569',
      contrastText: '#ffffff',
    },
    error: {
      main: '#A73737',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FFB620',
      text: '#ffffff',
      contrastText: '#ffffff',
    },
    info: {
      main: '#4A397F',
      contrastText: '#000000',
    },
    white: {
      main: '#ffffff',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  shape: {
    borderRadius: 5,
  },
  props: {
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
  },
});

export default defaultTheme;
