import {
  Box, CircularProgress, Divider, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import CreateMarketList from './components/CreateMarketList';
import BenvoSecondaryButton from '../../components/BenvoSecondaryButton';
import routes from '../../services/routes';
import { getTokenInfo } from '../../utils/configAuth';
import BenvoPagination from '../../components/BenvoPagination';
import PATHS from '../../constants/applicationPaths';
import BenvoDeleteModal from '../../components/BenvoDeleteModal';
import { useAlert } from '../../context/alertContext';
import BenvoEmpty from '../../components/BenvoEmpty';
import BenvoContentBox from '../../components/BenvoContentBox';

const { marketplace: marketplaceRoute } = routes;

export default function Marketplace() {
  const ilpiId = getTokenInfo('ilpiId');
  const [page, setPage] = useState(0);
  const alert = useAlert();

  const getMarketplaceList = async (currentPage) => {
    const { message } = await marketplaceRoute.getMarketplaceList({ ilpiId }, { page: currentPage });
    return message;
  };

  const {
    data: marketplaceList = {
      rows: [],
    }, refetch: refetchMarketplaceList, isLoading,
  } = useQuery({
    queryKey: ['marketplaceList', page],
    queryFn: () => getMarketplaceList(page),
    retry: 0,
    cacheTime: 0,
  });

  const handleDelete = async (listId) => {
    try {
      const response = await marketplaceRoute.deleteMarketplaceList({ listId });
      refetchMarketplaceList();
      alert.success('Lista excluída com sucesso!');
      return response;
    } catch (error) {
      alert.error('Erro ao excluir lista!');
      return error;
    }
  };

  return (
    <BenvoContentBox>
      {marketplaceList?.rows.length > 0 && (
        <Box
          sx={{
            mt: '24px',
            mb: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: '16px',
          }}
        >
          <Typography variant="h6" color="#474750" sx={{ fontSize: '18px' }}>
            Listas Disponíveis
          </Typography>
          <CreateMarketList
            marketplaceList={marketplaceList}
            refetchMarketplaceList={refetchMarketplaceList}
          />
        </Box>
      )}

      {isLoading && marketplaceList.rows ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '65vh',
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Box
          sx={{
            pt: '4px',
            px: '16px',
            overflowY: 'auto',
            height: marketplaceList?.rows.length > 0 ? 'calc(100vh - 330px)' : 'calc(100vh - 160px)',
            maxHeight: marketplaceList?.rows.length > 0 ? 'calc(100vh - 330px)' : 'calc(100vh - 160px)',
            '&::-webkit-scrollbar': {
              width: '4px',
              height: '4px',
              backgroundColor: '#F5F5F7',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#4A397F',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#F5F5F7',
            },
          }}
        >
          <BenvoEmpty
            active={!marketplaceList.rows.length}
            title="Marketplace"
            message="Comece agora mesmo criando a sua primeira lista.
             Com ela você poderá facilmente fazer um orçamento e realizar o pedido dos itens desejados."
          >
            <CreateMarketList
              marketplaceList={marketplaceList}
              refetchMarketplaceList={refetchMarketplaceList}
              buttonTitle="Criar uma lista"
            />
          </BenvoEmpty>

          {marketplaceList?.rows?.map((marketplace) => (
            <Box
              data-testid="marketplace-list-card"
              key={marketplace.id}
              sx={{
                padding: '11px 20px',
                borderRadius: '6px',
                boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '12px',

              }}
            >
              <Box>
                <Typography
                  variant="subtitle2"
                  color="#474750"
                  fontWeight={400}
                  sx={{
                    fontSize: '10px',
                  }}
                >
                  LISTA
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#474750"
                  sx={{
                    fontWeight: '500',
                    fontSize: '18px',
                  }}
                >
                  {marketplace.name}
                </Typography>
              </Box>
              {marketplace.isMain === 1 && (
                <Box
                  data-testid="main-list-label"
                  sx={{
                    mr: 'auto',
                    ml: '20px',
                    backgroundColor: '#4A397F',
                    padding: '5.231px 15.692px',
                    borderRadius: '6px',
                    color: '#fff',
                    fontSize: '12px',
                  }}
                >
                  Lista principal
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="#474750"
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    DATA CRIAÇÃO
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="#474750"
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    {moment(marketplace.createdAt).format('DD [de] MMMM [de] YYYY')}
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                {marketplace.isMain === 0 && (
                  <BenvoDeleteModal
                    testid="benvo-delete-btn"
                    handleDelete={() => handleDelete(marketplace.id)}
                    title="Apagar lista"
                    description="Deseja apagar essa lista? Essa ação não pode ser desfeita."
                    buttonText="Excluir lista"
                  />
                )}

                <Link to={`${PATHS.marketplace}/${marketplace.id}`}>
                  <BenvoSecondaryButton data-testid="open-list-btn">
                    Visualizar
                  </BenvoSecondaryButton>
                </Link>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {(marketplaceList?.rows.length > 0 && marketplaceList.totalPages > 1) && (
        <BenvoPagination
          count={marketplaceList.totalPages}
          onChange={(event, value) => setPage(value - 1)}
        />
      )}
    </BenvoContentBox>
  );
}
