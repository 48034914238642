import { Box, Typography, styled } from '@mui/material';
import Gradient from '../../../../assets/healthService/card-degrade.png';
import Image from '../../../../assets/healthService/exam-request-image.png';

export const CardContainer = styled(Box)`
  width: 348px;
  height: 313px;
  flex-shrink: 0;
  border-radius: 6px;
  background: url(${Gradient});

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 26px 21.37px 27px 22px;
`;

export const CardImage = styled(Box)`
  border-radius: 6px;
  background: url(${Image});
  width: 304px;
  height: 194px;
`;

export const TextAndButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 2px;

  a {
    text-decoration: none;
  }
`;

export const CardText = styled(Typography)`
  width: 170.9px;

  color: var(--paleta-senior-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 20.7px */
  letter-spacing: 0.027px;
`;
