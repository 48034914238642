import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import seniorLogo from '../assets/logo-benvo.png';
import routes from '../services/routes';

const seniorUrl = 'senioramais.com.br';
const addImage = (doc) => doc.addImage(seniorLogo, 'PNG', 55, 12, 100, 14, 'center');

export const generateListPdf = async (listInformation) => {
  const keys = {
    product: 'Produtos',
    market: 'Itens de mercado',
    medication: 'Medicações',
    flv: 'Frutas, legumes e verduras',
  };
  const doc = new JsPDF({ unit: 'mm' });
  let currentY = 35;

  addImage(doc);
  doc.setFont('helvetica', 'bold');

  currentY += 5;

  Object.entries(listInformation.list).forEach(([key, list]) => {
    if (!list.length) return;

    doc.setFont('helvetica', 'bold');
    doc.text(keys[key], 20, (currentY += 10));
    doc.setFont('helvetica', 'normal');

    list.forEach((item) => {
      const splitText = doc.splitTextToSize(`•  ${item.item} - ${item.quantity} ${item.measure}`, 170);

      splitText.forEach((text) => {
        doc.text(text, 20, (currentY += 10));
      });

      doc.line(20, (currentY += 3), 190, currentY);
    });

    currentY += 5;
  });

  const { w } = doc.getTextDimensions(seniorUrl);

  doc.textWithLink(seniorUrl, (doc.internal.pageSize.getWidth() - w) / 2, 290, {
    url: 'https://senioramais.com.br',
  });

  return doc.save(`${listInformation.residentName}-${moment(listInformation.date).format('DD-MM-YYYY')}`);
};

const wellBeing = (doc, data, yAxios) => {
  doc.setFont('helvetica', 'bold');
  doc.text('Humor:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.humor}`, 39, yAxios);

  doc.setFont('helvetica', 'bold');
  doc.text('Apetite:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.appetite}`, 40, yAxios);

  doc.setFont('helvetica', 'bold');
  doc.text('Sono:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.repose}`, 36, yAxios);
};

const vitalSigns = (doc, data, yAxios) => {
  doc.setFont('helvetica', 'bold');
  doc.text('Pressão arterial sistólica:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.systolicBloodPressure}`, 81, yAxios);

  doc.setFont('helvetica', 'bold');
  doc.text('Pressão arterial diastólica:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.diastolicBloodPressure}`, 84, yAxios);

  doc.setFont('helvetica', 'bold');
  doc.text('Frequência respiratória:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.respiratoryFrequency}`, 78, yAxios);

  doc.setFont('helvetica', 'bold');
  doc.text('Frequência cardíaca:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.heartFrequency}`, 70, yAxios);

  doc.setFont('helvetica', 'bold');
  doc.text('Saturação do oxigênio:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.oxygenSaturation}`, 76, yAxios);

  doc.setFont('helvetica', 'bold');
  doc.text('Temperatura:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.temperature}`, 53, yAxios);

  doc.setFont('helvetica', 'bold');
  doc.text('HGT:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.hgt}`, 34, yAxios);
};

const diuresis = (doc, data, yAxios) => {
  doc.setFont('helvetica', 'bold');
  doc.text('Diurese:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.diuresis}`, 41, yAxios);

  doc.setFont('helvetica', 'bold');
  doc.text('Evacuação:', 20, (yAxios += 8));
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.evacuation}`, 49, yAxios);
};

export const generatePdfEvolution = async (data) => {
  const {
    date, kind, name, observation, responsibleName, status,
  } = data;

  const yAxios = 81;

  const formatedDate = moment(date).format('DD/MM/YYYY');

  const doc = new JsPDF({ unit: 'mm' });
  doc.setLanguage('pt-BR');

  addImage(doc);
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  // Header
  doc.text('Registro de Evolução', 105, 36, null, null, 'center');
  doc.setFont('helvetica', 'normal');
  doc.text(`Responsável: ${responsibleName}`, 105, 41, null, null, 'center');
  doc.text(`Residente: ${name}`, 105, 46, null, null, 'center');
  doc.text(`Data: ${formatedDate}`, 105, 51, null, null, 'center');

  // Status
  doc.setFontSize(24);
  switch (status) {
    case 'Atenção':
      doc.setTextColor(229, 119, 52);
      break;
    case 'Alerta':
      doc.setTextColor(242, 60, 60);
      break;
    default:
      break;
  }
  doc.text(`Status: ${status}`, 105, 71, null, null, 'center');

  // Body
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(14);
  switch (kind) {
    case 'Bem Estar':
      wellBeing(doc, data, yAxios);
      break;
    case 'Sinais Vitais':
      vitalSigns(doc, data, yAxios);
      break;
    case 'Diurese':
      diuresis(doc, data, yAxios);
      break;
    default:
      break;
  }
  // Observations
  doc.text(`Observações: ${observation || 'Não há observação.'}`, 20, 270, null, null);

  return doc.save(`evolucao-${name}-${moment(date).format('DD-MM-YYYY')}`);
};

export const generateShoppingListPdf = async (order, resident) => {
  const { ilpiId } = resident;
  const {
    message: { name: ilpiName },
  } = await routes.ilpi.getIlpiById({ ilpiId });
  const listDate = moment(order?.maximumDeliveryDate).add(3, 'hours').format('DD/MM/YYYY');

  let pageCount = 0;
  const startX = 15;
  const line = 8;

  const pdf = new JsPDF('p', 'mm', 'a4', true);
  pdf.setLanguage('pt-BR');

  const setHeaderAndFooter = () => {
    // Header
    let currentY = 27;
    pageCount += 1;

    currentY += line;

    if (pageCount === 1) {
      pdf.setFontSize(12);

      pdf.setFont('helvetica', 'bold').text('Residente:', startX, currentY += line);
      pdf.setFont('helvetica', 'normal').text(resident.name, 37, currentY);

      pdf.setFont('helvetica', 'bold').text('Residencial:', startX, currentY += line);
      pdf.setFont('helvetica', 'normal').text(ilpiName, 41, currentY);

      pdf.setFont('helvetica', 'bold').text('Código da Lista:', startX, currentY += line);
      pdf.setFont('helvetica', 'normal').text(order?.orderCode || 'Não gerado', 49, currentY);

      if (order?.maximumDeliveryDate) {
        pdf.setFont('helvetica', 'bold').text('Limite de Entrega:', startX, currentY += line);
        pdf.setFont('helvetica', 'normal').text(listDate, 53, currentY);
      }
    }

    // Footer
    pdf.text(`Página ${pageCount}`, 180, 285);
  };

  addImage(pdf);

  const body = order?.products
    .filter(({ quantity }) => quantity > 0)
    .map(({ name, quantity, rawQuantity }) => {
      if (!rawQuantity) {
        return ([name, `${quantity} un`]);
      }
      name = name.replace(/\s\d+\s*(compr[ií]midos?|comp\.?|c[aá]psulas?)/i, '');
      return ([name, `${rawQuantity} cps`]);
    });

  autoTable(pdf, {
    startY: 75,
    head: [['Produto', 'Qtd.']],
    theme: 'striped',
    headStyles: {
      fillColor: [58, 59, 60],
      textColor: [255, 255, 255],
      fontStyle: 'bold',
    },
    columnStyles: {
      0: { cellWidth: 160, halign: 'left' },
      1: { cellWidth: 30, halign: 'center' },
    },
    body,
    bodyStyles: {
      valign: 'middle',
    },
    willDrawPage: () => {
      setHeaderAndFooter();
    },
    didParseCell: (data) => {
      if (data.section === 'head') {
        if (data.column.index === 0) {
          data.cell.styles.halign = 'left';
        } else if (data.column.index === 1) {
          data.cell.styles.halign = 'center';
        }
      }
    },
  });

  pdf.save(`Lista Compras - ${moment().format('DD-MM-YYYY')}.pdf`);
};
