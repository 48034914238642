import React, { useState } from 'react';
import {
  Box,
  Button,
  Chip,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import {
  cnpjInputMask,
  daysMask,
  moneyMask,
  percentMask,
  removeMask,
} from '../../../../../utils/inputMasks';
import { useAlert } from '../../../../../context/alertContext';
import { StyledGridEmail, StyledLi, StyledUl } from './style';

const paymentOptions = [
  {
    label: 'Dinheiro',
    value: 'cash',
  },
  {
    label: 'Cartão de crédito',
    value: 'credit',
  },
  {
    label: 'Cartão de débito',
    value: 'debit',
  },
  {
    label: 'Pix',
    value: 'pix',
  },
  {
    label: 'Boleto',
    value: 'bankSlip',
  },
];

const targetTypeOptions = [
  {
    label: 'B2B',
    value: 'b2b',
  },
  {
    label: 'B2C',
    value: 'b2c',
  },
];

export default function GeneralInfoForm({
  otherEmails,
  setOtherEmails,
  errors,
  register,
  watch,
  setValue,
  takeRate,
  setTakeRate,
  estimatedDeliveryTime,
  setEstimatedDeliveryTime,
  minimumOrder,
  setMinimumOrder,
}) {
  const [currentEmail, setCurrentEmail] = useState('');

  const alert = useAlert();

  const addEmail = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    const emailAlreadyExists = otherEmails.includes(currentEmail);

    if (!emailRegex.test(currentEmail)) {
      alert.error('Email inválido');
    } else if (emailAlreadyExists) {
      alert.error('Esse email já foi adicionado');
      setCurrentEmail('');
    } else {
      setOtherEmails([currentEmail, ...otherEmails]);
      setCurrentEmail('');
    }
  };

  const removeEmail = (email) => setOtherEmails(otherEmails.filter((item) => item !== email));

  const handleOtherEmailChange = ({ target: { value } }) => setCurrentEmail(value);

  const handleChange = ({ target: { name, value } }) => {
    switch (name) {
      case 'takeRate': {
        setTakeRate(percentMask(value));
        setValue(name, removeMask(value));
        break;
      }
      case 'estimatedDeliveryTime': {
        setEstimatedDeliveryTime(daysMask(value));
        setValue(name, removeMask(value));
        break;
      }
      case 'minimumOrder': {
        setMinimumOrder(moneyMask(value));
        setValue(name, removeMask(value));
        break;
      }
      default:
        break;
    }
  };

  const onDelete = ({ key, target: { name } }) => {
    switch (name) {
      case 'takeRate': {
        if (key === 'Backspace' || key === 'Delete') {
          setTakeRate('');
          setValue(name, '');
        }
        break;
      }
      case 'estimatedDeliveryTime': {
        if (key === 'Backspace' || key === 'Delete') {
          setEstimatedDeliveryTime('');
          setValue(name, '');
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Typography
          variant="h6"
          component="h2"
          color="primary"
          sx={{ fontWeight: 'bold', mb: 2 }}
        >
          Informações do Fornecedor
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          size="small"
          fullWidth
          label="Nome"
          error={!!errors.name}
          helperText={errors.name?.message}
          value={watch('name')}
          {...register('name')}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          label="CNPJ"
          inputProps={{ maxLength: 18 }}
          error={!!errors.cnpj}
          helperText={errors.cnpj?.message}
          value={cnpjInputMask(watch('cnpj'))}
          {...register('cnpj')}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          type="email"
          size="small"
          fullWidth
          label="Email principal"
          error={!!errors.email}
          helperText={errors.email?.message}
          value={watch('email')}
          {...register('email')}
        />
      </Grid>

      <StyledGridEmail
        item
        xs={12}
        sm={6}
      >
        <>
          <TextField
            type="email"
            size="small"
            fullWidth
            label="Outros emails"
            value={currentEmail}
            onChange={handleOtherEmailChange}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={addEmail}
          >
            Adicionar
          </Button>
        </>
      </StyledGridEmail>

      <Grid item xs={12}>
        {otherEmails.length > 0 && (
        <StyledUl>
          {otherEmails.map((email, index) => (
            <StyledLi
              key={email}
              index={index}
            >
              <Typography variant="body2">
                {email}
              </Typography>

              <IconButton
                size="small"
                color="error"
                onClick={() => removeEmail(email)}
              >
                <Delete />
              </IconButton>
            </StyledLi>
          ))}
        </StyledUl>
        )}
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          size="small"
          fullWidth
          name="takeRate"
          label="Taxa de Repasse"
          placeholder="Em %"
          onChange={handleChange}
          onKeyDown={onDelete}
          value={takeRate}
          error={!!errors.takeRate}
          helperText={errors.takeRate?.message}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          size="small"
          fullWidth
          name="estimatedDeliveryTime"
          label="Estimativa de Entrega"
          placeholder="Em dias"
          onChange={handleChange}
          onKeyDown={onDelete}
          error={!!errors.estimatedDeliveryTime}
          helperText={errors.estimatedDeliveryTime?.message}
          value={estimatedDeliveryTime}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          type="time"
          size="small"
          fullWidth
          name="deliveryOrderTimeLimit"
          label="Tempo Limite para Entrega"
          error={!!errors.deliveryOrderTimeLimit}
          helperText={errors.deliveryOrderTimeLimit?.message}
          {...register('deliveryOrderTimeLimit')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          size="small"
          fullWidth
          name="minimumOrder"
          label="Pedido Mínimo"
          placeholder="Em R$"
          onChange={handleChange}
          onKeyDown={onDelete}
          error={!!errors.minimumOrder}
          helperText={errors.minimumOrder?.message}
          value={minimumOrder}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography
          variant="h6"
          component="h2"
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          Público Alvo
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <InputLabel id="targetType">
          Selecione o público alvo do fornecedor
        </InputLabel>
        <Select
          fullWidth
          size="small"
          labelId="targetType"
          multiple
          value={watch('targetType') || []}
          error={!!errors.targetType}
          helperText={errors.targetType?.message}
          {...register('targetType')}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    targetTypeOptions.find((option) => option.value === value)
                      ?.label
                  }
                />
              ))}
            </Box>
          )}
        >
          {targetTypeOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>

        {errors.targetTypeOptions?.message && (
          <FormHelperText error>
            {errors.targetTypeOptions?.message}
          </FormHelperText>
        )}
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography
          variant="h6"
          component="h2"
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          Formas de Pagamento
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <InputLabel id="paymentOptions">
          Selecione os métodos de pagamento
        </InputLabel>
        <Select
          fullWidth
          size="small"
          labelId="paymentOptions"
          multiple
          value={watch('paymentOptions') || []}
          error={!!errors.paymentOptions}
          helperText={errors.paymentOptions?.message}
          {...register('paymentOptions')}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    paymentOptions.find((option) => option.value === value)
                      ?.label
                  }
                />
              ))}
            </Box>
          )}
        >
          {paymentOptions.map((currentMethod) => (
            <MenuItem key={currentMethod.value} value={currentMethod.value}>
              {currentMethod.label}
            </MenuItem>
          ))}
        </Select>

        {errors.paymentOptions?.message && (
          <FormHelperText error>
            {errors.paymentOptions?.message}
          </FormHelperText>
        )}
      </Grid>
    </>
  );
}
