import { ReceiptRounded } from '@mui/icons-material';
import { Button, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { generateListPdf } from '../../../../utils/generatePDFs';

function GenerateListPdfButton({ list }) {
  const [isLoading, setIsLoading] = useState(false);
  const generate = async () => {
    setIsLoading(true);
    await generateListPdf(list);
    setIsLoading(false);
  };

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={generate}
      padding="10px"
      sx={{ py: '2px' }}
    >
      {isLoading ? (
        <CircularProgress size="17px" />
      ) : (
        <ReceiptRounded sx={{ width: '18px' }} />
      )}
      <Typography component="label" style={{ marginLeft: '7px' }}>
        Gerar pdf
      </Typography>
    </Button>
  );
}

export default GenerateListPdfButton;
