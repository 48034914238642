import React, { createRef, useState } from 'react';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import {
  ActionsContainer,
  BackButtonAndTitleContainer, InformationContainer,
  StyledBenvoButton,
  StyledBenvoContainer, StyledIconButton, TitleAndUpdateInformationContainer,
  TooltipContainer,
  TitleContainer,
  TitleAndTooltipContainer,
} from './style';
import ArrowLeft from '../../../../assets/residents/ArrowLeft';
import ResidentShoppingList from './components/ResidentShoppingList';
import DocumentDownload from '../../../../assets/residents/DocumentDownload';
import SendIndividualShoppingListModal from './components/SendIndividualShoppingListModal';
import Send from '../../../../assets/residents/Send';
import routes from '../../../../services/routes';
import { getTokenInfo } from '../../../../utils/configAuth';
import { useAlert } from '../../../../context/alertContext';
import PATHS from '../../../../constants/applicationPaths';
import { generateShoppingListPdf } from '../../../../utils/generatePDFs';
import ConfirmReturnModal from './components/ConfirmReturnModal';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info-circle-red.svg';
import BenvoTooltip from '../../../../components/BenvoTooltip';

const { shoppingList } = routes;

export default function ResidentShoppingListCard({
  selectedProductsList, setSelectedProductsList,
  residentInformations, listProducts, productComparisonList,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    type: '',
  });
  const ilpiId = getTokenInfo('ilpiId');

  const path = useLocation().pathname;
  const navigate = useNavigate();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const notFoundItemsRef = createRef(null);

  const refetchListProducts = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['listProducts'],
    });
  };

  const isNewList = path.includes('nova-lista');
  const isVisualization = listProducts ? !listProducts?.canUpdate && !isNewList : false;

  const listId = path.split('/')[4];

  const createShoppingList = async () => {
    setIsLoading(true);
    try {
      const products = selectedProductsList.map(({ purchasedItem, ...product }) => ({
        ...product,
      }));
      await shoppingList.createShoppingList({
        products,
        ilpiId,
        residentId: residentInformations.id,
      });
      alert.success('Listas salva com sucesso!');
      navigate(`${PATHS.residents.root}/${residentInformations.id}/${PATHS.residents.orderHistory}`);
    } catch (error) {
      alert.error('Erro ao criar lista de compras');
      navigate(`${PATHS.residents.root}/${residentInformations.id}/${PATHS.residents.orderHistory}`);
    } finally {
      setIsLoading(false);
    }
  };

  const isThereADifferenceInTheProductList = () => {
    const originalList = JSON.stringify(productComparisonList);
    const currentList = JSON.stringify(selectedProductsList);

    return originalList !== currentList;
  };

  const updateShoppingList = async () => {
    setIsLoading(true);
    try {
      if (!isThereADifferenceInTheProductList()) {
        navigate(`${PATHS.residents.root}/${residentInformations.id}/${PATHS.residents.orderHistory}`);
        return;
      }
      await shoppingList.updateShoppingList({ listId }, {
        products: selectedProductsList,
        ilpiId,
        residentId: residentInformations.id,
      });
      alert.success('Listas salva com sucesso!');
      await refetchListProducts();
    } catch (error) {
      await refetchListProducts();
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackButton = () => {
    if (isNewList) {
      setConfirmModal({ open: true, type: 'new' });
    }
    setConfirmModal({ open: true, type: 'edit' });
  };

  const orderProducts = {
    products: selectedProductsList,
  };

  const actionButtonText = () => {
    if (isNewList) {
      return 'Criar lista';
    }
    return isThereADifferenceInTheProductList() ? 'Salvar' : 'Voltar';
  };

  return (
    <StyledBenvoContainer>
      <ConfirmReturnModal
        handler={setConfirmModal}
        state={confirmModal}
        functions={{
          create: createShoppingList,
          update: updateShoppingList,
        }}
      />
      <TitleAndUpdateInformationContainer>
        <BackButtonAndTitleContainer>
          <IconButton onClick={() => {
            if (isThereADifferenceInTheProductList()) {
              handleBackButton();
            } else {
              navigate(-1);
            }
          }}
          >
            <ArrowLeft />
          </IconButton>
          <TitleAndTooltipContainer>
            <TitleContainer>
              <Typography variant="h5">
                Lista de compra para
              </Typography>

              <Typography variant="body1">
                {residentInformations.name}
              </Typography>
            </TitleContainer>

            {selectedProductsList?.some((product) => product.found === false && !product.manuallyEntered) && (
            <TooltipContainer>
              <BenvoTooltip arrow title="Existem produtos que foram descontinuados" placement="top">
                <InfoIcon onClick={() => notFoundItemsRef.current?.scrollIntoView({ behavior: 'smooth' })} />
              </BenvoTooltip>
            </TooltipContainer>
            )}
          </TitleAndTooltipContainer>
        </BackButtonAndTitleContainer>
        <InformationContainer isNewList={isNewList}>
          <Typography variant="h5">
            COd. da lista
          </Typography>
          <Typography variant="body1">
            {listProducts?.orderCode}
          </Typography>
        </InformationContainer>
      </TitleAndUpdateInformationContainer>

      <ResidentShoppingList
        selectedProductsList={selectedProductsList}
        setSelectedProductsList={setSelectedProductsList}
        isVisualization={isVisualization}
        listProducts={listProducts}
        ref={notFoundItemsRef}
      />

      <ActionsContainer>
        {!isNewList && !isVisualization && (
          <SendIndividualShoppingListModal
            residentInformation={residentInformations}
            orderInformation={listProducts}
            refetchShoppingList={refetchListProducts}
            currentListId={listId}
          >
            <BenvoTooltip
              title={isThereADifferenceInTheProductList() ? 'Salve para compartilhar' : 'Compartilhar lista'}
              placement="top"
              arrow
            >
              <StyledIconButton
                disabled={isThereADifferenceInTheProductList() || isLoading}
              >
                <Send />
              </StyledIconButton>
            </BenvoTooltip>
          </SendIndividualShoppingListModal>
        )}
        <BenvoTooltip
          title={isThereADifferenceInTheProductList() ? 'Salve para baixar' : 'Baixar lista'}
          placement="top"
          arrow
        >
          <StyledIconButton
            disabled={selectedProductsList.length === 0 || isLoading || isThereADifferenceInTheProductList()}
            onClick={() => generateShoppingListPdf({
              ...listProducts,
              products: orderProducts.products,
            }, residentInformations)}
          >
            <DocumentDownload />
          </StyledIconButton>
        </BenvoTooltip>
        <StyledBenvoButton
          disabled={selectedProductsList.length === 0 || isLoading}
          onClick={() => (isNewList ? createShoppingList() : updateShoppingList())}
        >
          {isLoading && <CircularProgress size={20} color="inherit" />}
          {actionButtonText()}
        </StyledBenvoButton>

      </ActionsContainer>
    </StyledBenvoContainer>
  );
}
