import {
  Box, IconButton,
  styled,
} from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';
import { BenvoContainer } from '../../../../components/BenvoContainer/style';

export const StyledBenvoContainer = styled(BenvoContainer)`
  padding: 25px 22px 22px;
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

export const TitleAndUpdateInformationContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const InformationContainer = styled(Box)`
  display: ${({ isNewList }) => (isNewList ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-end;

  h5 {
    color: #474750;

    font-size: 10px;
    font-weight: 400;
    line-height: 100%; /* 10px */
    letter-spacing: 0.15px;
    text-transform: uppercase;
  }

  h6 {
    color: #474750;

    font-size: 10px;
    font-weight: 400;
    line-height: 100%; /* 10px */
    letter-spacing: 0.15px;
    padding-top: 4px;
  }

  p {
    color: #474750;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 23.4px */
    letter-spacing: 0.027px;
  }
`;

export const BackButtonAndTitleContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 12px;

  button {
    padding: 0;
  }
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 3px;

  h5 {
    width: 100%;
    max-width: 250px;
    color: #96969e;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.035px;
  }

  p {
    color: #474750;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
    letter-spacing: 0.027px;
  }
`;

export const ActionsContainer = styled(Box)`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  justify-content: flex-end;
  flex: 1;
`;

export const StyledIconButton = styled(IconButton)`
  background: #f5f5f7;

  &:disabled {
    cursor: not-allowed;
    pointer-events: initial;
  }
`;

export const StyledBenvoButton = styled(BenvoButton)`
  padding: 16px 0;
  min-width: 170px;
`;

export const TooltipContainer = styled('div')`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: flex-end;
  bottom: 0;
`;

export const TitleAndTooltipContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  gap: 40px;
`;
