import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import RecipeCard from './RecipeCard';
import routes from '../../../services/routes';
import { useAlert } from '../../../context/alertContext';
import { useDoctorLP } from '../../../context/doctorLPContext';
import alertMapper from '../../../utils/alertMapper';
import {
  StyledBox, StyledBoxCards,
  StyledContent,
} from '../styles';
import LoadingComponent from '../components/LoadingComponent';
import KeySelector from '../components/KeySelector/KeySelector';
import ContentHeader from '../components/ContentHeader/ContentHeader';
import EmptyResult from '../components/EmptyResult/EmptyResult';

const { publicRoutes } = routes;

function Recipes({ drawerHandler }) {
  const alert = useAlert();
  const { ilpiId, doctorId } = useParams();
  const doctorLP = useDoctorLP();
  const [organizedRecipes, setOrganizedRecipes] = useState({});

  /**
   * Groups recipes by resident
   * @param {array} array array with recipes by type
   * @returns array with recipes by resident
   */
  const groupByResident = (array) => {
    const organized = array.reduce((acc, recipe) => {
      const { residentName } = recipe;
      if (!acc[residentName]) {
        acc[residentName] = [];
      }
      acc[residentName].push(recipe);
      return acc;
    }, {});
    return organized;
  };

  /**
   * Fetch recipes from backend
   */
  const fetchRecipes = async () => {
    doctorLP.setIsLoading(true);
    try {
      const { message } = await publicRoutes.getSentRecipes({ ilpiId, collaboratorId: doctorId });
      doctorLP.setRecipes(message);
    } catch (error) {
      if (error.response?.status === 404) alert.info('Não há receitas para serem validadas!');
      else alert[alertMapper(error.response?.status)]();
    } finally {
      doctorLP.setIsLoading(false);
    }
  };

  /**
   * Everytime recipes array changes, organize them by resident
   */
  useEffect(() => {
    setOrganizedRecipes(groupByResident(doctorLP.recipes));
  }, [doctorLP.recipes]);

  /**
   * Remove recipe from recipeArray and add it to removed
   * @param {string} name the resident name
   * @param {object} data the data with removal motive
   */
  const removeRecipe = (name, data) => {
    const { motive } = data;
    const recipesWithoutRemovedResidents = doctorLP.recipes.filter(
      (recipe) => recipe.residentName !== name,
    );
    const recipesWithRemovedResidents = doctorLP.recipes.filter(
      (recipe) => recipe.residentName === name,
    );

    const prepareRemoved = recipesWithRemovedResidents.map((recipe) => ({
      id: recipe.id,
      motive,
    }));

    doctorLP.setRemoved((prevValues) => [...prevValues, ...prepareRemoved]);
    doctorLP.setRecipes(recipesWithoutRemovedResidents);
  };

  /**
   * Set approved recipes and open key selector to proceed with signature
   */
  const setApprovedAndOpenKeySelector = async () => {
    doctorLP.setApproved(doctorLP.recipes.map((recipe) => recipe.id));
    drawerHandler.handle();
  };

  /**
   * Mounts recipes cards for each resident
   * @param {Object} object with properties name and inRecipes
   * @returns The jsx element to be mounted
   */
  const mountCardForEachResident = (object) => {
    const entries = Object.entries(object);
    const cards = [];
    entries.forEach((entry) => {
      const [name, inRecipes] = entry;
      const data = { name, inRecipes };
      cards.push(
        <RecipeCard
          data={data}
          removeRecipe={removeRecipe}
          key={entry}
        />,
      );
    });
    return cards;
  };

  /**
   * Fetch recipes on component mount
   */
  useEffect(() => {
    fetchRecipes();
  }, []);

  return (
    <StyledBox>
      {doctorLP.isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <ContentHeader
            title="Receitas geradas"
            onClick={setApprovedAndOpenKeySelector}
            isThereData={doctorLP.recipes.length}
          />
          <StyledContent>
            {!doctorLP.recipes.length ? (
              <EmptyResult type="Receitas" />
            ) : (
              <StyledBoxCards>
                {mountCardForEachResident(organizedRecipes)}
              </StyledBoxCards>
            )}
          </StyledContent>
        </>
      )}
      <KeySelector drawerHandler={drawerHandler} />
    </StyledBox>
  );
}

export default Recipes;
