import { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AddCircleOutline } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import moment from 'moment/moment';
import { convertAPIDate } from '../../../../utils/dateFormat';
import schemas from '../../../../schemas';
import { useAlert } from '../../../../context/alertContext';
import React, { getTokenInfo } from '../../../../utils/configAuth';
import {
  createEmployeeOptions,
  createResidentOptions,
} from '../../../../utils/createOptionsForAutoComplete';
import routes from '../../../../services/routes';
import WellBeingForm from './WellBeingForm';
import VitalSignsForm from './VitalSignsForm';
import DiuresisForm from './DiuresisForm';

const formOptions = [
  {
    value: 'Bem Estar',
    label: 'Bem Estar',
  },
  {
    value: 'Sinais Vitais',
    label: 'Sinais Vitais',
  },
  {
    value: 'Diurese',
    label: 'Diurese',
  },
];

const statusOptions = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Atenção', label: 'Atenção' },
  { value: 'Alerta', label: 'Alerta' },
];

export default function CreateEvolution({
  dialogInfoInitialState,
  dialogInfo,
  setDialogInfo,
  onSuccess,
}) {
  const ilpiId = getTokenInfo('ilpiId');
  const alert = useAlert();

  const getResidentName = async (residentId) => {
    try {
      const resident = await routes.resident.getResidentById({ residentId });
      return resident.message.name;
    } catch (error) {
      return console.error(error);
    }
  };

  const getResidents = async () => {
    const {
      message: { rows },
    } = await routes.resident.findResidentsByIlpi({ ilpiId });

    return rows;
  };

  const getEmployees = async () => {
    const {
      message: { rows },
    } = await routes.employee.findEmployeesByIlpi({ ilpiId });

    return rows;
  };

  const { data: residents = {} } = useQuery({
    queryKey: ['residents'],
    queryFn: () => getResidents(),
    onError: (error) => {
      console.error(error);
    },
    retry: 0,
    cacheTime: 0,
  });

  const { data: employees = {} } = useQuery({
    queryKey: ['employees'],
    queryFn: () => getEmployees(),
    onError: (error) => {
      console.error(error);
    },
    retry: 0,
    cacheTime: 0,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schemas.evolution.createEvolution),
    defaultValues: {
      kind: 'Bem Estar',
      status: 'Normal',
      date: moment().format('YYYY-MM-DD HH:mm'),
    },
  });

  const handleModalClose = () => {
    reset();
    setDialogInfo(dialogInfoInitialState);
  };

  const createResidentEvolution = async (data) => {
    const residentName = await getResidentName(data.residentId);
    const {
      residentId, observation, date, ...rest
    } = data;
    const payload = {
      ...rest,
      residentId,
      ilpiId,
      date: convertAPIDate(date),
      name: residentName,
    };

    if (observation) payload.observation = observation;

    try {
      await routes.resident.createResidentEvolution({ residentId }, payload);

      onSuccess();
      handleModalClose();
      alert.success('Evolução criada com sucesso');
    } catch (error) {
      console.error(error);
      alert.error('Erro ao criar evolução');
    }
  };

  const createInputs = (kind) => {
    switch (kind) {
      case 'Bem Estar':
        return (
          <WellBeingForm watch={watch} register={register} errors={errors} />
        );
      case 'Sinais Vitais':
        return (
          <VitalSignsForm watch={watch} register={register} errors={errors} />
        );
      default:
        return (
          <DiuresisForm watch={watch} register={register} errors={errors} />
        );
    }
  };

  useEffect(() => {
    const currentKind = watch('kind');

    reset();
    setValue('kind', currentKind);
  }, [watch('kind')]);

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        onClick={() => setDialogInfo({
          open: true,
        })}
      >
        Evolução
      </Button>
      <Dialog open={dialogInfo.open} onClose={handleModalClose}>
        <DialogTitle color="primary" fontWeight="600">
          Criar Evolução
        </DialogTitle>
        <form onSubmit={handleSubmit(createResidentEvolution)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Tipo"
                  error={!!errors.kind}
                  helperText={errors.kind?.message}
                  value={watch('kind')}
                  {...register('kind')}
                >
                  {formOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label="Status"
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  value={watch('status')}
                  {...register('status')}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {residents.length > 0 && employees.length > 0 && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      size="small"
                      fullWidth
                      label="Responsável"
                      error={!!errors.responsibleName}
                      helperText={errors.responsibleName?.message}
                      {...register('responsibleName')}
                    >
                      {createEmployeeOptions(employees).map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      size="small"
                      fullWidth
                      label="Residente"
                      error={!!errors.residentId}
                      helperText={errors.residentId?.message}
                      {...register('residentId')}
                    >
                      {createResidentOptions(residents).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="datetime-local"
                  label="Data da Evolução"
                  size="small"
                  error={!!errors.date}
                  helperText={errors.date?.message}
                  value={watch('date')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: moment().format('YYYY-MM-DD HH:mm'),
                  }}
                  {...register('date')}
                />
              </Grid>

              {createInputs(watch('kind'))}

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Observação (Opcional)"
                  error={!!errors.observation}
                  helperText={errors.observation?.message}
                  {...register('observation')}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </form>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: '16px',
          }}
        >
          <Button color="cancel" onClick={handleModalClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(createResidentEvolution)}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
