import { get } from './ApiRequest';

const IBGE_LOCATIONS = 'https://servicodados.ibge.gov.br/api/v1/localidades';

const externalAPIs = {

  // IBGE Locations
  ibge: {
    getCitiesFromState: ({ state }) => get(`${IBGE_LOCATIONS}/estados/${state}/municipios`),
  },

  // ViaCEP
  viacep: {
    getAddressFromZipCode: ({ zipCode }) => get(`https://viacep.com.br/ws/${zipCode}/json/`),
  },
};

export default externalAPIs;
