import React, { useRef, useState } from 'react';
import {
  Box, CircularProgress, Typography,
} from '@mui/material';
import moment from 'moment-business-days';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ActionContainer,
  ObservationText,
  StyledBenvoButton,
  StyledDesktopDatePicker,
  StyledTextField,
  TextAndActionsContainer,
  TextContainer,
} from './style';
import SendIndividualShoppingListImage from '../../../../assets/residents/send-individual-resident-shopping-list.png';
import BenvoDialog from '../../../../components/Dialog';
import SendWhite from '../../../../assets/residents/SendWhite';
import routes from '../../../../services/routes';
import { getTokenInfo } from '../../../../utils/configAuth';
import { useAlert } from '../../../../context/alertContext';
import CalendarIcon from '../../../../assets/CalendarIcon';

const { shoppingList } = routes;
export default function SendAllOpenShoppingListsModal({
  shoppingListIds,
  refetch,
  setSelectedLists,
  children,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSendingFinished, setIsSendingFinished] = useState(false);
  const [deliveryDay, setDeliveryDay] = useState(null);
  const alert = useAlert();
  const datePickerRef = useRef(null);

  const handleDayChange = (event) => {
    if (event) {
      const day = event._d;
      setDeliveryDay(day);
    }
  };

  const sendShoppingLists = async () => {
    setIsSending(true);
    const ilpiId = getTokenInfo('ilpiId');

    const payload = {
      ilpiId,
      ids: shoppingListIds,
      deliveryDay,
    };

    try {
      await shoppingList.sendLists(payload);
      alert.success('Listas enviadas com sucesso!');
      setSelectedLists([]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSending(false);
      setIsSendingFinished(true);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    refetch();
  };

  const shareMessage = () => {
    let message;
    if (isSending) {
      message = 'Compartilhando';
    } else if (isSendingFinished) {
      message = 'Enviado';
    } else {
      message = 'Compartilhar';
    }
    return message;
  };

  const handleFocus = () => {
    if (datePickerRef.current) {
      datePickerRef.current.querySelector('button').click();
    }
  };

  return (
    <Box>
      <BenvoDialog.Trigger onClick={handleOpenModal}>{children}</BenvoDialog.Trigger>
      <BenvoDialog.Root onClose={handleCloseModal} open={openModal}>
        <BenvoDialog.Content>
          <img src={SendIndividualShoppingListImage} alt="Send Individual Shopping List" />
          <TextAndActionsContainer>
            <TextContainer>
              <Typography variant="h6">Listas de compras dos residentes</Typography>
              <Typography variant="h5">Envie a lista de compra dos residentes para seus familiares</Typography>
            </TextContainer>
            <ActionContainer>
              <Typography variant="body1">Dia limite para entrega no residêncial</Typography>

              <StyledDesktopDatePicker
                ref={datePickerRef}
                value={deliveryDay}
                onChange={handleDayChange}
                inputFormat="DD/MM/yyyy"
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                minDate={moment()}
                shouldDisableDate={(date) => moment(date).day() === 0}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    size="small"
                    onClick={handleFocus}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Selecione uma data',
                    }}
                  />
                )}
              />
            </ActionContainer>
          </TextAndActionsContainer>
        </BenvoDialog.Content>
        <BenvoDialog.Actions>
          <StyledBenvoButton onClick={sendShoppingLists} disabled={isSending || !deliveryDay || isSendingFinished}>
            {isSending ? <CircularProgress size={20} color="inherit" /> : <SendWhite />}
            {shareMessage()}
          </StyledBenvoButton>
        </BenvoDialog.Actions>
        <ObservationText variant="body1">
          O envio é feito para todos os residentes com lista atualizada, residentes sem cadastro de familiar ficam
          pendentes de envio.
        </ObservationText>
      </BenvoDialog.Root>
    </Box>
  );
}
