import React from 'react';

export default function SendWhite() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.16641 6.16743L13.2414 3.8091C16.4164 2.75076 18.1414 4.4841 17.0914 7.6591L14.7331 14.7341C13.1497 19.4924 10.5497 19.4924 8.96641 14.7341L8.26641 12.6341L6.16641 11.9341C1.40807 10.3508 1.40807 7.7591 6.16641 6.16743Z" stroke="#F5F5F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.42578 12.2758L11.4091 9.28418" stroke="#F5F5F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
