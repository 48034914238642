import React from 'react';
import { Divider, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  CardContainer,
  ListAndSubtitleContainer,
  ListContainer,
  PrimarySubtitle,
  SecondarySubtitle,
  Title,
  TitleAndButtonContainer,
  TitleContainer,
} from './style';
import routes from '../../../../services/routes';
import { getTokenInfo } from '../../../../utils/configAuth';
import CreateOrEditListOfExam from './components/CreateOrEditListOfExam';

const { healthServiceList: serviceListRoute } = routes;

export default function ListOfExams() {
  const ilpiId = getTokenInfo('ilpiId');
  const getExamsList = async () => {
    try {
      const { message } = await serviceListRoute.findHealthServiceListByIlpiId({ ilpiId });
      return message;
    } catch (error) {
      return error;
    }
  };

  const {
    data: examsListData = {},
    isLoading: examsLoading,
    refetch: refetchExamsList,
  } = useQuery({
    queryKey: ['examsList'],
    queryFn: getExamsList,
    cacheTime: 0,
    retry: 0,
  });

  return (
    <CardContainer>
      <TitleAndButtonContainer>
        <TitleContainer>
          <Title>Listas de exames</Title>
          <PrimarySubtitle>Deixe listas de exames prontas, facilitando o seu dia a dia.</PrimarySubtitle>
        </TitleContainer>
        <CreateOrEditListOfExam refetchExamsList={refetchExamsList} />
      </TitleAndButtonContainer>
      <Divider flexItem />
      <ListAndSubtitleContainer>
        <SecondarySubtitle>Suas listas:</SecondarySubtitle>
        {examsLoading ? (
          <Skeleton variant="rounded" width="100%" height="116px" />
        ) : (
          <ListContainer>
            {examsListData.rows?.map((currentList) => (
              <CreateOrEditListOfExam
                currentList={currentList}
                refetchExamsList={refetchExamsList}
              />
            ))}
          </ListContainer>
        )}
      </ListAndSubtitleContainer>
    </CardContainer>
  );
}
