import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import {
  Alert, IconButton, Snackbar, Typography,
} from '@mui/material';
import { ReactComponent as CloseSquare } from '../assets/icons/close-square.svg';
import { ReactComponent as TickCircle } from '../assets/icons/tick-circle.svg';
import { ReactComponent as InfoCircle } from '../assets/icons/info-circle.svg';
import { ReactComponent as InfoCircleDark } from '../assets/icons/info-circle-dark.svg';
import { ReactComponent as CloseSquareDark } from '../assets/icons/close-square-dark.svg';

const alertContext = createContext({});

const DEFAULT_MESSAGES = {
  error: 'Algo deu errado',
  success: 'Concluído com sucesso!',
  info: 'Informação!',
  warning: 'Aviso!',
};

const DEFAULT_SNACK = {
  open: false,
  type: 'info',
  message: '',
  message2: '',
};

const ERROR_SNACK = {
  open: true,
  type: 'error',
  icon: <InfoCircle color="inherit" />,
};

const INFO_SNACK = {
  open: true,
  type: 'info',
  icon: <InfoCircle color="inherit" />,
};

const SUCCESS_SNACK = {
  open: true,
  type: 'success',
  icon: <TickCircle color="inherit" />,
};

const WARNING_SNACK = {
  open: true,
  type: 'warning',
  icon: <InfoCircleDark color="inherit" />,
};

export const useAlert = () => useContext(alertContext);

export function AlertProvider({ children }) {
  const [snack, setSnack] = useState(DEFAULT_SNACK);
  const autoHideoutTime = 3000;

  const error = (msg = DEFAULT_MESSAGES.error, msg2 = '') => {
    setSnack({ ...ERROR_SNACK, message: msg, message2: msg2 });
  };

  const success = (msg = DEFAULT_MESSAGES.success, msg2 = '') => {
    setSnack({ ...SUCCESS_SNACK, message: msg, message2: msg2 });
  };

  const info = (msg = DEFAULT_MESSAGES.info, msg2 = '') => {
    if (msg) setSnack({ ...INFO_SNACK, message: msg, message2: msg2 });
  };

  const warning = (msg = DEFAULT_MESSAGES.warning, msg2 = '') => {
    if (msg) setSnack({ ...WARNING_SNACK, message: msg, message2: msg2 });
  };

  const notFound = () => {
    setSnack({ ...INFO_SNACK, message: 'Não foram encontrados resultados para a sua busca.' });
  };

  const generalError = () => {
    setSnack({
      ...ERROR_SNACK,
      message: 'Ocorreu um erro na requisição para nosso servidor.',
      message2: 'Tente novamente mais tarde!',
    });
  };

  const unauthorized = () => {
    setSnack({ ...ERROR_SNACK, message: 'Você não possui autorização para essa requisição.' });
  };

  const wrongPassword = () => {
    setSnack({ ...WARNING_SNACK, message: 'Usuário ou senha inválidos, verifique a digitação.' });
  };

  const close = () => {
    setSnack((prev) => ({ ...prev, open: false }));
  };

  const action = (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={close}
    >
      {snack.type === 'warning'
        ? (<CloseSquareDark fontSize="inherit" />)
        : (<CloseSquare fontSize="inherit" />)}
    </IconButton>
  );

  const store = useMemo(() => ({
    error,
    success,
    info,
    close,
    warning,
    autoHideoutTime,
    notFound,
    generalError,
    unauthorized,
    wrongPassword,
  }));

  return (
    <alertContext.Provider value={store}>
      <Snackbar
        open={snack.open}
        autoHideDuration={autoHideoutTime}
        onClose={close}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={close}
          data-testid="alert"
          severity={snack.type}
          variant="filled"
          icon={snack.icon}
          action={action}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body2">
            {snack.message}
          </Typography>
          <Typography variant="body2">
            {snack.message2}
          </Typography>
        </Alert>
      </Snackbar>
      {children}
    </alertContext.Provider>
  );
}
