import React from 'react';
import { StyledBoxHeader, StyledPageTitle } from './style';
import BenvoPrimaryButton from '../../../../components/BenvoPrimaryButton';

function ContentHeader({ title, onClick, isThereData }) {
  return (
    <StyledBoxHeader>
      <StyledPageTitle>
        {title}
      </StyledPageTitle>
      <BenvoPrimaryButton
        disabled={!isThereData}
        testid="approve-all-button"
        onClick={onClick}
      >
        Aprovar todos
      </BenvoPrimaryButton>
    </StyledBoxHeader>
  );
}

export default ContentHeader;
