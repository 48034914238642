import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Button,
} from '@mui/material';
import moment from 'moment';
import { createEmployeeOptions } from '../../../utils/createOptionsForAutoComplete';
import { useAlert } from '../../../context/alertContext';
import { getTokenInfo } from '../../../utils/configAuth';
import routes from '../../../services/routes';

export default function TasksRegistryFilter({ onFilter }) {
  const [filterData, setFilterData] = useState({
    beginDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    status: '',
    employeeId: '',
  });
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();
  const ilpiId = getTokenInfo('ilpiId');
  const statusOptions = [
    {
      value: 'completed',
      label: 'Concluída',
    },
    {
      value: 'cancelled',
      label: 'Cancelada',
    },
  ];

  const getEmployees = async () => {
    setIsLoading(true);
    try {
      const { message } = await routes.employee.findEmployee(
        { ilpiId },
        { employeeType: 'caregiver' },
      );

      setEmployees(message.rows);
    } catch (error) {
      alert.error('Erro ao obter lista de Cuidadores');
      console.error('TasksHistoryFilter', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (value) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [name]: value,
      }));
    } else {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [name]: '',
      }));
    }
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    if (!value) {
      const defaultDate = name === 'beginDate'
        ? moment().subtract(1, 'months').format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [name]: defaultDate,
      }));
      return;
    }

    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const onSearch = () => {
    onFilter(filterData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <TextField
          type="date"
          label="Data inicial"
          size="small"
          fullWidth
          name="beginDate"
          value={filterData.beginDate}
          onChange={(value) => handleDateChange(value)}
          inputProps={{
            max: filterData.endDate,
            min: '2023-01-01',
          }}
        />
      </Grid>
      <Grid item xs={12} md>
        <TextField
          type="date"
          label="Data final"
          size="small"
          fullWidth
          name="endDate"
          value={filterData.endDate}
          onChange={(value) => handleDateChange(value)}
          inputProps={{
            max: moment().format('YYYY-MM-DD'),
            min: filterData.beginDate,
          }}
        />
      </Grid>
      <Grid item xs={12} md>
        <TextField
          select
          fullWidth
          size="small"
          name="status"
          label="Status"
          value={filterData.status}
          onChange={(value) => handleFilterChange(value)}
        >
          <MenuItem value="">Todos</MenuItem>
          {statusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <TextField
            select
            fullWidth
            size="small"
            name="employeeId"
            label="Cuidador"
            value={filterData.employeeId}
            onChange={(value) => handleFilterChange(value)}
          >
            <MenuItem value="">Todos</MenuItem>
            {createEmployeeOptions(employees)?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
      <Grid item xs={12} md>
        <Button variant="contained" color="primary" onClick={onSearch}>
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
}
