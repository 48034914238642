import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import BenvoDrawer from '../../../../components/BenvoDrawer';
import routes from '../../../../services/routes';
import { moneyMask, upperCaseFirstLetter } from '../../../../utils/inputMasks';
import {
  Container,
  IsHomeSpan,
  IsHomeText,
  IsHomeTextContainer,
  OrderInformationPrice,
  OrderInformationResidentName,
  OrderInformationTitle,
  OrderInformationTotalContainer,
  OrderInformationTotalText,
  StyledDivider,
  StyledList,
  StyledServiceListItem,
  TitleContainer,
} from './style';

export default function OrderInformation({
  open, onClose, orderId, residentId,
}) {
  const {
    data: order = {},
    isLoading,
    remove,
  } = useQuery({
    queryKey: ['order', orderId],
    queryFn: async () => {
      const { message } = await routes.healthServiceOrder.getHealthServiceOrderByOrderAndResidentId({
        orderId,
        residentId,
      });

      return message;
    },
    enabled: open,
  });

  const totalPrice = order.services?.reduce((acc, { price }) => acc + price, 0).toFixed(2);

  const handleClose = () => {
    onClose();
    remove();
  };

  return (
    <BenvoDrawer
      data-testid="service-order-modal"
      justifyContent="flex-start"
      open={open}
      onClose={handleClose}
      isLoading={isLoading}
      title="Solicitação de Serviços"
    >
      <Container>
        <TitleContainer>
          <OrderInformationTitle>Solicitação de exames para</OrderInformationTitle>
          <OrderInformationResidentName>{order.residentName}</OrderInformationResidentName>
        </TitleContainer>

        <Divider flexItem />

        <OrderInformationTitle>Exames solicitados</OrderInformationTitle>

        <StyledList>
          {order.services?.map((service) => (
            <StyledServiceListItem key={service.name} data-testId="order-service-list-item-card">
              <Box>
                <Typography>{service.name}</Typography>

                <StyledDivider orientation="vertical" />

                <OrderInformationPrice>{moneyMask(service.price)}</OrderInformationPrice>
              </Box>
            </StyledServiceListItem>
          ))}
        </StyledList>
        <OrderInformationTotalContainer>
          <OrderInformationTotalText>Total</OrderInformationTotalText>
          <OrderInformationPrice>{moneyMask(totalPrice)}</OrderInformationPrice>
        </OrderInformationTotalContainer>
        <IsHomeTextContainer>
          {order.services?.some((service) => service.isHome) && (
            <IsHomeText>Serviços com disponibilidade para realização em domicilio:</IsHomeText>
          )}
          {order.services?.map((service) => (
            <IsHomeSpan key={service.name} component="span">
              {service.isHome ? `${upperCaseFirstLetter(service.name)}; ` : ''}
            </IsHomeSpan>
          ))}
        </IsHomeTextContainer>
      </Container>
    </BenvoDrawer>
  );
}

OrderInformation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  residentId: PropTypes.string.isRequired,
};
