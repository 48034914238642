import { TextField, styled } from '@mui/material';

const StyledTextField = styled(TextField)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);

  @media (max-width: 768px) {
    background: #fff;
    width: 100% !important;
    align-items: stretch;
  }

  & .MuiOutlinedInput-root {
    width: 100%;
    max-width: 266px;
    height: 40px;
    padding: 10px 12px;
    font-size: 14px;
    color: #000;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    & fieldset {
      border: none;
    }
    &:hover fieldset {
      border: none;
    }
    &.Mui-focused fieldset {
      border: none;
    }

    &.MuiInputBase-root {
      input {
        padding: 0px !important;

        &::placeholder {
          color: #96969e;
        }
      }
    }
  }
`;

export default StyledTextField;
