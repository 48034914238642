import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  gap: 29px;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

export const CardsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
