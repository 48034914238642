import React from 'react';
import StyledSelect from './style';

/**
 *
 * @param {string} minWidth - The min width of the select field in px.
 */
export default function BenvoSelectField({ minWidth, children, ...props }) {
  return (
    <StyledSelect
      fullWidth
      displayEmpty
      minWidth={minWidth}
      {...props}
    >
      {children}
    </StyledSelect>
  );
}
