import { Pagination } from '@mui/material';
import React from 'react';

export default function BenvoPagination({
  count, onChange, sx, ...props
}) {
  return (
    <Pagination
      count={count}
      sx={{
        p: '16px',
        '.MuiPaginationItem-root': {
          color: '#4A397F',
          fontSize: '14px',
          fontWeight: '500',
          backgroundColor: '#F5F5F7',
          ml: '13px',
        },
        '.MuiPaginationItem-root.Mui-selected': {
          color: '#fff',
          backgroundColor: '#4A397F',
        },
        '.MuiPagination-ul': {
          justifyContent: 'flex-end',
        },
        ...sx,
      }}
      onChange={onChange}
      hideNextButton
      hidePrevButton
      {...props}
    />
  );
}
