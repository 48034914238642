import React from 'react';
import { Box, Typography } from '@mui/material';

export const wellBeingStatus = (evolution) => (
  <Box
    sx={{
      mt: '48px',
    }}
  >
    <Typography variant="h6" component="p" fontWeight="bold">
      Humor:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.humor}
      </Typography>
    </Typography>
    <Typography variant="h6" component="p" fontWeight="bold">
      Apetite:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.appetite}
      </Typography>
    </Typography>
    <Typography variant="h6" component="p" fontWeight="bold">
      Sono:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.repose}
      </Typography>
    </Typography>
  </Box>
);

export const vitalSignsStatus = (evolution) => (
  <Box
    sx={{
      mt: '48px',
    }}
  >
    <Typography variant="h6" component="p" fontWeight="bold">
      Pressão arterial sistólica:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.systolicBloodPressure}
      </Typography>
    </Typography>
    <Typography variant="h6" component="p" fontWeight="bold">
      Pressão arterial diastólica:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.diastolicBloodPressure}
      </Typography>
    </Typography>
    <Typography variant="h6" component="p" fontWeight="bold">
      Frequência Respiratória:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.respiratoryFrequency}
      </Typography>
    </Typography>
    <Typography variant="h6" component="p" fontWeight="bold">
      Frequência Cardíaca:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.heartFrequency}
      </Typography>
    </Typography>
    <Typography variant="h6" component="p" fontWeight="bold">
      Saturação do Oxigênio:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.oxygenSaturation}
      </Typography>
    </Typography>
    <Typography variant="h6" component="p" fontWeight="bold">
      Temperatura:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.temperature}
      </Typography>
    </Typography>
    <Typography variant="h6" component="p" fontWeight="bold">
      HGT:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.hgt}
      </Typography>
    </Typography>
  </Box>
);

export const diuresisStatus = (evolution) => (
  <Box
    sx={{
      mt: '48px',
    }}
  >
    <Typography variant="h6" component="p" fontWeight="bold">
      Diurese:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.diuresis}
      </Typography>
    </Typography>
    <Typography variant="h6" component="p" fontWeight="bold">
      Evacuação:
      {' '}
      <Typography variant="h6" component="span">
        {evolution.evacuation}
      </Typography>
    </Typography>
  </Box>
);
