import {
  Box,
  Divider,
  IconButton,
  Select,
  Skeleton,
  styled,
} from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';

export const Container = styled(Box)`
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid #f5f5f7;

  display: flex;
  width: 100%;
  gap: 29px;

  * {
    margin: 0;
  }
`;

export const NameAndPositionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 120px;

  h3 {
    color: #474750;

    font-size: 12px;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.12px;
  }

  p {
    color: #474750;

    font-size: 10px;
    font-weight: 400;
    line-height: 100%; /* 10px */
    letter-spacing: 0.15px;
    text-transform: uppercase;
  }
`;

export const ActionsContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
`;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  padding-right: 10px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  height: 40px;
  font-size: 12px;
  letter-spacing: 0.12px;
  color: var(--grey-variation-dark-grey, #474750);
  border: none;
  width: 175px;

  & .Mui-readOnly {
    color: var(--grey-variation-light-grey, #d4d4da);
    cursor: not-allowed;
  }

  & fieldset {
    border: none;
    ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
  }

  .MuiSelect-select {
    ${({ value }) => value === '' && 'color: var(--grey-variation-light-grey, #47475070)'}
  }
`;

export const UserInformationContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  color: #474750;
  white-space: nowrap;
  flex: 1;

  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 100%; /* 10px */
    letter-spacing: 0.15px;
  }

  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.04px;
  }
`;

export const StyledDivider = styled(Divider)`
  background-color: #d4d4da;
  height: 49px;
`;

export const ActionButtonsContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledIconButton = styled(IconButton)`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 80px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:disabled {
    background: #eaebec;
    pointer-events: all;

    &:hover {
      box-shadow: none;
    }

    svg {
      filter: grayscale(60%);
    }
  }
`;

export const StyledButton = styled(BenvoButton)`
  padding: 16px 0;
  width: 118px;
`;

export const StyledSkeleton = styled(Skeleton)`
  width: 903.78px;
  height: 71px;
  border-radius: 6px;
`;
