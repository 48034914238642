import React from 'react';

export default function paymentSuccess() {
  return (
    <svg width="214" height="186" viewBox="0 0 214 186" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 34068">
        <ellipse id="Ellipse 660" cx="107.061" cy="95" rx="90" ry="91" fill="#D9D9D9" />
        <g id="Group">
          <g id="Group_2">
            <path id="Vector" d="M90.0321 22.3601C89.5424 22.329 89.084 22.1092 88.7531 21.747C88.4222 21.3847 88.2448 20.9083 88.258 20.4179L88.3942 1.93764C88.3911 1.68064 88.4398 1.42565 88.5374 1.18787C88.635 0.950092 88.7795 0.73439 88.9622 0.553623C89.1449 0.372856 89.3621 0.23072 89.6009 0.13569C89.8398 0.0406596 90.0953 -0.00531933 90.3522 0.000489082L91.8295 0.0665239C92.0855 0.0833937 92.3355 0.151896 92.5644 0.267893C92.7933 0.383891 92.9964 0.544994 93.1614 0.741512C93.3265 0.938029 93.45 1.16589 93.5247 1.41141C93.5994 1.65692 93.6236 1.915 93.596 2.17012L91.9826 20.5843C91.9488 21.0746 91.7271 21.5328 91.3637 21.8637C91.0003 22.1945 90.5233 22.3723 90.0321 22.3601Z" fill="#4A397F" />
            <path id="Vector_2" d="M81.3926 24.3619C81.0909 24.4663 80.7601 24.4466 80.4729 24.307C80.1858 24.1675 79.9658 23.9196 79.8614 23.6179L75.7146 12.3222C75.5943 12.0011 75.6063 11.6454 75.748 11.3331C75.8897 11.0208 76.1495 10.7775 76.4704 10.6566L77.3903 10.3308C77.5482 10.2765 77.7156 10.2555 77.882 10.2692C78.0484 10.2829 78.2102 10.3309 78.3571 10.4103C78.504 10.4896 78.6328 10.5985 78.7355 10.7302C78.8382 10.8619 78.9125 11.0134 78.9536 11.1752L82.1807 22.7966C82.27 23.1088 82.2386 23.4432 82.0925 23.7332C81.9465 24.0233 81.6966 24.2478 81.3926 24.3619Z" fill="#4A397F" />
            <path id="Vector_3" d="M76.8435 31.2045C76.2356 32.0352 74.9364 32.0872 73.8985 31.3223L55.4672 17.743C54.3663 16.9318 53.986 15.5214 54.6308 14.6404L55.5295 13.4125C56.1743 12.5313 57.5844 12.534 58.632 13.4189L76.1642 28.2263C77.1517 29.0602 77.4512 30.3739 76.8435 31.2045Z" fill="#4A397F" />
          </g>
          <g id="Group_3">
            <path id="Vector_4" d="M206.303 60.5451L92.5891 33.4755C88.3751 32.4724 84.1458 35.0753 83.1426 39.2893L66.4202 109.537C65.4171 113.751 68.02 117.98 72.234 118.983L185.948 146.053C190.162 147.056 194.392 144.453 195.395 140.239L212.117 69.9916C213.12 65.7776 210.518 61.5483 206.303 60.5451Z" fill="#735FB3" />
            <path id="Vector_5" d="M210.538 76.6227L81.5635 45.9204L78.0642 60.6202L207.039 91.3224L210.538 76.6227Z" fill="#4A397F" />
            <path id="Vector_6" d="M189.865 131.531L164.22 125.426C164.138 125.406 164.056 125.457 164.037 125.538L162.412 132.364C162.392 132.445 162.443 132.527 162.525 132.547L188.17 138.652C188.252 138.671 188.334 138.621 188.353 138.539L189.978 131.714C189.997 131.632 189.947 131.55 189.865 131.531Z" fill="white" />
          </g>
          <g id="Group_4">
            <path id="Vector_7" d="M140.421 23.6748L7.97771 69.2994C3.88213 70.7103 1.70574 75.1741 3.1166 79.2697L31.6431 162.079C33.0539 166.175 37.5178 168.351 41.6134 166.94L174.057 121.316C178.152 119.905 180.329 115.441 178.918 111.345L150.391 28.536C148.981 24.4404 144.517 22.264 140.421 23.6748Z" fill="#342465" />
            <path id="Vector_8" d="M137.669 34.3802L122.22 39.7021C119.763 40.5486 118.457 43.2269 119.304 45.6843L124.626 61.1333C125.472 63.5907 128.151 64.8965 130.608 64.05L146.057 58.728C148.514 57.8815 149.82 55.2032 148.974 52.7459L143.652 37.2968C142.805 34.8395 140.127 33.5337 137.669 34.3802Z" fill="white" />
            <path id="Vector_9" d="M83.5589 108.417L32.1713 126.12C32.0241 126.17 31.9459 126.331 31.9966 126.478L34.6141 134.076C34.6648 134.223 34.8252 134.302 34.9724 134.251L86.36 116.549C86.5072 116.498 86.5854 116.338 86.5347 116.19L83.9172 108.592C83.8665 108.445 83.7061 108.367 83.5589 108.417Z" fill="white" />
            <path id="Vector_10" d="M110.918 116.557L37.579 141.821C37.4318 141.871 37.3536 142.032 37.4043 142.179L40.0218 149.777C40.0725 149.925 40.2329 150.003 40.3801 149.952L113.719 124.688C113.866 124.637 113.944 124.477 113.894 124.33L111.276 116.732C111.225 116.584 111.065 116.506 110.918 116.557Z" fill="white" />
          </g>
        </g>
      </g>
    </svg>

  );
}
