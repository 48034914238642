import React, { useState } from 'react';
import { Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ufOptions } from 'constants';
import externalAPIs from '../../../../../services/externalAPIs';

export default function CityCoverageForm({ areaCoverage, setAreaCoverage }) {
  const [cities, setCities] = useState([]);
  const [currentCity, setCurrentCity] = useState(null);
  const [currentUF, setCurrentUF] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleStateChange = async ({ target: { value } }) => {
    setCurrentUF(value);
    try {
      const data = await externalAPIs.ibge.getCitiesFromState({ state: value });
      setCities(data.map((city) => city.nome));
    } catch (error) {
      setCities([]);
    }
  };

  const handleCityChange = (newValue) => {
    if (newValue) {
      setCurrentCity(newValue);
      setIsDisabled(false);
    }
  };

  const handleAddCity = () => {
    setCities([...cities.filter((city) => city !== currentCity)]);
    setAreaCoverage({
      ...areaCoverage,
      states: [...areaCoverage.states],
      cities: [...areaCoverage.cities, `${currentCity} - ${currentUF}`],
    });
    setIsDisabled(true);
  };

  const handleDeleteCity = (city) => {
    setCities([...cities, city.split(' - ')[0]]);
    setAreaCoverage({
      ...areaCoverage,
      cities: areaCoverage.cities.filter((item) => item !== city),
    });
  };

  const createUfOptions = () => {
    const options = ufOptions.filter(({ value }) => !areaCoverage.states.includes(value));

    return options.map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ));
  };

  const rows = areaCoverage.cities.map((city) => {
    const [name, uf] = city.split(' - ');

    return {
      id: city,
      name,
      uf,
    };
  });

  const columns = [
    {
      field: 'name',
      headerName: 'Município',
      flex: 1,
    },
    {
      field: 'uf',
      headerName: 'UF',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => handleDeleteCity(params.row.id)}
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Typography color="primary" variant="h6">
          {areaCoverage.states.length > 0
            ? 'Cobertura Municipal (Opcional)'
            : 'Cobertura Municipal (Obrigatório)'}
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            mt: 1,
            mb: 2,
          }}
        >
          {areaCoverage.states.length > 0
            ? 'Você preencheu a cobertura estadual, agora essa etapa é opcional.'
            : 'Você não preencheu a cobertura estadual, agora essa etapa é obrigatória.'}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          select
          size="small"
          fullWidth
          label="Selecionar Estado"
          onChange={handleStateChange}
        >
          {createUfOptions()}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Autocomplete
          autoComplete
          disabled={cities.length === 0}
          options={cities}
          onChange={(_, newValue) => {
            handleCityChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              label="Selecionar Cidades"
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <Button
          variant="contained"
          disabled={isDisabled}
          onClick={handleAddCity}
        >
          Adicionar
        </Button>
      </Grid>

      <Grid item xs={12} sm={12}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          localeText={{
            noRowsLabel: 'Nenhum município adicionado à cobertura.',
          }}
          disableSelectionOnClick
          hideFooterPagination
          hideFooter
          sx={{
            height: 250,
          }}
        />
      </Grid>
    </>
  );
}
