import { Box, Typography, styled } from '@mui/material';

export const ResidentShoppingListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #f5f5f7;

  div:nth-of-type(even) {
    background-color: #fefefe;
    border: 1px solid var(--grey-variation-lightest-grey, #f5f5f7);

    input {
      background-color: #F5F5F7;
    }
  }
  div:nth-of-type(odd) {
    border: 1px solid transparent;
  }

  
`;

export const ResidentShoppingListsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-height: ${({ hasDeliveryDate }) => (hasDeliveryDate ? 'calc(100vh - 373px)' : 'calc(100vh - 369px)')};
  overflow: auto;
`;

export const Container = styled(Box)`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  flex-direction: column;
  gap: 22px;
`;

export const ShoppingListTitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 244px;
  }
`;

export const ShoppingListTitle = styled(Typography)`
  color: #474750;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const ShoppingListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 42px;
  overflow-y: auto;
  max-height: calc(100vh - 373px);
  padding-bottom: 18px;
`;

export const ListsContainer = styled(Box)`
  /* overflow-y: auto; */
`;

export const TableTitleContainer = styled(Box)`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  div{
    width: 244px;
    display: flex;
    justify-content: space-between;
  }
`;

export const TableTitle = styled(Typography)`
color:  #96969E;

font-size: 12px;
font-weight: 400;
line-height: 170%; /* 20.4px */
letter-spacing: 0.12px;

&:last-child {
  margin-left: -14px;
  margin-right: 13px;
}
`;

export const MaximumDeliveryDateTitle = styled(Typography)`
  color: #474750;

  font-size: 10px;
  font-weight: 400;
  line-height: 100%; /* 10px */
  letter-spacing: 0.15px;
  margin-bottom: 22px;
  text-align: end;
`;

export const CategoryTitle = styled(Typography)`
  color: #96969e;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; 
  letter-spacing: 0.12px; 
  margin-bottom: 8px;
`;

export const SubcategoryContainer = styled(Box)`
  margin-block: 20px;
`;
