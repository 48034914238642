import Joi from 'joi';

const createVisit = Joi.object({
  observation: Joi.string().allow(''),
  eventType: Joi.string()
    .valid(
      'Visita',
      'Tarefa',
      'Consulta',
      'Atendimento',
      'Eventos Gerais',
      'Bloqueio de Agenda',
      'Outros',
    )
    .required(),
  linkResident: Joi.boolean().required(),
  residentId: Joi.when('linkResident', {
    is: true,
    then: Joi.string().uuid().required(),
    otherwise: Joi.forbidden(),
  }),
  visitor: Joi.when('eventType', {
    is: Joi.string().valid('Visita'),
    then: Joi.string().required(),
    otherwise: Joi.forbidden(),
  }),
  kinshipDegree: Joi.when('eventType', {
    is: Joi.string().valid('Visita'),
    then: Joi.string().required(),
    otherwise: Joi.forbidden(),
  }),
  beginDate: Joi.date().required(),
  endDate: Joi.date().required(),
});

export default {
  createVisit,
};
