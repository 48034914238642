import {
  Box, IconButton, styled,
} from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 16px;
  border-radius: 80px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const TextContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  h5 {
    color: #96969e;

    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.035px;
    width: 100%;
    max-width: 334px;
    text-align: center;
  }

  h6 {
    color: #435565;

    font-size: 16px;
    font-weight: 500;
    line-height: 115%; /* 18.4px */
    letter-spacing: 0.024px;
  }
`;
