import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import ProductListTable from './ProductListTable';

export default function SellerProductsView({
  sellerId,
  sellerName,
  dialogInfo,
  setDialogInfo,
}) {
  const handleModalClose = () => {
    setDialogInfo({
      open: false,
      sellerId: null,
    });
  };

  return (
    <Dialog
      open={dialogInfo.open && dialogInfo.sellerId === sellerId}
      onClose={handleModalClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: 900,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: '0 1rem 0 0',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
        >
          {`Listas de Produtos do Fornecedor ${sellerName}`}
        </Typography>

        <IconButton onClick={handleModalClose}>
          <Close color="primary" />
        </IconButton>
      </Box>
      <DialogContent>
        <ProductListTable sellerId={sellerId} sellerName={sellerName} />
      </DialogContent>
    </Dialog>
  );
}
