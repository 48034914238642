import React, { useRef, useState } from 'react';
// import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import PropTypes from 'prop-types';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import { useAlert } from '../../context/alertContext';
import treatImage from '../../utils/treatImage';
import { ReactComponent as CloseSquare } from '../../assets/icons/close-square2.svg';
import 'react-image-crop/dist/ReactCrop.css';

// function setCanvasImage(image, canvas, crop) {
//   if (!crop || !canvas || !image) return null;

//   const ctx = canvas.getContext('2d');
//   const scaleX = image.naturalWidth / image.width;
//   const scaleY = image.naturalHeight / image.height;
//   const pixelRatio = window.devicePixelRatio;

//   canvas.width = crop.width * pixelRatio * scaleX;
//   canvas.height = crop.height * pixelRatio * scaleY;

//   ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
//   ctx.imageSmoothingQuality = 'high';

//   ctx.drawImage(
//     image,
//     crop.x * scaleX,
//     crop.y * scaleY,
//     crop.width * scaleX,
//     crop.height * scaleY,
//     0,
//     0,
//     crop.width * scaleX,
//     crop.height * scaleY,
//   );

//   return new Promise((resolve) => {
//     canvas.toBlob((blob) => {
//       resolve(blob);
//     }, 'image/jpeg');
//   });
// }

async function pdfToImage(pdf, canvas) {
  const pdfjsLib = await import('pdfjs-dist/build/pdf');
  const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  return new Promise((resolve) => {
    const fileReader = new FileReader();
    const context = canvas.getContext('2d');

    fileReader.readAsBinaryString(pdf);
    fileReader.onload = async () => {
      const image = await pdfjsLib.getDocument({ data: fileReader.result }).promise;
      const page = await image.getPage(1);
      const viewport = page.getViewport({ scale: 1 });

      canvas.width = viewport.width;
      canvas.height = Math.min(viewport.height, 800);

      await page.render({
        canvasContext: context,
        viewport,
      }).promise;

      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    };
  });
}

// include 'aspect' on ImagePicker
export default function ImagePicker({ onChange, children }) {
  // const aspectCrop = aspect ? undefined : 1;
  const [imagePreview, setImagePreview] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  // const [crop, setCrop] = useState(null);

  const inputRef = useRef(null);
  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const alert = useAlert();

  const handleImagePreview = async ({ target }) => {
    const file = target.files[0];

    if (file.type.includes('pdf')) {
      const parsedFile = await pdfToImage(file, canvasRef.current);
      setImageFile(parsedFile);
      setImagePreview(URL.createObjectURL(parsedFile));
    } else if (file.type.includes('image')) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    } else {
      alert.error('Formato de arquivo inválido');
      return;
    }

    setImageName(file.name);
  };

  // const handleCrop = (e) => {
  //   console.log(e);
  //   const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

  //   // const startAspect = aspect ? (width / height) : 1;

  //   const centeredCrop = centerCrop(
  //     makeAspectCrop(
  //       {
  //         unit: 'px',
  //         width: 100,
  //       },
  //       1,
  //       width,
  //       height,
  //     ),
  //     width,
  //     height,
  //   );

  //   setCrop(convertToPixelCrop(centeredCrop, imageRef.current.width, imageRef.current.height));
  // };

  const handleClose = () => {
    setImagePreview(null);
    setImageName(null);
    inputRef.current.value = null;
  };

  const handleConfirm = async () => {
    if (!onChange) return;

    try {
      // const file = await setCanvasImage(imageRef.current, canvasRef.current, crop);
      const treatedImage = await treatImage(imageFile);

      onChange({
        data: treatedImage,
        name: imageName,
        preview: imagePreview,
      });
    } catch (error) {
      alert.error(error.message);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <canvas ref={canvasRef} style={{ display: 'none' }} />

      <Box sx={{
        position: 'relative',
        ':hover': {
          transform: 'scale(1.02)',
        },
        height: '100%',
      }}
      >
        <input
          type="file"
          ref={inputRef}
          accept="image/*, application/pdf"
          onChange={handleImagePreview}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            cursor: 'pointer',
            opacity: 0,
          }}
        />

        {children}
      </Box>

      <Dialog open={Boolean(imagePreview)} sx={{ '& .MuiDialog-paper': { borderRadius: '12px', m: 0 } }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: '20px 24px',
          pb: '14px',
          borderBottom: '1px solid #EAEBEC',
        }}
        >
          <DialogTitle
            sx={{
              p: 0,
              fontSize: '16px',
              color: '#474750',
            }}
          >
            {/* Recortar imagem */}
            Confirme a imagem
          </DialogTitle>
          <CloseSquare onClick={handleClose} style={{ cursor: 'pointer' }} />
        </Box>

        <DialogContent sx={{
          py: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', px: '24px',
        }}
        >
          {/* <ReactCrop
            crop={crop}
            onChange={setCrop}
            aspect={aspectCrop}
            style={{ borderRadius: '6px' }}
          > */}
          <img
            src={imagePreview}
            ref={imageRef}
            // onLoad={handleCrop}
            alt="Imagem selecionada"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '6px',
            }}
          />
          {/* </ReactCrop> */}
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center', m: '10px 0 14px 0' }}>
          <Button
            onClick={handleConfirm}
            variant="contained"
            sx={{
              backgroundColor: '#4A397F',
              color: '#FFFFFF',
              textTransform: 'none',
              fontSize: '14px',
              borderRadius: '80px',
              boxShadow: 'none',
              padding: '8px 45px',
              letterSpacing: '0.175px',

              '&:hover': {
                backgroundColor: '#4A397F',
              },
            }}
          >
            {/* Recortar */}
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ImagePicker.propTypes = {
  // aspect: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
