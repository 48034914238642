import React from 'react';
import './notFound.scss';
import ops from '../../assets/404.png';

function NotFound() {
  return (
    <div className="container_notFound">
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <div>
          <img src={ops} alt="ops, 404" />
        </div>
        <div>
          <h2>Página não encontrada</h2>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
