import {
  Grid,
} from '@mui/material';
import React, { useEffect } from 'react';
import { cnpjInputMask, maskNumber } from '../../../../../utils/inputMasks';
import { StyledTextField } from '../style';
import BenvoDropdown from '../../../../../components/BenvoDropdown';

export default function IlpiInfoForm({
  watch,
  register,
  errors,
  setError,
  clearErrors,
}) {
  useEffect(() => {
    const residentsCapacity = watch('residentsCapacity') ? Number(watch('residentsCapacity')) : 0;
    const residentsQuantity = watch('residentsQuantity') ? Number(watch('residentsQuantity')) : 0;

    if (residentsQuantity && residentsQuantity > residentsCapacity) {
      setError('residentsQuantity', {
        type: 'manual',
        message: 'A quantidade de residentes não pode ser maior que a capacidade',
      });
    } else {
      clearErrors('residentsQuantity');
    }
  }, [watch('residentsCapacity'), watch('residentsQuantity')]);

  const ilpiInfoErrors = {
    ...errors.name,
    ...errors.responsibleName,
    ...errors.cnpj,
    ...errors.fantasyName,
    ...errors.residentsCapacity,
    ...errors.residentsQuantity,
    ...errors.password,
    ...errors.confirmPassword,
  };

  return (
    <BenvoDropdown title="Dados" initialState error={ilpiInfoErrors}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Razão Social"
            error={!!errors.name}
            helperText={errors.name?.message}
            {...register('name')}
          />
        </Grid>

        <Grid item xs={7}>
          <StyledTextField
            id="cnpj"
            name="cnpj"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Responsável"
            error={!!errors.responsibleName}
            helperText={errors.responsibleName?.message}
            {...register('responsibleName')}
          />
        </Grid>

        <Grid item xs={5}>
          <StyledTextField
            id="cnpj"
            name="cnpj"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="CNPJ"
            data-testid="cnpj"
            error={!!errors.cnpj}
            helperText={errors.cnpj?.message}
            value={cnpjInputMask(watch('cnpj')) ?? ''}
            {...register('cnpj')}
            inputProps={{
              maxLength: 18,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledTextField
            id="name"
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Nome Fantasia"
            error={!!errors.fantasyName}
            helperText={errors.fantasyName?.message}
            {...register('fantasyName')}
          />
        </Grid>

        <Grid item xs={6}>
          <StyledTextField
            id="name"
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Capacidade de residentes"
            error={!!errors.residentsCapacity}
            helperText={errors.residentsCapacity?.message}
            value={maskNumber(watch('residentsCapacity'))}
            {...register('residentsCapacity')}
          />
        </Grid>

        <Grid item xs={6}>
          <StyledTextField
            id="name"
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Quantidade ocupada"
            error={!!errors.residentsQuantity}
            helperText={errors.residentsQuantity?.message}
            value={maskNumber(watch('residentsQuantity'))}
            {...register('residentsQuantity')}
          />
        </Grid>
      </Grid>
    </BenvoDropdown>
  );
}
