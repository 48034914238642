import { unparse } from 'papaparse';
import { saveAs } from 'file-saver';

const generateCsv = (fileName, fields, data) => {
  const csvContent = unparse({
    fields,
    data,
  });

  const file = new Blob([csvContent], {
    type: 'text/csv;charset=utf-8',
  });

  saveAs(file, `${fileName}.csv`);
};

export default generateCsv;
