import { Dialog, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';

export const StyledDialog = styled(Dialog)`
.MuiDialog-paper {
    padding: 22px;
    display: flex;
    border-radius: 6px;
    background: #fff;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    gap: 14px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  }

  .MuiBackdrop-root {
    background-color: #0000001a;
    backdrop-filter: blur(1px);
  }
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;

  border-bottom: 1px solid #f5f6f8;
  padding-bottom: 14px;
`;

export const Title = styled(Typography)`
  display: flex;
  width: 299px;
  height: 22px;
  flex-direction: column;
  color: var(--main-and-gray-half-dark-bluegray, #435565);
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: 0.024px;
`;

export const Description = styled(Typography)`
  display: flex;
  width: 299px;
  flex-direction: column;
  color: var(--main-and-gray-medium-bluegray, #848f99);
  text-align: center;
  font-size: 14px;
  font-family: Poppins;
  line-height: 140%;
  letter-spacing: 0.035px;
`;
