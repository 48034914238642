import React, { useEffect, useState } from 'react';
import { MenuItem, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  ButtonsContainer,
  ExamItemContainer,
  ExamItemInformation,
  ExamsContainer,
  ExamsListContainer,
  ObservationText,
  ObservationTextContainer,
  ObservationTextSpan,
  PageContainer,
  PageSubtitle,
  PageTitle,
  Price,
  SearchContainer,
  StyledCheckbox,
  StyledDivider,
} from './style';
import BenvoSelectField from '../../../../../../components/BenvoSelectField';
import { getTokenInfo } from '../../../../../../utils/configAuth';
import routes from '../../../../../../services/routes';
import BenvoSearchField from '../../../../../../components/BenvoSearchField';
import { BRLMask, upperCaseFirstLetter } from '../../../../../../utils/inputMasks';

import { ReactComponent as RadioUnchecked } from '../../../../../../assets/healthService/radio.svg';
import { ReactComponent as RadioChecked } from '../../../../../../assets/healthService/radio-checked.svg';
import BenvoButton from '../../../../../../components/BenvoButton/style';

const { healthServiceList: serviceListRoute, healthService } = routes;
export default function ProductsStep({ setHealthServiceOrder, healthServiceOrder, setActiveStep }) {
  const ilpiId = getTokenInfo('ilpiId');

  const INITIAL_STATE = {
    serviceIds: [],
    services: [],
  };
  const [selectedExams, setSelectedExams] = useState({});
  const [listOfExams, setListOfExams] = useState([]);
  const [createListOfExam, setCreateListOfExam] = useState(INITIAL_STATE);
  const [listServicesHome, setListServicesHome] = useState([]);

  const getCreatedExamsList = async () => {
    try {
      const { message } = await serviceListRoute.findHealthServiceListByIlpiId({ ilpiId });
      return message;
    } catch (error) {
      return error;
    }
  };

  const { data: createdExamsListData = {}, isLoading: examsLoading } = useQuery({
    queryKey: ['createdExamsList'],
    queryFn: getCreatedExamsList,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 0,
  });

  const getExamsList = async () => {
    try {
      const { message } = await healthService.findAllByType();
      const { services } = message[0];
      setListOfExams(services.filter((service) => !createListOfExam.serviceIds.includes(service.id)));
      setCreateListOfExam((prevState) => ({
        ...prevState,
        serviceType: message[0].type,
      }));
      return message;
    } catch (error) {
      return error;
    }
  };

  const { isLoading: examsListIsLoading } = useQuery({
    queryKey: ['getExamsList'],
    queryFn: getExamsList,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const handleSearch = async (event) => {
    const { value } = event.target;
    if (!value || value.length > 2) {
      try {
        const { message } = await healthService.findAllByType({
          name: value,
        });
        const { services } = message[0];
        setListOfExams(services.filter((service) => !createListOfExam.serviceIds.includes(service.id)));
        setCreateListOfExam((prevState) => ({
          ...prevState,
          serviceType: message[0].type,
        }));
        return message;
      } catch (error) {
        setListOfExams([]);
        return error;
      }
    }
    return null;
  };

  const handleToggleItem = (event, exam) => {
    const { checked } = event.target;
    if (checked) {
      setCreateListOfExam((prevState) => ({
        ...prevState,
        serviceIds: [...prevState.serviceIds, exam.id],
        services: [...prevState.services, exam],
      }));
      setListOfExams((prevState) => prevState.filter((service) => service.id !== exam.id));
    } else {
      setCreateListOfExam((prevState) => ({
        ...prevState,
        serviceIds: prevState.serviceIds.filter((id) => id !== exam.id),
        services: prevState.services.filter((service) => service.id !== exam.id),
      }));
      const sortedExams = [exam, ...listOfExams].sort((a, b) => a.name.localeCompare(b.name));
      setListOfExams(sortedExams);
    }
  };

  useEffect(() => {
    if (selectedExams.id) {
      const selectedExamsIds = selectedExams.services.map((service) => service.id);
      setCreateListOfExam((prevState) => ({
        ...prevState,
        serviceIds: selectedExamsIds,
        services: selectedExams.services,
      }));
      setListOfExams((prevState) => prevState.filter((service) => !selectedExamsIds.includes(service.id)));
      const examsIsHome = selectedExams.services.filter((service) => service.isHome);
      setListServicesHome(examsIsHome);
    } else {
      setCreateListOfExam(INITIAL_STATE);
    }
  }, [selectedExams && selectedExams.id]);

  useEffect(() => {
    setHealthServiceOrder((prevState) => ({
      ...prevState,
      serviceIds: createListOfExam.serviceIds,
    }));
    const examsIsHome = createListOfExam.services.filter((service) => service.isHome);
    setListServicesHome(examsIsHome);
  }, [createListOfExam]);

  return (
    <PageContainer>
      <PageTitle>Selecione serviços de saúde</PageTitle>
      <ExamsContainer>
        {examsLoading ? (
          <Skeleton variant="rounded" width="100%" height="40px" />
        ) : (
          <BenvoSelectField fullWidth value={selectedExams.id || ''} displayEmpty>
            <MenuItem
              sx={{
                color: '#BDBDBD',
              }}
              value=""
              onClick={() => setSelectedExams({})}
            >
              Lista de exames
            </MenuItem>
            {createdExamsListData.rows?.map((exam) => (
              <MenuItem key={exam.id} value={exam.id} onClick={() => setSelectedExams(exam)}>
                {exam.name}
              </MenuItem>
            ))}
          </BenvoSelectField>
        )}
        <StyledDivider flexItem />
        <PageSubtitle>Pesquise e selecione exames disponíveis</PageSubtitle>
        <SearchContainer>
          <BenvoSearchField placeholder="Pesquisar" onChange={handleSearch} />
        </SearchContainer>
        {examsListIsLoading ? (
          <Skeleton variant="rounded" width="100%" height="220px" />
        ) : (
          <ExamsListContainer>
            {listOfExams?.map((examItem) => (
              <ExamItemContainer key={examItem.id}>
                <ExamItemInformation>
                  <PageSubtitle>{examItem.name}</PageSubtitle>
                  <StyledDivider orientation="vertical" flexItem />
                  <Price>{BRLMask(examItem.price)}</Price>
                </ExamItemInformation>
                <StyledCheckbox
                  icon={<RadioUnchecked />}
                  checkedIcon={<RadioChecked />}
                  id={examItem.id}
                  onChange={(event) => handleToggleItem(event, examItem)}
                />
              </ExamItemContainer>
            ))}
          </ExamsListContainer>
        )}

        {createListOfExam?.services?.length > 0 && (
          <>
            <StyledDivider flexItem />
            <PageSubtitle>Exames selecionados</PageSubtitle>
            <ExamsListContainer>
              {createListOfExam?.services?.map((examItem) => (
                <ExamItemContainer key={examItem.id}>
                  <ExamItemInformation>
                    <PageSubtitle>{examItem.name}</PageSubtitle>
                    <StyledDivider orientation="vertical" flexItem />
                    <Price>{BRLMask(examItem.price)}</Price>
                  </ExamItemInformation>
                  <StyledCheckbox
                    icon={<RadioUnchecked />}
                    checkedIcon={<RadioChecked />}
                    id={examItem.id}
                    onChange={(event) => handleToggleItem(event, examItem)}
                    checked={createListOfExam.serviceIds.includes(examItem.id)}
                  />
                </ExamItemContainer>
              ))}
            </ExamsListContainer>
          </>
        )}

        {listServicesHome.length > 0 && (
          <>
            <StyledDivider flexItem />
            <ObservationTextContainer>
              <ObservationText>Serviços com disponibilidade para realização em domicílio:</ObservationText>
              {listServicesHome.map((service) => (
                <ObservationTextSpan component="span" key={service.id}>
                  {`${upperCaseFirstLetter(service.name)}; `}
                </ObservationTextSpan>
              ))}
            </ObservationTextContainer>
          </>
        )}
      </ExamsContainer>
      <ButtonsContainer>
        <BenvoButton horizontalPadding="25" variant="secondary" onClick={() => setActiveStep(0)}>
          Voltar
        </BenvoButton>
        <BenvoButton
          disabled={!healthServiceOrder.serviceIds.length}
          onClick={() => setActiveStep(2)}
          horizontalPadding="25"
        >
          Continuar
        </BenvoButton>
      </ButtonsContainer>
    </PageContainer>
  );
}
