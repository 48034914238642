import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { PictureAsPdf, Visibility } from '@mui/icons-material';
import moment from 'moment';
import MTable from '../../../../components/MuiTable/index';
import convertStatus from '../../utils/convertStatus';
import statusColor from '../../utils/statusColor';
import ConfirmationDialog from '../../../../components/confirmationDialog/index';
import GeneratePDF from '../ModalGeneratePdf/index';
import ModalVisualizedRecipe from '../ModalVisualizedRecipe/index';
import { useRecipe } from '../../context/recipeContext';

function TableRecipe() {
  const { listRecipe, deleteRecipe, getPDFLink } = useRecipe();
  const [open, setOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState([]);

  const handleModalVisualizedClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <ModalVisualizedRecipe
        isOpen={open}
        handleModalVisualizedClose={handleModalVisualizedClose}
        recipeInfo={selectedRecipe}
      />
      <MTable.Container sx={{ mt: 4 }}>
        <MTable.Root>
          <MTable.Head>
            <MTable.Row>
              <MTable.Cell>Residente</MTable.Cell>
              <MTable.Cell>Tipo de Receita</MTable.Cell>
              <MTable.Cell>Status</MTable.Cell>
              <MTable.Cell>Data de Criação</MTable.Cell>
              <MTable.Cell>Digitalizável</MTable.Cell>
              <MTable.Cell>Ações</MTable.Cell>
            </MTable.Row>
          </MTable.Head>
          <MTable.Body>
            {listRecipe?.map((recipe) => (
              <MTable.Row key={recipe.id}>
                <MTable.Cell>{ recipe.residentName }</MTable.Cell>
                <MTable.Cell>{ recipe.recipeType }</MTable.Cell>
                <MTable.Cell
                  sx={{ color: statusColor(recipe.status) }}
                >
                  {convertStatus(recipe.status)}
                </MTable.Cell>
                <MTable.Cell>{ moment(recipe.createdAt).format('DD/MM/yyyy') }</MTable.Cell>
                <MTable.Cell>{ recipe.isDigitalizable ? 'Sim' : 'Não' }</MTable.Cell>
                <MTable.Cell>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: 4,
                    }}
                  >
                    <Tooltip title="Imprimir PDF">
                      {recipe.isDigitalizable ? (
                        <IconButton
                          color="primary"
                          style={{ backgroundColor: 'transparent' }}
                          onClick={() => getPDFLink(recipe.id)}
                          disabled={recipe.status !== 'signed'}
                        >
                          <PictureAsPdf />
                        </IconButton>
                      ) : (
                        <span>
                          <GeneratePDF
                            thisRecipe={{
                              recipeId: recipe.id,
                              doctorId: recipe.doctorId,
                              name: recipe.residentName,
                              status: recipe.status,
                            }}
                          />
                        </span>
                      )}
                    </Tooltip>
                    <Tooltip title="Visualizar">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setOpen(true);
                          setSelectedRecipe(recipe);
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                      <ConfirmationDialog
                        title="Deseja remover a receita?"
                        message="Essa ação não poderá ser desfeita."
                        handleConfirm={() => deleteRecipe(recipe.id)}
                      />
                    </Tooltip>
                  </div>
                </MTable.Cell>
              </MTable.Row>
            ))}
          </MTable.Body>
        </MTable.Root>
      </MTable.Container>
    </>
  );
}

export default TableRecipe;
