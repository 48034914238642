import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from './style';
import applicationPaths from '../../../../constants/applicationPaths';

export default function HomeResidentsCard() {
  return (
    <Card>
      <h2>
        Envio de Listas e controle de residentes
      </h2>
      <p>Amplie a satisfação dos seus clientes otimizando as listas de medicamentos e higiene.</p>
      <Link to={applicationPaths.residents.root}>
        <Button type="button" variant="secondary">Gerenciar residentes</Button>
      </Link>
    </Card>
  );
}
