/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  MenuItem, Divider, CircularProgress, Tooltip,
} from '@mui/material';
import BenvoDrawer from '../../../../components/BenvoDrawer';
import BenvoSelectField from '../../../../components/BenvoSelectField';
import Doctor from './types/Doctor';
import Nurse from './types/Nurse';
import Caregiver from './types/Caregiver';
import Employee from './types/Employee';
import { Container, LoadingContainer, Title } from './style';
import BenvoIconButton from '../../../../components/BenvoIconButton/style';
import EditIcon from '../../../../assets/icons/EditIcon';
import routes from '../../../../services/routes';
import BenvoButton from '../../../../components/BenvoButton/style';

const { collaborator: collaboratorRoute } = routes;

const types = [
  { value: 'doctor', label: 'Médico', dataTestId: 'doctor' },
  { value: 'nurse', label: 'Enfermeiro', dataTestId: 'nurse' },
  { value: 'caregiver', label: 'Cuidador', dataTestId: 'caregiver' },
  { value: 'employee', label: 'Funcionário', dataTestId: 'employee' },
];

export default function EditCollaborator({ getUsersAndCollaborators, collaborator, isButton = false }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [gettingCollaboratorData, setGettingCollaboratorData] = useState(false); // eslint-disable-line no-unused-vars
  const [collaboratorData, setCollaboratorData] = useState({});
  const [selectedType, setSelectedType] = useState(collaborator.type);

  const getCurrentCollaboratorData = async () => {
    setGettingCollaboratorData(true);
    try {
      const { message } = await collaboratorRoute.getCollaboratorById({ collaboratorId: collaborator.collaboratorId });
      setCollaboratorData(message);
      return message;
    } catch (error) {
      return error;
    } finally {
      setGettingCollaboratorData(false);
    }
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
    getCurrentCollaboratorData();
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const renderFormBasedOnType = () => {
    const form = {
      doctor: <Doctor
        handleCloseDrawer={handleCloseDrawer}
        selectedType={selectedType}
        getUsersAndCollaborators={getUsersAndCollaborators}
        collaboratorData={collaboratorData}
      />,
      nurse: <Nurse
        handleCloseDrawer={handleCloseDrawer}
        selectedType={selectedType}
        getUsersAndCollaborators={getUsersAndCollaborators}
        collaboratorData={collaboratorData}
      />,
      caregiver: <Caregiver
        handleCloseDrawer={handleCloseDrawer}
        selectedType={selectedType}
        getUsersAndCollaborators={getUsersAndCollaborators}
        collaboratorData={collaboratorData}
      />,
      employee: <Employee
        handleCloseDrawer={handleCloseDrawer}
        selectedType={selectedType}
        getUsersAndCollaborators={getUsersAndCollaborators}
        collaboratorData={collaboratorData}
      />,
    };
    return form[selectedType];
  };

  return (
    <>
      {isButton ? (
        <BenvoButton variant="secondary" onClick={handleOpenDrawer}>
          Visualizar
        </BenvoButton>
      ) : (
        <Tooltip title="Editar Colaborador" placement="top" arrow>
          <BenvoIconButton
            onClick={handleOpenDrawer}
          >
            <EditIcon />
          </BenvoIconButton>
        </Tooltip>
      )}
      <BenvoDrawer
        open={openDrawer}
        onClose={handleCloseDrawer}
        title="Edição de funcionário"
        justifyContent="flex-start"
      >
        {gettingCollaboratorData ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <Container>
            <Title variant="h6">Area de atuação</Title>
            <BenvoSelectField
              fullWidth
              readOnly
              value={selectedType}
            >
              {types.map((type) => (
                <MenuItem
                  key={type.value}
                  value={type.value}
                  data-testid={type.dataTestId}
                  onClick={() => setSelectedType(type.value)}
                >
                  {type.label}
                </MenuItem>
              ))}
            </BenvoSelectField>
            <Divider orientation="horizontal" flexItem />
            {renderFormBasedOnType()}
          </Container>
        )}
      </BenvoDrawer>
    </>
  );
}
