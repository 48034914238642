import React from 'react';

export default function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill="#4A397F"
        stroke="#4A397F"
        d="M15.125 7.167v1.197c-.082-.006-.165-.01-.25-.015V7.167c0-1.335-.183-2.585-.941-3.493-.776-.929-2.049-1.382-3.934-1.382-1.885 0-3.158.453-3.934 1.382-.758.908-.941 2.158-.941 3.493v1.182c-.085.004-.168.01-.25.015V7.167c0-1.187.148-2.46.83-3.432C6.364 2.8 7.594 2.042 10 2.042s3.637.758 4.294 1.693c.683.972.831 2.245.831 3.432z"
      />
      <path
        fill="#4A397F"
        stroke="#4A397F"
        d="M4.436 9.413h.007c.339-.047.733-.071 1.187-.08h8.74c.455.009.849.033 1.188.08h.007c.991.12 1.494.406 1.79.869.327.508.479 1.332.479 2.718v1.667c0 1.67-.221 2.513-.687 2.98-.466.466-1.31.686-2.98.686H5.834c-1.67 0-2.514-.22-2.98-.686-.466-.466-.687-1.31-.687-2.98V13c0-1.386.152-2.21.478-2.718.297-.463.8-.748 1.79-.869z"
        opacity="0.4"
      />
      <path
        fill="#292D32"
        stroke="#4A397F"
        d="M6.542 14.139h0l-.013-.006a.333.333 0 01-.103-.065.375.375 0 01-.093-.235c0-.032.008-.075.028-.124a.454.454 0 01.07-.114.327.327 0 01.098-.062l.01-.003.01-.004a.333.333 0 01.347.06c.033.04.058.08.075.123l.445-.185-.445.185c.02.049.029.092.029.124a.376.376 0 01-.096.238.376.376 0 01-.238.096.331.331 0 01-.124-.028z"
      />
      <path
        fill="#292D32"
        d="M10 14.667a.86.86 0 01-.591-.242.963.963 0 01-.175-.275.83.83 0 01-.067-.317.86.86 0 01.242-.591c.308-.309.866-.309 1.183 0a.86.86 0 01.242.591.83.83 0 01-.067.317c-.042.1-.1.192-.175.275a.876.876 0 01-.592.242zM13.333 14.667a.876.876 0 01-.591-.242.86.86 0 01-.242-.592.86.86 0 01.242-.591c.316-.309.875-.309 1.183 0 .033.041.067.083.1.133a.464.464 0 01.075.142c.025.05.042.1.05.15.008.058.017.116.017.166a.876.876 0 01-.242.592.876.876 0 01-.592.242z"
      />
    </svg>
  );
}
