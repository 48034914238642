import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import EmptyListImage from '../../assets/icons/emptyList.png';

function BenvoEmpty({
  active, title, message, children, sx,
}) {
  if (active) {
    return (
      <Box
        data-testid="marketplace-empty-list-card"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
          ...sx,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
          }}
        >
          <img src={EmptyListImage} alt="" />
          <Typography variant="h6" fontWeight={700} color="#474750" sx={{ fontSize: '18px' }}>
            {title}
          </Typography>

          <Typography
            variant="body1"
            fontWeight={400}
            color="#96969E"
            sx={{
              fontSize: '14px', maxWidth: '336px', textAlign: 'center', mb: '8px',
            }}
          >
            {message}
          </Typography>

          {children}
        </Box>
      </Box>
    );
  }
}

BenvoEmpty.propTypes = {
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
};

BenvoEmpty.defaultProps = {
  children: null,
};

export default BenvoEmpty;
