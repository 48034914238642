import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logout } from '../../../../assets/icons/logout.svg';
import {
  StyledBoxMenu, StyledTypographyMenu,
  StyledBoxMenuItem, StyledBoxMenuWindow, StyledMenuItem, StyledMenuList,
} from './style';

function Item({
  Icon, to, onClick, active, children,
}) {
  return (
    <Link to={to} style={{ textDecoration: 'none' }} onClick={() => onClick && onClick()}>
      <StyledBoxMenuItem active={active}>
        <StyledMenuItem>
          <Icon style={{ minWidth: '16px' }} />
          <StyledTypographyMenu>{children}</StyledTypographyMenu>
        </StyledMenuItem>
      </StyledBoxMenuItem>
    </Link>
  );
}

export default function MenuWindow({ open }) {
  const handleLogout = () => {
    window.location.reload(false);
  };

  if (!open) return null;

  return (
    <StyledBoxMenuWindow>
      <StyledBoxMenu>
        <StyledMenuList>
          <Item Icon={Logout} onClick={handleLogout}>Sair</Item>
        </StyledMenuList>
      </StyledBoxMenu>
    </StyledBoxMenuWindow>
  );
}
