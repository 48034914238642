import React from 'react';

export default function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#474750"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.168 1.667H7.501c-4.166 0-5.833 1.666-5.833 5.833v5c0 4.166 1.667 5.833 5.833 5.833h5c4.167 0 5.834-1.666 5.834-5.833v-1.667"
      />
      <path
        stroke="#474750"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M13.367 2.517L6.801 9.084c-.25.25-.5.741-.55 1.1l-.358 2.508c-.134.908.508 1.541 1.416 1.416l2.509-.358c.35-.05.841-.3 1.1-.55l6.566-6.566c1.133-1.134 1.667-2.45 0-4.117-1.667-1.667-2.983-1.133-4.117 0z"
      />
      <path
        fill="#D4D4DA"
        d="M12.426 3.458a5.954 5.954 0 004.116 4.117l-4.116-4.117z"
      />
      <path
        stroke="#474750"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M12.426 3.458a5.954 5.954 0 004.116 4.117"
      />
    </svg>
  );
}
