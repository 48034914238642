import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import BenvoTooltip from '../../../../components/BenvoTooltip';
import CloseIcon from '../../../../assets/residents/CloseIcon';
import {
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
} from './style';
import removeReferenceContactImage from '../../../../assets/residents/removeReferenceContactImage.webp';
import cannotRemoveReferente from '../../../../assets/residents/cannotRemoveReferente.webp';
import routes from '../../../../services/routes';

export default function RemoveContactModal({
  editReferenceContact,
  contactInformation,
  residentInformation,
  residentInformationToEdit,
  referenceList,
}) {
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const queryClient = useQueryClient();

  const cannotRemoveReference = referenceList.length <= 1;

  const { resident } = routes;

  const handleOpenRemoveModal = () => setOpenRemoveModal(true);
  const handleCloseRemoveModal = () => setOpenRemoveModal(false);

  const handleRemoveReferenceContact = async () => {
    try {
      const response = await resident.removeContactAssociation({
        contactId: contactInformation.id,
        residentId: residentInformation.id ?? residentInformationToEdit.id,
      });
      queryClient.invalidateQueries({
        queryKey: ['referenceContactList'],
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  return (
    <div>
      <BenvoTooltip title="Remover" arrow placement="top">
        <StyledIconButton
          onClick={handleOpenRemoveModal}
          disabled={editReferenceContact}
        >
          <CloseIcon />
        </StyledIconButton>
      </BenvoTooltip>
      <StyledDialog open={openRemoveModal} onClose={handleCloseRemoveModal}>
        {cannotRemoveReference ? (
          <img
            src={cannotRemoveReferente}
            alt="Não é permitido excluir todos os contatos"
          />
        ) : (
          <img
            src={removeReferenceContactImage}
            alt="Remover contato de referência"
          />
        )}
        <StyledDialogTitle>
          {cannotRemoveReference
            ? 'Ação não permitida'
            : 'Remover contato de referência'}
        </StyledDialogTitle>
        <StyledDialogContent>
          {cannotRemoveReference
            ? 'Não é permitido excluir todos os contatos. Pelo menos um contato deve permanecer cadastrado.'
            : 'Tem certeza que deseja retirar este contato de referência deste residente?'}
        </StyledDialogContent>
        {cannotRemoveReference ? (
          <StyledDialogActions>
            <StyledButton
              onClick={handleCloseRemoveModal}
              variant="secondary"
              horizontalPadding={121.5}
            >
              Voltar
            </StyledButton>
          </StyledDialogActions>
        ) : (
          <StyledDialogActions>
            <StyledButton
              onClick={handleCloseRemoveModal}
              variant="secondary"
              horizontalPadding={24}
            >
              Cancelar
            </StyledButton>
            <StyledButton
              onClick={handleRemoveReferenceContact}
              horizontalPadding={24}
            >
              Sim
            </StyledButton>
          </StyledDialogActions>
        )}
      </StyledDialog>
    </div>
  );
}
