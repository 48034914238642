import React from 'react';
import { Collapse } from '@mui/material';
import {
  ContactEmailOrPhone,
  ContactInfoContainer,
  ContactName,
  ContactContainer,
  Container,
  StyledIconButton,
  GeneralContainer,
} from './style';
import EditIcon from '../../../../assets/residents/EditIcon';
import BenvoTooltip from '../../../../components/BenvoTooltip';
import { phoneWithCountryCodeMaskAndSpace } from '../../../../utils/inputMasks';
import EditReferenceContactForm from '../EditReferenceContactForm';
import RemoveContactModal from '../RemoveContactModal';

export default function ReferenceContactList({
  contact,
  residentInformation,
  editReferenceContact,
  setEditReferenceContact,
  residentInformationToEdit,
  referenceList,
}) {
  const [isEditing, setIsEditing] = React.useState(false);
  const handleEdit = () => {
    setIsEditing(true);
    setEditReferenceContact(true);
  };

  return (
    <GeneralContainer>
      <Container>
        <ContactContainer>
          <ContactInfoContainer>
            <ContactName>{contact.name}</ContactName>
            <ContactEmailOrPhone>
              {phoneWithCountryCodeMaskAndSpace(contact.phone)}
            </ContactEmailOrPhone>
            {contact.email && (
            <ContactEmailOrPhone>{`,${''} ${contact.email}`}</ContactEmailOrPhone>
            )}
          </ContactInfoContainer>
        </ContactContainer>

        <BenvoTooltip title="Editar" arrow placement="top">
          <StyledIconButton onClick={handleEdit} disabled={editReferenceContact}>
            <EditIcon />
          </StyledIconButton>
        </BenvoTooltip>

        <RemoveContactModal
          contactInformation={contact}
          residentInformation={residentInformation}
          editReferenceContact={editReferenceContact}
          residentInformationToEdit={residentInformationToEdit}
          referenceList={referenceList}
        />
      </Container>
      <Collapse in={isEditing}>
        <EditReferenceContactForm
          contactInformation={contact}
          residentInformation={residentInformation}
          setIsEditing={setIsEditing}
          setEditReferenceContact={setEditReferenceContact}
          residentInformationToEdit={residentInformationToEdit}
        />
      </Collapse>
    </GeneralContainer>
  );
}
