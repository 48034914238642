import { Select, styled } from '@mui/material';

const StyledSelect = styled(Select)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  height: 40px;
  border: none;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
  
  & .Mui-readOnly{
    color: var(--grey-variation-light-grey, #D4D4DA);
    cursor: not-allowed;
  }

  & fieldset {
    border: none;
    ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
  }

  .MuiSelect-select {
    ${({ value }) => value === '' && 'color: var(--grey-variation-light-grey, #47475070)'}
  }
`;

export default StyledSelect;
