const API_URL = process.env.REACT_APP_API_URL;

const NGROK_URL = process.env.REACT_APP_DEV_API_URL;

const PROTOCOL = process.env.REACT_APP_PROTOCOL || 'http';

const PORT = process.env.REACT_APP_API_PORT;

const URL = {
  BASE_URL: API_URL,
  SECOND_URL: NGROK_URL,
  PROTOCOL,
  PORT,
};

export default URL;
