/* eslint-disable import/prefer-default-export */
export const organizeFilesToDasa = async (files) => {
  const filesArray = await Promise.all(files.map(async (file) => {
    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.replace(`data:${file.type};base64,`, ''));
    });

    return {
      type: file.type,
      base64,
    };
  }));

  return filesArray;
};
