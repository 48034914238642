import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { PostHogProvider } from 'posthog-js/react';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

root.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <App />
  </PostHogProvider>,
);
