/* eslint-disable max-len */
import React from 'react';

export default function EyeIconClose({
  width = '18', height = '18', color = '#fff', viewBox = '0 0 18 18',
}) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M15.5516 14.4419C13.7885 15.6217 12.1785 16.1111 10.331 16.1111H9.66904C5.59998 16.1111 2.05308 13.3965 1.06619 9.52704C0.977936 9.18101 0.977936 8.81899 1.06619 8.47295C1.53925 6.61816 2.6005 5.02873 4.02551 3.87643C4.38553 3.625 4.55906 3.66667 4.55906 3.66667M15.5516 14.4419L18.1614 17M15.5516 14.4419L12.4442 11.3959M8.16075 2.01491C8.65252 1.93193 9.15665 1.88889 9.66905 1.88889H10.331C14.4 1.88889 17.9469 4.60345 18.9338 8.47296C19.0221 8.81899 19.0221 9.18101 18.9338 9.52704C18.785 10.1104 18.5781 10.6675 18.3199 11.193M10.8534 5.79515C12.02 6.0928 12.9413 6.98632 13.2588 8.12433M1.83859 1L4.55906 3.66667M7.62352 6.67052C6.98005 7.27614 6.69548 8.05995 6.69548 9.00343C6.69548 10.8415 8.21556 12.3315 10.0907 12.3315C11.0042 12.3315 11.8339 11.9712 12.4442 11.3959M7.62352 6.67052L12.4442 11.3959M7.62352 6.67052L4.55906 3.66667" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
