import Joi from 'joi';
import { matchIsValidTel } from 'mui-tel-input';

const createIlpi = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'O nome é obrigatório',
    'string.min': 'O nome deve ter no mínimo 3 caracteres',
  }),
  responsibleName: Joi.string().required().messages({
    'string.empty': 'O nome do responsável é obrigatório',
    'string.min': 'O nome do responsável deve ter no mínimo 3 caracteres',
  }),
  cnpj: Joi.string().length(18).required()
    .messages({
      'string.empty': 'O CNPJ é obrigatório',
      'string.length': 'O CNPJ deve ter 14 caracteres',
    }),
  fantasyName: Joi.string().required().messages({
    'any.required': 'O nome fantasia é obrigatório',
    'string.empty': 'O nome fantasia é obrigatório',
    'string.min': 'O nome fantasia deve ter no mínimo 3 caracteres',
  }),
  residentsCapacity: Joi.string().required().messages({
    'any.required': 'A capacidade de residentes é obrigatória',
    'string.empty': 'A capacidade de residentes é obrigatória',
  }),
  residentsQuantity: Joi.string().required().messages({
    'any.required': 'A quantidade de residentes é obrigatória',
    'string.empty': 'A quantidade de residentes é obrigatória',
  }),
  zipCode: Joi.string().required().messages({
    'any.required': 'O CEP é obrigatório',
    'string.empty': 'O CEP é obrigatório',
  }),
  state: Joi.string().required().messages({
    'any.required': 'O estado é obrigatório',
    'string.empty': 'O estado é obrigatório',
  }),
  city: Joi.string().required().messages({
    'any.required': 'A cidade é obrigatória',
    'string.empty': 'A cidade é obrigatória',
  }),
  district: Joi.string().required().messages({
    'any.required': 'O bairro é obrigatório',
    'string.empty': 'O bairro é obrigatório',
  }),
  street: Joi.string().required().messages({
    'any.required': 'o Logradouro é obrigatório',
    'string.empty': 'o Logradouro é obrigatório',
  }),
  number: Joi.string().required().messages({
    'any.required': 'O número é obrigatório',
    'string.empty': 'O número é obrigatório',
  }),
  complement: Joi.string().allow(''),
  reference: Joi.string().allow(''),
  phoneNumber: Joi.string().custom((value, helpers) => {
    if (!matchIsValidTel(value)) {
      return helpers.message('Telefone inválido');
    }
    return value;
  }).required().messages({
    'any.required': 'O telefone é obrigatório',
    'string.empty': 'O telefone é obrigatório',
  }),
  landline: Joi.string().length(14).allow('').messages({
    'string.min': 'Telefone inválido',
    'string.max': 'Telefone inválido',
    'string.length': 'Telefone inválido',
  }),
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: false },
  }).required().messages({
    'any.required': 'O e-mail é obrigatório',
    'string.empty': 'O e-mail é obrigatório',
  }),
  features: Joi.array().required(),
});

const updateIlpi = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'O nome é obrigatório',
    'string.min': 'O nome deve ter no mínimo 3 caracteres',
  }),
  responsibleName: Joi.string().required().messages({
    'string.empty': 'O nome do responsável é obrigatório',
    'string.min': 'O nome do responsável deve ter no mínimo 3 caracteres',
  }),
  cnpj: Joi.string().length(18).required()
    .messages({
      'string.empty': 'O CNPJ é obrigatório',
      'string.min': 'O CNPJ deve ter no mínimo 11 caracteres',
      'string.length': 'O CNPJ deve ter 14 caracteres',
    }),
  fantasyName: Joi.string().required().messages({
    'any.required': 'O nome fantasia é obrigatório',
    'string.empty': 'O nome fantasia é obrigatório',
    'string.min': 'O nome fantasia deve ter no mínimo 3 caracteres',
  }),
  residentsCapacity: Joi.number().required().messages({
    'any.required': 'A capacidade de residentes é obrigatória',
    'number.empty': 'A capacidade de residentes é obrigatória',
  }),
  residentsQuantity: Joi.string().required().messages({
    'any.required': 'A quantidade de residentes é obrigatória',
    'string.empty': 'A quantidade de residentes é obrigatória',
  }),
  zipCode: Joi.string().required().messages({
    'any.required': 'O CEP é obrigatório',
    'string.empty': 'O CEP é obrigatório',
  }),
  state: Joi.string().required().messages({
    'any.required': 'O estado é obrigatório',
    'string.empty': 'O estado é obrigatório',
  }),
  city: Joi.string().required().messages({
    'any.required': 'A cidade é obrigatória',
    'string.empty': 'A cidade é obrigatória',
  }),
  district: Joi.string().required().messages({
    'any.required': 'O bairro é obrigatório',
    'string.empty': 'O bairro é obrigatório',
  }),
  street: Joi.string().required().messages({
    'any.required': 'o Logradouro é obrigatório',
    'string.empty': 'o Logradouro é obrigatório',
  }),
  number: Joi.string().required().messages({
    'any.required': 'O número é obrigatório',
    'string.empty': 'O número é obrigatório',
  }),
  complement: Joi.string().allow(''),
  reference: Joi.string().allow(''),
  phoneNumber: Joi.string().custom((value, helpers) => {
    if (!matchIsValidTel(value)) {
      return helpers.message('Telefone inválido');
    }
    return value;
  }).required().messages({
    'any.required': 'O telefone é obrigatório',
    'string.empty': 'O telefone é obrigatório',
  }),
  landline: Joi.string().length(14).allow('').messages({
    'string.min': 'Telefone inválido',
    'string.max': 'Telefone inválido',
    'string.length': 'Telefone inválido',
  }),
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: false },
  }).required().messages({
    'any.required': 'O e-mail é obrigatório',
    'string.empty': 'O e-mail é obrigatório',
  }),
  features: Joi.array().required(),
});

export default {
  createIlpi,
  updateIlpi,
};
