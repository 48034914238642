import React from 'react';
import {
  Box, Paper, Typography, Divider, IconButton,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import moment from 'moment';
import { Check, Close } from '@mui/icons-material';
import { mapTaskType } from '../../../../utils/mapTaskType';
import { getHourFromMoment } from '../../../../utils/getHourFromMoment';
import Button from '../../../../components/Button';
import { ReactComponent as TickIcon } from '../../../../assets/icons/tick-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-icon.svg';
import * as Color from '../../../../constants/color-system';

function RenderTasks({
  tasks,
  isShowCancelModelOn,
  setTaskIdToCancel,
  setIsShowCompleteModelOn,
  setTaskIdToComplete,
  setCurrentTypeTask,
  selectedTaskType,
}) {
  const treatHourFromAPi = (time) => {
    const [hour, minutes] = time.split(':');
    return `${hour}:${minutes}`;
  };

  if (!tasks.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Typography variant="body1" component="p">
          {selectedTaskType.value !== ''
            ? `Nenhuma tarefa de ${selectedTaskType.label} encontrada`
            : 'Não há tarefas para hoje'}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        minWidth: '368px',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {tasks.map((task) => (
        <Box key={task.date}>
          {task.taskType === 'cleaning' && (
            <Paper
              sx={{
                p: '18px',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    fontWeight="700"
                    color={Color.gray[600]}
                    sx={{
                      fontSize: '28px',
                    }}
                  >
                    {task.startTime
                      ? treatHourFromAPi(task.startTime)
                      : getHourFromMoment(task.date)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor:
                      task.startTime > moment().format('HH:mm:ss')
                        ? Color.gray[300]
                        : Color.purple[500],
                    py: '4px',
                    px: '18px',
                    borderRadius: '2px',
                  }}
                >
                  <Typography
                    color={Color.white}
                    fontWeight="700"
                    variant="body1"
                    component="p"
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    {mapTaskType(task.taskType).name}
                  </Typography>
                </Box>
              </Box>

              {task.tasks.map(({ taskId, title, observation }) => (
                <Box
                  key={taskId}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      gap: '20px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <Typography
                        fontWeight="700"
                        variant="body1"
                        component="p"
                        sx={{
                          fontSize: '16px',
                        }}
                      >
                        {title}
                      </Typography>
                      {observation && (
                        <Typography
                          variant="body1"
                          component="h6"
                          fontWeight={700}
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {'Observações: '}
                          <Typography
                            variant="span"
                            component="p"
                            sx={{
                              fontSize: '12px',
                              fontWeight: 500,
                              mt: '8px',
                              backgroundColor: Color.gray[100],
                              borderRadius: '8px',
                              p: '8px',
                            }}
                          >
                            {observation}
                          </Typography>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Divider orientation="horizontal" flexItem />
                  <Box
                    sx={{
                      minWidth: '82px',
                      minHeight: '40px',
                      display: 'flex',
                      gap: '12px',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      iconCenterLeft={<CloseIcon />}
                      variant="secondary"
                      onClick={() => {
                        setCurrentTypeTask(task.taskType);
                        isShowCancelModelOn(true);
                        setTaskIdToCancel(taskId);
                      }}
                      disabled={task.startTime > moment().format('HH:mm:ss')}
                    >
                      Recusar
                    </Button>
                    <Button
                      iconCenterLeft={<TickIcon />}
                      onClick={() => {
                        setIsShowCompleteModelOn(true);
                        setTaskIdToComplete(taskId);
                      }}
                      variant="primary"
                      disabled={task.startTime > moment().format('HH:mm:ss')}
                    >
                      Realizar
                    </Button>
                  </Box>
                </Box>
              ))}
            </Paper>
          )}
          {task.taskType !== 'cleaning' && (
            <Paper
              sx={{
                p: '18px',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    fontWeight="700"
                    color={Color.gray[600]}
                    sx={{
                      fontSize: '28px',
                    }}
                  >
                    {task.startTime
                      ? treatHourFromAPi(task.startTime)
                      : getHourFromMoment(task.date)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor:
                      task.startTime > moment().format('HH:mm:ss')
                        ? Color.gray[300]
                        : Color.purple[500],
                    py: '4px',
                    px: '18px',
                    borderRadius: '2px',
                  }}
                >
                  <Typography
                    color={Color.white}
                    fontWeight="700"
                    variant="body1"
                    component="p"
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    {mapTaskType(task.taskType).name}
                  </Typography>
                </Box>
              </Box>

              {task.residents.map(
                ({
                  taskId: generalTaskId, residentId, residentName, medicines, observation,
                }) => (
                  <Box
                    key={residentId}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        gap: '20px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                        }}
                      >
                        <Typography
                          variant="body1"
                          component="p"
                          sx={{
                            fontSize: '16px',
                          }}
                        >
                          {residentName}
                        </Typography>

                        {task.taskType !== 'medications' && observation && (
                        <Typography
                          variant="body1"
                          component="p"
                          fontWeight={700}
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {'Observações: '}
                          <Typography
                            variant="span"
                            component="p"
                            sx={{
                              fontSize: '12px',
                              fontWeight: 500,
                              mt: '8px',
                              backgroundColor: Color.gray[100],
                              borderRadius: '8px',
                              p: '8px',
                            }}
                          >
                            {observation}
                          </Typography>
                        </Typography>
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {task.taskType === 'medications' && (
                          <Typography
                            variant="body1"
                            component="h3"
                            fontWeight={700}
                            sx={{
                              fontSize: '14px',
                            }}
                          >
                            {'Remédios: '}
                            {task.taskType === 'medications'
                                && medicines.map(
                                  ({
                                    medicineName, quantity, unit, observation: taskObservation, taskId,
                                  }) => (
                                    <Box key={taskId}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="p"
                                          sx={{
                                            fontSize: '12px',
                                            mt: '8px',
                                            backgroundColor: Color.gray[100],
                                            borderRadius: '8px',
                                            p: '8px',

                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            component="span"
                                            sx={{
                                              fontSize: '12px',
                                            }}
                                          >
                                            {medicineName}
                                          </Typography>

                                          <Typography
                                            variant="body1"
                                            component="span"
                                            sx={{
                                              fontSize: '12px',
                                            }}
                                          >
                                            {`${quantity} ${unit}`}
                                          </Typography>
                                        </Typography>

                                        <IconButton
                                          disabled={task.startTime > moment().format('HH:mm:ss')}
                                          color="error"
                                          onClick={() => {
                                            setCurrentTypeTask(task.taskType);
                                            isShowCancelModelOn(true);
                                            setTaskIdToCancel(taskId);
                                          }}
                                          sx={{
                                            ml: '8px',
                                          }}
                                        >
                                          <Close />
                                        </IconButton>

                                        <IconButton
                                          disabled={task.startTime > moment().format('HH:mm:ss')}
                                          color="success"
                                          onClick={() => {
                                            setIsShowCompleteModelOn(true);
                                            setTaskIdToComplete(taskId);
                                          }}
                                        >
                                          <Check />
                                        </IconButton>
                                      </Box>

                                      <Box>
                                        {taskObservation && (
                                        <Typography
                                          variant="body1"
                                          component="p"
                                          color={Color.gray[500]}
                                          sx={{
                                            fontSize: '12px',
                                          }}
                                        >
                                          {taskObservation}
                                        </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                  ),
                                )}
                          </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Divider orientation="horizontal" flexItem />
                    {task.taskType !== 'medications' && (
                    <Box
                      sx={{
                        minWidth: '82px',
                        minHeight: '40px',
                        display: 'flex',
                        gap: '12px',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        iconCenterLeft={<CloseIcon />}
                        variant="secondary"
                        onClick={() => {
                          setCurrentTypeTask(task.taskType);
                          isShowCancelModelOn(true);
                          setTaskIdToCancel(generalTaskId);
                        }}
                        disabled={task.startTime > moment().format('HH:mm:ss')}
                      >
                        Recusar
                      </Button>
                      <Button
                        iconCenterLeft={<TickIcon />}
                        onClick={() => {
                          setIsShowCompleteModelOn(true);
                          setCurrentTypeTask(task.taskType);
                          setTaskIdToComplete(generalTaskId);
                        }}
                        disabled={task.startTime > moment().format('HH:mm:ss')}
                      >
                        Realizar
                      </Button>
                    </Box>
                    )}
                  </Box>
                ),
              )}
            </Paper>
          )}
        </Box>
      ))}
    </Grid>
  );
}

export default RenderTasks;
