export const shoppingListStatuses = {
  ORDER_CREATED: 'ORDER_CREATED',
  ORDER_SHARED: 'ORDER_SHARED',
  PAYMENT_STARTED: 'PAYMENT_STARTED',
  PAYMENT_PROCESSING: 'PAYMENT_PROCESSING',
  PAYMENT_CONFIRMED: 'PAYMENT_CONFIRMED',
  PAYMENT_REFUNDED: 'PAYMENT_REFUNDED',
  ORDER_DELIVERED: 'ORDER_DELIVERED',
  ORDER_CANCELLED: 'ORDER_CANCELLED',
  ORDER_EXPIRED: 'ORDER_EXPIRED',
  ORDER_FAILED: 'ORDER_FAILED',
  ORDER_CLOSED: 'ORDER_CLOSED',

  LIST_CREATED: 'LIST_CREATED',
  LIST_UPDATED: 'LIST_UPDATED',
  LIST_SYNCED: 'LIST_SYNCED',
  LIST_NOT_SENT: 'LIST_NOT_SENT',
  LIST_CLOSED: 'LIST_CLOSED',
};

export const UiLabels = {
  LIST_SAVED: 'Lista salva',
  LIST_SENT: 'Lista enviada',
  LIST_NOT_SENT: 'Lista não enviada',
  PAYMENT_STARTED: 'Compra iniciada',
  LIST_SOLD: 'Lista comprada',
  LIST_NOT_SOLD: 'Lista não comprada',
  LIST_REPLACED: 'Lista substituída',
  ORDER_FAILED: 'Erro de processamento',
};

export const actionButton = {
  EDIT: 'Editar',
  VIEW: 'Visualizar',
};

const stateMachine = {
  ORDER_CREATED: {
    actionButton: actionButton.EDIT,
    isSendable: true,
    triggerNewOrder: false,
    status: UiLabels.LIST_SAVED,
    color: '#ffb620',
  },
  ORDER_UPDATED: {
    actionButton: actionButton.EDIT,
    isSendable: true,
    triggerNewOrder: false,
    status: UiLabels.LIST_SAVED,
    color: '#ffb620',
  },
  ORDER_SHARED: {
    actionButton: actionButton.EDIT,
    isSendable: true,
    triggerNewOrder: false,
    status: UiLabels.LIST_SENT,
    color: '#6377E9',
  },
  ORDER_CLOSED: {
    actionButton: actionButton.VIEW,
    isSendable: false,
    triggerNewOrder: true,
    status: UiLabels.LIST_REPLACED,
    color: '#96969E',
  },
  PAYMENT_STARTED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.PAYMENT_STARTED,
    color: '#52A569',
  },
  PAYMENT_PROCESSING: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.PAYMENT_STARTED,
    color: '#52A569',
  },
  PAYMENT_FAILED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_NOT_SOLD,
    color: '#96969E',
  },
  PAYMENT_CONFIRMED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_SOLD,
    color: '#52A569',
  },
  PAYMENT_EXPIRED: {
    actionButton: actionButton.EDIT,
    triggerNewOrder: true,
    isSendable: true,
    status: UiLabels.LIST_NOT_SOLD,
    color: '#96969E',
  },
  PAYMENT_REFUNDED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_NOT_SOLD,
    color: '#96969E',
  },
  ORDER_PENDING_REVIEW: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_SOLD,
    color: '#52A569',
  },
  ORDER_CONFIRMED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_SOLD,
    color: '#52A569',
  },
  ORDER_READY_FOR_DELIVERY: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_SOLD,
    color: '#52A569',
  },
  ORDER_DELIVERED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_SOLD,
    color: '#52A569',
  },
  ORDER_CANCELLED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_NOT_SOLD,
    color: '#96969E',
  },
  ORDER_EXPIRED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_NOT_SOLD,
    color: '#96969E',
  },
  ORDER_FAILED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.ORDER_FAILED,
    color: '#A73737',
  },

  LIST_CREATED: {
    actionButton: actionButton.EDIT,
    triggerNewOrder: false,
    isSendable: true,
    status: UiLabels.LIST_SAVED,
    color: '#ffb620',
  },
  LIST_UPDATED: {
    actionButton: actionButton.EDIT,
    triggerNewOrder: false,
    isSendable: true,
    status: UiLabels.LIST_SAVED,
    color: '#ffb620',
  },
  LIST_SYNCED: {
    actionButton: actionButton.EDIT,
    triggerNewOrder: false,
    isSendable: false,
    status: UiLabels.LIST_SENT,
    color: '#6377E9',
  },
  LIST_NOT_SENT: {
    actionButton: actionButton.EDIT,
    triggerNewOrder: false,
    isSendable: true,
    status: UiLabels.LIST_NOT_SENT,
    color: '#A73737',
  },
  LIST_CLOSED: {
    actionButton: actionButton.VIEW,
    triggerNewOrder: true,
    isSendable: false,
    status: UiLabels.LIST_SOLD,
    color: '#52A569',
  },
};

export default stateMachine;
