import { Box, TextField, styled } from '@mui/material';
import BenvoButton from '../../../components/BenvoButton/style';

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 6px;
  padding: 10px 42px;
  width: 100%;
  height: 100%;
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  width: 100%;

  a {
    color: #4a397f;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.03px;
    text-decoration: none;
    align-self: flex-end;
  }
`;

export const StyledTexField = styled(TextField)`
  & .MuiOutlinedInput-root {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: #f5f5f7;
    & fieldset {
      border: none;
    }
    & input {
      color: #474750;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      -webkit-box-shadow: 0 0 0 1000px #f5f5f7 inset;
    }
  }
`;

export const StyledButton = styled(BenvoButton)`
  width: 106px;
  padding: 16px 0;
`;
