import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

function LoadingComponent() {
  return (
    <Box
      sx={{
        color: '#474750',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        height: '65vh',
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
}

export default LoadingComponent;
