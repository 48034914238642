import React from 'react';
import { Box } from '@mui/material';
import Menu from './components/menu';
import BenvoIcon from '../../assets/benvo-icon.png';
import { StyledAppBar, StyledToolbar } from './style';

function TopbarExternal({ doctorName }) {
  return (
    <StyledAppBar
      data-testid="topbar-external"
      position="static"
      color="default"
    >
      <StyledToolbar>
        <Box>
          <img src={BenvoIcon} alt="benvo-logo" width={40} />
        </Box>
        <Box>
          <Menu doctorName={doctorName} />
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default TopbarExternal;
