import React from 'react';
import { Typography, AccordionSummary, AccordionDetails } from '@mui/material';
import { ReactComponent as Arrow } from '../../../../../assets/healthService/vuesax-linear-arrow-down.svg';
import { AccordionContainer, AccordionTitle, StyledAccordion } from './style';

export default function BenvoAccordion({ accordionItems, activeStep, setActiveStep }) {
  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setActiveStep(panel);
    } else {
      setActiveStep(-1);
    }
  };

  return (
    <AccordionContainer>
      {accordionItems.map((item, index) => (
        <StyledAccordion key={item.label} expanded={index === activeStep} onChange={handleChange(index)}>
          <AccordionSummary expandIcon={<Arrow />} id={`panel${index}bh-header`}>
            <AccordionTitle
              sx={{
                width: '53%',
                flexShrink: 0,
                display: 'flex',
                gap: '10px',
              }}
            >
              {item.label}
              {item.subtitle && <Typography sx={{ fontSize: '12px', color: '#474750' }}>{item.subtitle}</Typography>}
            </AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.children}</Typography>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </AccordionContainer>
  );
}
