import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import MailBox from '../../assets/sendEmail/mailBox';
import {
  ButtonContainer, Container, InputContainer, StyledButton, StyledForm, StyledTexField, TextContainer,
} from './style';
import { hiddenEmailMask } from '../../utils/inputMasks';
import routes from '../../services/routes';
import { useAlert } from '../../context/alertContext';

export default function SendEmail() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const alert = useAlert();
  const { publicRoutes } = routes;

  const email = searchParams.get('email');
  const hiddenEmail = hiddenEmailMask(email);

  const handleBack = () => {
    navigate(-1);
  };

  const handlePasswordRecovery = async () => {
    setIsSubmitting(true);
    try {
      await publicRoutes.requestNewPassword({
        email,
      });
      alert.success('Reenviamos o link para seu e-mail');
    } catch (error) {
      alert.error('Erro ao reenviar o link de recuperação de senha. Tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <MailBox />
      <TextContainer>
        <h4>Acesse seu e-mail</h4>
        <p>Enviamos um e-mail com o link para recuperar a senha no endereço de e-mail cadastrado.</p>
      </TextContainer>
      <StyledForm>
        <InputContainer>
          <p>E-mail enviado para:</p>
          <StyledTexField
            name="email"
            type="email"
            size="small"
            disabled
            value={hiddenEmail}
            fullWidth
          />
        </InputContainer>
        <ButtonContainer>
          <StyledButton
            disabled={isSubmitting}
            onClick={handleBack}
            variant="secondary"
            type="button"
          >
            Alterar
          </StyledButton>
          <StyledButton
            disabled={isSubmitting}
            onClick={handlePasswordRecovery}
            type="button"
          >
            Reenviar
          </StyledButton>
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
}
