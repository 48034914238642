import React, { cloneElement, useEffect, useState } from 'react';
import { Collapse, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import BenvoDrawer from '../../../../components/BenvoDrawer';
import {
  AccordionContent,
  FormsContainer,
  StyledCollapse,
  StyledIconButton,
} from './style';

import ResidentForm from '../ResidentForm';
import ReferenceContactForm from '../ReferenceContactForm';
import BenvoAccordion from '../../../../components/BenvoAccordion';
import ReferenceContactList from '../ReferenceContactList';
import routes from '../../../../services/routes';
import Add from '../../../../assets/residents/Add';
import BenvoTooltip from '../../../../components/BenvoTooltip';
import { getTokenInfo } from '../../../../utils/configAuth';

const TriggerDrawer = ({ children, ...props }) => {
  const child = React.Children.only(children);
  const childWithProps = cloneElement(child, { ...props });
  return childWithProps;
};

export default function CreateOrEditResident({
  refetchResidentList,
  residentInformationToEdit = [],
  children,
}) {
  const [residentInformation, setResidentInformation] = useState({});
  const [createANewReferenceContact, setCreateANewReferenceContact] = useState(false);
  const [editReferenceContact, setEditReferenceContact] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [query, setQuery] = useSearchParams();

  const { resident } = routes;

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const getReferenceContactList = async () => {
    try {
      const { message } = await resident.getResidentContact({
        residentId: residentInformation.id ?? residentInformationToEdit.id,
      });
      return message;
    } catch (error) {
      return error;
    }
  };

  const {
    data: referenceList = [],
    isFetching: isFetchingReferenceList,
    remove: removeReferenceList,
  } = useQuery({
    queryKey: ['referenceContactList'],
    queryFn: getReferenceContactList,
    refetchOnWindowFocus: false,
    enabled:
      (Boolean(residentInformation.id)
        || Boolean(residentInformationToEdit.id))
      && isDrawerOpen,
  });

  const sortedReferenceListByName = referenceList.sort((a, b) => a.name.localeCompare(b.name));

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setResidentInformation({});
    setCreateANewReferenceContact(false);
    removeReferenceList();
    setEditReferenceContact(false);
    setQuery({});
  };

  const getAllReferenceContactsByIlpi = async () => {
    const ilpiId = getTokenInfo('ilpiId');
    try {
      const { message } = await resident.getReferenceContactsByIlpi({ ilpiId });
      return message;
    } catch (error) {
      return error;
    }
  };

  const {
    data: allReferenceContactsByIlpi = [],
    isFetching: isFetchingReferenceContacts,
  } = useQuery({
    queryKey: ['referenceContacts'],
    queryFn: getAllReferenceContactsByIlpi,
    refetchOnWindowFocus: false,
    enabled:
      (Boolean(residentInformation.id)
        || Boolean(residentInformationToEdit.id))
      && isDrawerOpen,
  });

  const handleCreateANewReferenceContact = () => setCreateANewReferenceContact(true);

  useEffect(() => {
    const residentId = query.get('residentId');
    if (residentId && residentId === residentInformationToEdit.id) {
      handleDrawerOpen();
    }
  }, [query.get('residentId')]);

  return (
    <>
      <TriggerDrawer onClick={handleDrawerOpen}>{children}</TriggerDrawer>
      <BenvoDrawer
        open={isDrawerOpen}
        justifyContent="flex-start"
        onClose={handleDrawerClose}
        title={
          residentInformationToEdit.id ? 'Editar residente' : 'Novo Residente'
        }
      >
        <FormsContainer>
          <BenvoAccordion
            content={[
              {
                defaultExpanded: true,
                title: 'Dados do residente',
                children: (
                  <ResidentForm
                    refetchResidentList={refetchResidentList}
                    isDrawerOpen={isDrawerOpen}
                    setResidentInformation={setResidentInformation}
                    residentInformation={residentInformation}
                    removeReferenceList={removeReferenceList}
                    residentInformationToEdit={residentInformationToEdit}
                    handleDrawerClose={handleDrawerClose}
                  />
                ),
              },
              {
                defaultExpanded: true,
                title: 'Contato de referência',
                children: (
                  <AccordionContent>
                    {referenceList.length === 0 && (
                      <ReferenceContactForm
                        allReferenceContactsByIlpi={allReferenceContactsByIlpi}
                        residentInformation={residentInformation}
                        setCreateANewReferenceContact={
                          setCreateANewReferenceContact
                        }
                        createANewReferenceContact={createANewReferenceContact}
                        referenceList={referenceList}
                        residentInformationToEdit={residentInformationToEdit}
                      />
                    )}

                    {isFetchingReferenceList || isFetchingReferenceContacts ? (
                      <Skeleton variant="rounded" height={100} />
                    ) : (
                      <Collapse in={referenceList.length > 0}>
                        {sortedReferenceListByName?.map((contact) => (
                          <ReferenceContactList
                            key={contact.id}
                            contact={contact}
                            residentInformation={residentInformation}
                            editReferenceContact={editReferenceContact}
                            setEditReferenceContact={setEditReferenceContact}
                            residentInformationToEdit={
                              residentInformationToEdit
                            }
                            referenceList={sortedReferenceListByName}
                          />
                        ))}
                      </Collapse>
                    )}

                    <StyledCollapse
                      in={
                        referenceList.length > 0
                        && !createANewReferenceContact
                        && !editReferenceContact
                      }
                    >
                      <BenvoTooltip
                        title="Adicionar contato"
                        arrow
                        placement="top"
                      >
                        <StyledIconButton
                          onClick={handleCreateANewReferenceContact}
                        >
                          <Add />
                        </StyledIconButton>
                      </BenvoTooltip>
                    </StyledCollapse>
                    <Collapse in={createANewReferenceContact}>
                      <ReferenceContactForm
                        allReferenceContactsByIlpi={allReferenceContactsByIlpi}
                        residentInformation={residentInformation}
                        setCreateANewReferenceContact={
                          setCreateANewReferenceContact
                        }
                        createANewReferenceContact={createANewReferenceContact}
                        referenceList={referenceList}
                        residentInformationToEdit={residentInformationToEdit}
                      />
                    </Collapse>
                  </AccordionContent>
                ),
              },
            ]}
          />
        </FormsContainer>
      </BenvoDrawer>
    </>
  );
}
