import {
  Box, Dialog, IconButton, Typography, styled,
} from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 16px;
  border-radius: 80px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  &:disabled{
    background: #EAEBEC;
    pointer-events: all;

    &:hover{
      box-shadow: none;
    }

    img{
      filter: grayscale(60%);
    }
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 22px;
    display: flex;
    border-radius: 6px;
    background: #fff;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  }

  .MuiBackdrop-root {
    background-color: #0000001a;
    backdrop-filter: blur(1px);
  }
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  border-bottom: 1px solid #f5f6f8;
  padding-bottom: 14px;
`;

export const Title = styled(Typography)`
  display: flex;
  width: 299px;
  flex-direction: column;
  color: var(--main-and-gray-half-dark-bluegray, #435565);
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: 0.024px;
`;

export const Description = styled(Typography)`
  display: flex;
  width: 299px;
  flex-direction: column;
  color: var(--main-and-gray-medium-bluegray, #848f99);
  text-align: center;
  font-size: 14px;
  font-family: Poppins;
  line-height: 140%;
  letter-spacing: 0.035px;
`;
