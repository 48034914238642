const Joi = require('joi');

const createAccommodation = Joi.object({
  name: Joi.string().min(1).required().messages({
    'string.min': 'O campo "Quarto" deve ter no mínimo 1 caractere',
    'string.empty': 'O campo "Quarto" não pode estar vazio',
  }),
  wing: Joi.string().valid('Feminino', 'Masculino', 'N/A').required().messages({
    'any.only': 'O campo "Ala" não pode estar vazio',
  }),
  floor: Joi.number().min(0).required().messages({
    'number.base': 'O campo "Andar" deve ser um número',
    'number.min': 'O campo "Andar" deve ser maior ou igual a 0',
  }),
  meters: Joi.number().min(1).required().messages({
    'number.base': 'O campo "Metros" deve ser um número',
    'number.min': 'O campo "Metros" deve ser maior ou igual a 1',
  }),
  hasBed: Joi.number().min(1).required().messages({
    'number.base': 'O campo "Camas" deve ser um número',
    'number.min': 'O campo "Camas" deve ser maior ou igual a 1',
  }),
  hasBathroom: Joi.number().min(0).required().messages({
    'number.base': 'O campo "Banheiros" deve ser um número',
    'number.min': 'O campo "Banheiros" deve ser maior ou igual a 0',
  }),
  cabinet: Joi.number().min(0).required().messages({
    'number.base': 'O campo "Armários" deve ser um número',
    'number.min': 'O campo "Armários" deve ser maior ou igual a 0',
  }),
  observation: Joi.string().allow(''),
});

const updateAccommodation = Joi.object({
  name: Joi.string().min(1).messages({
    'string.min': 'O campo "Quarto" deve ter no mínimo 1 caractere',
    'string.empty': 'O campo "Quarto" não pode estar vazio',
  }),
  wing: Joi.string().valid('Feminino', 'Masculino', 'N/A').messages({
    'any.only': 'O campo "Ala" não pode estar vazio',
  }),
  floor: Joi.number().min(0).messages({
    'number.base': 'O campo "Andar" deve ser um número',
    'number.min': 'O campo "Andar" deve ser maior ou igual a 0',
  }),
  meters: Joi.number().min(1).messages({
    'number.base': 'O campo "Metros" deve ser um número',
    'number.min': 'O campo "Metros" deve ser maior ou igual a 1',
  }),
  hasBed: Joi.number().min(1).messages({
    'number.base': 'O campo "Camas" deve ser um número',
    'number.min': 'O campo "Camas" deve ser maior ou igual a 1',
  }),
  hasBathroom: Joi.number().min(0).messages({
    'number.base': 'O campo "Banheiros" deve ser um número',
    'number.min': 'O campo "Banheiros" deve ser maior ou igual a 0',
  }),
  cabinet: Joi.number().min(0).messages({
    'number.base': 'O campo "Armários" deve ser um número',
    'number.min': 'O campo "Armários" deve ser maior ou igual a 0',
  }),
  isActive: Joi.number().valid(0, 1),
  observation: Joi.string().allow(''),
});

export default {
  createAccommodation,
  updateAccommodation,
};
