import React from 'react';
import FLVTab from './FLVTab';
import MedicationTab from './MedicationTab';
import ProductTab from './ProductTab';
import MarketItemsTab from './MarketItemsTab';

function ClassTabs({ type, formValues, setFormValues }) {
  const handleChange = ({ value, name }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormItems = ({ label, value }) => {
    const newItem = {
      item: label,
      type,
    };

    if (value) {
      newItem.id = value;
    }

    setFormValues((prev) => ({ ...prev, ...newItem }));
  };

  switch (type) {
    case 'medication':
      return (
        <MedicationTab
          formValues={formValues}
          handleChange={handleChange}
          handleFormItems={handleFormItems}
        />
      );
    case 'flv':
      return (
        <FLVTab
          formValues={formValues}
          handleChange={handleChange}
          handleFormItems={handleFormItems}
        />
      );
    case 'market':
      return (
        <MarketItemsTab
          formValues={formValues}
          handleChange={handleChange}
          handleFormItems={handleFormItems}
        />
      );
    default:
      return (
        <ProductTab
          formValues={formValues}
          handleChange={handleChange}
          handleFormItems={handleFormItems}
        />
      );
  }
}

export default ClassTabs;
