import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';

export const StyledDialog = styled(Dialog)`
  img {
    width: 205px;
    height: auto;
    margin: 0 auto 24px
  }

  .MuiDialog-paper {
    padding: 24px 48px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding:0;
  color: #435565;
  text-align: center;

  font-size: 16px;
  font-weight: 500;
  line-height: 115%; /* 18.4px */
  letter-spacing: 0.024px;
`;

export const StyledDialogContent = styled(DialogContent)`
  margin: 4px 0 24px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;

  p {
    color: #96969e;
    text-align: center;
    margin: 0;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    letter-spacing: 0.12px;
    max-width: 287px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border-radius: 8px;
    overflow: auto;
    width: 100%;
    border: 1px solid  #F5F5F7;
    max-height: 200px;


    li {
      color: #474750;

      font-size: 10px;
      font-weight: 400;
      line-height: 140%; /* 14px */
      letter-spacing: 0.04px;
      padding: 12px;
    }

    li:nth-of-type(odd) {
      background-color: #f5f5f7;
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0;
  display: flex;
  justify-content: center;
`;
