import React, { useEffect, useState } from 'react';
import { Radio, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import {
  CardInfoBox, CollapseBox, CreditCardActions, CreditCardBox, RadioAndCardInfoBox,
} from '../style';
import BenvoDeleteModal from '../../../../../../components/BenvoDeleteModal';

export default function CreditCardControl({
  creditCard, selectedCard, selectCard, deleteCard,
}) {
  const [grow, setGrow] = useState(false);
  const [checked, setChecked] = useState(false);

  const checker = () => {
    if (!selectedCard) setChecked(false);
    else setChecked(selectedCard.token === creditCard.token);
  };

  useEffect(() => {
    checker();
  }, [selectedCard]);

  return (
    <CreditCardBox>
      <RadioAndCardInfoBox onClick={() => selectCard(creditCard)}>
        <Radio checked={checked} />
        <CardInfoBox>
          <Typography>Crédito</Typography>
          <Typography>
            {`•••• ${creditCard.maskedNumber.slice(-4)}`}
          </Typography>
        </CardInfoBox>
      </RadioAndCardInfoBox>
      <CreditCardActions>
        <MoreVert
          sx={{ color: '#D4D4DA' }}
          onClick={() => {
            setGrow((prev) => !prev);
          }}
        />
        <CollapseBox in={grow} collapsedSize={0} orientation="horizontal">
          <BenvoDeleteModal
            testid={`delete-${creditCard.id}`}
            title="Remover cartão"
            description="Deseja remover o cartão?"
            tooltipTitle="Remover cartão"
            handleDelete={() => deleteCard(creditCard)}
            iconStyle={{
              width: '34px',
              height: '34px',
            }}
          />
        </CollapseBox>
      </CreditCardActions>
    </CreditCardBox>
  );
}
