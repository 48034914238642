/* eslint-disable import/prefer-default-export */
import { Box, styled } from '@mui/material';

export const PageContainer = styled(Box)`
  background-color: #F5F5F7;
  height: 100dvh;
  max-height: 100dvh;
  padding-inline: 200px;
  padding-block: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @media (max-width: 1440px) {
    padding-inline: 128px;
  }

  @media (max-width: 1280px) {
    padding-inline: 96px;
  }

  @media (max-width: 900px) {
    padding-inline: 48px;
  }

  @media (max-width: 600px) {
    padding-inline: 24px;
  }

  iframe{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 6px;
  }
`;
