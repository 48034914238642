const statusColor = (status) => {
  let convertedStatus = '';
  switch (status) {
    case 'sent':
      convertedStatus = '#000';
      break;
    case 'notSent':
      convertedStatus = '#b5a900';
      break;
    case 'approved':
      convertedStatus = '#15bab1';
      break;
    case 'unapproved':
      convertedStatus = '#d72800';
      break;
    case 'signed':
      convertedStatus = '#7A5DEF';
      break;
    default:
      break;
  }
  return convertedStatus;
};

export default statusColor;
