import React, { useState } from 'react';
import moment from 'moment';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton,
} from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import { getTokenInfo } from '../../../../utils/configAuth';
import Loading from '../../../../components/Loading/Loading';
import routes from '../../../../services/routes';
import logoAfarma from '../../../../assets/benvo-icon.png';

function GeneratePDF({ thisRecipe }) {
  const {
    recipeId, doctorId, name, status,
  } = thisRecipe;
  const { doctor, recipe, ilpi } = routes;
  const ilpiId = getTokenInfo('ilpiId');
  const [open, setOpen] = useState(false);
  const [doctorInfo, setDoctorInfo] = useState({});
  const [recipeInfo, setRecipeInfo] = useState({});
  const [ilpiData, setIlpiData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchInfo = async () => {
    try {
      const { message: doctorInfoFromAPi } = await doctor.getDoctorById({ doctorId, ilpiId });
      setDoctorInfo(doctorInfoFromAPi);
      const { message } = await recipe.getRecipeById({ recipeId });
      setRecipeInfo(message);
      const { message: ilpiDataFromApi } = await ilpi.getIlpiById({ ilpiId });
      setIlpiData(ilpiDataFromApi);
    } catch (error) {
      console.log('Recipe wtf', error);
    } finally {
      setIsLoading(false);
    }
  };

  const openPDFModal = () => {
    fetchInfo();
    setOpen(true);
  };

  const closePDFModal = () => {
    setOpen(false);
  };

  const ref = React.createRef();

  const generateRecipeItems = () => {
    if (!recipeInfo.recipeItems) return '';

    return recipeInfo.recipeItems.map((item) => (
      <Typography sx={{ margin: '20px 0' }}>
        -
        <Typography variant="span" fontWeight={600} align="justify">{item.substance}</Typography>
        {`, ${item.dosage} -- Fazer ${item.quantity} ${item.unit}, ${item.timesPerDay}x ao dia`}
        <Typography align="justify">{item.observation}</Typography>
      </Typography>
    ));
  };

  return (
    <>
      <IconButton
        color="primary"
        style={{ backgroundColor: 'transparent' }}
        onClick={openPDFModal}
        disabled={status !== 'approved'}
      >
        <PictureAsPdf />
      </IconButton>

      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={closePDFModal}
      >
        {isLoading ? (
          <Box sx={{
            display: 'flex',
            flexFlow: 'column nowrap',
            alignContent: 'center',
            justifyContent: 'center',
            minHeight: '200px',
          }}
          >
            <Loading />
          </Box>
        ) : (
          <div ref={ref}>
            <DialogTitle>
              <Box className="img_login" align="center">
                <img src={logoAfarma} alt="logo afarma" width="150" />
              </Box>
              <Typography align="center" fontWeight={700}>{`Dr(a). ${doctorInfo.name}`}</Typography>
              <Typography align="center" fontWeight={600}>{`CRM ${doctorInfo.crm}`}</Typography>
              <Typography align="center" fontWeight={600}>{`Tel.: ${doctorInfo.phoneNumber}`}</Typography>
              <Typography align="center" fontWeight={600}>
                {recipeInfo.recipeType === 'REGULAR' ? 'Formulário Receita Branca' : 'Formulário Receita Especial'}
              </Typography>
            </DialogTitle>

            <DialogContent>
              <Typography variant="body">
                {`${ilpiData.city}/${ilpiData.state}, ${moment().format('DD/MM/yyyy')}.`}
              </Typography>
              <Typography variant="h5" align="center" sx={{ marginTop: '20px' }}>
                {`Receita de ${name}`}
              </Typography>

              {generateRecipeItems()}

              <Typography>____________________</Typography>
              <Typography>CARIMBO COM CRM</Typography>
            </DialogContent>
          </div>
        )}

        <DialogActions>
          <Button onClick={closePDFModal} color="cancel">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GeneratePDF;
