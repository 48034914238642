import React from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';
import {
  CardsContainer, Container,
} from './style';
import CreateListCard from './components/CreateListCard';
import ShareTheList from './components/ShareTheList';
import OrderHistoryCard from './components/OrderHistoryCard';
import routes from '../../services/routes';

const { resident, shoppingList } = routes;

export default function OrderHistory() {
  const path = useLocation().pathname;
  const residentId = path.split('/')[2];
  const getResidentInformations = async () => {
    try {
      const { message } = await resident.getResidentById({ residentId });
      return message;
    } catch (error) {
      return error;
    }
  };

  const { data: residentInformations, isLoading } = useQuery({
    queryKey: ['residentInformations', residentId],
    queryFn: getResidentInformations,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const getOrderHistory = async () => {
    try {
      const { message: { rows } } = await shoppingList.getAllByResidentId({ residentId });
      return rows;
    } catch (error) {
      return [];
    }
  };

  const {
    data: orderHistory = [],
    isLoading: isLoadingOrderHistory,
    isFetching: isOrderHistoryFetching,
  } = useQuery({
    queryKey: ['orderHistory'],
    queryFn: getOrderHistory,
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  return (
    <Container>
      {isLoading || isOrderHistoryFetching ? (
        <Skeleton variant="rounded" width="1299px" height="100%" />
      ) : (
        <OrderHistoryCard residentInformations={residentInformations} />
      )}
      <CardsContainer>
        {isLoadingOrderHistory ? (
          <Skeleton variant="rounded" width="398px" height="95.8px" />
        ) : (
          <CreateListCard orderHistory={orderHistory} />
        )}
        {isLoading ? (
          <Skeleton variant="rounded" width="398px" height="69.6px" />
        ) : (
          <ShareTheList residentInformations={residentInformations} />
        )}
      </CardsContainer>

    </Container>
  );
}
