import {
  Box, FormControlLabel, FormHelperText, Switch, Typography, styled,
} from '@mui/material';

export const Title = styled(Typography)`
    color: var(--grey-variation-dark-grey, #474750);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%; /* 18.4px */
    letter-spacing: 0.024px;
`;

export const StyledForm = styled('form')`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

export const ButtonsContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;
`;

export const StyledFormHelper = styled(FormHelperText)`
    margin-left: 0;
    margin-right: 0;
`;

export const StyledSwitch = styled(Switch)`
    &.MuiSwitch-root{
        width: 36px;
        height: 20px;
        border-radius: 12px;
        border: 1px solid #2D2152;
        
        padding: 0;
        
        .MuiSwitch-switchBase{
            padding: 0;
            color: var(--grey-variation-dark-grey, white);
            top: 1px;
            left: 2px;

            .MuiSwitch-thumb {
                width: 16px;
                height: 16px;
            }
        }
        .MuiSwitch-track{
            background-color: var(--grey-variation-light-grey, #EDEDED);
        }
        :has(.Mui-checked){
            background-color: var(--purple-variation-purple, #4A397F);

            .MuiSwitch-track{
                background-color: var(--purple-variation-purple, #4A397F);
                opacity: 1;
            }
            .MuiSwitch-switchBase{
            padding: 0;
            color: var(--grey-variation-dark-grey, white);
            transform: translateX(14px);
        }
        }
    }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
    &.MuiFormControlLabel-root{
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0;
        .MuiTypography-root{
            color: var(--grey-variation-dark-grey, #474750);

            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%; /* 20.4px */
            letter-spacing: 0.12px;
        }
    }
`;
