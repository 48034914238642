import React, { useRef } from 'react';
import { Typography } from '@mui/material';
import { ProductItemContainer, StyledIconButton } from './style';
import AddSquare from '../../../../../../../../assets/residents/AddSquare';

export default function ProductSearchResultItem({ product, setSelectedProductsList, selectedProductsList }) {
  const initialRawQuantityRef = useRef(product.defaultRawQuantity || product.rawQuantity);
  const handleAddProductToShoppingList = () => {
    const isProductAlreadySelected = selectedProductsList
      .find((selectedProduct) => selectedProduct.externalId === product.id);

    if (isProductAlreadySelected) {
      setSelectedProductsList((prevProductsList) => prevProductsList.map((selectedProduct) => {
        if (selectedProduct.externalId === product.id) {
          const newQuantity = selectedProduct.quantity + 1;
          const newRawQuantity = initialRawQuantityRef.current
            ? newQuantity * initialRawQuantityRef.current
            : null;
          return {
            ...selectedProduct,
            quantity: newQuantity,
            rawQuantity: newRawQuantity,
            manuallyEntered: false,
          };
        }
        return selectedProduct;
      }));
    } else {
      setSelectedProductsList((prevProductsList) => [...prevProductsList, {
        name: product.displayName,
        quantity: 1,
        externalId: product.id,
        isAvailable: product.isAvailable,
        displayNameType: product.displayNameType,
        rawQuantity: product.rawQuantity,
        defaultRawQuantity: product.rawQuantity,
      }]);
    }
  };

  return (
    <ProductItemContainer>
      <Typography variant="body1">
        {product.displayName}
      </Typography>
      <StyledIconButton onClick={handleAddProductToShoppingList}>
        <AddSquare />
      </StyledIconButton>
    </ProductItemContainer>
  );
}
