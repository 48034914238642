import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  StyledBoxButtonsContainer, StyledBoxContainer, StyledBoxFormContainer, StyledDivider,
} from './style';
import schemas from '../../../../schemas';
import BenvoPrimaryButton from '../../../../components/BenvoPrimaryButton';
import BenvoSecondaryButton from '../../../../components/BenvoSecondaryButton';
import routes from '../../../../services/routes';
import { removeMask } from '../../../../utils/inputMasks';
import { useAlert } from '../../../../context/alertContext';
import IlpiInfoForm from './IlpiInfoForm';
import IlpiAdressForm from './IlpiAdressForm';
import IlpiContactForm from './IlpiContactForm';
import IlpiFeaturesForm from './IlpiFeaturesForm';

export default function CreateIlpi({
  onClose,
  defaultValues,
}) {
  const alert = useAlert();

  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    clearErrors,
    control,
  } = useForm({
    defaultValues: {
      ...defaultValues,
      features: [],
    },
    resolver: joiResolver(schemas.ilpi.createIlpi),
  });

  const createIlpi = async ({ confirmPassword, ...data }) => {
    const payload = {
      ...data,
      phoneNumber: removeMask(data.phoneNumber),
      landline: removeMask(data.landline),
      zipCode: removeMask(data.zipCode),
      cnpj: removeMask(data.cnpj),
    };

    try {
      await routes.ilpi.createIlpi(payload);
      reset();
      onClose();
      alert.success('ILPI cadastrada com sucesso!');
    } catch (error) {
      alert.error('Erro ao cadastrar ILPI');
    }
  };

  useEffect(() => {
    const hasError = Object.keys(errors).length > 0;

    if (hasError) {
      alert.error('Erro ao cadastrar ILPI. Por favor, verifique os campos preenchidos.');
    }
  }, [errors]);

  return (
    <StyledBoxContainer>
      <StyledBoxFormContainer>
        <IlpiInfoForm
          watch={watch}
          register={register}
          setValue={setValue}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
        />
        <StyledDivider />

        <IlpiAdressForm
          watch={watch}
          register={register}
          setValue={setValue}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
        />
        <StyledDivider />

        <IlpiContactForm
          watch={watch}
          register={register}
          setValue={setValue}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          control={control}
        />
        <StyledDivider />

        <IlpiFeaturesForm
          watch={watch}
          register={register}
          setValue={setValue}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
        />
      </StyledBoxFormContainer>

      <StyledBoxButtonsContainer>
        <BenvoSecondaryButton
          onClick={onClose}
        >
          Cancelar
        </BenvoSecondaryButton>

        <BenvoPrimaryButton
          onClick={handleSubmit(createIlpi)}
        >
          Adicionar
        </BenvoPrimaryButton>
      </StyledBoxButtonsContainer>

    </StyledBoxContainer>
  );
}
