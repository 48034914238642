import {
  Autocomplete, Grid, Stack, TextField,
} from '@mui/material';
import React, { useState } from 'react';
import routes from '../../../../../services/routes';
import { useAlert } from '../../../../../context/alertContext';

const initialFormValues = {
  measure: 'und',
  quantity: '',
  item: '',
};

function MedicationTab({ formValues, handleFormItems, handleChange }) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [medicationSuggestions, setMedicationSuggestions] = useState([]);
  const [option, setOption] = useState('');

  const getMedications = async (name) => {
    setIsLoading(true);

    try {
      const { message } = await routes.medicine.findBySubstanceOrName({ name });
      const suggestions = message.rows.map((medicine) => ({
        label: `${medicine.name} - ${medicine.substance} ${medicine.dosage}`,
        value: medicine.id,
      }));

      setMedicationSuggestions(suggestions);
    } catch {
      alert.error('Medicamento não encontrado');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDebounce = (value) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const timeout = setTimeout(async () => {
      if (value.length >= 4) {
        getMedications(value);
      }
    }, 400);

    setTimeoutId(timeout);
  };

  const handleMedicationChange = (event) => {
    if (event && event.target) {
      const value = event.target.value || '';

      setOption(value);
      handleDebounce(value);
    }
  };

  const handleOptions = () => {
    if (option.length > 1 && option.length < 4) {
      return 'A busca precisa ter ao menos 4 caracteres';
    } return 'Digite para buscar um medicamento';
  };

  return (
    <>
      <Grid item xs={12} md={6} lg={4.5}>
        <Autocomplete
          name="item"
          options={medicationSuggestions}
          noOptionsText={handleOptions()}
          value={formValues.item}
          loading={isLoading}
          isOptionEqualToValue={(thisOption, value) => thisOption.label === value}
          onChange={(_e, item) => handleFormItems(item || initialFormValues)}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Buscar medicamento" />
          )}
          onInputChange={handleMedicationChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={12} lg={4.5}>
        <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }}>
          <TextField
            id="quantity"
            name="quantity"
            type="number"
            label="Quantidade"
            value={formValues.quantity}
            sx={{ width: '100%' }}
            onChange={({ target }) => handleChange(target)}
            size="small"
          />
        </Stack>
      </Grid>
    </>
  );
}

export default MedicationTab;
