/* eslint-disable no-unused-vars */
import { joiResolver } from '@hookform/resolvers/joi';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Divider,
  FormControl,
  Grid, MenuItem,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import schemas from '../../../../../../schemas';
import BenvoTextField from '../../../../../../components/BenvoField/style';
import BenvoSelectField from '../../../../../../components/BenvoSelectField';
import { ufOptions } from '../../../../../../constants';
import BenvoButton from '../../../../../../components/BenvoButton/style';
import {
  cepInputMask, cpfInputMask, phoneInputMask, removeMask,
} from '../../../../../../utils/inputMasks';
import { get } from '../../../../../../services/ApiRequest';
import {
  ButtonsContainer, StyledForm, StyledFormControlLabel, StyledFormHelper, StyledSwitch, Title,
} from './style';
import { useAlert } from '../../../../../../context/alertContext';
import routes from '../../../../../../services/routes';
import { getTokenInfo } from '../../../../../../utils/configAuth';

const accessPermissions = [
  { value: 'user', label: 'Usuário', dataTestId: 'user' },
  { value: 'admin', label: 'Administrador', dataTestId: 'admin' },
];

const { collaborator } = routes;

export default function Employee({
  handleCloseDrawer, selectedType, getUsersAndCollaborators, collaboratorData,
}) {
  const [isEditingEmployeeInProgress, setIsEditingEmployeeInProgress] = useState(false);
  const alert = useAlert();
  const {
    handleSubmit, reset, register, watch, formState: { errors }, setValue,
  } = useForm({
    resolver: joiResolver(schemas.employee.createEmployee),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const editEmployee = async (data) => {
    setIsEditingEmployeeInProgress(true);
    const ilpiId = getTokenInfo('ilpiId');
    const employeeData = {
      ...data,
      type: selectedType,
      cpf: removeMask(data.cpf),
      phoneNumber: removeMask(data.phoneNumber),
      zipCode: removeMask(data.zipCode),
      ilpiId,
    };
    if (watch('createUser') === false) delete employeeData.privilege;
    try {
      await collaborator.editCollaborator({ collaboratorId: collaboratorData.id }, employeeData);
      alert.success('Funcionário editado com sucesso');
      getUsersAndCollaborators();
      handleCloseDrawer();
    } catch (error) {
      const { data: errorData, status } = error.response;
      if (status === 409) {
        alert.error(errorData.message);
      } else {
        alert.error('Erro ao editar Funcionário');
      }
    } finally {
      setIsEditingEmployeeInProgress(false);
    }
  };

  const fetchCEPData = async (cep) => {
    const cleanCep = removeMask(cep);
    const url = `https://viacep.com.br/ws/${cleanCep}/json/`;
    try {
      const data = await get(url);
      if (data.erro) throw new Error();
      return {
        state: setValue('state', data.uf ?? ''),
        city: setValue('city', data.localidade ?? ''),
        district: setValue('district', data.bairro ?? ''),
        street: setValue('street', data.logradouro ?? ''),
        complement: setValue('complement', data.complemento ?? ''),
      };
    } catch (error) {
      alert.error('CEP não encontrado');

      return {
        state: setValue('state', ''),
        city: setValue('city', ''),
        district: setValue('district', ''),
        street: setValue('street', ''),
        complement: setValue('complement', ''),
      };
    }
  };

  useQuery({
    queryKey: ['zipCode', watch('zipCode')],
    queryFn: () => fetchCEPData(watch('zipCode')),
    enabled: watch('zipCode')?.length === 9,
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (collaboratorData) {
      setValue('name', collaboratorData.name);
      setValue('cpf', cpfInputMask(collaboratorData.cpf));
      setValue('phoneNumber', phoneInputMask(collaboratorData.phoneNumber));
      setValue('email', collaboratorData.email);
      setValue('zipCode', cepInputMask(collaboratorData.zipCode));
      setValue('street', collaboratorData.street);
      setValue('number', collaboratorData.number);
      setValue('state', collaboratorData.state);
      setValue('city', collaboratorData.city);
      setValue('district', collaboratorData.district);
      setValue('complement', collaboratorData.complement);
      setValue('reference', collaboratorData.reference);

      if (collaboratorData.userId) {
        setValue('createUser', true);
        setValue('privilege', collaboratorData.privilege);
      }
    }
  }, [collaboratorData]);

  return (
    <StyledForm onSubmit={handleSubmit(editEmployee)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title variant="h6">Dados</Title>
        </Grid>
        <Grid item xs={8}>
          <BenvoTextField
            placeholder="Nome"
            fullWidth
            {...register('name')}
            error={!!errors.name}
            helperText={errors.name?.message}
            value={watch('name') ?? ''}
          />
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-start" alignItems="center">
          <StyledFormControlLabel
            control={(
              <StyledSwitch
                checked={watch('createUser') || false}
                {...register('createUser')}
              />
            )}
            label="Criar usuário"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BenvoTextField
            placeholder="CPF"
            fullWidth
            {...register('cpf')}
            error={!!errors.cpf}
            helperText={errors.cpf?.message}
            value={cpfInputMask(watch('cpf')) ?? ''}
            inputProps={{ maxLength: 14 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BenvoTextField
            placeholder="Telefone"
            fullWidth
            {...register('phoneNumber')}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
            value={phoneInputMask(watch('phoneNumber')) ?? ''}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>
        <Grid item xs={12}>
          <BenvoTextField
            placeholder="E-mail"
            fullWidth
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            value={watch('email') ?? ''}
          />
        </Grid>
        {watch('createUser') && (
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.privilege}>
            <BenvoSelectField
              fullWidth
              displayEmpty
              {...register('privilege')}
              error={!!errors.privilege}
              value={watch('privilege') ?? ''}
            >
              <MenuItem value="" disabled>
                Permissão de acesso
              </MenuItem>
              {accessPermissions.map((accessPermission) => (
                <MenuItem
                  key={accessPermission.value}
                  value={accessPermission.value}
                  data-testid={accessPermission.dataTestId}
                >
                  {accessPermission.label}
                </MenuItem>
              ))}
            </BenvoSelectField>
            {!!errors.privilege && (
            <StyledFormHelper>{errors.privilege?.message}</StyledFormHelper>
            )}
          </FormControl>
        </Grid>
        )}

        <Grid item xs={12}>
          <Divider orientation="horizontal" flexItem />
        </Grid>

        <Grid item xs={12}>
          <Title variant="h6">Endereço</Title>
        </Grid>
        <Grid item xs={12} sm={5}>
          <BenvoTextField
            placeholder="CEP"
            fullWidth
            {...register('zipCode')}
            error={!!errors.zipCode}
            helperText={errors.zipCode?.message}
            value={cepInputMask(watch('zipCode')) ?? ''}
            inputProps={{ maxLength: 9 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BenvoTextField
            placeholder="Logradouro"
            fullWidth
            {...register('street')}
            error={!!errors.street}
            helperText={errors.street?.message}
            value={watch('street') ?? ''}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <BenvoTextField
            placeholder="Número"
            fullWidth
            {...register('number')}
            error={!!errors.number}
            helperText={errors.number?.message}
            value={watch('number') ?? ''}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth error={!!errors.state}>
            <BenvoSelectField
              displayEmpty
              placeholder="Estado"
              fullWidth
              {...register('state')}
              error={!!errors.state}
              value={watch('state') ?? ''}
            >
              <MenuItem value="" disabled>
                Estado
              </MenuItem>
              {ufOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  data-testid={`select-${option.value}`}
                >
                  {option.label}
                </MenuItem>
              ))}
            </BenvoSelectField>
            {!!errors.state && (
            <StyledFormHelper>{errors.state?.message}</StyledFormHelper>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <BenvoTextField
            placeholder="Cidade"
            fullWidth
            {...register('city')}
            error={!!errors.city}
            helperText={errors.city?.message}
            value={watch('city') ?? ''}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BenvoTextField
            placeholder="Bairro"
            fullWidth
            {...register('district')}
            error={!!errors.district}
            helperText={errors.district?.message}
            value={watch('district') ?? ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BenvoTextField
            placeholder="Complemento"
            fullWidth
            {...register('complement')}
            error={!!errors.complement}
            helperText={errors.complement?.message}
            value={watch('complement') ?? ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BenvoTextField
            placeholder="Referência"
            fullWidth
            {...register('reference')}
            error={!!errors.reference}
            helperText={errors.reference?.message}
            value={watch('reference') ?? ''}
          />
        </Grid>

      </Grid>

      <ButtonsContainer>
        <BenvoButton
          disabled={isEditingEmployeeInProgress}
          variant="secondary"
          horizontalPadding={24}
          onClick={handleCloseDrawer}
        >
          Cancelar
        </BenvoButton>
        <BenvoButton
          disabled={isEditingEmployeeInProgress}
          variant="primary"
          type="submit"
        >
          Editar
        </BenvoButton>
      </ButtonsContainer>
    </StyledForm>
  );
}
