/* eslint-disable max-len */
import React from 'react';

export default function HomeIcon({ width = 24, height = 25, color = '#474750' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 18.5V15.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.07 3.32009L3.14002 8.87009C2.36002 9.49009 1.86002 10.8001 2.03002 11.7801L3.36002 19.7401C3.60002 21.1601 4.96002 22.3101 6.40002 22.3101H17.6C19.03 22.3101 20.4 21.1501 20.64 19.7401L21.97 11.7801C22.13 10.8001 21.63 9.49009 20.86 8.87009L13.93 3.33009C12.86 2.47009 11.13 2.47009 10.07 3.32009Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
