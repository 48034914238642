import { Box, styled } from '@mui/material';

export const BurgerMenuContainer = styled(Box)`
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;

  border: 1px solid #f5f5f7;
  background: #fff;
  border-radius: 12px;
  cursor: pointer;
  z-index: 1010;
`;

export const BurgerIconContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 3px;

  div {
    width: 20.5px;
    height: ${({ open }) => (open ? '3px' : '4px')};
    background: #474750;
    border-radius: 8px;
    background-color: #474750;
    transition: all 0.3s linear;
  }

  div:nth-of-type(1) {
    width: 16px;
    display: ${({ open }) => (open ? 'none' : 'block')};
  }

  div:nth-of-type(2) {
    width: ${({ open }) => (open ? '21px' : '20.5px')};
    transform: ${({ open }) => (open ? 'rotate(45deg) translate(2px, 2px)' : 'rotate(0)')};
  }

  div:nth-of-type(3) {
    width: ${({ open }) => (open ? '21px' : '20.5px')};
    transform: ${({ open }) => (open ? 'rotate(-45deg) translate(2px, -2px)' : 'rotate(0)')};
  }
`;

export const MenuContainer = styled(Box)`
  transition: all 0.3s ease-in-out;
  display: ${({ open }) => (open ? 'grid' : 'none')};
  justify-content: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  position: fixed;
  inset: 0;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
  background: #f5f6f8;
  z-index: 1000;
  grid-template-rows: 1fr auto auto 1fr;

  img {
    margin-bottom: 40px;
    align-self: flex-end;
  }

  p {
    color: #474750;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 146%; /* 14.6px */
    letter-spacing: 0.025px;
    margin-bottom: 33px;
  }
`;

export const NavigationList = styled('ul')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  padding: 0;

  li {
    list-style: none;
    a {
      color: #474750;
      font-size: 20px;
      font-weight: 500;
      line-height: 135%; /* 27px */
      letter-spacing: 0.03px;
      text-decoration: none;
    }
  }
`;

export const SettingsList = styled('ul')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  padding: 0;
  margin: 16px 0 0 0;

  li {
    list-style: none;
    a {
      color: #474750;
      font-size: 20px;
      line-height: 135%; /* 27px */
      letter-spacing: 0.03px;
      text-decoration: none;
    }
  }

  button {
    background: none;
    border: none;
    color: #474750;
    font-size: 20px;
    line-height: 135%; /* 27px */
    letter-spacing: 0.03px;
    cursor: pointer;
  }
`;

export const Divider = styled(Box)`
  width: 216px;
  height: 1px;
  background: #e5e5e5;
`;
