import Joi from 'joi';

const recoverPassword = Joi.object({
  email: Joi.string().email({ tlds: false }).required().messages({
    'any.required': 'O email é obrigatório',
    'string.email': 'O email deve ser um email válido',
    'string.empty': 'O email não pode ficar vazio',
  }),
});

export default { recoverPassword };
