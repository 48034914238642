import { useEffect, useState } from 'react';

export default ({ getData }) => {
  const [{
    data, page, finished, queries, isLoading,
  }, setState] = useState({
    data: [],
    page: 0,
    finished: false,
    queries: {},
    isLoading: false,
  });
  const getMore = async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const { rows = [], totalPages = 0 } = (await getData({ page, ...queries })) || {};

    setState((prev) => ({
      ...prev,
      finished: prev.page === totalPages - 1,
      data: [...prev.data, ...rows],
      isLoading: false,
    }));
  };

  const nextPage = async () => {
    setState((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const handleQueries = (newQueries) => {
    setState((prev) => ({
      ...prev,
      queries: { ...prev.queries, ...newQueries },
      page: 0,
      finished: false,
      isLoading: true,
      data: [],
    }));
  };

  const refetch = () => {
    setState({
      queries: {},
      page: 0,
      finished: false,
      isLoading: true,
      data: [],
    });
  };

  useEffect(() => {
    getMore();
  }, [page, queries]);

  return {
    data,
    nextPage,
    finished,
    refetch,
    loading: isLoading,
    setQueries: handleQueries,
  };
};
