/* eslint-disable max-len */
import React from 'react';

export default function EditIcon({ width = 20, height = 20 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="edit">
        <g id="vuesax/bulk/edit">
          <g id="edit_2">
            <path
              id="Vector"
              opacity="0.4"
              d="M16.9917 7.59856V7.6V14.2333C16.9917 15.5757 16.5941 16.5864 15.9194 17.261C15.2448 17.9356 14.2341 18.3333 12.8917 18.3333H6.26675C4.92886 18.3333 3.91803 17.9358 3.24218 17.26C2.56636 16.5842 2.16675 15.5713 2.16675 14.225V7.6C2.16675 6.25826 2.56614 5.24745 3.24194 4.57254C3.91786 3.89751 4.92885 3.5 6.26675 3.5H12.9001C14.2381 3.5 15.2489 3.89756 15.9236 4.5723C16.5981 5.24683 16.9956 6.25715 16.9917 7.59856Z"
              fill="#4A397F"
              stroke="#4A397F"
            />
            <path
              id="Vector_2"
              d="M13.3854 3.33836L13.3854 3.33836L13.3869 3.33679C14.1017 2.62203 14.7288 2.34259 15.2879 2.34573C15.8479 2.34887 16.4663 2.63621 17.1617 3.33534C17.8691 4.05064 18.1501 4.67297 18.1501 5.24157C18.1501 5.79857 17.8724 6.40377 17.1632 7.11302L17.1616 7.11461L16.3113 7.97254C14.5165 7.42255 13.0775 5.98347 12.5274 4.1887L13.3854 3.33836Z"
              fill="#4A397F"
              stroke="#4A397F"
            />
            <path
              id="Vector_3"
              d="M13.9806 9.26806C14.1973 9.40316 14.4259 9.51768 14.6446 9.62326L10.6964 13.5714L10.6964 13.5714L10.6915 13.5764C10.5954 13.6753 10.4354 13.789 10.2435 13.8859C10.052 13.9825 9.86554 14.0437 9.72992 14.0632C9.72971 14.0632 9.7295 14.0633 9.72929 14.0633L7.26738 14.4115C7.1782 14.4195 7.11234 14.4249 7.05 14.4249C6.74873 14.4249 6.50661 14.3245 6.33689 14.1547C6.1428 13.9606 6.02973 13.6363 6.0866 13.2376C6.08662 13.2375 6.08663 13.2374 6.08664 13.2373L6.44488 10.7547L6.44498 10.754C6.464 10.6208 6.52616 10.435 6.62472 10.2414C6.72296 10.0485 6.83828 9.88545 6.93689 9.78684L10.8878 5.83597C10.9914 6.05907 11.1046 6.27622 11.2352 6.49431C11.3361 6.67058 11.4499 6.84803 11.5561 6.98642C11.664 7.15762 11.7838 7.31324 11.8787 7.42224C11.9341 7.50946 11.9909 7.57184 12.0217 7.60356C12.283 7.91395 12.5686 8.21004 12.8491 8.43929C12.8821 8.47167 12.9135 8.5007 12.9419 8.52524C12.9426 8.52579 12.9433 8.52642 12.9441 8.52711C12.9557 8.53713 12.9826 8.56048 13.0178 8.58512C13.1508 8.69595 13.313 8.83064 13.468 8.92984C13.6361 9.05285 13.8099 9.16555 13.9806 9.26806Z"
              fill="#4A397F"
              stroke="#4A397F"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
