import React, { useEffect, useState } from 'react';
import {
  Box, Checkbox, Divider, Skeleton,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  InfoListAndSearchContainer,
  ListContainer,
  ListItem,
  ListNameInput,
  ListTitle,
  SearchContainer,
  Title,
  StyledIconButton,
  SelectedContainer,
  ButtonContainer,
} from './style';
import { ReactComponent as AddIcon } from '../../../../../../assets/healthService/add-square.svg';
import { ReactComponent as RadioUnchecked } from '../../../../../../assets/healthService/radio.svg';
import { ReactComponent as RadioChecked } from '../../../../../../assets/healthService/radio-checked.svg';
import { ReactComponent as ArrowRight } from '../../../../../../assets/healthService/arrow-square-right.svg';
import BenvoDrawer from '../../../../../../components/BenvoDrawer';
import BenvoSearchField from '../../../../../../components/BenvoSearchField';
import routes from '../../../../../../services/routes';
import { getTokenInfo } from '../../../../../../utils/configAuth';
import BenvoButton from '../../../../../../components/BenvoButton/style';
import { useAlert } from '../../../../../../context/alertContext';
import BenvoDeleteModal from '../../../../../../components/BenvoDeleteModal';

const { healthService, healthServiceList } = routes;

export default function CreateOrEditListOfExam({ currentList, refetchExamsList }) {
  const ilpiId = getTokenInfo('ilpiId');
  const alert = useAlert();
  const INITIAL_STATE = {
    name: '',
    serviceType: '',
    serviceIds: [],
    ilpiId,
    services: [],
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const [listOfExams, setListOfExams] = useState([]);
  const [createListOfExam, setCreateListOfExam] = useState(INITIAL_STATE);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setListOfExams([]);
    setCreateListOfExam(INITIAL_STATE);
  };

  const handleListNameChange = (event) => {
    setCreateListOfExam({ ...createListOfExam, name: event.target.value });
  };

  const getExamsList = async () => {
    try {
      const { message } = await healthService.findAllByType();
      const { services } = message[0];
      setListOfExams(services.filter((service) => !createListOfExam.serviceIds.includes(service.id)));
      setCreateListOfExam((prevState) => ({
        ...prevState,
        serviceType: message[0].type,
      }));
      return message;
    } catch (error) {
      return error;
    }
  };

  const handleSearch = async (event) => {
    const { value } = event.target;
    if (!value || value.length > 2) {
      try {
        const { message } = await healthService.findAllByType({
          name: value,
        });
        const { services } = message[0];
        setListOfExams(services.filter((service) => !createListOfExam.serviceIds.includes(service.id)));
        setCreateListOfExam((prevState) => ({
          ...prevState,
          serviceType: message[0].type,
        }));
        return message;
      } catch (error) {
        setListOfExams([]);
        return error;
      }
    }
    return null;
  };

  const handleToggleItem = (event, exam) => {
    const { checked } = event.target;
    if (checked) {
      setCreateListOfExam((prevState) => ({
        ...prevState,
        serviceIds: [...prevState.serviceIds, exam.id],
        services: [...prevState.services, exam],
      }));
      setListOfExams((prevState) => prevState.filter((service) => service.id !== exam.id));
    } else {
      setCreateListOfExam((prevState) => ({
        ...prevState,
        serviceIds: prevState.serviceIds.filter((id) => id !== exam.id),
        services: prevState.services.filter((service) => service.id !== exam.id),
      }));
      const sortedExams = [exam, ...listOfExams].sort((a, b) => a.name.localeCompare(b.name));
      setListOfExams(sortedExams);
    }
  };

  const { isLoading: examsListIsLoading } = useQuery({
    queryKey: ['getExamsList'],
    queryFn: getExamsList,
    enabled: openDrawer,
    refetchOnWindowFocus: false,
  });

  const handleCreateExamList = async () => {
    const payload = createListOfExam;
    delete payload.services;

    try {
      const response = await healthServiceList.createHealthServiceList(payload);
      handleCloseDrawer();
      alert.success('Lista criada com sucesso!');
      refetchExamsList();
      return response;
    } catch (error) {
      alert.error('Erro ao criar lista!');
      return error;
    }
  };

  const handleEditExamList = async () => {
    const payload = createListOfExam;
    delete payload.services;

    try {
      await healthServiceList.updateHealthServiceList({
        listId: currentList.id,
        ilpiId,
      }, payload);
      handleCloseDrawer();
      alert.success('Lista atualizada com sucesso!');
      refetchExamsList();
    } catch (error) {
      alert.error('Erro ao atualizar lista!');
    }
  };

  const handleDeleteServiceList = async () => {
    try {
      await healthServiceList.deleteHealthServiceList({
        listId: currentList.id,
        ilpiId,
      });
      refetchExamsList();
      handleCloseDrawer();
      alert.success('Lista de serviços excluída com sucesso!');
    } catch (error) {
      alert.error('Erro ao excluir lista de serviços!');
    }
  };

  useEffect(() => {
    if (currentList) {
      setCreateListOfExam({
        name: currentList.name,
        serviceType: currentList.serviceType,
        serviceIds: currentList.services.map((service) => service.id),
        services: currentList.services,
      });
    }
  }, [currentList, openDrawer]);

  return (
    <>
      {currentList ? (
        <ListItem key={currentList.id}>
          <ListTitle>{currentList.name}</ListTitle>
          <StyledIconButton onClick={handleOpenDrawer}>
            <ArrowRight data-testid="arrow-right-btn" />
          </StyledIconButton>
        </ListItem>
      ) : (
        <StyledIconButton onClick={handleOpenDrawer}>
          <AddIcon data-testid="add-icon-btn" />
        </StyledIconButton>
      )}

      <BenvoDrawer data-testid="create-edit-exams-list-modal" onClose={handleCloseDrawer} open={openDrawer} justifyContent="flex-start" title="Lista de exames">
        <InfoListAndSearchContainer>
          <ListNameInput
            variant="outlined"
            hiddenLabel
            placeholder="Nome da lista"
            onChange={handleListNameChange}
            value={createListOfExam.name}
          />
          <SearchContainer>
            <Title>Pesquise e selecione exames disponíveis</Title>
            <BenvoSearchField
              minWidth="502"
              placeholder="Pesquisar produtos"
              onChange={handleSearch}
            />
            {examsListIsLoading ? (
              <Skeleton variant="rounded" width="100%" height="221.95px" />
            ) : (
              <Box width={1}>
                {listOfExams.length > 0 ? (
                  <ListContainer>
                    {listOfExams.map((service) => (
                      <ListItem key={service.id} htmlFor={service.id} data-testId="service-list-card">
                        <ListTitle>{service.name}</ListTitle>
                        <Checkbox
                          icon={<RadioUnchecked />}
                          checkedIcon={<RadioChecked />}
                          id={service.id}
                          onChange={(event) => handleToggleItem(event, service)}
                          checked={createListOfExam.serviceIds.includes(service.id)}
                        />
                      </ListItem>
                    ))}
                  </ListContainer>
                ) : (
                  <Title>Nenhum exame encontrado</Title>
                )}
              </Box>
            )}
          </SearchContainer>
          {createListOfExam?.services?.length > 0 && (
            <>
              <Divider flexItem />
              <SelectedContainer>
                <Title>Exames selecionados</Title>
                <ListContainer>
                  {createListOfExam.services.map((service) => (
                    <ListItem key={service.id} htmlFor={service.id} data-testId="selected-exams-list-card">
                      <ListTitle>{service.name}</ListTitle>
                      <Checkbox
                        icon={<RadioUnchecked />}
                        checkedIcon={<RadioChecked />}
                        id={service.id}
                        onChange={(event) => handleToggleItem(event, service)}
                        checked={createListOfExam.serviceIds.includes(service.id)}
                      />
                    </ListItem>
                  ))}
                </ListContainer>
              </SelectedContainer>
            </>
          )}
        </InfoListAndSearchContainer>

        <ButtonContainer>
          {currentList && (
            <Box flex={1} data-testid="delete-list-btn">
              <BenvoDeleteModal
                description="Tem certeza que deseja excluir a lista de serviços?"
                title="Excluir lista de serviços"
                handleDelete={handleDeleteServiceList}
              />
            </Box>
          )}

          <BenvoButton variant="secondary" onClick={handleCloseDrawer}>
            Cancelar
          </BenvoButton>

          <BenvoButton
            variant="primary"
            onClick={currentList ? handleEditExamList : handleCreateExamList}
            disabled={createListOfExam.name === '' || createListOfExam.serviceIds.length === 0}
          >
            {currentList ? 'Atualizar' : 'Criar'}
          </BenvoButton>
        </ButtonContainer>
      </BenvoDrawer>
    </>
  );
}
