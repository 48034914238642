/* eslint-disable max-len */
import {
  get, put, post, deleteMethod, postWithoutToken, patch,
  putWithoutToken,
} from './ApiRequest';

const ACCOMMODATION = '/api/accommodation';
const COLLABORATOR = '/api/collaborator';
const CONTRACT = '/api/contract';
const DOCUMENT = '/api/document';
const EMPLOYEE = '/api/employee';
const HEALTH_SERVICE = '/api/healthService';
const ILPI = '/api/ilpi';
const ILPI_KLIVO = '/api/ilpiKlivo';
const ORDER = '/api/order';
const PRESCRIPTION = '/api/prescription';
const MEDICINE = '/api/medicine';
const PUBLIC = '/public';
const RECIPE = '/api/recipe';
const RESIDENT = '/api/resident';
const SHOPPING_LIST = '/api/shoppingList';
const TASKS = '/api/tasks';
const USERS = '/api/users';
const VISIT = '/api/visit';
const MARKETPLACE = '/api/marketplaceList';
const SELLER = '/api/seller';
const PRODUCT = '/api/product';
const AUTH = '/api/auth';
const METRICS = '/api/metrics';
const FRUBANA = '/api/frubana';
const SUPERADMIN = '/api/superadmin';

const routes = {
  accommodation: {
    findAccommodation: ({ ilpiId }, queries) => get(`${ACCOMMODATION}/ilpi/${ilpiId}`, queries),
    getResidentsInAccommodationById: ({ accommodationId, ilpiId }) => get(`${ACCOMMODATION}/${accommodationId}/ilpi/${ilpiId}`),
    createAccommodation: ({ ilpiId }, payload) => post(`${ACCOMMODATION}/ilpi/${ilpiId}`, payload),
    createResidentInAccommodation: ({ accommodationId, ilpiId, residentId }) => post(`${ACCOMMODATION}/${accommodationId}/ilpi/${ilpiId}/resident/${residentId}`),
    updateAccommodation: ({ accommodationId, ilpiId }, payload) => put(`${ACCOMMODATION}/${accommodationId}/ilpi/${ilpiId}`, payload),
    removeResidentFromAccommodation: ({ accommodationId, ilpiId, residentId }) => deleteMethod(`${ACCOMMODATION}/${accommodationId}/ilpi/${ilpiId}/resident/${residentId}`),
    removeAccommodation: ({ accommodationId, ilpiId }) => deleteMethod(`${ACCOMMODATION}/${accommodationId}/ilpi/${ilpiId}`),
    removeAccommodationByResidentId: ({ accommodationId, ilpiId, residentId }) => deleteMethod(`${ACCOMMODATION}/${accommodationId}/ilpi/${ilpiId}/resident/${residentId}`),
  },
  auth: {
    renew: (payload) => post(`${AUTH}/renew`, payload),
    logout: () => post(`${AUTH}/logout`),
  },
  collaborator: {
    createCollaborator: (payload) => post(`${COLLABORATOR}`, payload),
    editCollaborator: ({ collaboratorId }, payload) => put(`${COLLABORATOR}/${collaboratorId}`, payload),
    getCollaboratorById: ({ collaboratorId }) => get(`${COLLABORATOR}/${collaboratorId}`),
    deleteCollaboratorById: ({ collaboratorId }) => deleteMethod(`${COLLABORATOR}/${collaboratorId}`),
    // doctor
    getAllDoctorsByIlpi: ({ ilpiId }, queries) => get(`${COLLABORATOR}/doctor/ilpi/${ilpiId}/all`, queries),
  },
  contract: {
    findContractsByIlpiId: ({ ilpiId }, queries) => get(`${CONTRACT}/ilpi/${ilpiId}`, queries),
    getContractById: ({ contractId, ilpiId }) => get(`${CONTRACT}/${contractId}/ilpi/${ilpiId}`),
    searchContractByResident: ({ residentId, ilpiId }) => get(`${CONTRACT}/resident/${residentId}/ilpi/${ilpiId}`),
    createContract: (payload) => post(`${CONTRACT}`, payload),
    updateContract: ({ contractId }, payload) => put(`${CONTRACT}/${contractId}`, payload),
    removeContract: ({ contractId }) => deleteMethod(`${CONTRACT}/${contractId}`),
  },
  document: {
    createDocument: ({ residentId }, payload) => post(`${DOCUMENT}/resident/${residentId}/upload`, payload),
    getDocumentsByResidentId: ({ residentId }, queries) => get(`${DOCUMENT}/resident/${residentId}`, queries),
    removeDocument: ({ documentId, residentId }) => deleteMethod(`${DOCUMENT}/${documentId}/resident/${residentId}`),
  },
  employee: {
    findEmployee: ({ ilpiId }, queries) => get(`${EMPLOYEE}/ilpi/${ilpiId}`, queries),
    findEmployeesByIlpi: ({ ilpiId }, queries) => get(`${EMPLOYEE}/ilpi/${ilpiId}`, queries),
    getEmployeeById: ({ employeeId, ilpiId }) => get(`${EMPLOYEE}/${employeeId}/ilpi/${ilpiId}`),
    getAllEmployeeNamesByIlpi: ({ ilpiId }) => get(`${EMPLOYEE}/ilpi/${ilpiId}/names`),
    createEmployee: (payload) => post(`${EMPLOYEE}`, payload),
    updateEmployee: ({ employeeId, ilpiId }, payload) => put(`${EMPLOYEE}/${employeeId}/ilpi/${ilpiId}`, payload),
    removeEmployee: ({ employeeId, ilpiId }) => deleteMethod(`${EMPLOYEE}/${employeeId}/ilpi/${ilpiId}`),
  },
  frubana: {
    createCart: (payload) => post(`${FRUBANA}/cart`, payload),
    checkoutCart: (payload) => post(`${FRUBANA}/cart/checkout`, payload),
    listCreditCards: () => get(`${FRUBANA}/payment/credit-cards`),
    addCreditCard: (payload) => post(`${FRUBANA}/payment/credit-cards`, payload),
    removeCreditCard: (token) => deleteMethod(`${FRUBANA}/payment/credit-cards`, { cardToken: token }),
  },
  healthService: {
    findAll: (queries) => get(`${HEALTH_SERVICE}`, queries),
    findMostRequested: (queries) => get(`${HEALTH_SERVICE}/mostRequested`, queries),
    findAllByType: (queries) => get(`${HEALTH_SERVICE}/sortedByType`, queries),
    findHealthServiceTypes: () => get(`${HEALTH_SERVICE}/types`),
  },
  healthServiceOrder: {
    createOrder: (payload) => post(`${HEALTH_SERVICE}/order`, payload),
    getOrdersByIlpiId: ({ ilpiId }, queries) => get(`${HEALTH_SERVICE}/order/ilpi/${ilpiId}`, queries),
    getHealthServiceOrderByOrderAndResidentId: ({ orderId, residentId }) => get(`${HEALTH_SERVICE}/order/${orderId}/resident/${residentId}`),
  },
  healthServiceList: {
    findHealthServiceListByIlpiId: ({ ilpiId }) => get(`${HEALTH_SERVICE}/list/ilpi/${ilpiId}`),
    createHealthServiceList: (payload) => post(`${HEALTH_SERVICE}/list`, payload),
    updateHealthServiceList: ({ listId, ilpiId }, payload) => put(`${HEALTH_SERVICE}/list/${listId}/ilpi/${ilpiId}`, payload),
    deleteHealthServiceList: ({ listId, ilpiId }) => deleteMethod(`${HEALTH_SERVICE}/list/${listId}/ilpi/${ilpiId}`),
  },
  ilpi: {
    // ILPI
    findIlpi: (queries) => get(`${ILPI}`, queries),
    getIlpiById: ({ ilpiId }) => get(`${ILPI}/${ilpiId}`),
    getIlpiHomeInformation: ({ ilpiId }, queries) => get(`${ILPI}/${ilpiId}/homeInformation`, queries),
    createIlpi: (payload) => post(`${ILPI}`, payload),
    updateIlpi: ({ ilpiId }, payload) => put(`${ILPI}/${ilpiId}`, payload),
    resetIlpiPassword: ({ ilpiId }) => put(`${ILPI}/${ilpiId}/password`),
    removeIlpi: ({ ilpiId }) => deleteMethod(`${ILPI}/${ilpiId}`),
    // ILPI Users
    getIlpiUsers: ({ ilpiId }) => get(`${ILPI}/${ilpiId}/users`),
    // Sales Representative
    findIlpiSalesRepresentative: ({ ilpiId }) => get(`${ILPI}/${ilpiId}/representative`),
    createIlpiSalesRepresentative: ({ ilpiId }, payload) => post(`${ILPI}/${ilpiId}/representative`, payload),
    // Features
    getIlpiFeatures: ({ ilpiId }) => get(`${ILPI}/${ilpiId}/features`),
    updateIlpiFeatures: ({ ilpiId }, payload) => put(`${ILPI}/${ilpiId}/features`, payload),
    // Terms
    updateReadTerms: ({ ilpiId }) => patch(`${ILPI}/${ilpiId}/terms`),
  },
  ilpiKlivo: {
    findAllIlpiKlivo: (queries) => get(`${ILPI_KLIVO}`, queries),
    getIlpiKlivoByIlpiId: ({ ilpiId }) => get(`${ILPI_KLIVO}/ilpi/${ilpiId}`),
    getResidentsByIlpiId: ({ ilpiId }) => get(`${ILPI_KLIVO}/ilpi/${ilpiId}/residents`),
    getCSVInfo: (queries) => get(`${ILPI_KLIVO}/csv`, queries),
    getCSVInfoById: ({ ilpiKlivoId }) => get(`${ILPI_KLIVO}/${ilpiKlivoId}/csv`),
    createIlpiKlivo: (payload) => post(`${ILPI_KLIVO}`, payload),
    updateIlpiKlivo: ({ ilpiKlivoId }, payload) => put(`${ILPI_KLIVO}/${ilpiKlivoId}`, payload),
    deleteIlpiKlivo: ({ ilpiKlivoId }) => deleteMethod(`${ILPI_KLIVO}/${ilpiKlivoId}`),
  },
  medicine: {
    findProductById: ({ medicineId }) => get(`${MEDICINE}/${medicineId}`),
    findBySubstanceOrName: (queries) => get(`${MEDICINE}/names`, queries),
    getAllProducts: (queries) => get(`${MEDICINE}`, queries),
    createProduct: (payload) => post(`${MEDICINE}`, payload),
  },
  order: {
    findOrders: (queries) => get(`${ORDER}`, queries),
    findOrdersByIlpi: ({ ilpiId }, queries) => get(`${ORDER}/ilpi/${ilpiId}`, queries),
    getOrderById: ({ orderId }) => get(`${ORDER}/${orderId}`),
    createOrder: ({ ilpiId }, payload) => post(`${ORDER}/create/ilpi/${ilpiId}`, payload),
  },
  prescription: {
    findPrescriptions: (queries) => get(`${PRESCRIPTION}`, queries),
    findPrescriptionsByIlpi: ({ ilpiId }, queries) => get(`${PRESCRIPTION}/ilpi/${ilpiId}`, queries),
    getPrescriptionById: ({ prescriptionId }) => get(`${PRESCRIPTION}/${prescriptionId}`),
    getPrescriptionsByResidentId: ({ residentId }) => get(`${PRESCRIPTION}/resident/${residentId}`),
    getPrescriptionsByResidentAndMedicationId: ({ residentId, medicationId }) => get(`${PRESCRIPTION}/resident/${residentId}/medicine/${medicationId}`),
    createPrescription: (payload) => post(`${PRESCRIPTION}`, payload),
    updatePrescription: ({ prescriptionId }, payload) => put(`${PRESCRIPTION}/${prescriptionId}`, payload),
    inactivatePrescription: ({ prescriptionId }, payload) => put(`${PRESCRIPTION}/${prescriptionId}/inactivate`, payload),
    replicatePrescripton: ({ prescriptionId }, payload) => put(`${PRESCRIPTION}/${prescriptionId}/replicate`, payload),
    removePrescription: ({ prescriptionId }) => deleteMethod(`${PRESCRIPTION}/${prescriptionId}`),
  },
  publicRoutes: {
    // Login
    login: (payload) => postWithoutToken(`${PUBLIC}/login`, payload),
    // Recipes
    getSentRecipes: ({ ilpiId, collaboratorId }) => get(`${PUBLIC}/ilpi/${ilpiId}/doctor/${collaboratorId}/recipe/sent`),
    returnCheckedRecipes: ({ ilpiId, doctorId }, payload) => put(`${PUBLIC}/recipe/ilpi/${ilpiId}/doctor/${doctorId}/return`, payload),
    signA1PDFRecipe: ({ collaboratorId, ilpiId }, payload) => post(`${PUBLIC}/doctor/${collaboratorId}/ilpi/${ilpiId}/recipe/sign`, payload),
    // Exams
    getSentExams: ({ ilpiId, collaboratorId }) => get(`${PUBLIC}/ilpi/${ilpiId}/doctor/${collaboratorId}/exam/sent`),
    rejectExam: ({ orderId }, payload) => put(`${PUBLIC}/order/${orderId}/status`, payload),
    signA1PDFExam: ({ collaboratorId, ilpiId }, payload) => post(`${PUBLIC}/doctor/${collaboratorId}/ilpi/${ilpiId}/exam/sign`, payload),
    // ShoppingList
    getShoppingListPreview: ({ shoppingListId }) => get(`${PUBLIC}/shoppingList/${shoppingListId}`),
    // Doctor and Certificates
    checkProfessionalIdentification: ({ ilpiId, collaboratorId, professionalIdentification }) => post(`${PUBLIC}/ilpi/${ilpiId}/doctor/${collaboratorId}/check/${professionalIdentification}`),
    fetchSignedPDF: ({ fileId }) => get(`${PUBLIC}/certificate/${fileId}`),
    // Password
    requestNewPassword: (payload) => postWithoutToken(`${PUBLIC}/user/forgot-password`, payload),
    resetPassword: (payload) => putWithoutToken(`${PUBLIC}/user/reset-password`, payload),
  },
  recipe: {
    findAllRecipesByIlpi: ({ ilpiId }, queries) => get(`${RECIPE}/ilpi/${ilpiId}`, queries),
    searchRecipesByIlpi: ({ ilpiId }, queries) => get(`${RECIPE}/ilpi/${ilpiId}/search`, queries),
    getRecipeById: ({ recipeId }) => get(`${RECIPE}/${recipeId}`),
    getRecipeByPrescriptionId: ({ prescriptionId }) => get(`${RECIPE}/prescription/${prescriptionId}`),
    getRecipeSummaryByPrescriptionId: ({ prescriptionId }) => get(`${RECIPE}/prescription/${prescriptionId}`),
    getRecipeStatusByIlpi: ({ status, ilpiId }) => get(`${RECIPE}/${status}/ilpi/${ilpiId}`),
    getNotSentRecipeByIlpi: ({ ilpiId }) => get(`${RECIPE}/ilpi/${ilpiId}`),
    createRecipe: (payload) => post(`${RECIPE}`, payload),
    generateRecipeByIlpi: ({ ilpiId }, payload) => post(`${RECIPE}/ilpi/${ilpiId}/generate`, payload),
    sendRecipeToDoctor: ({ ilpiId, doctorId }) => post(`${RECIPE}/ilpi/${ilpiId}/doctor/${doctorId}`),
    removeRecipe: ({ recipeId }) => deleteMethod(`${RECIPE}/${recipeId}`),
    getPDFLink: ({ recipeId }) => get(`${RECIPE}/${recipeId}/pdf`),
  },
  resident: {
    // Residents
    findResidents: (queries) => get(`${RESIDENT}`, queries),
    findResidentsByIlpi: ({ ilpiId }, queries) => get(`${RESIDENT}/ilpi/${ilpiId}`, queries),
    searchResidentByIlpi: ({ ilpiId }, queries) => get(`${RESIDENT}/ilpi/${ilpiId}/search`, queries),
    getResidentById: ({ residentId }) => get(`${RESIDENT}/${residentId}`),
    getAllResidentsByIlpiWithoutPagination: ({ ilpiId }) => get(`${RESIDENT}/ilpi/${ilpiId}/noLimit`),
    createResident: (payload) => post(`${RESIDENT}`, payload),
    updateResident: ({ residentId }, payload) => put(`${RESIDENT}/${residentId}`, payload),
    removeResident: ({ residentId }) => deleteMethod(`${RESIDENT}/${residentId}`),
    getSerproAuth: () => get(`${RESIDENT}/serpro/auth`),
    // Evolutions
    findResidentEvolutionByIlpi: ({ ilpiId }, queries) => get(`${RESIDENT}/evolution/ilpi/${ilpiId}`, queries),
    findResidentEvolutionsByResidentId: ({ residentId }, queries) => get(`${RESIDENT}/${residentId}/evolution`, queries),
    createResidentEvolution: ({ residentId }, payload) => post(`${RESIDENT}/${residentId}/evolution`, payload),
    updateResidentEvolution: ({ evolutionId }, payload) => put(`${RESIDENT}/evolution/${evolutionId}`, payload),
    removeResidentEvolution: ({ evolutionId }) => deleteMethod(`${RESIDENT}/evolution/${evolutionId}`),
    listAllResidentsAtFamilyByResponsibleEmailOrPhone: ({
      ilpiId, email = '', phone = '', residentId = '',
    }) => get(`${RESIDENT}/ilpi/${ilpiId}/responsible?email=${email}&phone=${phone}&residentId=${residentId}`),
    residentContact: ({ residentId }, payload) => post(`${RESIDENT}/${residentId}/contact`, payload),
    getResidentContact: ({ residentId }) => get(`${RESIDENT}/${residentId}/contacts`),
    getReferenceContactsByIlpi: ({ ilpiId }, query = '') => get(`${RESIDENT}/ilpi/${ilpiId}/contacts?${query}`),
    removeContactAssociation: ({ residentId, contactId }) => deleteMethod(`${RESIDENT}/${residentId}/contact/${contactId}`),
  },
  seller: {
    listAllSellers: (queries) => get(`${SELLER}`, queries),
    listListsBySellerId: ({ sellerId }, queries) => get(`${SELLER}/${sellerId}/products/batch`, queries),
    findProductsListById: ({ batch }, queries) => get(`${SELLER}/products/batch/${batch}`, queries),
    createSeller: (payload) => post(`${SELLER}`, payload),
    updateSeller: ({ sellerId }, payload) => put(`${SELLER}/${sellerId}`, payload),
    sendProductSellerCSV: ({ sellerId }, payload) => post(`${SELLER}/${sellerId}/products/csv`, payload),
    removeSeller: ({ sellerId }) => deleteMethod(`${SELLER}/${sellerId}`),
    removeList: ({ batch }) => deleteMethod(`${SELLER}/products/batch/${batch}`),
  },
  shoppingList: {
    getProducts: (queries) => get(`${SHOPPING_LIST}/products`, queries),
    createShoppingList: (payload) => post(`${SHOPPING_LIST}`, payload),
    updateShoppingList: ({ listId }, payload) => put(`${SHOPPING_LIST}/${listId}`, payload),
    getAllByResidentId: ({ residentId }, queries) => get(`${SHOPPING_LIST}/resident/${residentId}`, queries),
    getAllIdsByIlpiId: ({ ilpiId }, queries) => get(`${SHOPPING_LIST}/ilpi/${ilpiId}/ids`, queries),
    getListById: ({ listId }) => get(`${SHOPPING_LIST}/${listId}`),
    sendLists: (payload) => post(`${SHOPPING_LIST}/send`, payload),
    sendList: ({ listId }, payload, queries) => post(`${SHOPPING_LIST}/${listId}/send`, payload, queries),
    getLatestList: ({ residentId }) => get(`${SHOPPING_LIST}/resident/${residentId}/latest`),
  },
  superadmin: {
    // Marketplace
    getAllProducts: (queries) => get(`${PRODUCT}/`, queries),
    generateQuotation: (_, payload) => post(`${MARKETPLACE}/quotation/all`, payload),
    // SuperAdmin
    generateImpersonate: (_, payload) => post(`${SUPERADMIN}/impersonate`, payload),
  },
  task: {
    findTaskById: ({ taskId }) => get(`${TASKS}/${taskId}`),
    findTasksByIlpi: ({ ilpiId }, queries) => get(`${TASKS}/ilpi/${ilpiId}`, queries),
    findTasksByEmployeeId: ({ employeeId }, queries) => get(`${TASKS}/employee/${employeeId}`, queries),
    getRegistryByIlpi: ({ ilpiId }, queries) => get(`${TASKS}/ilpi/${ilpiId}/registry`, queries),
    generateCSV: ({ ilpiId }, queries) => get(`${TASKS}/ilpi/${ilpiId}/registry/csv`, queries),
    getTodayTasks: ({ employeeId, onlyTasksType }) => get(`${TASKS}/employee/${employeeId}/today`, onlyTasksType ? { onlyTasksType } : {}),
    createTask: ({ employeeId }, payload) => post(`${TASKS}/employee/${employeeId}`, payload),
    createTaskRegistry: ({ taskId }, payload) => post(`${TASKS}/${taskId}/registry`, payload),
    updateTask: ({ taskId }, payload) => put(`${TASKS}/${taskId}`, payload),
    removeTask: ({ taskId }) => deleteMethod(`${TASKS}/${taskId}`),
  },
  users: {
    findUser: (queries) => get(`${USERS}/username`, queries),
    findAllUserAndCollaboratorsByIlpi: ({ ilpiId }, queries) => get(`${USERS}/ilpi/${ilpiId}`, queries),
    findUserAfarma: (queries) => get(`${USERS}/afarma`, queries),
    getAllUser: () => get(`${USERS}`),
    createUser: (payload) => post(`${USERS}`, payload),
    changeUserPassword: ({ userId }, payload) => put(`${USERS}/${userId}/password`, payload),
    overrideUserPassword: ({ userId, ilpiId }, payload) => put(`${USERS}/${userId}/ilpi/${ilpiId}/password`, payload),
    changeUserStatus: ({ userId }) => put(`${USERS}/${userId}/status`),
    updateUser: ({ userId }, payload) => put(`${USERS}/${userId}`, payload),
    getUserById: ({ userId }) => get(`${USERS}/${userId}`),
    deleteUserById: ({ userId }) => deleteMethod(`${USERS}/${userId}`),
  },
  visit: {
    findVisitByIlpi: ({ ilpiId }, queries) => get(`${VISIT}/ilpi/${ilpiId}`, queries),
    createVisit: ({ ilpiId }, payload) => post(`${VISIT}/ilpi/${ilpiId}`, payload),
    updateVisit: ({ visitId, ilpiId }, payload) => put(`${VISIT}${visitId}/ilpi/${ilpiId}`, payload),
    deleteVisit: ({ visitId, ilpiId }) => deleteMethod(`${VISIT}/${visitId}/ilpi/${ilpiId}`),
    findVisitByResidentId: ({ residentId }) => get(`${VISIT}/resident/${residentId}`),
  },
  marketplace: {
    getMarketplaceList: ({ ilpiId }, queries) => get(`${MARKETPLACE}/ilpi/${ilpiId}`, queries),
    getMarketplaceListById: ({ listId }) => get(`${MARKETPLACE}/${listId}`),
    createMarketplaceList: ({ ilpiId }, payload) => post(`${MARKETPLACE}/ilpi/${ilpiId}`, payload),
    deleteMarketplaceList: ({ listId }) => deleteMethod(`${MARKETPLACE}/${listId}`),
    addProductToMarketplaceList: ({ listId }, payload) => patch(`${MARKETPLACE}/${listId}/insert`, payload),
    deleteProductToMarketplaceList: ({ listId, itemId }) => patch(`${MARKETPLACE}/${listId}/item/${itemId}/remove`),
    updateMarketplaceList: ({ listId }, payload) => put(`${MARKETPLACE}/${listId}`, payload),
    generateQuotation: ({ ilpiId }, payload) => post(`${MARKETPLACE}/quotation/ilpi/${ilpiId}`, payload),
  },
  product: {
    getProductList: (queries) => get(`${PRODUCT}`, queries),
    getProductsByIlpi: ({ ilpiId }, queries) => get(`${PRODUCT}/ilpi/${ilpiId}`, queries),
  },
  metrics: {
    postMixpanel: (eventName, eventInformation) => post(`${METRICS}/register`, { eventName, eventInformation }),
  },
};

export default routes;
