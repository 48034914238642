import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAlert } from '../../context/alertContext';
import OrderCard from './Components/OrderCard';
import { getTokenInfo } from '../../utils/configAuth';
import routes from '../../services/routes';
import Loading from '../../components/Loading/Loading';
import BenvoCard from '../../components/BenvoCard';
import DynamicScroll from '../../components/DynamicScroll';
import BenvoEmpty from '../../components/BenvoEmpty';

export default function Order() {
  const alert = useAlert();
  const ilpiId = getTokenInfo('ilpiId');
  const [pagination, setPagination] = useState({ page: 0, finished: false });
  const [isLoading, setIsLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);

  const findOrders = async () => {
    setIsLoading(true);
    try {
      const { message: { rows, totalPages } } = await routes.order
        .findOrdersByIlpi({ ilpiId }, { page: pagination.page });

      setPagination((prev) => ({ page: prev.page + 1, finished: prev.page === totalPages - 1 }));
      setOrderList((prev) => [...prev, ...rows]);
    } catch {
      alert.error('Não foi possível carregar os pedidos');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    findOrders();
  }, []);

  if (isLoading && orderList.length === 0) return <Loading />;

  return (
    <BenvoCard title={orderList.length ? 'Pedidos' : ''} sx={{ m: 0 }}>
      <BenvoEmpty
        active={!orderList.length}
        title="Pedidos"
        message="Seus pedidos serão listados nessa tela, aqui você poderá acompanhar o andamento deles!"
      />

      <DynamicScroll
        onScrollEnd={findOrders}
        loading={isLoading}
        finished={pagination.finished}
        sx={{
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            width: '0',
          },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ padding: '2px' }}
        >
          {orderList?.map((order) => (
            <OrderCard key={order.id} order={order} />
          ))}
        </Grid>
      </DynamicScroll>
    </BenvoCard>
  );
}
