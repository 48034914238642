/* eslint-disable max-len */
import React from 'react';

export default function TasksIcon({ width = 24, height = 25, color = '#474750' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="note">
        <g id="vuesax/linear/note">
          <g id="note_2">
            <path id="Vector" d="M8 2.5V5.5" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_2" d="M16 2.5V5.5" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_3" d="M7 13.5H15" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_4" d="M7 17.5H12" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_5" d="M16 4C19.33 4.18 21 5.45 21 10.15V16.33C21 20.45 20 22.51 15 22.51H9C4 22.51 3 20.45 3 16.33V10.15C3 5.45 4.67 4.19 8 4H16Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
      </g>
    </svg>
  );
}
