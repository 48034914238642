import React from 'react';

export default function AddSquare() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66602 10.9167H13.3327" stroke="#96969E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 14.2502V7.5835" stroke="#96969E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.49935 19.2502H12.4993C16.666 19.2502 18.3327 17.5835 18.3327 13.4168V8.41683C18.3327 4.25016 16.666 2.5835 12.4993 2.5835H7.49935C3.33268 2.5835 1.66602 4.25016 1.66602 8.41683V13.4168C1.66602 17.5835 3.33268 19.2502 7.49935 19.2502Z" stroke="#96969E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
