import React from 'react';
import moment from 'moment';
import { IconButton, Tooltip } from '@mui/material';
import { Save } from '@mui/icons-material';
import MTable from '../../../../components/MuiTable';
import ConfirmationDialog from '../../../../components/confirmationDialog';
import routes from '../../../../services/routes';
import { useAlert } from '../../../../context/alertContext';
import { generatePdfEvolution } from '../../../../utils/generatePDFs';
import EditEvolution from '../EditEvolution';
import ViewEvolution from '../ViewEvolution';

function EvolutionsTable({ evolutionsList, listEvolutions }) {
  const alert = useAlert();

  const getStatusColor = (status) => {
    switch (status) {
      case 'Normal':
        return '#0A305A';
      case 'Atenção':
        return '#e57734';
      case 'Alerta':
        return '#F23C3C ';
      default:
        return 'transparent';
    }
  };

  const deleteEvolution = async (evolutionId) => {
    try {
      await routes.resident.removeResidentEvolution({ evolutionId });
      alert.success('Evolução excluída com sucesso!');
      listEvolutions();
    } catch (error) {
      alert.error('Erro ao excluir evolução');
      console.error(error);
    }
  };

  return (
    <MTable.Container sx={{ mb: 4, mt: 4 }}>
      <MTable.Root>
        <MTable.Head>
          <MTable.Row>
            <MTable.Cell>Residente</MTable.Cell>
            <MTable.Cell>Tipo</MTable.Cell>
            <MTable.Cell>Responsável</MTable.Cell>
            <MTable.Cell>Data</MTable.Cell>
            <MTable.Cell>Status</MTable.Cell>
            <MTable.Cell>Ações</MTable.Cell>
          </MTable.Row>
        </MTable.Head>
        <MTable.Body>
          {evolutionsList.map((evolution) => (
            <MTable.Row key={evolution.id}>
              <MTable.Cell>{evolution.name}</MTable.Cell>
              <MTable.Cell>{evolution.kind}</MTable.Cell>
              <MTable.Cell>{evolution.responsibleName}</MTable.Cell>
              <MTable.Cell>{moment(evolution.date).format('DD/MM/yyyy HH:mm')}</MTable.Cell>
              <MTable.Cell
                sx={{
                  color: getStatusColor(evolution.status),
                }}
              >
                {evolution.status}
              </MTable.Cell>
              <MTable.Cell
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ViewEvolution evolution={evolution} />
                <Tooltip title="Salvar em PDF">
                  <IconButton
                    color="primary"
                    onClick={() => generatePdfEvolution(evolution)}
                  >
                    <Save />
                  </IconButton>
                </Tooltip>
                <EditEvolution evolution={evolution} listEvolutions={listEvolutions} />
                <ConfirmationDialog
                  title="Deseja excluir a evolução?"
                  message="Essa ação não poderá ser desfeita."
                  handleConfirm={() => deleteEvolution(evolution.id)}
                />
              </MTable.Cell>
            </MTable.Row>
          ))}
        </MTable.Body>
      </MTable.Root>
    </MTable.Container>
  );
}

export default EvolutionsTable;
