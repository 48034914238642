export const formatCurrencyBR = (number) => {
  if (!number) return '0.00';

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat('pt-BR', options).format(Number(number));
};

export const formatCurrencyAndAddRealSymbol = (value) => {
  if (!value) {
    return 'R$ 0,00';
  }

  return `R$ ${formatCurrencyBR(value)}`;
};
