import React from 'react';

function ArrowRight() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.49935 18.8332L12.4993 18.8332C16.666 18.8332 18.3327 17.1665 18.3327 12.9998L18.3327 7.99984C18.3327 3.83317 16.666 2.16651 12.4993 2.16651L7.49935 2.1665C3.33268 2.1665 1.66602 3.83317 1.66601 7.99984L1.66601 12.9998C1.66601 17.1665 3.33268 18.8332 7.49935 18.8332Z" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.94922 13.4414L11.8826 10.4998L8.94922 7.55811" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowRight;
