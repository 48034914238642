import { Button, styled } from '@mui/material';

const ButtonVariantProps = {
  primary: {
    background: 'var(--paleta-senior-purple, #4A397F)',
    color: '#FFF',
    ':hover': {
      border: '1px solid var(--paleta-senior-purple, #4A397F)',
      background: 'var(--black-and-white-white, #FFF)',
      color: 'var(--paleta-senior-purple, #4A397F)',
      boxShadow: '0px 10px 15px 0px rgba(0, 0, 0, 0.10)',
    },
    ':disabled': {
      background: 'var(--main-and-gray-light-gray, #EAEBEC) !important',
      color: 'var(--main-and-gray-light-bluegray, #BEC5CF) !important',
      cursor: 'not-allowed !important',
    },
  },
  secondary: {
    background: 'var(--grey-variation-lightest-grey, #F5F5F7)',
    color: 'var(--paleta-senior-purple, #4A397F)',
    ':hover': {
      background: 'var(--grey-variation-lightest-grey, #F5F5F7)',
      border: '1px solid var(--paleta-senior-purple, #4A397F)',
      boxShadow: '0px 10px 15px 0px rgba(0, 0, 0, 0.10)',
    },
    ':disabled': {
      background: 'var(--grey-variation-lightest-grey, #F5F5F7)',
      border: '1px solid var(--grey-variation-light-grey, #D4D4DA)',
      cursor: 'not-allowed',
    },
  },
  success: {
    background: 'var(--paleta-senior-alert-green, #52A569)',
    border: 'none',
    color: '#FFF',
    cursor: 'pointer',
    ':hover': {
      background: 'var(--paleta-senior-alert-green, #52A569)',
      border: 'none',
      boxShadow: '0px 10px 15px 0px rgba(0, 0, 0, 0.10)',
    },
    ':disabled': {
      background: 'var(--main-and-gray-light-gray, #EAEBEC) !important',
      color: 'var(--main-and-gray-light-bluegray, #BEC5CF) !important',
      cursor: 'not-allowed !important',
    },
  },
};

/**
 * @param {string} variant - The variant of the button. Can be 'primary' or 'secondary'.
 * @default variant - 'primary'
 * @param {string | number} horizontalPadding - The horizontal padding of the button. Default is 40px.
 * @default horizontalPadding - 40px
 * @param {boolean} hasBorder - If the button has a border or not.
 */
const BenvoButton = styled(Button)`
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: ${({ horizontalPadding }) => (horizontalPadding ? `${horizontalPadding}px` : '40px')};
  padding-right: ${({ horizontalPadding }) => (horizontalPadding ? `${horizontalPadding}px` : '40px')};
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border: 1px solid transparent;

  border-radius: 80px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.175px;
  text-transform: none;
  transition: all 0.3s ease-in-out;

  ${({ hasBorder }) => (hasBorder ? 'border: 1px solid var(--paleta-senior-purple, #4A397F);' : '')}

  ${({ variant }) => (variant ? ButtonVariantProps[variant] : ButtonVariantProps.primary)};

  & .MuiButton-startIcon {
    margin-right: 0;
  }
`;

export default BenvoButton;
