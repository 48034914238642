import React, { useEffect, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ChildrenContainer, StyledDropdown, TitleContainer } from './style';

export default function BenvoDropdown({
  children, title, initialState = false, error = {},
}) {
  const [open, setOpen] = useState(initialState);
  const [hasError, setHasError] = useState(false);

  const handleOpen = () => setOpen(!open);

  const handleErrors = () => {
    if (Object.keys(error).length) {
      setHasError(true);
      setOpen(true);
    } else {
      setHasError(false);
    }
  };

  const resetDropdown = () => {
    setHasError(false);
  };

  useEffect(() => {
    handleErrors();
  }, [error]);

  return (
    <StyledDropdown open={open || hasError}>
      <TitleContainer
        onClick={() => {
          handleOpen();
          resetDropdown();
        }}
        data-testid={`dropdown-${title.slice(0, 6)}`}
      >
        <h6>{title}</h6>
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </TitleContainer>
      <ChildrenContainer open={open || hasError}>
        {children}
      </ChildrenContainer>
    </StyledDropdown>
  );
}
