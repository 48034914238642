import {
  Box, Select, Typography, styled,
} from '@mui/material';

export const ListContainer = styled(Box)`
    display: flex;
    width: 100%;
    height: 77px;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    border-radius: 6px;
    border: 1px solid var(--grey-variation-lightest-grey, #F5F5F7);
    background: var(--black-and-white-white, #FFF);
`;

export const UserContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

export const InformationAndActionsContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

export const UserInformationContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
`;

export const ActionsContainer = styled(Box)`
    display: flex;
    align-items: flex-start;
    gap: 12px;
`;

export const Name = styled(Typography)`
    color: var(--grey-variation-dark-grey, #474750);
    font-size: 18px;
    font-weight: 500;
    line-height: 130%; /* 23.4px */
    letter-spacing: 0.027px;
`;

export const Role = styled(Typography)`
    color: var(--grey-variation-dark-grey, #474750);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 10px */
    letter-spacing: 0.15px;
    text-transform: uppercase;
`;

export const GeneralInformation = styled(Typography)`
    color: var(--grey-variation-dark-grey, #474750);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.04px;
`;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  padding-right: 10px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  height: 40px;
  font-size: 12px;
  letter-spacing: 0.12px;
  color: var(--grey-variation-dark-grey, #474750);
  border: none;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
  
  & .Mui-readOnly{
    color: var(--grey-variation-light-grey, #D4D4DA);
    cursor: not-allowed;
  }

  & fieldset {
    border: none;
    ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
  }

  .MuiSelect-select {
    ${({ value }) => value === '' && 'color: var(--grey-variation-light-grey, #47475070)'}
  }
`;
