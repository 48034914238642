import React from 'react';

export default function TaskSquare() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M13.491 2.1665H6.50768C3.47435 2.1665 1.66602 3.97484 1.66602 7.00817V13.9915C1.66602 17.0248 3.47435 18.8332 6.50768 18.8332H13.491C16.5244 18.8332 18.3327 17.0248 18.3327 13.9915V7.00817C18.3327 3.97484 16.5244 2.1665 13.491 2.1665Z"
        fill="#4A397F"
      />
      <path
        d="M15.2578 7.8916C15.2578 8.23327 14.9828 8.5166 14.6328 8.5166H10.2578C9.91615 8.5166 9.63281 8.23327 9.63281 7.8916C9.63281 7.54993 9.91615 7.2666 10.2578 7.2666H14.6328C14.9828 7.2666 15.2578 7.54993 15.2578 7.8916Z"
        fill="#4A397F"
      />
      <path
        d="M8.30716 7.08314L6.43216 8.95814C6.30716 9.08314 6.14883 9.14147 5.99049 9.14147C5.83216 9.14147 5.66549 9.08314 5.54883 8.95814L4.92383 8.33314C4.67383 8.09147 4.67383 7.69147 4.92383 7.44981C5.16549 7.20814 5.55716 7.20814 5.80716 7.44981L5.99049 7.63314L7.42383 6.1998C7.66549 5.95814 8.05716 5.95814 8.30716 6.1998C8.54883 6.44147 8.54883 6.84147 8.30716 7.08314Z"
        fill="#4A397F"
      />
      <path
        d="M15.2578 13.7246C15.2578 14.0663 14.9828 14.3496 14.6328 14.3496H10.2578C9.91615 14.3496 9.63281 14.0663 9.63281 13.7246C9.63281 13.3829 9.91615 13.0996 10.2578 13.0996H14.6328C14.9828 13.0996 15.2578 13.3829 15.2578 13.7246Z"
        fill="#292D32"
      />
      <path
        d="M8.30716 12.9166L6.43216 14.7916C6.30716 14.9166 6.14883 14.975 5.99049 14.975C5.83216 14.975 5.66549 14.9166 5.54883 14.7916L4.92383 14.1666C4.67383 13.925 4.67383 13.525 4.92383 13.2833C5.16549 13.0416 5.55716 13.0416 5.80716 13.2833L5.99049 13.4666L7.42383 12.0333C7.66549 11.7916 8.05716 11.7916 8.30716 12.0333C8.54883 12.275 8.54883 12.675 8.30716 12.9166Z"
        fill="#292D32"
      />
    </svg>
  );
}
