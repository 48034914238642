import React from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';

const formOptions = [
  { value: 'Ótimo', label: 'Ótimo' },
  { value: 'Bom', label: 'Bom' },
  { value: 'Regular', label: 'Regular' },
  { value: 'Ruim', label: 'Ruim' },
  { value: 'Péssimo', label: 'Péssimo' },
];

export default function WellBeingForm({ errors, register }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          select
          fullWidth
          label="Humor"
          error={!!errors.humor}
          helperText={errors.humor?.message}
          defaultValue="Ótimo"
          {...register('humor')}
        >
          {formOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          select
          fullWidth
          label="Apetite"
          error={!!errors.appetite}
          helperText={errors.appetite?.message}
          defaultValue="Ótimo"
          {...register('appetite')}
        >
          {formOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          select
          fullWidth
          label="Sono"
          error={!!errors.repose}
          helperText={errors.repose?.message}
          defaultValue="Ótimo"
          {...register('repose')}
        >
          {formOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
}
