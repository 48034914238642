import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Tooltip,
} from '@mui/material';
import routes from '../../../../services/routes';
import {
  cepInputMask,
  cnpjInputMask,
  removeMask,
  phoneInputMask,
  phoneWithCountryCodeMask,
} from '../../../../utils/inputMasks';
import schemas from '../../../../schemas';
import BenvoSecondaryButton from '../../../../components/BenvoSecondaryButton';
import BenvoPrimaryButton from '../../../../components/BenvoPrimaryButton';
import BenvoDrawer from '../../../../components/BenvoDrawer';
import { StyledDivider } from '../CreateIlpi/style';
import { useAlert } from '../../../../context/alertContext';
import IlpiInfoForm from '../CreateIlpi/IlpiInfoForm';
import IlpiAdressForm from '../CreateIlpi/IlpiAdressForm';
import IlpiContactForm from '../CreateIlpi/IlpiContactForm';
import IlpiFeaturesForm from '../CreateIlpi/IlpiFeaturesForm';

export default function EditIlpi({ currentIlpi, searchIlpi }) {
  const [openDrawer, setOpenDrawer] = useState(false);

  const alert = useAlert();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    control,
  } = useForm({
    resolver: joiResolver(schemas.ilpi.updateIlpi),
  });

  useEffect(() => {
    if (currentIlpi) {
      setValue('name', currentIlpi.name);
      setValue('responsibleName', currentIlpi.responsibleName);
      setValue('cnpj', cnpjInputMask(currentIlpi.cnpj));
      setValue('fantasyName', currentIlpi.fantasyName || '');
      setValue('residentsCapacity', String(currentIlpi.residentsCapacity) || '');
      setValue('residentsQuantity', String(currentIlpi.residentsQuantity) || '');
      setValue('zipCode', cepInputMask(currentIlpi.zipCode));
      setValue('state', currentIlpi.state);
      setValue('city', currentIlpi.city);
      setValue('district', currentIlpi.district);
      setValue('street', currentIlpi.street);
      setValue('number', currentIlpi.number);
      setValue('complement', currentIlpi.complement || '');
      setValue('reference', currentIlpi.reference || '');
      setValue('phoneNumber', phoneWithCountryCodeMask(currentIlpi.phoneNumber));
      setValue('landline', phoneInputMask(currentIlpi.landline) || '');
      setValue('email', currentIlpi.email);
    }
  }, [currentIlpi]);

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    reset();
  };

  const editIlpi = async (data) => {
    const payload = {
      ...data,
      phoneNumber: removeMask(data.phoneNumber),
      landline: removeMask(data.landline),
      zipCode: removeMask(data.zipCode),
      cnpj: removeMask(data.cnpj),
    };

    try {
      await routes.ilpi.updateIlpi({ ilpiId: currentIlpi.id }, payload);
      searchIlpi();
      reset();
      handleDrawerClose();
      alert.success('ILPI atualizada com sucesso!');
    } catch (error) {
      alert.error('Erro ao atualizar ILPI, tente novamente mais tarde.');
    }
  };

  return (
    <Box>
      <Tooltip title="Editar">
        <BenvoSecondaryButton sx={{ width: '117px' }} onClick={() => setOpenDrawer(true)}>
          Editar
        </BenvoSecondaryButton>
      </Tooltip>

      <BenvoDrawer
        title="Editar ILPI"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '16px',
        }}
        >
          <Box sx={{
            width: '100%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '16px',
          }}
          >
            <IlpiInfoForm
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
            />
            <StyledDivider />

            <IlpiAdressForm
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              currentIlpi={currentIlpi}
            />
            <StyledDivider />

            <IlpiContactForm
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              control={control}
            />
            <StyledDivider />

            <IlpiFeaturesForm
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              currentIlpi={currentIlpi}
            />
          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '16px',
          }}
          >
            <BenvoSecondaryButton
              onClick={handleDrawerClose}
            >
              Cancelar
            </BenvoSecondaryButton>

            <BenvoPrimaryButton
              onClick={handleSubmit(editIlpi)}
            >
              Editar
            </BenvoPrimaryButton>
          </Box>

        </Box>
      </BenvoDrawer>
    </Box>
  );
}
