import React from 'react';
import { Box, Typography } from '@mui/material';
import MTable from '../../../components/MuiTable';

export default function TasksRegistryTable({ tasks }) {
  const statusColor = (status) => {
    switch (status) {
      case 'completed':
        return '#2c7c2f';
      case 'cancelled':
        return '#d10e00';
      default:
        return '#000';
    }
  };

  const translateStatus = (status) => {
    switch (status) {
      case 'completed':
        return 'Concluída';
      case 'cancelled':
        return 'Cancelada';
      default:
        return 'warning';
    }
  };

  const translateType = (type) => {
    switch (type) {
      case 'medications':
        return 'Medicação';
      case 'evolution':
        return 'Evolução';
      case 'playing':
        return 'Lazer/Jogos';
      case 'physicalActivity':
        return 'Atividade Física';
      case 'cleaning':
        return 'Limpeza';
      case 'meals':
        return 'Alimentação';
      case 'hygiene':
        return 'Higiene';
      default:
        return '-';
    }
  };

  const tableHeadCells = [
    'Tarefa',
    'Tipo',
    'Cuidador',
    'Residente',
    'Justificativa',
    'Data da Tarefa',
    'Data do Registro',
    'Status',
  ];

  const tableBodyCells = (task) => [
    task.title ?? '-',
    translateType(task.type) ?? '-',
    task.employeeName,
    task.residentName ?? '-',
    task.justification ?? '-',
    task.date,
    task.completedAt,
  ];

  if (!tasks.message?.rows || tasks.message.rows.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Typography variant="h5">Nenhuma tarefa encontrada</Typography>
      </Box>
    );
  }

  return (
    <MTable.Container sx={{ mb: 4, mt: 4 }}>
      <MTable.Root>
        <MTable.Head>
          <MTable.Row>
            {tableHeadCells.map((cell) => (
              <MTable.Cell key={cell}>{cell}</MTable.Cell>
            ))}
          </MTable.Row>
        </MTable.Head>
        <MTable.Body>
          {tasks.message?.rows?.map((task) => (
            <MTable.Row key={task.id}>
              {tableBodyCells(task).map((cell) => (
                <MTable.Cell>{cell}</MTable.Cell>
              ))}
              <MTable.Cell
                sx={{
                  color: statusColor(task.status),
                }}
              >
                {translateStatus(task.status)}
              </MTable.Cell>
            </MTable.Row>
          ))}
        </MTable.Body>
      </MTable.Root>
    </MTable.Container>
  );
}
