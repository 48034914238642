import axios from 'axios';
import moment from 'moment';
import routes from '../services/routes';

const {
  REACT_APP_API_URL,
  REACT_APP_SERPRO_URL,
} = process.env;

const SERPRO_AUTH_URL = 'https://gateway.apiserpro.serpro.gov.br/token';

async function serproAuth() {
  const params = new URLSearchParams();
  params.append('grant_type', 'client_credentials');

  const { message } = await routes.resident.getSerproAuth();

  const request = {
    method: 'post',
    maxBodyLength: Infinity,
    url: SERPRO_AUTH_URL,
    data: params.toString(),
    headers: {
      Authorization: message,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const { data } = await axios.request(request);
    return data.access_token;
  } catch (error) {
    return undefined;
  }
}

const getAuthorization = async () => (REACT_APP_API_URL === 'lvh.me'
  ? '06aef429-a981-3ec5-a1f8-71d38d86481e'
  : serproAuth());

export default async function consultCpf(cpf) {
  const authorization = await getAuthorization();
  try {
    const response = await axios.get(`${REACT_APP_SERPRO_URL}/${cpf}`, {
      headers: {
        Authorization: `Bearer ${authorization}`,
      },
    });

    const { nome: name, nascimento, ni } = response.data;

    const birthDate = moment(nascimento, 'DDMMYYYY').format('YYYY-MM-DD');

    return { name, birthDate, ni };
  } catch (error) {
    return undefined;
  }
}
