import { Box, Typography, styled } from '@mui/material';

export const StyledBoxFeaturesContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
`;

export const StyledBoxFeatureOptions = styled(Box)`
  display: flex;
  width: calc(50% - 5px);
  padding: 12px;
  border-radius: 6px;
  gap: 12px;
  align-items: center;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
`;

export const StyledTypographyFeatureName = styled(Typography)`
  letter-spacing: 0.12px;
  color: var(--grey-variation-dark-grey, #474750);
  font-size: 14px;
`;
