/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ProfileAdd } from '../../../../assets/icons/profile-add.svg';
import { ImageBox } from '../CreateOrEditResident/style';
import ImagePicker from '../../../../components/ImagePicker';
import { StyledResidentImageBox } from './style';
import BenvoDeleteModal from '../../../../components/BenvoDeleteModal';

export default function AddResidentImage({
  onChange, currentImage, profileImage, isEdit,
}) {
  const [imagePreview, setImagePreview] = useState(currentImage);

  const handleImage = ({ data, preview }) => {
    onChange(data);
    setImagePreview(preview);
  };

  const handleDelete = () => {
    onChange(null);
    setImagePreview(null);
  };

  useEffect(() => {
    if (!profileImage && !isEdit) {
      setImagePreview(null);
    }
  }, [profileImage]);

  if (!imagePreview) {
    return (
      <ImagePicker onChange={handleImage}>
        <ImageBox>
          <ProfileAdd />
        </ImageBox>
      </ImagePicker>
    );
  }

  return (
    <label htmlFor="delete-modal" style={{ cursor: 'pointer', height: '100%' }}>
      <StyledResidentImageBox>
        <img
          src={imagePreview}
          alt="foto da carteirinha"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
        <BenvoDeleteModal
          id="delete-modal"
          title="Apagar imagem"
          description="Deseja apagar a imagem do residente?"
          buttonText="Excluir imagem"
          handleDelete={handleDelete}
          imageUrl="/images/profile-remove.svg"
          iconStyle={{
            position: 'absolute',
            inset: 0,
            margin: 'auto',
            cursor: 'pointer',
            background: 'none',
          }}
        />
      </StyledResidentImageBox>
    </label>
  );
}

AddResidentImage.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentImage: PropTypes.string,
  profileImage: PropTypes.string,
  isEdit: PropTypes.bool,
};

AddResidentImage.defaultProps = {
  currentImage: null,
  isEdit: false,
  profileImage: null,
};
