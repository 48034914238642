import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Grid, Pagination } from '@mui/material';
import routes from '../../services/routes';
import { useAlert } from '../../context/alertContext';
import ContainerPage from '../../templates/containerPage/ContainerPage';
import SellerTable from './components/SellerTable/Index';
import CreateOrEditSeller from './components/CreateOrEditSeller/Index';

const TITLE = 'Registro de Fornecedores';
const DESCRIPTION = 'Aqui você pode visualizar, editar e cadastrar fornecedores.';

export default function Seller() {
  const [toggleCreated, setToggleCreated] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectSellerId, setSelectSellerId] = useState(null);
  const [editModalInfo, setEditModalInfo] = useState({
    open: false,
    seller: {},
  });

  const alert = useAlert();

  const listAllSellers = async (page = 0) => {
    const { message } = await routes.seller.listAllSellers({
      page,
    });
    setTotalPages(message.totalPages);
    return message.rows;
  };

  const deleteSeller = async (sellerId) => {
    try {
      await routes.seller.removeSeller({ sellerId });
      alert.success('Fornecedor removido com sucesso!');
      setSelectSellerId(sellerId);
    } catch (error) {
      alert.error('Erro ao remover fornecedor. Tente novamente mais tarde.');
    }
  };

  const { data: allSellers = [] } = useQuery({
    queryKey: ['seller', toggleCreated, selectSellerId, currentPage],
    queryFn: () => listAllSellers(currentPage),
    onError: () => {
      alert.warning('Não há fornecedores cadastrados.');
    },
    retry: 0,
    cacheTime: 0,
  });

  const handlePagination = () => (_event, value) => setCurrentPage(value - 1);

  return (
    <ContainerPage title={TITLE} description={DESCRIPTION}>
      <Grid container justifyContent="flex-end">
        <CreateOrEditSeller
          setToggleCreated={setToggleCreated}
          editModalInfo={editModalInfo}
          setEditModalInfo={setEditModalInfo}
        />
      </Grid>

      <SellerTable
        allSellers={allSellers}
        deleteSeller={deleteSeller}
        editModalInfo={editModalInfo}
        setEditModalInfo={setEditModalInfo}
        setSelectSellerId={setSelectSellerId}
      />
      <Pagination
        color="primary"
        sx={{
          '.MuiPagination-ul': {
            justifyContent: 'flex-end',
          },
        }}
        page={currentPage + 1}
        count={totalPages}
        onChange={handlePagination()}
      />
    </ContainerPage>
  );
}
