import { getTokenInfo } from './configAuth';

export function isSuperAdmin() {
  return getTokenInfo('privilege') === 'superadmin';
}

export function isAdmin() {
  return getTokenInfo('privilege') === 'admin';
}

export function getFeatures(feature) {
  if (getTokenInfo('features') === undefined) {
    return null;
  }
  return getTokenInfo('features')[feature];
}
