import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export default function BenvoCard({
  children, title, header, sx,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '6px',
        height: '100%',
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        overflow: 'hidden',
        flex: '1',
        minWidth: '720px',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          height: '9px',
          background:
            'linear-gradient(90deg, rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%)',
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '28px',
        }}
      >
        <Typography
          variant="h6"
          color="#474750"
          sx={{
            fontSize: '18px',
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '12px',
          }}
        >
          {header}
        </Box>
      </Box>

      {children}
    </Box>
  );
}
