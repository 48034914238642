import { Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ufOptions } from 'constants';
import React, { useState } from 'react';

export default function StateCoverageForm({ areaCoverage, setAreaCoverage }) {
  const [currentState, setCurrentState] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const [states, setStates] = useState(ufOptions.map((uf) => uf.label));

  const handleStateChange = (newValue) => {
    if (newValue) {
      setCurrentState(newValue);
      setDisabled(false);
    }
  };

  const handleAddState = () => {
    setStates(states.filter((state) => state !== currentState.label));
    setAreaCoverage({
      ...areaCoverage,
      states: [...areaCoverage.states, currentState.value],
      cities: [...areaCoverage.cities],
    });
    setCurrentState(null);
    setDisabled(true);
  };

  const handleDeleteState = (state) => {
    setStates([...states, state]);
    setAreaCoverage({
      ...areaCoverage,
      states: areaCoverage.states.filter((item) => item !== state),
      cities: [...areaCoverage.cities],
    });
  };

  const rows = areaCoverage.states.map((state) => ({
    id: state,
    name: ufOptions.find((uf) => uf.value === state).label,
  }));

  const columns = [
    {
      field: 'name',
      headerName: 'Estado',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => handleDeleteState(params.row.id)}
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Typography color="primary" variant="h6">
          Cobertura Estadual
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            mt: 1,
            mb: 2,
          }}
        >
          Caso opte por pular essa estapa, a próxima será obrigatória.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Autocomplete
          autoComplete
          options={states}
          onChange={(_, newValue) => {
            handleStateChange(ufOptions.find((uf) => uf.label === newValue));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              label="Selecionar Estados"
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <Button
          disabled={isDisabled}
          variant="contained"
          onClick={handleAddState}
        >
          Adicionar
        </Button>
      </Grid>

      <Grid item xs={12} sm={12}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          localeText={{
            noRowsLabel: 'Nenhum estado adicionado à cobertura.',
          }}
          disableSelectionOnClick
          hideFooterPagination
          hideFooter
          sx={{
            height: 250,
          }}
        />
      </Grid>
    </>
  );
}
