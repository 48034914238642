import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 80px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: none;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 0;

  color: #474750;

font-size: 16px;
font-weight: 500;
line-height: 115%; /* 18.4px */
letter-spacing: 0.024px;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
