import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import {
  BurgerIconContainer, BurgerMenuContainer, Divider, MenuContainer, NavigationList, SettingsList,
} from './style';
import PATHS from '../../../../constants/applicationPaths';
import BenvoLogo from '../../../../assets/topbar/burgerMenu-logo.webp';
import routes from '../../../../services/routes';
import { clearToken } from '../../../../utils/configAuth';
import { BENVO_FOOTER } from '../../../../constants';

const menuItems = [{
  name: 'Home',
  link: PATHS.home,
},
{
  name: 'Residentes',
  link: PATHS.residents.root,
},
];

export default function BurgerMenu() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const navigate = useNavigate();
  const posthog = usePostHog();

  const handleLogout = () => {
    routes.auth.logout(); // Doesn't need to be async, let server handle the logout
    posthog?.reset(true);
    clearToken();
    navigate('/login');
  };

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const handleNavigate = () => {
    setMenuIsOpen(false);
  };

  return (
    <>
      <BurgerMenuContainer onClick={toggleMenu} open={menuIsOpen}>
        <BurgerIconContainer open={menuIsOpen}>
          <div />
          <div />
          <div />
        </BurgerIconContainer>
      </BurgerMenuContainer>

      <MenuContainer open={menuIsOpen}>
        <img src={BenvoLogo} alt="Logo da Benvo" />
        <NavigationList>
          {menuItems.map((item) => (
            <li key={item.name}>
              <Link
                onClick={handleNavigate}
                to={item.link}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </NavigationList>

        <Divider />

        <SettingsList>
          <li>
            <Link
              onClick={handleNavigate}
              to={PATHS.changePassword}
            >
              Alterar senha
            </Link>
          </li>
          <li>
            <button
              type="button"
              onClick={handleLogout}
            >
              Sair
            </button>
          </li>
        </SettingsList>

        <p>{BENVO_FOOTER}</p>
      </MenuContainer>
    </>
  );
}
