import { Box, styled } from '@mui/material';

export const CardContainer = styled('label')`
  display: flex;
  width: 100%;
  max-width: 398px;
  padding: 22px;
  align-items: center;
  gap: 24px;
  border-radius: 6px;
  background: #fff;

  cursor: pointer;
`;

export const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 3px;

  h5 {
    width: 100%;
    max-width: 173.361px;
    color: #474750;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.024px;
  }

  p {
    width: 100%;
    max-width: 310px;
    color: #96969e;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.025px;
  }
`;
