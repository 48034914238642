/* eslint-disable max-len */
import React from 'react';

export default function OrderIcon({ active }) {
  if (active) {
    return (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 4.07649V4.09252C18.9993 4.07766 19 4.07116 19 4.07649ZM18.63 4.11649C18.7945 4.11649 18.9377 4.17838 19.0662 4.33009C19.3637 4.99427 19.5 5.97041 19.5 7.44649V17.3665C19.5 18.858 19.3609 19.8391 19.0568 20.5036C18.9367 20.6394 18.7992 20.6965 18.63 20.6965C18.3097 20.6965 17.7939 20.4714 17.1357 19.7655C16.1029 18.6573 14.4766 18.7481 13.5702 19.9562C13.5702 19.9563 13.5701 19.9564 13.57 19.9565L12.5607 21.2955L12.5607 21.2955L12.5582 21.2989C12.2385 21.7305 11.8494 21.9065 11.5 21.9065C11.1506 21.9065 10.7615 21.7305 10.4418 21.2989L10.4418 21.2989L10.4389 21.2951L9.42051 19.9472C9.42021 19.9468 9.41991 19.9464 9.41962 19.946C8.52351 18.7483 6.91263 18.6601 5.88146 19.7479L5.87644 19.753L5.8704 19.759L5.86457 19.7652C5.33115 20.3364 4.88197 20.6034 4.55709 20.6779C4.29295 20.7384 4.1021 20.6786 3.94209 20.5012C3.6388 19.8367 3.5 18.8562 3.5 17.3665V7.44649C3.5 5.97001 3.63634 4.99375 3.93404 4.32956C4.09201 4.14118 4.28352 4.07544 4.55487 4.13671C4.88044 4.21023 5.33165 4.47711 5.86457 5.04776L5.8704 5.05401L5.87645 5.06005L5.88148 5.06508C6.91267 6.15296 8.52361 6.06464 9.41969 4.86691C9.41996 4.86655 9.42023 4.86618 9.42051 4.86582L10.4389 3.51791L10.439 3.51792L10.4418 3.51411C10.7615 3.0825 11.1506 2.90649 11.5 2.90649C11.8494 2.90649 12.2385 3.0825 12.5582 3.51411L12.5582 3.51412L12.5607 3.51745L13.57 4.85649C13.5701 4.85667 13.5703 4.85685 13.5704 4.85703C14.4768 6.06492 16.103 6.15564 17.1357 5.04748C17.7939 4.34154 18.3097 4.11649 18.63 4.11649ZM4 4.09252V4.07649C4 4.07116 4.00067 4.07766 4 4.09252ZM8 15.4065H14C14.6861 15.4065 15.25 14.8426 15.25 14.1565C15.25 13.4704 14.6861 12.9065 14 12.9065H8C7.31386 12.9065 6.75 13.4704 6.75 14.1565C6.75 14.8426 7.31386 15.4065 8 15.4065ZM8 11.9065H16C16.6861 11.9065 17.25 11.3426 17.25 10.6565C17.25 9.97035 16.6861 9.40649 16 9.40649H8C7.31386 9.40649 6.75 9.97035 6.75 10.6565C6.75 11.3426 7.31386 11.9065 8 11.9065Z" fill="#4A397F" stroke="#4A397F" />
      </svg>
    );
  } return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 7.44656V17.3666C20 18.8866 19.86 19.9666 19.5 20.7366C19.5 20.7466 19.49 20.7666 19.48 20.7766C19.26 21.0566 18.97 21.1966 18.63 21.1966C18.1 21.1966 17.46 20.8466 16.77 20.1066C15.95 19.2266 14.69 19.2966 13.97 20.2566L12.96 21.5966C12.56 22.1366 12.03 22.4066 11.5 22.4066C10.97 22.4066 10.44 22.1366 10.04 21.5966L9.02002 20.2466C8.31002 19.2966 7.05999 19.2266 6.23999 20.0966L6.22998 20.1066C5.09998 21.3166 4.10002 21.4966 3.52002 20.7766C3.51002 20.7666 3.5 20.7466 3.5 20.7366C3.14 19.9666 3 18.8866 3 17.3666V7.44656C3 5.92656 3.14 4.84655 3.5 4.07655C3.5 4.06655 3.50002 4.05655 3.52002 4.04655C4.09002 3.31655 5.09998 3.49655 6.22998 4.70655L6.23999 4.71656C7.05999 5.58656 8.31002 5.51656 9.02002 4.56656L10.04 3.21656C10.44 2.67656 10.97 2.40656 11.5 2.40656C12.03 2.40656 12.56 2.67656 12.96 3.21656L13.97 4.55656C14.69 5.51656 15.95 5.58655 16.77 4.70655C17.46 3.96655 18.1 3.61655 18.63 3.61655C18.97 3.61655 19.26 3.76655 19.48 4.04655C19.5 4.05655 19.5 4.06655 19.5 4.07655C19.86 4.84655 20 5.92656 20 7.44656Z" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 10.6566H16" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 14.1566H14" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
