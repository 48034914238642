/* eslint-disable import/prefer-default-export */
import { Box, styled } from '@mui/material';

export const SearchResultContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 8px;
  background: #f5f5f7;

  label:nth-of-type(even) {
    background-color: #fefefe;
    border: 1px solid var(--grey-variation-lightest-grey, #f5f5f7);
  }

  label:nth-of-type(odd) {
    border: 1px solid transparent;
  }
`;
