import {
  Dialog, DialogActions, DialogContent, styled,
} from '@mui/material';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 378px;
    padding: 22px;
    display: flex;
    border-radius: 6px;
    background: #fff;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    flex-shrink: 0;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  }

  .MuiBackdrop-root {
    background-color: #0000001a;
    backdrop-filter: blur(1px);
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  width: 100%;
  overflow: hidden;
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;
