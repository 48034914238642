import Joi from 'joi';
import moment from 'moment';

const oneYearAgo = moment().subtract(1, 'years');

const createNewResident = Joi.object({
  profileImage: Joi.alternatives().try(
    Joi.string().base64(),
    Joi.string().uri(),
  ).allow(null),
  name: Joi.string().min(3).required().messages({
    'string.empty': 'O nome é obrigatório',
    'string.min': 'Digite um nome válido',
  }),
  gender: Joi.valid('M', 'F', 'other').required().messages({
    'any.only': 'Você deve selecionar um gênero',
    'any.required': 'O gênero é obrigatório',
  }),
  cpf: Joi.string().length(14).required().messages({
    'string.empty': 'O CPF é obrigatório',
    'string.length': 'O CPF deve ter 11 caracteres',
  }),
  birthDate: Joi.date().max(oneYearAgo).required().messages({
    'date.required': 'A data de nascimento é obrigatória',
    'date.empty': 'A data de nascimento é obrigatória',
    'date.base': 'A data fornecida não é válida',
    'date.max': 'A data deve ser anterior à um ano.',
  }),
  isInsured: Joi.boolean().required(),
  planCard: Joi.alternatives().try(
    Joi.string().base64(),
    Joi.string().uri(),
  ).allow(null),
});

export default {
  createNewResident,
};
