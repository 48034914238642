import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Loading from '../../../../components/Loading/Loading';
import routes from '../../../../services/routes';

function CreatePrescriptionItem({
  isOpen,
  onClose,
  medication,
  resident,
  handlePrescription,
  onFailure,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    residentName: resident.label,
    startDate: new Date(),
    endDate: null,
    medicineId: medication.id,
    quantity: '',
    timesPerDay: '',
    dosage: '',
  });

  const getInformations = async () => {
    setIsLoading(true);

    try {
      const { medicineId } = formValues;
      const { message } = await routes.medicine.findProductById({ medicineId });

      setFormValues((prev) => ({ ...prev, ...message }));
    } catch {
      onFailure(
        'Não foi possível carregar as informações necessárias para a criação da prescrição',
      );
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = ({ target }) => {
    setFormValues((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const values = {
      medicineId: formValues.medicineId,
      quantity: formValues.quantity,
      timesPerDay: formValues.timesPerDay,
      dosage: formValues.dosage,
      observation: formValues.observation,
      substance: formValues.substance,
      medicationType: formValues.medicineClassification,
      startDate: moment(formValues.startDate).format('YYYY-MM-DD'),
      class: formValues.class,
      controlType: formValues.controlType,
      unit: formValues.unit,
    };

    if (formValues.endDate) {
      values.endDate = moment(formValues.endDate).format('YYYY-MM-DD');
    }

    handlePrescription(values);
    onClose();
  };

  useEffect(() => {
    getInformations();
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ width: '60%' }}>
        <Paper sx={{ padding: 4 }}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Typography variant="h2" fontSize="1.4rem" margin="20px 0">
                {`Prescrição do medicamento ${formValues.substance} para o residente ${formValues.residentName}`}
              </Typography>

              <form onSubmit={handleSubmit}>
                <Grid container spacing={{ xs: 1, sm: 3, md: 3 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Residente"
                      value={formValues.residentName}
                      name="residentName"
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={formValues.name}
                      label="Medicamento"
                      name="name"
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={formValues.substance}
                      label="Substância"
                      name="substance"
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={formValues.controlType}
                      label="Tipo de controle"
                      name="controlType"
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={{ xs: 1, sm: 1 }}>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          value={formValues.quantity}
                          label="Quandidade"
                          name="quantity"
                          onChange={handleChange}
                          fullWidth
                          required
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          value={formValues.unit}
                          label="Unidade"
                          name="unit"
                          onChange={handleChange}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={{ xs: 1, sm: 1 }}>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          type="number"
                          label="Usos diários"
                          value={formValues.timesPerDay}
                          name="timesPerDay"
                          onChange={handleChange}
                          required
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          value={formValues.dosage}
                          label="Dosagem"
                          name="dosage"
                          onChange={handleChange}
                          disabled
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={formValues.startDate}
                      label="Data de início do tratamento"
                      renderInput={(params) => (
                        <TextField {...params} fullWidth required />
                      )}
                      onChange={(value) => handleChange({ target: { name: 'startDate', value } })}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={formValues.endDate}
                      label="Data final do tratamento (opcional)"
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      onChange={(value) => handleChange({ target: { name: 'endDate', value } })}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Observação"
                      value={formValues.observation}
                      name="observation"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Button
                      type="submit"
                      variant="text"
                      color="cancel"
                      onClick={onClose}
                      fullWidth
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Salvar prescrição
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Paper>
      </Box>
    </Modal>
  );
}

export default CreatePrescriptionItem;
