import { Box, Typography, styled } from '@mui/material';

export const StyledBoxContainer = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLeftBox = styled(Box)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px;
  background-color: #F5F5F7;
`;

export const StyledLoginFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 436px;
  height: 400px;
  background-color: #FFFFFF;
  border-radius: 6px;
`;

export const StyledTypographyFooter = styled(Typography)`
  position: absolute;
  bottom: 24px;
  left: 24px;
  color: #474750;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
`;

export const StyledRightBox = styled(Box)`
  position: relative;
  height: 100vh;
  max-width: 1325px;
  background-image: url(${(props) => props['data-image']});
  background-repeat: no-repeat;
  background-size: cover;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 9px;
    background: linear-gradient(rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%);
  }
`;
