import React, { useState, createContext, useMemo } from 'react';

export const residentPageContext = createContext();

export default function ResidentPageProvider({ children }) {
  const [tab, setTab] = useState('information');

  const context = useMemo(() => ({
    tab,
    setTab,
  }));

  return (
    <residentPageContext.Provider value={context} displayName="residentPageContext">
      {children}
    </residentPageContext.Provider>
  );
}
