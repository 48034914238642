/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { redirect, useNavigate, useParams } from 'react-router';
import { KJUR } from 'jsrsasign';
import { clearToken, insertToken } from '../../utils/configAuth';
import Loading from '../../components/Loading/Loading';
import {
  ErrorBox, LayoutBox, LoadingBox, TypographyStyled,
} from './style';
import { useAlert } from '../../context/alertContext';
import PATHS from '../../constants/applicationPaths';

export default function Impersonate() {
  const alert = useAlert();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  const { jwt } = useParams('jwt');

  useEffect(() => {
    clearToken();
    try {
      // Verifies the token
      KJUR.jws.JWS.parse(jwt);
      // Stores the token
      insertToken(jwt);
      alert.success('Perfeito! Você será redirecionado em breve');
      setTimeout(() => {
        navigate(PATHS.home);
      }, 2000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  return (
    <LayoutBox>
      {isLoading
        ? (
          <LoadingBox>
            <Loading />
            <TypographyStyled>Aguarde enquanto configuramos o acesso!</TypographyStyled>
          </LoadingBox>
        )
        : (
          <ErrorBox>
            <img src="/images/delete-dialog.png" alt="Erro" />
            <TypographyStyled>Ocorreu um erro</TypographyStyled>
            <TypographyStyled>Por favor verifique se você copiou o link completo e tente novamente.</TypographyStyled>
            <TypographyStyled>Caso o erro persista, peça para o administrador gerar um novo acesso.</TypographyStyled>
          </ErrorBox>
        )}
    </LayoutBox>
  );
}
