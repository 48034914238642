import {
  Box, IconButton, TextField, Typography, styled,
} from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const ListNameInput = styled(TextField)`
  width: 502px;
  height: 40px;
  border-radius: 8px;
  background: #f5f5f7;
  outline: none;
  border: none;

  .MuiInputBase-root {
    border: none;
    height: 40px;
    padding: 10px 8px;
    outline: none;

    input {
      padding: 0;
      font-size: 12px;

      &::placeholder {
        color: var(--grey-variation-dark-grey, #474750);
        opacity: 1;
      }
    }

    fieldset {
      border: none;
    }
  }
`;

export const InfoListAndSearchContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
`;

export const SearchContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const SelectedContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`;

export const Title = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  /* Label */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const ListContainer = styled(Box)`
  display: flex;
  height: auto;
  max-height: 221.95px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  //background: #f5f5f7;
  overflow-y: auto;
  width: 100%;

  label:nth-of-type(odd) {
    background: #f5f5f7;
  } label:nth-of-type(even) {
    background: #fff;
  }
`;

export const ListItem = styled('label')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
  cursor: pointer;
`;

export const ListTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  /* Label */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
  user-select: none;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 12px;
  width: 100%;
  flex: 1;
`;
