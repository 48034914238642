import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import Grid from '@mui/material/Grid';

import Loading from '../../components/Loading/Loading';
import './Prescription.scss';

import routes from '../../services/routes';
import { TODAY, THIRTTY_DAYS_AGO } from '../../utils/daysConstants';
import { useAlert } from '../../context/alertContext';
import CreatePrescription from './components/CreatePrescription';
import { getTokenInfo } from '../../utils/configAuth';
import TablePrescription from './components/TablePrescripition';
import Search from './components/Search/Search';

import ContainerPage from '../../templates/containerPage/ContainerPage';

const TITLE = 'Prescrição';
const DESCRIPTION = 'Aqui você pode fazer as suas prescrições.';

const ONE_HUNDRED_YEARS_AHEAD = new Date(TODAY.getFullYear() + 100, 0, 1);

export default function Prescription() {
  const alert = useAlert();
  const { prescription } = routes;
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const ilpiId = getTokenInfo('ilpiId');

  const listPrescription = async (params = {}) => {
    setIsLoading(true);
    try {
      const { message } = await prescription.findPrescriptionsByIlpi(
        { ilpiId },
        params,
      );
      setPrescriptionList(message.rows);
      setPagination(message.totalPages);
    } catch (error) {
      alert.info('Não foram encontradas prescrições');
      setPrescriptionList([]);
      setPagination(1);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listPrescription();
  }, []);

  const handlePagination = (page) => {
    const currentPage = page - 1;
    listPrescription(currentPage);
  };

  const handleInactivatePrescriptionSuccess = () => {
    alert.success('Prescrição removida com sucesso!');
    listPrescription();
  };

  return (
    <ContainerPage title={TITLE} description={DESCRIPTION}>
      <Grid container>
        <Search
          onSearch={(value) => listPrescription(value)}
          searchStartDate={THIRTTY_DAYS_AGO}
          searchEndDate={ONE_HUNDRED_YEARS_AHEAD}
        />

        <CreatePrescription
          listPrescription={listPrescription}
        />
      </Grid>

      {isLoading ? (
        <div style={{ marginTop: '48px' }}>
          <Loading />
        </div>
      ) : (
        <TablePrescription
          prescriptionList={prescriptionList}
          onInactivateSuccess={handleInactivatePrescriptionSuccess}
        />
      )}
      <br />
      <Pagination
        count={pagination}
        color="primary"
        sx={{
          '.MuiPagination-ul': {
            justifyContent: 'flex-end',
          },
        }}
        onChange={(event, value) => handlePagination(value)}
      />
    </ContainerPage>
  );
}
