import { Box, Typography, styled } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';

export const StyledBoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledBoxButtonsContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTypography = styled(Typography)`
  color: #474750;
  font-size: 16px;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: 0.024px;
`;

export const StyledTellInput = styled(MuiTelInput)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    font-size: 12px;
    & fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &:hover fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &.Mui-focused fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &.Mui-disabled fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }

    &.Mui-disabled input {
      color: var(--grey-variation-light-grey, #474750);
      -webkit-text-fill-color: var(--grey-variation-light-grey, #474750);
    }

    &.MuiInputBase-root {
      font-size: 12px;
      input {
        padding: 0px !important;

        &::placeholder {
          color: var(--grey-variation-light-grey, #474750);
        }
      }
    }
  }

  p {
    transition: all 0.2s ease-in-out;
    background: #fff !important;
    margin: 0px !important;
  }
`;
