import * as React from 'react';
import {
  AccordionDetails, AccordionSummary,
} from '@mui/material';
import {
  StyledAccordion, StyledTypography, TitleContainer,
} from './style';
import ArrowUp from '../../assets/residents/ArrowUp';

export default function BenvoAccordion({ content = [] }) {
  const defaultExpandedPanels = content
    .filter((item) => item.defaultExpanded)
    .map((item) => item.title);

  const [expanded, setExpanded] = React.useState(defaultExpandedPanels);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded((prevExpanded) => {
      if (isExpanded) {
        return [...prevExpanded, panel];
      }
      return prevExpanded.filter((item) => item !== panel);
    });
  };

  return (
    <div>
      {content.map(({ title, children }) => (
        <StyledAccordion
          key={title}
          expanded={expanded.includes(title)}
          onChange={handleChange(title)}
        >
          <AccordionSummary
            expandIcon={<ArrowUp />}
            aria-controls={`panel-${title}-content`}
            id={`panel-${title}-content`}
          >
            <TitleContainer>
              <StyledTypography>{title}</StyledTypography>
            </TitleContainer>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
}
