import React from 'react';
import moment from 'moment';
import {
  Box,
  Grid,
} from '@mui/material';
import {
  StyledBoxHeaderCard, StyledCard, StyledTypographyH1ResidentName,
  StyledTypography10px, StyledBoxForCardActions,
  StyledDivider, StyledBoxContentCard, StyledTypographyPDarkGrey12px, StyledBoxResidentDataExam,
} from '../styles';
import getAge from '../../../utils/getAge';
import getGender from '../../../utils/getGender';
import BenvoDeleteModal from '../../../components/BenvoDeleteModal';

function ConciergeCard({ data, rejectExam }) {
  const {
    services, residents, id, createdAt,
  } = data;

  return (
    <StyledCard>
      <StyledBoxHeaderCard>
        <Box>
          <StyledTypography10px>
            {`EXAMES SOLICITADOS EM ${moment(createdAt).format('DD/MM/YYYY')}:`}
          </StyledTypography10px>
        </Box>
        <StyledBoxForCardActions>
          <StyledDivider
            orientation="vertical"
            flexItem
          />
          <BenvoDeleteModal
            handleDelete={() => rejectExam(id)}
            buttonText="Rejeitar"
            title="Deseja rejeitar a solicitação?"
            description="Essa ação não poderá ser desfeita."
            variant="secondary"
            testid="reject-exam-button"
          >
            Rejeitar
          </BenvoDeleteModal>
        </StyledBoxForCardActions>
      </StyledBoxHeaderCard>
      <StyledBoxContentCard>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <StyledTypographyH1ResidentName>
              Exames
            </StyledTypographyH1ResidentName>
            {services.map((service) => (
              <StyledTypographyPDarkGrey12px
                key={`${service.id}`}
                align="justify"
                sx={{ mt: '8px' }}
              >
                {`- ${service.name}`}
              </StyledTypographyPDarkGrey12px>
            ))}
          </Grid>
          <Grid item sm={12} md={6}>
            <StyledTypographyH1ResidentName>
              Residentes
            </StyledTypographyH1ResidentName>
            {residents.map((resident) => (
              <StyledBoxResidentDataExam>
                <StyledTypographyPDarkGrey12px align="justify">
                  {resident.name}
                </StyledTypographyPDarkGrey12px>
                <StyledTypography10px>
                  {`${getGender(resident.gender)} - ${getAge(resident.birthDate)} anos`}
                </StyledTypography10px>
              </StyledBoxResidentDataExam>
            ))}
          </Grid>
        </Grid>
      </StyledBoxContentCard>
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }} />
    </StyledCard>
  );
}

export default ConciergeCard;
