import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, MenuItem, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { SEVEN_DAYS_AFTER, SEVEN_DAYS_AGO } from '../../../../utils/daysConstants';
import { createEmployeeOptions } from '../../../../utils/createOptionsForAutoComplete';
import { createTypeOptions } from '../../utils/createFormFields';

export default function TasksFilter({
  employees,
  handleSubmit,
  setCreateTaskModal,
}) {
  const [filterData, setFilterData] = useState({
    beginDate: SEVEN_DAYS_AGO,
    endDate: SEVEN_DAYS_AFTER,
  });

  const handleChange = ({ target: { name, value } }) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (field, value) => {
    setFilterData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const beforeSubmit = () => {
    const { beginDate, endDate, ...data } = filterData;
    handleSubmit({
      ...data,
      beginDate: beginDate ? moment(beginDate).format('YYYY-MM-DDT00:00') : '',
      endDate: endDate ? moment(endDate).format('YYYY-MM-DDT00:00') : '',
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <DesktopDatePicker
          label="Data Inicial"
          inputFormat="DD/MM/yyyy"
          value={filterData.beginDate}
          onChange={(value) => handleDateChange('beginDate', value)}
          renderInput={(params) => (
            <TextField fullWidth size="small" {...params} />
          )}
        />
      </Grid>
      <Grid item xs={12} md>
        <DesktopDatePicker
          label="Data Final"
          inputFormat="DD/MM/yyyy"
          value={filterData.endDate}
          onChange={(value) => handleDateChange('endDate', value)}
          renderInput={(params) => (
            <TextField fullWidth size="small" {...params} />
          )}
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <TextField
          select
          fullWidth
          size="small"
          name="type"
          label="Tipo"
          value={filterData.type || ''}
          onChange={handleChange}
        >
          <MenuItem value="">Todos</MenuItem>
          {createTypeOptions().map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={1}>
        <TextField
          select
          fullWidth
          name="employeeId"
          size="small"
          label="Cuidador"
          value={filterData.employeeId || ''}
          onChange={handleChange}
        >
          <MenuItem value="">Todos</MenuItem>
          {employees.length > 0
            && createEmployeeOptions(employees).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          id="title"
          name="title"
          label="Nome da tarefa ou do residente"
          variant="outlined"
          size="small"
          autoComplete="off"
          onChange={handleChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              beforeSubmit(filterData);
            }
          }}
        />
      </Grid>
      <Grid item display="flex">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            beforeSubmit(filterData);
          }}
        >
          Buscar
        </Button>
      </Grid>
      <Grid item xs="auto" md display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{ height: '100%' }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setCreateTaskModal((prev) => ({
            ...prev,
            open: true,
          }))}
        >
          Tarefa
        </Button>
      </Grid>
    </Grid>
  );
}
