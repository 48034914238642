import React, { useState, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useParams } from 'react-router';
import {
  StyledBox, StyledCard, StyledTypographyH1, StyledTypographyP, StyledTypographyPTitle,
} from './style';
import BenvoTextField from '../../components/BenvoTextField';
import BenvoPrimaryButton from '../../components/BenvoPrimaryButton';
import BenvoSecondaryButton from '../../components/BenvoSecondaryButton';
import routes from '../../services/routes';
import { useAlert } from '../../context/alertContext';
import alertMapper from '../../utils/alertMapper';
import benvo from '../../assets/logo-benvo.png';

const VALIDADOR_ITI_PAGE = 'https://validar.iti.gov.br/';

function SignatureChecker() {
  const alert = useAlert();
  const { fileId } = useParams();
  const [fileLink, setFileLink] = useState(null);
  const [wasError, setWasError] = useState(false);

  const fetchFileInfo = async () => {
    try {
      const { message } = await routes.publicRoutes.fetchSignedPDF({ fileId });
      setFileLink(message);
    } catch (error) {
      if (error.response?.status === 404) {
        setWasError(true);
        alert.error('O arquivo indicado não existe');
      } else alert[alertMapper(error.response?.status)]();
    }
  };

  useEffect(() => {
    fetchFileInfo();
  }, []);

  const copyLink = () => {
    alert.info('Link copiado para a área de transferência');
    navigator.clipboard.writeText(fileLink);
  };

  const goToValidadorIti = () => window.open(VALIDADOR_ITI_PAGE, '_blank');

  return (
    <StyledBox>
      <StyledCard>
        {wasError ? (
          <>
            <img src={benvo} alt="benvo-logo" width={200} style={{ alignSelf: 'center' }} />
            <StyledTypographyP sx={{ textAlign: 'justify' }}>
              O arquivo indicado não existe, verifique a URL. Se o erro persistir, entre em contato
              com a BENVO.
            </StyledTypographyP>
          </>
        ) : (
          <>
            <img src={benvo} alt="benvo-logo" width={200} style={{ alignSelf: 'center' }} />
            <StyledTypographyH1>
              Cheque a validade do documento
            </StyledTypographyH1>
            {fileLink ? (
              <BenvoTextField
                InputProps={{
                  defaultValue: fileLink,
                  readOnly: true,
                  style: { fontSize: '14px' },
                }}
              />
            ) : (
              <Skeleton variant="text" width="100%" height="40px" />
            )}
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
            >
              <BenvoSecondaryButton onClick={copyLink}>
                Copiar Link
              </BenvoSecondaryButton>
              <BenvoPrimaryButton onClick={goToValidadorIti}>
                Ir à validação
              </BenvoPrimaryButton>
            </Box>
            <Box>
              <StyledTypographyPTitle>
                Como funciona?
              </StyledTypographyPTitle>
              <StyledTypographyP>
                Após clicar em &quot;Copiar Link&quot;, abra uma nova janela no seu navegador e cole o link para
                confirmar se o documento em mãos corresponde ao digital.
              </StyledTypographyP>
              <StyledTypographyPTitle>
                O documento é o mesmo! Agora, como validar a assinatura digital?
              </StyledTypographyPTitle>
              <StyledTypographyP>
                Clique em &quot;Ir à validação&quot; para ser redirecionado à página oficial de validação de assinaturas
                eletrônicas do Governo Federal (validar.iti.gov.br). Quando a página carregar, clique no botão
                &quot;Colar URL&quot;, cole o link copiado anteriormente e clique em &quot;Enviar&quot;. Se tudo estiver
                correto, você verá uma página confirmado a assinatura e a identificação do signatário.
              </StyledTypographyP>
              <StyledTypographyPTitle>
                IMPORTANTE!
              </StyledTypographyPTitle>
              <StyledTypographyP>
                O link fornecido tem validade de 5 (cinco) minutos. Qualquer tentativa de validação após esse período
                não terá sucesso. Se precisar verificar a assinatura novamente e o link tiver expirado, basta atualizar
                a página para gerar um novo link.
              </StyledTypographyP>
            </Box>
          </>
        )}
      </StyledCard>
    </StyledBox>
  );
}

export default SignatureChecker;
