import React, { useState } from 'react';
import {
  Box, CircularProgress, Typography,
} from '@mui/material';
import BenvoDialog from '../../../../components/Dialog';
import { StyledIconButton, TextContainer } from './style';
import BenvoButton from '../../../../components/BenvoButton/style';
import LinkIcon from '../../../../assets/icons/LinkIcon';
import routes from '../../../../services/routes';
import { useAlert } from '../../../../context/alertContext';
import BenvoTooltip from '../../../../components/BenvoTooltip';

function GenerateImpersonate({ userId }) {
  const alert = useAlert();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => setOpenModal(true);
  const handleClickClose = () => setOpenModal(false);

  const generateImpersonate = async () => {
    setIsLoading(true);
    try {
      const { message } = await routes.superadmin.generateImpersonate('', { userId });
      navigator.clipboard.writeText(message);
      alert.success('Link de acesso gerado com sucesso e já copiado para a área de transferência!');
      handleClickClose();
    } catch (error) {
      alert.error('Ocorreu um erro, tente novamente mais tarde!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <BenvoDialog.Trigger>
        <BenvoTooltip title="Gerar acesso personificado" placement="top" arrow>
          <StyledIconButton type="button" onClick={handleClickOpen} data-testid={`impersonate-${userId.id}`}>
            <img src="/images/key-icon.svg" alt="excluir lista" />
          </StyledIconButton>
        </BenvoTooltip>
      </BenvoDialog.Trigger>
      <BenvoDialog.Root onClose={handleClickClose} open={openModal}>
        <BenvoDialog.Content>
          <img src="/images/emptyList.png" alt="Gerar Acesso" width="200px" />
          <TextContainer>
            <Typography variant="h6">
              Deseja gerar acesso personificado desta ILPI?
            </Typography>
            <Typography variant="h5">
              Clique em copiar e um link será copiado à área de transferência.
              Compartilhe ou cole o link na barra de endereços.
            </Typography>
          </TextContainer>
        </BenvoDialog.Content>
        <BenvoDialog.Actions>
          <BenvoButton variant="secondary" onClick={handleClickClose} disabled={isLoading}>
            Cancelar
          </BenvoButton>
          <BenvoButton
            onClick={() => generateImpersonate()}
            disabled={isLoading}
            sx={{ width: '165px' }}
          >
            {isLoading
              ? (
                <CircularProgress size={20} />
              )
              : (
                <>
                  Copiar
                  <LinkIcon />
                </>
              )}
          </BenvoButton>
        </BenvoDialog.Actions>
      </BenvoDialog.Root>
    </Box>
  );
}

export default GenerateImpersonate;
