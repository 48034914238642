import React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import ResidentPageProvider from './context/residentPageContext';
import defaultTheme from './themes/defaultTheme';
import { AlertProvider } from './context/alertContext';
import Routers from './routers';

import './app.scss';
import { ModalOfferProvider } from './components/BenvoModuleOfferModal';

function App() {
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <ModalOfferProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <QueryClientProvider client={queryClient}>
              <AlertProvider>
                <ResidentPageProvider>
                  <Routers />
                </ResidentPageProvider>
              </AlertProvider>
            </QueryClientProvider>
          </LocalizationProvider>
        </ModalOfferProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
