import {
  Box,
  Checkbox,
  Divider,
  Typography,
  styled,
} from '@mui/material';

export const ContainerResidentInformation = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  overflow-y: auto;
`;

export const ContainerTitleSearchAndActions = styled(Box)`
  display: flex;
  width: 100%;
  padding-bottom: 16px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    padding-bottom: 12px;
  }

  h2 {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.027px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const ContainerSearchAndActions = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column-reverse !important;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ContainerActions = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 12px;

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const ContainerDivider = styled(Divider)`
  background-color: #eaebec;
  opacity: 0.4;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ResidentListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

// Novo layout

export const Container = styled(Box)`
  display: flex;
  gap: 23px;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
`;

export const BenvoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 25px 16px 16px;
  border-radius: 6px;
  height: 100%;
  position: relative;
  overflow: hidden;
  flex: 1;
  min-width: 720px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 9px;
    background: linear-gradient(90deg, rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%);
  }

  @media (max-width: 768px) {
    min-width: auto;
    width: 100%;
    background-color: transparent;
    padding: 0 16px 16px;

    &::before {
      display: none;
    }
  }
`;

export const ContainerTitle = styled(Typography)`
  color: #474750;
  font-size: 20px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.03px;
  margin-bottom: 12px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const CardsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    gap: 0;
    margin-top: 28px;
  }
`;

export const NewResidentCard = styled(Box)`
  width: 100%;
  max-width: 348px;
  cursor: pointer;

  display: flex;
  padding: 22px;
  align-items: center;
  gap: 24px;

  border-radius: 6px;
  background: #fff;
`;

export const NewResidentCardTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  h3 {
    color: #474750;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.024px;
  }

  p {
    color: #96969e;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.025px;
  }
`;

export const CreateResidentDesktopContainer = styled(Box)`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CreateResidentMobileContainer = styled(Box)`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const SendAllListsContainer = styled(Box)`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CheckboxContainer = styled('label')`
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 14px;
  cursor: pointer;
  user-select: none;
  gap: 12px;

  p {
    color: #474750;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
    letter-spacing: 0.12px;
    margin: 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  padding: 0;
  svg {
    fill: ${({ checked }) => (checked ? '#4A397F' : '#d4d4da')};
  }
`;
