import {
  Box, DialogActions, DialogContent,
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ContentContainer, Description, StyledDialog, StyledIconButton, Title,
} from './styles';
import { ReactComponent as TrashButtonIcon } from '../../assets/icons/trash-icon-outline.svg';
import BenvoButton from '../BenvoButton/style';
import BenvoTooltip from '../BenvoTooltip';

export default function BenvoDeleteModal(props) {
  const {
    handleDelete,
    title,
    description,
    buttonText,
    imageUrl,
    children,
    variant,
    testid,
    tooltipTitle,
    tooltipDisabledTitle,
    iconStyle,
    id,
    dialogImage,
    dialogButton,
    disabled = false,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      {children ? (
        <BenvoButton id={id} variant={variant} type="button" onClick={handleClickOpen} data-testid={testid}>
          {children}
        </BenvoButton>
      ) : (
        <BenvoTooltip title={disabled ? tooltipDisabledTitle : (tooltipTitle ?? title)} placement="top" arrow>
          <StyledIconButton id={id} style={iconStyle} type="button" onClick={handleClickOpen} data-testid={testid} disabled={disabled}>
            <img src={imageUrl} alt="excluir lista" />
          </StyledIconButton>
        </BenvoTooltip>
      )}
      <StyledDialog open={open} onClose={handleClose} data-testid="dialog-box">
        <DialogContent
          sx={{
            padding: '0px',
          }}
        >
          <ContentContainer>
            <img src={dialogImage} alt={title} />
            <Box>
              <Title>{title}</Title>
              <Description>{description}</Description>
            </Box>
          </ContentContainer>
        </DialogContent>
        <DialogActions>
          <BenvoButton variant="secondary" onClick={handleClose}>
            Cancelar
          </BenvoButton>
          <BenvoButton
            variant="primary"
            data-testid="delete-button"
            onClick={handleDelete}
            sx={{
              px: '32px',
            }}
            startIcon={dialogButton}
          >
            {buttonText}
          </BenvoButton>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
}

BenvoDeleteModal.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  imageUrl: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  tooltipTitle: PropTypes.string,
  tooltipDisabledTitle: PropTypes.string,
  iconStyle: PropTypes.objectOf(PropTypes.string),
  id: PropTypes.string,
  children: PropTypes.string,
  dialogImage: PropTypes.string,
  dialogButton: PropTypes.element,
};

BenvoDeleteModal.defaultProps = {
  title: 'Titulo',
  description: 'Descrição',
  buttonText: 'Confirmar',
  imageUrl: '/images/trash-icon.svg',
  tooltipTitle: null,
  tooltipDisabledTitle: null,
  children: null,
  variant: 'primary',
  id: null,
  iconStyle: null,
  dialogImage: '/images/delete-dialog.png',
  dialogButton: <TrashButtonIcon />,
};
