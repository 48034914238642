import { Box, styled, Typography } from '@mui/material';

export const PageContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 80px;
  min-width: 1180px;
`;

export const StepperContainer = styled(Box)`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
`;

export const Title = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.027px;
`;

export const Subtitle = styled(Typography)`
  width: 250px;
  color: var(--grey-variation-normal-grey, #96969e);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.035px;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;

  a {
    text-decoration: none;
  }
`;
