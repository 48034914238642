import {
  Box, Card, Typography, styled,
} from '@mui/material';

export const StyledBox = styled(Box)`
align-items: center;
background-image: linear-gradient(135deg, #D55448 0%, #D55448 33%, #77224F 66%, #1E183C 100%);
display: flex;
height: 100%;
justify-content: center;
width: 100%,
`;

export const StyledCard = styled(Card)`
display: flex;
flex-direction: column;
gap: 20px;
padding: 20px;
max-width: 475px;
min-width: 375px;
`;

export const StyledTypographyH1 = styled(Typography)`
color: var(--grey-variation-dark-grey, #474750);
font-size: 24px;
font-weight: 700;
line-height: 130%;
letter-spacing: 0.024px;
`;

export const StyledTypographyP = styled(Typography)`
color: var(--grey-variation-normal-grey, #96969E);
font-size: 14px;
line-height: 140%;
letter-spacing: 0.025px;
padding-bottom: 10px;
`;

export const StyledTypographyPTitle = styled(Typography)`
color: var(--grey-variation-normal-grey, #96969E);
font-size: 14px;
font-weight: 700;
line-height: 140%;
letter-spacing: 0.025px;
`;
