import Joi from 'joi';

const createNewShoppingList = Joi.object({
  name: Joi.string().min(3).required().messages({
    'string.empty': 'O nome do produto é obrigatório',
    'string.min': 'Digite um nome válido',
  }),
  quantity: Joi.string().min(1).required().messages({
    'string.empty': 'A quantidade é obrigatória',
    'string.min': 'Digite uma quantidade válida',
  }),
  unit: Joi.valid('un', 'kg', 'g', 'l', 'ml', 'mg').required().messages({
    'any.only': 'Você deve selecionar uma unidade',
  }),
});

export default {
  createNewShoppingList,
};
