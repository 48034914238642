import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 30.53px;
  min-width: 1255.84px;
  max-width: 1255.84px;
  margin: 0 auto;

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
    align-items: center;
    zoom: 100% !important;
  }

  @media (max-height: 829px) {
    zoom: 95%;
  }
  @media (max-height: 790px) {
    zoom: 90%;
  }
  @media (max-height: 756px) {
    zoom: 85%;
  }
  @media (max-height: 720px) {
    zoom: 80%;
  }
`;

export const CardsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 18px;
  }
`;

export const VersionContainer = styled(Box)`
  color: #474750;
  font-size: 10px;
  font-weight: 400;
  line-height: 146%; /* 14.6px */
  letter-spacing: 0.025px;

  display: flex;
  justify-content: space-between;

  div{
    display: flex;
    gap: 40px;

    a{
      color: #474750;
      text-decoration: none;
    }
  }
`;

export const ResidentsAndFeaturesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 22.18px;

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

export const FeaturesContainer = styled(Box)`
  display: flex;
  gap: 19.79px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 18px;
  }
`;
