import Joi from 'joi';
import { matchIsValidTel } from 'mui-tel-input';

const createNewReferenceContact = Joi.object({
  id: Joi.string().uuid(),
  name: Joi.string().min(3).required().messages({
    'any.required': 'O nome é obrigatório',
    'string.empty': 'O nome é obrigatório',
    'string.min': 'O nome deve conter no mínimo 3 caracteres',
  }),
  phone: Joi.string().custom((value, helpers) => {
    if (!matchIsValidTel(value)) {
      return helpers.message('O telefone do responsável deve ser válido');
    }
    return value;
  }).required().messages({
    'any.required': 'Você deve informar um telefone válido',
    'string.empty': 'Você deve informar um telefone válido',
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } }).messages({
      'string.email': 'Digite um email válido',
    }).allow('')
    .allow(null),
});

export default {
  createNewReferenceContact,
};
