import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-button-icon-outline.svg';
import StyledSearchField from './style';

/**
 * @param {string | number} minWidth - The min width of the search field in px.
 * @param {string} placeholder - The placeholder of the search field.
 */
export default function BenvoSearchField({
  searchValue, onClick, placeholder = 'Pesquisar', minWidth = '200px', sx = {}, ...props
}) {
  return (
    <StyledSearchField
      hiddenLabel
      value={searchValue}
      minWidth={minWidth}
      placeholder={placeholder}
      sx={{
        ...sx,
      }}
      InputProps={{
        endAdornment: <SearchIcon style={{ cursor: 'pointer' }} onClick={onClick} data-testid="search-button" />,
      }}
      {...props}
    />
  );
}
