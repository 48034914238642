import { List, styled } from '@mui/material';
import React from 'react';
import MenuDropItem from './MenuDropItem';
import MenuItem from './MenuItem';

const StyledList = styled(List)`
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    border-radius: 4px;
    gap: 13px;

    
    &:hover {
        background-color: #f5f5f5 !important;
    }
  }
`;

export default function Menu({
  isOpen, setIsOpen, menuList, activeMenu, setActiveMenu, ...props
}) {
  return (
    <StyledList {...props}>
      {menuList.map((item, index) => {
        if (!item.validUser) return null;

        return item.isDrop && !item.drawer ? (
          <MenuDropItem
            key={item.title}
            item={item}
            index={index}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setActiveMenu={setActiveMenu}
            activeMenu={activeMenu === item.title}
          />
        ) : (
          <MenuItem
            key={item.title}
            item={item}
            setIsOpen={setIsOpen}
            index={index}
            isOpen={isOpen}
            setActiveMenu={setActiveMenu}
          />
        );
      })}
    </StyledList>
  );
}
