import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material';

/**
 * A customized tooltip component with an edgy design.
 *
 * @component
 * @param {string} title The content of the tooltip.
 * @param {boolean} arrow Whether the tooltip should have an arrow or not.
 * @param {string} placement The placement of the tooltip.
 * @example
 * return (
 *  <BenvoTooltip title="This is a tooltip" arrow placement="top">
 *   <Button>Hover me</Button>
 * </BenvoTooltip>
 */
const BenvoTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ style }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#4A397F',
      color: '#fff',
      padding: '4px 8px',
      width: 'fit-content',
      ...style,
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '130%',
      letterSpacing: '0.12px',
      textAlign: 'center',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: style?.arrowColor || '#4A397F',
    },
  }),
);

export default BenvoTooltip;
