import {
  Box, Collapse, Typography, styled,
} from '@mui/material';

export const HeadBox = styled(Box)`
  background-color: white;
  padding: 42px;
  width: 400px;
`;

export const TitleBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const SubtitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const Separator = styled(Box)`
  background-color: #D4D4DA;
  width: 100%;
  height: 1px;
  margin: 12px 0;
`;

export const TitleTypography = styled(Typography)`
  color: #2D2D33;
  font-size: 1.28rem;
  font-weight: 500;
`;

export const SubtitleTypography = styled(Typography)`
  color: #2D2D33;
  font-size: 1.15rem;
`;

export const SubtitleSecondTypography = styled(Typography)`
  color: #52A569;
  font-size: 1.0rem;
  text-align: justify;
`;

export const SubtitleSecondAlertTypography = styled(Typography)`
  color: #474750;
  font-size: 1.0rem;
  text-align: justify;
`;

export const Heading2 = styled(Typography)`
  color: #474750;
  font-size: 1rem;
`;

export const DarkTextTypography = styled(Typography)`
  color: #474750;
  font-size: 0.85rem;
`;

export const CardSelectorBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CreditCardListBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CreditCardBox = styled(Box)`
  align-items: center;
  background-color: #F5F5F7;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px;
`;

export const RadioAndCardInfoBox = styled(Box)`
  display: flex;
  flex-grow: 1;
  gap: 12px;
`;

export const CardInfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
  flex-grow: 1;
`;

export const CreditCardActions = styled(Box)`
  display: flex;
  align-items: center;
`;

export const CollapseBox = styled(Collapse)`
`;

export const ModalActionsButtonsBox = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const AddCardButtonsBox = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

export const PayedBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
`;

export const PaymentHeadBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CardNumberAndCVVBoxes = styled(Box)`
  align-items: center;    
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const CardNumberTypography = styled(Typography)`
  fontSize: 0.85rem;
  color: #474750;
  letter-spacing: 2px;
  font-weight: 500;
`;

export const PaymentButtonBox = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 12px;
  padding-top: 36px;
`;

export const PaymentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const NotAddedItemsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const NotAddedItems = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid #F5F5F7;
  padding: 2px;
  border-radius: 6px;
  
  div:nth-child(2n) {
    background-color: #F5F5F7;
  }
`;

export const NotAddedItem = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;
