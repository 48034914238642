import React from 'react';
import { StepLabel } from '@mui/material';
import {
  LabelDescription, StyledConnector, StyledContent, StyledStep, StyledStepper,
} from './style';

export default function BenvoVerticalStepper({ steps, activeStep }) {
  return (
    <StyledStepper nonLinear activeStep={activeStep} orientation="vertical" connector={<StyledConnector />}>
      {steps.map((step, index) => (
        <StyledStep
          last={index === steps.length - 1}
          isLastStep={index === steps.length - 1}
          concluded={index < activeStep}
          key={step.label}
          active={index === activeStep}
        >
          <StepLabel>{step.label}</StepLabel>
          <StyledContent
            TransitionProps={{
              in: true,
            }}
            concluded={index < activeStep}
            key={step.label}
            active={index === activeStep}
          >
            <LabelDescription width={step.descriptionSize}>{step.description}</LabelDescription>
          </StyledContent>
        </StyledStep>
      ))}
    </StyledStepper>
  );
}
