import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box, Button, CircularProgress, Grid, Pagination,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import routes from '../../services/routes';
import ContainerPage from '../../templates/containerPage/ContainerPage';
import { useAlert } from '../../context/alertContext';
import { getTokenInfo } from '../../utils/configAuth';
import { createRegistryData, csvHeadersTaskRegistry } from './utils';
import generateCsv from '../../utils/generateCsv';
import TasksRegistryFilter from './components/TasksRegistryFilter';
import TasksRegistryTable from './components/TasksRegistryTable';

function TaskRegistry() {
  const [filterState, setFilterState] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { task } = routes;
  const alert = useAlert();
  const ilpiId = getTokenInfo('ilpiId');

  const handleFilter = async (filter) => {
    setFilterState(filter);
  };

  const handleError = (error) => {
    const { status } = error.response;
    if (status === 404) {
      alert.error('Nenhuma tarefa encontrada');
    }
  };

  const { data: tasksRegistry = {}, isLoading } = useQuery({
    queryKey: ['tasks', filterState, currentPage],
    queryFn: () => task.getRegistryByIlpi({ ilpiId }, { ...filterState, page: currentPage }),
    onSuccess: (data) => {
      setTotalPages(data.message.totalPages);
    },
    onError: (error) => {
      handleError(error);
    },
    retry: 0,
    cacheTime: 0,
  });

  const handleGenerateCSV = async () => {
    const beginDate = filterState.beginDate
      ? moment(filterState.beginDate).format('DD-MM-YYYY')
      : moment().subtract(1, 'month').format('DD-MM-YYYY');
    const endDate = filterState.endDate
      ? moment(filterState.endDate).format('DD-MM-YYYY')
      : moment().format('DD-MM-YYYY');

    try {
      const { message } = await task.generateCSV({ ilpiId }, filterState);
      const data = createRegistryData(message);

      generateCsv(
        `Registro_de_Tarefas_de_${beginDate}_a_${endDate}`,
        csvHeadersTaskRegistry,
        data,
      );
    } catch (error) {
      alert.error('Erro ao gerar relatório');
    }
  };

  return (
    <ContainerPage
      title="Registro de tarefas"
      description="Aqui você pode ver o registro de tarefas realizadas"
    >
      <Box
        sx={{
          mt: '20px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TasksRegistryFilter onFilter={handleFilter} />
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleGenerateCSV}
            >
              Relatório
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <TasksRegistryTable tasks={tasksRegistry} />
        )}
        <Pagination
          color="primary"
          sx={{
            '.MuiPagination-ul': {
              justifyContent: 'flex-end',
            },
          }}
          count={totalPages}
          onChange={(event, page) => {
            setCurrentPage(page - 1);
          }}
        />
      </Box>
    </ContainerPage>
  );
}

export default TaskRegistry;
