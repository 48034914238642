import React, { useState } from 'react';
import alertMapper from '../../../../../../utils/alertMapper';
import useInfiniteList from '../../../../../../hooks/useInfiniteList';
import routes from '../../../../../../services/routes';
import { getTokenInfo } from '../../../../../../utils/configAuth';
import { useAlert } from '../../../../../../context/alertContext';
import DynamicScroll from '../../../../../../components/DynamicScroll';
import getAge from '../../../../../../utils/getAge';
import BenvoImage from '../../../../../../components/BenvoImage';
import { ReactComponent as MockImage } from '../../../../../../assets/healthService/mock-image.svg';
import { ReactComponent as RadioUnchecked } from '../../../../../../assets/healthService/radio.svg';
import { ReactComponent as RadioChecked } from '../../../../../../assets/healthService/radio-checked.svg';
import {
  PageContainer,
  PageTitle,
  ResidentAge,
  ResidentListItem,
  ResidentListItemInformation,
  ResidentName,
  StyledCheckbox,
  StyledDivider,
} from './style';
import BenvoSearchField from '../../../../../../components/BenvoSearchField';
import BenvoButton from '../../../../../../components/BenvoButton/style';

const { resident: residentRoute } = routes;

export default function ResidentsStep({ setHealthServiceOrder, healthServiceOrder, setActiveStep }) {
  const [searchValue, setSearchValue] = useState('');
  const ilpiId = getTokenInfo('ilpiId');
  const alert = useAlert();
  const getResidentInformation = async (queries) => {
    try {
      const { message } = await residentRoute.searchResidentByIlpi({ ilpiId }, queries);
      return message;
    } catch (error) {
      return alert[alertMapper(error.response?.status)]();
    }
  };

  const {
    data: residentInfo = [],
    nextPage,
    setQueries,
    loading,
    finished,
  } = useInfiniteList({ getData: getResidentInformation });

  const searchResident = async () => {
    setQueries({ name: searchValue });
  };
  const handleSelectResident = (event) => {
    const { id } = event.target;
    setHealthServiceOrder((prevState) => {
      if (event.target.checked) {
        return {
          ...prevState,
          residentIds: [...prevState.residentIds, id],
        };
      }
      return {
        ...prevState,
        residentIds: prevState.residentIds.filter((residentId) => residentId !== id),
      };
    });
  };

  return (
    <PageContainer>
      <PageTitle>Selecione um ou mais residentes</PageTitle>
      <BenvoSearchField
        placeholder="Pesquisar residentes"
        onChange={(event) => setSearchValue(event.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            searchResident();
          }
        }}
      />
      <DynamicScroll
        sx={{
          borderRadius: '8px',
          maxHeight: '346px',
          backgroundColor: '#F5F5F7',
        }}
        onScrollEnd={nextPage}
        finished={finished}
        loading={loading}
      >
        {residentInfo.map((resident) => (
          <ResidentListItem htmlFor={resident.id} key={resident.id}>
            <ResidentListItemInformation>
              <BenvoImage
                alt={resident.name}
                src={resident.profileImage}
                DefaultImage={MockImage}
                sx={{
                  borderRadius: '3px',
                  objectFit: 'cover',
                  minWidth: '47px',
                  width: '47px',
                  height: '47px',
                }}
              />
              <ResidentName>{resident.name}</ResidentName>
              <StyledDivider flexItem orientation="vertical" />
              <ResidentAge>{`idade ${getAge(resident.birthDate)} anos`}</ResidentAge>
            </ResidentListItemInformation>
            <StyledCheckbox
              icon={<RadioUnchecked />}
              checkedIcon={<RadioChecked />}
              id={resident.id}
              onChange={handleSelectResident}
              checked={healthServiceOrder.residentIds.includes(resident.id)}
            />
          </ResidentListItem>
        ))}
      </DynamicScroll>
      <BenvoButton
        sx={{
          alignSelf: 'flex-end',
          mt: '10px',
        }}
        horizontalPadding="25"
        disabled={!healthServiceOrder.residentIds.length}
        onClick={() => setActiveStep(1)}
      >
        Continuar
      </BenvoButton>
    </PageContainer>
  );
}
