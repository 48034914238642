const {
  AppBar, styled, Toolbar,
} = require('@mui/material');

export const StyledAppBar = styled(AppBar)`
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%),
    0px 4px 24px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
  position: relative;
`;

export const StyledToolbar = styled(Toolbar)`
  padding: 16px 40px 16px 40px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
