const moment = require('moment');

const daysOfWeek = {
  monday: 'Segunda',
  tuesday: 'Terça',
  wednesday: 'Quarta',
  thursday: 'Quinta',
  friday: 'Sexta',
  saturday: 'Sábado',
  sunday: 'Domingo',
};

const daysOfWeekArr = Object.keys(daysOfWeek);

export const mapRecurrence = (recurrence) => {
  if (!recurrence) {
    return 'Não Recorrente';
  } if (JSON.parse(recurrence) === 'daily') {
    return 'Diário';
  }
  const recurrenceSort = JSON
    .parse(recurrence)
    .sort((a, b) => daysOfWeekArr.indexOf(a) - daysOfWeekArr.indexOf(b));
  const days = recurrenceSort.map((day) => daysOfWeek[day]);

  if (days.length >= 2) {
    const lastDay = days.pop();

    return `${days.join(', ')} e ${lastDay}`;
  }
  return days[0];
};

export const validateTime = (dateTime) => {
  const [, minutes] = moment(dateTime).format('HH:mm').split(':');
  if (Number(minutes) % 5 === 0) {
    return moment(dateTime).format('HH:mm');
  }
  const roundedMinutes = Math.ceil(parseInt(minutes, 2) / 5) * 5;
  const roundedTime = moment(dateTime).minutes(roundedMinutes);
  return roundedTime.format('HH:mm');
};
