import { Avatar } from '@mui/material';
import React, { useState } from 'react';
import ArrowDown from '../../../../assets/icons/arrow-down.svg';
import MenuWindow from './MenuWindow';

import {
  StyledBoxArrow, StyledProfileBox,
  StyledProfileButton, StyledTypographyName,
} from './style';

function Menu({ doctorName }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleMenu = () => setIsOpen((prev) => !prev);

  return (
    <StyledProfileBox>
      <StyledProfileButton onClick={handleMenu}>
        <Avatar src="" sx={{ width: '34px', height: '34px' }} />

        <StyledTypographyName>
          {doctorName}
        </StyledTypographyName>

        <StyledBoxArrow isOpen={isOpen}>
          <img src={ArrowDown} alt="Arrow" />
        </StyledBoxArrow>
      </StyledProfileButton>

      <MenuWindow open={isOpen} setIsOpen={setIsOpen} />
    </StyledProfileBox>
  );
}

export default Menu;
