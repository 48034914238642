import { Grid, TextField } from '@mui/material';
import React from 'react';

export default function VitalSignsEditForm({ evolution, register, errors }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          label="Pressão Arterial Sistólica"
          defaultValue={evolution.systolicBloodPressure}
          error={!!errors.systolicBloodPressure}
          helperText={errors.systolicBloodPressure?.message}
          inputProps={{
            type: 'number',
            min: 0,
            max: 180,
            placeholder: 'Entre 0 e 180',
          }}
          {...register('systolicBloodPressure')}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          label="Pressão Arterial Diastólica"
          defaultValue={evolution.diastolicBloodPressure}
          error={!!errors.diastolicBloodPressure}
          helperText={errors.diastolicBloodPressure?.message}
          inputProps={{
            type: 'number',
            min: 0,
            max: 180,
            placeholder: 'Entre 0 e 180',
          }}
          {...register('diastolicBloodPressure')}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          label="Frequência Respiratória"
          defaultValue={evolution.respiratoryFrequency}
          error={!!errors.respiratoryFrequency}
          helperText={errors.respiratoryFrequency?.message}
          inputProps={{
            type: 'number',
            min: 4,
            max: 28,
            placeholder: 'Entre 4 e 28',
          }}
          {...register('respiratoryFrequency')}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          label="Frequência Cardíaca"
          defaultValue={evolution.heartFrequency}
          error={!!errors.heartFrequency}
          helperText={errors.heartFrequency?.message}
          inputProps={{
            type: 'number',
            min: 40,
            max: 140,
            placeholder: 'Entre 40 e 140',
          }}
          {...register('heartFrequency')}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          label="Saturação de Oxigênio (em %)"
          defaultValue={evolution.oxygenSaturation}
          error={!!errors.oxygenSaturation}
          helperText={errors.oxygenSaturation?.message}
          inputProps={{
            type: 'number',
            min: 70,
            max: 100,
            placeholder: 'Entre 70 e 100',
          }}
          {...register('oxygenSaturation')}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          label="Temperatura (em °C)"
          defaultValue={evolution.temperature}
          error={!!errors.temperature}
          helperText={errors.temperature?.message}
          inputProps={{
            type: 'number',
            min: 35,
            max: 43,
            placeholder: 'Entre 35 e 43',
          }}
          {...register('temperature')}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          label="HGT em mg/dL"
          defaultValue={evolution.hgt}
          error={!!errors.hgt}
          helperText={errors.hgt?.message}
          inputProps={{
            type: 'number',
            min: 50,
            max: 600,
            placeholder: 'Entre 50 e 600',
          }}
          {...register('hgt')}
        />
      </Grid>
    </>
  );
}
