import React from 'react';

export default function Copy() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        stroke="#474750"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.783 8.597v2.787c0 2.322-.934 3.251-3.267 3.251h-2.8c-2.333 0-3.267-.929-3.267-3.251V8.597c0-2.322.934-3.251 3.267-3.251h2.8c2.333 0 3.267.929 3.267 3.251z"
      />
      <path
        stroke="#474750"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.783 4.616v2.787c0 2.322-.934 3.251-3.267 3.251h-.733V8.597c0-2.322-.934-3.251-3.267-3.251H5.449v-.73c0-2.322.934-3.251 3.267-3.251h2.8c2.333 0 3.267.929 3.267 3.25z"
      />
    </svg>
  );
}
