import React from 'react';
import { Box, Typography } from '@mui/material';
import './ContainerPage.scss';

function ContainerPage(props) {
  const { title, description, children } = props;
  return (
    <Box>
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 1,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          color="primary"
          fontWeight="700"
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          color="primary"
          fontWeight="700"
        >
          {description}
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}

export default ContainerPage;
