import {
  Box, Drawer, IconButton, styled,
} from '@mui/material';

export const StyledDrawer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .MuiDrawer-paper {
    width: 100%;
    max-width: 582px;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 9px;
      background: linear-gradient(90deg, rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%);

      @media screen and (max-width: 768px) {
        content: none;
      }
    }
  }
`;

export const ContentContainer = styled(Box)`
  width: 100%;
  padding: 22px 40px;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  overflow-y: auto;
  height: 100%;

  @media screen and (max-width: 768px) {
    padding: 22px 28px;
  }
`;

export const CloseButton = styled(IconButton)`
  position: fixed;
  top: 40px;
  right: 600px;
  background-color: #4a397f;
  z-index: 1300;

  &:hover {
    background-color: #fe663f;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
