import { Box, styled } from '@mui/material';
import residentsDesktop from '../../../../assets/home/residents-desktop.webp';
import residentsMobile from '../../../../assets/home/residents-mobile.webp';
import BenvoButton from '../../../../components/BenvoButton/style';

export const Card = styled(Box)`
  padding: 48.5px 0 48.82px 97px;
  background: url(${residentsDesktop}) no-repeat;

  @media (max-width: 768px) {
    padding: 25.06px 0 0 18px;
    background: url(${residentsMobile}) no-repeat;
    min-width: 350px;
    height: 280.05px;
  }

  a {
    text-decoration: none;
    width: fit-content;
    display: block;
  }

  h2 {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    line-height: 130%; /* 39px */
    letter-spacing: 0.045px;
    margin-bottom: 12px;

    width: 100%;
    max-width: 308px;

    @media (max-width: 768px) {
      font-size: 20px;
      font-weight: 700;
      line-height: 130%; /* 26px */
      letter-spacing: 0.03px;

      max-width: 208px;
    }
  }

  p {
    color: #fff;
    font-size: 17.681px;
    font-weight: 400;
    line-height: 140%; /* 24.754px */
    letter-spacing: 0.044px;
    margin-bottom: 20px;

    width: 100%;
    max-width: 288.796px;

    @media (max-width: 768px) {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      letter-spacing: 0.03px;
      margin-bottom: 12px;

      max-width: 196px;
    }
  }
`;

export const Button = styled(BenvoButton)`
  width: 193px;
  padding: 16px 0px;

  @media (max-width: 768px) {
    font-size: 12.389px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: 0.155px;
    width: 170px;
    height: 35px;
  }
`;
