import React from 'react';
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

function MTableContainer({ children, ...props }) {
  return (
    <TableContainer component={Paper} {...props}>
      {children}
    </TableContainer>
  );
}

function MTableRoot({ children, ...props }) {
  return <Table {...props}>{children}</Table>;
}

function MTableHead({ children, ...props }) {
  return <TableHead {...props}>{children}</TableHead>;
}

function MTableRow({ children, ...props }) {
  const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return <StyledTableRow {...props}>{children}</StyledTableRow>;
}

function MTableCell({ children, ...props }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: 18,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));

  return (
    <StyledTableCell align="center" {...props}>
      {children}
    </StyledTableCell>
  );
}

function MTableBody({ children, ...props }) {
  return <TableBody {...props}>{children}</TableBody>;
}

const MTable = {
  Container: MTableContainer,
  Root: MTableRoot,
  Head: MTableHead,
  Row: MTableRow,
  Cell: MTableCell,
  Body: MTableBody,
};

export default MTable;
