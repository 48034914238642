import {
  Box, Paper, Select, TextField, styled,
} from '@mui/material';

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h5 {
    color: #474750;

    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.014px;
    margin: 0;
  }
`;

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 4px;
  }
`;

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 12px;
`;

export const StyledTextField = styled(TextField)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);

  .Mui-disabled input {
    color: #d4d4da !important;
    -webkit-text-fill-color: #d4d4da !important;
  }

  .Mui-disabled {
    pointer-events: all;
    cursor: not-allowed;
  }

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    font-size: 12px;
    & fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &:hover fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &.Mui-focused fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &.Mui-disabled fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }

    &.Mui-disabled input {
      color: var(--grey-variation-light-grey, #474750);
      -webkit-text-fill-color: var(--grey-variation-light-grey, #474750);
    }

    &.MuiInputBase-root {
      font-size: 12px;
      input {
        padding: 0px !important;

        &::placeholder {
          color: var(--grey-variation-light-grey, #474750);
        }
      }
    }
  }

  p {
    transition: all 0.2s ease-in-out;
    background: #fff !important;
    margin: 0px !important;
  }
`;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  height: 40px;
  border: none;
  font-size: 12px;

  & fieldset {
    border: none;
    ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
  }

  .MuiSelect-select {
    ${({ value }) => value === '' && 'color: var(--grey-variation-light-grey, #47475070)'}
  }
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledOptions = styled('li')`
  font-size: 12px;
  font-weight: 400;
  margin: 0 12px;
  padding: 4px !important;
  &.Mui-focused {
    background: #4a397f !important;
    color: #fff;
    border-radius: 4px;
  }
`;
export const StyledPaper = styled(Paper)`
  background: #F5F5F7;
  border-radius: 8px;
  box-shadow: none;
  margin-top: 4px;
`;
