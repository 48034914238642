import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
// import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  BoxActions,
  BoxPhoto,
  BoxPhotoAndNameDesktop,
  BoxPhotoAndNameMobile,
  BoxRefereceInformationTextAndActions,
  BoxReferenceInformation,
  BoxResidentInformation,
  BoxResidentInformationAndPhoto,
  ContainerReferenceInformationAndActions,
  ContainerResidentInformation,
  EmptyCheckboxContainer,
  StatusContainer,
  StyledCheckBox,
  StyledDivider,
  StyledIconButton,
} from './style';
import getAge from '../../../../utils/getAge';
// import PATHS from '../../../../constants/applicationPaths';
import BenvoImage from '../../../../components/BenvoImage';
import PATHS from '../../../../constants/applicationPaths';
import stateMachine from '../../../../utils/stateMachine';
import EditIcon from '../../../../assets/icons/EditIcon';
import TaskSquare from '../../../../assets/residents/TaskSquare';
import BenvoTooltip from '../../../../components/BenvoTooltip';
import CreateOrEditResident from '../CreateOrEditResident';

export default function ListResidentInformation({
  residentInfo,
  getResidentInformation,
  hasShoppingListFeat,
  setSelectedLists,
  selectedLists,
}) {
  const { status, color } = stateMachine[residentInfo.shoppingListStatus || ''] || {};
  const { innerWidth: width } = window;

  const shouldShowStatus = useMemo(
    () => hasShoppingListFeat && residentInfo.shoppingListUpdatedAt,
    [residentInfo.shoppingListUpdatedAt, hasShoppingListFeat],
  );

  const handleSelectResident = () => {
    setSelectedLists((prev) => {
      if (prev.includes(residentInfo.shoppingListId)) {
        return prev.filter((shoppingListId) => shoppingListId !== residentInfo.shoppingListId);
      }
      return [...prev, residentInfo.shoppingListId];
    });
  };

  return (
    <ContainerResidentInformation>
      <BoxPhotoAndNameDesktop>
        {(residentInfo.shoppingListStatus
          && stateMachine[residentInfo.shoppingListStatus].isSendable)
          // eslint-disable-next-line max-len
          ? (<StyledCheckBox onChange={handleSelectResident} checked={selectedLists.includes(residentInfo.shoppingListId)} />)
          : (<EmptyCheckboxContainer />)}
        <BoxPhoto sx={{ width: '77px', height: '55px' }}>
          <BenvoImage
            src={residentInfo.profileImage}
            alt="Foto do residente"
            sx={{ borderRadius: '3px', objectFit: 'cover' }}
          />
        </BoxPhoto>

        <BoxResidentInformation>
          <Typography variant="h4" data-testid={`name-${residentInfo.id}`}>
            {residentInfo.name}
          </Typography>
          <Typography>{`idade ${getAge(residentInfo.birthDate)} anos`}</Typography>
        </BoxResidentInformation>
      </BoxPhotoAndNameDesktop>

      {width <= 768 && (
      <BoxPhotoAndNameMobile>
        <CreateOrEditResident
          refetchResidentList={getResidentInformation}
          residentInformationToEdit={residentInfo}
        >
          <BoxResidentInformationAndPhoto>
            <BoxPhoto sx={{ width: '77px', height: '55px' }}>
              <BenvoImage
                src={residentInfo.profileImage}
                alt="Foto do residente"
                sx={{ borderRadius: '3px', objectFit: 'cover' }}
              />
            </BoxPhoto>

            <BoxResidentInformation>
              <Typography variant="h4">{residentInfo.name}</Typography>
              <Typography>{`idade ${getAge(residentInfo.birthDate)} anos`}</Typography>
            </BoxResidentInformation>
          </BoxResidentInformationAndPhoto>
        </CreateOrEditResident>
      </BoxPhotoAndNameMobile>
      )}

      <ContainerReferenceInformationAndActions>
        <BoxReferenceInformation>
          <BoxRefereceInformationTextAndActions>
            {shouldShowStatus && <StatusContainer color={color}>{status}</StatusContainer>}
          </BoxRefereceInformationTextAndActions>
        </BoxReferenceInformation>

        {(residentInfo.shoppingListStatus) && <StyledDivider orientation="vertical" flexItem />}

        {width > 768 && (
          <BoxActions>
            <CreateOrEditResident
              refetchResidentList={getResidentInformation}
              residentInformationToEdit={residentInfo}
            >
              <BenvoTooltip title="Editar dados do residente" arrow placement="top">
                <StyledIconButton>
                  <EditIcon />
                </StyledIconButton>
              </BenvoTooltip>
            </CreateOrEditResident>

            {hasShoppingListFeat && (
            <BenvoTooltip title="Histórico de Listas" arrow placement="top">
              <Link to={`${PATHS.residents.root}/${residentInfo.id}/${PATHS.residents.orderHistory}`}>
                <StyledIconButton>
                  <TaskSquare />
                </StyledIconButton>
              </Link>
            </BenvoTooltip>
            )}
          </BoxActions>
        )}
      </ContainerReferenceInformationAndActions>
    </ContainerResidentInformation>
  );
}

ListResidentInformation.propTypes = {
  residentInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    birthDate: PropTypes.string.isRequired,
  }).isRequired,
};
