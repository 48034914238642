import React, {
  createContext, useContext, useMemo, useState,
} from 'react';

const doctorLPContext = createContext({});

export const useDoctorLP = () => useContext(doctorLPContext);

export function DoctorLPProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [doctor, setDoctor] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [exams, setExams] = useState([]);
  const [approved, setApproved] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [signed, setSigned] = useState(false);

  if (signed) {
    setRecipes([]);
    setExams([]);
    setSigned(false);
  }

  const store = useMemo(() => ({
    isLoading,
    doctor,
    recipes,
    removed,
    exams,
    approved,
    pdfs,
    signed,
    setIsLoading,
    setDoctor,
    setRecipes,
    setRemoved,
    setExams,
    setApproved,
    setPdfs,
    setSigned,
  }));

  return (
    <doctorLPContext.Provider value={store}>
      {children}
    </doctorLPContext.Provider>
  );
}
