import {
  Box, Select, Typography, styled,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 22px;
  align-self: stretch;
`;

export const PageTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const FieldsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const StyledDateInput = styled(DesktopDatePicker)`
  display: flex;
  /* max-width: 586px; */
  align-items: space-between;
  gap: 8px;
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  outline: none;
  border: none;
  margin: 8px 0;
  input {
    border: none;
    outline: none;
    padding: 8px;
    opacity: 1;
    color: var(--grey-variation-dark-grey, #474750);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    letter-spacing: 0.12px;
    &::placeholder {
      opacity: 0.6;
      color: var(--grey-variation-dark-grey, #474750);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%;
      letter-spacing: 0.12px;
    }
  }
  fieldset {
    border: none;
    outline: none;
  }
  &.MuiFormControl-root {
    width: 100%;
  }
`;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  height: 40px;
  border: none;
  font-size: 12px;

  & .Mui-readOnly {
    color: var(--grey-variation-light-grey, #d4d4da);
    cursor: not-allowed;
  }

  & fieldset {
    border: none;
    ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
  }

  .MuiSelect-select {
    ${({ value }) => value === ''
      && `
        color: var(--grey-variation-dark-grey, #474750);
        font-size: 12px;
    `}
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;
