import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Grid,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  ButtonContainer,
  FormContainer,
  StyledForm,
  StyledTextField,
} from './style';
import schemas from '../../../../schemas';
import { StyledTellInput } from '../CreateOrEditResident/style';
import BenvoButton from '../../../../components/BenvoButton/style';
import { getTokenInfo } from '../../../../utils/configAuth';
import routes from '../../../../services/routes';
import {
  removeMask,
} from '../../../../utils/inputMasks';
import ResponsibleConflictModal from '../ResponsibleConflictModal';

export default function ReferenceContactForm({
  allReferenceContactsByIlpi,
  residentInformation,
  setCreateANewReferenceContact,
  referenceList,
  residentInformationToEdit,
}) {
  const [responsibleConflict, setResponsibleConflict] = useState({
    open: false,
    responsible: [],
    upsert: false,
  });

  const ilpiId = getTokenInfo('ilpiId');
  const queryClient = useQueryClient();
  const referenceForm = useForm({
    resolver: joiResolver(schemas.referenceContact.createNewReferenceContact),
    defaultValues: {
      name: '',
      phone: '',
      email: '',
    },
  });

  const filteredReferenceList = allReferenceContactsByIlpi
    .filter((item) => !referenceList.some((reference) => reference.id === item.id))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleCloseConflictModal = () => setResponsibleConflict({ open: false, responsible: [] });

  const { resident } = routes;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = referenceForm;

  const createReferenceContact = async (data) => {
    const referenceContactId = allReferenceContactsByIlpi.find(
      (item) => item.id === data.id,
    )?.id;
    const payload = {
      ilpiId,
      id: referenceContactId,
      email: data.email || null,
      phone: removeMask(data.phone) || null,
      name: data.name,
    };

    if (!referenceContactId) {
      delete payload.id;
    }

    try {
      await resident.residentContact(
        {
          residentId: residentInformation.id ?? residentInformationToEdit.id,
        },
        payload,
      );
      queryClient.invalidateQueries({
        queryKey: ['referenceContactList'],
      });
      reset();
      setCreateANewReferenceContact(false);
    } catch (error) {
      const { response } = error;
      const errorCode = response?.request?.status;
      const responsible = response?.data?.message;
      if (errorCode === 409) {
        setResponsibleConflict({
          open: true,
          responsible,
        });
      }
    }
  };

  const editReferenceContact = async () => {
    const payload = {
      ilpiId,
      id: responsibleConflict.responsible[0]?.id,
      email: watch('email') || undefined,
      phone: removeMask(watch('phone')) || null,
      name: watch('name'),
    };

    try {
      await resident.residentContact({
        residentId: residentInformation.id ?? residentInformationToEdit.id,
      }, payload);

      queryClient.invalidateQueries({
        queryKey: ['referenceContactList'],
      });
      reset();
      setResponsibleConflict({ open: false, responsible: [] });
      setCreateANewReferenceContact(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormContainer>
      <StyledForm onSubmit={handleSubmit(createReferenceContact)}>
        <Grid
          container
          rowSpacing="12px"
          columnSpacing={{ xs: '15px', md: '12px' }}
        >
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  placeholder="Nome"
                  helperText={errors.name?.message}
                  error={!!errors.name}
                  data-testid="responsible-name"
                  disabled={
                    !!watch('name')
                    && filteredReferenceList.some(
                      (item) => item.id === watch('id'),
                    )
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <StyledTellInput
                  {...field}
                  fullWidth
                  placeholder="Telefone"
                  langOfCountryName="pt-br"
                  defaultCountry="BR"
                  preferredCountries={['BR', 'PT']}
                  helperText={errors.phone?.message}
                  error={!!errors.phone}
                  data-testid="responsible-phone"
                  disabled={
                      !!watch('name')
                      && filteredReferenceList.some(
                        (item) => item.id === watch('id'),
                      )
                    }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  placeholder="E-mail"
                  helperText={errors.email?.message}
                  error={!!errors.email}
                  data-testid="responsible-email"
                  value={field.value || ''}
                  disabled={
                      !!watch('name')
                      && filteredReferenceList.some(
                        (item) => item.id === watch('id'),
                      )
                    }
                />
              )}
            />
          </Grid>
        </Grid>

        <ButtonContainer>
          <BenvoButton
            onClick={() => {
              reset();
              setCreateANewReferenceContact(false);
            }}
            horizontalPadding={24}
            variant="secondary"
            type="button"
            disabled={responsibleConflict.open}
          >
            Cancelar
          </BenvoButton>
          <BenvoButton
            disabled={!watch('name') || !watch('phone') || responsibleConflict.open || (!residentInformation.id && !residentInformationToEdit.id)}
            horizontalPadding={24}
            type="submit"
          >
            Salvar
          </BenvoButton>
        </ButtonContainer>
      </StyledForm>
      <ResponsibleConflictModal
        handleClose={handleCloseConflictModal}
        responsibleConflict={responsibleConflict}
        editReferenceContact={editReferenceContact}
        inputValues={{
          email: watch('email'),
          phone: removeMask(watch('phone')),
        }}
      />
    </FormContainer>
  );
}
