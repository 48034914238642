import Joi from 'joi';

const loginSchema = Joi.object({
  email: Joi.string().email({ tlds: false }).required().messages({
    'string.email': 'O email deve ser válido',
    'string.empty': 'O email não pode ser vazio',
  }),
  password: Joi.string().min(6).required().messages({
    'string.empty': 'Este campo não pode estar vazio',
    'string.min': 'Este campo deve ter no mínimo {#limit} caracteres',
    'any.required': 'Este campo é obrigatório',
  }),
});

export default { loginSchema };
