import React from 'react';

import { Outlet, useNavigate } from 'react-router';
import {
  ApplicationVersion,
  ButtonAndLogoContainer,
  Card, CardContainer, Container, ContentContainer, ImageContainer,
  StyledIconButton,
} from './style';
import BenvoLoginImage from '../../assets/BenvoLoginImage.png';
import BenvoLogo from '../../assets/authLayout/benvo-logo.webp';
import { BENVO_FOOTER } from '../../constants';
import BackButton from '../../assets/authLayout/backButton';

export default function AuthLayout() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <ContentContainer>
        <CardContainer>
          <Card>
            <ButtonAndLogoContainer>
              <StyledIconButton type="button" onClick={handleBack}>
                <BackButton />
              </StyledIconButton>
              <img src={BenvoLogo} alt="Logo da Benvo" />
            </ButtonAndLogoContainer>
            <Outlet />
          </Card>
        </CardContainer>

        <ApplicationVersion>
          {BENVO_FOOTER}
        </ApplicationVersion>
      </ContentContainer>
      <ImageContainer data-image={BenvoLoginImage} />
    </Container>
  );
}
