import {
  Box,
  Divider, MenuItem, TextField, styled,
} from '@mui/material';

export const StyledBoxContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;

export const StyledBoxFormContainer = styled(Box)`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
`;

export const StyledBoxButtonsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 8px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    background-color: #F5F5F7;
    & fieldset {
      border: none;
    }
  }
  & input {
    color: #474750;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    box-shadow: 0 0 0 1px #F5F5F7 inset;
  }
`;

export const StyledSelect = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 8px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    background-color: #F5F5F7;
    & fieldset {
      border: none;
    }
  }
  & input {
    color: #474750;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    box-shadow: 0 0 0 1px #F5F5F7 inset;
  }
  & .MuiSelect-select {
    color: #474750;
    font-size: 12px;
    font-weight: 400;
    }
    & .MuiInputLabel-root {
      color: #47475075;
      font-size: 12px;
      font-weight: 400;
      }`;

export const StyledMenuItem = styled(MenuItem)`
    color: #474750;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    box-shadow: 0 0 0 1px #F5F5F7 inset;
    `;

export const StyledDivider = styled(Divider)`
  display: flex;
  max-width: 502px;
  height: 1px;
  align-items: flex-start;
background: #EAEBEC;`;
