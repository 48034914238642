import { Box, styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledResidentPlanCard = styled(Box)`
  align-items: "flex-start";
  width: "100%";
  height: "100%";
  display: "flex";
  justify-content: "space-between";
  border-radius: "8px";

  button {
    visibility: hidden;
  }

  :hover {
    filter: brightness(0.8);

    button {
      visibility: visible;
      filter: brightness(1.25);
    }
  }
`;

export const StyledImageBox = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 8px;
  background: var(--paleta-senior-white, #fff);
`;
