import React, { useRef } from 'react';
import { MenuItem } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  ButtonsContainer,
  Observation,
  OptionTextContainer,
  OptionsContainer,
  PageContainer,
  PageTitle,
  RequestOptionsContainer,
  StyledCheckbox,
  StyledSelect,
  StyledTextField,
  StyledTypographyUploadFormat,
  StyledTypographyUploadUpload,
  StyledUploadBox,
  Subtitle,
} from './style';
import { ReactComponent as RadioUnchecked } from '../../../../../../assets/healthService/radio.svg';
import { ReactComponent as RadioChecked } from '../../../../../../assets/healthService/radio-checked.svg';
import routes from '../../../../../../services/routes';
import { getTokenInfo } from '../../../../../../utils/configAuth';
import UploadIcon from '../../../../../../assets/icons/UploadIcon';
import BenvoButton from '../../../../../../components/BenvoButton/style';

const { collaborator } = routes;

export default function MedicalRequestStep({ setHealthServiceOrder, healthServiceOrder, setActiveStep }) {
  const ilpiId = getTokenInfo('ilpiId');
  const ref = useRef(null);

  const getDoctorList = async () => {
    try {
      const { message } = await collaborator.getAllDoctorsByIlpi({ ilpiId });
      return message;
    } catch (error) {
      return [];
    }
  };

  const { data: doctorListData = [] } = useQuery({
    queryKey: ['doctorList'],
    queryFn: getDoctorList,
    refetchOnWindowFocus: false,
  });

  const handleSelectDoctor = ({ doctorId }) => {
    setHealthServiceOrder((prevState) => ({
      ...prevState,
      collaboratorId: doctorId,
    }));
  };

  const selectImages = ({ target }) => {
    const { files: selectedFiles } = target;

    setHealthServiceOrder((prevState) => ({
      ...prevState,
      files: [...prevState.files, ...selectedFiles],
    }));
  };

  const onDigitalRequestSelect = () => {
    setHealthServiceOrder((prevState) => ({
      ...prevState,
      medicalRequest: true,
      files: [],
    }));
  };

  const onImageRequestSelect = () => {
    setHealthServiceOrder((prevState) => ({
      ...prevState,
      medicalRequest: false,
      collaboratorId: null,
    }));
  };

  const handleIndicationChange = ({ target: { value } }) => {
    setHealthServiceOrder((prevState) => ({
      ...prevState,
      indication: value,
    }));
  };

  const disabledButton = () => {
    if (healthServiceOrder.medicalRequest) {
      return healthServiceOrder.collaboratorId === null || healthServiceOrder.indication === '';
    }
    return healthServiceOrder.files.length === 0 || healthServiceOrder.indication === '';
  };

  return (
    <PageContainer>
      <PageTitle>Faça a requisição médica para os exames</PageTitle>
      <RequestOptionsContainer>
        <StyledTextField
          id="indication"
          onChange={handleIndicationChange}
          placeholder="Indicação médica (Exemplo: Exame de rotina)"
          fullWidth
        />
        <Subtitle>Formato de requisição</Subtitle>
        <OptionsContainer>
          <OptionTextContainer>
            <StyledCheckbox
              icon={<RadioUnchecked />}
              checkedIcon={<RadioChecked />}
              id="digitalRequest"
              onChange={onDigitalRequestSelect}
              checked={healthServiceOrder.medicalRequest}
            />
            <Subtitle>Envio para assinatura digital</Subtitle>
          </OptionTextContainer>
          {healthServiceOrder.medicalRequest && (
            <>
              <StyledSelect fullWidth value={healthServiceOrder.collaboratorId || ''} displayEmpty>
                <MenuItem
                  sx={{
                    color: '#BDBDBD',
                  }}
                  value=""
                  onClick={() => handleSelectDoctor({ doctorId: null })}
                >
                  Selecione o médico
                </MenuItem>
                {doctorListData?.map((doctor) => (
                  <MenuItem
                    key={doctor.id}
                    value={doctor.id}
                    onClick={() => handleSelectDoctor({ doctorId: doctor.id })}
                  >
                    {doctor.name}
                  </MenuItem>
                ))}
              </StyledSelect>
              <Observation>Não encontrou o seu médico? Cadastre-o como um dos seus profissionais de saúde.</Observation>
            </>
          )}
        </OptionsContainer>

        <OptionsContainer>
          <OptionTextContainer>
            <StyledCheckbox
              icon={<RadioUnchecked />}
              checkedIcon={<RadioChecked />}
              id="imageRequest"
              onChange={onImageRequestSelect}
              checked={!healthServiceOrder.medicalRequest}
            />
            <Subtitle>Upload em formato de imagem</Subtitle>
          </OptionTextContainer>
          {!healthServiceOrder.medicalRequest && (
            <>
              <input
                type="file"
                multiple
                id=""
                ref={ref}
                onChange={selectImages}
                accept="image/png, image/jpeg, image/jpg, application/pdf"
                style={{
                  display: 'none',
                }}
              />
              <StyledUploadBox onClick={() => ref.current.click()}>
                <UploadIcon />

                {healthServiceOrder.files.length === 0 ? (
                  <>
                    <StyledTypographyUploadUpload>Upload de documentos</StyledTypographyUploadUpload>

                    <StyledTypographyUploadFormat>.PDF .PNG .JPG .JPEG</StyledTypographyUploadFormat>
                    <StyledTypographyUploadFormat>
                      O tamanho total de todos os arquivos anexados não pode exceder 10 MB.
                    </StyledTypographyUploadFormat>
                  </>
                ) : (
                  <StyledTypographyUploadUpload>
                    {healthServiceOrder.files.length}
                    {' '}
                    arquivo(s) selecionado(s)
                  </StyledTypographyUploadUpload>
                )}
              </StyledUploadBox>
            </>
          )}
        </OptionsContainer>
      </RequestOptionsContainer>
      <ButtonsContainer>
        <BenvoButton horizontalPadding="36" variant="secondary" onClick={() => setActiveStep(1)}>
          Voltar
        </BenvoButton>
        <BenvoButton
          disabled={disabledButton()}
          horizontalPadding="25"
          variant="primary"
          onClick={() => setActiveStep(3)}
        >
          Continuar
        </BenvoButton>
      </ButtonsContainer>
    </PageContainer>
  );
}
