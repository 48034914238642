import React from 'react';
import { Link } from 'react-router-dom';
import {
  CardContainer, CardImage, CardText, TextAndButtonContainer,
} from './style';
import BenvoButton from '../../../../components/BenvoButton/style';
import PATHS from '../../../../constants/applicationPaths';

export default function ExamRequestCard() {
  return (
    <CardContainer>
      <CardImage />
      <TextAndButtonContainer>
        <CardText>Solicitação de exames</CardText>
        <Link to={PATHS.exams.requests}>
          <BenvoButton variant="secondary" horizontalPadding="18">
            Fazer pedido
          </BenvoButton>
        </Link>
      </TextAndButtonContainer>
    </CardContainer>
  );
}
