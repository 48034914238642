export function cnpjMask() {
  return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
}

export function dddMask() {
  return ['(', /\d/, /\d/, ')'];
}

export function cpfMask() {
  return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
}

export function maskNumber(number) {
  if (number) {
    number = number.replace(/\D/g, '');
    return number;
  }

  return '';
}

export function maskFloatNumber(number) {
  if (number) {
    number = number.replace(/[^0-9.,]/g, '');
    number = number.replace(/[,]/g, '.');
    return number;
  }

  return '';
}

export function percentMask(number) {
  if (number) {
    number = number.replace(/[^0-9.,]/g, '');
    number = number.replace(/[,]/g, '.');
    number += '%';
    return number;
  }

  return '';
}

export function daysMask(number) {
  if (number) {
    if (number === '1') {
      number = number.replace(/[^0-9]/g, '');
      number += ' dia';
      return number;
    }
    number = number.replace(/[^0-9]/g, '');
    number = `${number} dias`;
    return number;
  }

  return '';
}

export function BRLMask(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function moneyMask(amountString) {
  if (amountString) {
    const number = Number(String(amountString).replace(/[^0-9]/g, ''));
    const numberStr = number.toString();
    const cents = numberStr.slice(-2);

    if (number >= 100000) {
      const integerMoreThanThousand = numberStr.slice(0, -2);
      const integer = integerMoreThanThousand.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return `R$ ${integer},${cents}`;
    }
    if (number >= 100) {
      const integer = numberStr.slice(0, -2);
      return `R$ ${integer},${cents}`;
    }
    if (number >= 10) {
      return `R$ 0,${number}`;
    }
    return `R$ 0,0${number}`;
  }

  return 'R$ 0,00';
}

export function maskLetters(value) {
  if (value) {
    value = value.replace(/[0-9]/g, '');
    return value;
  }

  return '';
}

export function cpfInputMask(value) {
  if (value) {
    return value
      .replace(/\D/g, '') // substitui qualquer caractere que não seja número por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // coloca um ponto depois do terceiro dígito
      .replace(/(\d{3})(\d)/, '$1.$2') // coloca um ponto depois do terceiro dígito
      .replace(/(\d{3})(\d{1,2})/, '$1-$2') // coloca um hífen depois do terceiro dígito
      .replace(/(-\d{2})\d+?$/, '$1'); // captura os 2 dígitos após o hífen e não permitir a entrada de mais dígitos;
  }
  return '';
}

export function cnpjInputMask(cpf) {
  if (cpf) {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    return cpf;
  }

  return '';
}

export function phoneInputMask(phone = '') {
  if (phone) {
    phone = phone.replace(/\D/g, '');
    phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2');
    phone = phone.replace(/(\d)(\d{4})$/, '$1-$2');

    return phone;
  }

  return '';
}

export function cepInputMask(cep) {
  if (cep) {
    cep = cep.replace(/\D/g, '');
    cep = cep.replace(/^(\d{5})(\d)/g, '$1-$2');

    return cep;
  }

  return '';
}

export function upperCaseFirstLetter(palavra) {
  const firstLetter = palavra.charAt(0).toUpperCase();
  const lastLetters = palavra.slice(1).toLowerCase();
  return firstLetter + lastLetters;
}

export function removeMask(value) {
  if (value) return value.replace(/\D+/g, '');

  return '';
}

export function phoneMask() {
  return ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
}

export function phoneMaskWithoutDDD() {
  return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
}

export function cepMask() {
  return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
}

export function emailOrPhoneMask(value) {
  if (value.includes('@')) {
    return value;
  }
  return phoneInputMask(value);
}

export function removeMaskOnlyNumbers(value) {
  if (value) {
    if (value.length <= 14) {
      return value.replace(/\D+/g, '');
    }
    return value;
  }
  return '';
}

export function hiddenEmailMask(email) {
  if (email) {
    const [user, domain] = email.split('@');
    const maskedUser = `${user.charAt(0)}${'*'.repeat(user.length - 3)}${user.charAt(user.length - 2)}${user.charAt(user.length - 1)}`;
    return `${maskedUser}@${domain}`;
  }
  return '';
}

export function phoneWithCountryCodeMask(value) {
  if (value) {
    return `+${value}`;
  }
  return '';
}

export function phoneWithCountryCodeMaskAndSpace(value) {
  if (value) {
    return `+${value.substring(0, 2)} ${value.substring(2, 4)} ${value.substring(4, 9)}-${value.substring(9)}`;
  }
  return '';
}
