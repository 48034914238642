import React, { useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Button,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import routes from '../../../../services/routes';
import ItemsContainer from '../ItemsContainer';
import CreatePrescription from './CreatePrescription';

function EditList({
  open, currentValues, onClose, onSuccess, onFailure,
}) {
  const [items, setItems] = useState(currentValues.list);
  const [prescriptionItems, setPrescriptionItems] = useState([]);
  const [prescriptionModalIsOpen, setPrescriptionModalIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    date: moment(currentValues.date),
    frequency: currentValues.isRecurrent ? 'monthly' : 'unique',
    recipient: currentValues.residentId ? 'resident' : 'ilpi',
    observation: currentValues.observation,
    resident: {
      label: currentValues.residentName,
      value: currentValues.residentId,
    },
  });

  const handleChange = ({ name, value }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const verifyFieldsValidity = () => {
    const THERE_ARE_NO_ITEMS = Object.values(items).every(
      (item) => !item.length,
    );

    return !THERE_ARE_NO_ITEMS;
  };

  const updateList = async () => {
    try {
      const shoppingListId = currentValues.id;
      const params = {
        date: formValues.date.format('YYYY-MM-DD'),
        isRecurrent: formValues.frequency !== 'unique',
        list: items,
      };

      if (formValues.observation) {
        params.observation = formValues.observation;
      }

      await routes.shoppingList.updateShoppingList({ shoppingListId }, params);
      onSuccess('Lista de compras atualizada com sucesso!');
    } catch {
      onFailure('Não foi possível atualizar lista de compras');
    }
  };

  const handleSubmit = async () => {
    if (prescriptionItems.length) {
      setPrescriptionModalIsOpen(true);
    } else updateList();
  };

  return (
    <Modal open={open} onClose={onClose} sx={{ paddingX: '20%', paddingY: 5 }}>
      <Box
        sx={{ padding: 5, overflow: 'auto', height: '100%' }}
        component={Paper}
      >
        <div className="title-information">
          <p>Editar lista de compras</p>
        </div>

        {prescriptionModalIsOpen && (
          <CreatePrescription
            prescriptionItems={prescriptionItems}
            resident={formValues.resident}
            onClose={() => setPrescriptionModalIsOpen(false)}
            onSuccess={updateList}
            onFailure={onFailure}
          />
        )}

        <form>
          <Grid container spacing={{ xs: 1, sm: 1 }} rowSpacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <DesktopDatePicker
                label="Data da compra"
                inputFormat="DD/MM/YYYY"
                value={formValues.date}
                renderInput={(params) => <TextField {...params} fullWidth />}
                onChange={(value) => handleChange({ name: 'date', value })}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="frequency">Frequência</InputLabel>
                <Select
                  fullWidth
                  labelId="frequency"
                  label="Frequência"
                  name="frequency"
                  value={formValues.frequency}
                  onChange={({ target }) => handleChange(target)}
                >
                  <MenuItem value="unique">Uma vez</MenuItem>
                  <MenuItem value="monthly">Mensal</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {currentValues.residentName && (
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  disabled
                  value={currentValues.residentName}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                type="text"
                name="observation"
                label="Observação"
                value={formValues.observation}
                onChange={({ target }) => handleChange(target)}
              />
            </Grid>
          </Grid>
        </form>

        <ItemsContainer
          setItems={setItems}
          items={items}
          setPrescriptionItems={setPrescriptionItems}
          values={formValues}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onClose} variant="text" color="cancel">
            Cancelar
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={!verifyFieldsValidity()}
          >
            Salvar
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default EditList;
