import {
  Box, Typography, styled,
} from '@mui/material';

export const CardContainer = styled(Box)`
  display: flex;
  width: 348px;
  padding: 22px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;

  border-radius: 6px;
  background: #fff;
  height: 265px;
`;

export const TitleAndButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const Title = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.024px;
`;

export const PrimarySubtitle = styled(Typography)`
  color: var(--grey-variation-normal-grey, #96969e);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.025px;
  width: 260px;
`;

export const ListAndSubtitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const SecondarySubtitle = styled(Typography)`
  color: var(--grey-variation-normal-grey, #96969e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.03px;
`;

export const ListContainer = styled(Box)`
  display: flex;
  height: auto;
  max-height: 116px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #f5f5f7;
  overflow-y: auto;

  label:nth-of-type(even) {
    background: #fff;
  }
`;
