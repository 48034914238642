import React from 'react';
import BenvoButton from '../../../../../../components/BenvoButton/style';
import { DarkTextTypography, Heading2, PayedBox } from '../style';
import PaymentSuccess from '../../../../../../assets/paymentSuccess';

function Payed({ onClose }) {
  return (
    <PayedBox data-testid="paymentSucceededDialog">
      <PaymentSuccess />
      <Heading2 textAlign="center">
        A solicitação de pagamento da Frubana foi processada com êxito.
      </Heading2>
      <DarkTextTypography textAlign="center">
        Se houver pedidos com outros fornecedores, fique tranquilo, eles já foram devidamente encaminhados,
        e você só efetuará o pagamento no momento do recebimento.
      </DarkTextTypography>
      <BenvoButton
        variant="primary"
        onClick={onClose}
        sx={{
          width: '150px',
        }}
      >
        OK
      </BenvoButton>
    </PayedBox>
  );
}

export default Payed;
