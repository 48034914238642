/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';

import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Button, MenuItem, TextField } from '@mui/material';
import { THIRTY_DAY_AFTER, TODAY } from '../../../../utils/daysConstants';
import { getTokenInfo } from '../../../../utils/configAuth';
import {
  convertBeginDateToMomentFormat,
  convertEndDateToMomentFormat,
} from '../../../../utils/dateFormat';

const KINDS_OPTIONS = [
  { label: '', value: '' },
  { label: 'Bem Estar', value: 'Bem Estar' },
  { label: 'Sinais Vitais', value: 'Sinais Vitais' },
  { label: 'Diurese', value: 'Diurese' },
];

const STATUS_OPTIONS = [
  { label: '', value: '' },
  { label: 'Atenção', value: 'Atenção' },
  { label: 'Alerta', value: 'Alerta' },
  { label: 'Normal', value: 'Normal' },
];

function EvolutionsFilter({ onSearch }) {
  const [values, setValues] = useState({});
  const [beginDate, setBeginDate] = useState(TODAY);
  const [endDate, setEndDate] = useState(THIRTY_DAY_AFTER);

  const currentIdLpi = getTokenInfo('ilpiId');

  const handleSubmit = () => {
    const params = {
      ilpiId: currentIdLpi || 0,
      residentName: values.residentName || '',
      beginDate: convertBeginDateToMomentFormat(beginDate),
      endDate: convertEndDateToMomentFormat(endDate),
      kind: values.evolutionKind || '',
      status: values.evolutionStatus || '',
    };
    onSearch(params);
  };

  const handleDateChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
    if (field === 'beginDate') {
      setBeginDate(value._d);
    }

    if (field === 'endDate') {
      setEndDate(value._d);
    }
  };

  return (
    <Grid container spacing={2} columns={24} xs={20}>
      <Grid item xs={24} sm={6}>
        <TextField
          id="residentName"
          name="residentName"
          label="Pesquisa por Residentes"
          variant="outlined"
          fullWidth
          size="small"
          autoComplete="off"
          onChange={(e) => {
            handleDateChange('residentName', e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
      </Grid>

      <Grid item xs={24} sm="auto" lg={5}>
        <DesktopDatePicker
          label="Data Inicial"
          inputFormat="DD/MM/yyyy"
          value={beginDate}
          onChange={(value) => handleDateChange('beginDate', value)}
          renderInput={(params) => (
            <TextField size="small" fullWidth {...params} />
          )}
        />
      </Grid>

      <Grid item xs={24} sm="auto" lg={5}>
        <DesktopDatePicker
          label="Data Final"
          inputFormat="DD/MM/yyyy"
          value={endDate}
          onChange={(value) => handleDateChange('endDate', value)}
          renderInput={(params) => (
            <TextField size="small" fullWidth {...params} />
          )}
          clearable
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          select
          size="small"
          label="Tipo"
          value={values.evolutionKind || ''}
          onChange={(e) => {
            handleDateChange('evolutionKind', e.target.value);
          }}
          placeholder="Tipo"
          fullWidth
        >
          {KINDS_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          size="small"
          select
          label="Status"
          value={values.evolutionStatus || ''}
          onChange={(e) => {
            handleDateChange('evolutionStatus', e.target.value);
          }}
          placeholder="Status"
          fullWidth
        >
          {STATUS_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs="auto" lg={2}>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
}

export default EvolutionsFilter;
