import { Box, Typography, styled } from '@mui/material';

export const Title = styled(Typography)`
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%; /* 18.4px */
    letter-spacing: 0.024px;
`;

export const ButtonsContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;
`;

export const StyledForm = styled('form')`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
