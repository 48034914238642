import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import Grid from '@mui/material/Grid';
import routes from '../../services/routes';
import ContainerPage from '../../templates/containerPage/ContainerPage';
import Loading from '../../components/Loading/Loading';
import { convertAPIDate } from '../../utils/dateFormat';
import { THIRTY_DAY_AFTER, THIRTTY_DAYS_AGO } from '../../utils/daysConstants';
import { useAlert } from '../../context/alertContext';
import { getTokenInfo } from '../../utils/configAuth';
import CreateEvolution from './components/CreateEvolution';
import EvolutionsTable from './components/EvolutionsTable/EvolutionsTable';
import EvolutionsFilter from './components/EvolutionsFilter/EvolutionsFilter';

const TITLE = 'Evoluções';
const DESCRIPTION = 'Aqui você pode visualizar o histórico de Evoluções.';

const dialogInfoInitialState = {
  open: false,
};

function Evolutions() {
  const alert = useAlert();
  const ilpiId = getTokenInfo('ilpiId');
  const [evolutionsList, setEvolutionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams, setSearchParams] = useState({
    page: 0,
    name: '',
    beginDate: convertAPIDate(THIRTTY_DAYS_AGO),
    endDate: convertAPIDate(THIRTY_DAY_AFTER),
    status: '',
    kind: '',
  });
  const [dialogInfo, setDialogInfo] = useState(dialogInfoInitialState);

  const listEvolutions = async (page = 0) => {
    try {
      const { message } = await routes.resident.findResidentEvolutionByIlpi(
        { ilpiId },
        { ...searchParams, page },
      );

      setTotalPages(message.totalPages);
      setEvolutionsList(message.rows);
    } catch (error) {
      console.error(error);
      setEvolutionsList([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listEvolutions();
  }, []);

  const handleSearch = async (params) => {
    const queries = {
      ...searchParams,
      ...params,
      page: 0,
    };
    setSearchParams(queries);

    try {
      const { message } = await routes.resident.findResidentEvolutionByIlpi(
        { ilpiId },
        queries,
      );
      setEvolutionsList(message.rows);
    } catch (error) {
      alert.error(
        'Não foram encontradas evoluções com os parâmetros selecionados',
      );
      console.log(error);
    }
  };

  const handlePagination = (page) => {
    const currentPage = page - 1;

    listEvolutions(currentPage);
  };

  return (
    <ContainerPage title={TITLE} description={DESCRIPTION}>
      <Grid container>
        <EvolutionsFilter loading={isLoading} onSearch={handleSearch} />
        <Grid
          item
          xs
          sx={{
            display: 'flex',
            justifyContent: { xs: 'flex-start', lg: 'flex-end' },
            alignItems: { xs: 'flex-start', sm: 'flex-end', lg: 'center' },
            mt: { xs: 2, lg: 0 },
          }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <CreateEvolution
              dialogInfoInitialState={dialogInfoInitialState}
              dialogInfo={dialogInfo}
              setDialogInfo={setDialogInfo}
              onSuccess={listEvolutions}
            />
          )}
        </Grid>
      </Grid>

      {isLoading ? (
        <Loading />
      ) : (
        <EvolutionsTable
          evolutionsList={evolutionsList}
          listEvolutions={listEvolutions}
        />
      )}

      <Pagination
        color="primary"
        sx={{
          '.MuiPagination-ul': {
            justifyContent: 'flex-end',
          },
        }}
        count={totalPages}
        onChange={(e, page) => handlePagination(page)}
      />
    </ContainerPage>
  );
}

export default Evolutions;
