import Joi from 'joi';

const createNurse = Joi.object({
  name: Joi.string().min(3).required().messages({
    'string.min': 'O nome deve ter no mínimo 3 caracteres',
    'string.empty': 'O nome não pode ser vazio',
  }),
  createUser: Joi.boolean().required(),
  privilege: Joi.when('createUser', {
    is: true,
    then: Joi.valid('admin', 'user').required().messages({
      'string.empty': 'O privilégio é obrigatório',
      'any.only': 'O privilégio deve ser "admin" ou "user"',
      'any.required': 'O privilégio é obrigatório',
    }),
  }),
  cpf: Joi.string().length(14).required().messages({
    'string.length': 'O CPF deve ter 11 caracteres',
    'string.empty': 'O CPF não pode ser vazio',
  }),
  professionalIdentification: Joi.string().required().messages({
    'string.empty': 'O COREN não pode ser vazio',
  }),
  phoneNumber: Joi.string().min(14).max(15).allow('')
    .messages({
      'string.min': 'O telefone deve ter no mínimo 10 caracteres',
      'string.max': 'O telefone deve ter no máximo 11 caracteres',
    }),
  email: Joi.string().email({ tlds: false }).required().messages({
    'string.email': 'O email deve ser válido',
    'string.empty': 'O email não pode ser vazio',
  }),
  zipCode: Joi.string().length(9).allow('').messages({
    'string.length': 'O CEP deve ter nove dígitos',
  }),
  street: Joi.string().min(2).allow('').messages({
    'string.min': 'Digite um logradouro válido',
  }),
  number: Joi.string().min(1).allow('').messages({
    'string.min': 'Digite um número válido',
  }),
  state: Joi.string().length(2).allow('').messages({
    'any.required': 'A UF é obrigatória',
  }),
  city: Joi.string().min(2).allow('').messages({
    'string.min': 'Digite uma cidade válida',
  }),
  district: Joi.string().min(2).allow('').messages({
    'string.min': 'Digite um bairro válido',
  }),

  complement: Joi.string().allow(''),
  reference: Joi.string().allow(''),
});

export default {
  createNurse,
};
