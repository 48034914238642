import {
  Box, Checkbox, Select, TextField, Typography, styled,
} from '@mui/material';

export const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 22px;
  align-self: stretch;
`;

export const PageTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0 !important;
`;

export const RequestOptionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const StyledTextField = styled(TextField)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    & fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &:hover fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &.Mui-focused fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }

    &.MuiInputBase-root {
      input {
        padding: 0px !important;
        font-size: 12px;

        &::placeholder {
          color: var(--grey-variation-light-grey, #474750);
          opacity: 1;
          font-size: 12px;
        }
      }
    }
  }
`;

export const Subtitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const Observation = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.04px;
`;

export const OptionsContainer = styled('label')`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  cursor: pointer;
`;

export const OptionTextContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  background: var(--paleta-senior-white, #fff);
  height: 40px;
  border: none;
  font-size: 12px;

  & .Mui-readOnly {
    color: var(--grey-variation-light-grey, #d4d4da);
    cursor: not-allowed;
  }

  & fieldset {
    border: none;
    ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
  }

  .MuiSelect-select {
    ${({ value }) => value === ''
      && `
        color: var(--grey-variation-dark-grey, #474750);
        font-size: 12px;
    `}
  }
`;

export const StyledUploadBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 8px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--paleta-senior-white, #fff);
  cursor: pointer;
  user-select: none;
`;

export const StyledTypographyUploadUpload = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const StyledTypographyUploadFormat = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 17px */
  letter-spacing: 0.1px;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;
