import React from 'react';
import Loading from '../../../../../../components/Loading/Loading';

export default function CardVisualizedPrescription({ medicineList, modalTableIsLoading }) {
  if (modalTableIsLoading) {
    return <Loading />;
  }
  return (

    <div
      height={300}
      data={medicineList.prescriptionItems}
    >
      <div>
        <div>Substância</div>
        <div />
      </div>

      <div>
        <div>Dosagem</div>
        <div />
      </div>

      <div>
        <div>QTD</div>
        <div />
      </div>

      <div>
        <div>UN</div>
        <div />
      </div>

      <div>
        <div>vzs. ao dia</div>
        <div />
      </div>
      <div>
        <div>Observação</div>
        <div />
      </div>
    </div>
  );
}
