import React from 'react';
import {
  Divider, Typography,
} from '@mui/material';
import EditIlpi from '../EditIlpi';
import BenvoDeleteModal from '../../../../components/BenvoDeleteModal';
import {
  BoxActions, BoxInformation, BoxReferenceInformation,
  ContainerInformationPerIlpi, ContainerReferenceInformationAndActions,
} from './style';
import { cnpjInputMask, phoneInputMask } from '../../../../utils/inputMasks';
import ManageIlpiUsersModal from '../manageIlpiUsersModal';

function ListIlpi({
  ilpiInfo, handleDelete, handleSearch, resetIlpiPassword,
}) {
  return (
    <ContainerInformationPerIlpi>
      <BoxInformation>
        <Typography variant="h4" data-testid={`name-${ilpiInfo.id}`}>
          {ilpiInfo.name}
        </Typography>
        <Typography data-testid={`responsible-name-${ilpiInfo.id}`}>
          {ilpiInfo.responsibleName}
        </Typography>
      </BoxInformation>

      <ContainerReferenceInformationAndActions>
        <BoxReferenceInformation>
          <Typography data-testid={`cnpj-${ilpiInfo.id}`}>{cnpjInputMask(ilpiInfo.cnpj)}</Typography>
          <Typography>{`${phoneInputMask(ilpiInfo.phoneNumber)} - ${ilpiInfo.state}`}</Typography>
        </BoxReferenceInformation>

        <Divider orientation="vertical" flexItem />

        <BoxActions>
          <BenvoDeleteModal
            testid={`delete-${ilpiInfo.id}`}
            buttonText="Excluir dados"
            title="Apagar dados"
            description="Deseja apagar os dados dessa ILPI? essa ação não pode ser desfeita"
            handleDelete={() => handleDelete(ilpiInfo.id)}
            handleSearch={handleSearch}
          />

          <ManageIlpiUsersModal
            ilpiId={ilpiInfo.id}
            resetIlpiPassword={resetIlpiPassword}
          />

          <EditIlpi currentIlpi={ilpiInfo} searchIlpi={handleSearch} />
        </BoxActions>
      </ContainerReferenceInformationAndActions>
    </ContainerInformationPerIlpi>
  );
}

export default ListIlpi;
