/* eslint-disable max-len */
import React from 'react';

export default function HealthIcon({ active }) {
  if (active) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.49 6.49 2 12 2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22C6.49 22 2 17.51 2 12ZM10.75 9.8V7.75H13.25V9.8C13.25 10.3247 13.6753 10.75 14.2 10.75H16.25V13.25H14.2C13.6753 13.25 13.25 13.6753 13.25 14.2V16.25H10.75V14.2C10.75 13.6753 10.3247 13.25 9.8 13.25H7.75V10.75H9.8C10.3247 10.75 10.75 10.3247 10.75 9.8ZM10.2 6.25C9.67533 6.25 9.25 6.67533 9.25 7.2V9.25H7.2C6.67533 9.25 6.25 9.67533 6.25 10.2V13.8C6.25 14.3247 6.67533 14.75 7.2 14.75H9.25V16.8C9.25 17.3247 9.67533 17.75 10.2 17.75H13.8C14.3247 17.75 14.75 17.3247 14.75 16.8V14.75H16.8C17.3247 14.75 17.75 14.3247 17.75 13.8V10.2C17.75 9.67533 17.3247 9.25 16.8 9.25H14.75V7.2C14.75 6.67533 14.3247 6.25 13.8 6.25H10.2Z" fill="#4A397F" />
      </svg>
    );
  } return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#474750" />
      <path d="M9.8 10H7.2C7.08954 10 7 10.0895 7 10.2V13.8C7 13.9105 7.08954 14 7.2 14H9.8C9.91046 14 10 14.0895 10 14.2V16.8C10 16.9105 10.0895 17 10.2 17H13.8C13.9105 17 14 16.9105 14 16.8V14.2C14 14.0895 14.0895 14 14.2 14H16.8C16.9105 14 17 13.9105 17 13.8V10.2C17 10.0895 16.9105 10 16.8 10H14.2C14.0895 10 14 9.91046 14 9.8V7.2C14 7.08954 13.9105 7 13.8 7H10.2C10.0895 7 10 7.08954 10 7.2V9.8C10 9.91046 9.91046 10 9.8 10Z" stroke="#474750" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>

  );
}
