/* eslint-disable max-len */
import React from 'react';

export default function UploadIcon() {
  return (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.74992 18.916V12.416L7.58325 14.5827" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.75 12.416L11.9167 14.5827" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.8334 11.3327V16.7493C23.8334 22.166 21.6667 24.3327 16.2501 24.3327H9.75008C4.33341 24.3327 2.16675 22.166 2.16675 16.7493V10.2493C2.16675 4.83268 4.33341 2.66602 9.75008 2.66602H15.1667" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.8334 11.3327H19.5001C16.2501 11.3327 15.1667 10.2493 15.1667 6.99935V2.66602L23.8334 11.3327Z" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
