import { Avatar, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import { usePostHog } from 'posthog-js/react';
import ArrowDown from '../../../../assets/icons/arrow-down.svg';
import { clearToken, getTokenInfo } from '../../../../utils/configAuth';
import MenuWindow from './MenuWindow';
import routes from '../../../../services/routes';

function Menu() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [userInformation, setUserInformation] = useState('');
  const posthog = usePostHog();
  const handleMenu = () => setIsOpen((prev) => !prev);
  const email = getTokenInfo('email');

  const menuRef = useRef(null);

  const getUserName = async () => {
    try {
      const { message } = await routes.users.findUser({ email });
      setUserInformation(message);
    } catch (error) {
      clearToken();
      navigate('/login');
    }
  };

  const handleClickOutsideMenuBar = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    getUserName();

    document.addEventListener('click', handleClickOutsideMenuBar);
  }, []);

  useEffect(() => {
    if (userInformation) {
      posthog?.identify(userInformation.id, {
        email: userInformation.email,
      });
    }
  }, [posthog, userInformation.id, userInformation.email]);

  return (
    <Box sx={{ position: 'relative', minWidth: '180px' }} ref={menuRef}>
      <Button
        onClick={handleMenu}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          flexGrow: 0,
          bgcolor: '#F5F5F7',
          border: '1px solid transparent',
          borderRadius: '8px',
          p: '7px 20px',
          '&:hover': {
            borderColor: '#4A397F',
          },
        }}
      >
        <Avatar src="" sx={{ width: '34px', height: '34px' }} />

        <Typography
          variant="body2"
          sx={{
            mx: '10px',
            fontWeight: '600',
            fontSize: '16px',
            color: '#2D2D33',
            textTransform: 'none',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
          }}
        >
          {userInformation?.name}
        </Typography>

        <Box
          sx={{
            transition: '0.3s',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <img src={ArrowDown} alt="Arrow" />
        </Box>
      </Button>

      <MenuWindow open={isOpen} setIsOpen={setIsOpen} />
    </Box>
  );
}

export default Menu;
