import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import BenvoDrawer from '../../../../components/BenvoDrawer';
import BenvoSelectField from '../../../../components/BenvoSelectField';
import BenvoTextField from '../../../../components/BenvoField/style';
import BenvoButton from '../../../../components/BenvoButton/style';
import { ButtonsContainer, StyledForm, Title } from './style';
import schemas from '../../../../schemas';
import { cpfInputMask, removeMask } from '../../../../utils/inputMasks';
import routes from '../../../../services/routes';
import { useAlert } from '../../../../context/alertContext';
import { getTokenInfo } from '../../../../utils/configAuth';
import BenvoIconButton from '../../../../components/BenvoIconButton/style';
import EditIcon from '../../../../assets/icons/EditIcon';

const accessPermissions = [
  { value: 'user', label: 'Usuário', dataTestId: 'user' },
  { value: 'admin', label: 'Administrador', dataTestId: 'admin' },
];

const { users } = routes;

export default function EditUser({ getUsersAndCollaborators, user, isButton = false }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const alert = useAlert();

  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: joiResolver(schemas.user.createUser),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    reset();
  };

  const editUser = async (data) => {
    const ilpiId = getTokenInfo('ilpiId');
    const userData = {
      ...data,
      cpf: removeMask(data.cpf),
      ilpiId,
    };
    try {
      await users.updateUser({ userId: user.userId }, userData);
      getUsersAndCollaborators();
      handleCloseDrawer();
      alert.success('Usuário editado com sucesso!');
    } catch (error) {
      const { data: errorData, status } = error.response;
      if (status === 409) {
        alert.error(errorData.message);
      } else {
        alert.error('Erro ao editar usuário!');
      }
    }
  };

  useEffect(() => {
    if (user) {
      setValue('name', user.name);
      setValue('cpf', cpfInputMask(user.cpf));
      setValue('email', user.email);
      setValue('privilege', user.privilege);
    }
  }, [user, openDrawer]);

  return (
    <>
      {isButton ? (
        <BenvoButton variant="secondary" onClick={handleOpenDrawer}>
          Visualizar
        </BenvoButton>
      ) : (
        <Tooltip title="Editar Usuário" placement="top" arrow>
          <BenvoIconButton onClick={handleOpenDrawer}>
            <EditIcon />
          </BenvoIconButton>
        </Tooltip>
      )}
      <BenvoDrawer open={openDrawer} onClose={handleCloseDrawer} title="Editar usuário" justifyContent="flex-start">
        <StyledForm onSubmit={handleSubmit(editUser)}>
          <Grid container rowSpacing="18px" columnSpacing="12px">
            <Grid item xs={12}>
              <Title>Dados</Title>
            </Grid>
            <Grid item xs={12}>
              <BenvoTextField
                placeholder="Nome"
                fullWidth
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
                value={watch('name') ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BenvoTextField
                placeholder="CPF"
                fullWidth
                {...register('cpf')}
                error={!!errors.cpf}
                helperText={errors.cpf?.message}
                value={cpfInputMask(watch('cpf')) ?? ''}
                inputProps={{ maxLength: 14 }}
              />
            </Grid>
            <Grid item xs={6}>
              <BenvoTextField
                placeholder="E-mail"
                fullWidth
                {...register('email')}
                error={!!errors.email}
                helperText={errors.email?.message}
                value={watch('email') ?? ''}
              />
            </Grid>
            <Grid item xs={12}>
              <BenvoSelectField
                fullWidth
                displayEmpty
                {...register('privilege')}
                error={!!errors.privilege}
                helperText={errors.privilege?.message}
                value={watch('privilege') ?? ''}
              >
                <MenuItem value="" disabled>
                  Permissão de acesso
                </MenuItem>
                {accessPermissions.map((accessPermission) => (
                  <MenuItem
                    key={accessPermission.value}
                    value={accessPermission.value}
                    data-testid={accessPermission.dataTestId}
                  >
                    {accessPermission.label}
                  </MenuItem>
                ))}
              </BenvoSelectField>
            </Grid>
          </Grid>
          <ButtonsContainer>
            <BenvoButton variant="secondary" horizontalPadding={24} onClick={handleCloseDrawer}>
              Cancelar
            </BenvoButton>
            <BenvoButton variant="primary" type="submit">
              Editar
            </BenvoButton>
          </ButtonsContainer>
        </StyledForm>
      </BenvoDrawer>
    </>
  );
}
