const { Box, styled, Typography } = require('@mui/material');

export const StyledBenvoContentBox = styled(Box)`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  height: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 9px;
    background: linear-gradient(90deg, rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%);
  }
`;

export const StyledTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.027px;
  padding: 25px 16px 16px 16px;
`;
