export const createEmployeeOptions = (employees) => {
  if (employees.length) {
    return employees.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }

  return [];
};

export const createResidentOptions = (residents) => {
  if (residents.length) {
    return residents.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }

  return [];
};

export const createMedicineOptions = (medicines) => {
  if (medicines.length) {
    return medicines.map(({ id, item }) => ({
      value: id,
      label: item,
    }));
  }

  return [];
};
