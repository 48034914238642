import {
  styled, Box, Typography,
} from '@mui/material';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/close-square-button-icon.svg';

export const KeySelectorBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.035px;
`;

export const KeyUploadAndPasswordBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
`;

export const KeyActionBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledSelectorA1 = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F7;
  color: #474750;
  border-radius: 6px;
  padding: 30px 10px;
  cursor: pointer;
`;

export const StyledTypography12pxInsideInput = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 0.12px;
  padding-top: 8px;
`;

export const StyledTypography10pxInsideInput = styled(Typography)`
  font-size: 10px;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 0.1px;
`;

export const StyledRemoveIcon = styled(RemoveIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
