import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getTokenInfo } from '../../../../utils/configAuth';
import routes from '../../../../services/routes';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search-button-icon.svg';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/clear-button-icon.svg';
import { ReactComponent as PlusSquare } from '../../../../assets/icons/plus-square-button-icon.svg';
import { ReactComponent as MinusSquare } from '../../../../assets/icons/minus-square-button-icon.svg';
import { ReactComponent as TrashSquare } from '../../../../assets/icons/trash-square-button-icon.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close-icon.svg';
import { ReactComponent as GrayMockedImage } from '../../../../assets/mocked-image-gray.svg';
import { isSuperAdmin } from '../../../../utils/checkUser';
import BenvoImage from '../../../../components/BenvoImage';

const { product: productRoute, superadmin } = routes;

export default function InsertItems({ addProductToMarketplaceList, updateMarketplaceList }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const ilpiId = getTokenInfo('ilpiId');

  const getAllProducts = async () => {
    const { message } = await superadmin.getAllProducts({ name: searchValue });
    return message;
  };

  const getAvailableProductList = async () => {
    const { message } = await productRoute.getProductsByIlpi({ ilpiId }, {
      name: searchValue,
    });
    return message;
  };

  const {
    data: searchResult = [],
    isInitialLoading,
    isError,
  } = useQuery({
    queryKey: ['products', searchValue.length >= 3 && searchValue],
    queryFn: isSuperAdmin() ? getAllProducts : getAvailableProductList,
    onSuccess: (data) => {
      setFilteredProductList(data.rows);
    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    enabled: searchValue.length >= 3,
    retry: 0,
    cacheTime: 0,
  });

  const filterProducts = (event) => {
    setSearchValue(event.target.value);

    const removeAccents = (string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    if (!event.target.value) {
      setFilteredProductList([]);
      return;
    }

    const filteredProducts = searchResult.rows?.filter((product) => {
      const productName = removeAccents(product.name.toLowerCase());
      const currentSearchValue = removeAccents(event.target.value.toLowerCase());

      return productName.includes(currentSearchValue);
    });

    setFilteredProductList(filteredProducts);
  };

  const handleAddToSelectedList = (product) => {
    const existingProduct = selectedProductList.find(
      (item) => item.name === product.name,
    );

    if (existingProduct) {
      const updatedProductList = selectedProductList.map((item) => {
        if (item.name === product.name) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });
      setSelectedProductList(updatedProductList);
      return;
    }

    if (product.sku) {
      setSelectedProductList((prevState) => [
        {
          name: product.name,
          sku: product.sku,
          category: product.category,
          quantity: 1,
        },
        ...prevState,
      ]);
    } else {
      setSelectedProductList((prevState) => [
        {
          name: product.name,
          groupId: product.groupId,
          type: product.type,
          quantity: 1,
        },
        ...prevState,
      ]);
    }
  };

  const incrementItemQuantity = (item) => {
    setSelectedProductList((prevState) => prevState.map((i) => {
      if (i.name === item.name) {
        return {
          ...i,
          quantity: i.quantity + 1,
        };
      }
      return i;
    }));
  };

  const decrementItemQuantity = (item) => {
    setSelectedProductList((prevState) => prevState.map((i) => {
      if (i.name === item.name && i.quantity > 1) {
        return {
          ...i,
          quantity: i.quantity - 1,
        };
      }
      return i;
    }));
  };

  const handleRemoveFromSelectedList = (item) => {
    setSelectedProductList((prevState) => prevState.filter((i) => i.name !== item.name));
  };

  const handleQuantityChange = (item, e) => {
    const { value } = e.target;
    if (value === '' || value === '0') {
      setSelectedProductList((prevState) => prevState.map((i) => {
        if (i.name === item.name) {
          return {
            ...i,
            quantity: 1,
          };
        }
        return i;
      }));
      return;
    }

    setSelectedProductList((prevState) => prevState.map((i) => {
      if (i.name === item.name) {
        return {
          ...i,
          quantity: Number(value),
        };
      }
      return i;
    }));
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setSearchValue('');
    setFilteredProductList([]);
  };

  return (
    <Box>
      {openDrawer && (
        <IconButton
          onClick={handleCloseDrawer}
          sx={{
            position: 'fixed',
            top: '40px',
            right: '600px',
            backgroundColor: '#4A397F',
            zIndex: '1300',
            ':hover': {
              backgroundColor: '#FE663F',
            },
          }}
        >
          <Close color="white" />
        </IconButton>
      )}
      <Button
        onClick={() => {
          handleOpenDrawer();
          updateMarketplaceList();
        }}
        variant="contained"
        sx={{
          backgroundColor: '#F5F5F7',
          color: '#4A397F',
          textTransform: 'none',
          fontSize: '14px',
          borderRadius: '80px',
          boxShadow: 'none',
          padding: '6px 17px',

          '&:hover': {
            backgroundColor: '#F5F5F7',
          },
        }}
      >
        Adicionar Item
      </Button>
      <Drawer
        data-testid="add-items-drawer"
        anchor="right"
        open={openDrawer}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '& .MuiDrawer-paper': {
            width: '100%',
            maxWidth: '582px',
            padding: '40px',

            '&::before': {
              content: '""',
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              height: '9px',
              background:
                'linear-gradient(90deg, rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%)',
            },
          },
        }}
      >
        <Typography
          variant="subtitle1"
          color="#474750"
          sx={{
            fontWeight: '700',
            fontSize: '18px',
          }}
        >
          Adicionar Itens
        </Typography>
        <Divider
          sx={{
            mt: '25px',
            opacity: '1.25',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            mt: '32px',
          }}
        >
          <TextField
            data-testid="search-item-input"
            label="Pesquisar"
            size="small"
            sx={{
              backgroundColor: '#F5F5F7',
              flex: '1',
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
            value={searchValue}
            onChange={(event) => filterProducts(event)}
          />
          <IconButton>
            {searchValue ? (
              <ClearIcon
                data-testid="clear-input-icon"
                onClick={() => {
                  setSearchValue('');
                  setFilteredProductList([]);
                }}
              />
            ) : (
              <SearchIcon />
            )}
          </IconButton>
        </Box>
        {isInitialLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '220px',
              mt: '4px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              mt: '4px',
              borderRadius: '8px',
              overflow: 'hidden',
              maxHeight: '220px',
              overflowY: 'auto',

              '&::-webkit-scrollbar': {
                width: '4px',
                height: '4px',
                backgroundColor: '#F5F5F7',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#4A397F',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#F5F5F7',
              },
            }}
          >
            {isError && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '220px',
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="#474750"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '170%',
                  }}
                >
                  Produto não encontrado
                </Typography>
              </Box>
            )}
            {filteredProductList?.map((product) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: '#fff',
                  padding: '12px',
                  '&:nth-of-type(odd)': {
                    backgroundColor: '#F5F5F7',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                  }}
                >
                  <Box sx={{ width: '55px', height: '55px' }}>
                    <BenvoImage
                      src={product.image}
                      alt={product.name}
                      DefaultImage={GrayMockedImage}
                      sx={{ minWidth: '55px' }}
                    />
                  </Box>

                  <Typography
                    variant="subtitle1"
                    color="#474750"
                    sx={{
                      fontSize: '12px',
                      lineHeight: '170%',
                      maxWidth: '300px',
                    }}
                  >
                    {product.name}
                  </Typography>
                </Box>
                {product.group && (
                  <Box
                    sx={{
                      mr: 'auto',
                      ml: '12px',
                      padding: '5px 10px',
                      borderRadius: '2px',
                      backgroundColor: '#4A397F',
                      color: '#fff',
                      fontSize: '10px',
                    }}
                  >
                    Grupo
                  </Box>
                )}
                <Box>
                  <IconButton
                    data-testid="include-item-to-list-btn"
                    onClick={() => {
                      handleAddToSelectedList(product);
                    }}
                  >
                    <PlusSquare />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        )}
        <Divider
          sx={{
            mt: '24px',
            opacity: '1.25',
          }}
        />
        {selectedProductList?.length > 0 && (
          <Typography
            variant="subtitle1"
            color="#474750"
            sx={{
              fontSize: '14px',
              mt: '24px',
            }}
          >
            Itens selecionados
          </Typography>
        )}
        <Box
          sx={{
            mt: '12px',
            borderRadius: '8px',
            overflow: 'hidden',
            maxHeight: '308px',
            overflowY: 'auto',

            '&::-webkit-scrollbar': {
              width: '4px',
              height: '4px',
              backgroundColor: '#F5F5F7',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#4A397F',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#F5F5F7',
            },
          }}
        >
          {selectedProductList?.map((product) => (
            <Box
              data-testid="item-card"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '#fff',
                padding: '12px',
                '&:nth-of-type(odd)': {
                  backgroundColor: '#F5F5F7',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="#474750"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '170%',
                    maxWidth: '300px',
                  }}
                >
                  {product.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  data-testid="remove-item-from-list"
                  onClick={() => {
                    handleRemoveFromSelectedList(product);
                  }}
                >
                  <TrashSquare />
                </IconButton>
                <IconButton
                  data-testid="decrement-quantity-icon"
                  onClick={() => {
                    decrementItemQuantity(product);
                  }}
                >
                  <MinusSquare data-testid="decrement-quantity-icon" />
                </IconButton>
                <TextField
                  data-testid="quantity-input-field"
                  size="small"
                  variant="standard"
                  onChange={(event) => handleQuantityChange(product, event)}
                  sx={{
                    width: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& input': {
                      textAlign: 'center',
                    },
                    '& .MuiInput-underline:before': {
                      borderBottom: 'none',
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottom: 'none',
                    },
                  }}
                  value={product.quantity || 0}
                />
                <IconButton
                  data-testid="increment-quantity-icon"
                  onClick={() => {
                    incrementItemQuantity(product);
                  }}
                >
                  <PlusSquare data-testid="increment-quantity-icon" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            mt: '22px',
            gap: '13px',
            flex: '1',
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#F5F5F7',
              color: '#4A397F',
              textTransform: 'none',
              fontSize: '14px',
              borderRadius: '80px',
              boxShadow: 'none',
              padding: '6px 36px',

              '&:hover': {
                backgroundColor: '#F5F5F7',
              },
            }}
            onClick={() => {
              setSelectedProductList([]);
            }}
          >
            Limpar Lista
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#4A397F',
              color: '#FFFFFF',
              textTransform: 'none',
              fontSize: '14px',
              borderRadius: '80px',
              boxShadow: 'none',
              padding: '6px 40px',

              '&:hover': {
                backgroundColor: '#4A397F',
              },
            }}
            onClick={() => {
              addProductToMarketplaceList(selectedProductList);
              handleCloseDrawer();
              setSelectedProductList([]);
            }}
          >
            Adicionar Itens
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}
