import { Box, styled } from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';
import ReferImage from '../../../../assets/home/refer.webp';
import ReferImageMobile from '../../../../assets/home/refer-mobile.webp';

export const Card = styled(Box)`
  padding: 0 7px 22.26px 0;
  min-width: 196px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 12px;
  background: url(${ReferImage}) no-repeat;

  @media (max-width: 768px) {
    width: 350px;
    height: 280.0544px;
    background: url(${ReferImageMobile}) no-repeat;
    justify-content: flex-start;
    gap: 12px;
    padding: 59.06px  0 0;
  }

  a {
    text-decoration: none;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    h2 {
      color: #fff;
      text-align: right;
      font-size: 22px;
      font-weight: 600;
      line-height: 130%; /* 28.6px */

      width: 100%;
      max-width: 144px;
      margin-bottom: 201.65px;

      @media (max-width: 768px) {
        font-size: 20px;
        font-weight: 700;
        line-height: 130%; /* 26px */
        letter-spacing: 0.03px;
        margin-bottom: 0;
        text-align: left;

        max-width: 208px;
      }
    }
  }
`;

export const Button = styled(BenvoButton)`
  width: 146px;
  padding: 16px 0px;
  justify-self: center;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 170px;
    margin-right: 38.02px;
  }
`;
