import React from 'react';
import {
  Box, Radio,
} from '@mui/material';
import { status } from '../cardHelper';
import BenvoButton from '../../../../../../components/BenvoButton/style';
import {
  CardSelectorBox, CreditCardBox,
  CreditCardListBox, Heading2, ModalActionsButtonsBox, Separator,
} from '../style';
import CreditCardControl from './CreditCardControl';

function CardSelector({
  creditCards, selectedCard, selectCard, deleteCard, onClose, handleSteps,
}) {
  const handleNextStep = () => {
    if (selectedCard === null) {
      handleSteps(status.REGISTERING);
    } else {
      handleSteps(status.PAYING);
    }
  };

  return (
    <CardSelectorBox>
      <Box>
        <Heading2 sx={{ mb: '12px' }}>Forma de pagamento</Heading2>
        <CreditCardListBox>
          <CreditCardBox onClick={() => selectCard(null)}>
            <Radio
              data-testid="registerCreditCardRadioBtn"
              checked={selectedCard === null}
            />
            Cartão de crédito
          </CreditCardBox>
          {creditCards.length ? creditCards.map((creditCard) => (
            <CreditCardControl
              data-testid="creditCardRadioBtn"
              key={creditCard.token}
              creditCard={creditCard}
              selectedCard={selectedCard}
              selectCard={selectCard}
              deleteCard={deleteCard}
            />
          )) : null}
        </CreditCardListBox>
      </Box>
      <Separator />
      <ModalActionsButtonsBox>
        <BenvoButton
          onClick={onClose}
          variant="secondary"
        >
          Desistir
        </BenvoButton>
        <BenvoButton
          onClick={handleNextStep}
        >
          Continuar
        </BenvoButton>
      </ModalActionsButtonsBox>
    </CardSelectorBox>
  );
}

export default CardSelector;
