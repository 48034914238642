/* eslint-disable max-len */
import {
  CircularProgress,
  Divider, IconButton, Skeleton, Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import routes from '../../services/routes';
import { getTokenInfo } from '../../utils/configAuth';
import CreateOrEditResident from './components/CreateOrEditResident';
import ListResidentInformation from './components/ListResidentInformation';
import SearchResidentField from './components/SearchResidentField';
import {
  BenvoContainer,
  CardsContainer,
  CheckboxContainer,
  Container,
  ContainerActions,
  ContainerDivider,
  ContainerResidentInformation,
  ContainerSearchAndActions,
  ContainerTitle,
  ContainerTitleSearchAndActions,
  CreateResidentDesktopContainer,
  CreateResidentMobileContainer,
  NewResidentCard,
  NewResidentCardTextContainer,
  ResidentListContainer,
  SendAllListsContainer,
  StyledCheckBox,
} from './style';
import DynamicScroll from '../../components/DynamicScroll';
import BenvoEmpty from '../../components/BenvoEmpty';
import useInfiniteList from '../../hooks/useInfiniteList';
import BenvoButton from '../../components/BenvoButton/style';
import SendAllOpenShoppingListsModal from './components/SendAllOpenShoppingListsModal';
import ArrowRight from '../../assets/icons/ArrowRight';
import stateMachine from '../../utils/stateMachine';

const { resident: residentRoute } = routes;

// const shoppintListStatus = [
//   {
//     value: `${shoppingListStatuses.LIST_CREATED},${shoppingListStatuses.LIST_UPDATED},${shoppingListStatuses.ORDER_CREATED}`,
//     label: shoppingListStatus.LIST_CREATED,
//   },
//   {
//     value: `${shoppingListStatuses.LIST_SYNCED},${shoppingListStatuses.ORDER_SHARED}`,
//     label: shoppingListStatus.LIST_SYNCED,
//   },
//   {
//     value: shoppingListStatuses.PAYMENT_CONFIRMED,
//     label: shoppingListStatus.PAYMENT_CONFIRMED,
//   },
//   {
//     value: `${shoppingListStatuses.PAYMENT_REFUNDED},${shoppingListStatuses.ORDER_CANCELLED}`,
//     label: shoppingListStatus.PAYMENT_REFUNDED,
//   },
//   {
//     value: shoppingListStatuses.ORDER_DELIVERED,
//     label: shoppingListStatus.ORDER_DELIVERED,
//   },
//   {
//     value: shoppingListStatuses.ORDER_EXPIRED,
//     label: shoppingListStatus.ORDER_EXPIRED,
//   },
//   {
//     value: shoppingListStatuses.ORDER_FAILED,
//     label: shoppingListStatus.ORDER_FAILED,
//   },
//   {
//     value: `${shoppingListStatuses.LIST_CLOSED},${shoppingListStatuses.ORDER_CLOSED}`,
//     label: shoppingListStatus.LIST_CLOSED,
//   },
//   {
//     value: shoppingListStatuses.LIST_NOT_SENT,
//     label: shoppingListStatus.LIST_NOT_SENT,
//   },
// ];

export default function Residents() {
  const [searchValue, setSearchValue] = useState({
    name: '',
    shoppingListStatus: '',
  });
  const [selectedLists, setSelectedLists] = useState([]);
  const ilpiId = getTokenInfo('ilpiId');
  const features = getTokenInfo('features');
  const [checkboxLoading, setCheckboxLoading] = useState(false);

  const hasShoppingListFeat = useMemo(() => features?.shoppingList === 1, [features]);

  const getResidentInformation = async (queries) => {
    try {
      const { message } = await residentRoute.searchResidentByIlpi({ ilpiId }, queries);
      return message;
    } catch (error) {
      return [];
    }
  };

  const getAllIds = async () => {
    setCheckboxLoading(true);
    const { message } = await routes.shoppingList.getAllIdsByIlpiId({ ilpiId }, { open: '1' });
    setCheckboxLoading(false);

    return message;
  };

  const {
    data: residentInfo = [],
    nextPage,
    setQueries,
    loading,
    finished,
    refetch,
  } = useInfiniteList({ getData: getResidentInformation });

  const shouldShowSelectAll = residentInfo.some((resident) => resident.shoppingListId && stateMachine[resident.shoppingListStatus].isSendable);

  const searchResident = async () => {
    setQueries({
      name: searchValue.name,
      shoppingListStatus: searchValue.shoppingListStatus,
    });
  };

  const validShoppingListIds = useMemo(
    () => residentInfo.filter((resident) => resident.shoppingListId
      && stateMachine[resident.shoppingListStatus].isSendable).map((resident) => resident.shoppingListId),
    [residentInfo],
  );

  const handleSelectAll = async (event) => {
    if (event.target.checked) {
      if (residentInfo.length > 9) {
        setSelectedLists(await getAllIds());
      } else setSelectedLists(validShoppingListIds);
    } else {
      setSelectedLists([]);
    }
  };

  useEffect(() => {
    if (searchValue.shoppingListStatus) {
      searchResident();
    }
  }, [searchValue.shoppingListStatus]);

  return (
    <Container>
      <CardsContainer>
        <CreateResidentMobileContainer>
          <CreateOrEditResident refetchResidentList={refetch}>
            <NewResidentCard>
              <NewResidentCardTextContainer>
                <Typography variant="h3">Novo Residente</Typography>
                <Typography variant="body1">
                  Adicione regularmente os dados dos residentes para garantir precisão e relevância.
                </Typography>
              </NewResidentCardTextContainer>
              <IconButton>
                <ArrowRight />
              </IconButton>
            </NewResidentCard>
          </CreateOrEditResident>
        </CreateResidentMobileContainer>
      </CardsContainer>

      <Divider flexItem />

      <BenvoContainer>
        <ContainerTitle variant="h2">Residentes</ContainerTitle>
        <ContainerTitleSearchAndActions>
          <Typography variant="h2">Residentes</Typography>
          <ContainerSearchAndActions>
            <ContainerActions>
              <CreateResidentDesktopContainer>
                <CreateOrEditResident refetchResidentList={refetch}>
                  <BenvoButton horizontalPadding={26} variant="secondary" data-testid="create-resident">
                    Novo Residente
                  </BenvoButton>
                </CreateOrEditResident>
              </CreateResidentDesktopContainer>

              <ContainerDivider orientation="vertical" flexItem />

              {/*  <StyledSelect
                onChange={(event) => {
                  setSearchValue((prevState) => ({ ...prevState, shoppingListStatus: event.target.value }));
                }}
                displayEmpty
                value={searchValue.shoppingListStatus}
                input={<OutlinedInput />}
              >
                <StyledMenuItem value="" onClick={refetch}>
                  Status
                </StyledMenuItem>

                {shoppintListStatus.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </StyledSelect>
                */}

              <SearchResidentField
                hiddenLabel
                placeholder="Pesquisar por CPF ou nome"
                onChange={(event) => setSearchValue((prevState) => ({ ...prevState, name: event.target.value }))}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    searchResident();
                  }
                }}
                searchValue={searchValue.name}
                value={searchValue.name}
                onClick={() => {
                  searchResident();
                }}
                data-testid="search-bar"
              />

              <ContainerDivider orientation="vertical" flexItem />

              <SendAllListsContainer>
                {loading ? (
                  <Skeleton variant="rounded" width={191} height={40} />
                ) : (
                  <SendAllOpenShoppingListsModal
                    refetch={refetch}
                    shoppingListIds={selectedLists}
                    setSelectedLists={setSelectedLists}
                  >
                    <BenvoButton disabled={!selectedLists.length} variant="secondary" horizontalPadding={24}>
                      Compartilhar Listas
                    </BenvoButton>
                  </SendAllOpenShoppingListsModal>
                )}
              </SendAllListsContainer>
            </ContainerActions>
          </ContainerSearchAndActions>
        </ContainerTitleSearchAndActions>

        {residentInfo.length > 0 && shouldShowSelectAll && (
        <CheckboxContainer>
          {checkboxLoading ? (
            <CircularProgress size={20} />
          ) : (
            <StyledCheckBox
              onChange={handleSelectAll}
              checked={
                  selectedLists.length >= validShoppingListIds.length
                  && validShoppingListIds.length > 0
                }
            />
          )}

          <p>Selecionar todos</p>
        </CheckboxContainer>
        )}
        <ContainerResidentInformation data-testid="resident-list">
          <DynamicScroll onScrollEnd={nextPage} finished={finished} loading={loading}>
            <ResidentListContainer>
              {residentInfo?.map((resident) => (
                <ListResidentInformation
                  key={resident.id}
                  residentInfo={resident}
                  getResidentInformation={refetch}
                  hasShoppingListFeat={hasShoppingListFeat}
                  setSelectedLists={setSelectedLists}
                  selectedLists={selectedLists}
                />
              ))}
            </ResidentListContainer>
          </DynamicScroll>
          <BenvoEmpty
            active={!loading && !residentInfo.length}
            title="Residentes"
            message="Comece agora mesmo a cadastrar os residentes da sua ILPI!"
          />
        </ContainerResidentInformation>
      </BenvoContainer>
    </Container>
  );
}
