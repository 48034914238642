import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Box, Modal, Paper } from '@mui/material';
import routes from '../../../../services/routes';
import CardVisualizedPrescription from './components/CardVisualizedPrescription/CardVisualizedPrescription';
import GenerateRecipe from './components/GenerateRecipe/GenerateRecipe';

function ModalVisualizedPrescription({
  isOpen,
  handleModalVisualizedClose,
  selectedResidentName,
  prescriptionId,
}) {
  const { prescription } = routes;
  const [dateInit, setDateInit] = useState('');
  const [dateFim, setDateFim] = useState('');
  const [medicineList, setMedicineList] = useState([]);
  const [modalTableIsLoading, setModalTableIsLoading] = useState(false);

  const populateModalData = (responseObj) => {
    setDateInit(responseObj.validateStartDate);
    setDateFim(responseObj.validateExpirationDate);
  };
  const requestSearch = async (thisPrescriptionId) => {
    setModalTableIsLoading(true);
    try {
      const { message } = await prescription.getPrescriptionById({
        prescriptionId: thisPrescriptionId,
      });
      setMedicineList(message);
      populateModalData(message);
    } catch (error) {
      console.log(error);
    } finally {
      setModalTableIsLoading(false);
    }
  };

  const handleClose = () => {
    handleModalVisualizedClose();
  };

  useEffect(() => {
    requestSearch(prescriptionId);
  }, [prescriptionId]);

  return (
    <Modal
      overflow={false}
      size="lg"
      height={600}
      open={isOpen}
      onClose={() => handleClose()}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component={Paper}
        sx={{
          width: { sx: '90%', sm: '70%' },
          p: '20px',
        }}
      >
        <div className="title-information">
          <p>
            Prescrição de
            {' '}
            {selectedResidentName}
          </p>
        </div>

        <div className="date_fields">
          <div
            className="date_field_item"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexWrap: 'nowrap',
              margin: '10px 0px',
            }}
          >
            {dateInit && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '350px',
                }}
              >
                <h5>Data Inicio da Prescrição </h5>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    border: '1px solid #F2F2F5',
                    textAlign: 'center',
                    padding: '0px 10px',
                  }}
                >
                  <p style={{ marginBottom: '0px' }}>
                    {moment(dateInit).format('D/MM/YYYY')}
                  </p>
                </div>
              </div>
            )}
            {dateFim && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '350px',
                }}
              >
                <h5>Data Final da Prescrição </h5>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    border: '1px solid #F2F2F5',
                    textAlign: 'center',
                    padding: '0px 10px',
                  }}
                >
                  <p style={{ marginBottom: '0px' }}>
                    {moment(dateFim).format('D/MM/YYYY')}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <Box sx={{ marginBlock: '12px' }}>
          <GenerateRecipe prescription={medicineList} />
        </Box>

        <div className="prescription_table" style={{ marginBottom: '20px' }}>
          <CardVisualizedPrescription
            medicineList={medicineList}
            modalTableIsLoading={modalTableIsLoading}
          />
        </div>
      </Box>
    </Modal>
  );
}

export default ModalVisualizedPrescription;
