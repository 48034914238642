import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import NotFound from '../notFound';
import { useDoctorLP } from '../../context/doctorLPContext';
import './index.css';
import Recipes from './Recipes/Recipes';
import {
  StyledBoxLogin, StyledLeftBox, StyledRightBox, StyledDoctorInfoBox,
  StyledTypographyFooter,
  StyledBoxPage,
  StyledBenvoContentBox,
} from './styles';

import Concierge from './Concierge/Concierge';
import DoctorLogin from './components/DoctorLogin';
import { BENVO_FOOTER } from '../../constants';

import BenvoDoctorLoginImage from '../../assets/BenvoDoctorLoginImage.png';
import TopbarExternal from '../../components/TopBarExternal';

function DoctorLandingPage() {
  const doctorLP = useDoctorLP();
  const { type } = useParams();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const drawerHandler = {
    open: drawerOpen,
    handle: () => {
      setDrawerOpen(!drawerOpen);
    },
    recipes: type,
  };

  const renderPage = () => {
    if (type === 'receitas') return (<Recipes drawerHandler={drawerHandler} />);
    if (type === 'exames') return (<Concierge drawerHandler={drawerHandler} />);
    return (<NotFound />);
  };

  if (doctorLP.doctor) {
    return (
      <StyledBoxPage>
        <TopbarExternal doctorName={doctorLP.doctor.name} />
        <StyledBenvoContentBox>
          {renderPage()}
        </StyledBenvoContentBox>
      </StyledBoxPage>
    );
  }

  return (
    <StyledBoxLogin>
      <StyledTypographyFooter>
        {BENVO_FOOTER}
      </StyledTypographyFooter>
      <StyledLeftBox>
        <StyledDoctorInfoBox
          sx={{ width: { xs: '100%', sm: '436px' } }}
        >
          <DoctorLogin />
        </StyledDoctorInfoBox>
      </StyledLeftBox>
      <StyledRightBox
        sx={{
          width: { lg: '55%' },
          backgroundImage: { xs: 'none', lg: `url(${BenvoDoctorLoginImage})` },
        }}
      />
    </StyledBoxLogin>
  );
}

export default DoctorLandingPage;
