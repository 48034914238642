import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Snackbar,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import routes from '../../services/routes';
import { getTokenInfo } from '../../utils/configAuth';
import CHANGE_PASSWORD_MESSAGES from './toastMessages';
import PATHS from '../../constants/applicationPaths';

// Page
export default function ChangePassword() {
  const [userPayload, setUserPayload] = useState({
    password: '',
    newPassword: '',
  });
  const [snack, setSnack] = useState({
    open: false,
    message: '',
  });
  const [checkPassword, setCheckPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const { users } = routes;

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const id = getTokenInfo('id');
    try {
      await users.changeUserPassword({ userId: id }, userPayload);

      setSnack({ open: true, message: CHANGE_PASSWORD_MESSAGES.success });

      setTimeout(() => {
        navigate(PATHS.home);
      }, 3000);
    } catch (error) {
      setSnack({ open: true, message: CHANGE_PASSWORD_MESSAGES.error });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    setUserPayload({ ...userPayload, [name]: value });
  };

  const handlePasswordConfirmation = (event) => {
    const { value } = event.target;
    setCheckPassword(value);
  };

  const handleCloseSnackbar = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({ open: false, message: '' });
  };

  useEffect(() => {
    if (checkPassword === userPayload.newPassword && userPayload.newPassword !== '') {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [checkPassword]);

  return (
    <>
      <Container component="main" maxWidth="s">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h4">
            Altere sua senha
          </Typography>
          <Box
            component="form"
            onSubmit={onSubmit}
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid item>
                <TextField
                  name="password"
                  id="password"
                  label="Senha atual"
                  type="password"
                  onChange={handleChange}
                  required
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item>
                <TextField
                  name="newPassword"
                  id="newPassword"
                  label="Nova senha"
                  type="password"
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  name="checkPassword"
                  id="checkPassword"
                  label="Confirme a nova senha"
                  type="password"
                  onChange={handlePasswordConfirmation}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              disabled={disabledButton}
              size="large"
            >
              {loading ? <CircularProgress sx={{ color: '#fff' }} size={25} /> : 'Confirmar alteração'}
            </Button>
          </Box>
        </Box>
      </Container>
      <Snackbar
        open={snack.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {snack.message}
      </Snackbar>
    </>
  );
}
