import {
  Box, Checkbox, Divider, styled, Typography,
} from '@mui/material';

export const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
`;

export const PageTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
  margin-bottom: 10px;
`;

export const ResidentListItem = styled('label')`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border: 1px solid var(--grey-variation-lightest-grey, #f5f5f7);
  &:nth-of-type(even) {
    background-color: var(--grey-variation-light-grey, #fefefe);
  }
`;

export const ResidentListItemInformation = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ResidentName = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const ResidentAge = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.04px;
`;
export const StyledDivider = styled(Divider)`
  background-color: var(--grey-variation-light-grey, red);
  &:nth-of-type(odd) {
    background-color: var(--grey-variation-light-grey, #fff);
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0 !important;
`;
