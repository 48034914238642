import React from 'react';

import BenvoButton from '../../../../components/BenvoButton/style';
import ContactChangeImage from '../../../../assets/residents/contactChangeImage.webp';
import {
  StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle,
} from './style';

export default function ReferenceChangeModal({ contactChange, handleClose, onConfirm }) {
  return (
    <StyledDialog open={contactChange.open} onClose={handleClose}>
      <img src={ContactChangeImage} alt="Alteração de dados de contato" />
      <StyledDialogTitle>Alteração de dados de contato</StyledDialogTitle>
      <StyledDialogContent>
        <p>
          Atenção! As mudanças feitas serão aplicadas a todos os residentes que
          compartilham este contato, conforme listado abaixo. Tem certeza que deseja alterar esses dados?
        </p>
        <ul>
          {contactChange.contact?.map((contact) => (
            <li key={contact}>
              {contact}
            </li>
          ))}
        </ul>
      </StyledDialogContent>
      <StyledDialogActions>
        <BenvoButton onClick={handleClose} variant="secondary" horizontalPadding={32.25}>
          Cancelar
        </BenvoButton>
        <BenvoButton onClick={onConfirm} horizontalPadding={51.75}>
          Sim
        </BenvoButton>
      </StyledDialogActions>
    </StyledDialog>
  );
}
