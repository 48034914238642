const { TextField, styled } = require('@mui/material');

const StyledSearchField = styled(TextField)`
  border-radius: 8px;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
  background: var(--grey-variation-lightest-grey, #f5f5f7);

  & .MuiOutlinedInput-root {
    width: 100%;
    ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
    height: 40px;
    padding: 10px 12px;
    color: var(--grey-variation-dark-grey, #474750);
    

    @media (max-width: 768px) {
      max-width: 100%;
    }

    & fieldset {
      border: none;
    }
    &:hover fieldset {
      border: none;
    }
    &.Mui-focused fieldset {
      border: none;
    }

    &.MuiInputBase-root {
      input {
        padding: 0px !important;
      }
    }
  }
`;

export default StyledSearchField;
