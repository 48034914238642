import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogTitle,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import moment from 'moment';
import ContainerPage from '../../templates/containerPage/ContainerPage';
import { getTokenInfo } from '../../utils/configAuth';
import { TODAY, THIRTTY_DAYS_AGO, TOMORROW } from '../../utils/daysConstants';

import { useAlert } from '../../context/alertContext';
import Loading from '../../components/Loading/Loading';
import CreateTaskModel from './components/CreateTaskModel';
import EditTaskModel from './components/EditTaskModel';
import GenerateTableTasks from './components/GenerateTaskTable';
import TasksFilter from './components/TasksFilter';
import { validateTime } from './utils/utils';
import { convertAPIDate } from '../../utils/dateFormat';

import routes from '../../services/routes';

export default function Tasks() {
  const [isLoading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [residents, setResidents] = useState([]);
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);
  const [showDeleteTaskOn, setShowModelDeleteTaskOn] = useState(false);
  const [editTaskModal, setEditTaskModal] = useState({
    open: false,
    data: {
      untilDate: TODAY,
      startTime: moment(TODAY).format('YYYY-MM-DDTHH:00'),
    },
  });
  const [createTaskModal, setCreateTaskModal] = useState({
    open: false,
  });
  const [timeError, setTimeError] = useState({
    message: '',
    open: false,
  });

  const alert = useAlert();

  const ilpiId = getTokenInfo('ilpiId');

  const getTasks = async (searchData) => {
    const queries = {
      page,
    };
    if (searchData) {
      const {
        beginDate, endDate, title, type, employeeId, status,
      } = searchData;
      queries.beginDate = beginDate;
      queries.endDate = endDate;
      if (title) queries.title = title;
      if (type) queries.type = type;
      if (employeeId) queries.employeeId = employeeId;
      if (status) queries.status = status;
    } else {
      queries.beginDate = convertAPIDate(THIRTTY_DAYS_AGO);
      queries.endDate = convertAPIDate(TOMORROW);
    }

    try {
      const {
        message: { result, totalPages: thisTotalPages },
      } = await routes.task.findTasksByIlpi({ ilpiId }, queries);

      if (!result.length) alert.warning('Nenhuma tarefa encontrada.');

      setTasks(result);
      setTotalPages(thisTotalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const createTask = async (data) => {
    const { employeeId, ...body } = data;
    try {
      await routes.task.createTask({ employeeId }, body);
      setCreateTaskModal({
        open: false,
      });
      await getTasks();
      return '';
    } catch (error) {
      console.error(error);
      return {
        message:
          'Não foi possível criar a tarefa. Revise os dados ou tente novamente mais tarde.',
      };
    }
  };

  const updateTask = async () => {
    const { data, id } = editTaskModal;

    data.startTime = validateTime(editTaskModal.data.startTime);
    data.untilDate = moment(editTaskModal.data.untilDate).format('YYYY-MM-DD');
    delete data.medicineId;
    delete data.residentId;
    delete data.medicineName;
    delete data.recurrence;

    try {
      await routes.task.updateTask({ taskId: id }, data);

      setEditTaskModal({
        open: false,
        data: {
          untilDate: TODAY,
          startTime: moment(TODAY).format('YYYY-MM-DDTHH:00'),
        },
      });

      alert.success('Tarefa atualizada com sucesso!');
      await getTasks();
    } catch (err) {
      console.error(err);
      alert.error(
        'Não foi possível atualizar a tarefa. Tente novamente mais tarde.',
      );
    }
  };

  const deleteTask = async () => {
    try {
      await routes.task.removeTask({ taskId: taskIdToDelete });
      alert.success('Tarefa excluída com sucesso!');
    } catch (err) {
      console.error(err);
      alert.error(
        'Não foi possível excluir a tarefa. Tente novamente mais tarde.',
      );
    } finally {
      setTaskIdToDelete(null);
      setShowModelDeleteTaskOn(false);
      await getTasks();
    }
  };

  const getEmployees = async () => {
    try {
      const { message } = await routes.employee.findEmployee(
        { ilpiId },
        { employeeType: 'caregiver' },
      );

      setEmployees(message.rows);
    } catch (error) {
      console.error(error);
    }
  };

  const getResidents = async () => {
    try {
      const { message } = await routes.resident.getAllResidentsByIlpiWithoutPagination({
        ilpiId,
      });
      setResidents(message);
    } catch (error) {
      console.error(error);
    }
  };

  const allFetch = async () => {
    try {
      await getTasks();
      await getEmployees();
      await getResidents();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (task) => {
    const treatedTask = Object.entries(task).reduce((acc, [key, value]) => {
      if (value) return { ...acc, [key]: value };
      return acc;
    }, {});

    const {
      id,
      type,
      createdAt,
      updatedAt,
      deletedAt,
      caregiverName,
      residentName,
      startTime,
      ...currTask
    } = treatedTask;

    setEditTaskModal((prev) => ({
      ...prev,
      open: true,
      data: {
        ...prev.data,
        ...currTask,
        startTime: moment().format(`YYYY-MM-DDT${startTime}`),
      },
      id,
      type,
    }));
  };

  useEffect(() => {
    getTasks();
  }, [page]);

  useEffect(() => {
    allFetch();
  }, []);

  return (
    <ContainerPage
      title="Gestão de tarefas"
      description="Aqui você pode visualizar, criar e editar tarefas dos cuidadores."
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            mt: '20px',
          }}
        >
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TasksFilter
                  employees={employees}
                  handleSubmit={getTasks}
                  setCreateTaskModal={setCreateTaskModal}
                />
              </Grid>
            </Grid>
            <CreateTaskModel
              createTaskModal={createTaskModal}
              setCreateTaskModal={setCreateTaskModal}
              employees={employees}
              residents={residents}
              timeError={timeError}
              setTimeError={setTimeError}
              createTask={createTask}
            />
          </Box>
          <EditTaskModel
            editTaskModal={editTaskModal}
            setEditTaskModal={setEditTaskModal}
            employees={employees}
            residents={residents}
            updateTask={updateTask}
            timeError={timeError}
            setTimeError={setTimeError}
          />
          <GenerateTableTasks
            tasks={tasks}
            handleEditClick={handleEditClick}
            setTaskIdToDelete={setTaskIdToDelete}
            setShowModelDeleteTaskOn={setShowModelDeleteTaskOn}
            totalPages={totalPages}
            setPage={setPage}
          />
        </Box>
      )}
      <Dialog open={showDeleteTaskOn}>
        <DialogTitle>Deletar tarefa?</DialogTitle>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setTaskIdToDelete(null);
              setShowModelDeleteTaskOn(false);
            }}
          >
            Não
          </Button>
          <Button onClick={deleteTask}>Sim</Button>
        </DialogActions>
      </Dialog>
    </ContainerPage>
  );
}
