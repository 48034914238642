import { KJUR } from 'jsrsasign';

const { REACT_APP_SECRET } = process.env;

const USER_DATA = 'userData';

export function insertToken(token) {
  window.localStorage.setItem(USER_DATA, token);
}

export function clearToken() {
  if (window.localStorage.getItem(USER_DATA) === null) return;
  window.localStorage.removeItem(USER_DATA);
}

export function fetchLocalStorageToken() {
  const data = window.localStorage.getItem(USER_DATA);
  return data;
}

function validateJWT(token) {
  return KJUR.jws.JWS.verify(token, REACT_APP_SECRET, ['HS256']);
}

function decodeJWT() {
  if (window.location.pathname.startsWith('/public')) return '';

  const token = fetchLocalStorageToken();
  if (!token || !validateJWT(token)) {
    clearToken();
    return '';
  }
  const { payloadObj } = KJUR.jws.JWS.parse(token);
  return payloadObj;
}

export function getTokenInfo(name) {
  const info = decodeJWT();
  return info[name];
}
