import Resizer from 'react-image-file-resizer';

export default async (file, { width = 500, height = 500, quality = 60 } = {}) => {
  if (!file) return null;

  if (!file.type.includes('image')) {
    throw new Error('Arquivo inválido');
  }

  const treatedImage = await new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'WEBP',
      quality,
      0,
      (uri) => resolve(uri.replace(/data:image\/(webp|png);base64,/g, '')),
    );
  }).catch(() => {
    throw new Error('Não foi possível tratar a imagem');
  });

  return treatedImage;
};
