import React, { useState } from 'react';
import {
  Box, Button, CircularProgress, Divider, Drawer, IconButton, Skeleton, Typography,
} from '@mui/material';
import moment from 'moment';
import { useAlert } from '../../../../context/alertContext';
import { formatCurrencyBR } from '../../../../utils/currencyFormat';
import routes from '../../../../services/routes';
import { ReactComponent as PlusSquare } from '../../../../assets/icons/plus-square-button-icon.svg';
import { ReactComponent as MinusSquare } from '../../../../assets/icons/minus-square-button-icon.svg';
import { ReactComponent as TrashSquare } from '../../../../assets/icons/trash-square-button-icon.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close-icon.svg';
import OrderConfirmation from './OrderConfirmation';
import { isSuperAdmin } from '../../../../utils/checkUser';
import { getTokenInfo } from '../../../../utils/configAuth';
import { BRLMask } from '../../../../utils/inputMasks';

const { marketplace, superadmin } = routes;

export default function GenerateQuotation({
  marketPlaceData,
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [orderConfirmation, setOrderConfirmation] = useState({ isOpen: false, items: [] });
  const [frubana, setFrubana] = useState([]);
  const [others, setOthers] = useState([]);
  const [quotationData, setQuotationData] = useState([]);
  const [drawerIsLoading, setDrawerIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const ilpiId = getTokenInfo('ilpiId');

  const alert = useAlert();

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setQuotationData([]);
    setDrawerIsLoading(true);
  };

  const generateQuotation = async () => {
    setLoading(true);
    const thisRoute = isSuperAdmin() ? superadmin : marketplace;

    try {
      const { message } = await thisRoute.generateQuotation({ ilpiId }, {
        itemList: marketPlaceData.itemList,
      });
      setQuotationData(message);
      setFrubana(message.quoted?.filter((seller) => seller.sellerName.includes('Frubana')));
      setOthers(message.quoted?.filter((seller) => !seller.sellerName.includes('Frubana')));
    } catch (error) {
      alert.error('Erro ao gerar cotação, tente novamente mais tarde.');
    } finally {
      setLoading(false);
      setDrawerIsLoading(false);
    }
  };

  const incrementItemQuantity = async (product) => {
    marketPlaceData.itemList.filter((item) => item.id === product.itemId)[0].quantity += 1;
    await generateQuotation();
  };

  const decrementItemQuantity = async (product, item) => {
    const { minimumOrder } = item;
    const { total } = item;
    if (total > minimumOrder) {
      marketPlaceData.itemList.filter((thisItem) => thisItem.id === product.itemId)[0].quantity -= 1;
      await generateQuotation();
      return;
    }
    alert.error(`Pedido mínimo desse fornecedor é de R$${minimumOrder}`);
  };

  async function removeItemFromQuotation(product) {
    const index = marketPlaceData.itemList.findIndex((item) => item.id === product.itemId);
    marketPlaceData.itemList.splice(index, 1);

    await generateQuotation();
  }

  async function setCheckout(items) {
    setOthers(items.filter((seller) => !seller.sellerName.includes('Frubana')));
    setFrubana(items.filter((seller) => seller.sellerName.includes('Frubana')));
    setOrderConfirmation({ isOpen: true, items });
  }

  return (
    <Box>
      {orderConfirmation.isOpen && (
        <OrderConfirmation
          others={others}
          frubana={frubana}
          isOpen={orderConfirmation.isOpen}
          onClose={() => setOrderConfirmation({ isOpen: false, items: [] })}
          onSuccess={handleCloseDrawer}
        />
      )}

      {openDrawer && (
        <IconButton
          onClick={handleCloseDrawer}
          sx={{
            position: 'fixed',
            top: '40px',
            right: '600px',
            backgroundColor: '#4A397F',
            zIndex: '1300',
            ':hover': {
              backgroundColor: '#FE663F',
            },
          }}
        >
          <Close color="white" />
        </IconButton>
      )}
      <Button
        data-testid="generate-quotation-btn"
        variant="contained"
        onClick={() => {
          generateQuotation();
          handleOpenDrawer();
        }}
        sx={{
          backgroundColor: '#4A397F',
          color: '#FFFFFF',
          textTransform: 'none',
          fontSize: '14px',
          borderRadius: '80px',
          boxShadow: 'none',
          padding: '6px 40px',

          '&:hover': {
            backgroundColor: '#4A397F',
          },
        }}
        disabled={!marketPlaceData?.itemList?.length}
      >
        Fazer Cotação
      </Button>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '& .MuiDrawer-paper': {
            width: '100%',
            maxWidth: '582px',

            '&::before': {
              content: '""',
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              height: '9px',
              background:
                'linear-gradient(90deg, rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%)',
            },
          },
        }}
      >
        {drawerIsLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress
              sx={{
                color: '#4A397F',
              }}
            />
          </Box>
        ) : (
          <>
            <Typography
              variant="subtitle1"
              color="#474750"
              sx={{
                fontWeight: '700',
                fontSize: '18px',
                paddingLeft: '40px',
                paddingTop: '40px',
              }}
            >
              Cotação de menor preço
            </Typography>
            <Divider
              sx={{
                mt: '25px',
                opacity: '1.25',
              }}
            />
            <Box
              sx={{
                mt: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 215px)',
                padding: '0 40px',

                '&::-webkit-scrollbar': {
                  width: '4px',
                  height: '4px',
                  backgroundColor: '#F5F5F7',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#4A397F',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#F5F5F7',
                },
              }}
            >
              {quotationData?.quoted?.map((item) => (
                <Box
                  data-test-id="generate-quotation-drawer"
                  key={item.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    gap: '12px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Typography variant="subtitle1" color="#474750" sx={{ fontSize: '14px' }}>
                      {item.sellerName}
                    </Typography>
                    <Typography
                      data-testid="delivery-date"
                      variant="subtitle1"
                      color="#F5F5F7"
                      sx={{
                        fontSize: '10px',
                        backgroundColor: '#96969E',
                        padding: '7.5px 13px',
                        borderRadius: '8px',
                      }}
                    >
                      {`DATA DE ENTREGA ${moment(item.estimatedDelivery).format('DD/MM/YYYY')}`}
                    </Typography>
                  </Box>
                  <Box
                    data-testid="quoted-itens-card"
                    sx={{
                      borderRadius: '8px',
                      overflow: 'hidden',
                      maxHeight: '220px',
                      overflowY: 'auto',
                      width: '100%',

                      '&::-webkit-scrollbar': {
                        width: '4px',
                        height: '4px',
                        backgroundColor: '#F5F5F7',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#4A397F',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#F5F5F7',
                      },
                    }}
                  >
                    {item.products?.map((product) => (
                      <Box
                        data-testid="item-card"
                        key={product.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          backgroundColor: '#FEFEFE',
                          padding: '12px',
                          '&:nth-of-type(odd)': {
                            backgroundColor: '#F5F5F7',
                          },
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="#474750"
                          sx={{
                            fontSize: '12px',
                            lineHeight: '170%',
                            maxWidth: '238px',
                            flex: '1',
                          }}
                        >
                          {product.name}
                        </Typography>
                        {loading ? (
                          <Skeleton variant="rounded" width={132} height={36} />
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <IconButton
                              data-testid="remove-item-from-list"
                              onClick={() => {
                                removeItemFromQuotation(product);
                              }}
                            >
                              <TrashSquare />
                            </IconButton>
                            <IconButton
                              data-testid="decrement-quantity-icon"
                              onClick={() => {
                                decrementItemQuantity(product, item);
                              }}
                            >
                              <MinusSquare />
                            </IconButton>

                            <Typography
                              variant="subtitle1"
                              color="#96969E"
                              sx={{
                                width: '24px',
                                fontSize: '12px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {marketPlaceData.itemList?.find((thisItem) => thisItem.id === product.itemId)?.quantity
                                || 0}
                            </Typography>

                            <IconButton
                              data-testid="increment-quantity-icon"
                              onClick={() => {
                                incrementItemQuantity(product);
                              }}
                            >
                              <PlusSquare />
                            </IconButton>
                          </Box>
                        )}
                        <Divider orientation="vertical" flexItem />
                        <Typography
                          data-testid="quotationTotaltemValue"
                          variant="subtitle1"
                          color="#474750"
                          sx={{
                            fontSize: '10px',
                          }}
                        >
                          {BRLMask(product.price)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  {loading ? (
                    <Skeleton variant="rounded" width={193.11} height={42} />
                  ) : (
                    <Typography variant="subtitle1" color="#474750" sx={{ fontSize: '14px' }}>
                      {'Valor Total '}
                      <Typography
                        data-testid="quotationSupplierValue"
                        variant="subtitle1"
                        component="span"
                        color="#474750"
                        sx={{ fontSize: '24px', fontWeight: '700' }}
                      >
                        {`R$ ${formatCurrencyBR(item.total)}`}
                      </Typography>
                    </Typography>
                  )}
                  <Button
                    data-testid="orderSuplierBtn"
                    variant="contained"
                    sx={{
                      backgroundColor: '#F5F5F7',
                      color: '#4A397F',
                      textTransform: 'none',
                      fontSize: '14px',
                      borderRadius: '80px',
                      boxShadow: 'none',
                      padding: '6px 17px',
                      '&:hover': {
                        backgroundColor: '#F5F5F7',
                      },
                    }}
                    onClick={() => setCheckout([item])}
                  >
                    Pedir este Fornecedor
                  </Button>

                  <Divider
                    sx={{
                      mt: '25px',
                      opacity: '1.25',
                      width: '100%',
                    }}
                  />
                </Box>
              ))}

              {quotationData.notQuoted?.length > 0 && (
              <Box
                data-testid="notquoted-itens-card"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  gap: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography variant="subtitle1" color="#474750" sx={{ fontSize: '14px' }}>
                    Itens não cotados
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRadius: '8px',
                    overflow: 'hidden',
                    maxHeight: '220px',
                    overflowY: 'auto',
                    width: '100%',

                    '&::-webkit-scrollbar': {
                      width: '4px',
                      height: '4px',
                      backgroundColor: '#F5F5F7',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#4A397F',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#F5F5F7',
                    },
                  }}
                >
                  {quotationData.notQuoted?.map((product) => (
                    <Box
                      data-testid="item-card"
                      key={product.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#FEFEFE',
                        padding: '12px',
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#F5F5F7',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="#474750"
                          sx={{
                            fontSize: '12px',
                            lineHeight: '170%',
                            maxWidth: '300px',
                          }}
                        >
                          {product.name}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>

                <Divider
                  sx={{
                    mt: '25px',
                    opacity: '1.25',
                    width: '100%',
                  }}
                />
              </Box>
              )}
            </Box>
            <Box
              sx={{
                width: '582px',
                height: '91px',
                boxShadow: '0px -5px 4px rgba(0, 0, 0, 0.05)',
                position: 'fixed',
                bottom: '0',
                right: '0',
                backgroundColor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                gap: '20px',
              }}
            >
              {loading ? (
                <Skeleton variant="rounded" width={205.7} height={42} />
              ) : (
                <Typography variant="subtitle1" color="#474750">
                  {'Valor Total '}
                  <Typography
                    variant="subtitle1"
                    component="span"
                    color="#474750"
                    sx={{ fontSize: '24px', fontWeight: '700' }}
                  >
                    {quotationData?.quoted?.length > 0 ? `R$ ${formatCurrencyBR(quotationData.total)}` : 'R$ 0,00'}
                  </Typography>
                </Typography>
              )}
              <Button
                data-testid="make-order-btn"
                variant="contained"
                sx={{
                  backgroundColor: '#4A397F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                  fontSize: '14px',
                  borderRadius: '80px',
                  boxShadow: 'none',
                  padding: '6px 30px',

                  '&:hover': {
                    backgroundColor: '#4A397F',
                  },
                }}
                onClick={() => setCheckout(quotationData.quoted)}
                disabled={quotationData?.quoted?.length === 0}
              >
                Fazer Pedido
              </Button>
            </Box>
          </>
        )}
      </Drawer>
    </Box>
  );
}
