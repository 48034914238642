/* eslint-disable max-len */
import React from 'react';

export default function RegisterIconActive({ width = 24, height = 25, color = '#4A397F' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="message-add">
        <g id="vuesax/bold/message-add">
          <g id="message-add_2">
            <path id="Vector" d="M8 2.5H16C17.9229 2.5 19.2733 2.98039 20.1464 3.85355C21.0196 4.72671 21.5 6.07707 21.5 8V16C21.5 17.9229 21.0196 19.2733 20.1464 20.1464C19.2733 21.0196 17.9229 21.5 16 21.5H3C2.72614 21.5 2.5 21.2739 2.5 21V8C2.5 6.07707 2.98039 4.72671 3.85355 3.85355C4.72671 2.98039 6.07707 2.5 8 2.5ZM13.25 15.5V13.25H15.5C16.1861 13.25 16.75 12.6861 16.75 12C16.75 11.3139 16.1861 10.75 15.5 10.75H13.25V8.5C13.25 7.81386 12.6861 7.25 12 7.25C11.3139 7.25 10.75 7.81386 10.75 8.5V10.75H8.5C7.81386 10.75 7.25 11.3139 7.25 12C7.25 12.6861 7.81386 13.25 8.5 13.25H10.75V15.5C10.75 16.1861 11.3139 16.75 12 16.75C12.6861 16.75 13.25 16.1861 13.25 15.5Z" fill={color} stroke={color} />
          </g>
        </g>
      </g>
    </svg>
  );
}
