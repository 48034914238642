import React from 'react';
import Loading from '../../../../../../components/Loading/Loading';

function CardVisualizedREcipe({ isLoading, medicineList }) {
  return isLoading ? (<Loading />) : (
    <div
      height={300}
      data={medicineList}
    >
      <div>
        <div>Medicamento</div>
        <div />
      </div>

      <div>
        <div>Dosagem</div>
        <div />
      </div>

      <div>
        <div>QTD</div>
        <div />
      </div>

      <div>
        <div>UN</div>
        <div />
      </div>

      <div>
        <div>vzs ao dia</div>
        <div />
      </div>
    </div>
  );
}

export default CardVisualizedREcipe;
