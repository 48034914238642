import {
  Box, DialogActions, DialogContent,
} from '@mui/material';
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import {
  ContentContainer, Description, StyledDialog, Title,
} from './styles';
import BenvoSecondaryButton from '../BenvoSecondaryButton';
import BenvoPrimaryButton from '../BenvoPrimaryButton';

const ModalContext = createContext(undefined);

export function ModalOfferProvider({ children }) {
  const [modalOpen, setModalOpen] = useState(false);

  const showModalOffer = () => {
    setModalOpen(true);
  };

  const hideModalOffer = () => {
    setModalOpen(false);
  };

  const value = useMemo(() => ({ showModalOffer, hideModalOffer }), [showModalOffer, hideModalOffer]);

  return (
    <ModalContext.Provider value={value}>
      {children}

      {modalOpen && (
        <BenvoModuleOfferModal hideModalOffer={hideModalOffer} modalOpen={modalOpen} />
      )}
    </ModalContext.Provider>
  );
}

export function useModalOffer() {
  return useContext(ModalContext);
}

export function BenvoModuleOfferModal({ hideModalOffer, modalOpen }) {
  return (
    <Box>
      <StyledDialog open={modalOpen} onClose={hideModalOffer}>
        <DialogContent
          sx={{
            padding: '0px',
          }}
        >
          <ContentContainer>
            <img src="/images/delete-dialog.png" alt="teste" />
            <Box>
              <Title>Você não possui acesso</Title>
              <Description>Esse módulo está disponível para adicionar a sua assinatura</Description>
            </Box>
          </ContentContainer>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '12px',
          }}
        >
          <BenvoSecondaryButton
            sx={{
              px: '26px',
            }}
            onClick={hideModalOffer}
          >
            Cancelar
          </BenvoSecondaryButton>
          <a href="https://benvo.com.br/" target="_blank" rel="noreferrer">
            <BenvoPrimaryButton
              data-testid="know-more-button"
              sx={{
                px: '32px',
              }}
              onClick={hideModalOffer}
            >
              Saiba mais
            </BenvoPrimaryButton>
          </a>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
}
