import {
  Box, Typography, styled,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useModalOffer } from '../../BenvoModuleOfferModal';
import BenvoTooltip from '../../BenvoTooltip';

const StyledTypography = styled(Typography)`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 19.6px;
  color: #474750;
  transition: 'display 0.1s ease-in-out';
`;

export default function MenuItem({
  item, index, isOpen, setIsOpen, setActiveMenu,
}) {
  const path = useLocation();
  const { showModalOffer } = useModalOffer();
  const handleNavigate = () => {
    setIsOpen(false);
    if (!item.access) {
      showModalOffer();
    }
  };

  const currentPath = path.pathname.startsWith(item.link);
  return (
    <BenvoTooltip
      disableHoverListener={isOpen}
      title={item.title}
      placement="right"
      arrow
      style={{
        width: 'fit-content',
      }}
    >
      <Link
        onMouseEnter={() => setActiveMenu(null)}
        key={item.title}
        onClick={handleNavigate}
        to={item.access ? item.link : '#'}
        style={{
          marginTop: index === 0 ? '0px' : '22px',
          width: isOpen ? '204px' : '40px',
          overflow: 'hidden',
          transition: 'all 0.3s ease-in-out',
          backgroundColor: currentPath ? '#f5f5f7' : '#fff0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '13px',
            backgroundColor: '#fff0 !important',
          }}
        >
          {item.icon}
          <StyledTypography
            sx={{
              width: '148px',
            }}
          >
            {item.title}
          </StyledTypography>
        </Box>
      </Link>
    </BenvoTooltip>
  );
}
