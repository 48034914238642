import {
  Accordion, Box, Typography, styled,
} from '@mui/material';

export const StyledTypography = styled(Typography)`
  color: #474750;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.014px;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const ActionsContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding-right: 8px;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  &:before {
    background-color: #f5f6f8;
  }

  &.Mui-expanded {
    margin: 0;
    min-height: none;
  }

  &.Mui-expanded:before {
    opacity: 1;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    border: none;
    min-height: auto;
    height: 64px;
  }

  .MuiAccordionDetails-root{
    padding: 0 0 16px 0;
  }
`;
