/* eslint-disable max-len */
import React from 'react';

export default function ShopIconActive({ width = 24, height = 25, color = '#4A397F' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="shop">
        <g id="vuesax/bold/shop">
          <g id="shop_2">
            <path id="Vector" d="M2.8065 11.3021L2.80651 11.3021L2.80457 11.2996C2.25751 10.5962 1.99905 9.68483 2.08762 8.73421C2.08765 8.73383 2.08769 8.73346 2.08772 8.73308L2.35646 5.99602C2.56462 4.52907 2.98425 3.59796 3.6191 3.02357C4.25112 2.45175 5.18214 2.15656 6.58989 2.15656H8.98989H10.4699H13.5099H14.9899H17.3499C18.7523 2.15656 19.6842 2.45163 20.3167 3.02073C20.9515 3.59191 21.3706 4.51601 21.5735 5.96713L21.8622 8.72441C21.8623 8.72512 21.8623 8.72583 21.8624 8.72654C21.9506 9.64937 21.7102 10.5328 21.1899 11.2266L21.1898 11.2265L21.1843 11.2341C20.5339 12.136 19.489 12.6566 18.3099 12.6566C17.2326 12.6566 16.1851 12.1246 15.5046 11.2496L15.0858 10.7111L14.6984 11.2726C14.1037 12.1344 13.1292 12.6566 11.9999 12.6566C10.8744 12.6566 9.87071 12.1078 9.28465 11.2373L8.89253 10.6549L8.47037 11.2159C7.80041 12.1063 6.73559 12.6566 5.63989 12.6566C4.48712 12.6566 3.467 12.1625 2.8065 11.3021Z" fill={color} stroke={color} />
            <path id="Vector_2" d="M11.401 17.564L11.402 17.5639C12.4845 17.4509 13.2127 17.6393 13.6625 18.002C14.1002 18.3549 14.3747 18.9551 14.3701 19.9042H14.3701V19.9066V22.2866H9.62012V19.5566C9.62012 18.5283 10.3907 17.6674 11.401 17.564Z" fill={color} stroke={color} />
            <path id="Vector_3" d="M3.80309 14.3579L3.80307 14.358L3.81177 14.3609C4.10101 14.4573 4.39891 14.5354 4.71327 14.5807C4.82253 14.5989 4.97375 14.6231 5.12673 14.6262C5.29755 14.6464 5.46864 14.6566 5.63988 14.6566C6.7854 14.6566 7.90594 14.278 8.83378 13.6201C9.73295 14.2801 10.8402 14.6566 11.9999 14.6566C13.1656 14.6566 14.2591 14.2976 15.1578 13.6386C16.0807 14.286 17.1823 14.6566 18.3099 14.6566C18.5049 14.6566 18.7014 14.6459 18.8896 14.6242C19.0029 14.6147 19.1275 14.6035 19.2564 14.5808C19.607 14.5342 19.9198 14.4336 20.1878 14.3474L20.2029 14.3426L20.2029 14.3426L20.2102 14.3402C20.5386 14.229 20.8699 14.4716 20.8699 14.8066V17.7866C20.8699 20.2671 18.8591 22.2812 16.3799 22.2866V19.9066C16.3799 18.5233 15.9553 17.373 15.0649 16.5754C14.2798 15.8615 13.2382 15.5366 12.0199 15.5366C11.7566 15.5366 11.4885 15.5471 11.1971 15.5794C9.16081 15.7865 7.61988 17.5029 7.61988 19.5566V22.2866C5.14062 22.2812 3.12988 20.2671 3.12988 17.7866V14.8266C3.12988 14.4812 3.46944 14.2399 3.80309 14.3579Z" fill={color} stroke={color} />
          </g>
        </g>
      </g>
    </svg>

  );
}
