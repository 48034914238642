import { Delete, DriveFileRenameOutline } from '@mui/icons-material';
import {
  Box, IconButton, Pagination, Tooltip,
} from '@mui/material';
import React from 'react';
import { getJustDayFromMoment } from '../../../../utils/getHourFromMoment';
import MTable from '../../../../components/MuiTable';
import { mapRecurrence } from '../../utils/utils';
import { mapTaskType } from '../../../../utils/mapTaskType';

const tableCells = [
  'Tarefa',
  'Tipo',
  'Observação',
  'Cuidador',
  'Recorrência',
  'Data',
  'Horário',
  'Ações',
];

export default function GenerateTableTasks({
  tasks,
  handleEditClick,
  setTaskIdToDelete,
  setShowModelDeleteTaskOn,
  totalPages,
  setPage,
}) {
  return (
    <Box>
      <MTable.Container sx={{ mb: 4, mt: 4 }}>
        <MTable.Root>
          <MTable.Head>
            <MTable.Row>
              {tableCells.map((cell) => (
                <MTable.Cell key={cell}>{cell}</MTable.Cell>
              ))}
            </MTable.Row>
          </MTable.Head>
          <MTable.Body>
            {tasks.map((task) => (
              <MTable.Row key={task.id}>
                <MTable.Cell>{task.title || task.residentName}</MTable.Cell>
                <MTable.Cell>{mapTaskType(task.type).name}</MTable.Cell>
                <MTable.Cell>{task.observation || 'Sem observação'}</MTable.Cell>
                <MTable.Cell>{task.caregiverName}</MTable.Cell>
                <MTable.Cell>{mapRecurrence(task.recurrence)}</MTable.Cell>

                <MTable.Cell>{getJustDayFromMoment(task.untilDate)}</MTable.Cell>
                <MTable.Cell>{task.startTime}</MTable.Cell>
                <MTable.Cell>
                  <Tooltip title="editar">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => handleEditClick(task)}
                    >
                      <DriveFileRenameOutline />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="excluir">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        setTaskIdToDelete(task.id);
                        setShowModelDeleteTaskOn(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </MTable.Cell>
              </MTable.Row>
            ))}
          </MTable.Body>
        </MTable.Root>
      </MTable.Container>
      <Pagination
        color="primary"
        sx={{
          '.MuiPagination-ul': {
            justifyContent: 'flex-end',
          },
        }}
        count={totalPages}
        onChange={(_, page) => {
          setPage(page - 1);
        }}
      />
    </Box>
  );
}
