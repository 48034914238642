/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { ReactComponent as GaleryAdd } from '../../../../assets/icons/gallery-add.svg';
import ImagePicker from '../../../../components/ImagePicker';
import { StyledResidentPlanCard, StyledImageBox } from './style';
import BenvoDeleteModal from '../../../../components/BenvoDeleteModal';

export default function AddResidentPlanCard({
  onChange, currentImage, sx, planCard, isEdit,
}) {
  const [imagePreview, setImagePreview] = useState(currentImage);

  const handleImage = ({ data, preview }) => {
    onChange(data);
    setImagePreview(preview);
  };

  const handleDelete = () => {
    onChange(null);
    setImagePreview(null);
  };

  useEffect(() => {
    if (!planCard && !isEdit) {
      setImagePreview(null);
    }
  }, [planCard]);

  if (!imagePreview) {
    return (
      <ImagePicker onChange={handleImage} aspect>
        <StyledImageBox sx={sx}>
          <GaleryAdd style={{ color: '#96969E' }} />

          <Typography
            sx={{
              color: '#96969E',
              letterSpacing: '0.15px',
              fontWeight: 400,
              textTransform: 'uppercase',
              fontSize: '10px',
              mt: '4px',
            }}
          >
            Imagem da carteirinha
          </Typography>
        </StyledImageBox>
      </ImagePicker>
    );
  }

  return (
    <label htmlFor="delete-modal" style={{ cursor: 'pointer' }}>
      <StyledResidentPlanCard sx={{ ...sx }}>
        <Box style={{ position: 'relative', display: 'inline' }}>
          <img
            src={imagePreview}
            alt="Foto da carteirinha do residente"
            style={{
              maxWidth: '314px',
              maxHeight: '182px',
              objectFit: 'scale-down',
              borderRadius: '8px',
            }}
          />
          <BenvoDeleteModal
            id="delete-modal"
            title="Apagar imagem"
            description="Deseja apagar a imagem da carteirinha?"
            buttonText="Excluir imagem"
            handleDelete={handleDelete}
            imageUrl="/images/gallery-add2.svg"
            iconStyle={{
              position: 'absolute',
              inset: 0,
              margin: 'auto',
              cursor: 'pointer',
              background: 'none',
            }}
          />
        </Box>
      </StyledResidentPlanCard>
    </label>
  );
}

AddResidentPlanCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentImage: PropTypes.string,
  planCard: PropTypes.string,
  isEdit: PropTypes.bool,
};

AddResidentPlanCard.defaultProps = {
  currentImage: null,
  isEdit: false,
  planCard: null,
};
