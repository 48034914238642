import React from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useNavigate } from 'react-router';
import {
  Container, StyledButton, StyledForm, StyledTexField, ButtonsContainer,
} from './style';
import schemas from '../../schemas';
import routes from '../../services/routes';
import { useAlert } from '../../context/alertContext';
import PATH from '../../constants/applicationPaths';

export default function RecoverPassword() {
  const { recoverPassword: { recoverPassword } } = schemas;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: joiResolver(recoverPassword),
    defaultValues: {
      email: '',
    },
  });
  const { publicRoutes } = routes;
  const navigate = useNavigate();
  const alert = useAlert();

  const handleBack = () => {
    navigate(-1);
  };

  const handlePasswordRecovery = async (data) => {
    try {
      await publicRoutes.requestNewPassword({
        email: data.email,
      });
      alert.success('E-mail enviado com sucesso!');
      setTimeout(() => {
        navigate(`${PATH.auth.sendEmail}?email=${data.email}`);
      }, 1000);
    } catch (error) {
      alert.error('O endereço de e-mail inserido não é válido.');
    }
  };

  return (
    <Container>
      <h3>
        <span>Recuperar senha</span>
        <br />
        Digite seu e-mail para prosseguir.
      </h3>

      <StyledForm onSubmit={handleSubmit(handlePasswordRecovery)}>
        <StyledTexField
          name="email"
          type="email"
          placeholder="Email"
          size="small"
          fullWidth
          error={errors.email}
          helperText={errors.email?.message}
          {...register('email')}
        />

        <ButtonsContainer>
          <StyledButton
            type="button"
            variant="secondary"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            disabled={isSubmitting}
            type="submit"
          >
            Enviar
          </StyledButton>
        </ButtonsContainer>
      </StyledForm>
    </Container>
  );
}
