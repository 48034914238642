import {
  Box, IconButton, Typography, styled,
} from '@mui/material';

export const GeneralContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 10px;
  background: #f5f5f7;

  &:disabled {
    background: #f5f5f7;
    cursor: not-allowed;
    & svg {
      path {
        stroke: #d4d4da;
      }
    }
  }
`;

export const ContactContainer = styled(Box)`
  border-radius: 8px;
  background: #f5f5f7;
  padding: 4px 8px;
  flex: 1;
`;

export const ContactInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ContactName = styled(Typography)`
  color: #474750;

  font-size: 12px;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 0.12px;
`;

export const ContactEmailOrPhone = styled(Typography)`
  color: #96969e;

  font-size: 10px;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.04px;
  display: contents;
`;
