/* eslint-disable max-len */
import React from 'react';

export default function LogoutIcon({ width = 24, height = 25, color = '#474750' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="logout/Variant7">
        <g id="vuesax/linear/logout">
          <g id="logout">
            <path id="Vector" d="M8.8999 7.55999C9.2099 3.95999 11.0599 2.48999 15.1099 2.48999H15.2399C19.7099 2.48999 21.4999 4.27999 21.4999 8.74999V15.27C21.4999 19.74 19.7099 21.53 15.2399 21.53H15.1099C11.0899 21.53 9.2399 20.08 8.9099 16.54" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_2" d="M15.0001 12H3.62012" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_3" d="M5.85 8.6499L2.5 11.9999L5.85 15.3499" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
      </g>
    </svg>
  );
}
