const {
  Box, Button, styled, Typography, MenuItem, MenuList,
} = require('@mui/material');

export const StyledProfileButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-grow: 0;
  background-color: #F5F5F7;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 7px 20px;

  &:hover {
    border-color: #4A397F;
  },
`;

export const StyledProfileBox = styled(Box)`
  position: relative;
  min-width: 180px;
`;

export const StyledTypographyName = styled(Typography)`
  margin: 0px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2D2D33;
  letter: 0.15px;
  text-transform: none;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

export const StyledBoxArrow = styled(Box)`
  transition: 0.3s;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const StyledBoxMenuItem = styled(Box)`
  position: relative;
  height: 40px;
  letter-spacing: 0.4px;
  color: ${(props) => (props.active ? 'var(--paleta-senior-purple, #4A397F)' : '#435565')};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: ${(props) => (props.active ? 'var(--paleta-senior-purple, #4A397F)' : 'transparent')};
    border-radius: 0 4px 4px 0;
  }

  &:hover {
    transition: all 0.3s ease-in-out;
    background: var(--main-and-gray-very-light-gray, #F5F6F8);
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  height: 100%;
  width: 100%;
  padding: 10px 20px;

  &:hover {
    background: transparent;
  }
`;

export const StyledMenuList = styled(MenuList)`
  padding: 0;
  color: #435565;
`;

export const StyledTypographyMenu = styled(Typography)`
  font-size: 14px;
  margin-left: 8px;
`;

export const StyledBoxMenuWindow = styled(Box)`
  z-index: 100;
  position: absolute;
  min-width: 180px;
  right: 0;
  top: 85px;
`;

export const StyledBoxMenu = styled(Box)`
  width: inherit;
  background: white;
  border-radius: 8px;

  &:before {
    content: '';
    position: absolute;
    top: -21px;
    right: 18px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-bottom-color: #FFF;
  }
`;
