import React from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { StyledForm } from '../../style';
import routes from '../../../../../../services/routes';
import { status } from '../cardHelper';
import schemas from '../../../../../../schemas';
import BenvoButton from '../../../../../../components/BenvoButton/style';
import BenvoTextField from '../../../../../../components/BenvoField/style';
import { useAlert } from '../../../../../../context/alertContext';
import { AddCardButtonsBox, DarkTextTypography } from '../style';

function AddCard({
  setLoading, setStep, returnToHandler, refetch,
}) {
  const alert = useAlert();
  const {
    handleSubmit, register, watch, formState: { errors },
  } = useForm({
    resolver: joiResolver(schemas.payment.createCard),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const dateMask = (value) => {
    if (value) {
      return value
        .replace(/\D/g, '') // substitui qualquer caractere que não seja número por nada
        .replace(/(\d{2})(\d)/, '$1/$2'); // coloca um barra depois do segundo dígito
    }
    return '';
  };

  const creditCardNumberMask = (value) => {
    if (value) {
      return value
        .replace(/\D/g, '') // substitui qualquer caractere que não seja número por nada
        .replace(/(\d{4})(\d)/, '$1 $2') // coloca um espaço depois do quarto dígito
        .replace(/(\d{4})(\d)/, '$1 $2') // coloca um espaço depois do oitavo dígito
        .replace(/(\d{4})(\d)/, '$1 $2'); // coloca um espaço depois do décimo segundo dígito
    }
    return '';
  };

  async function registerNewCard({
    firstName, lastName, number, cvv, expirationDate, email,
  }) {
    await setLoading(true);

    const [month, year] = expirationDate.split('/');
    const creditCard = number.replace(' ', '');

    try {
      await routes.frubana.addCreditCard({
        firstName,
        lastName,
        number: creditCard,
        cvv,
        expirationMonth: month,
        expirationYear: year,
        email,
      });
      setStep(status.SELECTING);
      alert.success('Cartão adicionado com sucesso!');
    } catch (error) {
      alert.error('Erro ao registrar novo cartão, tente novamente mais tarde.');
    } finally {
      setLoading(false);
      refetch();
    }
  }

  return (
    <Box>
      <DarkTextTypography>
        Informe os dados do cartão
      </DarkTextTypography>
      <StyledForm onSubmit={handleSubmit(registerNewCard)} data-testid="register-credit-card-form">
        <Grid container spacing={2} sx={{ paddingY: 1 }}>
          <Grid item xs={12} sm={6}>
            <BenvoTextField
              placeholder="Nome"
              {...register('firstName')}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              value={watch('firstName')}
              inputProps={{ maxLength: 20 }}
              sx={{
                '& ::placeholder': {
                  color: 'black !important',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BenvoTextField
              placeholder="Sobrenome"
              {...register('lastName')}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              inputProps={{ maxLength: 20 }}
              sx={{
                '& ::placeholder': {
                  color: 'black !important',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingY: 1 }}>
          <Grid item xs={12} sm={12}>
            <BenvoTextField
              placeholder="Número do Cartão"
              fullWidth
              {...register('number')}
              error={!!errors.number}
              helperText={errors.number?.message}
              value={creditCardNumberMask(watch('number')) ?? ''}
              inputProps={{ maxLength: 19 }}
              sx={{
                '& ::placeholder': {
                  color: 'black !important',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingY: 1 }}>
          <Grid item xs={12} sm={4}>
            <BenvoTextField
              placeholder="CVV"
              {...register('cvv')}
              error={!!errors.cvv}
              helperText={errors.cvv?.message}
              inputProps={{ maxLength: 3 }}
              sx={{
                '& ::placeholder': {
                  color: 'black !important',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <BenvoTextField
              placeholder="Validade (ex: 05/2024)"
              {...register('expirationDate')}
              error={!!errors.expirationDate}
              helperText={errors.expirationDate?.message}
              value={dateMask(watch('expirationDate')) ?? ''}
              inputProps={{ maxLength: 7 }}
              sx={{
                '& ::placeholder': {
                  color: 'black !important',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingY: 1 }}>
          <Grid item xs={12} sm={12}>
            <BenvoTextField
              placeholder="E-mail"
              fullWidth
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email?.message}
              inputProps={{ maxLength: 50 }}
              sx={{
                '& ::placeholder': {
                  color: 'black !important',
                },
              }}
            />
          </Grid>
        </Grid>
        <AddCardButtonsBox>
          <BenvoButton
            variant="secondary"
            type="button"
            onClick={returnToHandler}
          >
            Voltar
          </BenvoButton>
          <BenvoButton
            variant="primary"
            type="submit"
          >
            Adicionar
          </BenvoButton>
        </AddCardButtonsBox>
      </StyledForm>
    </Box>
  );
}

export default AddCard;
