import React from 'react';
import { Container } from '@mui/system';
import { Outlet } from 'react-router';
import NavbarDash from '../Topbar';
import Sidebar from '../Sidebar';
import { PageContainer } from './style';

export default function PageLayout() {
  return (
    <Container maxWidth="100vw" disableGutters>
      <NavbarDash />
      <PageContainer>
        <Sidebar />
        <Outlet />
      </PageContainer>
    </Container>
  );
}
