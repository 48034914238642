/* eslint-disable import/prefer-default-export */
import { Box, styled } from '@mui/material';

export const PageContainer = styled(Box)`
  height: calc(100vh - 84px);
  background: #f5f5f7;
  padding: 42px 40px 44px 146px;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 24.6px 24px 24px;
    height: calc(100dvh - 111.39px);
  }
`;
