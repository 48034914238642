import React from 'react';

export default function MailBox() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="156"
      height="147"
      fill="none"
      viewBox="0 0 156 147"
    >
      <path
        fill="#48318D"
        d="M98.967 73.078L96.6 85.781l-11.27 60.385-20.3-3.79L78.046 72.62l.619-3.324 20.3 3.782z"
      />
      <path
        fill="#735FB2"
        d="M148.963 42.7l-7 37.528a.863.863 0 01-.356.547.846.846 0 01-.638.135L42.624 62.567 54.199.505l74.679 13.929c13.536 2.524 22.526 15.176 20.085 28.266z"
      />
      <path
        fill="#48318D"
        d="M75.205 30.106l-6.946 37.237-51.621-9.628 6.945-37.237C26.14 6.768 39.769-2.192 54.023.468c14.255 2.658 23.739 15.927 21.182 29.638z"
      />
      <path
        fill="#342465"
        d="M99.237 47.513c-3.804-.71-6.314-4.407-5.597-8.246.716-3.84 4.39-6.384 8.194-5.674a6.99 6.99 0 015.315 4.592l48.053 8.962-1.299 6.96-48.052-8.962a6.988 6.988 0 01-6.614 2.368zM73.492 30.79l-6.767 36.28L18.095 58l6.768-36.28C27.354 8.363 40.26-.436 53.689 2.068 67.117 4.573 75.983 17.433 73.492 30.79z"
      />
      <path
        fill="#fff"
        d="M100.6 43.997a3.54 3.54 0 100-7.08 3.54 3.54 0 000 7.08z"
      />
      <path
        fill="#48318D"
        d="M155.757 47.123l-4.245 22.758a.376.376 0 01-.438.3l-11.158-2.08a.073.073 0 01-.048-.032.078.078 0 01-.012-.055l4.3-23.055a.077.077 0 01.031-.048.076.076 0 01.056-.011l11.455 2.136a.074.074 0 01.047.031.073.073 0 01.012.056z"
      />
      <path
        fill="#fff"
        d="M34.189 44.982c.47 3.456 4.262 7.564 4.262 7.564-5.78.41-8.91-2.858-10.256-4.844a24.58 24.58 0 01-5.105.906c-11.866.84-22.08-6.906-22.817-17.311C-.465 20.892 8.555 11.782 20.42 10.94c11.865-.841 22.078 6.905 22.816 17.31.48 6.769-3.173 12.99-9.047 16.73z"
      />
      <path
        fill="#735FB2"
        d="M68.31 67.366c-.538 1.36-10.364 41.876-42.16 35.945-34.867-5.903-9.647-45.607-9.647-45.607l51.806 9.662z"
      />
      <path
        fill="#48318D"
        d="M21.754 33.343a2.95 2.95 0 100-5.9 2.95 2.95 0 000 5.9zM8.547 32.057a2.968 2.968 0 100-5.935 2.968 2.968 0 000 5.935zM34.834 34.6a2.968 2.968 0 100-5.935 2.968 2.968 0 000 5.936z"
      />
      <path
        fill="#342465"
        d="M98.967 73.078L96.6 85.781l-18.554-13.16.619-3.325 20.3 3.782z"
      />
    </svg>
  );
}
