import React, { Children, cloneElement } from 'react';
import { StyledDialog, StyledDialogActions, StyledDialogContent } from './style';

/**
 * Creates a dialog trigger component.
 *
 * @param {object} props - The properties for the dialog trigger.
 * @param {ReactNode} props.children - The child components of the dialog trigger.
 * @param {function} props.onClick - The click event handler for the dialog trigger.
 * @return {ReactNode} The dialog trigger component.
 */
function DialogTrigger({ children, onClick }) {
  const handleChildClick = (event) => {
    event.stopPropagation();
    onClick(event);
  };

  return Children.map(children, (child) => cloneElement(child, {
    onClick: handleChildClick,
  }));
}

/**
 * Renders the root component for the dialog.
 *
 * @param {Object} props - The props for the component.
 * @param {ReactNode} props.children - The content of the dialog.
 * @param {Function} props.onClose - The function to call when the dialog is closed.
 * @param {boolean} props.open - Whether the dialog is open.
 * @param {Object} props... - Additional props for the component.
 * @return {ReactNode} The rendered root component.
 */
function DialogRoot({
  children, onClose, open, ...props
}) {
  return (
    <StyledDialog open={open} onClose={onClose} {...props}>
      {children}
    </StyledDialog>
  );
}

/**
 * Renders a custom dialog content component.
 *
 * @param {Object} props - The props object containing the children and other props.
 * @param {React.ReactNode} props.children - The children of the component.
 * @returns {React.ReactNode} The rendered custom dialog content component.
 */
function CustomDialogContent({ children, ...props }) {
  return (
    <StyledDialogContent {...props}>
      {children}
    </StyledDialogContent>
  );
}

/**
 * Creates a custom dialog actions component.
 *
 * @param {Object} props - The props for the component.
 * @param {ReactNode} props.children - The children elements.
 * @return {ReactNode} The custom dialog actions component.
 */
function CustomDialogActions({ children, ...props }) {
  return (
    <StyledDialogActions {...props}>
      {children}
    </StyledDialogActions>
  );
}

const BenvoDialog = {
  Trigger: DialogTrigger,
  Root: DialogRoot,
  Content: CustomDialogContent,
  Actions: CustomDialogActions,
};

export default BenvoDialog;
