const {
  Typography, styled, Box,
} = require('@mui/material');

export const StyledListItem = styled(Box)`
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid var(--grey-variation-lightest-grey, #f5f5f7);
  background: var(--black-and-white-white, #fff);

  span {
    color: var(--grey-variation-dark-grey, #474750);
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.15px;
    text-transform: uppercase;
  }
`;

export const StyledResidentName = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.027px;
  height: 18px;
`;

export const Container = styled(Box)`
  display: flex;
  gap: 23px;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

export const CardsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
