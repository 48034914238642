import {
  Box, Button, Typography, styled,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { usePostHog } from 'posthog-js/react';
import LogoutIcon from '../../../assets/icons/LogoutIcon';
import { clearToken } from '../../../utils/configAuth';
import routes from '../../../services/routes';

const StyledTypography = styled(Typography)`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 19.6px;
  color: #474750;
  transition: "display 0.1s ease-in-out";
  `;

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 13px;
    padding: 8px;
    border-radius: 4px;
    width: 204px;
    text-transform: none;
    
    
    &:hover {
        background-color: #f5f5f5;
    }
    `;

export default function MenuLogout({ isOpen }) {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const handleLogout = () => {
    routes.auth.logout(); // Doesn't need to be async, let server handle the logout
    posthog?.reset(true);
    clearToken();
    navigate('/login');
  };

  return (
    <Box
      sx={{
        mt: 'auto',
        width: isOpen ? '204px' : '40px',
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <StyledButton
        onClick={handleLogout}
      >
        <LogoutIcon />
        <StyledTypography>
          Sair
        </StyledTypography>
      </StyledButton>
    </Box>
  );
}
