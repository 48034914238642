import axios from 'axios';

import API from '../constants/api';
import convertSearchParams from '../utils/convertSearchParams';
import { getTokenInfo } from '../utils/configAuth';

export const api = axios.create({
  baseURL: `${API.PROTOCOL}://${API.BASE_URL}:${API.PORT}`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: '',
  },
});

export const secondApi = axios.create({
  baseURL: 'https://hub2.bry.com.br',
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

export const post = async (path, payload, params = null) => {
  const token = getTokenInfo('token');
  const searchParams = convertSearchParams(params);
  const url = `${path}${searchParams}`;
  const { data } = await api.post(url, payload, { headers: { Authorization: token } });
  return data;
};

export const get = async (path, params = null) => {
  const token = getTokenInfo('token');
  const searchParams = convertSearchParams(params);
  const url = `${path}${searchParams}`;
  const { data } = await api.get(url, { headers: { Authorization: token } });
  return data;
};

export const put = async (path, payload) => {
  const token = getTokenInfo('token');
  const { data } = await api.put(path, payload, { headers: { Authorization: token } });
  return data;
};

export const patch = async (path, payload) => {
  const token = getTokenInfo('token');
  const { data } = await api.patch(path, payload, { headers: { Authorization: token } });
  return data;
};

export const deleteMethod = async (path, payload) => {
  const token = getTokenInfo('token');
  const { data } = await api.delete(path, { headers: { Authorization: token }, data: payload });
  return data;
};

export const upload = async (path, data) => {
  const token = getTokenInfo('token');

  const { data: response } = await api.post(path, data, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: token },
    data,
  });
  return response;
};

export const getWithoutToken = async (path) => {
  const { data } = await api.get(path);
  return data;
};

export const putWithoutToken = async (path, payload) => {
  const { data } = await api.put(path, payload);
  return data;
};

export const postWithoutToken = async (path, payload) => {
  const { data } = await api.post(path, payload);
  return data;
};

export const getWithHeaderParams = async (path, options) => api.get(path, options);

export const uploadDocument = (path, params) => upload(path, params);
