import { Box, TextField, styled } from '@mui/material';
import BenvoButton from '../../components/BenvoButton/style';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 28px;

  @media screen and (max-width: 770px) {
    width: 100%;
  }
`;

export const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: #474750;

  h4 {
    text-align: right;
    margin: 0;

    font-size: 24px;
    font-weight: 400;
    line-height: 110%; /* 26.4px */

    @media screen and (max-width: 770px) {
      font-size: 20px;
    }
  }

  p {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    line-height: 139.5%; /* 22.32px */
    letter-spacing: 0.024px;
    margin: 0;

    max-width: 265px;
  }
`;

export const StyledForm = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 27px;

  @media screen and (max-width: 770px) {
    width: 100%;
    max-width: 293px;
  }
`;

export const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  p {
    margin: 0;

    color: #2d2d33;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.03px;
  }
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 770px) {
    justify-content: space-between;
  }
`;

export const StyledButton = styled(BenvoButton)`
  padding: 16px 0;
  width: 153px;

  @media screen and (max-width: 770px) {
    width: 140px;
    &:first-of-type {
      background-color: #fff;
    }
  }
`;

export const StyledTexField = styled(TextField)`
  & .MuiOutlinedInput-root {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: #f5f5f7;

    @media screen and (max-width: 770px) {
      background-color: #fff;
    }

    & fieldset {
      border: none;
    }

    & input {
      color: #474750;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      -webkit-box-shadow: 0 0 0 1000px #f5f5f7 inset;

      text-align: left;

      @media screen and (max-width: 770px) {
        -webkit-box-shadow: none;
      }
    }
  }
`;
