import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

function Loading({ sx = {} }) {
  return (
    <Grid
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <CircularProgress sx={{ color: '#0A305A' }} />
    </Grid>
  );
}

export default Loading;
