import {
  Box, Divider, IconButton, Typography, styled,
} from '@mui/material';
import BenvoButton from '../../../../../../../../components/BenvoButton/style';

export const ListItemContainer = styled(Box)`
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px;
  border: 1px solid #f5f5f7;
  background: #fff;
  width: 100%;
`;

export const ListItemCodeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ListItemCodeTitle = styled(Typography)`
  color: #474750;

  font-size: 10px;
  font-weight: 400;
  line-height: 100%; /* 10px */
  letter-spacing: 0.15px;
`;

export const ListItemCodeNumber = styled(Typography)`
  color: #474750;

  font-size: 18px;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.027px;
`;

export const DateAndStatusContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  a {
    text-decoration: none;
  }
`;

export const StatusContainer = styled(Box)`
  display: flex;
  padding: 7px 0;
  width: 168px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ color }) => color || '#d4d4da'};

  color: ${({ color }) => color || '#474750'};

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 15.6px */
  letter-spacing: 0.12px;
`;

export const StyledDivider = styled(Divider)`
  background: #d4d4da;
`;

export const StyledIconButton = styled(IconButton)`
  background: #f5f5f7;
`;

export const StyledBenvoButton = styled(BenvoButton)`
  min-width: 148px;
  padding: 16px 0;
`;

export const EmptySendButtonContainer = styled(Box)`
  width: 36px;
  height: 37px;
`;
