import {
  Autocomplete, Button, Modal, Paper, TextField, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import MTable from '../../../../components/MuiTable';
import ContainerPage from '../../../../templates/containerPage/ContainerPage';
import { getTokenInfo } from '../../../../utils/configAuth';
import routes from '../../../../services/routes';

function CreatePrescription({
  prescriptionItems, resident, onClose, onSuccess, onFailure,
}) {
  const ilpiId = getTokenInfo('ilpiId');
  const [doctorSuggestions, setDoctorSuggestions] = useState([]);
  const [doctor, setDoctor] = useState({
    label: '',
    value: '',
  });

  const getDoctors = async () => {
    try {
      const { message } = await routes.doctor.searchDoctor({ ilpiId });

      setDoctorSuggestions(
        message.rows.map((thisDoctor) => ({
          label: thisDoctor.name,
          value: thisDoctor.id,
        })),
      );
    } catch (error) {
      console.log(error);
      onFailure('Não foi possível carregar médicos');
    }
  };

  const getPrescription = async (prescriptionId) => {
    try {
      const { message } = await routes.prescription.getPrescriptionById({ prescriptionId });

      return message;
    } catch (error) {
      onFailure('Não foi possível carregar a prescrição');

      return error;
    }
  };

  const createRecipe = async (prescription) => {
    const thisPrescriptionItems = prescription.prescriptionItems.map((item) => {
      const itemWithoutNullValues = {};

      Object.entries(item).forEach(([key, value]) => {
        if (value !== null) itemWithoutNullValues[key] = value;
      });

      return itemWithoutNullValues;
    });

    try {
      const params = {
        prescriptionId: prescription.id,
        doctorId: doctor.value,
        residentId: prescription.residentId,
        residentName: prescription.residentName,
        thisPrescriptionItems,
      };

      await routes.recipe.generateRecipeByIlpi({ ilpiId }, params);
      onSuccess();
    } catch {
      onFailure('Não foi possível gerar a receita');
    } finally {
      onClose();
    }
  };

  const createPrescription = async () => {
    try {
      const params = {
        residentId: resident.value,
        residentName: resident.label,
        prescriptionItems,
        ilpiId,
      };

      const { message: prescriptionId } = await routes.prescription.createPrescription(params);
      const prescription = await getPrescription(prescriptionId);

      createRecipe(prescription);
    } catch (error) {
      onFailure('Não foi possível gerar a prescrição');
      onClose();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    createPrescription();
  };

  useEffect(() => {
    getDoctors();
  }, []);

  return (
    <Modal open sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box component={Paper} padding={2} sx={{ width: '90%', height: '100%', overflowY: 'scroll' }}>
        <ContainerPage
          title="Criação de prescrição"
          description="Confirme os dados da nova prescrição"
        >
          <MTable.Container>
            <MTable.Root>
              <MTable.Head>
                <MTable.Cell>Substância</MTable.Cell>
                <MTable.Cell>Quantidade</MTable.Cell>
                <MTable.Cell>Controle</MTable.Cell>
                <MTable.Cell>Classe</MTable.Cell>
                <MTable.Cell>Dosagem</MTable.Cell>
                <MTable.Cell>Unidade</MTable.Cell>
                <MTable.Cell>Usos diários</MTable.Cell>
                <MTable.Cell>Observação</MTable.Cell>
                <MTable.Cell>Início do tratamento</MTable.Cell>
                <MTable.Cell>Fim do tratamento</MTable.Cell>
              </MTable.Head>

              <MTable.Body>
                {prescriptionItems.map((item) => (
                  <MTable.Row key={item.id}>
                    <MTable.Cell>{item.substance}</MTable.Cell>
                    <MTable.Cell>{item.quantity}</MTable.Cell>
                    <MTable.Cell>{item.controlType}</MTable.Cell>
                    <MTable.Cell>{item.class}</MTable.Cell>
                    <MTable.Cell>{item.dosage}</MTable.Cell>
                    <MTable.Cell>{item.unit}</MTable.Cell>
                    <MTable.Cell>{item.timesPerDay}</MTable.Cell>
                    <MTable.Cell>{item.observation || 'Nenhuma'}</MTable.Cell>
                    <MTable.Cell>{item.startDate}</MTable.Cell>
                    <MTable.Cell>{item.endDate || 'Uso contínuo'}</MTable.Cell>
                  </MTable.Row>
                ))}
              </MTable.Body>
            </MTable.Root>
          </MTable.Container>

          <Box marginY="15px">
            <Typography component="p" color="primary" fontWeight="700">
              Escolha o médico responsável pela Receita
            </Typography>

            <Autocomplete
              name="item"
              options={doctorSuggestions}
              onChange={(_e, item) => setDoctor(item || {})}
              value={doctor.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Médico responsável"
                  sx={{ width: '40%', marginY: '10px' }}
                />
              )}
            />
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Button onClick={onClose} variant="text" color="cancel">
              Cancelar
            </Button>

            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={!doctor.value}
            >
              Salvar
            </Button>
          </Box>
        </ContainerPage>
      </Box>
    </Modal>
  );
}

export default CreatePrescription;
