import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDoctorLP } from '../../../context/doctorLPContext';
import ConciergeCard from './ConciergeCard';
import routes from '../../../services/routes';
import { useAlert } from '../../../context/alertContext';
import alertMapper from '../../../utils/alertMapper';
import {
  StyledBox, StyledBoxCards, StyledContent,
} from '../styles';
import LoadingComponent from '../components/LoadingComponent';
import KeySelector from '../components/KeySelector/KeySelector';
import ContentHeader from '../components/ContentHeader/ContentHeader';
import EmptyResult from '../components/EmptyResult/EmptyResult';

function Concierge({ drawerHandler }) {
  const alert = useAlert();
  const { ilpiId, doctorId } = useParams();
  const doctorLP = useDoctorLP();

  /**
  * Fetch recipes from backend
  */
  const fetchExams = async () => {
    doctorLP.setIsLoading(true);
    try {
      const { message } = await routes.publicRoutes.getSentExams({ ilpiId, collaboratorId: doctorId });
      doctorLP.setExams(message);
    } catch (error) {
      doctorLP.setExams([]);
      if (error.response?.status === 404) alert.info('Não há exames para serem validados!');
      else alert[alertMapper(error.response?.status)]();
    } finally {
      doctorLP.setIsLoading(false);
    }
  };

  const rejectExam = async (id) => {
    doctorLP.setIsLoading(true);
    try {
      await routes.publicRoutes.rejectExam({ orderId: id }, { status: 'rejected' });
    } catch (error) {
      alert[alertMapper(error.response?.status)]();
    } finally {
      fetchExams();
    }
  };

  const mountCardForEachOrder = () => {
    const cards = doctorLP.exams.map((exam) => (
      <ConciergeCard
        key={exam.id}
        data={exam}
        rejectExam={rejectExam}
      />
    ));
    return cards;
  };

  const approveExams = async () => {
    doctorLP.setApproved(doctorLP.exams);
    drawerHandler.handle();
  };

  /**
  * Fetch exams on component mount
  */
  useEffect(() => {
    fetchExams();
  }, []);

  return (
    <StyledBox sx={{ overflow: 'auto', height: '100%' }}>
      {doctorLP.isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <ContentHeader
            title="Exames gerados"
            onClick={approveExams}
            isThereData={doctorLP.exams.length}
          />
          <StyledContent>
            {!doctorLP.exams.length ? (
              <EmptyResult type="Solicitações de exames" />
            ) : (
              <StyledBoxCards>
                {mountCardForEachOrder()}
              </StyledBoxCards>
            )}
          </StyledContent>
        </>
      )}
      <KeySelector drawerHandler={drawerHandler} />
    </StyledBox>
  );
}

export default Concierge;
