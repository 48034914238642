import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { getTokenInfo } from '../../../../utils/configAuth';
import ConfirmationInput from '../../../../components/ConfirmationInput';
import CreatePrescription from './CreatePrescription';
import ItemsContainer from '../ItemsContainer';
import routes from '../../../../services/routes';

function CreateList({
  open, onClose, onFailure, onSuccess,
}) {
  const { shoppingList, resident } = routes;
  const ilpiId = getTokenInfo('ilpiId');
  const [residentSuggestions, setResidentSuggestions] = useState([]);
  const [prescriptionItems, setPrescriptionItems] = useState([]);
  const [prescriptionModalIsOpen, setPrescriptionModalIsOpen] = useState(false);
  const [residentChangeConfirmation, setResidentChangeConfirmation] = useState({
    isOpen: false,
    resident: null,
  });

  const [formValues, setFormValues] = useState({
    date: moment(),
    observation: '',
    frequency: 'unique',
    recipient: 'ilpi',
    resident: {
      label: '',
      value: '',
    },
  });

  const [items, setItems] = useState({
    medication: [],
    product: [],
    market: [],
    flv: [],
  });

  const getResidents = async () => {
    try {
      const { message } = await resident.getAllResidentsByIlpiWithoutPagination(
        { ilpiId },
      );
      const suggestions = message.map((thisResident) => ({
        label: thisResident.name,
        value: thisResident.id,
      }));

      setResidentSuggestions(suggestions);
    } catch {
      onFailure('Não foi possível carregar moradores');
    }
  };

  const handleChange = ({ name, value }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleResidentChange = (thisResident) => {
    if (prescriptionItems.length) {
      setResidentChangeConfirmation({ isOpen: true, resident: thisResident });
    } else setFormValues((prev) => ({ ...prev, resident: thisResident }));
  };

  const clearItems = () => {
    setItems({ medication: [], product: [], flv: [] });
  };

  const implementOnResidentChangeRules = () => {
    setItems((prev) => ({ ...prev, medication: [] }));
    setPrescriptionItems([]);
  };

  const implementNonResidentRules = () => {
    setFormValues((prev) => ({ ...prev, resident: { label: '', value: '' } }));
    setPrescriptionItems([]);
  };

  const verifyFieldsValidity = () => {
    const RESIDENT_IS_NOT_SELECTED = formValues.recipient === 'resident' && !formValues.resident.value;
    const THERE_ARE_NO_ITEMS = Object.values(items).every(
      (item) => !item.length,
    );

    return !(RESIDENT_IS_NOT_SELECTED || THERE_ARE_NO_ITEMS);
  };

  const sendList = async () => {
    try {
      const params = {
        date: formValues.date.format('YYYY-MM-DD'),
        isRecurrent: formValues.frequency !== 'unique',
        list: Object.entries(items).reduce(
          (acc, [key, value]) => (value.length ? { ...acc, [key]: value } : acc),
          {},
        ),
        ilpiId,
      };

      if (formValues.observation) {
        params.observation = formValues.observation;
      }

      if (formValues.recipient === 'resident' && formValues.resident.value) {
        params.residentId = formValues.resident.value;
      }

      await shoppingList.createShoppingList(params);
      onSuccess('Lista de compras criada com sucesso!');
    } catch (error) {
      onFailure('Não foi possível criar lista de compras.');
    } finally {
      onClose();
    }
  };

  const handleSubmit = async () => {
    if (prescriptionItems.length) {
      setPrescriptionModalIsOpen(true);
    } else sendList();
  };

  useEffect(() => {
    if (formValues.recipient !== 'resident') {
      implementNonResidentRules();
    }
    clearItems();
  }, [formValues.recipient]);

  useEffect(() => {
    implementOnResidentChangeRules();
  }, [formValues.resident]);

  useEffect(() => {
    getResidents();
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      data-testid="create-list-modal"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: { xs: '90%', sm: '80%', md: '70%' },
          height: '90%',
          padding: 5,
          overflow: 'auto',
        }}
        component={Paper}
      >
        <div className="title-information">
          <p>Nova lista de compras</p>
        </div>

        {prescriptionModalIsOpen && (
          <CreatePrescription
            prescriptionItems={prescriptionItems}
            resident={formValues.resident}
            onClose={() => setPrescriptionModalIsOpen(false)}
            onSuccess={sendList}
            onFailure={onFailure}
          />
        )}

        {residentChangeConfirmation.isOpen && (
          <ConfirmationInput
            isOpen={residentChangeConfirmation.isOpen}
            message="A mudança de residente vai remover todos os medicamentos prescritos. Deseja continuar?"
            onAccept={() => setFormValues((prev) => ({
              ...prev,
              resident: residentChangeConfirmation.resident,
            }))}
            onClose={() => setResidentChangeConfirmation((prev) => ({
              ...prev,
              isOpen: false,
            }))}
          />
        )}

        <form>
          <Grid container spacing={{ xs: 1, sm: 1 }} rowSpacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <DesktopDatePicker
                label="Data da compra"
                inputFormat="DD/MM/YYYY"
                value={formValues.date}
                renderInput={(params) => (
                  <TextField size="small" {...params} fullWidth />
                )}
                onChange={(value) => handleChange({ name: 'date', value })}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="frequency">Frequência</InputLabel>
                <Select
                  fullWidth
                  labelId="frequency"
                  label="Frequência"
                  name="frequency"
                  value={formValues.frequency}
                  onChange={({ target }) => handleChange(target)}
                  size="small"
                >
                  <MenuItem value="unique">Uma vez</MenuItem>
                  <MenuItem value="monthly">Mensal</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="recipient">Destinatário</InputLabel>
                <Select
                  fullWidth
                  labelId="recipient"
                  label="Destinatário"
                  name="recipient"
                  value={formValues.recipient}
                  onChange={({ target }) => handleChange(target)}
                  size="small"
                >
                  <MenuItem value="ilpi">Ilpi</MenuItem>
                  <MenuItem value="resident">Residente</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete
                fullWidth
                name="item"
                disabled={formValues.recipient !== 'resident'}
                options={residentSuggestions}
                value={formValues.resident.label}
                onChange={(_e, item) => handleResidentChange(item || {})}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Residente" />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                type="text"
                name="observation"
                label="Observação"
                value={formValues.observation}
                onChange={({ target }) => handleChange(target)}
                size="small"
              />
            </Grid>
          </Grid>
        </form>

        <ItemsContainer
          setItems={setItems}
          items={items}
          setPrescriptionItems={setPrescriptionItems}
          values={formValues}
          onFailure={onFailure}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px 0',
          }}
        >
          <Button onClick={onClose} variant="text" color="cancel">
            Cancelar
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            data-testid="monthlyList-save-btn"
            disabled={!verifyFieldsValidity()}
          >
            {prescriptionItems.length ? 'Salvar e gerar receita' : 'Salvar'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default CreateList;
