import { Box, styled, Typography } from '@mui/material';

export const StyledBoxHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const StyledPageTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.027px;
`;
