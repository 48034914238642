import { Box, styled } from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';

export const Card = styled(Box)`
  padding: 22px;
  border-radius: 12px;
  background: #fff;

  @media (max-width: 768px) {
    display: none;
    width: 350px;
    height: 412.84769px;
  }

  h2 {
    color: #2d2d33;
    font-size: 24.761px;
    font-weight: 700;
    line-height: 130%; /* 32.189px */
    letter-spacing: 0.037px;
    margin-bottom: 9.9px;

    width: 100%;
    max-width: 360px;

    @media (max-width: 768px) {
      font-size: 20px;
      font-weight: 700;
      line-height: 130%; /* 26px */
      letter-spacing: 0.03px;

      max-width: 307px;
    }
  }

  p {
    color: #474750;
    font-size: 14.594px;
    font-weight: 400;
    line-height: 140%; /* 20.431px */
    letter-spacing: 0.036px;
    margin-bottom: 12.8px;

    width: 100%;
    max-width: 340px;

    @media (max-width: 768px) {
      font-size: 12px;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      letter-spacing: 0.03px;
      margin-bottom: 9.9px;

      max-width: 307px;
    }
  }
`;

export const DesktopImage = styled('img')`
  margin-bottom: 9.9px;
  width: 360.0498px;
  height: 436.39185px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Button = styled(BenvoButton)`
  width: 141px;
  padding: 16px 0px;
`;
