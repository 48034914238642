import Joi from 'joi';

const createUser = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'O nome é obrigatório',
  }),
  cpf: Joi.string().length(14).allow('').messages({
    'string.length': 'O CPF deve ter 11 caracteres',
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': 'O e-mail é obrigatório',
      'string.email': 'O e-mail deve ser válido',
    }),
  privilege: Joi.valid('admin', 'user').required().messages({
    'string.empty': 'O privilégio é obrigatório',
    'any.only': 'O privilégio deve ser "admin" ou "user"',
  }),
});

export default {
  createUser,
};
