import React, { useState } from 'react';
import {
  Box, Dialog, Typography,
} from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';
import { useAlert } from '../../../../context/alertContext';
import { getTokenInfo } from '../../../../utils/configAuth';
import orderImg from '../../../../assets/order.png';
import routes from '../../../../services/routes';
import Frubana from './Frubana/Frubana';

export default function OrderConfirmation({
  others,
  frubana,
  isOpen,
  onClose,
  onSuccess,
}) {
  const alert = useAlert();
  const ilpiId = getTokenInfo('ilpiId');
  const [frubanaOpen, setFrubanaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [frubanaTotal, setFrubanaTotal] = useState(0);

  function processOrder(test = false) {
    let allProducts = others; // TEMPORARY WORKAROUND WHILE FRUBANA ISN'T 100%

    if (!test) { // ALSO TEMPORARY WORKAROUND WHILE FRUBANA ISN'T 100%
      allProducts = [...others, ...frubana];
    }

    const order = {
      ordersItems: allProducts.map(({ products, total, ...seller }) => ({
        sellerId: seller.id,
        estimatedDelivery: seller.estimatedDelivery,
        totalPrice: total,
        products: products.map((product) => ({
          id: product.id,
          name: product.name,
          category: product.category,
          sku: product.sku,
          price: product.price,
          quantity: product.quantity,
        })),
      })),
    };

    return order;
  }

  async function handlePlaceAllOrders(test = false) {
    const order = processOrder(test);

    try {
      await routes.order.createOrder({ ilpiId }, order);
      alert.success('Pedido realizado com sucesso!', 'Em breve você receberá um email com o resumo do pedido.');
      onSuccess();
    } catch (error) {
      alert.error('Erro ao realizar pedido, tente novamente mais tarde.');
    }
  }

  async function handleCloseFrubana() {
    setFrubanaOpen(false);
    onClose();
  }

  function processFrubanaOrder() {
    const [order] = frubana.map(({ products }) => (products.map((product) => ({
      name: product.name,
      quantity: product.quantity,
    }))));

    return order;
  }

  async function createFrubanaCart() {
    try {
      const products = processFrubanaOrder();
      const { message: processedCart } = await routes.frubana.createCart({ products });
      setCart(processedCart);
      setFrubanaTotal(processedCart.cart.total.totalToPay);
    } catch (error) {
      alert.error('Erro ao criar carrinho na Frubana, tente novamente mais tarde ou entre em contato com a equipe Benvo.');
      handleCloseFrubana();
    }
  }

  async function handleOrderLogic() {
    setIsLoading(true);
    if (frubana.length) {
      await createFrubanaCart();
      setFrubanaOpen(true);
      setIsLoading(false);
    } else {
      await handlePlaceAllOrders();
      onClose();
      setIsLoading(false);
    }
  }

  async function handleOldLogic() {
    setIsLoading(true);
    await handlePlaceAllOrders();
    onClose();
    setIsLoading(false);
  }

  return (
    <Dialog
      data-testid="payment-confirmation-dialog"
      open={isOpen}
      onClose={onClose}
    >
      <Frubana
        isOpen={frubanaOpen}
        onClose={() => handleCloseFrubana()}
        handlePlaceAllOrders={() => handlePlaceAllOrders()}
        total={frubanaTotal}
        frubanaQuotationTotal={frubana.length ? frubana[0].total : 0}
        processedCart={cart}
      />
      <BenvoButton
        onClick={() => handleOrderLogic()}
        sx={{
          opacity: '0',
          cursor: 'text !important',
        }}
        data-testid="frubana-checkout-button"
      >
        {isLoading ? 'Carregando...' : 'BETA: Fazer pedido'}
      </BenvoButton>
      <Box
        sx={{
          bgcolor: 'white',
          width: '350px',
          padding: '35px',
          textAlign: 'center',
        }}
      >
        <img src={orderImg} alt="imagem do pedido" />
        <Box sx={{ margin: '20px 0' }}>
          <Box>
            <Typography
              variant="p"
              sx={{
                fontSize: '1rem',
                fontWeight: '500',
                color: '#435565',
              }}
            >
              Atenção!
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="p"
              sx={{
                fontSize: '1rem',
                color: '#848F99',
              }}
            >
              Deseja fazer o pedido dos itens selecionados?
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            mt: '12px',
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
          }}
        >
          <BenvoButton
            onClick={onClose}
            variant="secondary"
            disabled={!!isLoading}
          >
            Voltar
          </BenvoButton>

          <BenvoButton
            onClick={() => handleOldLogic()}
            disabled={!!isLoading}
            data-testid="order-checkout-button"
          >
            {isLoading ? 'Carregando...' : 'Fazer pedido'}
          </BenvoButton>
        </Box>
      </Box>
    </Dialog>
  );
}
