import React, { useState } from 'react';
import {
  CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import BenvoButton from '../../../../../../components/BenvoButton/style';

export default function ConfirmReturnModal({
  handler,
  state,
  functions,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleDialogClose = () => {
    navigate(-1);
    handler({ open: false, type: '' });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (state.type === 'create') {
      await functions.create();
    } else {
      await functions.update();
    }
    setIsLoading(false);
  };

  const contextText = state.type === 'create'
    ? 'Ao clicar em não, você perderá todas as informações inseridas até o momento.'
    : 'Ao clicar em não, você perderá todas as alterações feitas até o momento.';

  const titleText = state.type === 'create'
    ? 'Deseja realizar a criação da lista?'
    : 'Deseja salvar as alterações?';

  return (
    <Dialog open={state.open} onClose={handleDialogClose} sx={{ maxWidth: '425px', verticalAlign: 'center', margin: 'auto' }}>
      <DialogTitle sx={{ textAlign: 'justify' }}>
        <Typography variant="h6">
          {titleText}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'justify' }}>{contextText}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        paddingBottom: '1.5rem',
      }}
      >
        <BenvoButton variant="secondary" onClick={handleDialogClose} disabled={isLoading}>
          Não
        </BenvoButton>
        <BenvoButton onClick={handleSubmit} disabled={isLoading}>
          {isLoading && <CircularProgress size={20} color="inherit" />}
          Sim
        </BenvoButton>
      </DialogActions>
    </Dialog>
  );
}
