import {
  Box,
  Checkbox,
  Collapse,
  Paper,
  TextField,
  styled,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import BenvoButton from '../../../../../../components/BenvoButton/style';

export const TextAndActionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
`;

export const TextContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;

  h5 {
    color: #96969e;

    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.035px;
  }

  h6 {
    color: #435565;

    font-size: 16px;
    font-weight: 500;
    line-height: 115%; /* 18.4px */
    letter-spacing: 0.024px;
  }
`;

export const ActionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h6 {
    color: #d4d4da;

    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.04px;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  p {
    color: #96969e;
    font-size: 10px;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.025px;
  }
`;

export const StyledTextField = styled(TextField)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);

  & .MuiOutlinedInput-root {
    width: 100%;
    height: auto;
    padding: 10px 12px;
    font-size: 12px;
    max-width: 334px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    & .MuiChip-root {
      max-width: calc(39% - 6px);
    }

    & fieldset {
      border: none;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &:hover fieldset {
      border: none;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &.Mui-focused fieldset {
      border: none;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }

    &.MuiInputBase-root {
      input {
        padding: 0px !important;

        &::placeholder {
          color: var(--grey-variation-light-grey, #474750);
          opacity: 1;
        }
      }
    }
  }

  p {
    background: #fff !important;
    margin: 0px !important;
  }
`;

export const StyledBenvoButton = styled(BenvoButton)`
  padding: 16px 24px;
  gap: 8px;
  &:hover {
    transition: all 0.3s ease;
    svg {
      path {
        fill: #4a397f;
      }
    }
  }
`;

export const StyledDesktopDatePicker = styled(DesktopDatePicker)`
  width: 100%;
`;

export const StyledCollapse = styled(Collapse)`
  width: 100%;
`;

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const StyledOptions = styled('li')`
  font-size: 12px;
  font-weight: 400;
  margin: 0 12px;
  padding: 4px !important;
  border-radius: 4px;
  margin-bottom: 1px;
  &.Mui-focused {
    background: #96969e !important;
    color: #fff;
  }

  &[aria-selected="true"] {
    background: #4a397f !important;
    color: #fff;
  }
`;
export const StyledPaper = styled(Paper)`
  background: #f5f5f7;
  border-radius: 8px;
  box-shadow: none;
  margin-top: 4px;

  & .MuiAutocomplete-noOptions {
    font-size: 12px;
  }
`;

export const EmptyReferenceList = styled(Box)`
  width: fit-content;
  margin: 0 auto;

  p {
    max-width: 334px;
    color: #96969e;

    font-size: 10px;
    line-height: 140%;
    letter-spacing: 0.04px;
    text-align: center;
    margin-bottom: 0;
  }

  a {
    color: #6377e9;
    font-size: 10px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.04px;
    text-decoration-line: underline;
  }
`;

export const ResponsibleListContainer = styled(Box)`
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
  padding: 0;

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
    cursor: pointer;

    div {
      display: flex;
      flex-direction: column;

      p {
        color: #474750;

        font-size: 10px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.04px;
        margin-bottom: 0;
      }

      div {
        display: flex;
        flex-direction: row;

        p {
          color: #96969e;
          width: fit-content;
          font-size: 10px;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0.04px;
        }
      }
    }
  }

  label:nth-of-type(odd) {
    background: #f5f5f7;
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  padding: 0;
  width: 12px;
  height: 12px;
  svg {
    fill: ${({ checked }) => (checked ? '#4A397F' : '#d4d4da')};
    width: 16px;
    height: 16px;
  }
`;

export const EmptyCheckboxContainer = styled(Box)`
  width: 24px;
  height: 24px;
`;
