import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Container } from './style';
import ResidentShoppingListCard from './components/ResidentShoppingListCard';
import AddProductCard from './components/AddProductCard';
import routes from '../../services/routes';
import { useAlert } from '../../context/alertContext';
// import stateMachine from '../../utils/stateMachine';

const { resident, shoppingList } = routes;

export default function ResidentShoppingList() {
  const [selectedProductsList, setSelectedProductsList] = useState([]);
  const [productComparisonList, setProductComparisonList] = useState([]);
  const path = useLocation().pathname;
  const residentId = path.split('/')[2];
  const [searchParams] = useSearchParams();

  const isNewList = path.includes('nova-lista');
  const listId = path.split('/')[4];
  const navigate = useNavigate();
  const alert = useAlert();

  const getResidentInformations = async () => {
    try {
      const { message } = await resident.getResidentById({ residentId });
      return message;
    } catch (error) {
      return error;
    }
  };

  const { data: residentInformations, isLoading } = useQuery({
    queryKey: ['residentInformations', residentId],
    queryFn: getResidentInformations,
    refetchOnWindowFocus: false,
    retry: false,
  });

  // OBTER INFORMAÇÕES DA LISTA PARA EDITAR
  const getListProducts = async () => {
    try {
      const { message } = await shoppingList.getListById({ listId });
      setSelectedProductsList(message.products);
      setProductComparisonList(message.products);
      return message;
    } catch (error) {
      if (error.response.status === 403) {
        alert.error('Você não tem permissão para acessar esta lista.');
        navigate('/residentes');
      }
      return error;
    }
  };

  const { data: listProducts, refetch: refetchList } = useQuery({
    queryKey: ['listProducts'],
    queryFn: getListProducts,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !isNewList,
    cacheTime: 0,
  });

  const isVisualization = listProducts ? !listProducts?.canUpdate && !isNewList : false;

  useEffect(() => {
    if (searchParams.get('from') === 'edit' && isVisualization) {
      alert.warning('Essa lista teve sua compra iniciada, não é possível editá-la ou enviá-la');
    }
  }, [searchParams.get('from'), isVisualization]);

  // OBTER INFORMAÇÕES DA ULTIMA LISTA FEITA PARA PREENCHER NA CRIAÇÃO DE UMA NOVA LISTA
  const getLatestList = async () => {
    try {
      const { message } = await shoppingList.getLatestList({ residentId });
      setSelectedProductsList(message.products);
      setProductComparisonList(message.products);
      return message;
    } catch (error) {
      return error;
    }
  };

  const { isFetching: latestListIsFetching } = useQuery({
    queryKey: ['latestList', residentId],
    queryFn: getLatestList,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isNewList,
  });

  const loadingListProducts = !isNewList && !listProducts;

  return (
    <Container>
      {isLoading || loadingListProducts || latestListIsFetching ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        <ResidentShoppingListCard
          selectedProductsList={selectedProductsList}
          setSelectedProductsList={setSelectedProductsList}
          residentInformations={residentInformations}
          listProducts={listProducts}
          refetchList={refetchList}
          productComparisonList={productComparisonList}
        />
      )}
      {!isVisualization && (
        <AddProductCard setSelectedProductsList={setSelectedProductsList} selectedProductsList={selectedProductsList} />
      )}
    </Container>
  );
}
