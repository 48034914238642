import {
  Box, Divider, IconButton, TextField, Typography,
} from '@mui/material';
import React from 'react';
import { ReactComponent as SearchArrowLeft } from '../../../../../assets/icons/square-arrow-left-icon.svg';
import { ReactComponent as MinusSquare } from '../../../../../assets/icons/minus-square-button-icon.svg';
import { ReactComponent as PlusSquare } from '../../../../../assets/icons/plus-square-button-icon.svg';
import { ReactComponent as TrashSquare } from '../../../../../assets/icons/trash-square-button-icon.svg';

export default function QuantityAndSummary({
  handleBack, handleReset, productsList, setProductsList,
}) {
  const incrementQuantity = (name) => {
    setProductsList((prevProductsList) => {
      const updatedItemList = prevProductsList.itemList.map((item) => {
        if (item.name === name) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });
      return {
        ...prevProductsList,
        itemList: updatedItemList,
      };
    });
  };

  const decrementQuantity = (name) => {
    setProductsList((prevProductsList) => {
      const updatedItemList = prevProductsList.itemList.map((item) => {
        if (item.name === name) {
          const newQuantity = item.quantity - 1;
          return {
            ...item,
            quantity: newQuantity < 1 ? 1 : newQuantity,
          };
        }
        return item;
      });
      return {
        ...prevProductsList,
        itemList: updatedItemList,
      };
    });
  };

  const removeItem = (name) => {
    setProductsList((prevProductsList) => {
      const updatedItemList = prevProductsList.itemList.filter((item) => item.name !== name);
      return {
        ...prevProductsList,
        itemList: updatedItemList,
      };
    });
  };

  const handleQuantityChange = (event, name) => {
    const newQuantity = parseInt(event.target.value, 10);
    setProductsList((prevProductsList) => {
      const updatedItemList = prevProductsList.itemList.map((item) => {
        if (item.name === name) {
          return {
            ...item,
            quantity: Number.isNaN(newQuantity) || newQuantity < 1 ? 1 : newQuantity,
          };
        }
        return item;
      });
      return {
        ...prevProductsList,
        itemList: updatedItemList,
      };
    });
  };

  return (
    <Box
      sx={{
        alignSelf: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#F5F5F7',
          borderRadius: '8px',
          width: '100%',
          px: '13px',
          py: '5px',
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          mb: '8px',
        }}
      >
        <IconButton
          onClick={handleReset}
        >
          <SearchArrowLeft data-testid="arrow-left-icon" />
        </IconButton>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#474750',
          }}
        >
          {productsList.name}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: '#F5F5F7',
          borderRadius: '8px',
          width: '100%',
          px: '13px',
          py: '5px',
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          mb: '22px',
        }}
      >
        <IconButton
          onClick={handleBack}
        >
          <SearchArrowLeft data-testid="arrow-left-icon" />
        </IconButton>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#474750',
          }}
        >
          Produtos adicionados
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
        />
        <Typography
          sx={{
            fontSize: '10px',
            color: '#474750',
          }}
        >
          {`${productsList.itemList.length} Itens`}
        </Typography>
      </Box>
      {productsList.itemList.length > 0 && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          mb: '22px',
        }}
      >
        <Typography
          fontWeight="500"
          sx={{
            fontSize: '16px',
            color: '#474750',
          }}
        >
          Quantidade
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#474750',
          }}
        >
          Confira a quantidade dos itens selecionados
        </Typography>
      </Box>
      )}
      <Box
        sx={{
          mt: '12px',
          borderRadius: '8px',
          overflow: 'hidden',
          maxHeight: '308px',
          overflowY: 'auto',

          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
            backgroundColor: '#F5F5F7',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#4A397F',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F5F5F7',
          },
        }}
      >
        {productsList.itemList.map((product) => (
          <Box
            data-testid="listed-products-card"
            key={product.name}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#fff',
              padding: '12px',
              gap: '20px',
              '&:nth-of-type(odd)': {
                backgroundColor: '#F5F5F7',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Typography
                variant="subtitle1"
                color="#474750"
                sx={{
                  fontSize: '12px',
                  lineHeight: '170%',
                  maxWidth: '300px',
                }}
              >
                {product.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                data-testid="remove-item-from-list"
                onClick={() => removeItem(product.name)}
              >
                <TrashSquare />
              </IconButton>
              <IconButton
                data-testid="decrement-quantity-icon"
                onClick={() => decrementQuantity(product.name)}
              >
                <MinusSquare />
              </IconButton>
              <TextField
                size="small"
                variant="standard"
                onChange={(event) => handleQuantityChange(event, product.name)}
                sx={{
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '& input': {
                    textAlign: 'center',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                  },
                  '& .Mui-focused .MuiInput-underline:before': {
                    borderBottom: 'none',
                  },
                }}
                value={product.quantity || 0}
              />
              <IconButton
                data-testid="increment-quantity-icon"
                onClick={() => incrementQuantity(product.name)}
              >
                <PlusSquare />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
