import React, { useState } from 'react';
import { Modal } from '@mui/material';
import ConfirmationInput from '../../../../components/ConfirmationInput';
import Loading from '../../../../components/Loading/Loading';
import routes from '../../../../services/routes';

function DeleteList({
  isOpen, listId, onClose, onSuccess, onFailure,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteList = async () => {
    setIsLoading(true);

    try {
      await routes.shoppingList.removeShoppingList({ shoppingListId: listId });
      onSuccess('Lista apagada com sucesso!');
    } catch {
      onFailure('Não foi possível apagar a lista');
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <ConfirmationInput
          isOpen
          message="Deseja apagar essa lista?"
          AcceptMessage="Sim, quero apagar a lista"
          denialMessage="Não, cancelar"
          onAccept={handleDeleteList}
          onClose={onClose}
        />
      )}
    </Modal>
  );
}

export default DeleteList;
