import React from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search-button-icon-outline.svg';
import StyledTextField from './style';

export default function SearchResidentField({
  searchValue, onClick, sx, ...props
}) {
  return (
    <StyledTextField
      sx={{
        ...sx,
      }}
      InputProps={{
        endAdornment: <SearchIcon style={{ cursor: 'pointer' }} onClick={onClick} data-testid="search-button" />,
      }}
      hiddenLabel
      placeholder="Pesquisar"
      {...props}
    />
  );
}
