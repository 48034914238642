import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 80px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:disabled {
    background: #eaebec;
    pointer-events: all;

    &:hover {
      box-shadow: none;
    }

    svg {
      filter: grayscale(60%);
    }
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: none;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 50dvh;
    max-height: 300px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 0;

  color: #474750;

  font-size: 16px;
  font-weight: 500;
  line-height: 115%; /* 18.4px */
  letter-spacing: 0.024px;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: auto;
`;
