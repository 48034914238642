import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import routes from '../../../../services/routes';
import { BRLMask } from '../../../../utils/inputMasks';

const columns = [
  {
    field: 'id',
    headerName: 'SKU',
    flex: 0.5,
  },
  {
    field: 'name',
    headerName: 'Nome',
    flex: 1,
  },
  {
    field: 'price',
    headerName: 'Preço',
    flex: 0.5,
  },
  {
    field: 'promoPrice',
    headerName: 'Preço Promocional',
    flex: 0.5,
  },
  {
    field: 'expirationDate',
    headerName: 'Data de Validade',
    flex: 0.8,
  },
];

export default function ProductsTable({
  open, setOpen, batch, createdAt,
}) {
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);

  const getProducts = async () => {
    try {
      const { message } = await routes.seller.findProductsListById(
        { batch },
        { page },
      );

      setRowCount(message.total);
      return message.rows;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const { data: products = [], isLoading } = useQuery({
    queryKey: [batch, page],
    queryFn: getProducts,
    onError: () => {
      setOpen(false);
    },
    retry: 0,
    cacheTime: 0,
  });

  const handleModalClose = () => {
    setOpen(false);
  };

  const rows = products.map((product) => ({
    id: product.sku,
    name: product.name,
    price: BRLMask((product.price)),
    promoPrice: product.promoPrice
      ? BRLMask((product.promoPrice))
      : '-',
    expirationDate: moment(product.expirationDate).format('DD/MM/YYYY'),
  }));

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '55%',
          maxWidth: '55%',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: '0 1rem 0 0',
          alignItems: 'center',
        }}
      >
        <DialogTitle
          color="primary"
          sx={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
        >
          {`Produtos da Lista do dia ${createdAt}`}
        </DialogTitle>
        <IconButton onClick={handleModalClose}>
          <Close color="primary" />
        </IconButton>
      </Box>

      <DialogContent>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          localeText={{
            noRowsLabel: 'Nenhum produto adicionado na lista selecionada.',
          }}
          autoHeight
          rowCount={rowCount}
          pageSize={10}
          pagination
          onPageChange={(currPage) => setPage(currPage)}
          loading={isLoading}
          paginationMode="server"
          sx={{ mt: 2 }}
        />
      </DialogContent>
    </Dialog>
  );
}
