import React from 'react';

export default function TrashIcon() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.49935 19.0832H12.4993C16.666 19.0832 18.3327 17.4165 18.3327 13.2498V8.24984C18.3327 4.08317 16.666 2.4165 12.4993 2.4165H7.49935C3.33268 2.4165 1.66602 4.08317 1.66602 8.24984V13.2498C1.66602 17.4165 3.33268 19.0832 7.49935 19.0832Z" stroke="#96969E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.9173 8.2916C13.1006 8.10827 11.2673 8.0166 9.44231 8.0166C8.35898 8.0166 7.27565 8.07493 6.20065 8.18327L5.08398 8.2916" stroke="#96969E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.0918 7.7417L8.20846 7.02503C8.2918 6.50836 8.35846 6.1167 9.28346 6.1167H10.7168C11.6418 6.1167 11.7085 6.52503 11.7918 7.02503L11.9085 7.73337" stroke="#96969E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.7417 8.3584L13.3833 13.8584C13.325 14.7167 13.275 15.3834 11.75 15.3834H8.24167C6.71667 15.3834 6.66666 14.7167 6.60833 13.8584L6.25 8.3584" stroke="#96969E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
