/* eslint-disable max-len */
import React from 'react';

export default function ListIcon({ width = 24, height = 25, color = '#474750' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="task-square">
        <g id="vuesax/linear/task-square">
          <g id="task-square_2">
            <path id="Vector" d="M12.3701 9.37988H17.6201" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_2" d="M6.37988 9.37988L7.12988 10.1299L9.37988 7.87988" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_3" d="M12.3701 16.3799H17.6201" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_4" d="M6.37988 16.3799L7.12988 17.1299L9.37988 14.8799" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_5" d="M9 22.5H15C20 22.5 22 20.5 22 15.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
      </g>
    </svg>
  );
}
