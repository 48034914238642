import React from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { StyledBenvoContainer, TitleContainer } from './style';
import OrderHistoryList from './components/OrderHistoryList';
import routes from '../../../../services/routes';
import useInfiniteList from '../../../../hooks/useInfiniteList';
import { useAlert } from '../../../../context/alertContext';

const { shoppingList } = routes;

export default function OrderHistoryCard({ residentInformations }) {
  const alert = useAlert();
  const navigate = useNavigate();

  const getOrderHistory = async (queries) => {
    try {
      const { message } = await shoppingList.getAllByResidentId({ residentId: residentInformations.id }, queries);
      return message;
    } catch (error) {
      if (error.response.status === 403) {
        alert.error('Você não tem permissão para acessar este residente.');
        navigate('/residentes');
      }
      return error;
    }
  };

  const {
    data: orderHistory = [],
    nextPage,
    loading,
    finished,
    refetch: refetchOrderHistory,
  } = useInfiniteList({
    getData: getOrderHistory,
  });

  return (
    <StyledBenvoContainer>
      <TitleContainer>
        <Typography variant="h5">
          Histórico de listas
        </Typography>
      </TitleContainer>
      <OrderHistoryList
        loading={loading}
        finished={finished}
        onScrollEnd={nextPage}
        orderHistory={orderHistory}
        residentInformations={residentInformations}
        refetchOrderHistory={refetchOrderHistory}
      />
    </StyledBenvoContainer>
  );
}
