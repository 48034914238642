import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { CardContainer, TextContainer } from './style';
import ArrowRight from '../../../../assets/residents/ArrowRight';
import PATHS from '../../../../constants/applicationPaths';
import stateMachine from '../../../../utils/stateMachine';

export default function CreateListCard({ orderHistory }) {
  const path = useLocation().pathname;
  const residentId = path.split('/')[2];
  const hideButton = orderHistory?.some(({ status, isOrderValid, maximumDeliveryDate }) => {
    const { triggerNewOrder } = stateMachine[status];
    return maximumDeliveryDate && !triggerNewOrder && isOrderValid;
  });

  if (hideButton) {
    return null;
  }

  return (
    <CardContainer>
      <TextContainer>
        <Typography variant="h5">
          Criar lista
        </Typography>
        <Typography variant="body1">
          Personalize a lista de compras mensal do seu residente. Adicione, remova ou faça alterações nos itens.
        </Typography>
      </TextContainer>
      <Link to={`${PATHS.residents.root}/${residentId}/${PATHS.residents.shoppingList}/nova-lista`}>
        <IconButton>
          <ArrowRight />
        </IconButton>
      </Link>
    </CardContainer>
  );
}
