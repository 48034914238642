import {
  Dialog,
  DialogActions,
  DialogContent, DialogTitle, IconButton, styled,
} from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';

export const StyledIconButton = styled(IconButton)`
  padding: 10px;
  background: #f5f5f7;

  &:disabled {
    background: #f5f5f7;
    cursor: not-allowed;
    & svg {
      path {
        stroke: #d4d4da;
      }
    }
  }
`;

export const StyledButton = styled(BenvoButton)`
  min-width: 131.5px;
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root{
    padding: 24px 48px;
  }

  img {
    max-width: 190px;
    margin: 0 auto 24px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 0;
  margin-bottom: 4px;

  color: #435565;
  text-align: center;

  font-size: 16px;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: 0.024px;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  margin-bottom: 14px;

  color: #96969e;
  text-align: center;

  font-size: 12px;
  line-height: 170%;
  letter-spacing: 0.12px;

  max-width: 287px;
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0;
`;
