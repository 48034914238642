import React from 'react';
import { Box } from '@mui/system';
import {
  Divider, MenuItem as MuiMenuItem, MenuList, Typography,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { ReactComponent as Logout } from '../../../../assets/icons/logout.svg';
import { ReactComponent as Setting4 } from '../../../../assets/icons/setting-4.svg';
import PATHS from '../../../../constants/applicationPaths';
import { clearToken } from '../../../../utils/configAuth';
import routes from '../../../../services/routes';

function MenuItem({
  Icon, to, onClick, active, children,
}) {
  return (
    <Link to={to} style={{ textDecoration: 'none' }} onClick={() => onClick && onClick()}>
      <Box sx={{
        height: '40px',
        letterSpacing: '0.4px',
        position: 'relative',
        color: active ? 'var(--paleta-senior-purple, #4A397F)' : '#435565',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '4px',
          height: '100%',
          background: active ? 'var(--paleta-senior-purple, #4A397F)' : 'transparent',
          borderRadius: '0 4px 4px 0',
        },
        '&:hover': {
          transition: 'all 0.3s ease-in-out',
          background: 'var(--main-and-gray-very-light-gray, #F5F6F8)',
        },
      }}
      >
        <MuiMenuItem sx={{
          height: '100%',
          padding: '10px 20px',
          '&:hover': {
            background: 'transparent',
          },
        }}
        >
          <Icon style={{ minWidth: '16px' }} />
          <Typography sx={{ ml: '8px', fontSize: '14px' }}>{children}</Typography>
        </MuiMenuItem>
      </Box>
    </Link>
  );
}

export default function MenuWindow({ open, setIsOpen }) {
  const navigate = useNavigate();
  const path = useLocation();
  const posthog = usePostHog();
  const handleLogout = () => {
    routes.auth.logout(); // Doesn't need to be async, let server handle the logout
    posthog?.reset(true);
    clearToken();
    navigate('/login');
  };

  if (!open) return null;

  return (
    <Box sx={{
      zIndex: 100,
      position: 'absolute',
      minWidth: '180px',
      right: 0,
      top: '85px',
    }}
    >
      <Box sx={{
        width: 'inherit',
        background: 'white',
        borderRadius: '8px',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '-21px',
          right: '18px',
          width: '0',
          height: '0',
          border: '11px solid transparent',
          borderBottomColor: '#FFF',
        },
      }}
      >
        <MenuList sx={{ color: '#435565', padding: '0' }}>
          <MenuItem
            active={path.pathname === PATHS.changePassword}
            Icon={Setting4}
            to={PATHS.changePassword}
            onClick={() => setIsOpen(false)}
          >
            Configurações

          </MenuItem>
          {/* <MenuItem Icon={UserOctagon} to={PATHS.changePassword}>Dados do perfil</MenuItem>
          <MenuItem Icon={Notification}>Últimas atualizações</MenuItem>
          <MenuItem Icon={Setting3}>Ajustes</MenuItem> */}

          <Divider />

          <MenuItem Icon={Logout} to={PATHS.login} onClick={handleLogout}>Sair</MenuItem>
        </MenuList>
      </Box>
    </Box>
  );
}
