import React, { useEffect, useState } from 'react';
import { Button, Modal, Box } from '@mui/material';
import convertStatus from '../../utils/convertStatus';
import CardVisualizedRecipe from './components/CardVisualizedRecipe/CardVisualizedRecipe';
import { getTokenInfo } from '../../../../utils/configAuth';
import Loading from '../../../../components/Loading/Loading';
import routes from '../../../../services/routes';

function ModalVisualizedRecipe({
  isOpen,
  handleModalVisualizedClose,
  recipeInfo,
}) {
  const ilpiId = getTokenInfo('ilpiId');
  const [doctorInfo, setDoctorInfo] = useState({
    crm: '',
    name: '',
  });
  const [recipeItems, setRecipeItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchInfo = async () => {
    setIsLoading(true);
    try {
      if (!recipeInfo.doctorId) return;
      const { message: doctor } = await routes.doctor.getDoctorById({
        doctorId: recipeInfo.doctorId,
        ilpiId,
      });
      setDoctorInfo(doctor);
      const { message: recipe } = await routes.recipe.getRecipeById({
        recipeId: recipeInfo.id,
      });
      setRecipeItems(recipe);
    } catch (error) {
      console.log('Recipe', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [recipeInfo]);

  const handleClose = () => {
    handleModalVisualizedClose();
  };

  return (
    <Modal
      overflow={false}
      size="lg"
      height={600}
      open={isOpen}
      onClose={() => handleClose()}
    >
      <Modal.Header>
        <div className="title-information">
          <p>
            Receita de
            {' '}
            {recipeInfo.residentName}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="date_fields">
          <div
            className="date_field_item"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexWrap: 'nowrap',
              margin: '10px 0px',
            }}
          >
            {recipeInfo.recipeType && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '350px',
                }}
              >
                <h5>Tipo de Receita</h5>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    border: '1px solid #F2F2F5',
                    textAlign: 'center',
                    padding: '0px 10px',
                  }}
                >
                  <p style={{ marginBottom: '0px' }}>
                    {recipeInfo.recipeType}
                    {' '}
                    -
                    {' '}
                    {recipeInfo.isDigitalizable
                      ? 'DIGITALIZÁVEL'
                      : 'NÃO DIGITALIZÁVEL'}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="date_fields">
          <div
            className="date_field_item"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexWrap: 'nowrap',
              margin: '10px 0px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '350px',
              }}
            >
              <h5>Assinado</h5>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  border: '1px solid #F2F2F5',
                  textAlign: 'center',
                  padding: '0px 10px',
                }}
              >
                <p style={{ marginBottom: '0px' }}>
                  {recipeInfo.isDoctorSigned ? 'Sim' : 'Não'}
                </p>
              </div>
            </div>
            {recipeInfo.batchShipping && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '350px',
                }}
              >
                <h5>Código de Envio</h5>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    border: '1px solid #F2F2F5',
                    textAlign: 'center',
                    padding: '0px 10px',
                  }}
                >
                  <p style={{ marginBottom: '0px' }}>
                    {recipeInfo.batchShipping}
                  </p>
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '350px',
              }}
            >
              <h5>Status</h5>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  border: '1px solid #F2F2F5',
                  textAlign: 'center',
                  padding: '0px 10px',
                }}
              >
                <p style={{ marginBottom: '0px' }}>
                  {convertStatus(recipeInfo.status)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="date_fields">
          <div
            className="date_field_item"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexWrap: 'nowrap',
              margin: '10px 0px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '350px',
              }}
            >
              <h5>CRM</h5>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  border: '1px solid #F2F2F5',
                  textAlign: 'center',
                  padding: '0px 10px',
                }}
              >
                <p style={{ marginBottom: '0px' }}>{doctorInfo.crm}</p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '350px',
              }}
            >
              <h5>Nome do Médico</h5>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  border: '1px solid #F2F2F5',
                  textAlign: 'center',
                  padding: '0px 10px',
                }}
              >
                <p style={{ marginBottom: '0px' }}>{doctorInfo.name}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="prescription_table" style={{ marginBottom: '20px' }}>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'column nowrap',
                alignContent: 'center',
                justifyContent: 'center',
                minHeight: '200px',
              }}
            >
              <Loading />
            </Box>
          ) : (
            <CardVisualizedRecipe
              loading={isLoading}
              medicineList={recipeItems.recipeItems}
            />
          )}
        </div>
        <div
          style={{
            border: '1px solid #F2F2F5',
            borderRadius: '5px',
            padding: '5px 10px',
          }}
        >
          <h5>Observações:</h5>
          <p style={{ marginBottom: '0' }}>
            {recipeInfo.observation || 'Esta prescrição não possui observações'}
            .
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalVisualizedRecipe;
