import { Box, styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledResidentImageBox = styled(Box)`
  position: relative;
  width: 84px;
  height: 84px;

  button {
    visibility: hidden;
  }

  :hover {
    filter: brightness(0.8);

    button {
      visibility: visible;
      filter: brightness(1.25);
    }
  }
`;
