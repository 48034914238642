import {
  Box, Checkbox, Divider, IconButton, styled,
} from '@mui/material';

export const ContainerResidentInformation = styled(Box)`
  display: flex;
  width: 100%;
  height: 77px;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid var(--grey-variation-lightest-grey, #f5f5f7);
  background: var(--black-and-white-white, #fff);
  justify-content: space-between;
  align-items: center;
`;

export const BoxPhotoAndNameDesktop = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const BoxPhotoAndNameMobile = styled(Box)`
  display: none;

  div[aria-label='Editar dados do residente'] {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const BoxPhoto = styled(Box)`
  width: 77px;
  height: 55px;
`;

export const ContainerReferenceInformationAndActions = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const BoxReferenceInformation = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;

  h4 {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
    text-transform: uppercase;
  }

  p {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.04px;
  }
`;

export const BoxRefereceInformationTextAndActions = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const BoxResidentInformationAndPhoto = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BoxResidentInformation = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  h4 {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.027px;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  p {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
    text-transform: uppercase;
  }
`;

export const BoxActions = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;

  a {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledIconButton = styled(IconButton)`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 80px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StatusContainer = styled(Box)`
  display: flex;
  padding: 7px 0;
  width: 168px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ color }) => color || '#d4d4da'};

  color: ${({ color }) => color || '#474750'};

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 15.6px */
  letter-spacing: 0.12px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledDivider = styled(Divider)`
  border-color: #d4d4da;
  opacity: 1;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  padding: 0;
  svg {
    fill: ${({ checked }) => (checked ? '#4A397F' : '#d4d4da')};
  }
`;

export const EmptyCheckboxContainer = styled(Box)`
  width: 24px;
  height: 24px;
`;
