import React from 'react';

export default function UsersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <g>
        <g stroke="#4A397F">
          <path
            fill="#4A397F"
            d="M4.042 6.125A3.462 3.462 0 017.5 2.667a3.462 3.462 0 013.459 3.457 3.448 3.448 0 01-3.326 3.451h0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0-.001 0a1.184 1.184 0 00-.233-.001c-1.901-.082-3.342-1.591-3.342-3.449z"
            opacity="0.4"
          />
          <path
            fill="#4A397F"
            d="M3.546 17.243h-.002c-.948-.633-1.41-1.443-1.41-2.26 0-.817.462-1.635 1.419-2.275 1.072-.71 2.503-1.079 3.958-1.079s2.882.37 3.945 1.079c.946.63 1.404 1.44 1.411 2.26 0 .825-.463 1.635-1.412 2.276-1.068.716-2.498 1.09-3.955 1.09-1.456 0-2.887-.374-3.954-1.09z"
          />
          <path
            fill="#4A397F"
            d="M12.53 9.086a4.328 4.328 0 00.977-3.126h0v-.003a4.332 4.332 0 00-.413-1.506 2.494 2.494 0 013.067 2.208 2.483 2.483 0 01-2.142 2.683h-.017a.93.93 0 00-.212.02 2.549 2.549 0 01-1.26-.276z"
            opacity="0.4"
          />
          <path
            fill="#292D32"
            d="M17.827 14.278h0v.006c-.05.6-.434 1.17-1.192 1.586h-.001a4.863 4.863 0 01-1.587.519 3.28 3.28 0 00.417-1.318h0v-.006c.1-1.236-.496-2.377-1.576-3.246h0l-.003-.003a6.665 6.665 0 00-.563-.399c1.327-.022 2.688.365 3.605 1.106h.001c.674.542.954 1.166.9 1.755z"
          />
        </g>
      </g>
    </svg>
  );
}
