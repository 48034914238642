import {
  Box, Checkbox, Divider, styled, Typography,
} from '@mui/material';

export const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
`;

export const PageTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
  margin-bottom: 10px;
`;

export const PageSubtitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const StyledDivider = styled(Divider)``;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0 !important;
`;

export const ExamsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const SearchContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  gap: 12px;
`;

export const ExamsListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #f5f5f7;
  max-height: 220px;
  overflow-y: auto;
  width: 100%;
`;

export const ExamItemContainer = styled('label')`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
  gap: 12px;

  &:nth-of-type(even) {
    background: #ffffff;
  }
`;

export const ExamItemInformation = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Price = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.04px;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const ObservationTextContainer = styled(Box)`
  align-self: stretch;
`;

export const ObservationText = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.04px;
`;

export const ObservationTextSpan = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.04px;
  text-transform: capitalize;
`;
