import { mapTaskType } from '../../../utils/mapTaskType';

export const csvHeadersTaskRegistry = [
  'Título',
  'Tipo',
  'Cuidador',
  'Residente',
  'Justificativa',
  'Data da Tarefa',
  'Data do Registro',
  'Status',
];

export const createRegistryData = (tasks) => tasks.map((task) => [
  task.title,
  mapTaskType(task.type).name,
  task.employeeName,
  task.residentName,
  task.justification,
  task.date,
  task.completedAt,
  task.status === 'completed' ? 'Concluída' : 'Cancelada',
]);
