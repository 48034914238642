import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import routes from '../../services/routes';
import schemas from '../../schemas';
import { useAlert } from '../../context/alertContext';
import { getTokenInfo, insertToken } from '../../utils/configAuth';
import PATHS from '../../constants/applicationPaths';
import BenvoLoginImage from '../../assets/BenvoLoginImage.png';
import LoginForm from './components/LoginForm';
import alertMapper from '../../utils/alertMapper';
import { BENVO_FOOTER } from '../../constants';
import {
  StyledBoxContainer, StyledLeftBox, StyledLoginFormContainer, StyledRightBox, StyledTypographyFooter,
} from './style';

function Login() {
  const [isLoading, setIsLoading] = useState(false);

  const alert = useAlert();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: joiResolver(schemas.login.loginSchema),
  });

  const loginRequest = async ({ email, password }) => {
    setIsLoading(true);

    const payload = {
      email,
      password,
    };

    try {
      const { message } = await routes.publicRoutes.login(payload);

      insertToken(message);

      alert.success('Perfeito! Você será redirecionado em breve');
      // await routes.metrics.postMixpanel('Login', { status: 'Ok' });

      setTimeout(() => {
        const userType = getTokenInfo('privilege');

        switch (userType) {
          case 'superadmin':
            return navigate(PATHS.superadmin.home);
          case 'caregiver':
            return navigate(PATHS.caregiver.home);
          default:
            return navigate(PATHS.home);
        }
      }, 1000);
    } catch (error) {
      alert[alertMapper(error.response?.status)]();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = getTokenInfo('token');

    if (token) {
      const userType = getTokenInfo('privilege');
      switch (userType) {
        case 'superadmin':
          navigate(PATHS.superadmin.home);
          break;
        case 'caregiver':
          navigate(PATHS.caregiver.home);
          break;
        default:
          navigate(PATHS.home);
      }
    }
  }, []);

  return (
    <StyledBoxContainer>
      <StyledLeftBox>
        <StyledLoginFormContainer
          sx={{
            width: {
              xs: '100%',
              sm: '436px',
            },
          }}
        >
          <LoginForm
            isLoading={isLoading}
            watch={watch}
            register={register}
            errors={errors}
            onSubmit={handleSubmit(loginRequest)}
          />
        </StyledLoginFormContainer>

        <StyledTypographyFooter>
          {BENVO_FOOTER}
        </StyledTypographyFooter>
      </StyledLeftBox>

      <StyledRightBox
        data-image={BenvoLoginImage}
        sx={{ width: { lg: '55%' } }}
      />
    </StyledBoxContainer>
  );
}

export default Login;
