import {
  Box, List, ListItem, Typography, styled,
} from '@mui/material';

export const StyledList = styled(List)`
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  max-height: 396px;
  overflow-y: auto;
  width: 100%;
`;

export const StyledServiceListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-variation-dark-grey, #474750);
  padding: 12px;
  background: #fefefe;

  div {
    display: flex;
    align-items: center;
  }

  p {
    font-size: 12px;
    letter-spacing: 0.12px;
  }

  :nth-child(odd) {
    background: var(--grey-variation-lightest-grey, #f5f5f7);

    hr {
      background: white;
    }
  }
`;

export const StyledDivider = styled('hr')`
  width: 1px;
  align-self: center;
  background: #f5f5f7;
  border: none;
  opacity: 1;
  margin: 0 12px;
  height: 12px;
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  width: 100%;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

export const OrderInformationTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const OrderInformationResidentName = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 18.4px */
  letter-spacing: 0.024px;
`;

export const OrderInformationPrice = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.04px;

  white-space: nowrap;
`;

export const OrderInformationTotalContainer = styled(Box)`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #f5f5f7;
  background: #fefefe;
`;

export const OrderInformationTotalText = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 15.6px */
  letter-spacing: 0.12px;
`;

export const IsHomeTextContainer = styled(Box)``;

export const IsHomeText = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.04px;
`;

export const IsHomeSpan = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.04px;
  text-transform: capitalize;
`;
