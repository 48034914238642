import Joi from 'joi';

const createCard = Joi.object({
  firstName: Joi.string().min(3).required().messages({
    'string.min': 'O primeiro nome deve ter um tamanho mínimo de {#limit}',
    'any.required': 'O primeiro nome é obrigatório',
    'string.empty': 'O primeiro nome não pode ficar vazio',
  }),
  lastName: Joi.string().min(3).required().messages({
    'string.min': 'O último nome deve ter um tamanho mínimo de {#limit}',
    'any.required': 'O último nome é obrigatório',
    'string.empty': 'O último nome não pode ficar vazio',
  }),
  number: Joi.string().length(19).required().messages({
    'string.min': 'O número do cartão deve ter um tamanho de {#limit} dígitos',
    'any.required': 'O número do cartão é obrigatório',
    'string.empty': 'O número do cartão não pode ficar vazio',
    'string.length': 'O número do cartão deve ter {#limit} dígitos',
  }),
  cvv: Joi.string().length(3).required().messages({
    'string.min': 'O CVV deve ter um tamanho de {#limit} dígitos',
    'any.required': 'O CVV é obrigatório',
    'string.empty': 'O CVV não pode ficar vazio',
    'string.length': 'O CVV deve ter {#limit} dígitos',
  }),
  expirationDate: Joi.string().length(7).required().messages({
    'string.min': 'A data de expiração deve ter um tamanho de {#limit} dígitos',
    'any.required': 'A data de expiração é obrigatória',
    'string.empty': 'A data de expiração não pode ficar vazia',
    'string.length': 'A data de expiração deve ter {#limit} dígitos',
  }),
  email: Joi.string().email({ tlds: false }).required().messages({
    'any.required': 'O email é obrigatório',
    'string.email': 'O email deve ser um email válido',
    'string.empty': 'O email não pode ficar vazio',
  }),
});

export default {
  createCard,
};
