import React from 'react';
import { TextField, InputAdornment, CircularProgress } from '@mui/material';

export default function BenvoTextField(props) {
  const {
    label,
    placeholder,
    fullWidth,
    sx,
    onChange,
    endIcon,
    startIcon,
    isLoading = false,
    onIconClick,
    ...otherProps
  } = props;

  const endAdornment = (() => {
    if (isLoading) {
      return (
        <InputAdornment position="end">
          <CircularProgress color="inherit" size={20} />
        </InputAdornment>
      );
    } if (endIcon) {
      return (
        <InputAdornment sx={{ cursor: 'pointer' }} onClick={onIconClick} position="end">{endIcon}</InputAdornment>
      );
    }
    return undefined;
  })();

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      fullWidth
      size="small"
      sx={{
        alignSelf: 'flex-start',
        backgroundColor: '#F5F5F7',
        borderRadius: '8px',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
          },
        },
        '.MuiInputBase-input::placeholder': {
          color: '#474750',
          fontSize: '12px',
        },
        ...sx,
      }}
      onChange={onChange}
      InputProps={{
        endAdornment,
        startAdornment: startIcon ? (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ) : undefined,
      }}
      {...otherProps}
    />
  );
}
