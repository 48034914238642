import React, { useState } from 'react';
import {
  MenuItem, Divider,
} from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';
import BenvoDrawer from '../../../../components/BenvoDrawer';
import BenvoSelectField from '../../../../components/BenvoSelectField';
import Doctor from './types/Doctor';
import Nurse from './types/Nurse';
import Caregiver from './types/Caregiver';
import Employee from './types/Employee';
import { Container, Title } from './style';

const types = [
  { value: 'doctor', label: 'Médico', dataTestId: 'doctor' },
  { value: 'nurse', label: 'Enfermeiro', dataTestId: 'nurse' },
  { value: 'caregiver', label: 'Cuidador', dataTestId: 'caregiver' },
  { value: 'employee', label: 'Funcionário', dataTestId: 'employee' },
];

export default function CreateCollaborator({ getUsersAndCollaborators }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedType, setSelectedType] = useState('doctor');

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
    setSelectedType('doctor');
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const renderFormBasedOnType = () => {
    const form = {
      doctor: <Doctor
        handleCloseDrawer={handleCloseDrawer}
        selectedType={selectedType}
        getUsersAndCollaborators={getUsersAndCollaborators}
      />,
      nurse: <Nurse
        handleCloseDrawer={handleCloseDrawer}
        selectedType={selectedType}
        getUsersAndCollaborators={getUsersAndCollaborators}
      />,
      caregiver: <Caregiver
        handleCloseDrawer={handleCloseDrawer}
        selectedType={selectedType}
        getUsersAndCollaborators={getUsersAndCollaborators}
      />,
      employee: <Employee
        handleCloseDrawer={handleCloseDrawer}
        selectedType={selectedType}
        getUsersAndCollaborators={getUsersAndCollaborators}
      />,
    };
    return form[selectedType];
  };

  return (
    <>
      <BenvoButton
        variant="primary"
        horizontalPadding={18}
        onClick={handleOpenDrawer}
      >
        Novo Colaborador
      </BenvoButton>
      <BenvoDrawer
        open={openDrawer}
        onClose={handleCloseDrawer}
        title="Cadastro de funcionário"
        justifyContent="flex-start"
      >
        <Container>
          <Title variant="h6">Area de atuação</Title>
          <BenvoSelectField
            fullWidth
            value={selectedType}
          >
            {types.map((type) => (
              <MenuItem
                key={type.value}
                value={type.value}
                data-testid={type.dataTestId}
                onClick={() => setSelectedType(type.value)}
              >
                {type.label}
              </MenuItem>
            ))}
          </BenvoSelectField>
          <Divider orientation="horizontal" flexItem />
          {renderFormBasedOnType()}
        </Container>
      </BenvoDrawer>
    </>
  );
}
