import React, { useState } from 'react';
import { Grid } from '@mui/material';
import {
  StyledButton,
  StyledDialog, StyledDialogContent, StyledDialogTitle,
} from './style';
import BenvoTextField from '../../../../components/BenvoField/style';
import { cnpjInputMask, cpfInputMask } from '../../../../utils/inputMasks';

const privilegeTranslations = {
  admin: 'Administrador',
  user: 'Usuário',
};

const translatePrivilege = (privilege) => privilegeTranslations[privilege] || 'Usuário';

export default function ViewIlpiUserModal({ user }) {
  const [openEditUserModal, setOpenEditUserModal] = useState(false);

  const handleOpenEditUserModal = () => {
    setOpenEditUserModal(true);
  };

  const handleCloseEditUserModal = () => {
    setOpenEditUserModal(false);
  };

  return (
    <div>
      <StyledButton variant="secondary" onClick={handleOpenEditUserModal}>
        Visualizar
      </StyledButton>
      <StyledDialog open={openEditUserModal} onClose={handleCloseEditUserModal}>
        <StyledDialogTitle>Visualizar Usuário</StyledDialogTitle>
        <StyledDialogContent>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12} sm={6}>
              <BenvoTextField
                placeholder="Nome"
                fullWidth
                value={user.name}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              { user.cpf
                ? (
                  <BenvoTextField
                    placeholder="CPF"
                    fullWidth
                    value={cpfInputMask(user.cpf)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <BenvoTextField
                    placeholder="CNPJ"
                    fullWidth
                    value={cnpjInputMask(user.cnpj)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <BenvoTextField
                placeholder="E-mail"
                fullWidth
                value={user.email}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <BenvoTextField
                fullWidth
                value={translatePrivilege(user.privilege)}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </StyledDialogContent>
      </StyledDialog>
    </div>

  );
}
