import React from 'react';

export default function InfoCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      fill="none"
      viewBox="0 0 13 12"
    >
      <path
        fill="#D4D4DA"
        d="M6.117 11.375A5.38 5.38 0 01.742 6 5.38 5.38 0 016.117.625 5.38 5.38 0 0111.492 6a5.38 5.38 0 01-5.375 5.375zm0-10A4.63 4.63 0 001.492 6a4.63 4.63 0 004.625 4.625A4.63 4.63 0 0010.742 6a4.63 4.63 0 00-4.625-4.625z"
      />
      <path
        fill="#D4D4DA"
        d="M6.117 6.875a.378.378 0 01-.375-.375V4c0-.205.17-.375.375-.375s.375.17.375.375v2.5c0 .205-.17.375-.375.375zM6.117 8.5a.498.498 0 01-.19-.04.577.577 0 01-.165-.105.516.516 0 01-.105-.165.498.498 0 01-.04-.19c0-.065.015-.13.04-.19a.577.577 0 01.105-.165.577.577 0 01.165-.105.5.5 0 01.38 0c.06.025.115.06.165.105.045.05.08.105.105.165.025.06.04.125.04.19s-.015.13-.04.19a.516.516 0 01-.105.165.577.577 0 01-.165.105.498.498 0 01-.19.04z"
      />
    </svg>
  );
}
