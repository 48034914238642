import Joi from 'joi';

const firstAccessSchema = Joi.object({
  password: Joi.string().min(6).required().messages({
    'string.empty': 'Este campo não pode estar vazio',
    'string.min': 'Este campo deve ter no mínimo {#limit} caracteres',
    'any.required': 'Este campo é obrigatório',
  }),
  repeatPassword: Joi.string().valid(Joi.ref('password')).required().messages({
    'string.empty': 'Este campo não pode estar vazio',
    'any.required': 'Este campo é obrigatório',
    'any.only': 'As senhas devem ser iguais',
  }),
});

export default { firstAccessSchema };
