import { Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { upperCaseFirstLetter } from '../../../../utils/inputMasks';
import { ReactComponent as More } from '../../../../assets/icons/more.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right.svg';
import PATHS from '../../../../constants/applicationPaths';

const pathMapper = {
  evolucoes: 'Evoluções',
  prescricoes: 'Prescrições',
  exames: 'Solicitação de exames',
  'dados-do-residente': 'Dados do residente',
  'receita-de-medicamentos': 'Receita de Medicamentos',
  'acesso-modulo': 'Acesso ao módulo',
  marketplace: 'Sistema de Compras',
  'solicitar-servico': 'Solicitar serviço',
  'historico-de-pedidos': 'Pedidos',
  'lista-de-compras': 'Lista de compras',
};

export default function Breadcrumb() {
  const { pathname } = useLocation();
  const [breakpointsLimit, setBreakointsLimit] = useState(null);
  const params = Object.values(useParams());

  const normalizePath = (path) => pathMapper[path]
    || path
      .split(/[^a-zA-Z0-9]/g)
      .map(upperCaseFirstLetter)
      .join(' ');

  const { paths, limited } = useMemo(() => {
    const result = {
      limited: false,
      paths: [
        {
          slice: 'Benvo',
          path: PATHS.home,
        },
      ],
    };

    let currentPath = '/';

    const cuttedURL = pathname
      .split('/')
      .filter((item) => item);

    cuttedURL.forEach((item) => {
      currentPath += `${item}/`;

      if (!params.includes(item)) {
        result.paths.push({
          slice: normalizePath(item),
          path: currentPath,
        });
      }
    });

    if (params.includes(cuttedURL.at(-1))) {
      result.paths.at(-1).path += cuttedURL.at(-1);
    }

    if (breakpointsLimit) {
      while (result.paths.length > breakpointsLimit) {
        result.paths.splice(result.paths.length - 2, 1);
        result.limited = true;
      }
    }

    return result;
  }, [pathname, breakpointsLimit]);

  const handleBreakpointsLimit = () => {
    const screenSize = window.innerWidth;

    if (screenSize >= 1920) setBreakointsLimit(null);
    else if (screenSize <= 500) setBreakointsLimit(1);
    else setBreakointsLimit(Math.floor((screenSize - 500) / 140 + 1));
  };

  useEffect(() => {
    window.addEventListener('resize', handleBreakpointsLimit);
    handleBreakpointsLimit();
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {paths.map(({ path, slice }, i) => (
        <Link key={path} to={path} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
          {i > 0 && <ArrowRight style={{ margin: '0 16px', width: '15px' }} />}

          {limited && i === paths.length - 1 && (
            <>
              <More />
              <ArrowRight style={{ margin: '0 16px', width: '15px' }} />
            </>
          )}

          <Typography
            key={slice}
            sx={{
              fontSize: '16px',
              fontWeight: i ? 400 : 500,
              color: i === paths.length - 1 ? '#4A397F' : '#474750',
            }}
          >
            {slice}
          </Typography>
        </Link>
      ))}
    </Box>
  );
}
