import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { ReactComponent as MockPhoto } from '../../assets/mocked-image.svg';

export default function BenvoImage({
  src, alt, DefaultImage, sx,
}) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  if (!src) {
    const Mock = DefaultImage || MockPhoto;

    return (
      <Mock style={{
        width: '100%',
        minWidth: '77px',
        height: '100%',
        ...sx,
      }}
      />
    );
  }

  return (
    <>
      { isLoading && (
        <Skeleton
          variant="rounded"
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      )}

      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        style={{
          display: isLoading ? 'none' : 'block',
          width: '100%',
          minWidth: '77px',
          height: '100%',
          objectFit: 'scale-down',
          borderRadius: '10px',
          ...sx,
        }}
      />
    </>
  );
}

BenvoImage.propTypes = {
  alt: PropTypes.string.isRequired,
  DefaultImage: PropTypes.elementType,
  src: PropTypes.string,
};

BenvoImage.defaultProps = {
  src: null,
  DefaultImage: null,
};
