import {
  Box,
  TextField, Typography, styled,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import BenvoButton from '../../../../components/BenvoButton/style';

export const TextAndActionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
`;

export const TextContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;

  h5 {
    color: #96969e;

    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.035px;
    width: 100%;
    max-width: 334px;
    text-align: center;
  }

  h6 {
    color: #435565;

    font-size: 16px;
    font-weight: 500;
    line-height: 115%; /* 18.4px */
    letter-spacing: 0.024px;
  }
`;

export const ActionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    color: #96969e;
    font-size: 10px;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.025px;
  }
`;

export const StyledTextField = styled(TextField)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    font-size: 12px;

    & fieldset {
      border: none;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &:hover fieldset {
      border: none;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &.Mui-focused fieldset {
      border: none;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }

    &.MuiInputBase-root {
      input {
        padding: 0px !important;

        &::placeholder {
          color: var(--grey-variation-light-grey, #474750);
          opacity: 1;
        }
      }
    }
  }

  p {
    background: #fff !important;
    margin: 0px !important;
  }
`;

export const StyledBenvoButton = styled(BenvoButton)`
  padding: 16px 24px;
  gap: 8px;
  &:hover {
    transition: all 0.3s ease;
    svg {
      path {
        fill: #4a397f;
      }
    }
  }
`;

export const StyledDesktopDatePicker = styled(DesktopDatePicker)`
  width: 100%;
`;

export const ObservationText = styled(Typography)`
  color: #96969e;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.025px;
  width: 100%;
  max-width: 334px;
`;
