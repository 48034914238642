import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { getTokenInfo } from '../../../../utils/configAuth';

function Filter({ onChange, onFailure, setShowForm }) {
  const ilpiId = getTokenInfo('ilpiId');
  const [values, setValues] = useState({
    targetBuyer: 'all',
    status: 'all',
    beginDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    residentName: '',
    ilpiId,
  });

  const handleFilter = () => {
    const params = {
      ...values,
      status: values.status !== 'all' ? values.status : '',
      beginDate: values.beginDate.format('YYYY-MM-DD'),
      endDate: values.endDate.format('YYYY-MM-DD'),
    };

    onChange(params);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!values.beginDate?.isValid() || !values.endDate?.isValid()) {
      onFailure('Data inválida');
    } else if (values.beginDate > values.endDate) {
      onFailure('A data inicial não pode ser maior que a data final');
    } else {
      handleFilter();
    }
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const implementNonResidentRules = () => {
    setValues({
      ...values,
      residentName: '',
    });
  };

  const implementSearchByResidentRules = () => {
    setValues({
      ...values,
      targetBuyer: 'residents',
    });
  };

  useEffect(() => {
    if (values.targetBuyer !== 'residents') {
      implementNonResidentRules();
    }
  }, [values.targetBuyer]);

  useEffect(() => {
    if (values.residentName) {
      implementSearchByResidentRules();
    }
  }, [values.residentName]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={2.5}>
          <TextField
            label="Busca por residente"
            value={values.residentName}
            onChange={({ target }) => handleChange('residentName', target.value)}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <DesktopDatePicker
            label="Data de início"
            inputFormat="DD/MM/YYYY"
            value={values.beginDate}
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
            onChange={(date) => handleChange('beginDate', date)}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <DesktopDatePicker
            label="Data final"
            inputFormat="DD/MM/YYYY"
            value={values.endDate}
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
            onChange={(date) => handleChange('endDate', date)}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={1.5}>
          <FormControl fullWidth>
            <InputLabel id="buyer">Comprador</InputLabel>
            <Select
              fullWidth
              labelId="buyer"
              label="Comprador"
              value={values.targetBuyer}
              onChange={({ target }) => handleChange('targetBuyer', target.value)}
              size="small"
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="ilpi">Ilpi</MenuItem>
              <MenuItem value="residents">Residente</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} lg={1.5}>
          <FormControl fullWidth>
            <InputLabel id="status">Status</InputLabel>
            <Select
              fullWidth
              labelId="status"
              label="Status"
              value={values.status}
              onChange={({ target }) => handleChange('status', target.value)}
              size="small"
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="requested">Requerido</MenuItem>
              <MenuItem value="sent">Enviado</MenuItem>
              <MenuItem value="done">Finalizado</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item display="flex">
          <Button variant="contained" type="submit">
            Buscar
          </Button>
        </Grid>
        <Grid item xs="auto" md display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            data-testid="create-list-button"
            onClick={() => setShowForm(true)}
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
          >
            Lista
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default Filter;
