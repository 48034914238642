import Joi from 'joi';

const createEvolution = Joi.object({
  status: Joi.string().required().messages({
    'any.required': 'O status é obrigatório',
  }),
  responsibleName: Joi.string().required().messages({
    'any.required': 'O nome do responsável é obrigatório',
    'string.empty': 'O nome do responsável é obrigatório',
  }),
  date: Joi.date().required().messages({
    'any.required': 'A data da evolução é obrigatória',
  }),
  observation: Joi.string().allow(''),
  residentId: Joi.string().uuid().required().messages({
    'any.required': 'O residente é obrigatório',
    'string.uuid': 'O residente é inválido',
    'string.empty': 'É obrigatório informar o residente',
  }),
  kind: Joi.valid('Bem Estar', 'Sinais Vitais', 'Diurese').messages({
    'any.only': 'O tipo de evolução é obrigatório',
  }),
  humor: Joi.when('kind', {
    is: 'Bem Estar',
    then: Joi.string().required().messages({}),
    otherwise: Joi.string().forbidden(),
  }),
  appetite: Joi.when('kind', {
    is: 'Bem Estar',
    then: Joi.string().required(),
    otherwise: Joi.string().forbidden(),
  }),
  repose: Joi.when('kind', {
    is: 'Bem Estar',
    then: Joi.string().required(),
    otherwise: Joi.string().forbidden(),
  }),
  systolicBloodPressure: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(0).max(180).required()
      .messages({
        'number.base': 'A pressão arterial deve ser um número',
        'number.min': 'A pressão arterial deve ser maior que 0',
        'number.max': 'A pressão arterial deve ser menor que 180',
        'any.required': 'A pressão arterial é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  diastolicBloodPressure: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(0).max(180).required()
      .messages({
        'number.base': 'A pressão arterial deve ser um número',
        'number.min': 'A pressão arterial deve ser maior que 0',
        'number.max': 'A pressão arterial deve ser menor que 180',
        'any.required': 'A pressão arterial é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  respiratoryFrequency: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(4).max(28).required()
      .messages({
        'number.base': 'A frequência respiratória deve ser um número',
        'number.min': 'A frequência respiratória deve ser maior que 4',
        'number.max': 'A frequência respiratória deve ser menor que 28',
        'any.required': 'A frequência respiratória é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  heartFrequency: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(40).max(140).required()
      .messages({
        'number.base': 'A frequência cardíaca deve ser um número',
        'number.min': 'A frequência cardíaca deve ser maior que 40',
        'number.max': 'A frequência cardíaca deve ser menor que 140',
        'any.required': 'A frequência cardíaca é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  oxygenSaturation: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(70).max(100).required()
      .messages({
        'number.base': 'A saturação de oxigênio deve ser um número',
        'number.min': 'A saturação de oxigênio deve ser maior que 70',
        'number.max': 'A saturação de oxigênio deve ser menor que 100',
        'any.required': 'A saturação de oxigênio é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  temperature: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(35).max(43).required()
      .messages({
        'number.base': 'A temperatura deve ser um número',
        'number.min': 'A temperatura deve ser maior que 35',
        'number.max': 'A temperatura deve ser menor que 43',
        'any.required': 'A temperatura é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  hgt: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(50).max(600).required()
      .messages({
        'number.base': 'A altura deve ser um número',
        'number.min': 'A altura deve ser maior que 50',
        'number.max': 'A altura deve ser menor que 600',
        'any.required': 'A altura é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  diuresis: Joi.when('kind', {
    is: 'Diurese',
    then: Joi.string().min(3).required().messages({
      'any.required': 'Campo obrigatório',
      'string.empty': 'Campo obrigatório',
      'string.min': 'Campo precisa ter mais de 5 caracteres',
    }),
    otherwise: Joi.string().forbidden(),
  }),
  evacuation: Joi.when('kind', {
    is: 'Diurese',
    then: Joi.string().min(3).required().messages({
      'any.required': 'Campo obrigatório',
      'string.empty': 'Campo obrigatório',
      'string.min': 'Campo precisa ter mais de 5 caracteres',
    }),
    otherwise: Joi.string().forbidden(),
  }),
});

const updateEvolution = Joi.object({
  status: Joi.string(),
  responsibleName: Joi.string(),
  date: Joi.date(),
  observation: Joi.string(),
  kind: Joi.valid('Bem Estar', 'Sinais Vitais', 'Diurese'),
  humor: Joi.when('kind', {
    is: 'Bem Estar',
    then: Joi.string(),
    otherwise: Joi.string().forbidden(),
  }),
  appetite: Joi.when('kind', {
    is: 'Bem Estar',
    then: Joi.string(),
    otherwise: Joi.string().forbidden(),
  }),
  repose: Joi.when('kind', {
    is: 'Bem Estar',
    then: Joi.string(),
    otherwise: Joi.string().forbidden(),
  }),
  systolicBloodPressure: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(0).max(180).required()
      .messages({
        'number.base': 'A pressão arterial deve ser um número',
        'number.min': 'A pressão arterial deve ser maior que 0',
        'number.max': 'A pressão arterial deve ser menor que 180',
        'any.required': 'A pressão arterial é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  diastolicBloodPressure: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(0).max(180).required()
      .messages({
        'number.base': 'A pressão arterial deve ser um número',
        'number.min': 'A pressão arterial deve ser maior que 0',
        'number.max': 'A pressão arterial deve ser menor que 180',
        'any.required': 'A pressão arterial é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  respiratoryFrequency: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(4).max(28).required()
      .messages({
        'number.base': 'A frequência respiratória deve ser um número',
        'number.min': 'A frequência respiratória deve ser maior que 4',
        'number.max': 'A frequência respiratória deve ser menor que 28',
        'any.required': 'A frequência respiratória é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  heartFrequency: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(40).max(140).required()
      .messages({
        'number.base': 'A frequência cardíaca deve ser um número',
        'number.min': 'A frequência cardíaca deve ser maior que 40',
        'number.max': 'A frequência cardíaca deve ser menor que 140',
        'any.required': 'A frequência cardíaca é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  oxygenSaturation: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(70).max(100).required()
      .messages({
        'number.base': 'A saturação de oxigênio deve ser um número',
        'number.min': 'A saturação de oxigênio deve ser maior que 70',
        'number.max': 'A saturação de oxigênio deve ser menor que 100',
        'any.required': 'A saturação de oxigênio é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  temperature: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(35).max(43).required()
      .messages({
        'number.base': 'A temperatura deve ser um número',
        'number.min': 'A temperatura deve ser maior que 35',
        'number.max': 'A temperatura deve ser menor que 43',
        'any.required': 'A temperatura é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  hgt: Joi.when('kind', {
    is: 'Sinais Vitais',
    then: Joi.number().min(50).max(600).required()
      .messages({
        'number.base': 'A altura deve ser um número',
        'number.min': 'A altura deve ser maior que 50',
        'number.max': 'A altura deve ser menor que 600',
        'any.required': 'A altura é obrigatória',
      }),
    otherwise: Joi.string().forbidden(),
  }),
  diuresis: Joi.when('kind', {
    is: 'Diurese',
    then: Joi.string(),
    otherwise: Joi.string().forbidden(),
  }),
  evacuation: Joi.when('kind', {
    is: 'Diurese',
    then: Joi.string(),
    otherwise: Joi.string().forbidden(),
  }),
});

export default {
  createEvolution,
  updateEvolution,
};
