import React from 'react';

function LinkIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="link">
        <path
          id="Vector"
          d="M11.2501 10C11.2501 12.65 9.10841 14.7917 6.45841 14.7917C3.80841 14.7917 1.66675 12.65 1.66675 10C1.66675 7.35 3.80841 5.20833 6.45841 5.20833"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8.33325 10C8.33325 7.24167 10.5749 5 13.3333 5C16.0916 5 18.3333 7.24167 18.3333 10C18.3333 12.7583 16.0916 15 13.3333 15"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_123_9"
          x1="2.07579"
          y1="10.9945"
          x2="14.2887"
          y2="10.9945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F85C4C" />
          <stop offset="0.854167" stopColor="#9F56B4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_123_9"
          x1="8.76008"
          y1="11.0377"
          x2="21.504"
          y2="11.0377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F85C4C" />
          <stop offset="0.854167" stopColor="#9F56B4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LinkIcon;
