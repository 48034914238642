// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react';
import {
  Box, CircularProgress, Collapse, Typography,
} from '@mui/material';
import moment from 'moment-business-days';
import { useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ActionContainer,
  EmptyReferenceList,
  ResponsibleListContainer,
  StyledBenvoButton,
  StyledCheckBox,
  StyledCollapse,
  StyledDesktopDatePicker,
  StyledForm,
  StyledTextField,
  TextAndActionsContainer,
  TextContainer,
} from './style';
import SendIndividualShoppingListImage from '../../../../../../assets/residents/send-individual-resident-shopping-list.png';
import BenvoDialog from '../../../../../../components/Dialog';
import SharebleLink from '../../../../../residents/components/ShareableLink';
import SendWhite from '../../../../../../assets/residents/SendWhite';
import routes from '../../../../../../services/routes';
import { getTokenInfo } from '../../../../../../utils/configAuth';
import { useAlert } from '../../../../../../context/alertContext';
import CalendarIcon from '../../../../../../assets/CalendarIcon';
import { convertAPIDateWithoutTime } from '../../../../../../utils/dateFormat';
import PATHS from '../../../../../../constants/applicationPaths';
import { phoneWithCountryCodeMaskAndSpace } from '../../../../../../utils/inputMasks';
import InfoCircle from '../../../../../../assets/shoppingList/info-circle';

const { shoppingList, resident } = routes;

const businessDays = moment().businessAdd(5)._d;

export default function SendIndividualShoppingListModal({
  residentInformation,
  orderInformation,
  refetchShoppingList,
  children,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [isSharingFinished, setIsSharingFinished] = useState(false);
  const [responsibleId, setResponsibleId] = useState([]);
  const [orderDeliveryDay, setOrderDeliveryDay] = useState(
    moment(orderInformation?.maximumDeliveryDate || null),
  );
  const datePickerRef = useRef(null);

  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const ilpiId = getTokenInfo('ilpiId');

  const handleDayChange = (event) => {
    if (event) {
      setOrderDeliveryDay(event._d);
      setIsSharingFinished(false);
    }
  };

  const handleResponsibleContactChange = (id) => {
    if (responsibleId.includes(id)) {
      setResponsibleId(responsibleId.filter((item) => item !== id));
    } else {
      setResponsibleId([...responsibleId, id]);
    }
  };

  const collapseUrl = () => {
    const apiDeliveryDate = orderInformation?.maximumDeliveryDate;
    const localDeliveryDate = orderDeliveryDay;
    if (apiDeliveryDate) {
      return (
        apiDeliveryDate === convertAPIDateWithoutTime(localDeliveryDate)
        || isSharingFinished
      );
    }
    return isSharingFinished;
  };

  const handleOpenModal = async () => {
    const listId = orderInformation?.id;

    const {
      message: { canUpdate },
    } = await shoppingList.getListById({ listId });

    if (canUpdate) {
      setOpenModal(true);
      return;
    }

    refetchShoppingList();
    if (searchParams.get('from') !== 'edit' || canUpdate) {
      await queryClient.invalidateQueries({ queryKey: ['orderHistory'] });
      alert.warning(
        'Essa lista teve sua compra iniciada, não é possível enviá-la',
      );
    }
  };

  const handleCloseModal = async () => {
    await refetchShoppingList();
    setOpenModal(false);
    setIsSharingFinished(false);
    await queryClient.invalidateQueries({ queryKey: ['orderHistory'] });
  };

  const shareOrderUrl = () => {
    setOrderDeliveryDay(orderInformation?.maximumDeliveryDate || businessDays);
  };

  const shareShoppingList = async (e) => {
    e.preventDefault();
    setIsSharing(true);

    const payload = {
      ilpiId,
      deliveryDay: orderDeliveryDay,
      contacts: responsibleId,
    };

    try {
      await shoppingList.sendList({ listId: orderInformation.id }, payload);
      alert.success('Lista compartilhada com sucesso!');
      setIsSharingFinished(true);
    } catch (error) {
      alert.error('Erro ao compartilhar lista');
    } finally {
      setIsSharing(false);
    }
  };

  const shareMessage = () => {
    if (isSharing) return 'Compartilhando';
    if (isSharingFinished) return 'Compartilhado';
    return 'Compartilhar';
  };

  const getReferenceContactList = async () => {
    try {
      const { message } = await resident.getResidentContact({
        residentId: residentInformation.id,
      });
      return message;
    } catch (error) {
      return error;
    }
  };

  const { data: referenceList = [] } = useQuery({
    queryKey: ['referenceContactListForShare'],
    queryFn: getReferenceContactList,
    refetchOnWindowFocus: false,
    enabled: Boolean(residentInformation.id) && openModal,
  });

  const sortedReferenceListByName = referenceList.sort((a, b) => a.name.localeCompare(b.name));

  const handleFocus = () => {
    if (datePickerRef.current) {
      datePickerRef.current.querySelector('button').click();
    }
  };

  useEffect(() => {
    const newResponsibleId = sortedReferenceListByName.map(({ id }) => id);
    if (openModal) {
      setResponsibleId(newResponsibleId);
    }
  }, [sortedReferenceListByName, openModal]);

  return (
    <Box>
      <BenvoDialog.Trigger onClick={handleOpenModal}>
        {children}
      </BenvoDialog.Trigger>
      <BenvoDialog.Root onClose={handleCloseModal} open={openModal}>
        <StyledForm onSubmit={shareShoppingList}>
          <BenvoDialog.Content>
            <img
              src={SendIndividualShoppingListImage}
              alt="Send Individual Shopping List"
            />
            <TextAndActionsContainer>
              <TextContainer>
                <Typography variant="h5">Lista de compras para</Typography>
                <Typography variant="h6">
                  {residentInformation?.name}
                </Typography>
              </TextContainer>
              <Collapse in={sortedReferenceListByName.length > 0}>
                <ActionContainer>
                  <Typography variant="body1">
                    Dia limite para entrega no residencial
                  </Typography>
                  <StyledDesktopDatePicker
                    ref={datePickerRef}
                    value={orderDeliveryDay}
                    onChange={handleDayChange}
                    inputFormat="DD/MM/yyyy"
                    components={{ OpenPickerIcon: CalendarIcon }}
                    minDate={moment()}
                    shouldDisableDate={(date) => moment(date).day() === 0}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        onClick={handleFocus}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Selecione uma data',
                        }}
                      />
                    )}
                  />
                  {
                    !collapseUrl() && (
                      <Typography variant="h6">
                        <InfoCircle />
                        Compartilhe para que essa data seja salva
                      </Typography>
                    )
                  }
                  <StyledCollapse in={orderDeliveryDay}>
                    <ResponsibleListContainer>
                      {sortedReferenceListByName.map(
                        ({
                          name, email, phone, id,
                        }) => (
                          <label htmlFor={id} key={id}>
                            <div>
                              <p>{name}</p>
                              <div>
                                <p>{phoneWithCountryCodeMaskAndSpace(phone)}</p>
                                {email && <p>{`, ${email}`}</p>}
                              </div>
                            </div>
                            <StyledCheckBox
                              id={id}
                              checked={responsibleId?.includes(id)}
                              onChange={() => handleResponsibleContactChange(id)}
                            />
                          </label>
                        ),
                      )}
                    </ResponsibleListContainer>
                  </StyledCollapse>
                </ActionContainer>
              </Collapse>
              <Collapse in={sortedReferenceListByName.length === 0}>
                <EmptyReferenceList>
                  <p>
                    Nenhum contato de referencia cadastrado.
                    {' '}
                    <a
                      href={`${PATHS.residents.root}?residentId=${residentInformation.id}`}
                    >
                      Clique aqui
                    </a>
                    {' '}
                    para adicionar um contato para compartilhar a lista.
                  </p>
                </EmptyReferenceList>
              </Collapse>
            </TextAndActionsContainer>
          </BenvoDialog.Content>
          {!orderDeliveryDay && (
            <BenvoDialog.Actions>
              <StyledBenvoButton onClick={shareOrderUrl}>
                Compartilhar pedido
              </StyledBenvoButton>
            </BenvoDialog.Actions>
          )}
          <StyledCollapse
            in={orderDeliveryDay && sortedReferenceListByName.length > 0}
          >
            <BenvoDialog.Actions>
              <StyledBenvoButton
                type="submit"
                disabled={
                  isSharing
                  || isSharingFinished
                  || !responsibleId?.length
                  || orderDeliveryDay._isValid === false
                }
              >
                {isSharing ? (
                  <CircularProgress size={18} color="inherit" />
                ) : (
                  <SendWhite />
                )}
                {shareMessage()}
              </StyledBenvoButton>
            </BenvoDialog.Actions>
            {orderInformation?.orderUrl && (
              <Collapse in={collapseUrl()}>
                <SharebleLink orderInformation={orderInformation} />
              </Collapse>
            )}
          </StyledCollapse>
        </StyledForm>
      </BenvoDialog.Root>
    </Box>
  );
}
