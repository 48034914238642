import { IconButton, styled } from '@mui/material';

export const ProductItemContainer = styled('label')`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  p {
    color: #474750;

    font-size: 12px;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
    letter-spacing: 0.12px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;
