import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { Pagination } from '@mui/material';
import moment from 'moment';
import { useAlert } from '../../context/alertContext';
import ContainerPage from '../../templates/containerPage/ContainerPage';
import { getTokenInfo } from '../../utils/configAuth';
import Loading from '../../components/Loading/Loading';
import CreateList from './components/Form/CreateList';
import List from './components/List';
import Filter from './components/Filter';
import routes from '../../services/routes';

function ShoppingList() {
  const { shoppingList } = routes;
  const alert = useAlert();
  const [showForm, setShowForm] = useState(false);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const ilpiId = getTokenInfo('ilpiId');
  const [searchParams, setSearchParams] = useState({
    beginDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    page: 0,
  });

  const searchLists = async () => {
    setIsLoading(true);

    try {
      const { message } = await shoppingList.findShoppingListByIlpiId(
        { ilpiId },
        searchParams,
      );
      const { rows, ...thisPagination } = message;

      setList(rows || []);
      setPagination(thisPagination || {});
    } catch (error) {
      if (error.response?.status === 404) {
        alert.info('Nenhuma lista encontrada');
        setList([]);
      } else {
        alert.error('Não foi possível obter as listas');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePagination = (page) => {
    const params = {
      ...searchParams,
      page: page - 1,
    };

    setSearchParams(params);
  };

  const handleSuccess = (message) => {
    alert.success(message);
    searchLists();
  };

  const handleFailure = (message) => {
    alert.error(message);
  };

  const handleFilterChange = (params) => {
    setSearchParams((prev) => ({ ...prev, ...params, page: 0 }));
  };

  useEffect(() => {
    searchLists();
  }, [searchParams]);

  return (
    <ContainerPage
      title="Lista mensal"
      description="Aqui você pode visualizar a demanda mensal de remédios e demais itens."
    >
      {showForm && (
        <CreateList
          open={showForm}
          onClose={() => setShowForm(false)}
          onSuccess={handleSuccess}
          onFailure={handleFailure}
        />
      )}

      <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12} md={12}>
          <Filter
            onChange={handleFilterChange}
            onFailure={handleFailure}
            setShowForm={setShowForm}
          />
        </Grid>
      </Grid>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <List
            list={list}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
          />

          <Pagination
            count={pagination.totalPages}
            onChange={(_event, value) => handlePagination(value)}
            page={searchParams.page + 1}
            color="primary"
            sx={{
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
    </ContainerPage>
  );
}

export default ShoppingList;
