import React, { useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import moment from 'moment';
import {
  ButtonsContainer, FieldsContainer, PageContainer, PageTitle, StyledDateInput, StyledSelect,
} from './style';
import CalendarIcon from '../../../../../../assets/CalendarIcon';
import { timeIntervals } from '../../../../../../constants';
import BenvoButton from '../../../../../../components/BenvoButton/style';

export default function SchedulingStep({
  setHealthServiceOrder,
  healthServiceOrder,
  setActiveStep,
  createHealthServiceOrder,
  buttonIsEnabledToCreateOrder,
  creatingHealthServiceOrder,
}) {
  const [dateError, setDateError] = useState(false);

  const handleDateChange = (date) => {
    if (date && date._isValid) {
      setDateError(null);
      const formatedDate = moment(date, 'DD/MM/YYYY');

      if (formatedDate.isBefore(moment(), 'day') || formatedDate.isSame(moment(), 'day')) {
        setDateError('Selecione uma data posterior à hoje');
      }

      setHealthServiceOrder((prevState) => ({
        ...prevState,
        date: date._d,
      }));
    } else {
      setDateError('Data inválida');
    }
  };

  // const handleDate = (date) => {
  //   const { error } = verifyDate(date);

  //   if (error) setDateError(error);
  //   else setDateError(null);
  // };

  const handleSelectDayShift = ({ target: { value } }) => {
    if (value) {
      setHealthServiceOrder((prevState) => ({
        ...prevState,
        dayShift: value,
      }));
    } else {
      setHealthServiceOrder((prevState) => ({
        ...prevState,
        dayShift: '',
      }));
    }
  };

  return (
    <PageContainer>
      <PageTitle>Indique sua preferência, informaremos caso o horário não esteja disponível</PageTitle>
      <FieldsContainer>
        <StyledDateInput
          value={healthServiceOrder.date || null}
          onChange={handleDateChange}
          minDate={moment().add(1, 'days')}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              error={!!dateError}
              helperText={dateError}
              sx={{
                '& .MuiFormHelperText-root': {
                  backgroundColor: '#FFF',
                  m: 0,
                },
              }}
            />
          )}
        />

        <StyledSelect fullWidth displayEmpty value={healthServiceOrder.dayShift || ''} onChange={handleSelectDayShift}>
          <MenuItem
            sx={{
              color: '#BDBDBD',
            }}
            value=""
          >
            Selecione um horário
          </MenuItem>
          {timeIntervals.map((shift) => (
            <MenuItem key={shift.value} value={shift.value}>
              {shift.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FieldsContainer>
      <ButtonsContainer>
        <BenvoButton horizontalPadding="36" variant="secondary" onClick={() => setActiveStep(2)}>
          Voltar
        </BenvoButton>
        <BenvoButton
          disabled={!buttonIsEnabledToCreateOrder() || creatingHealthServiceOrder}
          horizontalPadding="25"
          variant="primary"
          onClick={() => createHealthServiceOrder()}
        >
          Fazer pedido
        </BenvoButton>
      </ButtonsContainer>
    </PageContainer>
  );
}
