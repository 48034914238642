/* eslint-disable import/prefer-default-export */
import { AppBar, Box, styled } from '@mui/material';

export const StyledAppBar = styled(AppBar)`
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 24px 0px rgb(0 0 0 / 7%),
    0px 1px 10px 0px rgb(0 0 0 / 0%);

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ImageContainer = styled(Box)`
  padding: 37px 19px 33.4px 20px;

  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;
