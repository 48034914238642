import {
  Dialog, DialogContent, DialogTitle, styled,
} from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: none;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 0;

  color: #474750;

  font-size: 16px;
  font-weight: 500;
  line-height: 115%; /* 18.4px */
  letter-spacing: 0.024px;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(BenvoButton)`
  padding: 16px 0;
  width: 118px;
`;
