/* eslint-disable import/prefer-default-export */
const { types } = require('../../../utils/mapTaskType');

const currentTypes = Object.values(types).map(({ name, originName }) => ({
  name,
  originName,
}));

export const createTypeOptions = () => currentTypes.map(({ name, originName }) => ({
  value: originName,
  label: name,
}));
