/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import {
  CleanHands,
  Extension,
  FitnessCenter,
  Restaurant,
  Sanitizer,
  Spa,
  Vaccines,
} from '@mui/icons-material';

const size = 'small';
const iconColor = 'primary';

export const types = {
  cleaning: {
    name: 'Limpeza',
    originName: 'cleaning',
    color: '#00AFAA',
    icon: <Sanitizer fontSize={size} color={iconColor} />,
  },
  meals: {
    name: 'Alimentação',
    originName: 'meals',
    color: '#ED3237',
    icon: <Restaurant fontSize={size} color={iconColor} />,
  },
  physicalActivity: {
    name: 'Atividade física',
    originName: 'physicalActivity',
    color: '#FFC10D',
    icon: <FitnessCenter fontSize={size} color={iconColor} />,
  },
  evolution: {
    name: 'Evolução',
    originName: 'evolution',
    color: 'purple',
    icon: <Spa fontSize={size} color={iconColor} />,
  },
  playing: {
    name: 'Lazer/Jogos',
    originName: 'playing',
    color: '#C61E83',
    icon: <Extension fontSize={size} color={iconColor} />,
  },
  medications: {
    name: 'Medicamentos',
    originName: 'medications',
    color: '#0A305A',
    icon: <Vaccines fontSize={size} color={iconColor} />,
  },
  hygiene: {
    name: 'Higiene',
    originName: 'hygiene',
    color: '#0A305A',
    icon: <CleanHands fontSize={size} color={iconColor} />,
  },
};

export const taskStatus = {
  unstarted: 'Não iniciada',
  completed: 'Completada',
  cancelled: 'Cancelada',
};

export const mapTaskType = (type) => types[type] || 'Outros';

export const mapTaskStatus = (statusArg) => taskStatus[statusArg] || 'Outros';
