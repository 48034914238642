/* eslint-disable max-len */
import React from 'react';

export default function EyeIconOpen({
  width = '24', height = '24', color = '#fff', viewBox = '0 0 24 24', style = {},
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <g id="Vector">
        <path d="M10.331 1H9.66905C5.59998 1 2.05308 3.67215 1.06619 7.48119C0.977936 7.82182 0.977936 8.17818 1.06619 8.51881C2.05308 12.3278 5.59998 15 9.66904 15H10.331C14.4 15 17.9469 12.3278 18.9338 8.51881C19.0221 8.17818 19.0221 7.82182 18.9338 7.48119C17.9469 3.67215 14.4 1 10.331 1Z" stroke={color} strokeWidth="2" strokeLinejoin="round" />
        <path d="M10 11.0951C11.7715 11.0951 13.2076 9.70936 13.2076 8C13.2076 6.29064 11.7715 4.90492 10 4.90492C8.22847 4.90492 6.79236 6.29064 6.79236 8C6.79236 9.70936 8.22847 11.0951 10 11.0951Z" stroke={color} strokeWidth="2" strokeLinejoin="round" />
      </g>
    </svg>
  );
}
