import { Box, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatCurrencyBR } from '../../../../../utils/currencyFormat';
import { useAlert } from '../../../../../context/alertContext';
import routes from '../../../../../services/routes';
import { status } from './cardHelper';
import CardSelector from './components/CardSelector';
import Payed from './components/Payed';
import AddCard from './components/AddCard';
import Payment from './components/Payment';
import Loading from '../../../../../components/Loading/Loading';
import {
  HeadBox, Separator, SubtitleBox, SubtitleSecondAlertTypography, SubtitleSecondTypography, SubtitleTypography,
  TitleBox, TitleTypography,
} from './style';

export default function Frubana({
  isOpen,
  onClose,
  handlePlaceAllOrders,
  total,
  processedCart,
  frubanaQuotationTotal,
}) {
  const alert = useAlert();
  const [creditCards, setCreditCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [step, setStep] = useState(status.SELECTING_STATUS);
  const [loading, setLoading] = useState(true);

  const handleClosing = () => {
    onClose();
    setSelectedCard(null);
    setStep(status.SELECTING);
  };

  const getCreditCards = async () => {
    setLoading(true);
    try {
      const { message } = await routes.frubana.listCreditCards();
      setCreditCards(message);
    } catch (error) {
      setCreditCards([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  const handleCvv = (event) => {
    const card = selectedCard;
    card.cvv = event.target.value.length < 3 ? event.target.value : event.target.value.substr(0, 3);
    setSelectedCard(card);
  };

  const handleSteps = (num) => {
    setStep(num);
  };

  const returnToHandler = () => {
    setSelectedCard(null);
    setStep(status.SELECTING);
  };

  const handlePlaceAllOrdersFrubana = async () => {
    if (!selectedCard) {
      alert.error('Selecione um cartão de crédito');
      return;
    }

    if (!selectedCard.cvv || selectedCard.cvv?.length < 3) {
      alert.error('Preencha um CVV válido');
      return;
    }

    setLoading(true);
    try {
      await routes.frubana.checkoutCart({
        cardToken: selectedCard.token,
        cvv: selectedCard.cvv,
      });
      handlePlaceAllOrders(true);
      setSelectedCard(null);
      setStep(status.PAYED);
      alert.success('Ordem de pagamento enviada!');
    } catch (error) {
      alert.error('Erro ao realizar pagamento, tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  const deleteCard = async ({ token }) => {
    setLoading(true);
    try {
      await routes.frubana.removeCreditCard(token);
      setSelectedCard(null);
      await getCreditCards();
    } catch (error) {
      alert.error('Erro ao remover o cartão, tente novamente');
    } finally {
      setLoading(false);
    }
  };

  const handleDiscount = () => {
    const { notAddedToCart } = processedCart;

    if (!notAddedToCart?.length && (frubanaQuotationTotal > total)) {
      const discountAmount = frubanaQuotationTotal - total;
      return (
        <SubtitleSecondTypography>
          {`Uau! Aqui na Benvo conseguimos desconto único de R$ ${formatCurrencyBR(discountAmount)}.`}
        </SubtitleSecondTypography>
      );
    }
    if (!notAddedToCart?.length && (frubanaQuotationTotal < total)) {
      const adjustmentAmount = total - frubanaQuotationTotal;
      return (
        <SubtitleSecondAlertTypography>
          {`Foi realizado um pequeno ajuste de R$ ${formatCurrencyBR(adjustmentAmount)}. na cotação`}
        </SubtitleSecondAlertTypography>
      );
    }

    if (notAddedToCart?.length) {
      const uncalculatedProductsPrice = notAddedToCart.reduce((acc, curr) => {
        const { price, quantity } = curr;
        return acc + (price * quantity);
      }, 0);

      if ((frubanaQuotationTotal - uncalculatedProductsPrice) > total) {
        const totalDiscount = frubanaQuotationTotal - (uncalculatedProductsPrice + total);
        return (
          <SubtitleSecondTypography>
            {`Uau! Aqui na Benvo conseguimos desconto único de R$ ${formatCurrencyBR(totalDiscount)}.`}
          </SubtitleSecondTypography>
        );
      }

      return (
        <SubtitleSecondAlertTypography>
          Alguns produtos não foram encontrados na Frubana, mas
          você pode adicioná-los ao carrinho e pagar separadamente.
        </SubtitleSecondAlertTypography>
      );
    }
    return null;
  };

  const stepperController = () => {
    if (step === status.REGISTERING) {
      return (
        <AddCard
          setLoading={setLoading}
          returnToHandler={returnToHandler}
          setStep={setStep}
          refetch={getCreditCards}
        />
      );
    }
    if (step === status.PAYING) {
      return (
        <Payment
          selectedCard={selectedCard}
          handleCvv={handleCvv}
          handlePlaceAllOrdersFrubana={handlePlaceAllOrdersFrubana}
          processedCart={processedCart}
        />
      );
    }
    if (step === status.PAYED) {
      return (
        <Payed
          onClose={handleClosing}
        />
      );
    }
    return (
      <CardSelector
        creditCards={creditCards}
        selectedCard={selectedCard}
        selectCard={setSelectedCard}
        deleteCard={deleteCard}
        handleSteps={handleSteps}
        onClose={handleClosing}
      />
    );
  };

  return (
    <Dialog
      data-testid="frubanaDialog"
      open={isOpen}
      onClose={onClose}
      maxWidth="xs"
    >

      <HeadBox>
        {step !== status.PAYED && (
          <>
            <TitleBox>
              <TitleTypography>Carrinho Frubana</TitleTypography>
              <SubtitleBox>
                {handleDiscount()}
              </SubtitleBox>
              <SubtitleBox>
                <SubtitleTypography>
                  Valor total
                </SubtitleTypography>
                <SubtitleTypography>
                  {`R$ ${formatCurrencyBR(total)}`}
                </SubtitleTypography>
              </SubtitleBox>
            </TitleBox>
            <Separator />
          </>
        )}
        {(loading) ? <Loading sx={{ color: 'primary' }} /> : (
          <Box>
            {stepperController(step)}
          </Box>
        )}
      </HeadBox>
    </Dialog>
  );
}
