import { Done } from '@mui/icons-material';
import { Button, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import routes from '../../../../services/routes';

export default function MarkAsCompletedButton({ listId, onSuccess, onFailure }) {
  const [isLoading, setIsLoading] = useState(false);

  const markAsCompleted = async () => {
    try {
      setIsLoading(true);
      await routes.shoppingList.updateShoppingList(
        {
          shoppingListId: listId,
        },
        { status: 'done' },
      );

      onSuccess('Lista marcada como concluída com sucesso!');
    } catch {
      onFailure('Não foi possível marcar a lista como concluída');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={markAsCompleted}
      padding="10px"
      sx={{ py: '2px' }}
    >
      {isLoading ? <CircularProgress size="17px" /> : <Done sx={{ width: '18px' }} />}
      <Typography component="label" style={{ marginLeft: '7px' }}>Marcar como concluída</Typography>
    </Button>
  );
}
