/* eslint-disable max-len */
import React from 'react';

export default function MedicalIcon({ width = 24, height = 25, color = '#474750' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="medical">
        <path id="Vector" d="M12 23.1565C6.07 23.1565 1.25 18.3365 1.25 12.4065C1.25 6.47649 6.07 1.65649 12 1.65649C17.93 1.65649 22.75 6.47649 22.75 12.4065C22.75 18.3365 17.93 23.1565 12 23.1565ZM12 3.15649C6.9 3.15649 2.75 7.30649 2.75 12.4065C2.75 17.5065 6.9 21.6565 12 21.6565C17.1 21.6565 21.25 17.5065 21.25 12.4065C21.25 7.30649 17.1 3.15649 12 3.15649Z" fill={color} />
        <path id="Vector 15" d="M9.8 10.4065H7.2C7.08954 10.4065 7 10.496 7 10.6065V14.2065C7 14.317 7.08954 14.4065 7.2 14.4065H9.8C9.91046 14.4065 10 14.496 10 14.6065V17.2065C10 17.317 10.0895 17.4065 10.2 17.4065H13.8C13.9105 17.4065 14 17.317 14 17.2065V14.6065C14 14.496 14.0895 14.4065 14.2 14.4065H16.8C16.9105 14.4065 17 14.317 17 14.2065V10.6065C17 10.496 16.9105 10.4065 16.8 10.4065H14.2C14.0895 10.4065 14 10.317 14 10.2065V7.60649C14 7.49604 13.9105 7.40649 13.8 7.40649H10.2C10.0895 7.40649 10 7.49604 10 7.60649V10.2065C10 10.317 9.91046 10.4065 9.8 10.4065Z" stroke={color} strokeWidth="1.5" strokeLinejoin="round" />
      </g>
    </svg>
  );
}
