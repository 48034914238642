import React from 'react';
import { Collapse } from '@mui/material';
import moment from 'moment';
import {
  CategoryTitle,
  Container,
  ListsContainer,
  MaximumDeliveryDateTitle,
  ResidentShoppingListContainer,
  ShoppingListContainer,
  ShoppingListTitle,
  ShoppingListTitleContainer,
  SubcategoryContainer,
  ResidentShoppingListsContainer,
  TableTitle,
  TableTitleContainer,
} from './style';
import ResidentShoppingListItem from './components/ResidentShoppingListItem';

export default React.forwardRef(({
  selectedProductsList,
  setSelectedProductsList,
  isVisualization,
  listProducts,
}, ref) => {
  selectedProductsList.sort((a, b) => a.name.localeCompare(b.name));

  const unpurchasedItems = selectedProductsList.filter(
    (product) => product.purchasedItem === false,
  );

  const purchasedItems = selectedProductsList.filter(
    (product) => product.purchasedItem,
  );

  const { foundItems, notFoundItems } = selectedProductsList.reduce((acc, curr) => {
    if (curr.found === false) acc.notFoundItems.push(curr);
    else acc.foundItems.push(curr);

    return acc;
  }, {
    foundItems: [],
    notFoundItems: [],
  });
  const showMaximumDeliveryDate = listProducts?.maximumDeliveryDate;

  return (
    <ListsContainer>
      {showMaximumDeliveryDate
        && (
          <MaximumDeliveryDateTitle variant="h6">
            <span>Data limite de entrega: </span>
            {moment(listProducts.maximumDeliveryDate).format('DD [de] MMM')}
          </MaximumDeliveryDateTitle>
        )}
      <Collapse in={selectedProductsList.length > 0
        && (!isVisualization || (unpurchasedItems.length === 0 && purchasedItems.length === 0))}
      >
        <TableTitleContainer>
          <TableTitle>
            Item
          </TableTitle>
          <div>
            <TableTitle>
              Comp. Mensal
            </TableTitle>
            <TableTitle>
              Quantidade
            </TableTitle>
          </div>
        </TableTitleContainer>
      </Collapse>
      {!isVisualization || (unpurchasedItems.length === 0 && purchasedItems.length === 0) ? (
        <ResidentShoppingListsContainer hasDeliveryDate={showMaximumDeliveryDate}>
          <ResidentShoppingListContainer>
            {foundItems.map((product) => (
              <ResidentShoppingListItem
                key={product.externalId}
                product={product}
                setSelectedProductsList={setSelectedProductsList}
                isVisualization={isVisualization}
              />
            ))}
          </ResidentShoppingListContainer>

          <SubcategoryContainer>
            {notFoundItems.length > 0 && (
              <CategoryTitle>
                {`Descontinuados (${notFoundItems.length})`}
              </CategoryTitle>
            )}

            <ResidentShoppingListContainer ref={ref}>
              {notFoundItems.map((product) => (
                <ResidentShoppingListItem
                  key={product.externalId}
                  product={product}
                  setSelectedProductsList={setSelectedProductsList}
                  isVisualization={isVisualization}
                />
              ))}
            </ResidentShoppingListContainer>
          </SubcategoryContainer>
        </ResidentShoppingListsContainer>
      ) : (
        <ShoppingListContainer>
          <Container display={purchasedItems.length > 0}>
            <ShoppingListTitleContainer>
              <ShoppingListTitle>
                Itens comprados pela Benvo
              </ShoppingListTitle>
              <div>
                <ShoppingListTitle>
                  Comp. Mensal
                </ShoppingListTitle>
                <ShoppingListTitle>
                  Quantidade
                </ShoppingListTitle>
              </div>
            </ShoppingListTitleContainer>
            <ResidentShoppingListContainer>
              {purchasedItems.map((product) => (
                <ResidentShoppingListItem
                  key={product.externalId}
                  product={product}
                  setSelectedProductsList={setSelectedProductsList}
                  isVisualization={isVisualization}
                />
              ))}
            </ResidentShoppingListContainer>
          </Container>
          <Container display={unpurchasedItems.length > 0}>
            <ShoppingListTitleContainer>
              <ShoppingListTitle>
                Itens não comprados pela Benvo
              </ShoppingListTitle>
              <div>
                <ShoppingListTitle>
                  Comp. Mensal
                </ShoppingListTitle>
                <ShoppingListTitle>
                  Quantidade
                </ShoppingListTitle>
              </div>
            </ShoppingListTitleContainer>
            <ResidentShoppingListContainer>
              {unpurchasedItems.map((product) => (
                <ResidentShoppingListItem
                  key={product.externalId}
                  product={product}
                  setSelectedProductsList={setSelectedProductsList}
                  isVisualization={isVisualization}
                />
              ))}
            </ResidentShoppingListContainer>
          </Container>
        </ShoppingListContainer>
      )}
    </ListsContainer>
  );
});
