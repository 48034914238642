import React from 'react';
import { SearchResultContainer } from './style';
import ProductSearchResultItem from './components/ProductSearchResultItem';

export default function ProductSearchResultContainer({ productList, setSelectedProductsList, selectedProductsList }) {
  return (
    <SearchResultContainer>
      {productList?.map((product) => (
        <ProductSearchResultItem
          key={product.id}
          product={product}
          setSelectedProductsList={setSelectedProductsList}
          selectedProductsList={selectedProductsList}
        />
      ))}
    </SearchResultContainer>
  );
}
