import {
  Box, Select, TextField, Typography, styled,
} from '@mui/material';

export const StyledTextField = styled(TextField)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    font-size: 12px;
    & fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &:hover fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &.Mui-focused fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }
    &.Mui-disabled fieldset {
      border: 1px solid transparent;
      ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
    }

    &.Mui-disabled input {
      color: var(--grey-variation-light-grey, #474750);
      -webkit-text-fill-color: var(--grey-variation-light-grey, #474750);
    }

    &.MuiInputBase-root {
      font-size: 12px;
      input {
        padding: 0px !important;

        &::placeholder {
          color: var(--grey-variation-light-grey, #474750);
        }
      }
    }
  }

  p {
    transition: all 0.2s ease-in-out;
    background: #fff !important;
    margin: 0px !important;
  }
`;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  height: 40px;
  border: none;
  font-size: 12px;

  & fieldset {
    border: none;
    ${({ error }) => error && 'border: 1px solid var(--paleta-senior-red, #FF0000);'}
  }

  .MuiSelect-select {
    ${({ value }) => value === '' && 'color: var(--grey-variation-light-grey, #47475070)'}
  }
`;

export const SelectOptionText = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  /* Label */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const InsuredContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  cursor: pointer;
`;

export const SelectTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  /* Label */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  letter-spacing: 0.12px;
`;

export const OptionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const IsInsuredContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 44px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  transition: all 0.3s ease-in-out;
  ${({ isOpen }) => isOpen && {
    height: '281px',
  }}
`;

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  height: 100%;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
  gap: 8px;
`;

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h5 {
    color: #474750;

    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.014px;
    margin: 0;
  }
`;
