import {
  Box, IconButton, styled, Typography,
} from '@mui/material';

export const Container = styled(Box)`
  display: grid;
  grid-template-columns: minmax(auto, 729px) minmax(50%, 1fr);
  height: 100vh;

  @media screen and (max-width: 770px) {
    grid-template-columns: 1fr;
  }
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f7;

  padding: 34px 46px;

  @media screen and (max-width: 770px) {
    padding: 34px 28px;
  }
`;

export const ButtonAndLogoContainer = styled(Box)`
  display: none;

  @media screen and (max-width: 770px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const StyledIconButton = styled(IconButton)`
  &:hover {
    background-color: transparent;
  }
`;

export const ImageContainer = styled(Box)`
  background: url(${(props) => props['data-image']}) no-repeat center top;
  background-size: cover;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 9px;
    background: linear-gradient(
      rgba(43, 29, 70, 1) 0%,
      rgba(150, 40, 83, 1) 42%,
      rgba(229, 144, 79, 1) 100%
    );
  }

  @media screen and (max-width: 770px) {
    display: none;
  }
`;

export const CardContainer = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 770px) {
    align-items: flex-start;
    width: 100%;
  }
`;

export const Card = styled(Box)`
  padding: 42px;
  background: #fff;
  border-radius: 6px;

  @media screen and (max-width: 770px) {
    width: 100%;
    max-width: 540px;
    background: transparent;
    padding: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 48px;
  }
`;

export const ApplicationVersion = styled(Typography)`
  color: #474750;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%; /* 14.6px */
  letter-spacing: 0.025px;

  width: 100%;
  text-align: start;
`;
