import React from 'react';
import {
  Link, Typography,
} from '@mui/material';
import { StyledCheckBoxLabel } from '../style';

function TermsCheckboxLabel({ href, text }) {
  let accept;
  if (text.includes('Termos')) {
    accept = 'Aceito os';
  } else {
    accept = 'Aceito as';
  }
  return (
    <StyledCheckBoxLabel>
      <Typography>{accept}</Typography>
      <Link href={href} target="_blank" rel="noreferrer">{text}</Link>
    </StyledCheckBoxLabel>
  );
}

export default TermsCheckboxLabel;
