import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { AddCircleOutline } from '@mui/icons-material';
import moment from 'moment';
import routes from '../../../../services/routes';
import {
  cnpjInputMask,
  daysMask,
  moneyMask,
  percentMask,
  removeMask,
} from '../../../../utils/inputMasks';
import { useAlert } from '../../../../context/alertContext';
import schemas from '../../../../schemas';
import CityCoverageForm from './CityCoverageForm';
import StateCoverageForm from './StateCoverageForm';
import GeneralInfoForm from './GeneralInfoForm';

const steps = [
  'Informações Gerais',
  'Cobertura Estadual',
  'Cobertura Municipal',
];

const defaultValues = {
  name: '',
  email: '',
  cnpj: '',
  takeRate: '',
  estimatedDeliveryTime: '',
  deliveryOrderTimeLimit: moment().format('HH:00'),
  minimumOrder: 0,
};

export default function CreateOrEditSeller({
  setToggleCreated,
  editModalInfo,
  setEditModalInfo,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [areaCoverage, setAreaCoverage] = useState({
    states: [],
    cities: [],
  });
  const [takeRate, setTakeRate] = useState('');
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState('');
  const [minimumOrder, setMinimumOrder] = useState('R$ 0,00');
  const [otherEmails, setOtherEmails] = useState([]);

  const firstStep = activeStep === 0;

  const alert = useAlert();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schemas.sellers.createSeller),
    defaultValues,
  });

  const handleModalClose = () => {
    reset();
    setAreaCoverage({
      states: [],
      cities: [],
    });
    setActiveStep(0);
    setOpen(false);
    setEditModalInfo({
      open: false,
      seller: null,
    });
    setTakeRate('');
    setEstimatedDeliveryTime('');
    setMinimumOrder('R$ 0,00');
    setOtherEmails([]);
  };

  const createSeller = async ({
    complement,
    cnpj,
    zipCode,
    minimumOrder: minOrder,
    ...data
  }) => {
    const payload = {
      ...data,
      minimumOrder: minOrder / 100,
      areaCoverage,
      cnpj: removeMask(cnpj),
      deliveryOrderTimeLimit: `${data.deliveryOrderTimeLimit}:00`,
      otherEmails,
    };

    try {
      await routes.seller.createSeller(payload);
      alert.success('Fornecedor criado com sucesso!');
      handleModalClose();
      setToggleCreated((prev) => !prev);
    } catch (error) {
      console.error(error);
      alert.error('Erro ao criar fornecedor!');
    }
  };

  const editSeller = async ({
    complement,
    cnpj,
    zipCode,
    minimumOrder: minOrder,
    ...data
  }) => {
    const payload = {
      ...data,
      minimumOrder: minOrder / 100,
      areaCoverage,
      cnpj: removeMask(cnpj),
      deliveryOrderTimeLimit: `${data.deliveryOrderTimeLimit}:00`,
      otherEmails,
    };

    try {
      await routes.seller.updateSeller(
        {
          sellerId: editModalInfo.seller.id,
        },
        payload,
      );
      alert.success('Fornecedor editado com sucesso!');
      handleModalClose();
      setToggleCreated((prev) => !prev);
    } catch (error) {
      console.error(error);
      alert.error('Erro ao editar fornecedor!');
    }
  };

  const handleNext = async (data) => {
    if (activeStep !== steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (editModalInfo.open) {
      await editSeller(data);
    } else {
      await createSeller(data);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const createSteps = () => {
    switch (activeStep) {
      case 1:
        return (
          <StateCoverageForm
            areaCoverage={areaCoverage}
            setAreaCoverage={setAreaCoverage}
          />
        );
      case 2:
        return (
          <CityCoverageForm
            areaCoverage={areaCoverage}
            setAreaCoverage={setAreaCoverage}
          />
        );
      default:
        return (
          <GeneralInfoForm
            otherEmails={otherEmails}
            setOtherEmails={setOtherEmails}
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
            takeRate={takeRate}
            setTakeRate={setTakeRate}
            estimatedDeliveryTime={estimatedDeliveryTime}
            setEstimatedDeliveryTime={setEstimatedDeliveryTime}
            minimumOrder={minimumOrder}
            setMinimumOrder={setMinimumOrder}
          />
        );
    }
  };

  useEffect(() => {
    if (!editModalInfo.open) {
      reset(defaultValues);
    } else {
      const {
        id, createdAt, updatedAt, deletedAt, ...rest
      } = editModalInfo.seller;

      setTakeRate(percentMask(String(rest.takeRate)));
      setEstimatedDeliveryTime(daysMask(String(rest.estimatedDeliveryTime)));
      setMinimumOrder(moneyMask(String(rest.minimumOrder * 100)));
      setOtherEmails(rest.otherEmails || []);

      delete rest.otherEmails;

      Object.entries(rest).forEach(([key, value]) => {
        switch (key) {
          case 'cnpj':
            return setValue(key, cnpjInputMask(value.toString()));
          case 'areaCoverage':
            return setAreaCoverage(value || { states: [], cities: [] });
          case 'paymentOptions':
            return setValue(key, value);
          case 'minimumOrder':
            return setValue(key, value * 100);
          case 'targetType':
            return setValue(key, value);
          case 'deliveryOrderTimeLimit': {
            const removedSeconds = value.slice(0, 5);
            return setValue(key, removedSeconds);
          }
          default:
            return setValue(key, value ? value.toString() : '');
        }
      });
    }
  }, [editModalInfo.open]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        onClick={() => setOpen(true)}
      >
        Fornecedor
      </Button>

      <Dialog
        open={open || editModalInfo.open}
        onClose={handleModalClose}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '950px',
            padding: 2,
          },
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <form onSubmit={handleSubmit(handleNext)}>
          <DialogContent>
            <Grid container spacing={2}>
              {createSteps()}
            </Grid>
          </DialogContent>
        </form>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: '16px',
          }}
        >
          {firstStep ? (
            <Button color="error" onClick={handleModalClose}>
              Fechar
            </Button>
          ) : (
            <Button color="inherit" onClick={handleBack}>
              Voltar
            </Button>
          )}

          <Box>
            <Button variant="contained" onClick={handleSubmit(handleNext)}>
              {activeStep === steps.length - 1 ? 'Enviar' : 'Próximo'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
