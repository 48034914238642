import { Box, styled } from '@mui/material';

export const ContainerInformationPerIlpi = styled(Box)`
  display: flex;
  width: 100%;
  height: 77px;
  padding: 10px 20px;
  margin: 12px 0;
  border-radius: 6px;
  border: 1px solid var(--grey-variation-lightest-grey, #f5f5f7);
  background: var(--black-and-white-white, #fff);
  justify-content: space-between;
  align-items: center;
`;

export const ContainerReferenceInformationAndActions = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  
  gap: 20px;
`;

export const BoxInformation = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  h4 {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.027px;
  }

  p {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
    text-transform: uppercase;
  }
`;

export const BoxActions = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 12px;

  a {
    text-decoration: none;
  }
`;

export const BoxReferenceInformation = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;

  h4 {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
    text-transform: uppercase;
  }

  p {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.04px;
  }
`;
