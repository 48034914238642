import React, { useRef, useState } from 'react';
import { Delete, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import ProductsTable from './ProductsTable';
import routes from '../../../../services/routes';
import { useAlert } from '../../../../context/alertContext';
import ConfirmationDialog from '../../../../components/confirmationDialog';

export default function ProductListTable({ sellerId }) {
  const [file, setFile] = useState(null);
  const [ProductsTableOpen, setProductsTableOpen] = useState(false);
  const [selectedListId, setSelectListId] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [sendingFile, setSendingFile] = useState(false);

  const alert = useAlert();

  const fileInputRef = useRef(null);

  const listProductsLists = async () => {
    try {
      const { message } = await routes.seller.listListsBySellerId({ sellerId });
      return message;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const {
    data: productsLists = [],
    refetch: getProductLists,
    isLoading,
  } = useQuery({
    queryKey: [sellerId],
    queryFn: listProductsLists,
    retry: 0,
    cacheTime: 0,
  });

  const deleteList = async (batch) => {
    try {
      await routes.seller.removeList({ batch });
      alert.success('Lista deletada com sucesso.');
      getProductLists();
    } catch (error) {
      console.error(error);
      alert.error('Erro ao deletar lista. Tente novamente mais tarde.');
    }
  };

  const handleFileChange = (event) => {
    const fileFromInput = event.target.files[0];

    if (!fileFromInput.name.endsWith('csv')) {
      alert.error('Por favor, envie um arquivo da extensão CSV.');
    } else {
      setFile(fileFromInput);
    }
  };

  const handleFileUpload = async () => {
    setSendingFile(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      await routes.seller.sendProductSellerCSV({ sellerId }, formData);
      alert.success('Arquivo enviado com sucesso.');
    } catch (error) {
      console.error(error);

      if (error.response.status === 422) {
        alert.error(error.response.data.message);
      } else {
        alert.error('Erro ao enviar arquivo. Tente novamente mais tarde.');
      }
    } finally {
      setSendingFile(false);
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Lote',
      flex: 1,
    },
    {
      field: 'totalProducts',
      headerName: 'Quantidade de Produtos',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Data de Envio',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: (params) => (
        <>
          <Tooltip title="Visualizar produtos da lista">
            <IconButton
              color="primary"
              onClick={() => {
                setSelectListId(params.row.id);
                setCreatedAt(params.row.createdAt);
                setProductsTableOpen(true);
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>

          <ConfirmationDialog
            title="Deletar Lista"
            message="Tem certeza que deseja deletar esta lista?"
            handleConfirm={() => deleteList(params.row.id)}
            testId="delete-list-button"
            iconButton={{
              icon: <Delete />,
              color: 'primary',
              tooltip: 'Deletar Lista',
            }}
          />
        </>
      ),
    },
  ];

  const rows = productsLists.map((list) => ({
    id: list.batch,
    totalProducts: list.totalProducts,
    createdAt: moment(list.createdAt).format('DD/MM/YYYY'),
  }));

  return (
    <Box>
      <Grid container spacing={2}>
        {!file?.name ? (
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Input
              inputRef={fileInputRef}
              type="file"
              sx={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'column nowrap',
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                selecione um arquivo
              </Button>
              <Typography
                variant="body2"
                sx={{ opacity: 0.8, alignSelf: 'flex-end', paddingTop: '3px' }}
              >
                * extensão deve ser .CSV
              </Typography>
            </Box>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant="body1">{file.name}</Typography>
            <IconButton
              color="secondary"
              onClick={() => {
                setFile(null);
              }}
            >
              <Delete />
            </IconButton>

            {sendingFile ? (
              <Button
                disabled="true"
                variant="contained"
                color="primary"
              >
                <CircularProgress
                  size={22}
                  color="inherit"
                  sx={{
                    marginRight: '5px',
                  }}
                />
                Enviando...
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleFileUpload}
              >
                Enviar
              </Button>
            )}
          </Grid>
        )}
      </Grid>

      <ProductsTable
        batch={selectedListId}
        open={ProductsTableOpen}
        setOpen={setProductsTableOpen}
        createdAt={createdAt}
      />

      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        localeText={{
          noRowsLabel: 'Nenhuma lista adicionada.',
        }}
        loading={isLoading}
        disableSelectionOnClick
        hideFooterPagination
        hideFooter
        autoHeight
        sx={{ mt: 2 }}
      />
    </Box>
  );
}
