import {
  Box, Typography, styled,
} from '@mui/material';

export const LayoutBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const TypographyStyled = styled(Typography)`
  margin-top: 1rem;
  color: var(--grey-variation-dark-grey, #474750);
  text-align: center;
`;

export const LoadingBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 375px;
`;
