import { Email } from '@mui/icons-material';
import { Button, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import ConfirmationInput from '../../../../components/ConfirmationInput';
import routes from '../../../../services/routes';

export default function SendListByEmailButton({
  listId,
  onSuccess,
  onFailure,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [sendListConfirmationIsOpen, setSendListConfirmationIsOpen] = useState(false);

  const handleSendList = async () => {
    setIsLoading(true);

    try {
      await routes.shoppingList.sendShoppingList({ shoppingListId: listId });
      onSuccess('Lista de compras enviada com sucesso');
    } catch (error) {
      onFailure('Não foi possível enviar lista de compras');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {sendListConfirmationIsOpen && (
        <ConfirmationInput
          title="Enviar lista de compras por e-mail"
          message={
            `Será enviada uma lista contendo os itens da classe Frutas, Legumes e Verduras 
            ao responsável pela compra. Essa ação não pode ser refeita, verifique se 
            a lista está completa antes de continuar.`
          }
          AcceptMessage="Enviar Lista"
          denialMessage="Verificar ou cancelar"
          isOpen={sendListConfirmationIsOpen}
          onClose={() => setSendListConfirmationIsOpen(false)}
          onAccept={handleSendList}
        />
      )}

      <Button
        color="primary"
        variant="outlined"
        onClick={() => setSendListConfirmationIsOpen(true)}
        sx={{ py: '2px' }}
      >
        {isLoading ? <CircularProgress size="17px" /> : <Email />}
        <Typography component="label" style={{ marginLeft: '7px' }}>
          Enviar ao responsável
        </Typography>
      </Button>
    </>
  );
}
