/* eslint-disable import/prefer-default-export */
import { Box, TextField, styled } from '@mui/material';
import BenvoButton from '../../components/BenvoButton/style';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 46px;

  h3 {
    color: #474750;
    margin: 0;

    font-size: 20px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: 0.03px;
    max-width: 290px;

    span {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 770px) {
    width: 100%;
    gap: 54px;
  }
`;

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px;

  @media screen and (max-width: 770px) {
    gap: 38px;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
`;

export const StyledTexField = styled(TextField)`
  & .MuiOutlinedInput-root {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: #f5f5f7;

    @media screen and (max-width: 770px) {
      background-color: #fff;
    }

    & fieldset {
      border: none;
    }

    & input {
      color: #474750;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      -webkit-box-shadow: 0 0 0 1000px #f5f5f7 inset;

      @media screen and (max-width: 770px) {
        -webkit-box-shadow: none;
      }
    }
  }
`;

export const StyledButton = styled(BenvoButton)`
  width: 138px;
  padding: 16px 0;

  @media screen and (max-width: 770px) {
    &:first-of-type {
      display: none;
    }
  }
`;
