import React, { useEffect, useState } from 'react';
import BenvoSwitch from '../../../../../components/BenvoSwitch/Index';
import routes from '../../../../../services/routes';
import { useAlert } from '../../../../../context/alertContext';
import {
  StyledBoxFeatureOptions,
  StyledBoxFeaturesContainer,
  StyledTypographyFeatureName,
} from './style';
import BenvoDropdown from '../../../../../components/BenvoDropdown';

const defaultFeatures = [{
  name: 'Marketplace',
  value: 'marketplace',
  checked: false,
},
{
  name: 'Serviços de Saúde',
  value: 'healthServices',
  checked: false,
}, {
  name: 'Lista de Compras',
  value: 'shoppingList',
  checked: false,
}];

export default function IlpiFeaturesForm({
  setValue,
  currentIlpi,
}) {
  const [featureOptions, setFeatureOptions] = useState(defaultFeatures);

  const alert = useAlert();

  const getIlpiFeatures = async () => {
    try {
      const { message } = await routes.ilpi.getIlpiFeatures({
        ilpiId: currentIlpi.id,
      });

      const newFeatureOptions = defaultFeatures.map((feature) => {
        if (message[feature.value]) {
          return {
            ...feature,
            checked: true,
          };
        }

        return feature;
      });

      setFeatureOptions(newFeatureOptions);

      const newFeatures = newFeatureOptions
        .filter((feature) => feature.checked)
        .map((feature) => feature.value);

      setValue('features', newFeatures);
    } catch (error) {
      alert.error('Erro ao buscar módulos da ILPI');
    }
  };

  const handleSwitch = (currentFeature) => {
    const newFeatures = featureOptions.map((thisFeature) => {
      if (thisFeature.value === currentFeature.value) {
        return {
          ...thisFeature,
          checked: !thisFeature.checked,
        };
      }

      return thisFeature;
    });

    setFeatureOptions(newFeatures);

    const newFeaturesValues = newFeatures
      .filter((thisFeature) => thisFeature.checked)
      .map((thisFeature) => thisFeature.value);

    setValue('features', newFeaturesValues);
  };

  useEffect(() => {
    if (currentIlpi) getIlpiFeatures();
  }, [currentIlpi]);

  return (
    <BenvoDropdown title="Módulos">
      <StyledBoxFeaturesContainer>
        {featureOptions.map((feature) => (
          <StyledBoxFeatureOptions>
            <BenvoSwitch
              checked={feature.checked}
              onClick={() => handleSwitch(feature)}
            />

            <StyledTypographyFeatureName>
              {feature.name}
            </StyledTypographyFeatureName>
          </StyledBoxFeatureOptions>
        ))}
      </StyledBoxFeaturesContainer>
    </BenvoDropdown>
  );
}
