import React from 'react';
import { Box, Toolbar } from '@mui/material';
import Breadcrumb from './components/Breadcrumb';
import Menu from './components/menu';
import BenvoLogo from '../../assets/topbar/topbar-logo.webp';
import {

  ImageContainer, StyledAppBar,
} from './style';
import BurgerMenu from './components/BurgerMenu';

function Topbar() {
  return (
    <>
      <ImageContainer>
        <img src={BenvoLogo} alt="Benvo Logo" />
        <BurgerMenu />
      </ImageContainer>
      <StyledAppBar position="static" color="default">
        <Toolbar
          sx={{
            padding: '16px 40px 16px 146px !important',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Box>
            <Breadcrumb />
          </Box>

          <Box>
            <Menu />
          </Box>
        </Toolbar>
      </StyledAppBar>
    </>
  );
}

export default Topbar;
