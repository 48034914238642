import React from 'react';
import { Box } from '@mui/system';
import { Divider, Typography } from '@mui/material';
import moment from 'moment';
import BenvoDrawer from '../../../components/BenvoDrawer';
import { formatCurrencyAndAddRealSymbol } from '../../../utils/currencyFormat';

export default function OrderInformation({ open, onClose, order }) {
  const showCurrentPrice = (product) => {
    const {
      price, promoPrice, promoMinimumQuantity, quantity,
    } = product;

    if (promoPrice && quantity >= promoMinimumQuantity) {
      return formatCurrencyAndAddRealSymbol(promoPrice);
    }
    return formatCurrencyAndAddRealSymbol(price);
  };

  return (
    <BenvoDrawer open={open} onClose={onClose} title="Dados do pedido">
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '12px',
          }}
        >
          <Typography variant="subtitle1" color="#474750" sx={{ fontSize: '14px' }}>
            {order.sellerName}
          </Typography>
          <Typography
            variant="subtitle1"
            color="#F5F5F7"
            sx={{
              fontSize: '10px',
              backgroundColor: '#96969E',
              padding: '5px 13px',
              borderRadius: '8px',
            }}
          >
            {`DATA DE ENTREGA ${moment(order.estimatedDelivery).format('DD/MM/YYYY')}`}
          </Typography>
        </Box>

        <Box sx={{ pb: '91px' }}>
          {order?.orderItems?.map((item) => (
            <Box
              key={item.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '#FEFEFE',
                padding: '12px',
                '&:nth-of-type(odd)': {
                  backgroundColor: '#F5F5F7',
                },
                '&:nth-child(1)': {
                  borderRadius: '8px 8px 0 0',
                },
                '&:last-child': {
                  borderRadius: '0 0 8px 8px',
                },
              }}
            >
              <Box sx={{ maxWidth: '70%' }}>
                <Typography
                  variant="p"
                  color="#474750"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '170%',
                    marginRight: '24px',
                    flex: '1',
                  }}
                >
                  {item.name}
                </Typography>

                <Typography
                  variant="p"
                  sx={{ fontSize: '12px' }}
                  color="#474750"
                >
                  {item.quantity}
                  und
                </Typography>
              </Box>

              <Typography
                variant="p"
                sx={{
                  fontSize: '10px',
                  color: '#474750',
                }}
              >
                {`Valor ${showCurrentPrice(item)}`}
              </Typography>
            </Box>
          ))}

          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
          >
            <Typography
              variant="subtitle1"
              color="#474750"
              sx={{
                fontSize: '16px',
                my: '8px',
              }}
            >
              Valor total
              <Typography
                variant="subtitle1"
                component="span"
                color="#474750"
                sx={{
                  fontSize: '24px',
                  fontWeight: '700',
                  ml: '4px',
                }}
              >
                {formatCurrencyAndAddRealSymbol(order.totalPrice)}
              </Typography>
            </Typography>
          </Box>

          <Divider sx={{ opacity: '1.25' }} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '582px',
          height: '91px',
          boxShadow: '0px -5px 4px rgba(0, 0, 0, 0.05)',
          position: 'fixed',
          bottom: '0',
          right: '0',
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '96px',
        }}
      >
        <Typography variant="subtitle1" color="#474750" sx={{ fontSize: '16px' }}>
          Valor total
          <Typography
            variant="subtitle1"
            component="span"
            color="#474750"
            sx={{
              fontSize: '24px',
              fontWeight: '700',
              mr: '54px',
              ml: '5px',
            }}
          >
            {formatCurrencyAndAddRealSymbol(order.totalPrice)}
          </Typography>
        </Typography>
      </Box>
    </BenvoDrawer>
  );
}
