import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Visibility, EditTwoTone, Delete } from '@mui/icons-material';
import { convertBRDate } from '../../../../utils/dateFormat';
import MTable from '../../../../components/MuiTable';
import ItemsListModal from '../ItemsListModal';
import EditList from '../Form/EditList';
import DeleteList from '../Form/DeleteList';

const STATUS_MAP = {
  requested: 'Requerido',
  sent: 'Enviado',
  done: 'Finalizado',
};

function List({ list, onSuccess, onFailure }) {
  const [editModalIsActive, setEditModalIsActive] = useState(false);
  const [listModalIsActive, setListModalIsActive] = useState(false);
  const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);
  const [selectedList, setSelectedList] = useState({});

  return (
    <>
      {editModalIsActive && (
        <EditList
          open={editModalIsActive}
          currentValues={selectedList}
          onClose={() => setEditModalIsActive(false)}
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
      )}

      {deleteModalIsActive && (
        <DeleteList
          isOpen={deleteModalIsActive}
          listId={selectedList.id}
          onClose={() => setDeleteModalIsActive(false)}
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
      )}

      <ItemsListModal
        isOpen={listModalIsActive}
        onClose={() => setListModalIsActive(false)}
        selectedList={selectedList}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />

      <MTable.Container sx={{ mt: 2, mb: 2 }}>
        <MTable.Root>
          <MTable.Head>
            <MTable.Row>
              <MTable.Cell>Data</MTable.Cell>
              <MTable.Cell>Comprador</MTable.Cell>
              <MTable.Cell>Status</MTable.Cell>
              <MTable.Cell>Ações</MTable.Cell>
            </MTable.Row>
          </MTable.Head>
          <MTable.Body data-testid="monthlyLists-body">
            {list.map((item) => (
              <MTable.Row>
                <MTable.Cell>{convertBRDate(item.date)}</MTable.Cell>
                <MTable.Cell>{item.residentId ? `${item.residentName}` : 'Ilpi'}</MTable.Cell>
                <MTable.Cell>{STATUS_MAP[item.status]}</MTable.Cell>
                <MTable.Cell>
                  <Tooltip title="Visualizar">
                    <IconButton
                      color="primary"
                      data-testid="view-list"
                      onClick={() => {
                        setSelectedList(item);
                        setListModalIsActive(!listModalIsActive);
                      }}
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Editar">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setSelectedList(item);
                        setEditModalIsActive(!editModalIsActive);
                      }}
                    >
                      <EditTwoTone />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Apagar">
                    <IconButton
                      color="primary"
                      data-testid="delete-list"
                      onClick={() => {
                        setSelectedList(item);
                        setDeleteModalIsActive(!deleteModalIsActive);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </MTable.Cell>
              </MTable.Row>
            ))}
          </MTable.Body>
        </MTable.Root>
      </MTable.Container>
    </>
  );
}

export default List;
