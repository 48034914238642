import { Button } from '@mui/material';
import React from 'react';

export default function BenvoPrimaryButton({
  onClick, children, sx, testid, ...props
}) {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#4A397F',
        color: '#FFFFFF',
        textTransform: 'none',
        fontSize: '14px',
        borderRadius: '80px',
        boxShadow: 'none',
        padding: '6px 40px',

        '&:hover': {
          backgroundColor: '#4A397F',
        },
        ...sx,
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}
