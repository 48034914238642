import React, { useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from '@mui/material';
import ConfirmationInput from '../../../../components/ConfirmationInput';
import routes from '../../../../services/routes';
import formatList from '../../utils/formatList';
import ClassTabs from './components/ClassTabs';
import CreatePrescriptionItem from '../Form/CreatePrescriptionItem';

const initialFormValues = {
  measure: 'und',
  quantity: '',
  item: '',
};

const residentTypeOptions = [
  { label: 'Medicamento', value: 'medication' },
  { label: 'Itens de mercado', value: 'market' },
];

const ilpiTypeOptions = [
  { label: 'Produto', value: 'product' },
  { label: 'Frutas, legumes e verduras', value: 'flv' },
  { label: 'Itens de mercado', value: 'market' },
];

function ItemsContainer({
  items,
  setItems,
  onFailure,
  setPrescriptionItems,
  values,
}) {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [type, setType] = useState('product');
  const [typeOptions, setTypeOptions] = useState(ilpiTypeOptions);
  const [prescriptionConfirmationIsOpen, setPrescriptionConfirmationIsOpen] = useState(false);
  const [prescriptionItemModalIsOpen, setPrescriptionItemModalIsOpen] = useState(false);

  const handleItems = () => {
    setItems((prev) => ({ ...prev, [type]: [...(prev[type] || []), formValues] }));
    setFormValues(initialFormValues);
  };

  const verifyFieldsValidity = () => {
    const RESIDENT_IS_NOT_SELECTED = values.recipient === 'resident' && !values.resident.value;
    const ITEM_IS_SELECTED = formValues.item && formValues.quantity;

    return !RESIDENT_IS_NOT_SELECTED && ITEM_IS_SELECTED;
  };

  const implementIlpiRules = () => {
    setType('product');
    setTypeOptions(ilpiTypeOptions);
  };

  const implementResidentRules = () => {
    setType('medication');
    setTypeOptions(residentTypeOptions);
  };

  const handleDelete = (itemName, id) => {
    setItems((prev) => Object.entries(prev).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value?.filter(({ item }) => item !== itemName) || [],
    }), {}));

    if (id) {
      setPrescriptionItems((prev) => prev.filter(({ medicineId }) => medicineId !== id));
    }
  };

  const handlePrescription = (prescriptionItem) => {
    setPrescriptionItems((prev) => [...prev, prescriptionItem]);
    handleItems();
  };

  const verifyPrescription = async () => {
    const { value: residentId } = values.resident;
    const { id: medicationId } = formValues;

    try {
      await routes.prescription.getPrescriptionsByResidentAndMedicationId({
        residentId,
        medicationId,
      });

      return true;
    } catch (error) {
      if (error.response.status !== 404) {
        onFailure('Não foi possível verificar a prescrição');
      }

      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (type === 'medication') {
        const medicineId = formValues.id;
        const medication = await routes.medicine.findProductById({ medicineId });

        if (!/(sem classificação)/i.test(medication.message.medicineClassification)) {
          const hasPrescription = await verifyPrescription();

          if (!hasPrescription) {
            return setPrescriptionConfirmationIsOpen(true);
          }
        }
      }

      return handleItems();
    } catch {
      return onFailure('Não foi possível obter informações do medicamento');
    }
  };

  useEffect(() => {
    if (values.recipient === 'resident') {
      implementResidentRules();
    } else implementIlpiRules();

    setFormValues(initialFormValues);
  }, [values.recipient]);

  return (
    <>
      {prescriptionConfirmationIsOpen && (
        <ConfirmationInput
          isOpen={prescriptionConfirmationIsOpen}
          message={
            `${formValues.item} precisa ser prescrito para ${values.resident.label}, deseja gerar uma prescrição?`
          }
          AcceptMessage="Sim, quero gerar uma prescrição"
          denialMessage="Agora não"
          onAccept={() => setPrescriptionItemModalIsOpen(true)}
          onClose={() => setPrescriptionConfirmationIsOpen(false)}
        />
      )}

      {prescriptionItemModalIsOpen && (
        <CreatePrescriptionItem
          isOpen={prescriptionItemModalIsOpen}
          onClose={() => setPrescriptionItemModalIsOpen(false)}
          handlePrescription={handlePrescription}
          resident={values.resident}
          medication={formValues}
          onFailure={onFailure}
        />
      )}

      <form onSubmit={handleSubmit} style={{ margin: '20px 0' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="type">Tipo</InputLabel>
              <Select
                labelId="type"
                label="type"
                value={type}
                onChange={({ target }) => setType(target.value)}
                fullWidth
                size="small"
              >
                {typeOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <ClassTabs
            type={type}
            formValues={formValues}
            setFormValues={setFormValues}
          />

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={!verifyFieldsValidity()}
              data-testid="monthlyList-addItem-btn"
              sx={{ height: '100%' }}
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </form>

      <TableContainer sx={{ maxHeight: 440, marginY: '10px' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Quantidade</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formatList(items).map(({
              item, quantity, measure, id,
            }) => (
              <TableRow hover tabIndex={-1} key={id}>
                <TableCell>{item}</TableCell>
                <TableCell>{quantity + (measure || 'und')}</TableCell>
                <TableCell sx={{ padding: 0 }}>
                  <Toolbar title="Apagar">
                    <IconButton
                      color="secondary"
                      sx={{ padding: 0 }}
                      onClick={() => handleDelete(item, id)}
                    >
                      <Delete />
                    </IconButton>
                  </Toolbar>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ItemsContainer;
