import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import BenvoButton from '../../../../components/BenvoButton/style';
import BenvoVerticalStepper from './BenvoVerticalStepper';
import BenvoAccordion from './BenvoAccordion';
import ResidentsStep from './Steps/ResidentsStep';
import MedicalRequestStep from './Steps/MedicalRequestStep';
import ProductsStep from './Steps/ProductsStep';
import SchedulingStep from './Steps/SchedulingStep';
import { getTokenInfo } from '../../../../utils/configAuth';
import {
  ButtonsContainer, PageContainer, StepperContainer, Subtitle, Title, TitleContainer,
} from './style';
import PATHS from '../../../../constants/applicationPaths';
import routes from '../../../../services/routes';
import { useAlert } from '../../../../context/alertContext';
import { organizeFilesToDasa } from '../../helpers';

const steps = [
  {
    label: 'Residentes',
    description: 'Selecione um ou mais residentes',
    descriptionSize: '103px',
  },
  {
    label: 'Produtos',
    description: 'Selecione serviços de saúde',
    descriptionSize: '114px',
  },
  {
    label: 'Requisição médica',
    description: 'Faça a requisição médica para os exames',
    descriptionSize: '146px',
  },
  {
    label: 'Preferência de Agendamento',
    description: 'Indique sua preferência, informaremos caso o horário não esteja disponível',
    descriptionSize: '160px',
  },
];

const { healthServiceOrder: healthServiceOrderRoute } = routes;

export default function RequestServicePage() {
  const alert = useAlert();
  const ilpiId = getTokenInfo('ilpiId');

  const defaultHealthServiceOrder = {
    ilpiId,
    residentIds: [],
    serviceIds: [],
    date: null,
    indication: '',
    dayShift: '',
    medicalRequest: true,
    collaboratorId: null,
    files: [],
  };
  const [healthServiceOrder, setHealthServiceOrder] = useState(defaultHealthServiceOrder);
  const [activeStep, setActiveStep] = useState(0);
  const [creatingHealthServiceOrder, setCreatingHealthServiceOrder] = useState(false);
  const navigate = useNavigate();

  const createHealthServiceOrder = async () => {
    setCreatingHealthServiceOrder(true);
    const payload = healthServiceOrder;

    if (payload.medicalRequest) {
      delete payload.files;
    } else {
      delete payload.collaboratorId;
      const filesBase64 = await organizeFilesToDasa(payload.files);
      payload.files = filesBase64;
    }
    if (payload.date === null) delete payload.date;
    else payload.date = moment(payload.date).format('YYYY-MM-DD');
    if (payload.dayShift === '') delete payload.dayShift;
    try {
      await healthServiceOrderRoute.createOrder(payload);
      alert.success('Solicitação de exame realizada');
      navigate(PATHS.exams.root);
    } catch (error) {
      alert.error('Erro ao solicitar exame, tente novamente mais tarde.');
    } finally {
      setCreatingHealthServiceOrder(false);
    }
  };

  const buttonIsEnabledToCreateOrder = () => {
    const miniumResidents = healthServiceOrder.residentIds.length > 0;
    const miniumServices = healthServiceOrder.serviceIds.length > 0;
    const hasIndication = healthServiceOrder.indication !== '';
    const medicalRequestCheck = healthServiceOrder.medicalRequest
      ? healthServiceOrder.collaboratorId !== null
      : healthServiceOrder.files.length > 0;

    return miniumResidents && miniumServices && hasIndication && medicalRequestCheck;
  };

  const accordionItems = [
    {
      label: 'Residentes',
      children: (
        <ResidentsStep
          setActiveStep={setActiveStep}
          setHealthServiceOrder={setHealthServiceOrder}
          healthServiceOrder={healthServiceOrder}
        />
      ),
    },
    {
      label: 'Produtos',
      children: (
        <ProductsStep
          setActiveStep={setActiveStep}
          setHealthServiceOrder={setHealthServiceOrder}
          healthServiceOrder={healthServiceOrder}
        />
      ),
    },
    {
      label: 'Requisição médica',
      children: (
        <MedicalRequestStep
          setActiveStep={setActiveStep}
          setHealthServiceOrder={setHealthServiceOrder}
          healthServiceOrder={healthServiceOrder}
        />
      ),
    },
    {
      label: 'Preferência de Agendamento',
      subtitle: 'opcional',
      children: (
        <SchedulingStep
          setActiveStep={setActiveStep}
          setHealthServiceOrder={setHealthServiceOrder}
          healthServiceOrder={healthServiceOrder}
          createHealthServiceOrder={createHealthServiceOrder}
          buttonIsEnabledToCreateOrder={buttonIsEnabledToCreateOrder}
          creatingHealthServiceOrder={creatingHealthServiceOrder}
        />
      ),
    },
  ];

  return (
    <PageContainer
      data-testid="exams-orders-page"
    >
      <StepperContainer>
        <TitleContainer>
          <Title>Serviço de saúde</Title>
          <Subtitle>Solicite serviços de saúde para seus residentes.</Subtitle>
        </TitleContainer>
        <BenvoVerticalStepper activeStep={activeStep} steps={steps} />
        <ButtonsContainer>
          <Link to={PATHS.exams.root}>
            <BenvoButton horizontalPadding="18" hasBorder variant="secondary">
              Cancelar
            </BenvoButton>
          </Link>
          <BenvoButton
            disabled={!buttonIsEnabledToCreateOrder() || creatingHealthServiceOrder}
            onClick={createHealthServiceOrder}
            horizontalPadding="18"
          >
            Fazer pedido
          </BenvoButton>
        </ButtonsContainer>
      </StepperContainer>
      <BenvoAccordion accordionItems={accordionItems} activeStep={activeStep} setActiveStep={setActiveStep} />
    </PageContainer>
  );
}
