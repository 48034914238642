import React, { useState, useEffect } from 'react';

import {
  Box,
  Collapse,
  ListItemButton,
  Typography,
  styled,
  keyframes,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useModalOffer } from '../../BenvoModuleOfferModal';
import BenvoTooltip from '../../BenvoTooltip';

const StyledBox = styled(Box)`
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  .MuiButtonBase-root {
    &:hover {
      background-color: #f5f5f5;
    }
  }

  &:has(a.active) {
    .MuiButtonBase-root {
      background-color: #f5f5f7;
    }
  }
`;

const StyledItemButton = styled(ListItemButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;

const StyledCollapse = styled(Collapse)`
  .MuiCollapse-wrapper {
    .MuiCollapse-wrapperInner {
      a {
        color: #474750;
        padding: 10px;

          &:hover {
          background-color: #fafafa;
        }
      }
    }
  }
`;

const StyledTypography = styled(Typography)`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 19.6px;
  color: #474750;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
`;

const displayMenu = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledMenu = styled(Box)`
  animation: ${displayMenu} 0.3s ease-in-out;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 90px;
  z-index: 9999999;
  border-radius: 6px;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.06));
  display: flex;
  flex-direction: column;
  
    &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
    }
`;

export default function MenuDropItem({
  item, index, isOpen, setIsOpen, setActiveMenu, activeMenu,
}) {
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const path = useLocation();
  const { showModalOffer } = useModalOffer();

  const handleMenuOpen = () => {
    setActiveMenu(item.title);
  };
  const handleMenuClose = () => {
    setActiveMenu(null);
  };

  const handleClick = () => {
    setIsOpen(false);
    if (isOpen) {
      setItemIsOpen(!itemIsOpen);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setItemIsOpen(false);
    }
  }, [isOpen]);

  const handleNavigate = (access) => {
    if (!access) {
      showModalOffer();
    }
  };

  const handleCurrentPath = (itemPath) => path.pathname.startsWith(itemPath);

  return (
    <StyledBox
      sx={{
        width: isOpen ? '204px' : '40px',
      }}
    >
      {isOpen ? (
        <Box>
          <StyledItemButton
            onClick={handleClick}
            key={item.title}
            sx={{
              mt: index === 0 ? '0px' : '22px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '13px',
              }}
            >
              {item.icon}
              <StyledTypography
                sx={{
                  width: '127px',
                }}
              >
                {item.title}
              </StyledTypography>
            </Box>
            {itemIsOpen ? (
              <KeyboardArrowUp sx={{
                color: '#474750',
              }}
              />
            ) : (
              <KeyboardArrowDown sx={{
                color: '#474750',
              }}
              />
            )}
          </StyledItemButton>
          <StyledCollapse in={itemIsOpen} timeout="auto">
            {item.itens.map((dropItens) => (dropItens.validUser ? (
              <Link
                onClick={() => {
                  handleNavigate(dropItens.access);
                }}
                to={dropItens.access ? dropItens.link : '#'}
                key={dropItens.title}
                className={handleCurrentPath(dropItens.link) ? 'active' : null}
                style={{
                  backgroundColor: handleCurrentPath(dropItens.link) ? '#e5e5e5' : '#fff0',
                }}
              >
                <StyledTypography>
                  {dropItens.title}
                </StyledTypography>
              </Link>
            ) : null))}
          </StyledCollapse>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <BenvoTooltip
            disableHoverListener={isOpen}
            title={item.title}
            placement="top"
            arrow
            style={{
              width: 'fit-content',
            }}
          >
            <StyledItemButton
              onMouseEnter={handleMenuOpen}
              key={item.title}
              sx={{
                mt: index === 0 ? '0px' : '22px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '13px',
                }}
              >
                {item.icon}
              </Box>
            </StyledItemButton>
          </BenvoTooltip>
          <StyledMenu
            onMouseLeave={handleMenuClose}
            sx={{
              display: activeMenu ? 'flex' : 'none',
            }}
          >
            {item.itens.map((dropItens) => (dropItens.validUser ? (
              <Link
                className={handleCurrentPath(dropItens.link) ? 'active' : null}
                onClick={() => {
                  handleNavigate(dropItens.access);
                  handleMenuClose();
                }}
                to={dropItens.access ? dropItens.link : '#'}
                style={{
                  backgroundColor: handleCurrentPath(dropItens.link) ? '#e5e5e5' : '#fff0',
                }}
              >
                <StyledTypography
                  sx={{
                    padding: '10px 20px',
                  }}
                >
                  {dropItens.title}
                </StyledTypography>
              </Link>
            ) : null))}
          </StyledMenu>
        </Box>
      )}
    </StyledBox>
  );
}
