import React, { useEffect, useState } from 'react';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';

import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import Loading from '../../../../components/Loading/Loading';
import { useAlert } from '../../../../context/alertContext';
import routes from '../../../../services/routes';
import { convertAPIDateWithoutTime } from '../../../../utils/dateFormat';
import { getTokenInfo } from '../../../../utils/configAuth';

export default function CreatePrescription({ listPrescription }) {
  const { resident, medicine, prescription } = routes;
  const [openCreatePrescriptionDialog, setOpenCreatePrescriptionDialog] = useState(false);
  const [formData, setFormData] = useState({
    data: {
      prescriptionItems: [],
    },
  });
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [productListIsLoading, setProductListIsLoading] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [residents, setResidents] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [option, setOption] = useState('');
  const [currentItem, setCurrentItem] = useState({});
  const ilpiId = getTokenInfo('ilpiId');

  const getResidents = async () => {
    try {
      const { message } = await resident.getAllResidentsByIlpiWithoutPagination(
        { ilpiId },
      );
      setResidents(message);
    } catch (error) {
      console.log(error);
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const getMedicines = async (medicineName = '') => {
    try {
      if (medicineName.length >= 4) {
        setProductListIsLoading(true);
        const { message } = await medicine.findBySubstanceOrName({
          name: medicineName,
        });
        setProductsList(message.rows);
      }
    } catch (error) {
      console.error('Prescription', error);
      alert.error('Medicamento não encontrado');
    } finally {
      setProductListIsLoading(false);
    }
  };

  const delayedGetProducts = debounce(getMedicines, 1000);

  const searchProducts = (query) => {
    delayedGetProducts(query);
  };

  const fetchAll = async () => {
    try {
      await getResidents();
      await getMedicines();
    } catch (error) {
      console.log('Prescription', error);
      alert.error(
        'Ocorreu um erro ao carregar a página, tente novamente mais tarde',
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const handleSubmit = async (event) => {
    setOpenCreatePrescriptionDialog(true);
    event.preventDefault();
    setSubmitIsLoading(true);
    try {
      formData.data.ilpiId = ilpiId;
      const { data } = formData;
      prescription.createPrescription(data);
      alert.success('Prescrição criada com sucesso!');
      setFormData({
        data: {
          prescriptionItems: [],
        },
      });
      setTimeout(() => {
        listPrescription(0);
        setOpenCreatePrescriptionDialog(false);
      }, 1500);
    } catch (error) {
      console.log('Prescription', error);
      alert.error('Erro ao criar prescrição');
    } finally {
      setSubmitIsLoading(false);
    }
  };

  const handleCancel = () => {
    setOpenCreatePrescriptionDialog(false);
    setFormData({
      data: {
        prescriptionItems: [],
      },
    });
    setProductsList([]);
  };

  const handleChange = (event) => {
    if (event) {
      const { name, value } = event.target;

      setCurrentItem((prevCurrentItem) => ({
        ...prevCurrentItem,
        [name]: value,
      }));
    }
  };

  const handleChangeTypeNumber = (event) => {
    if (event && !event.target.value.match(/\D/g)) {
      const { name, value } = event.target;
      setCurrentItem((prevCurrentItem) => ({
        ...prevCurrentItem,
        [name]: value,
      }));
    }
  };

  const handleResidentAutoComplete = (e, newValue) => {
    if (newValue) {
      const { id: residentId, name: residentName } = newValue;
      setFormData((prevFormData) => ({
        ...prevFormData,
        data: {
          ...prevFormData.data,
          residentId,
          residentName,
        },
      }));
    }
  };

  const handleProductAutoComplete = (e, newValue) => {
    if (newValue) {
      const {
        id,
        class: medicineClass,
        controlType,
        substance,
        dosage,
        medicineClassification,
      } = newValue;
      setCurrentItem((prevCurrentItem) => ({
        ...prevCurrentItem,
        medicineId: id,
        class: medicineClass,
        controlType,
        substance,
        dosage,
        medicationType: medicineClassification,
      }));
    }
  };

  const handleDateSelect = (field, event) => {
    if (event) {
      setCurrentItem((prevCurrentItem) => ({
        ...prevCurrentItem,
        [field.name]: convertAPIDateWithoutTime(event._d),
      }));
    }
  };

  const handleAddItem = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      data: {
        ...prevFormData.data,
        prescriptionItems: [
          ...prevFormData.data.prescriptionItems,
          currentItem,
        ],
      },
    }));
    setCurrentItem({});
    setProductsList([]);
  };

  const handleDeleteItem = (id) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      data: {
        ...prevFormData.data,
        prescriptionItems: prevFormData.data.prescriptionItems.filter(
          (item) => item.medicineId !== id,
        ),
      },
    }));
  };

  const handleOptions = () => {
    if (option.length > 1 && option.length < 4) {
      return 'A busca precisa ter ao menos 4 caracteres';
    } return 'Digite para buscar um medicamento';
  };

  const columns = [
    {
      field: 'substance',
      headerName: 'Substância',
      width: 290,
    },
    { field: 'dosage', headerName: 'Dosagem', width: 150 },
    { field: 'quantity', headerName: 'Qtd', width: 40 },
    { field: 'unit', headerName: 'Und', width: 40 },
    {
      field: 'startDate',
      headerName: 'Data Inicial',
      width: 120,
      valueFormatter: (params) => moment(params.value).format('DD/MM/yyyy'),
    },
    {
      field: 'endDate',
      headerName: 'Data Final',
      width: 120,
      valueFormatter: (params) => moment(params.value).format('DD/MM/yyyy'),
    },
    {
      field: 'delete',
      headerName: 'Ações',
      width: 60,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => handleDeleteItem(params.row.medicineId)}
          data-testid={`delete-item-${params.row.medicineId}`}
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Grid container xs="auto" md lg display="flex" justifyContent="flex-end">
        {isLoading ? (
          <Loading />
        ) : (
          <Button
            appearance="primary"
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() => setOpenCreatePrescriptionDialog(true)}
            data-testid="create-prescription-button"
          >
            Prescrição
          </Button>
        )}
      </Grid>
      <Dialog
        maxWidth="md"
        open={openCreatePrescriptionDialog}
        onClose={handleCancel}
      >
        <DialogTitle>
          <Typography variant="h5">Cadastrar Prescrição</Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="resident"
                  name="resident"
                  options={residents}
                  noOptionsText="Nenhum residente encontrado"
                  getOptionLabel={(thisOption) => thisOption.name || ''}
                  onChange={(e, newValue) => {
                    handleResidentAutoComplete(e, newValue, 'resident');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Selecione um Residente"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="medicineId"
                  name="medicineId"
                  options={productsList ?? []}
                  noOptionsText={handleOptions()}
                  loading={productListIsLoading}
                  loadingText="Carregando..."
                  getOptionLabel={(options) => `${options.name} - ${options.substance} - ${options.dosage}` ?? ''}
                  onChange={(e, newValue) => {
                    handleProductAutoComplete(e, newValue, 'medicineId');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Buscar medicamento"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        setOption(e.target.value);
                        searchProducts(e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  id="quantity"
                  name="quantity"
                  label="Quantidade"
                  value={currentItem.quantity ?? ''}
                  onChange={handleChangeTypeNumber}
                  size="small"
                  fullWidth
                  inputProps={{
                    min: 0,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  id="unit"
                  name="unit"
                  label="Unidade"
                  value={currentItem.unit ?? ''}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  id="timesPerDay"
                  name="timesPerDay"
                  label="Vezes ao dia"
                  value={currentItem.timesPerDay ?? ''}
                  onChange={handleChangeTypeNumber}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DesktopDatePicker
                  label="Data Inicial da Medicação"
                  inputFormat="DD/MM/yyyy"
                  value={currentItem.startDate || null}
                  onChange={(event) => handleDateSelect({ name: 'startDate' }, event)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="startDate"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DesktopDatePicker
                  label="Data Final da Medicação"
                  inputFormat="DD/MM/yyyy"
                  value={currentItem.endDate || null}
                  onChange={(event) => handleDateSelect({ name: 'endDate' }, event)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="endDate"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <TextField
                  multiline
                  id="observation"
                  name="observation"
                  label="Observação"
                  value={currentItem.observation ?? ''}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  spellCheck="false"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  data-testid="add-item-button"
                  variant="contained"
                  color="primary"
                  onClick={handleAddItem}
                  disabled={
                    !formData.data.residentId
                    || !currentItem.startDate
                    || !currentItem.medicineId
                    || !currentItem.quantity
                    || !currentItem.unit
                    || !currentItem.timesPerDay
                  }
                >
                  Adicionar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={formData.data.prescriptionItems}
                  columns={columns}
                  getRowId={(row) => row.medicineId}
                  autoHeight
                  disableSelectionOnClick
                  hideFooterPagination
                  hideFooter
                  localeText={{
                    noRowsLabel: 'Nenhum item adicionado',
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 24px',
            }}
          >
            <Button variant="text" color="cancel" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button
              data-testid="submit-button"
              variant="contained"
              color="primary"
              type="submit"
              startIcon={
                submitIsLoading && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
              disabled={
                !formData.data.residentId
                || !formData.data.prescriptionItems.length
                || submitIsLoading
              }
            >
              Criar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
