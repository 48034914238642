import React from 'react';

export default function Send() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M14.5514 14.4085L14.5512 14.4089C14.1411 15.6413 13.677 16.5264 13.2084 17.0936C12.7385 17.6624 12.3102 17.8605 11.959 17.8605C11.6077 17.8605 11.1795 17.6624 10.7095 17.0936C10.241 16.5264 9.77687 15.6413 9.36674 14.4089L8.62517 12.1758L8.54616 11.9379L8.30823 11.8589L6.0749 11.1172L6.07473 11.1172C4.84434 10.7091 3.96085 10.247 3.39498 9.78024C2.82756 9.3122 2.63053 8.88573 2.63086 8.53595C2.63119 8.18577 2.82944 7.75779 3.39873 7.28731C3.96625 6.81828 4.85166 6.35313 6.08423 5.94092L13.6004 3.43274C13.6005 3.43273 13.6005 3.43271 13.6006 3.43269C15.211 2.89593 16.2744 3.12498 16.8187 3.67004C17.3636 4.21566 17.5922 5.2819 17.0595 6.89252C17.0594 6.89268 17.0593 6.89285 17.0593 6.89301L14.5514 14.4085Z" fill="#4A397F" stroke="#4A397F" />
      <mask id="path-2-inside-1_6725_3886" fill="white">
        <path d="M10.0996 10.1916L13.2746 7.0083L10.0996 10.1916Z" />
      </mask>
      <path d="M10.0996 10.1916L13.2746 7.0083L10.0996 10.1916Z" fill="#4A397F" />
      <path d="M10.8076 10.8978L13.9826 7.71448L12.5666 6.30212L9.39158 9.48546L10.8076 10.8978Z" fill="#4A397F" mask="url(#path-2-inside-1_6725_3886)" />
      <path d="M10.0114 10.1034L10.0123 10.1025L13.178 6.92006C13.1781 6.91997 13.1782 6.91988 13.1783 6.91979C13.1998 6.89842 13.2304 6.88525 13.2661 6.88525C13.302 6.88525 13.3327 6.89853 13.3543 6.92006C13.3758 6.94158 13.3891 6.97232 13.3891 7.00817C13.3891 7.04402 13.3758 7.07476 13.3543 7.09628L13.3533 7.09721L10.195 10.2722C10.1541 10.3083 10.1169 10.3165 10.0995 10.3165C10.0818 10.3165 10.0663 10.3133 10.053 10.3079C10.0401 10.3027 10.026 10.2943 10.0114 10.2796L9.74738 10.5436L10.0114 10.2796C9.98984 10.2581 9.97656 10.2274 9.97656 10.1915C9.97656 10.1557 9.98984 10.1249 10.0114 10.1034Z" fill="#4A397F" stroke="#4A397F" />
    </svg>
  );
}
