import { Grid, TextField } from '@mui/material';
import React from 'react';

export default function DiuresisForm({ errors, register }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size="small"
          label="Diurese"
          error={!!errors.diuresis}
          helperText={errors.diuresis?.message}
          {...register('diuresis')}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size="small"
          label="Evacuação"
          error={!!errors.evacuation}
          helperText={errors.evacuation?.message}
          {...register('evacuation')}
        />
      </Grid>
    </>
  );
}
