import {
  Box, Divider, Radio, Step, StepConnector, StepLabel, Stepper, Typography, stepConnectorClasses, styled,
} from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as StepCompletedIcon } from '../../../../assets/icons/step-completed.svg';
import AddItens from './steps/AddItens';
import AddOtherItems from './steps/AddOtherItems';
import QuantityAndSummary from './steps/QuantityAndSummary';
import SelectItens from './steps/SelectItens';
import BenvoTextField from '../../../../components/BenvoTextField';
import routes from '../../../../services/routes';
import { useAlert } from '../../../../context/alertContext';
import { getTokenInfo } from '../../../../utils/configAuth';
import BenvoDrawer from '../../../../components/BenvoDrawer';
import BenvoPrimaryButton from '../../../../components/BenvoPrimaryButton';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#EAEBEC',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#EAEBEC',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#EAEBEC',
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const {
    active, completed, className, icon,
  } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <StepCompletedIcon className="QontoStepIcon-completedIcon" />
      ) : (
        <Box
          sx={{
            backgroundColor: active ? '#4A397F4D' : '#D4D4DA',
            borderRadius: '50%',
            padding: '4px',
          }}
        >
          <Typography
            sx={{
              backgroundColor: active ? '#4A397F' : '#96969E',
              fontSize: '16px',
              color: '#FFFFFF',
              borderRadius: '50%',
              width: '28px',
              height: '28px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

            }}
          >
            {icon}
          </Typography>
        </Box>
      )}
    </QontoStepIconRoot>
  );
}

const productsListInitialState = {
  name: '',
  itemList: [],
};

export default function CreateMarketList({ refetchMarketplaceList, marketplaceList, buttonTitle = 'Nova Lista' }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedValue, setSelectedValue] = useState('1');
  const [activeStep, setActiveStep] = useState(0);
  const [productsList, setProductsList] = useState(productsListInitialState);
  const [isLoading, setIsLoading] = useState(false);
  const { marketplace } = routes;
  const alert = useAlert();
  const ilpiId = getTokenInfo('ilpiId');

  const stepsEmptyList = ['Criar lista', 'Selecione os itens', 'Adicione outros itens', 'Quantidade e resumo'];

  const stepsLegacyList = ['Criar lista', 'Adicionar Itens', 'Quantidade e resumo'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleReset = () => {
    setActiveStep(0);
    setProductsList((prevState) => ({
      ...prevState,
      itemList: [],
    }));
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    handleReset();
    setSelectedValue('1');
    setProductsList(productsListInitialState);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const setProductsListName = (event) => {
    const name = event.target.value;

    setProductsList((prevState) => ({
      ...prevState,
      name,
    }));
  };

  const handleRenderStep = (step) => {
    if (selectedValue === '1') {
      const stepsComponents = {
        0: (
          <>
            <Typography
              alignSelf="flex-start"
              sx={{
                color: '#474750',
                fontSize: '16px',
              }}
            >
              Nome da lista
            </Typography>
            <BenvoTextField
              placeholder="Nome da lista"
              onChange={setProductsListName}
              value={productsList.name}
              sx={{
                my: '22px',
              }}
            />
            <Divider
              flexItem
              sx={{
                color: '#EAEBEC',
              }}
            />
            <Typography
              alignSelf="flex-start"
              sx={{
                color: '#474750',
                fontSize: '16px',
                mt: '22px',
              }}
            >
              Modelo
            </Typography>
            <Box
              onClick={() => setSelectedValue('1')}
              sx={{
                alignSelf: 'flex-start',
                backgroundColor: '#F5F5F7',
                borderRadius: '8px',
                width: '100%',
                p: '13px',
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                mb: '12px',
                mt: '22px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Radio
                data-testid="modelRadioBtn"
                checked={selectedValue === '1'}
                value="1"
                onChange={handleChange}
                sx={{
                  color: '#D4D4DA',
                  '&.Mui-checked': {
                    color: '#4A397F',
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#474750',
                }}
              >
                Começar lista vazia
              </Typography>
            </Box>
            {marketplaceList?.rows?.some((item) => item.isMain === 1) && (
              <Box
                onClick={() => setSelectedValue('2')}
                sx={{
                  alignSelf: 'flex-start',
                  backgroundColor: '#F5F5F7',
                  borderRadius: '8px',
                  width: '100%',
                  p: '13px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Radio
                  data-testid="modelRadioBtn"
                  checked={selectedValue === '2'}
                  value="2"
                  disabled={!marketplaceList?.rows?.some((item) => item.isMain === 1)}
                  onChange={handleChange}
                  sx={{
                    color: '#D4D4DA',
                    '&.Mui-checked': {
                      color: '#4A397F',
                    },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#474750',
                  }}
                >
                  Começar lista importando itens da principal
                </Typography>
              </Box>
            )}

          </>
        ),
        1: <AddItens handleReset={handleReset} productsList={productsList} setProductsList={setProductsList} />,
        2: <QuantityAndSummary
          handleBack={handleBack}
          handleReset={handleReset}
          productsList={productsList}
          setProductsList={setProductsList}
        />,
      };
      return stepsComponents[step] ?? null;
    }
    const stepsComponents = {
      0: (
        <>
          <Typography
            alignSelf="flex-start"
            sx={{
              color: '#474750',
              fontSize: '16px',
            }}
          >
            Nome da lista
          </Typography>
          <BenvoTextField
            placeholder="Nome da lista"
            onChange={setProductsListName}
            value={productsList.name}
            sx={{
              my: '22px',
            }}
          />
          <Divider
            flexItem
            sx={{
              color: '#EAEBEC',
            }}
          />
          <Typography
            alignSelf="flex-start"
            sx={{
              color: '#474750',
              fontSize: '16px',
              mt: '22px',
            }}
          >
            Modelo
          </Typography>
          <Box
            onClick={() => setSelectedValue('1')}
            sx={{
              alignSelf: 'flex-start',
              backgroundColor: '#F5F5F7',
              borderRadius: '8px',
              width: '100%',
              p: '13px',
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              mb: '12px',
              mt: '22px',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Radio
              data-testid="modelRadioBtn"
              checked={selectedValue === '1'}
              value="1"
              onChange={handleChange}
              sx={{
                color: '#D4D4DA',
                '&.Mui-checked': {
                  color: '#4A397F',
                },
              }}
            />
            <Typography
              sx={{
                fontSize: '12px',
                color: '#474750',
              }}
            >
              Começar lista vazia
            </Typography>
          </Box>
          {marketplaceList?.rows?.some((item) => item.isMain === 1) && (
            <Box
              onClick={() => setSelectedValue('2')}
              sx={{
                alignSelf: 'flex-start',
                backgroundColor: '#F5F5F7',
                borderRadius: '8px',
                width: '100%',
                p: '13px',
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Radio
                data-testid="modelRadioBtn"
                checked={selectedValue === '2'}
                value="2"
                disabled={!marketplaceList?.rows?.some((item) => item.isMain === 1)}
                onChange={handleChange}
                sx={{
                  color: '#D4D4DA',
                  '&.Mui-checked': {
                    color: '#4A397F',
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#474750',
                }}
              >
                Começar lista importando itens da principal
              </Typography>
            </Box>
          )}
        </>
      ),
      1: <SelectItens
        handleReset={handleReset}
        productsList={productsList}
        marketplaceList={marketplaceList}
        setProductsList={setProductsList}
      />,
      2: <AddOtherItems
        handleReset={handleReset}
        handleBack={handleBack}
        productsList={productsList}
        setProductsList={setProductsList}
      />,
      3: <QuantityAndSummary
        handleBack={handleBack}
        handleReset={handleReset}
        productsList={productsList}
        setProductsList={setProductsList}
      />,
    };
    return stepsComponents[step] ?? null;
  };

  const handleSumit = async () => {
    setIsLoading(true);
    try {
      await marketplace.createMarketplaceList({ ilpiId }, productsList);
      alert.success('Lista criada com sucesso!');
      refetchMarketplaceList();
      handleCloseDrawer();
    } catch (error) {
      alert.error('Erro ao criar lista, tente novamente mais tarde.');
    } finally {
      setIsLoading(false);
    }
  };

  const getButtonText = (currentStep) => {
    if (selectedValue === '1') {
      const buttonText = {
        0: (
          <BenvoPrimaryButton
            onClick={handleNext}
            disabled={productsList.name.length < 3}
          >
            Criar lista
          </BenvoPrimaryButton>
        ),
        1: (
          <BenvoPrimaryButton
            onClick={handleNext}
            disabled={productsList.itemList.length === 0}
          >
            Adicionar
          </BenvoPrimaryButton>
        ),
        2: (
          <BenvoPrimaryButton
            onClick={handleSumit}
            disabled={productsList.itemList.length === 0 || isLoading}
          >
            Finalizar
          </BenvoPrimaryButton>
        ),
      };
      return buttonText[currentStep] ?? null;
    }

    const buttonText = {
      0: (
        <BenvoPrimaryButton
          onClick={handleNext}
          disabled={productsList.name.length < 3}
        >
          Criar lista
        </BenvoPrimaryButton>
      ),
      1: (
        <BenvoPrimaryButton
          onClick={handleNext}
          disabled={productsList.itemList.length === 0}
        >
          Adicionar
        </BenvoPrimaryButton>
      ),
      2: (
        <BenvoPrimaryButton
          onClick={handleNext}
          disabled={productsList.itemList.length === 0}
        >
          Adicionar
        </BenvoPrimaryButton>
      ),
      3: (
        <BenvoPrimaryButton
          onClick={handleSumit}
          disabled={productsList.name === '' || isLoading}
        >
          Finalizar
        </BenvoPrimaryButton>
      ),
    };
    return buttonText[currentStep] ?? null;
  };

  return (
    <Box>
      <BenvoPrimaryButton onClick={handleOpenDrawer}>
        {buttonTitle}
      </BenvoPrimaryButton>

      <BenvoDrawer
        data-testid="benvo-drawer"
        open={openDrawer}
        onClose={handleCloseDrawer}
        title="Nova lista"
      >
        <Box
          sx={{
            width: '100%',
            mb: '34px',
          }}
        >
          <Stepper
            data-testid={selectedValue === '1' ? 'legacy-list-steps' : 'emptyListSteps'}
            activeStep={activeStep}
            connector={<QontoConnector />}
            sx={{
              mt: '7px',
            }}
          >
            {selectedValue === '1' ? (
              stepsLegacyList.map((label, index) => (
                <Step key={label} data-testid="step-marker">
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                  >
                    {activeStep === index ? label : ''}
                  </StepLabel>
                </Step>
              ))

            ) : (
              stepsEmptyList.map((label, index) => (
                <Step key={label} data-testid="step-marker">
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                  >
                    {activeStep === index ? label : ''}
                  </StepLabel>
                </Step>
              ))
            )}
          </Stepper>
        </Box>

        {handleRenderStep(activeStep)}
        <Box
          sx={{
            flex: '1',
            alignSelf: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          {getButtonText(activeStep)}
        </Box>
      </BenvoDrawer>
    </Box>
  );
}
