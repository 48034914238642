import user from './user';
import resident from './resident';
import referenceContact from './referenceContact';
import evolution from './evolution';
import doctor from './doctor';
import employee from './employee';
import ilpi from './ilpi';
import accommodation from './accommodation';
import visit from './visit';
import marketplace from './marketplace';
import sellers from './sellers';
import login from './login';
import caregiver from './caregiver';
import nurse from './nurse';
import payment from './payment';
import firstAccess from './firstAccess';
import recoverPassword from './recoverPassword';

export default {
  user,
  resident,
  referenceContact,
  evolution,
  doctor,
  employee,
  payment,
  ilpi,
  accommodation,
  visit,
  marketplace,
  sellers,
  login,
  caregiver,
  nurse,
  firstAccess,
  recoverPassword,
};
