import { Box, Typography } from '@mui/material';
import React from 'react';

export default function BenvoHome() {
  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
    }}
    >
      <Typography variant="h1" component="h1">Área do SuperAdmin</Typography>
    </Box>
  );
}
