import React, { useState } from 'react';
import moment from 'moment';
import { Typography, Box, Divider } from '@mui/material';
import BenvoCard from '../../components/BenvoCard';
import routes from '../../services/routes';
import { getTokenInfo } from '../../utils/configAuth';
import { useAlert } from '../../context/alertContext';
import BenvoEmpty from '../../components/BenvoEmpty';
import getAge from '../../utils/getAge';
import BenvoButton from '../../components/BenvoButton/style';
import {
  CardsContainer, Container, StyledListItem, StyledResidentName,
} from './style';
import OrderInformation from './components/OrderInformation';
import BenvoSearchField from '../../components/BenvoSearchField';
import useInfiniteList from '../../hooks/useInfiniteList';
import DynamicScroll from '../../components/DynamicScroll';
import ExamRequestCard from './components/ExamRequestCard';
import ListOfExams from './components/ListOfExamsCard';

export default function RequiredServices() {
  const alert = useAlert();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const ilpiId = getTokenInfo('ilpiId');

  const getOrders = async (queries) => {
    try {
      const { message } = await routes.healthServiceOrder.getOrdersByIlpiId({ ilpiId }, queries);

      return message;
    } catch (error) {
      if (error.response?.status === 404) {
        alert.info('Nenhum exame encontrado');
      } else alert.error('Não foi possível carregar os exames');

      return {};
    }
  };

  const {
    data: orders = [],
    nextPage,
    setQueries,
    loading,
    finished,
    // refetch,
  } = useInfiniteList({
    getData: getOrders,
  });

  const handleSearch = async () => {
    setQueries({ resident: searchValue });
  };

  // const handleRefetch = async () => {
  //   refetch();
  //   setSearchValue('');
  // };

  return (
    <Container>
      <CardsContainer>
        <ExamRequestCard />
        <ListOfExams />
      </CardsContainer>
      <BenvoCard
        title="Exames solicitados"
        header={(
          <BenvoSearchField
            value={searchValue}
            onClick={handleSearch}
            onChange={({ target }) => setSearchValue(target.value)}
            sx={{
              width: '312px',
            }}
          />
        )}
      >
        <BenvoEmpty active={!orders.length && !loading} title="Exames" message="Nenhum exame solicitado" />

        <OrderInformation
          open={Boolean(selectedOrder)}
          onClose={() => setSelectedOrder(null)}
          residentId={selectedOrder?.residentId}
          orderId={selectedOrder?.id}
        />

        <DynamicScroll onScrollEnd={nextPage} loading={loading} finished={finished}>
          {orders.map((order) => (
            <StyledListItem>
              <Box>
                <StyledResidentName>{order.residentName}</StyledResidentName>

                <Typography variant="span">{`IDADE ${getAge(order.residentBirthDate)} ANOS`}</Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  height: '100%',
                }}
              >
                <Box sx={{ textAlign: 'right' }}>
                  <Box sx={{ height: '18px' }}>
                    <Typography variant="p">
                      {moment(order.date).format('DD [DE] MMMM YYYY [- SOLICITAÇÃO]')}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="p">{`${order.totalExams} EXAMES`}</Typography>
                  </Box>
                </Box>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    mx: '20px',
                    opacity: '1',
                  }}
                />

                <Box>
                  <BenvoButton variant="secondary" horizontalPadding="20" onClick={() => setSelectedOrder(order)}>
                    Visualizar
                  </BenvoButton>
                </Box>
              </Box>
            </StyledListItem>
          ))}
        </DynamicScroll>
      </BenvoCard>
    </Container>
  );
}
