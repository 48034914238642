import {
  Box, Modal, Paper, Typography,
} from '@mui/material';
import React from 'react';
import { Stack } from '@mui/system';
import formatList from '../../utils/formatList';
import MTable from '../../../../components/MuiTable';
import { phoneInputMask } from '../../../../utils/inputMasks';
import SendListByEmailButton from './SendListByEmailButton';
import MarkAsCompletedButton from './MarkAsCompletedButton';
import GenerateListPdfButton from './GenerateListPdfButton';

function ItemsListModal({
  isOpen, onClose, selectedList, onSuccess, onFailure,
}) {
  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      data-testid="monthlyList-modal"
      open={isOpen}
      onClose={onClose}
    >
      <MTable.Container
        component={Paper}
        sx={{
          padding: 2,
          width: '75%',
          maxHeight: '80%',
          overflow: 'auto',
        }}
      >
        <Typography variant="h5" color="primary" component="span" fontWeight="bold">
          Lista de compras
        </Typography>

        <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
          {selectedList.responsiblePhoneNumber && (
            <Box>
              <Box style={{ fontWeight: 'bold' }}>Contato do responsável</Box>
              <Typography component="span">
                {phoneInputMask(selectedList.responsiblePhoneNumber)}
              </Typography>
            </Box>
          )}

          {selectedList.observation && (
            <Box>
              <Box style={{ fontWeight: 'bold' }}> Observação </Box>
              <Typography component="span">{selectedList.observation}</Typography>
            </Box>
          )}

          {/* {selectedList.responsiblePhoneNumber &&
            selectedList.status === 'requested' && (
              <SendListByWppButton
                listId={selectedList.id}
                status={selectedList.status}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            )} */}
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <GenerateListPdfButton list={selectedList} onSuccess={onSuccess} />

            {selectedList.list?.flv.length !== 0 && selectedList.status === 'requested' && (
              <SendListByEmailButton
                listId={selectedList.id}
                status={selectedList.status}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            )}

            {(selectedList.status === 'sent' || !selectedList.list?.flv.length) && (
              <MarkAsCompletedButton
                listId={selectedList.id}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            )}
          </Box>
        </Stack>
        <MTable.Root size="small" aria-label="a dense table">
          <MTable.Head>
            <MTable.Cell>Item</MTable.Cell>
            <MTable.Cell>Quantidade</MTable.Cell>
          </MTable.Head>
          <MTable.Body>
            {formatList(selectedList.list).map(({ quantity, item, measure }) => (
              <MTable.Row key={item} data-testid="monthlyList-modal-item">
                <MTable.Cell component="th" scope="item">
                  {item}
                </MTable.Cell>
                <MTable.Cell component="th">{quantity + (measure || 'und')}</MTable.Cell>
              </MTable.Row>
            ))}
          </MTable.Body>
        </MTable.Root>
      </MTable.Container>
    </Modal>
  );
}

export default ItemsListModal;
