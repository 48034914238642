import { Box, styled } from '@mui/material';
import { BenvoContainer } from '../../../../components/BenvoContainer/style';

export const StyledBenvoContainer = styled(BenvoContainer)`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 3px;

  h5 {
    width: 100%;
    max-width: 250px;
    color: #96969e;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.035px;
  }

  p {
    color: #474750;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
    letter-spacing: 0.027px;
  }
`;
