import {
  FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField,
} from '@mui/material';
import React from 'react';

function MarketItemsTab({
  formValues,
  handleChange,
  handleFormItems,
}) {
  return (
    <>
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          label="Item"
          name="item"
          value={formValues.item}
          onChange={({ target }) => handleFormItems({ label: target.value })}
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }}>
          <TextField
            id="quantity"
            name="quantity"
            type="number"
            label="Quantidade"
            value={formValues.quantity}
            onChange={({ target }) => handleChange(target)}
            fullWidth
            size="small"
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <FormControl fullWidth>
          <InputLabel id="measure">Medida</InputLabel>
          <Select
            labelId="measure"
            label="Medida"
            name="measure"
            value={formValues.measure}
            onChange={({ target }) => handleChange(target)}
            fullWidth
            size="small"
          >
            <MenuItem value="und">Und</MenuItem>
            <MenuItem value="kg">Kg</MenuItem>
            <MenuItem value="g">G</MenuItem>
            <MenuItem value="mg">Mg</MenuItem>
            <MenuItem value="l">L</MenuItem>
            <MenuItem value="ml">Ml</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export default MarketItemsTab;
