import {
  Box, Divider, IconButton, Skeleton, TextField, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search-button-icon-outline.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-square-button-icon.svg';
import { ReactComponent as SearchArrowLeft } from '../../../../../assets/icons/square-arrow-left-icon.svg';
import { ReactComponent as PlusSquare } from '../../../../../assets/icons/plus-square-button-icon.svg';
import { ReactComponent as MinusSquare } from '../../../../../assets/icons/minus-square-button-icon.svg';
import { ReactComponent as TrashSquare } from '../../../../../assets/icons/trash-square-button-icon.svg';
import { ReactComponent as GrayMockedImage } from '../../../../../assets/mocked-image-gray.svg';
import BenvoTextField from '../../../../../components/BenvoTextField';
import { getTokenInfo } from '../../../../../utils/configAuth';
import { isSuperAdmin } from '../../../../../utils/checkUser';
import routes from '../../../../../services/routes';
import BenvoImage from '../../../../../components/BenvoImage';

const { product: productRoute, superadmin } = routes;

export default function AddItens({ handleReset, productsList, setProductsList }) {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState({
    name: '',
  });
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const ilpiId = getTokenInfo('ilpiId');

  useEffect(() => {
    if (searchTerm.name.length >= 3) {
      const timerId = setTimeout(() => {
        setDebouncedSearchTerm(searchTerm);
      }, 800);

      return () => {
        clearTimeout(timerId);
      };
    }

    return undefined;
  }, [searchTerm]);

  const handleChange = (event) => {
    const filteredProduct = event.target.value;
    setSearchTerm({
      name: filteredProduct,
    });
  };

  const getAllProducts = async () => {
    setIsLoading(true);
    try {
      const { message } = await superadmin.getAllProducts(debouncedSearchTerm);
      setProducts(message.rows);
    } catch {
      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const getFilteredProducts = async () => {
    setIsLoading(true);
    try {
      const { message } = await productRoute.getProductsByIlpi({ ilpiId }, debouncedSearchTerm);
      setProducts(message.rows);
    } catch {
      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm.name.length >= 3) {
      if (isSuperAdmin()) getAllProducts();
      else getFilteredProducts();
    }
  }, [debouncedSearchTerm]);

  const handleSelectProduct = (product) => {
    const {
      name, category, sku, type, groupId,
    } = product;

    const existingProductIndex = productsList.itemList.findIndex((item) => item.name === name);

    if (existingProductIndex === -1) {
      setProductsList({
        ...productsList,
        itemList: [
          {
            name,
            category,
            sku,
            type,
            groupId,
            quantity: 1,
          },
          ...productsList.itemList,
        ],
      });
    } else {
      const updatedItemList = [...productsList.itemList];
      updatedItemList[existingProductIndex].quantity += 1;
      setProductsList({
        ...productsList,
        itemList: updatedItemList,
      });
    }
  };

  const incrementQuantity = (name) => {
    setProductsList((prevProductsList) => {
      const updatedItemList = prevProductsList.itemList.map((item) => {
        if (item.name === name) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });
      return {
        ...prevProductsList,
        itemList: updatedItemList,
      };
    });
  };

  const decrementQuantity = (name) => {
    setProductsList((prevProductsList) => {
      const updatedItemList = prevProductsList.itemList.map((item) => {
        if (item.name === name) {
          const newQuantity = item.quantity - 1;
          return {
            ...item,
            quantity: newQuantity < 1 ? 1 : newQuantity,
          };
        }
        return item;
      });
      return {
        ...prevProductsList,
        itemList: updatedItemList,
      };
    });
  };

  const handleQuantityChange = (event, name) => {
    const newQuantity = parseInt(event.target.value, 10);
    setProductsList((prevProductsList) => {
      const updatedItemList = prevProductsList.itemList.map((item) => {
        if (item.name === name) {
          return {
            ...item,
            quantity: Number.isNaN(newQuantity) || newQuantity < 1 ? 1 : newQuantity,
          };
        }
        return item;
      });
      return {
        ...prevProductsList,
        itemList: updatedItemList,
      };
    });
  };

  const removeItem = (name) => {
    setProductsList((prevProductsList) => {
      const updatedItemList = prevProductsList.itemList.filter((item) => item.name !== name);
      return {
        ...prevProductsList,
        itemList: updatedItemList,
      };
    });
  };

  const clearSearchBar = () => {
    setSearchTerm({
      name: '',
    });
    setProducts([]);
  };

  return (
    <Box
      sx={{
        alignSelf: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#F5F5F7',
          borderRadius: '8px',
          width: '100%',
          px: '13px',
          py: '5px',
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          mb: '22px',
        }}
      >
        <IconButton
          onClick={handleReset}
        >
          <SearchArrowLeft data-testid="arrow-left-icon" />
        </IconButton>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#474750',
          }}
        >
          {productsList.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          mb: '22px',
        }}
      >
        <Typography
          fontWeight="500"
          sx={{
            fontSize: '16px',
            color: '#474750',
          }}
        >
          Adicionar Itens
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#474750',
          }}
        >
          Confira a quantidade dos itens selecionados
        </Typography>
      </Box>
      <BenvoTextField
        placeholder="Pesquisar"
        onIconClick={clearSearchBar}
        endIcon={searchTerm.name.length ? <CloseIcon data-testid="clear-input-icon" /> : <SearchIcon />}
        onChange={handleChange}
        value={searchTerm.name}
        isLoading={isLoading}
      />

      <Box
        sx={{
          mt: '12px',
          borderRadius: '8px',
          overflow: 'hidden',
          height: '132px',
          maxHeight: '132px',
          overflowY: 'auto',

          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
            backgroundColor: '#F5F5F7',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#4A397F',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F5F5F7',
          },
        }}
      >
        {isLoading && (
          <Box>
            <Skeleton
              variant="rectangular"
              sx={{
                mt: '12px',
                borderRadius: '8px',
                height: '120px',
                width: '100%',
              }}
            />
          </Box>
        )}

        {products.length === 0 && searchTerm.name.length > 0 && !isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '120px',
            }}
          >
            <Typography
              variant="subtitle1"
              color="#474750"
              sx={{
                fontSize: '12px',
                lineHeight: '170%',
              }}
            >
              Produto não encontrado
            </Typography>
          </Box>
        )}

        {!isLoading && products.map((product) => (
          <Box
            data-testid="searched-item-card"
            key={product.id || product.groupId}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#fff',
              padding: '12px',
              '&:nth-of-type(odd)': {
                backgroundColor: '#F5F5F7',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Box sx={{ width: '55px', height: '55px' }}>
                <BenvoImage
                  src={product.image}
                  alt={product.name}
                  DefaultImage={GrayMockedImage}
                  sx={{ minWidth: '55px' }}
                />
              </Box>

              <Typography
                variant="subtitle1"
                color="#474750"
                sx={{
                  fontSize: '12px',
                  lineHeight: '170%',
                  maxWidth: '300px',
                }}
              >
                {product.name}
              </Typography>
            </Box>
            {product.group && (
              <Box
                data-testid="group-tag"
                sx={{
                  mr: 'auto',
                  ml: '12px',
                  padding: '5px 10px',
                  borderRadius: '2px',
                  backgroundColor: '#4A397F',
                  color: '#fff',
                  fontSize: '10px',
                }}
              >
                Grupo
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => handleSelectProduct(product)}
              >
                <PlusSquare data-testid="include-item-to-list-btn" />
              </IconButton>
            </Box>
          </Box>
        ))}

      </Box>

      {productsList.itemList.length > 0 && (
        <Box>
          <Divider sx={{ mt: '22px' }} />
          <Typography sx={{ mt: '22px' }}>
            Itens selecionados
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          mt: '12px',
          borderRadius: '8px',
          overflow: 'hidden',
          maxHeight: '220px',
          overflowY: 'auto',

          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
            backgroundColor: '#F5F5F7',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#4A397F',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F5F5F7',
          },
        }}
      >
        {productsList.itemList.map((product) => (
          <Box
            data-testid="listed-products-card"
            key={product.name}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#fff',
              padding: '12px',
              '&:nth-of-type(odd)': {
                backgroundColor: '#F5F5F7',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Typography
                variant="subtitle1"
                color="#474750"
                sx={{
                  fontSize: '12px',
                  lineHeight: '170%',
                  maxWidth: '300px',
                }}
              >
                {product.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => removeItem(product.name)}
              >
                <TrashSquare data-testid="remove-item-from-list" />
              </IconButton>
              <IconButton
                data-testid="decrement-quantity-icon"
                onClick={() => decrementQuantity(product.name)}
              >
                <MinusSquare />
              </IconButton>
              <TextField
                data-testid="quantityDisplay"
                size="small"
                variant="standard"
                onChange={(event) => handleQuantityChange(event, product.name)}
                sx={{
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '& input': {
                    textAlign: 'center',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                  },
                  '& .Mui-focused .MuiInput-underline:before': {
                    borderBottom: 'none',
                  },
                }}
                value={product.quantity || 0}
              />
              <IconButton
                data-testid="increment-quantity-icon"
                onClick={() => incrementQuantity(product.name)}
              >
                <PlusSquare />
              </IconButton>
            </Box>
          </Box>
        ))}

      </Box>
    </Box>
  );
}
