import React from 'react';

export default function ArrowUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#474750"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M19.918 15.05l-6.52-6.52c-.77-.77-2.03-.77-2.8 0l-6.52 6.52"
      />
    </svg>
  );
}
