import React from 'react';

export default function ArrowRight() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.6898 18.1144L12.5174 18.1144C16.5404 18.1144 18.1496 16.5052 18.1496 12.4822L18.1496 7.65465C18.1496 3.63166 16.5404 2.02246 12.5174 2.02246L7.6898 2.02246C3.66681 2.02246 2.05762 3.63166 2.05762 7.65465L2.05762 12.4822C2.05761 16.5052 3.66681 18.1144 7.6898 18.1144Z" stroke="#474750" strokeWidth="1.44828" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.08984 12.909L11.922 10.0687L9.08984 7.22852" stroke="#474750" strokeWidth="1.44828" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
