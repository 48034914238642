/* eslint-disable max-len */
import React from 'react';

export default function ResidentsIcon({ width = 24, height = 25, active = false }) {
  if (active) {
    return (
      <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="category-2">
          <g id="vuesax/bold/user-tag">
            <g id="user-tag">
              <g id="Vector">
                <mask id="path-1-inside-1_1919_8677" fill="white">
                  <path d="M18 2.40649H6C4.34 2.40649 3 3.73649 3 5.37649V16.2865C3 17.9265 4.34 19.2565 6 19.2565H6.76C7.56 19.2565 8.32 19.5665 8.88 20.1265L10.59 21.8165C11.37 22.5865 12.64 22.5865 13.42 21.8165L15.13 20.1265C15.69 19.5665 16.46 19.2565 17.25 19.2565H18C19.66 19.2565 21 17.9265 21 16.2865V5.37649C21 3.73649 19.66 2.40649 18 2.40649ZM12 6.15649C13.29 6.15649 14.33 7.19649 14.33 8.48649C14.33 9.77649 13.29 10.8165 12 10.8165C10.71 10.8165 9.67 9.76649 9.67 8.48649C9.67 7.19649 10.71 6.15649 12 6.15649ZM14.68 15.4665H9.32C8.51 15.4665 8.04 14.5665 8.49 13.8965C9.17 12.8865 10.49 12.2065 12 12.2065C13.51 12.2065 14.83 12.8865 15.51 13.8965C15.96 14.5665 15.48 15.4665 14.68 15.4665Z" />
                </mask>
                <path d="M18 2.40649H6C4.34 2.40649 3 3.73649 3 5.37649V16.2865C3 17.9265 4.34 19.2565 6 19.2565H6.76C7.56 19.2565 8.32 19.5665 8.88 20.1265L10.59 21.8165C11.37 22.5865 12.64 22.5865 13.42 21.8165L15.13 20.1265C15.69 19.5665 16.46 19.2565 17.25 19.2565H18C19.66 19.2565 21 17.9265 21 16.2865V5.37649C21 3.73649 19.66 2.40649 18 2.40649ZM12 6.15649C13.29 6.15649 14.33 7.19649 14.33 8.48649C14.33 9.77649 13.29 10.8165 12 10.8165C10.71 10.8165 9.67 9.76649 9.67 8.48649C9.67 7.19649 10.71 6.15649 12 6.15649ZM14.68 15.4665H9.32C8.51 15.4665 8.04 14.5665 8.49 13.8965C9.17 12.8865 10.49 12.2065 12 12.2065C13.51 12.2065 14.83 12.8865 15.51 13.8965C15.96 14.5665 15.48 15.4665 14.68 15.4665Z" fill="#4A397F" stroke="#4A397F" strokeWidth="2" mask="url(#path-1-inside-1_1919_8677)" />
              </g>
            </g>
          </g>
        </g>
      </svg>

    );
  } return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="category-2">
        <g id="vuesax/linear/user-tag">
          <g id="user-tag">
            <path id="Vector" d="M18 19.2665H17.24C16.44 19.2665 15.68 19.5765 15.12 20.1365L13.41 21.8265C12.63 22.5965 11.36 22.5965 10.58 21.8265L8.87 20.1365C8.31 19.5765 7.54 19.2665 6.75 19.2665H6C4.34 19.2665 3 17.9365 3 16.2965V5.38647C3 3.74647 4.34 2.4165 6 2.4165H18C19.66 2.4165 21 3.74647 21 5.38647V16.2965C21 17.9265 19.66 19.2665 18 19.2665Z" stroke="#474750" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <g id="Group">
              <path id="Vector_2" d="M11.9999 10.4066C13.2868 10.4066 14.33 9.36336 14.33 8.07654C14.33 6.78971 13.2868 5.74658 11.9999 5.74658C10.7131 5.74658 9.66992 6.78971 9.66992 8.07654C9.66992 9.36336 10.7131 10.4066 11.9999 10.4066Z" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path id="Vector_3" d="M16 16.0666C16 14.2666 14.21 12.8066 12 12.8066C9.79 12.8066 8 14.2666 8 16.0666" stroke="#474750" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
