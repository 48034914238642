import { Box, IconButton, styled } from '@mui/material';
import BenvoButton from '../../../../components/BenvoButton/style';

export const ActionsWithoutFamiliarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;

  h6 {
    color: #96969e;
    text-align: center;

    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.035px;
  }
`;

export const DividerContainer = styled(Box)`
  width: 100%;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #474750;

    font-size: 12px;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
    letter-spacing: 0.12px;
  }

  div {
    width: 100%;
    height: 1px;
    background: #f5f6f8;
  }
`;

export const LinkContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  width: 100%;

  div {
    display: flex;
    padding: 10px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #f5f5f7;
    flex: 1;
    overflow-x: auto;
    justify-content: space-between;

    color: #474750;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 12px;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
    letter-spacing: 0.12px;
    max-height: 40px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StyledBenvoButton = styled(BenvoButton)`
  padding: 16px 26px;
  gap: 8px;
  max-width: 130px;
  svg {
    min-width: 21px;
    min-height: 21px;
    path {
      stroke: ${({ disabled }) => disabled && '#BEC5CF'};
    }
  }
  &:hover {
    transition: all 0.3s ease;
    svg {
      path {
        ${({ isCopied }) => !isCopied && 'stroke: #4a397f;'}
      }
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;
