import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { createTypeOptions } from '../../utils/createFormFields';
import { validateTime } from '../../utils/utils';
import { getTokenInfo } from '../../../../utils/configAuth';
import {
  createEmployeeOptions,
  createMedicineOptions,
  createResidentOptions,
} from '../../../../utils/createOptionsForAutoComplete';
import { TODAY } from '../../../../utils/daysConstants';
import { useAlert } from '../../../../context/alertContext';
import routes from '../../../../services/routes';

const daysOfWeek = {
  monday: 'Segunda',
  tuesday: 'Terça',
  wednesday: 'Quarta',
  thursday: 'Quinta',
  friday: 'Sexta',
  saturday: 'Sábado',
  sunday: 'Domingo',
};

const recurrenceOptions = [
  { value: 'daily', label: 'Todos os dias' },
  { value: [], label: 'Toda semana nos dias...' },
];

export default function CreateTaskModel({
  createTaskModal,
  setCreateTaskModal,
  employees,
  residents,
  createTask,
}) {
  const ilpiId = getTokenInfo('ilpiId');
  const alert = useAlert();

  const [medicines, setMedicines] = useState([]);
  const [newTask, setNewTask] = useState({
    type: 'cleaning',
    untilDate: TODAY,
    startTime: moment(TODAY).format('YYYY-MM-DDTHH:00'),
    ilpiId,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isRecurrent, setIsRecurrent] = useState(false);

  const residentOptions = createResidentOptions(residents);

  const getMedicines = async (residentId) => {
    try {
      const { message } = await routes.shoppingList.getMedicinesByResidentId({ residentId });
      setMedicines(message);
    } catch (error) {
      console.error(error);
      setMedicines([]);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'residentId' && newTask.type === 'medications') {
      getMedicines(value);
      setNewTask((prev) => ({
        ...prev,
        residentId: value,
      }));
    } else {
      setNewTask((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleModelClose = () => {
    setIsRecurrent(false);
    setCreateTaskModal({
      open: false,
    });
    setNewTask({
      type: 'cleaning',
      untilDate: moment(TODAY).format('YYYY-MM-DD'),
      ilpiId,
    });
  };

  const handleSubmit = async () => {
    const request = newTask;
    request.startTime = validateTime(newTask.startTime);
    request.untilDate = moment(newTask.untilDate).format('YYYY-MM-DD');

    const err = await createTask(request);

    if (err) {
      alert.error(err);
    } else {
      setNewTask({
        type: 'cleaning',
        untilDate: TODAY,
        startTime: moment(TODAY).format('YYYY-MM-DDTHH:00'),
        ilpiId,
      });
      alert.success('Tarefa criada com sucesso!');
      setIsRecurrent(false);
    }
  };

  useEffect(() => {
    if (newTask.type !== 'cleaning') {
      const { title, ...rest } = newTask;
      setNewTask({
        ...rest,
      });
    }
  }, [newTask.type]);

  const validateInputs = () => {
    if (newTask.type && newTask.untilDate && newTask.employeeId) {
      if (newTask.type === 'cleaning' && newTask.title) {
        setIsDisabled(false);
      } else if (
        newTask.type === 'medications'
        && newTask.residentId
        && newTask.medicineId
      ) {
        setIsDisabled(false);
      } else if (
        newTask.type !== 'medications'
        && newTask.type !== 'cleaning'
        && newTask.residentId
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    validateInputs();
  }, [newTask]);

  return (
    <Dialog maxWidth="md" open={createTaskModal.open}>
      <DialogTitle>
        <Typography color="primary" variant="h5" fontWeight={700}>
          Criar Nova Tarefa
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              select
              required
              id="type"
              name="type"
              label="Escolha o tipo da tarefa"
              onChange={handleChange}
              defaultValue={newTask.type || ''}
              size="small"
            >
              {createTypeOptions().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              select
              required
              id="employeeId"
              name="employeeId"
              label="Escolha o cuidador"
              defaultValue={newTask.employeeId || ''}
              onChange={handleChange}
              size="small"
            >
              {createEmployeeOptions(employees)?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            {newTask.type === 'cleaning' ? (
              <TextField
                fullWidth
                required
                type="text"
                label="Nome da Tarefa"
                id="title"
                name="title"
                onChange={handleChange}
                size="small"
              />
            ) : (
              <Autocomplete
                fullWidth
                id="residentId"
                name="residentId"
                options={residentOptions || []}
                defaultValue={
                  residentOptions.find(
                    ({ value }) => value === newTask.residentId,
                  ) || null
                }
                onChange={(e, field) => {
                  handleChange({
                    target: { name: 'residentId', value: field?.value },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    label="Residentes"
                  />
                )}
              />
            )}
          </Grid>
          {newTask.type === 'medications' && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                required
                id="medicineId"
                name="medicineId"
                label="Escolha o medicamento"
                onChange={handleChange}
                size="small"
              >
                {createMedicineOptions(medicines).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item md={12}>
            <TextField
              fullWidth
              multiline
              type="text"
              label="Observação (Opcional)"
              id="observation"
              name="observation"
              onChange={handleChange}
              size="small"
            />
          </Grid>
          <Grid item md={12}>
            <FormControlLabel
              control={<Checkbox />}
              id="isRecurrent"
              name="isRecurrent"
              label="Tarefa recorrente"
              onChange={() => {
                setIsRecurrent(!isRecurrent);
              }}
            />
          </Grid>
          {isRecurrent && (
            <Grid container spacing={2} md={12}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  select
                  required
                  id="recurrence"
                  name="recurrence"
                  label="Escolha a frequência da tarefa"
                  onChange={handleChange}
                  size="small"
                >
                  {recurrenceOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {newTask.recurrence && newTask.recurrence !== 'daily' && (
                <Grid item xs={12} md={12}>
                  {Object.values(daysOfWeek).map((day, i) => (
                    <FormControlLabel
                      control={<Checkbox />}
                      id="isRecurrent"
                      name={day}
                      label={day}
                      onChange={({ target }) => {
                        if (target.checked) {
                          setNewTask({
                            ...newTask,
                            recurrence: [
                              ...newTask.recurrence,
                              Object.keys(daysOfWeek)[i],
                            ],
                          });
                        } else {
                          setNewTask({
                            ...newTask,
                            recurrence: newTask.recurrence.filter(
                              (item) => item !== day,
                            ),
                          });
                        }
                      }}
                    >
                      <Checkbox id={day} name={day} onChange={handleChange} />
                    </FormControlLabel>
                  ))}
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={12} sm="auto">
            <DesktopDatePicker
              label="Data final da tarefa"
              value={newTask.untilDate}
              renderInput={(params) => (
                <TextField
                  id="untilDate"
                  name="untilDate"
                  {...params}
                  required
                  size="small"
                  fullWidth
                />
              )}
              onChange={(value) => handleChange({ target: { name: 'untilDate', value } })}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <TimePicker
              label="Horário da tarefa"
              value={newTask.startTime}
              renderInput={(params) => (
                <TextField
                  required
                  id="startTime"
                  name="startTime"
                  {...params}
                  size="small"
                  fullWidth
                />
              )}
              onChange={(value) => handleChange({ target: { name: 'startTime', value } })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleModelClose}>
          Cancelar
        </Button>
        <Button type="submit" disabled={isDisabled} onClick={handleSubmit}>
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
