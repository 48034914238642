import {
  Accordion, Box, styled, Typography,
} from '@mui/material';

export const AccordionContainer = styled(Box)`
  width: 100%;
  max-width: 630px;
  margin: 0 auto;
  z-index: 9;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin-bottom: 12px;
  border-radius: 6px;

  ::before {
    display: none;
  }

  .MuiAccordionDetails-root {
    padding: 7px 22px 22px 22px;
  }

  .Mui-disabled {
    background-color: var(--grey-variation-lightest-grey, #fff);
    opacity: 1;
  }
`;

export const AccordionTitle = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 18.4px */
  letter-spacing: 0.024px;
`;
