import BenvoContentBox from '../../components/BenvoContentBox';

const {
  Box, Typography, styled, Select, Divider,
} = require('@mui/material');

export const StyledBoxPage = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #F5F5F7;
`;

export const StyledBenvoContentBox = styled(BenvoContentBox)`
  margin: 42px;
`;

export const StyledBoxLogin = styled(Box)`
  align-items: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
`;

export const StyledLeftBox = styled(Box)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #F5F5F7;
`;

export const StyledRightBox = styled(Box)`
  position: relative;
  height: 100vh;
  max-width: 1325px;
  background-repeat: no-repeat;
  background-size: cover;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 9px;
    background: linear-gradient(rgba(43, 29, 70, 1) 0%, rgba(150, 40, 83, 1) 42%, rgba(229, 144, 79, 1) 100%);
  }
`;

export const StyledDoctorInfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 436px;
  max-height: 300px;
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 42px;
`;

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 46px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const StyledBoxLogo = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 208px;
  gap: 22px;
`;

export const StyledTypographyFooter = styled(Typography)`
  position: absolute;
  bottom: 24px;
  left: 24px;
  color: #474750;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
`;

export const StyledTypographyH1ResidentName = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.024px;
`;

export const StyledTypographyPDarkGrey12px = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);
  font-size: 12px;
  line-height: 170%;
  letter-spacing: 0.12px;
`;

export const StyledTypography10px = styled(Typography)`
  color: var(--grey-variation-dark-grey, #474750);
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0.04px;
`;

export const StyledBox = styled(Box)`
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  padding: 32px;
  gap: 20px;
`;

export const StyledBoxCards = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  width: 100%;
`;

export const StyledCard = styled(Box)`
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #F5F5F7;
  gap: 11px;
`;

export const StyledBoxHeaderCard = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledDivider = styled(Divider)`
  border-style: solid;
  border-width: 0px 1px 0px 1px;
  border-color: #D4D4DA;
`;

export const StyledBoxForCardActions = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const StyledBoxContentCard = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  background-color: #F5F5F7;
  border-radius: 12px;
`;

export const StyledBoxResidentDataExam = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;
`;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  background: var(--grey-variation-lightest-grey, #f5f5f7);
  border: none;
  & fieldset {
    border: none;
  }
`;

export const StyledBoxInputs = styled(Box)`
display: flex;
flex-direction: row;
gap: 20px;
width: 100%;
`;

export const StyledContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;
