import React from 'react';
import PropTypes from 'prop-types';
import {
  Button as MuiButton,
  CircularProgress,
  buttonClasses,
  styled,
  Typography,
} from '@mui/material';

export default function Button({
  children,
  variant,
  iconCenterRight,
  iconCenterLeft,
  iconRight,
  loading,
  disabled,
  ...props
}) {
  const CustomButton = styled(MuiButton)({
    [`&.${buttonClasses.contained}`]: {
      background: 'linear-gradient(90deg, #F85C4C 4.27%, #9F56B4 113.12%)',
      color: '#fff',
      borderRadius: '80px',
      padding: iconRight ? '16px 20px' : '16px 40px',
      height: '40px',
      fontSize: '14px',
      border: 'none',
      boxShadow: 'none',
      fontFamily: 'Poppins',

      '&:disabled': {
        background: '#EAEBEC',
        color: '#BEC5CF',
      },

      '& .MuiTypography-root': {
        textTransform: 'none',
        fontFamily: 'Poppins',
      },

      '& .MuiButton-endIcon': {
        marginLeft: iconRight && '10px',
      },

      '& .MuiCircularProgress-root': {
        color: '#fff',
      },
    },
    [`&.${buttonClasses.contained}:hover`]: {
      backgroundClip: 'text',
      backgroundImage: 'linear-gradient(90deg, #F85C4C 4.27%, #9F56B4 113.12%)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      boxShadow: `0px 0px 0px 1px
          rgba(248, 92, 76, 0.8),
          inset 0px 0px 0px 1px
          rgba(159, 86, 180, 0.8)`,

      '& .MuiButton-endIcon svg g #Vector': {
        stroke: 'url(#paint0_linear_123_9)',
      },
      '& .MuiButton-endIcon svg g #Vector_2': {
        stroke: 'url(#paint1_linear_123_9)',
      },
      '& .MuiButton-startIcon svg g #Vector': {
        stroke: 'url(#paint0_linear_123_9)',
      },
      '& .MuiButton-startIcon svg g #Vector_2': {
        stroke: 'url(#paint1_linear_123_9)',
      },

      '& .MuiSvgIcon-root': {
        color: '#F85C4C',
      },

      '& .MuiCircularProgress-root': {
        color: '#F85C4C',
      },
    },
    [`&.${buttonClasses.outlined}`]: {
      background: '#F9F9F9',
      color: '#7A5DEF',
      borderRadius: '80px',
      padding: iconRight ? '16px 20px' : '16px 40px',
      height: '40px',
      fontSize: '14px',
      border: '1px solid #7A5DEF',
      boxShadow: 'none',
      fontFamily: 'Poppins',

      '&:disabled': {
        background: '#F9F9F9',
        color: '#999999',
        border: '1px solid #D9D9D9',
      },

      '& .MuiTypography-root': {
        textTransform: 'none',
        fontFamily: 'Poppins',
      },

      '& .MuiCircularProgress-root': {
        color: '#7A5DEF',
      },
    },
    [`&.${buttonClasses.outlined}:hover`]: {
      boxShadow: ' 0px 10px 15px rgba(0, 0, 0, 0.1)',

      '& .MuiButton-endIcon svg g path': {
        stroke: '#7A5DEF',
      },

      '& .MuiButton-startIcon svg g path': {
        stroke: '#7A5DEF',
      },
    },
  });

  const customVariant = (buttonVariant) => {
    switch (buttonVariant) {
      case 'primary':
        return 'contained';
      case 'secondary':
        return 'outlined';
      default:
        return 'contained';
    }
  };

  return (
    <CustomButton
      variant={customVariant(variant)}
      disabled={disabled}
      startIcon={iconCenterLeft && loading ? <CircularProgress size={20} /> : iconCenterLeft}
      endIcon={
        ((iconCenterRight || iconRight) && loading) || (loading && !iconCenterLeft) ? (
          <CircularProgress size={20} />
        ) : (
          iconCenterRight || iconRight
        )
      }
      {...props}
    >
      <Typography variant="button">{children}</Typography>
    </CustomButton>
  );
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  iconCenterRight: PropTypes.node,
  iconCenterLeft: PropTypes.node,
  iconRight: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'primary',
  iconCenterRight: null,
  iconCenterLeft: null,
  iconRight: null,
  loading: false,
  disabled: false,
};
