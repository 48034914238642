import { Box, styled } from '@mui/material';

export const StyledDropdown = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    gap: 20px;
`;

export const TitleContainer = styled(Box)`
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
width: 100%;
h6{
color: var(--grey-variation-dark-grey, #474750);

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 115%; /* 18.4px */
letter-spacing: 0.024px;
margin-bottom: 0;
}
`;

export const ChildrenContainer = styled(Box)`
  display: grid;
  grid-template-rows: ${({ open }) => (open ? '1fr' : '0fr')};
  transition: grid-template-rows 0.3s ease-in-out;
  width: 100%;

  & > * {
    overflow: hidden;
  }
`;
