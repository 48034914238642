import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { CardContainer, CardText } from './style';
import BenvoButton from '../../../../components/BenvoButton/style';
import SendIndividualShoppingListModal from '../../../residentShoppingList/components/ResidentShoppingListCard/components/SendIndividualShoppingListModal';
import routes from '../../../../services/routes';
import stateMachine from '../../../../utils/stateMachine';

const { shoppingList } = routes;

export default function ShareTheList({ residentInformations }) {
  const getOrderHistory = async () => {
    try {
      const { message: { rows } } = await shoppingList.getAllByResidentId({ residentId: residentInformations.id });
      return rows;
    } catch (error) {
      return [];
    }
  };

  const { data: orderHistory = [], refetch: refetchOrderHistory } = useQuery({
    queryKey: ['orderHistory'],
    queryFn: getOrderHistory,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { isSendable } = stateMachine[orderHistory[0]?.status] || {};
  if (!orderHistory.length || !isSendable) {
    return null;
  }

  return (
    <CardContainer>
      <CardText>
        Compartilhe acesso a lista com o familiar
      </CardText>
      <SendIndividualShoppingListModal
        residentInformation={residentInformations}
        orderInformation={orderHistory[0]}
        refetchShoppingList={refetchOrderHistory}
      >
        <BenvoButton variant="secondary" horizontalPadding={18}>
          Compartilhar
        </BenvoButton>
      </SendIndividualShoppingListModal>
    </CardContainer>
  );
}
