import React from 'react';

export default function DocumentDownload() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M14.6757 9.4915H17.084C17.2662 9.4915 17.4173 9.64265 17.4173 9.82484V14.1915C17.4173 15.5563 16.9449 16.5795 16.2167 17.2635C15.4835 17.9521 14.4519 18.3332 13.2757 18.3332H6.72565C5.54943 18.3332 4.51783 17.9521 3.78462 17.2635C3.05635 16.5795 2.58398 15.5563 2.58398 14.1915V6.80817C2.58398 5.44333 3.05635 4.42016 3.78462 3.73617C4.51783 3.04753 5.54943 2.6665 6.72565 2.6665H10.259C10.4412 2.6665 10.5923 2.81765 10.5923 2.99984V5.40817C10.5923 7.65931 12.4245 9.4915 14.6757 9.4915Z" fill="#4A397F" stroke="#4A397F" />
      <path d="M14.5294 7.34161L14.5294 7.34158H14.5241C13.5286 7.34158 12.7324 6.54402 12.7324 5.61658V2.70825C12.7324 2.70132 12.7334 2.69853 12.7334 2.69845L12.7334 2.69842C12.7335 2.69818 12.7336 2.69793 12.7341 2.69724C12.7355 2.69531 12.7414 2.68892 12.754 2.68395C12.7666 2.67899 12.7785 2.67845 12.7867 2.67993C12.7922 2.68093 12.8003 2.68328 12.8122 2.69513C13.6441 3.52703 14.8929 4.78717 15.9863 5.89057C16.513 6.42207 17.0036 6.91719 17.3943 7.31058L17.3955 7.3118C17.3985 7.31473 17.4002 7.31689 17.4011 7.31813L17.4015 7.31882C17.4014 7.32033 17.4008 7.32302 17.3991 7.32674C17.3959 7.33411 17.3907 7.33995 17.3856 7.3434C17.3833 7.34497 17.3806 7.34636 17.377 7.34745C17.3736 7.34851 17.3674 7.34991 17.3574 7.34991C16.4148 7.34991 15.3178 7.34991 14.5294 7.34161ZM17.3574 7.84991C17.8324 7.84991 18.0824 7.29158 17.7491 6.95825C17.3593 6.56572 16.8692 6.07119 16.3429 5.54006C15.249 4.43619 13.9984 3.17427 13.1658 2.34158C12.8241 1.99991 12.2324 2.23325 12.2324 2.70825V5.61658C12.2324 6.83325 13.2658 7.84158 14.5241 7.84158C15.3157 7.84991 16.4156 7.84991 17.3573 7.84991H17.3574Z" fill="#4A397F" stroke="#4A397F" />
      <path d="M8.25052 13.3667V14.5738L9.10407 13.7202L9.70407 13.1202C9.7256 13.0987 9.75634 13.0854 9.79219 13.0854C9.82804 13.0854 9.85877 13.0987 9.8803 13.1202C9.90183 13.1417 9.9151 13.1725 9.9151 13.2083C9.9151 13.2442 9.90183 13.2749 9.8803 13.2964L8.22769 14.9491C8.20392 14.9685 8.18228 14.9813 8.15579 14.9931C8.14231 14.9991 8.13631 15 8.12552 15C8.11611 15 8.10237 14.9992 8.07226 14.9918C8.06929 14.99 8.06656 14.9882 8.06405 14.9866C8.04571 14.9669 8.02801 14.951 8.01294 14.9386L6.37074 13.2964C6.34921 13.2749 6.33594 13.2442 6.33594 13.2083C6.33594 13.1725 6.34921 13.1417 6.37074 13.1202L6.01719 12.7667L6.37074 13.1202C6.39227 13.0987 6.423 13.0854 6.45885 13.0854C6.49471 13.0854 6.52544 13.0987 6.54697 13.1202L7.14697 13.7202L8.00052 14.5738V13.3667V9.875C8.00052 9.84485 8.01281 9.81357 8.03845 9.78793C8.06409 9.76228 8.09537 9.75 8.12552 9.75C8.15567 9.75 8.18695 9.76228 8.21259 9.78793C8.23824 9.81357 8.25052 9.84485 8.25052 9.875V13.3667Z" fill="#4A397F" stroke="#4A397F" />
    </svg>

  );
}
