/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  OutlinedInput,
  Radio,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useQuery } from '@tanstack/react-query';
import {
  ButtonContainer,
  FormContainer,
  InsuredContainer,
  IsInsuredContainer,
  OptionsContainer,
  SelectOptionText,
  SelectTitle,
  StyledForm,
  StyledSelect,
  StyledTextField,
} from './style';
import AddResidentPlanCard from '../AddResidentPlanCard';
import AddResidentImage from '../AddResidentImage';
import schemas from '../../../../schemas';
import {
  cpfInputMask,
  maskLetters,
  removeMask,
} from '../../../../utils/inputMasks';
import consultCpf from '../../../../utils/consultCpf';
import CalendarIcon from '../../../../assets/CalendarIcon';
import { getTokenInfo } from '../../../../utils/configAuth';
import routes from '../../../../services/routes';
import { convertAPIDateWithoutTime } from '../../../../utils/dateFormat';
import BenvoButton from '../../../../components/BenvoButton/style';
import { useAlert } from '../../../../context/alertContext';
import BenvoDeleteModal from '../../../../components/BenvoDeleteModal';

const genderOptions = [
  { value: 'M', label: 'Masculino', dataTestId: 'masculino' },
  { value: 'F', label: 'Feminino', dataTestId: 'feminino' },
  { value: 'other', label: 'Outro', dataTestId: 'outro' },
];

export default function ResidentForm({
  refetchResidentList,
  isDrawerOpen,
  setResidentInformation,
  residentInformation,
  removeReferenceList,
  residentInformationToEdit,
  handleDrawerClose,
}) {
  const [errorWhileConsultingCpf, setErrorWhileConsultingCpf] = useState(false);

  const { resident } = routes;
  const ilpiId = getTokenInfo('ilpiId');
  const alert = useAlert();

  const convertIsInsured = (value) => {
    const isInsuredValue = {
      0: 'false',
      1: 'true',
    };

    return isInsuredValue[value];
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: residentInformationToEdit.name || '',
      isInsured: convertIsInsured(residentInformationToEdit.isInsured) || 'false',
      birthDate: residentInformationToEdit.birthDate || null,
      gender: residentInformationToEdit.gender || '',
      cpf: cpfInputMask(residentInformationToEdit.cpf) || '',
      profileImage: residentInformationToEdit.profileImage || null,
      planCard: residentInformationToEdit.planCard || null,
    },
    resolver: joiResolver(schemas.resident.createNewResident),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const cpfHasBeenEdited = watch('cpf') !== cpfInputMask(residentInformationToEdit.cpf);

  const isEditingDisabled = () => {
    const cpf = removeMask(watch('cpf'));
    if (
      watch('name') === residentInformationToEdit.name
      && cpf === residentInformationToEdit.cpf
      && watch('gender') === residentInformationToEdit.gender
      && convertAPIDateWithoutTime(watch('birthDate')) === residentInformationToEdit.birthDate
      && watch('isInsured') === convertIsInsured(residentInformationToEdit.isInsured)
      && watch('planCard') === residentInformationToEdit.planCard
      && watch('profileImage') === residentInformationToEdit.profileImage
    ) {
      return true;
    }
    return false;
  };

  const createResident = async (data, shouldCreateAnotherResident) => {
    if (data.isInsured && !data.planCard) {
      alert.error(
        'Imagem da carteirinha é obrigatória para residentes conveniados!',
      );
      return;
    }

    const payload = data;
    try {
      if (residentInformation.id) {
        setResidentInformation({});
        removeReferenceList();
        reset();
        return;
      }
      payload.ilpiId = ilpiId;
      payload.cpf = removeMask(data.cpf);
      payload.birthDate = convertAPIDateWithoutTime(data.birthDate);

      if (watch('profileImage') == null) {
        delete payload.profileImage;
      }

      if (watch('planCard') == null || !data.isInsured) {
        delete payload.planCard;
      }

      const { message } = await resident.createResident(payload);
      setResidentInformation({
        id: message.id,
      });
      alert.success('Residente criado com sucesso!');
      refetchResidentList();
      if (shouldCreateAnotherResident) {
        reset();
      }
    } catch (error) {
      const errorCode = error.request.status;
      if (errorCode === 409) {
        alert.error('CPF já cadastrado!');
      } else {
        alert.error('Erro ao criar residente!');
      }
    }
  };

  const editResident = async (data) => {
    if (data.isInsured && !data.planCard) {
      alert.error(
        'Imagem da carteirinha é obrigatória para residentes conveniados!',
      );
      return;
    }

    const payload = data;
    try {
      if (residentInformation.id) {
        setResidentInformation({});
        removeReferenceList();
        reset();
        return;
      }
      payload.ilpiId = ilpiId;
      payload.cpf = removeMask(data.cpf);
      payload.birthDate = convertAPIDateWithoutTime(data.birthDate);

      if (watch('planCard') == null || !data.isInsured) {
        delete payload.planCard;
      }

      const { message } = await resident.updateResident(
        {
          residentId: residentInformationToEdit.id,
        },
        payload,
      );
      setResidentInformation({
        id: message.id,
      });
      alert.success('Residente editado com sucesso!');
      refetchResidentList();
      handleDrawerClose();
    } catch (error) {
      const errorCode = error.request.status;
      if (errorCode === 409) {
        alert.error('CPF já cadastrado!');
      } else {
        alert.error('Erro ao editar residente!');
      }
    }
  };

  const handleDelete = async () => {
    try {
      const response = await resident.removeResident({
        residentId: residentInformationToEdit.id,
      });
      refetchResidentList();
      alert.success('Residente excluído com sucesso!');
      return response;
    } catch (error) {
      alert.error('Dados do usuário excluídos com sucesso');
      return error;
    }
  };

  const { isInitialLoading: cpfDataIsLoading } = useQuery({
    queryKey: ['cpf', watch('cpf')],
    queryFn: () => consultCpf(removeMask(watch('cpf'))),
    enabled:
      isDrawerOpen
      && watch('cpf')?.length === 14
      && cpfHasBeenEdited
      && !errorWhileConsultingCpf,
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data) {
        setValue('name', data.name);
        setValue('birthDate', data.birthDate);
        setValue('cpf', cpfInputMask(data.ni));
        setErrorWhileConsultingCpf(false);
      }
    },
    onError: () => {
      setErrorWhileConsultingCpf(true);
      alert.error('CPF Inválido');
    },
  });

  const cpf = watch('cpf');
  const maskedCpf = cpfInputMask(cpf);

  useEffect(() => {
    if (
      isDrawerOpen
      && maskedCpf.length === 14
      && !cpfDataIsLoading
      && cpfHasBeenEdited
    ) {
      consultCpf(removeMask(maskedCpf)).then((data) => {
        if (data) {
          setValue('name', data.name);
          setValue('birthDate', data.birthDate);
          setValue('cpf', cpfInputMask(data.ni));
          setErrorWhileConsultingCpf(false);
        } else {
          setErrorWhileConsultingCpf(true);
          alert.error('CPF Inválido');
        }
      });
    }
  }, [maskedCpf]);

  const handleDateChange = (date) => {
    if (date && date._isValid) {
      setValue('birthDate', date._d);
      clearErrors('birthDate');
    } else {
      setError('birthDate', {
        type: 'manual',
        message: 'Data inválida',
      });
    }
  };

  return (
    <FormContainer>
      <StyledForm
        onSubmit={handleSubmit(
          residentInformationToEdit.id
            ? editResident
            : (data) => createResident(data, false),
        )}
      >
        <Grid
          container
          rowSpacing="18px"
          columnSpacing={{
            xs: '15px',
            md: '12px',
          }}
        >
          <Grid item xs="auto" sm={2.6} minWidth="98px" maxHeight="116px">
            <AddResidentImage
              onChange={(image) => setValue('profileImage', image)}
              profileImage={watch('profileImage')}
              currentImage={residentInformationToEdit.profileImage}
            />
          </Grid>

          <Grid item xs={12} sm={9.4}>
            <Grid
              container
              rowSpacing="18px"
              columnSpacing={{
                xs: '15px',
                md: '12px',
              }}
            >
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  placeholder="CPF"
                  disabled={cpfDataIsLoading}
                  error={!!errors.cpf}
                  helperText={errors.cpf?.message}
                  value={cpfInputMask(watch('cpf')) ?? ''}
                  {...register('cpf')}
                  data-testid="resident-cpf"
                  inputProps={{
                    maxLength: 14,
                  }}
                  InputProps={{
                    endAdornment: cpfDataIsLoading && (
                      <CircularProgress size="16px" color="inherit" />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledSelect
                  fullWidth
                  displayEmpty
                  value={watch('gender') ?? ''}
                  error={!!errors.gender}
                  {...register('gender')}
                  data-testid="gender"
                  input={<OutlinedInput />}
                >
                  <MenuItem value="" disabled>
                    Gênero
                  </MenuItem>
                  {genderOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      data-testid={`select-${option.value}`}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  disabled={
                    (cpfDataIsLoading || !errorWhileConsultingCpf)
                    && !residentInformationToEdit.id
                  }
                  placeholder="Nome do residente"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  {...register('name')}
                  value={maskLetters(watch('name')) ?? ''}
                  data-testid="resident-name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <DesktopDatePicker
                  value={watch('birthDate') ?? null}
                  onChange={handleDateChange}
                  components={{
                    OpenPickerIcon: CalendarIcon,
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      size="small"
                      fullWidth
                      disabled={cpfDataIsLoading}
                      data-testid="resident-birth-date"
                      error={!!errors.birthDate}
                      helperText={errors.birthDate?.message}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Data de nascimento',
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SelectTitle variant="h6">
              Formato de atendimento médico
            </SelectTitle>
          </Grid>

          <Grid item xs={12}>
            <OptionsContainer>
              <label htmlFor="particular" style={{ width: '100%' }}>
                <InsuredContainer>
                  <Radio
                    id="particular"
                    checked={watch('isInsured') === 'false'}
                    name="radio-button-demo"
                    value={false}
                    {...register('isInsured')}
                  />
                  <SelectOptionText variant="body1">
                    Particular
                  </SelectOptionText>
                </InsuredContainer>
              </label>

              <IsInsuredContainer isOpen={watch('isInsured') === 'true'}>
                <label htmlFor="convenio">
                  <InsuredContainer>
                    <Radio
                      id="convenio"
                      checked={watch('isInsured') === 'true'}
                      name="radio-button-demo"
                      value
                      {...register('isInsured')}
                    />
                    <SelectOptionText variant="body1">
                      Convênio
                    </SelectOptionText>
                  </InsuredContainer>
                </label>

                <Box sx={{ width: '100%', height: '100%', padding: '12px' }}>
                  <AddResidentPlanCard
                    onChange={(image) => setValue('planCard', image)}
                    planCard={watch('planCard')}
                    currentImage={residentInformationToEdit.planCard}
                    isEdit
                  />
                </Box>
              </IsInsuredContainer>
            </OptionsContainer>
          </Grid>
        </Grid>
        <ButtonContainer>
          {!residentInformationToEdit.id && (
            <BenvoButton
              variant="secondary"
              type="button"
              disabled={isSubmitting}
              data-testid="save-and-create-button"
              horizontalPadding={24}
              onClick={() => createResident(
                {
                  ...watch(),
                  isInsured: watch('isInsured') === 'true',
                },
                true,
              )}
            >
              {residentInformation.id ? 'Criar outro' : 'Salvar e criar outro'}
              {isSubmitting && <CircularProgress size="14px" color="primary" />}
            </BenvoButton>
          )}

          {residentInformationToEdit.id && (
            <BenvoDeleteModal
              testid={`delete-${residentInformationToEdit.id}`}
              buttonText="Excluir dados"
              title="Apagar dados"
              description="Deseja apagar os dados do residente?"
              tooltipTitle="Apagar dados do residente"
              handleDelete={handleDelete}
            />
          )}

          <BenvoButton
            variant="primary"
            type="submit"
            data-testid="save-button"
            disabled={isSubmitting || residentInformation.id || isEditingDisabled()}
            horizontalPadding={24}
          >
            Salvar
            {isSubmitting && <CircularProgress size="14px" color="white" />}
          </BenvoButton>
        </ButtonContainer>
      </StyledForm>
    </FormContainer>
  );
}
