import { styled, Box, Checkbox } from '@mui/material';

export const StyledBoxContainer = styled(Box)`
  width: 375px;
  margin: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const StyledCheckBox = styled(Checkbox)`
  color: palette.primary;
`;

export const StyledCheckBoxLabel = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
