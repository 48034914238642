/* eslint-disable no-unused-vars */
import React from 'react';
import {
  DarkTextTypography, Heading2, NotAddedItem, NotAddedItems, NotAddedItemsBox,
} from '../style';
import { formatPhraseToLowerCaseWithFirstLetterUpperCase } from '../../../../../../utils/formatStrings';

export default function Cart({ processedCart }) {
  const { notAddedToCart } = processedCart;

  return (
    <NotAddedItemsBox>
      <Heading2>Itens não adicionados</Heading2>
      <NotAddedItems>
        {notAddedToCart.map(({ name, quantity }) => {
          const nameToLowerCase = formatPhraseToLowerCaseWithFirstLetterUpperCase(name);
          return (
            <NotAddedItem>
              <DarkTextTypography>
                {name.length > 32 ? `${nameToLowerCase.slice(0, 32)}...` : nameToLowerCase}
              </DarkTextTypography>
              <DarkTextTypography>{`${quantity} un`}</DarkTextTypography>
            </NotAddedItem>
          );
        })}
      </NotAddedItems>
    </NotAddedItemsBox>
  );
}
