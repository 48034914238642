import { Box, Skeleton, styled } from '@mui/material';

export const ContainerTitleSearchAndActions = styled(Box)`
  display: flex;
  width: 100%;
  padding: 25px 16px 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;

  h2 {
    color: var(--grey-variation-dark-grey, #474750);

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.027px;
  }
`;

export const ContainerSearchAndActions = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ContainerActions = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 12px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const ContainerIlpiInformation = styled(Box)`
  display: flex;
  height: calc(100% - 100px);
  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-shrink: 0;
  overflow-y: scroll;
`;

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: calc(100% - 25px);
`;
