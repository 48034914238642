/* eslint-disable max-len */
import {
  Divider, MenuItem,
} from '@mui/material';
import React from 'react';
import { phoneInputMask } from '../../../../utils/inputMasks';
import EditCollaborator from '../EditCollaborator';
import EditUser from '../EditUser';
import BenvoDeleteModal from '../../../../components/BenvoDeleteModal';
import {
  ActionsContainer, GeneralInformation, InformationAndActionsContainer, ListContainer, Name, Role, StyledSelect, UserContainer, UserInformationContainer,
} from './style';
import { useAlert } from '../../../../context/alertContext';
import routes from '../../../../services/routes';

const { collaborator: collaboratorRoute, users: usersRoute } = routes;

const statusOptions = [
  { value: 1, label: 'Ativo', dataTestId: 'active' },
  { value: 0, label: 'Inativo', dataTestId: 'inactive' },
];

export default function ListUser({ user, getUsersAndCollaborators }) {
  const alert = useAlert();
  const translatePrivilege = (privilege) => {
    switch (privilege) {
      case 'admin':
        return 'Administrador';
      case 'user':
        return 'Usuário';
      default:
        return 'Usuário';
    }
  };

  const translateUserType = (userType) => {
    switch (userType) {
      case 'doctor':
        return user.professionalIdentification ? `Médico - CRM ${user.professionalIdentification.slice(0, -2)} - ${user.professionalIdentification.slice(-2)}` : 'Médico';
      case 'nurse':
        return user.professionalIdentification ? `Enfermeiro - COREN ${user.professionalIdentification}` : 'Enfermeiro';
      case 'caregiver':
        return 'Cuidador';
      case 'employee':
        return 'Funcionário';
      default:
        return translatePrivilege(user.privilege);
    }
  };

  const handleDeleteUser = async () => {
    try {
      if (user.type) {
        await collaboratorRoute.deleteCollaboratorById({ collaboratorId: user.collaboratorId });
        alert.success('Colaborador deletado com sucesso');
      } else {
        await usersRoute.deleteUserById({ userId: user.userId });
        alert.success('Usuário deletado com sucesso');
      }
      getUsersAndCollaborators();
    } catch (error) {
      alert.error('Não foi possível deletar o usuário');
    }
  };

  const handleChangeUserStatus = async () => {
    try {
      await usersRoute.changeUserStatus({ userId: user.userId });
      alert.success('Status do usuário alterado com sucesso');
      getUsersAndCollaborators();
    } catch {
      alert.error('Não foi possível alterar o status do usuário');
    }
  };

  return (
    <ListContainer>
      <UserContainer>
        <Name>{user.name}</Name>
        <Role>{translateUserType(user.type)}</Role>
      </UserContainer>
      <InformationAndActionsContainer>
        {user.isUser === 1 && (
          <StyledSelect
            fullWidth
            displayEmpty
            value={user.isActive}
            renderValue={() => (`Status ${user.isActive ? 'ativo' : 'inativo'} `)}
            onChange={handleChangeUserStatus}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value} data-testid={option.dataTestId}>
                {option.label}
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        <UserInformationContainer>
          <GeneralInformation>{phoneInputMask(user.phoneNumber)}</GeneralInformation>
          <GeneralInformation>{user.email}</GeneralInformation>
        </UserInformationContainer>
        <Divider orientation="vertical" flexItem />
        <ActionsContainer>
          <BenvoDeleteModal
            tooltipTitle={user.type ? 'Apagar colaborador' : 'Apagar usuário'}
            handleDelete={handleDeleteUser}
            title={user.type ? 'Apagar colaborador' : 'Apagar usuário'}
            description="Deseja apagar esse usuário, essa ação não pode ser desfeita?"
            buttonText="Excluir usuário"
          />
          {user.type ? (
            <>
              <EditCollaborator
                getUsersAndCollaborators={getUsersAndCollaborators}
                collaborator={user}
              />
              <EditCollaborator
                getUsersAndCollaborators={getUsersAndCollaborators}
                collaborator={user}
                isButton
              />
            </>
          ) : (
            <>
              <EditUser
                getUsersAndCollaborators={getUsersAndCollaborators}
                user={user}
              />
              <EditUser
                getUsersAndCollaborators={getUsersAndCollaborators}
                user={user}
                isButton
              />
            </>
          )}
        </ActionsContainer>
      </InformationAndActionsContainer>
    </ListContainer>
  );
}
