import React from 'react';

import { Link } from 'react-router-dom';
import HomeResidentsCard from './components/HomeResidentsCard';
import MedicineAndPharmacy from './components/MedicineAndPharmacy';
import EletricityBill from './components/ElectricityBill';
import RequestExamsCard from './components/RequestExamsCard';
import CreditCard from './components/CreditCard';
import { BENVO_FOOTER } from '../../constants';

import PATHS from '../../constants/applicationPaths';

import {
  CardsContainer,
  Container,
  FeaturesContainer,
  ResidentsAndFeaturesContainer,
  VersionContainer,
} from './styles';

export default function IlpiHome() {
  return (
    <Container>
      <CardsContainer>
        <ResidentsAndFeaturesContainer>
          <HomeResidentsCard />
          <FeaturesContainer>
            <MedicineAndPharmacy />
            <EletricityBill />
          </FeaturesContainer>
        </ResidentsAndFeaturesContainer>
        <RequestExamsCard />
        <CreditCard />
      </CardsContainer>
      <VersionContainer>
        {BENVO_FOOTER}
        <div>
          <Link target="_blank" to={PATHS.public.termsAndConditions}>Termos & Condições</Link>
          <Link target="_blank" to={PATHS.public.privacyPolicy}>Política de privacidade</Link>
        </div>
      </VersionContainer>
    </Container>
  );
}
