import Joi from 'joi';

const createDoctor = Joi.object({
  name: Joi.string().min(3).required().messages({
    'string.min': 'O nome deve ter no mínimo 3 caracteres',
    'string.empty': 'O nome não pode ser vazio',
  }),
  createUser: Joi.boolean().required(),
  privilege: Joi.when('createUser', {
    is: true,
    then: Joi.valid('admin', 'user').required().messages({
      'string.empty': 'O privilégio é obrigatório',
      'any.only': 'O privilégio deve ser "admin" ou "user"',
      'any.required': 'O privilégio é obrigatório',
    }),
  }),
  cpf: Joi.string().length(14).required().messages({
    'string.length': 'O CPF deve ter 11 caracteres',
    'string.empty': 'O CPF não pode ser vazio',
  }),
  professionalIdentification: Joi.string().required().messages({
    'string.empty': 'O CRM não pode ser vazio',
  }),
  crmUf: Joi.string().min(2).required().messages({
    'string.min': 'A UF deve ter no mínimo 2 caracteres',
    'string.empty': 'A UF não pode ser vazia',
    'any.required': 'A UF é obrigatória',
  }),
  phoneNumber: Joi.string().min(14).max(15).allow('')
    .messages({
      'string.min': 'O telefone deve ter no mínimo 10 caracteres',
      'string.max': 'O telefone deve ter no máximo 11 caracteres',
    }),
  email: Joi.string().email({ tlds: false }).required().messages({
    'string.email': 'O email deve ser válido',
    'string.empty': 'O email não pode ser vazio',
  }),
  zipCode: Joi.string().length(9).allow('').messages({
    'string.length': 'O CEP deve ter nove dígitos',
  }),
  street: Joi.when('zipCode', {
    is: Joi.string(),
    then: Joi.string().min(2).required().messages({
      'string.min': 'Digite um logradouro válido',
      'string.empty': 'O logradouro não pode ser vazio',
    }),
    otherwise: Joi.string().allow(''),
  }),
  number: Joi.string().allow('').messages({
    'string.min': 'Digite um número válido',
    'string.empty': 'O número não pode ser vazio',
  }),
  state: Joi.when('zipCode', {
    is: Joi.string(),
    then: Joi.string().length(2).required().messages({
      'string.min': 'Digite uma cidade válida',
      'string.empty': 'O estado não pode ser vazio',
    }),
    otherwise: Joi.string().allow(''),
  }),
  city: Joi.when('zipCode', {
    is: Joi.string(),
    then: Joi.string().min(2).required().messages({
      'string.min': 'Digite uma cidade válida',
      'string.empty': 'A cidade não pode ser vazia',
    }),
    otherwise: Joi.string().allow(''),
  }),
  district: Joi.when('zipCode', {
    is: Joi.string(),
    then: Joi.string().min(2).required().messages({
      'string.min': 'Digite um bairro válido',
      'string.empty': 'O bairro não pode ser vazio',
    }),
    otherwise: Joi.string().allow(''),
  }),
  complement: Joi.string().allow(''),
  reference: Joi.string().allow(''),
});

const editDoctor = createDoctor;

export default {
  createDoctor,
  editDoctor,
};
