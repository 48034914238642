import React from 'react';

export default function BackButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="42"
      fill="none"
      viewBox="0 0 41 42"
    >
      <rect
        width="40"
        height="40"
        x="0.5"
        y="1"
        fill="#fff"
        stroke="#F5F5F7"
        rx="11.5"
      />
      <path
        fill="#474750"
        stroke="#474750"
        strokeWidth="0.2"
        d="M23.158 13.867a.99.99 0 00-1.402 0l-6.578 6.579a.789.789 0 000 1.116l6.578 6.579a.989.989 0 001.402 0 .989.989 0 000-1.402L17.426 21l5.74-5.739a.987.987 0 00-.008-1.393z"
      />
    </svg>
  );
}
