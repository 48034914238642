import React, { useEffect, useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import BenvoTooltip from '../../../../components/BenvoTooltip';
import {
  ButtonsContainer,
  StyledDialog, StyledDialogContent, StyledDialogTitle, StyledForm, StyledIconButton,
} from './style';
import EditIcon from '../../../../assets/admin(ilpis)/editIcon';
import BenvoTextField from '../../../../components/BenvoField/style';
import BenvoSelectField from '../../../../components/BenvoSelectField';
import { getTokenInfo } from '../../../../utils/configAuth';
import schemas from '../../../../schemas';
import { cpfInputMask, removeMask } from '../../../../utils/inputMasks';
import routes from '../../../../services/routes';
import { useAlert } from '../../../../context/alertContext';
import BenvoButton from '../../../../components/BenvoButton/style';

const accessPermissions = [
  { value: 'user', label: 'Usuário', dataTestId: 'user' },
  { value: 'admin', label: 'Administrador', dataTestId: 'admin' },
];

export default function EditIlpiUserModal({ isAdmin, user }) {
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const queryClient = useQueryClient();

  const { users } = routes;
  const alert = useAlert();

  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors, isSubmitting },
    setValue,

  } = useForm({
    resolver: joiResolver(schemas.user.createUser),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const handleOpenEditUserModal = () => {
    setOpenEditUserModal(true);
  };

  const handleCloseEditUserModal = () => {
    setOpenEditUserModal(false);
    reset();
  };

  const editUser = async (data) => {
    const ilpiId = getTokenInfo('ilpiId');
    const userData = {
      ...data,
      cpf: removeMask(data.cpf),
      ilpiId,
    };
    try {
      await users.updateUser({ userId: user.id }, userData);
      alert.success('Usuário editado com sucesso!');
      handleCloseEditUserModal();
      queryClient.invalidateQueries({
        queryKey: ['ilpiUsers'],
      });
    } catch (error) {
      const { data: errorData, status } = error.response;
      if (status === 409) {
        alert.error(errorData.message);
      } else {
        alert.error('Erro ao editar usuário!');
      }
    }
  };

  useEffect(() => {
    if (user) {
      setValue('name', user.name);
      setValue('cpf', cpfInputMask(user.cpf));
      setValue('email', user.email);
      setValue('privilege', user.privilege);
    }
  }, [user, openEditUserModal]);

  return (
    <div>
      <BenvoTooltip title={isAdmin ? 'Usuário é administrador' : 'Editar Usuário'} arrow placement="top">
        <StyledIconButton disabled={isAdmin} onClick={handleOpenEditUserModal}>
          <EditIcon />
        </StyledIconButton>
      </BenvoTooltip>
      <StyledDialog open={openEditUserModal} onClose={handleCloseEditUserModal}>
        <StyledDialogTitle>Editar Usuário</StyledDialogTitle>
        <StyledDialogContent>
          <StyledForm onSubmit={handleSubmit(editUser)}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <BenvoTextField
                  placeholder="Nome"
                  fullWidth
                  {...register('name')}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  value={watch('name') ?? ''}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <BenvoTextField
                  placeholder="CPF"
                  fullWidth
                  {...register('cpf')}
                  error={!!errors.cpf}
                  helperText={errors.cpf?.message}
                  value={cpfInputMask(watch('cpf')) ?? ''}
                  inputProps={{ maxLength: 14 }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <BenvoTextField
                  placeholder="E-mail"
                  fullWidth
                  {...register('email')}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  value={watch('email') ?? ''}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <BenvoSelectField
                  fullWidth
                  displayEmpty
                  {...register('privilege')}
                  error={!!errors.privilege}
                  helperText={errors.privilege?.message}
                  value={watch('privilege') ?? ''}
                >
                  <MenuItem value="" disabled>
                    Permissão de acesso
                  </MenuItem>
                  {accessPermissions.map((accessPermission) => (
                    <MenuItem
                      key={accessPermission.value}
                      value={accessPermission.value}
                    >
                      {accessPermission.label}
                    </MenuItem>
                  ))}
                </BenvoSelectField>
              </Grid>
            </Grid>
            <ButtonsContainer>
              <BenvoButton disabled={isSubmitting} variant="secondary" horizontalPadding={24} onClick={handleCloseEditUserModal}>
                Cancelar
              </BenvoButton>
              <BenvoButton disabled={isSubmitting} variant="primary" type="submit">
                Editar
              </BenvoButton>
            </ButtonsContainer>
          </StyledForm>
        </StyledDialogContent>
      </StyledDialog>
    </div>

  );
}
