import { Drawer, IconButton, styled } from '@mui/material';

export const StyledDrawer = styled(Drawer)`
  overflow: visible;
  position: relative;
  display: block;

  @media (max-width: 768px) {
    display: none;
  }

  .MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 22px 32px;
    box-sizing: border-box;
    width: ${({ open }) => (open ? '258px' : '104px')};
    max-width: 258px;
    border-radius: 0px 8px 8px 0px;
    transition: width 0.3s ease-in-out;
    border: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    overflow: visible;
  }
`;

export const StyledDrawerButton = styled(IconButton)`
  position: fixed;
  top: 32px;
  background-color: #4a397f;
  color: #ffffff;
  border-radius: 4px;
  z-index: 9999;
  transition: left 0.3s ease-in-out;
  width: 20px;
  height: 20px;

  &:hover {
    background-color: #4a397f;
    color: #ffffff;
  }
`;
