import React from 'react';
import { Button, Card } from './style';

export default function EletricityBill() {
  return (
    <Card>
      <h2>
        Economize até 20% em sua conta de luz
      </h2>
      <p>
        A Benvo possui parceria com +50 usinas em todo país,
        gerando desconto diretamente na fatura de ILPI&apos;s parceiras.
      </p>
      <a
        target="_blank"
        href="https://energia.benvo.com.br/"
        rel="noreferrer"
      >
        <Button type="button">Saiba mais</Button>
      </a>
    </Card>
  );
}
