import { Close } from '@mui/icons-material';
import {
  Box, CircularProgress, Divider, Typography,
} from '@mui/material';
import React from 'react';
import BenvoIconButton from '../BenvoIconButton/style';
import { StyledDrawer, ContentContainer, CloseButton } from './style';
import BenvoLogo from '../../assets/benvo-logo.png';
import ArrowBack from '../../assets/icons/ArrowBack';

export default function BenvoDrawer({
  open, onClose, title, children, isLoading = false, justifyContent = 'center', sx, ...props
}) {
  return (
    <Box>
      {open && (
        <CloseButton
          data-testid="close-benvo-drawer-btn"
          onClick={onClose}
        >
          <Close color="white" />
        </CloseButton>
      )}
      <StyledDrawer
        anchor="right"
        open={open}
        {...props}
      >
        <Typography
          variant="subtitle1"
          color="#474750"
          sx={{
            fontWeight: '700',
            fontSize: '18px',
            mt: '34px',
            mb: '25px',
            mx: '40px',
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
        >
          {title}
        </Typography>
        <Divider
          sx={{
            opacity: '1.25',
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
        />

        <Box sx={{
          paddingTop: '34px',
          px: '28px',
          display: {
            xs: 'flex',
            sm: 'none',
          },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <BenvoIconButton
            sx={{
              borderRadius: '12px',
              boxShadow: 'none !important',
              border: '1px solid #F5F5F7',
              background: 'white !important',
            }}
            onClick={onClose}
          >
            <ArrowBack />
          </BenvoIconButton>
          <img src={BenvoLogo} alt="Benvo Logo" />
        </Box>
        <ContentContainer justifyContent={justifyContent}>
          {isLoading ? (
            <CircularProgress />
          ) : children}
        </ContentContainer>
      </StyledDrawer>
    </Box>
  );
}
