import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  ButtonContainer, FormContainer, StyledForm, StyledTextField,
} from './style';
import schemas from '../../../../schemas';
import { StyledTellInput } from '../CreateOrEditResident/style';
import BenvoButton from '../../../../components/BenvoButton/style';
import { getTokenInfo } from '../../../../utils/configAuth';
import routes from '../../../../services/routes';
import { phoneWithCountryCodeMask, removeMask } from '../../../../utils/inputMasks';

import ResponsibleConflictModal from '../ResponsibleConflictModal';
import ReferenceChangeModal from '../ReferenceChangeModal';

export default function EditReferenceContactForm({
  residentInformation,
  contactInformation,
  setEditReferenceContact,
  setIsEditing,
  residentInformationToEdit,
}) {
  const [responsibleConflict, setResponsibleConflict] = useState({
    open: false,
    responsible: [],
  });
  const [contactChange, setContactChange] = useState({
    open: false,
    contact: [],
  });

  const ilpiId = getTokenInfo('ilpiId');

  const referenceForm = useForm({
    resolver: joiResolver(schemas.referenceContact.createNewReferenceContact),
  });

  const queryClient = useQueryClient();
  const { resident } = routes;
  const {
    control, handleSubmit, formState: { errors, isSubmitting }, reset, watch, setValue,
  } = referenceForm;
  const watchAllFields = watch();

  useEffect(() => {
    if (contactInformation) {
      setValue('name', contactInformation.name);
      setValue('phone', phoneWithCountryCodeMask(contactInformation.phone));
      setValue('email', contactInformation.email);
    }
  }, [contactInformation, setValue]);

  const handleCloseConflictModal = () => setResponsibleConflict({ open: false, responsible: [] });
  const handleCloseContactChangeModal = () => setContactChange({ open: false, contact: [] });

  const isEditingDisabled = () => {
    const phone = removeMask(watchAllFields.phone);
    return watchAllFields.name === contactInformation.name
      && phoneWithCountryCodeMask(phone) === phoneWithCountryCodeMask(contactInformation.phone)
      && (watchAllFields.email === contactInformation.email || (watchAllFields.email === '' && contactInformation.email === null));
  };

  const checkConflit = async ({ email, phone }) => {
    try {
      const { message } = await resident.getReferenceContactsByIlpi(
        {
          ilpiId,
        },
        `email=${email || ''}&phone=${removeMask(phone)}`,
      );

      return message?.filter((contact) => contact.id !== contactInformation.id) || [];
    } catch (error) {
      return [];
    }
  };

  const editReferenceContact = async (data, confirmed = false) => {
    const residentId = residentInformation.id ?? residentInformationToEdit.id;
    const payload = {
      id: contactInformation.id,
      ilpiId,
      email: data.email || null,
      phone: removeMask(data.phone) || null,
      name: data.name,
    };

    try {
      const conflitContacts = await checkConflit(data);
      if (conflitContacts.length) {
        setResponsibleConflict({
          open: true,
          responsible: [...conflitContacts, {
            isEditing: true,
            name: data.name,
            email: contactInformation.email,
            phone: contactInformation.phone,
          }],
        });

        return;
      }

      if (!confirmed) {
        const { message } = await resident.listAllResidentsAtFamilyByResponsibleEmailOrPhone({
          ilpiId,
          email: contactInformation.email || '',
          phone: removeMask(contactInformation.phone) || '',
          residentId,
        });
        if (message.length > 0) {
          setContactChange({
            open: true,
            contact: message,
          });
          return;
        }
      }

      await resident.residentContact({ residentId }, payload);

      setEditReferenceContact(false);
      queryClient.invalidateQueries({
        queryKey: ['referenceContactList'],
      });
      reset();
    } catch (error) {
      const { response } = error;
      const errorCode = response?.request?.status;
      const responsible = response?.data?.message;
      if (errorCode === 409) {
        setContactChange({
          open: false,
          contact: null,
        });
        setResponsibleConflict({
          open: true,
          responsible: [...responsible, {
            isEditing: true,
            name: data.name,
            email: data.email,
            phone: data.phone,
          }],
        });
      }
    }
  };

  const upsertReferenceContact = async () => {
    const payload = {
      ilpiId,
      id: responsibleConflict.responsible[0]?.id,
      email: watch('email') || null,
      phone: removeMask(watch('phone')) || null,
      name: watch('name'),
    };
    try {
      await resident.residentContact({
        residentId: residentInformation.id ?? residentInformationToEdit.id,
      }, payload);

      queryClient.invalidateQueries({
        queryKey: ['referenceContactList'],
      });
      reset();
      setResponsibleConflict({ open: false, responsible: [] });
      setEditReferenceContact(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelEdit = () => {
    setEditReferenceContact(false);
    setIsEditing(false);
    setValue('name', contactInformation.name);
    setValue('phone', phoneWithCountryCodeMask(contactInformation.phone));
    setValue('email', contactInformation.email);
  };

  const isSaveButtonDisabled = () => (
    !watch('name')
    || !watch('phone')
    || isSubmitting
    || isEditingDisabled()
    || contactChange.open
  );

  return (
    <FormContainer>
      <StyledForm onSubmit={handleSubmit((data) => editReferenceContact(data, false))}>
        <Grid container rowSpacing="12px" columnSpacing={{ xs: '15px', md: '12px' }}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  placeholder="Nome do contato"
                  helperText={errors.name?.message}
                  error={!!errors.name}
                  data-testid="responsible-name"
                  value={field.value || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <StyledTellInput
                  {...field}
                  fullWidth
                  placeholder="Telefone"
                  langOfCountryName="pt-br"
                  defaultCountry="BR"
                  preferredCountries={['BR', 'PT']}
                  helperText={errors.phone?.message}
                  error={!!errors.phone}
                  data-testid="responsible-phone"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  placeholder="E-mail"
                  helperText={errors.email?.message}
                  error={!!errors.email}
                  data-testid="responsible-email"
                  value={field.value || ''}
                />
              )}
            />
          </Grid>
        </Grid>

        <ButtonContainer>
          <BenvoButton
            disabled={isSubmitting || contactChange.open}
            onClick={handleCancelEdit}
            horizontalPadding={24}
            variant="secondary"
            type="button"
          >
            Cancelar
          </BenvoButton>
          <BenvoButton
            disabled={isSaveButtonDisabled()}
            horizontalPadding={24}
            type="submit"
          >
            Salvar
          </BenvoButton>
        </ButtonContainer>
      </StyledForm>
      <ResponsibleConflictModal
        handleClose={handleCloseConflictModal}
        responsibleConflict={responsibleConflict}
        editReferenceContact={upsertReferenceContact}
      />
      <ReferenceChangeModal
        handleClose={handleCloseContactChangeModal}
        contactChange={contactChange}
        onConfirm={() => {
          handleSubmit((data) => editReferenceContact(data, true))();
        }}
      />
    </FormContainer>
  );
}
